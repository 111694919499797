import React from "react";
import * as S from "./CmpTextAreaInput.style";
import { useTranslation } from "react-i18next";
import { PopUpPayload } from "../../genericTemplate/GenericTemplate.style";
import Popup from "reactjs-popup";

// pipes
import * as P from "../../../../../../../../utils/pipes";

// Components
import SelectorForm from "../selectorForm";

function CmpTextAreaInput({
  item,
  insertfunction,
  customFormChangeItem,
  typeView
}) {  
  const { i18n: { language } } = useTranslation("global");

  const changeOptionItem = (value) => {
    const userToCustomFormField = {
      customFormFieldParentId: item?.customFormFieldParentId,
      customFormFieldId: item.id,
      value: value.toString(),
      recordId: customFormChangeItem.recordId,
      key: value.toString()
    }
    insertfunction(userToCustomFormField)
  }

  return (
    <S.CmpTextAreaInput>
      <div className="title">
        {item.fieldName && P.dataTranslation(item.fieldName, language)}
        <Popup
          trigger={
            <i className="template-header-item-icon icon zaia-alert"></i>
          }
          on={['hover', 'focus']}
          closeOnDocumentClick
          keepTooltipInside
          position={['right center']}
          nested
        >
          <PopUpPayload>
            <p>
            {P.dataTranslation(item.descript, language)}
            </p>
          </PopUpPayload>
        </Popup>
      </div>
      <div className="selection-container">
        {
          <SelectorForm 
            boxShadow={false}
            type='textarea'
            dataInput={customFormChangeItem ? customFormChangeItem?.value?.toString() : item?.userToCustomFormField ? item?.userToCustomFormFields[0]?.value?.toString() : null}
            handleSubmit={changeOptionItem}
            disabled={typeView === 'view'}
          />
        }
      </div>
    </S.CmpTextAreaInput>
  );
}

export default CmpTextAreaInput;
