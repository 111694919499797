export const Routes = {
  HOME: {
    id: "home",
    name: "Home",
    route: "/",
  },
  LOGIN: {
    id: "login",
    name: "Inicio de sesión",
    route: "/login",
  },
  REGISTER: {
    id: "register",
    name: "Registro",
    route: "/register",
  },
  RESET_PASSWORD: {
    id: "password",
    name: "Restablecer contraseña",
    route: "/reset-password",
  },
  DASHBOARD: {
    id: "alerts",
    name: "Inicio",
    route: "/alerts",
  },
  PATIENTS: {
    id: "patients",
    name: "Patientes",
    route: "/patients",
  },
  ALGORITHMS: {
    id: "algorithms",
    name: "Algoritmos",
    route: "/algorithms",
  },
  OUTCOMES: {
    id: "outcomes",
    name: "Resultados",
    route: "/outcomes",
  },
  CHAT: {
    id: "chat",
    name: "Chat",
    route: "/chat",
  },
  GENERATIVEAI: {
    id: "generativeai",
    name: "IA Generativa",
    route: "/generativeai",
  },
  AGENDA: {
    id: "agenda",
    name: "Agenda",
    route: "/agenda",
  },
  AINAVIGATOR: {
    id: "ainavigator",
    name: "Navegador IA",
    route: "/ainavigator",
  },
  PROFILE: {
    id: "profile",
    name: "Profile",
    route: "/profile",
  },
  FILE_READING: {
    id: "file-reading",
    name: "Archivos",
    route: "/file-reading",
  },
  SURVEYS: {
    id: "surveys",
    name: "Encuestas",
    route: "/surveys",
  },
  POLICE: {
    id: "police",
    name: "Policía",
    route: "/police",
  }
};
