import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalOutcomesEventHistory.style"
import { v4 as uuidv4 } from 'uuid';
import Popup from "reactjs-popup";

// pipes
import * as P from "../../../../../utils/pipes";
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_GET_CUSTOM_FORMS_HISTORY_BY_ID } from "../../../../../graphql/queries/CustomForm";
import { useMemo } from "react";
import ModalOutcomesEventHistoryInfo from "../modalOutcomesEventHistoryInfo";
import ModalOutcomesEventHistoryEditing from "../modalOutcomesEventHistoryEditing";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import { MUTATION_DELETE_HISTORY_CUSTOM_FORMS_RECORDS } from "../../../../../graphql/mutations/UserToCustomForm";

const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function ModalOutcomesEventHistory({ 
  setSeeEventHistoryActive,
  patientID,
  processID,
  cmpID,
  setCreateEventActive
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");

  const [itemsActived, setItemsActived] = useState(null);
  const [editionActived, setEditionActived] = useState(null);
  const [activeConfirmationModal, setActiveConfirmationModal] = useState(false);
  const [formSuccess, setFormSuccess] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [deleteUserToCustomFormFields, {loading: deleteUserToCustomFormFieldsLoading}] = useMutation(
    MUTATION_DELETE_HISTORY_CUSTOM_FORMS_RECORDS
  );

  const formSuccessText = () => { 
    return formSuccess
    ? editionActived 
      ? t("globally.changesSaved")
      : t("globally.deletedSuccessfully")
    : t("globally.genericError")
  }

  useEffect(() => {
    function handleKeyDown(e) {
      // console.log(e.keyCode);
      // console.log(e);
      if (e.keyCode === 27) {
        setSeeEventHistoryActive(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const handleDeletionFunction = async () => {
    setFormLoading(true);
    
    try {
      await deleteUserToCustomFormFields({
        variables: {
          recordsIds: itemsActived.map(item => item[0].id),
        },
      });
      
      setFormLoading(false);
      setFormSuccess(true);
    } catch (err) {
      setFormLoading(false);
      setFormSuccess(false);
      console.log("Error updating user to process", err); 
    }
  }

  if(activeConfirmationModal)
    return (
      <S.ModalOutcomesEventHistory>
        <div className="modal-background" onClick={() => setActiveConfirmationModal(false)}>
        </div>
        <div className="modal-container modal-container--response">
          <ZaiaModalResponseContent
            button1={() => {
                if(editionActived === false) handleDeletionFunction();
                setEditionActived(null);
                setActiveConfirmationModal(false);
              }
            }
            button2={() => setActiveConfirmationModal(false)}
            formSuccess={false}
            button1Text={t("followUp.confirm")}
            button2Text={t("globally.cancel")}
            title={
              editionActived 
              ? t("globally.discardChanges") 
              : t("aiNavigator.outcomes.deleteAllInfo")
            }
            subtitle={editionActived === false && t("aiNavigator.outcomes.notUndone")}
            doubleButton={true}
          />
        </div>
      </S.ModalOutcomesEventHistory>
    )

  if(formSuccess || formSuccess === false)
    return (
      <S.ModalOutcomesEventHistory>
        <div className="modal-background" 
          onClick={() => {
            setFormSuccess(null);
            setEditionActived(null);
            setFormLoading(false);
            setItemsActived(null)
          }}>
        </div>
        <div className="modal-container modal-container--response">
          <ZaiaModalResponseContent
            button1={() => {
              setFormSuccess(null)
              setEditionActived(null);
              setFormLoading(false);
              setItemsActived(null)
            }}
            button1Text={t("globally.done")}
            formSuccess={formSuccess}
            title={formSuccessText()}
          />
        </div>
      </S.ModalOutcomesEventHistory>
    )

  if(formLoading) 
    return (
      <S.ModalOutcomesEventHistory>
        <div className="modal-background">
        </div>
        <div className="modal-container modal-container--loading">
          <ZaiaReaction
            zaiaImg={logoZaiaLoading}
            widthImg="150px"
          />
        </div>
      </S.ModalOutcomesEventHistory>
    )

  return (
    <S.ModalOutcomesEventHistory>
      <div className="modal-background" onClick={() => setSeeEventHistoryActive(false)}>
      </div>
      <div className="modal-container">
        <div className="header">
          <div className="header__text">
            <div className="header__note">{t("outcomes.modalOutcomesHistory.note")}</div>
            <div className="header__title">{t("outcomes.modalOutcomesHistory.title")}</div>
            {
              editionActived && <div className="header__editing">{t("outcomes.modalOutcomesHistory.editing")}</div>
            }
          </div>
          <div className="header__close" onClick={() => setSeeEventHistoryActive(false)}>
            <i className="header__close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        {
          !editionActived 
          ? <ModalOutcomesEventHistoryInfo
            patientID={patientID}
            processID={processID}
            cmpID={cmpID}
            setActiveConfirmationModal={setActiveConfirmationModal}
            setEditionActived={setEditionActived}
            setItemsActived={setItemsActived}
            editionActived={editionActived}
            itemsActived={itemsActived}
            setFormSuccess={setFormSuccess}
            setFormLoading={setFormLoading}
            setSeeEventHistoryActive={setSeeEventHistoryActive}
            setCreateEventActive={setCreateEventActive}
          />
          : <ModalOutcomesEventHistoryEditing
            patientID={patientID}
            processID={processID}
            setActiveConfirmationModal={setActiveConfirmationModal}
            itemsActived={itemsActived}
            setFormSuccess={setFormSuccess}
            setFormLoading={setFormLoading}
          /> 
        }
      </div>
    </S.ModalOutcomesEventHistory>
  );
}

export default ModalOutcomesEventHistory;
