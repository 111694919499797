import React from "react";
import * as S from "./CheckboxRound.style";

function CheckboxRound({ checkFunction, itemCheckbox, selectedItemId, fontSize }) {

  return (
    <S.CheckboxRoundDiv 
      fontSize={fontSize}
    >
      <i className={`icon-checkbox icon ${selectedItemId === itemCheckbox.id ? "zaia-icon-check" : "zaia-check-icon2"}`} onClick={() => checkFunction(itemCheckbox)}/>
      <div className="text-checkbox" onClick={() => checkFunction(itemCheckbox)}>{itemCheckbox.name}</div>
    </S.CheckboxRoundDiv>
  );
}

export default CheckboxRound;
