import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./NoteComplete.style";
import Popup from "reactjs-popup";

import * as P from "../../../../../utils/pipes";

function NoteComplete({
  maxView=false,
  setNoteSelected,
  noteSelected,
  setEditNoteActived
}) {

  const { t, i18n: { language }} = useTranslation("global"); 

  return (
    <S.NoteComplete
      maxView={maxView}
    >
      <div className="header">
        {
          !maxView &&
          <div className="header__back-button">
            <button className="back-button"
              onClick={() => { 
                setNoteSelected(null);
              }}
            >
              <i className="back-button__icon icon zaia-icono-atras"></i>
              <div className="back-button__text">
                {t("notes.backToNotes")}
              </div>
            </button>
            <Popup
              trigger={
                <div className="header__option">
                  <i className="header__option-icon icon zaia-icono-dots-horizontal"></i>
                </div>
              }
              keepTooltipInside
              nested
              // position={"right bottom"}
            >
              {
                close => (
                  <S.NoteOptions>
                    <div className="note-option"
                      onClick={() => {setEditNoteActived(noteSelected)}}
                    >
                      <div className="note-option__icon">
                        <i className="icon zaia-icono-editar"/>
                      </div>
                      <div className="note-option__text">
                        {t("globally.edit")}
                      </div>
                    </div>
                    <div className="note-option">
                      <div className="note-option__icon">
                        <i className="icon zaia-i-trash-can"/>
                      </div>
                      <div className="note-option__text">
                        {t("globally.delete")}
                      </div>
                    </div>
                  </S.NoteOptions>
                )
              }
            </Popup>
          </div>
        }
        {
          <div className="header__note-info">
            <div className="header__note-info-left">
              {
                !maxView &&
                <div className="program-info">
                  {t("globally.program")} <b>{P.dataTranslation(noteSelected.userToProcess.process.processName, language)}</b>
                </div>
              }
              {
                noteSelected.doctor &&
                <div className="written-by">
                  <div className="written-by__label">{t("notes.writtenBy")}</div>
                  <div className="written-by__name">
                    {
                      `${noteSelected.doctor.name} ${noteSelected.doctor.lastname}`
                    }
                  </div>
                </div>
              }
              <div className="note-date">
                <div className="note-date__label">{t("notes.noteDate")}</div>
                <div className="note-date__date">{P.longDate(noteSelected.created_at, t, language)}</div>
                <div className="note-date__time">{P.hoursIn12format(new Date(noteSelected.created_at).getHours(), new Date(noteSelected.created_at).getMinutes(), t, language)}</div>
              </div>
            </div>
            <div className="header__note-info-left">
              {
                maxView &&
                <Popup
                    trigger={
                      <div className="header__option">
                        <i className="header__option-icon icon zaia-icono-dots-horizontal"></i>
                      </div>
                    }
                    keepTooltipInside
                    nested
                    // position={"right bottom"}
                  >
                    {
                      close => (
                        <S.NoteOptions>
                          <div className="note-option"
                            onClick={() => {setEditNoteActived(noteSelected)}}
                          >
                            <div className="note-option__icon">
                              <i className="icon zaia-icono-editar"/>
                            </div>
                            <div className="note-option__text">
                              {t("globally.edit")}
                            </div>
                          </div>
                          <div className="note-option">
                            <div className="note-option__icon">
                              <i className="icon zaia-i-trash-can"/>
                            </div>
                            <div className="note-option__text">
                              {t("globally.delete")}
                            </div>
                          </div>
                        </S.NoteOptions>
                      )
                    }
                  </Popup>
              }
            </div>
          </div>
        }
      </div> 
      <div className="note">
        <div className="note__label">
          {t("globally.note")}
        </div>
        <div className="note__container">
          {noteSelected.descript}
        </div>
      </div>
    </S.NoteComplete>
  );
}

export default NoteComplete;
