// graphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_GROUP_CHAT_BY_ID } from "../graphql/queries/Chat";


export default function useChatVerification(chatId) {
  
  const {
    data: chatData,
    error,
    loading: chatDataLoading
  } = useQuery(
    QUERY_GET_GROUP_CHAT_BY_ID,
    { 
      variables: {
        chatId: chatId
      }
    }
  );

  return { 
    chatValid: chatData ? true : false,
    loadingValidation: chatDataLoading
  };
}