import React, { useState, useRef } from "react";
import ReCaptcha from "react-google-recaptcha";
import * as Yup from "yup";
import * as S from "./ResetPassword.style";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../contextAPI/StateProvider";
import Cookies from "js-cookie";

// components
import Header from "../header";
import ZaiaButton from "../../zaiaComponents/zaiaButton";
import ZaiaInputForm from "../../zaiaComponents/zaiaInputForm";
import CoverPage from "../coverPage";
import EmailSent from "../emailSent";
import ErrorMessage from "../errorMessage";

// supertokens
import { sendPasswordResetEmail } from "supertokens-auth-react/recipe/emailpassword";

const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY
const env = process.env.REACT_APP_ENV;

function ResetPassword() {
  const [t] = useTranslation("global");
  const [{ colorTheme },] = useStateValue();
  const resetPassCaptchaRef = useRef(null);
  const webView = Cookies.get('webView');

  const [errorMsg, setErrorMsg] = useState('');
  const [emailSent, setEmailSent] = useState('');
  const [emailSentActive, setEmailSentActive] = useState(false);
  const [activeErrorForm, setActiveErrorForm] = useState(false);
  const [loadingResetPass, setLoadingResetPass] = useState(false);  

  const handleSubmit = async (values, { resetForm }) => {
    if (env === 'test' && env === 'development') {
      const captchaToken = await resetPassCaptchaRef.current.executeAsync();
      if(captchaToken) {
        sendEmailClicked(values, resetForm, captchaToken);
      } else {
        setErrorMsg('globally.errorCaptcha');
        activateError();
        resetPassCaptchaRef.current.reset();
      }
      resetForm();
    } else {
      sendEmailClicked(values, resetForm);
    }
  };

  async function sendEmailClicked(values, resetForm, captchaToken) {
    setLoadingResetPass(true);
    try {
      let response = await sendPasswordResetEmail({
        formFields: [{
            id: "email",
            value: values.email
        }]
      });
      if (response.status === "FIELD_ERROR") {
        setLoadingResetPass(false);
        setErrorMsg(t('emailPassSent.errorEmailPassSent'));
        activateError();
        resetForm();
        if(captchaToken) resetPassCaptchaRef.current.reset();
      } else {
        setLoadingResetPass(false);
        setEmailSentActive(true);
        setEmailSent(values.email);  
        resetForm();
      }
    } catch (err) {
      setLoadingResetPass(false);
      setErrorMsg(t('globally.errorServer'));
      activateError();
      resetForm();
      if(captchaToken) resetPassCaptchaRef.current.reset();
    }
  }

  const activateError = () => {
    setActiveErrorForm(true);
    setTimeout(function() {
      setActiveErrorForm(false);      
    }, 5000);
  }

  if (emailSentActive) 
    return (
      <EmailSent
        email={emailSent}
        setEmailSent={setEmailSentActive}
        message1={t("emailPassSent.link")}
        message2={t("emailPassSent.checkLink")}
      />
    )

  return (
  <S.ResetPasswordDiv webView={webView}>
      <CoverPage/>
      <div className="resetPassword__right">
        {
          !webView &&
          <Header route="/" />
        }
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object({
            email: Yup.string()
              .email(t("register.validation.email"))
              .required(t("register.validation.required")),
          })}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <form onSubmit={props.handleSubmit} className="reset-pass-form">
              <>
                <S.ResetPasswordFormDiv>
                  <div className="reset-pass-container">
                    <ErrorMessage
                      errorMessage={errorMsg}
                      activeError={activeErrorForm}
                    />
                    <h2>{t("globally.resetPassword")}</h2>
                    <p>{t("resetPassword.subtitle")}</p>
                    <div className="resetPassword__email">
                      <h3 className="title-register">{t("home.email")}</h3>
                      <ZaiaInputForm
                        placeholder={t("globally.email")}
                        name="email"
                        type="email"
                        icon="icono-email"
                      />
                      {
                        env !== 'test' && env !== 'development' &&
                        <ReCaptcha
                          ref={resetPassCaptchaRef}
                          sitekey={reCaptchaKey}
                          size="invisible"
                          theme={colorTheme}
                        />
                      }
                      <ZaiaButton
                        type="submit"
                        marginTop="170"
                        shadow={true}
                        title={
                          loadingResetPass
                            ? `${t("globally.loading")}...`
                            : `${t("globally.send")}`
                        }
                      />
                    </div>
                  </div>
                </S.ResetPasswordFormDiv>
              </>
            </form>
          )}
        </Formik>
      </div>
    </S.ResetPasswordDiv>
  );
}

export default ResetPassword;
