import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./DashboardPatient.style";

// Components
import BoxInfoTimeFilter from "../boxInfoTimeFilter";
import FeelingsBoxInfo from "./components/feelingsBoxInfo";
import SymptomsBoxInfo from "./components/symptomsBoxInfo";
import MedicinesBoxInfo from "./components/medicinesBoxInfo";
import VitalsignsBoxInfo from "./components/vitalsignsBoxInfo";
import PatientHeader from "../../../shared/patientHeader";
import ListOfCommentCards from "./components/listOfCommentCards";
import PdfPatientInfo from "./components/pdfPatientInfo/PdfPatientInfo";

function DashboardPatient({ 
  patientID, 
  setSelectedBox, 
  setEventCalendarActive
}) {

  const { t }  = useTranslation("global");
  const [feelingsTime, setFeelingsTime] = useState();
  const [commentCardsTime, setCommentCardsTime] = useState();
  const [symptomsTime, setSymptomsTime] = useState();
  const [medicinesTime, setmedicinesTime] = useState();
  const [vitalsignsTime, setVitalsignsTime] = useState();

  return (
    <S.DashboardPatient>

      {/* <PdfPatientInfo patientID={patientID} /> */}

      <PatientHeader 
        patientID={patientID} 
        closeLink={"/patients"} 
        viewName={'patients'} 
        setEventCalendarActive={setEventCalendarActive}
      />
      <div className="cards-container">
        <BoxInfoTimeFilter
          icon="zaia-sintoma-bien"
          title={t("globally.feeling")}
          color="var(--blue-color)"
          colorTitle="var(--blue-color-200)"
          setTime={setFeelingsTime}
          time={feelingsTime}
        >
          <FeelingsBoxInfo time={feelingsTime} icon={"zaia-icono-sintomas"}/>
        </BoxInfoTimeFilter>
        <BoxInfoTimeFilter
          icon="zaia-icono-sintomas"
          title={t("globally.symptoms")}
          color="var(--blue-color)"
          colorTitle="var(--blue-color-200)"
          setTime={setSymptomsTime}
          time={symptomsTime}
        >
          <SymptomsBoxInfo time={symptomsTime} icon={"zaia-icono-sintomas"}/>
        </BoxInfoTimeFilter>
        <BoxInfoTimeFilter
          icon="zaia-icono-medicina"
          title={t("globally.medications")}
          color="var(--green-color)"
          colorTitle="var(--green-color-100)"
          setTime={setmedicinesTime}
          time={medicinesTime}
        >
          <MedicinesBoxInfo time={medicinesTime} icon={"zaia-icono-medicina"}/>
        </BoxInfoTimeFilter>
        <BoxInfoTimeFilter
          icon="zaia-icono-signos-vitales"
          title={t("globally.vitalSign")}
          color="var(--red-color)"
          colorTitle="var(--red-color-100)"
          setTime={setVitalsignsTime}
          time={vitalsignsTime}
        >
          <VitalsignsBoxInfo time={vitalsignsTime} icon={"zaia-icono-signos-vitales"}/>
        </BoxInfoTimeFilter>
        <BoxInfoTimeFilter
          color="var(--skyblue-box-headercolor)"
          colorTitle="var(--skyblue-box-headercolor)"
          icon="zaia-icono-home"
          title={t("globally.comments")}
          setTime={setCommentCardsTime}
          time={commentCardsTime}
        >
          <ListOfCommentCards
            initialDate={new Date(commentCardsTime?.startDate)}
            finishDate={new Date(commentCardsTime?.finishDate)}
            selectedPatient={patientID}
            setSelectedBox={setSelectedBox}
          />
        </BoxInfoTimeFilter>
      </div>
      
    </S.DashboardPatient>
  );
}

export default DashboardPatient;
