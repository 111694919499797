import React, { useEffect, useState } from "react";
import App from "../App";
import {
  ApolloClient,
  HttpLink,
  ApolloProvider,
  InMemoryCache,
  split
} from "@apollo/client";
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { useStateValue } from "../contextAPI/StateProvider";
import { darkTheme, lightTheme } from "../themes";

// Hooks
import useInfoToken from "../hooks/useInfoToken";

// Supertokens
import Session from "supertokens-auth-react/recipe/session";

// cookies
import Cookies from "js-cookie";
import { CookiesProvider } from 'react-cookie';

// components
import ZaiaSplash from "../pages/zaiaComponents/zaiaSplash";
import { ThemeProvider } from "styled-components";

function Apollo() {
  const [accessToken, setAccessToken] = useState();
  const [client, setClient] = useState();

  const [{ isAuth }, dispatch] = useStateValue();
  const [ existToken, setExistToken ] = useState(null);
  const { getTokenInfo } = useInfoToken();
  const [{ colorTheme }] = useStateValue();

  const getTokenInfoAsync = async () => {
    const accessToken = await Session.doesSessionExist();
    setExistToken(accessToken ? true : false);
    const tokenInfo = await getTokenInfo();
    updateAuthSession(tokenInfo);
  }

  useEffect(() => {
    const token = Cookies.get('accessToken');
    if (token) {
      Cookies.set('sFrontToken', token);
      Cookies.remove('accessToken');
    }
  }, []);

  useEffect(() => {
    getTokenInfoAsync();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]) 

  const updateAuthSession = (tokenInfo) => {
    if (!tokenInfo) {
      dispatch({
        type: "SET_AUTH",
        isAuth: false,
      });
      setAccessToken(null);
      setExistToken(false);
    } else {
      dispatch({
        type: "SET_AUTH",
        isAuth: true,
      });
      setAccessToken(tokenInfo.token);
      setExistToken(true);
    }
  }

  const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;

  useEffect(() => {
    const getHeaders = () => {
      const headers = {};
      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }
      return headers;
    };
  
    const httpLink = new HttpLink({
      uri: `https://${GRAPHQL_ENDPOINT}`,
      headers: getHeaders(),
    });
  
    const wsLink = new GraphQLWsLink(
      createClient({
        url: `wss://${GRAPHQL_ENDPOINT}`,
        connectionParams: () => {
          return { headers: getHeaders() };
        },
        // shouldRetry: true
      }),
    );
  
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === "OperationDefinition" &&
          definition.operation === "subscription"
        );
      },
      wsLink,
      httpLink
    );
  
  
    const client = new ApolloClient({
      link: splitLink,
      cache: new InMemoryCache()
    });

    setClient(client);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  

  // useEffect(() => {
  //   const authLink = setContext((_, { headers }) => {
  //   const token = localStorage.getItem('accessToken');
  //     if (token) {
  //       return {
  //         headers: {
  //           ...headers,
  //           authorization: 'Bearer ' + token,
  //         },
  //       };
  //     } else {
  //       return {
  //         headers: {
  //           ...headers,
  //         },
  //       };
  //     }
  //   });

  //   apolloConfig(authLink)
    
  // }, [accessToken]);

  


  // const apolloConfig = async (authLink) => {
  //   // await persistCache({
  //   //   cache,
  //   //   storage: window.localStorage
  //   // })
    

  if (!client) {
    return (
      <ThemeProvider theme={colorTheme === 'light' ? lightTheme : darkTheme} >
        <ZaiaSplash />
      </ThemeProvider>
    )
  }

  return (
    <ThemeProvider theme={colorTheme === 'light' ? lightTheme : darkTheme} >
      <ApolloProvider client={client}>
        <CookiesProvider>
          <App existToken={existToken} />
        </CookiesProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default Apollo;
