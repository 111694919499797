import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalMedication.style";

// graphQL
import { useQuery, useMutation } from "@apollo/client";
import { MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS } from "../../../../../../graphql/mutations/UserToProcess";

// Hooks
import ZaiaReaction from "../../../../../zaiaComponents/zaiaReaction";
import ZaiaButton from "../../../../../zaiaComponents/zaiaButton";
import ZaiaInput from "../../../../../zaiaComponents/zaiaInput";
import ZaiaModalResponseContent from "../../../../../zaiaComponents/zaiaModalResponseContent";
import { QUERY_GET_DRUGS_INFO, QUERY_GET_USER_TO_DRUG } from "../../../../../../graphql/queries/drugs";

import MedicationSearcher from "./components/medicationSearcher";
import MedicationPresentation from "./components/medicationPresentation";
import MedicationDosage from "./components/medicationDosage";
import MedicationFrecuency from "./components/medicationFrecuency";
import MedicationRange from "./components/medicationRange";
import MedicationSelector from "./components/medicationSelector";
import MedicationMeasure from "./components/medicationMeasure";
import { INSERT_USER_TO_DRUG, INSERT_USER_TO_DRUG_HISTORY } from "../../../../../../graphql/mutations/PatientActions";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`

function ModalMedication({ 
  setModalActive,
  patient,
  option=1
}) {

  const {
    t
  } = useTranslation("global");

  
  const {data: drugsList, loading: drugListLoading, error: drugListError} = useQuery(QUERY_GET_DRUGS_INFO);
  const [insertDrug, {loading: drugAddedLoading}] = useMutation(INSERT_USER_TO_DRUG);
  const [insertDrugHistory, {loading: drugHistoryAddedLoading}] = useMutation(INSERT_USER_TO_DRUG_HISTORY);

  const {data: drugsPatientList, loading: drugsPatientListLoading, error: drugsPatientListError} = useQuery(QUERY_GET_USER_TO_DRUG,
    {
      variables: {
        patientId: patient.id
      }
    }
  );

  const [descriptionSelected, setDescriptionSelected] = useState("");
  const [formSuccess, setFormSuccess] = useState(null); 
  const [summaryView, setSummaryView] = useState(false);

  const medicationFormInitial = [
    {
      medication: {
        value: null,
        text: ""
      },
      presentation: {
        value: null,
        text: "",
      },
      quantity: {
        value: null,
        text: ""
      },
      dose: {
        value: null,
        text: ""
      },
      interval: {
        value: null,
        text: ""
      },
      startingHour: {
        value: null,
        text: ""
      },
      rangeStart: {
        value: null,
        text: ""
      },
      rangeFinish: {
        value: null,
        text: ""
      },
      description: {
        value: null,
        text: ""
      },
    },
    // {
    //   medication: {
    //     value: null,
    //     text: ""
    //   },
    //   measure: {
    //     value: null,
    //     text: ""
    //   },
    //   description: {
    //     value: null,
    //     text: ""
    //   },
    // }
  ]

  const medicationTabs = [
    {
      id: 1,
      tabName: t("globally.createAlert"),
      inputs: [
        {
          id: 1,
          placeholder: t("healthAddData.medication.selectMedication"),
          icon: "zaia-i-medication",
          form: ["medication"]
        },
        {
          id: 2,
          placeholder: t("healthAddData.medication.medicationPresentation"),
          icon: "zaia-i-presentation",
          form: ["presentation"]
        },
        {
          id: 3,
          placeholder: t("healthAddData.medication.dosage"),
          icon: "zaia-i-medication",
          form: ["quantity", "dose"]
        },
        {
          id: 4,
          placeholder: t("globally.frequency"),
          icon: "zaia-i-frequency",
          form: ["interval", "startingHour"]
        },
        {
          id: 5,
          placeholder: t("globally.range"),
          icon: "zaia-i-time",
          form: ["rangeStart", "rangeFinish"]
        }
      ]
    },
    // {
    //   id: 2,
    //   tabName: "Medication Taking",
    //   inputs: [
    //     {
    //       id: 1,
    //       placeholder: "Select Medication",
    //       icon: "zaia-i-medication",
    //       form: ["medication"]
    //     },
    //     {
    //       id: 2,
    //       placeholder: "Taking Medication",
    //       icon: "zaia-i-presentation",
    //       form: ["measure"]
    //     },
    //   ]
    // }
  ]

  const [medicationForm, setMedicationForm] = useState(medicationFormInitial);
  const [tabSelected, setTabSelected] = useState(medicationTabs[option - 1]);
  const [inputSelected, setInputSelected] = useState(medicationTabs[option - 1].inputs[0])
  
  const goNextInput = () => {
    if(inputSelected.id < medicationTabs[option - 1].inputs.length - 1) {
      setInputSelected(medicationTabs[option - 1].inputs[inputSelected.id])
    }
  }  

  const changeFormField = (changesList) => {
    const medicationFormTemp = JSON.parse(JSON.stringify(medicationForm));
    for (const change of changesList) {
      if(medicationFormTemp[tabSelected.id - 1][change.fieldName]) {
        medicationFormTemp[tabSelected.id - 1][change.fieldName].value = change.value;
        medicationFormTemp[tabSelected.id - 1][change.fieldName].text = change.text;
      }
    }
    setMedicationForm(medicationFormTemp);
  }
  
  const handleSubmit = async () => {  
    if(summaryView === false) {
      setSummaryView(true);
    } else {
      if(tabSelected.id === 1) {
        insertUserToDrugFunction();
      } else {
        insertUserToDrugHistoryFunction()
      }
    }
  };

  const getUTCTime = (time) => {
    let currentDate = new Date();

    // Extract hours, minutes, and seconds from the input time
    const [hours, minutes, seconds] = time.split(':');
    
    // Set the time on the current date
    currentDate.setHours(parseInt(hours, 10));
    currentDate.setMinutes(parseInt(minutes, 10));
    currentDate.setSeconds(seconds ? parseInt(seconds, 10) : 0);
    // Convert to UTC and format the result
    const utcTime = currentDate.toISOString().slice(11, 19);

    return utcTime;
  }

  const insertUserToDrugFunction = async () => {
    const form = await medicationForm[tabSelected.id - 1];
    const UTCTime = getUTCTime(form.startingHour.value);
    const dataMedication = {
      drugId: form.medication.value === 0 ? 158 : form.medication.value,
      quantity: 1,
      startTreatment: form.rangeStart.value,
      endTreatment: form.rangeFinish.value === true ? null : form.rangeFinish.value,
      startHour: UTCTime,
      dose: Number(form.quantity.value),
      doseShape: form.presentation.value,
      doseType: form.dose.value,
      frequency: Number(form.interval.value),
      frequencyType: "hours",
      isPeriodic: true,
      isNotify: true,
      isFix: false,
      comment: form.description.value,
      displayName: form.medication.text,
      userId: patient.id,
      daysOfWeek: [0,1,2,3,4,5,6]
    }

    try {
      await insertDrug({
        variables: {
          data: dataMedication
        }
      });
      setFormSuccess(true)
    } catch (err) {
      setFormSuccess(false)
    }
  }

  const insertUserToDrugHistoryFunction = async () => {
    const form = await medicationForm[tabSelected.id - 1];
    const userToDrugSelected = drugsPatientList?.user[0].userToDrugs.find((userToDrug) => userToDrug.id === form.medication.value)
;    const dataMedication = {
      doseType: userToDrugSelected.doseType,
      dose: userToDrugSelected.dose,
      delay: 0,
      userToDrugId: userToDrugSelected.id,
      isTaken: form.measure.value,
      comment: form.description.value,
      userId: patient.id
    }
    try {
      await insertDrugHistory({
        variables: {
          data: dataMedication
        },
      });
      setFormSuccess(true)
    } catch (err) {
      setFormSuccess(false)
    }
  }

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setModalActive(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const selectedMedication = useMemo(() => {
    return drugsList?.drug.find((drug) => drug.id === medicationForm[0].medication.value)
  }, [medicationForm])

  const inputsVerification = useMemo(() => {

    let verification = true;
  
    tabSelected?.inputs.forEach((input) => {      
      if(input.form.filter((form) =>  medicationForm[tabSelected.id - 1][form].value !== null).length !== input.form.length) {
        verification = false;
      };
    })
    
    const descriptionValue = medicationForm[tabSelected.id - 1].description.value;

    if( descriptionValue === "" || descriptionValue === null) {
      verification = false;
    }

    return verification;
    
  }, [tabSelected, medicationForm])

  const getDinamicInputsViews = () => {

    if(summaryView) {
      return (
        <div className="custom-form-inputs">
          <div className="summary">
            <div className="summary-title">
              {t("globally.summary")}
            </div>
            <div className="summary-items">
              {
                tabSelected.inputs.map((input) => {  
                  return (
                    <div className="summary-item" key={input.id}>
                      <div className="summary-item__title">
                        <i className={'summary-item__icon icon ' + input.icon} />
                        <div className="summary-item__name input-text__input-name">{input.placeholder}</div>
                      </div>
                      <div className="summary-item__info">
                        {input.form.map((form) => {return medicationForm[tabSelected.id - 1][form]?.text})}
                      </div>
                    </div>
                  )
                })
              }
              <div className="summary-item">
                <div className="summary-item__title">
                  <i className={'summary-item__icon icon zaia-i-message-outline'} />
                  <div className="summary-item__name input-text__input-name">{t("globally.description")}</div>
                </div>
                <div className="summary-item__info">
                  {medicationForm[tabSelected.id -1].description.text}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    if (tabSelected.id === 1) {
      
      if (drugListError)
      return (
        <div className="custom-form-inputs">
          <ZaiaReaction
            zaiaImg={logoZaiaAlert}
            text={"Error loading medications"}
            sizeText="20px"
            textColor="var(--blue-color)"
            widthImg="110px"
          />
        </div>
      )
    
    
      if (drugListLoading)
      return (
        <div className="custom-form-inputs">
          <ZaiaReaction
            zaiaImg={logoZaiaLoading}
            sizeText="20px"
            textColor="var(--blue-color)"
            widthImg="110px"
          />
        </div>
      )

      return(
        <div className="custom-form-inputs">
          {
            <div className={`medication-inputs ${inputSelected.id === 1 && 'medication-inputs--selected'}`}>
              <MedicationSearcher
                selectedMedication={medicationForm[0].medication}
                changeFormField={changeFormField}
                drugsList={drugsList?.drug}
                goNextInput={goNextInput}
              />
            </div>
          }
          {
            <div className={`medication-inputs ${inputSelected.id === 2 && 'medication-inputs--selected'}`}>
              <MedicationPresentation
                selectedPresentation={medicationForm[0].presentation}
                changeFormField={changeFormField}
                presentationList={drugsList?.enDoseShape}
                goNextInput={goNextInput}
              />
            </div>
          }
          {
            <div className={`medication-inputs ${inputSelected.id === 3 && 'medication-inputs--selected'}`}>
              <MedicationDosage
                selectedMedication={medicationForm[0].medication}
                selectedQuantity={medicationForm[0].quantity}
                selectedDose={medicationForm[0].dose}
                changeFormField={changeFormField}
                doseList={
                  medicationForm[0]?.medication?.value === 0 ? 
                  drugsList?.enDoseType :
                  selectedMedication?.drugDoses
                }
                goNextInput={goNextInput}
              />
            </div>
          }
          {
            <div className={`medication-inputs ${inputSelected.id === 4 && 'medication-inputs--selected'}`}>
              <MedicationFrecuency
                selectedInterval={medicationForm[0].interval}
                selectedStartingHour={medicationForm[0].startingHour}
                changeFormField={changeFormField}
                frecuencyList={drugsList?.enFrequencyType}
                goNextInput={goNextInput}
              />
            </div>
          }
          {
            <div className={`medication-inputs ${inputSelected.id === 5 && 'medication-inputs--selected'}`}>
              <MedicationRange
                selectedRangeStart={medicationForm[0].rangeStart}
                selectedRangeFinish={medicationForm[0].rangeFinish}
                // changeFormFieldDates={changeFormFieldDates}
                changeFormField={changeFormField}
              />
            </div>
          }
        </div>
      )
    }
    
    if (tabSelected.id === 2) {

      if (drugsPatientListError)
      return (
        <div className="custom-form-inputs">
          <ZaiaReaction
            zaiaImg={logoZaiaAlert}
            text={"Error loading medications"}
            sizeText="20px"
            textColor="var(--blue-color)"
            widthImg="110px"
          />
        </div>
      )
    
    
      if (drugsPatientListLoading)
      return (
        <div className="custom-form-inputs">
          <ZaiaReaction
            zaiaImg={logoZaiaLoading}
            sizeText="20px"
            textColor="var(--blue-color)"
            widthImg="110px"
          />
        </div>
      )
      
      return(
        <div className="custom-form-inputs">

          <div className={`medication-inputs ${inputSelected.id === 1 && 'medication-inputs--selected'}`}>
            <MedicationSelector
              selectedMedication={medicationForm[1].medication}
              changeFormField={changeFormField}
              drugList={drugsPatientList?.user[0].userToDrugs}
            />
          </div>

          <div className={`medication-inputs ${inputSelected.id === 2 && 'medication-inputs--selected'}`}>
            <MedicationMeasure
              selectedMeasure={medicationForm[1].measure}
              changeFormField={changeFormField}
            />
          </div>

        </div>
      )    
    }
  }

  if(
    drugAddedLoading ||
    drugHistoryAddedLoading
  ) 
    return(
      <S.ModalMedication>
        <div className="modal-background">
        </div>
        <div className="modal-container modal-container--response">
          <ZaiaReaction
            widthImg={"150px"}
            zaiaImg={logoZaiaLoading}
            padding={"200px 0px"}
          />
        </div>
      </S.ModalMedication>
    )

  if(formSuccess)
    return(
      <S.ModalMedication>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container modal-container--response">
          <ZaiaModalResponseContent
            button1={() => setModalActive(null)}
            formSuccess={formSuccess}
            button1Text={t("globally.done")}
            title={tabSelected.id === 1 ? t("healthAddData.medication.successfulAlertCreation") : t("healthAddData.medication.failedAlertCreation")}
          />
        </div>
      </S.ModalMedication>
    )

  if(formSuccess === false)
    return(
      <S.ModalMedication>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container modal-container--response">
          <ZaiaModalResponseContent
            button1={() => setModalActive(null)}
            formSuccess={formSuccess}
            title={t("globally.errorForm")}
            subtitle={tabSelected.id === 1 ? t("healthAddData.medication.successfulRecordCreation") : t("healthAddData.medication.failedRecordCreation")}
            button1Text={t("globally.accept")}            
          />
        </div>
      </S.ModalMedication>
    )

  return (
    <S.ModalMedication>
      <div className="modal-background" onClick={() => setModalActive(false)}>
      </div>
      <div className="modal-container">
        <div className="header">
          <div className="header__text">
            <div className="header__title">{t("globally.medication")}</div>
            <div className="header__subtitle">{t("healthAddData.medication.subtitle")}</div>
          </div>
          <div className="header__close" onClick={() => setModalActive(false)}>
            <i className="header__close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        <div className="modal-tabs">
          {
            medicationTabs.map((tab) => {
              return (
                <div
                  key={tab.id} 
                  className={`tab ${tab.id === tabSelected?.id && "tab tab--selected"}`}
                  onClick={() => 
                    {
                      setSummaryView(false)
                      setTabSelected(tab)
                    }
                  }
                >
                  {
                    tab.tabName
                  }
                </div>
              )
            })
          }
        </div>
        <div className="inputs">
          <div className="rows">
            <div className="principal-input">
              <MedicationInputs
                inputsList={tabSelected.inputs}
                patient={patient}
                setDescriptionSelected={setDescriptionSelected}
                descriptionSelected={descriptionSelected}
                inputSelected={inputSelected}
                setInputSelected={setInputSelected}
                medicationForm={medicationForm[tabSelected.id - 1]}
                changeFormField={changeFormField}
                setSummaryView={setSummaryView}
              />
            </div>
            {getDinamicInputsViews()}
          </div>  
        </div>
        <div className="button-container">
          <div onClick={() => handleSubmit()}>
            <ZaiaButton
              type="button"
              option="primary"
              marginTop="5"
              backgroundColor={ inputsVerification ? "#17AF54" : "#E0E6ED"}
              title={summaryView ? tabSelected.id === 1 ? t("globally.createAlert") : t("healthAddData.medication.recordMedicine") : t("globally.done")}
              // title={t("outcomes.tableOutcomes.modalNewEvent.add")}
              borderRadius="5"
              fontSize="32.25"
              height="64"
              fontFamily={"Nunito Sans"}
              shadow={false}
              border={false}
              color={inputsVerification ? "#FFFFFF" : "#8A939D"}
              disabled={!inputsVerification}
            />
          </div>
        </div>
      </div>
    </S.ModalMedication>
  );
}

const MedicationInputs = ({
  patient,
  inputsList, 
  inputSelected,
  setInputSelected,
  medicationForm,
  changeFormField,
  setSummaryView
}) => {

  const {
    t
  } = useTranslation("global");

  return (
    <div className="principal-input__container">      
      <div className="input-box">
        <div className="input-box__label">
          <i className="input-box__label-icon icon zaia-i-person"/>
          {t("globally.patient")}
        </div>
        <div className="input-value">
          {`${patient?.name} ${patient?.lastname}`}
        </div>
      </div>
      {
        inputsList.map((input) => {
          const filledVerification = input.form.filter((form) => medicationForm[form].value !== null).length === input.form.length;

          return (
            <div className="input-box__input" key={input.id} onClick={() => {
              setInputSelected(input)
              setSummaryView(false);
            }}>
              <S.InputOption
                selected={inputSelected?.id === input.id}
                filled={filledVerification}
              >
                <i className={'input-icon icon ' + input.icon} />
                <div className="input-text">
                  {input.placeholder}
                  <b>{input.form.map((form) => {return medicationForm[form].text})}</b>
                </div>
                {
                  !filledVerification 
                  ? <i className={'input-icon-arrow icon zaia-icono-dropdown-light'} />
                  : <i className={'input-icon-check icon zaia-i-check-1'} />
                }
                { 
                  inputSelected?.id === input.id &&
                  <div className="shape-container">
                    <div className="shape">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="32" viewBox="0 0 25 32" fill="none">
                        <path d="M0 31.5C-2.16635e-07 26.544 4.32006 22.5263 9.64912 22.5263L15.3509 22.5263C20.6799 22.5263 25 26.544 25 31.5L25 0.499999C25 5.45603 20.6799 9.47368 15.3509 9.47368L9.64912 9.47368C4.32006 9.47368 -1.13842e-06 5.45603 -1.35505e-06 0.5L0 31.5Z" fill="#6C6BCC"/>
                      </svg>
                    </div>
                  </div>
                }
              </S.InputOption>
            </div>
          )
        })
      }

      <div className="input-box input-box--last">
        <div className="input-box__label">
          <i className="input-box__label-icon icon zaia-i-message-outline"/>
          {t("healthAddData.addNotes")}
        </div>
        <ZaiaInput
          textColor={"#36369B"}
          borderColor={"#36369B"}
          placeholderTextColor={"#878792"}
          fontSize="15px"
          placeholder={t("globally.eventDescription")}
          type="text"
          name="description"
          padding={"20px"}
          borderRadius={"14px"}
          textarea={true}
          errorOption={false}
          height={"100%"}
          getText={(e) => 
            changeFormField(
              [
                {
                  value: e,
                  text: e,
                  fieldName: "description"
                }
              ]
            )
          }
          value={medicationForm.description.text}
        />
      </div>
    </div>
  )
}


export default ModalMedication;
