import { gql } from "@apollo/client";

export const QUERY_GET_USER_TO_PROCESS_BY_STAGE = gql`
  query QUERY_GET_USER_TO_PROCESS_BY_STAGE(
    $patientId: uuid, 
    $processId: Int, 
    $stagesIds: [String!]
  ) {
    userToProcessHistory(where: {userToProcess: {userId: {_eq: $patientId}}, processId: {_eq: $processId}, stageId: {_in: $stagesIds}}, order_by: {created_at: desc}, limit: 1) {
      id
      processId
      stageId
      doctorId
      descript
      created_at
      userToProcessId
    }
  }
`

export const QUERY_GET_USER_TO_PROCESS_HISTORY = gql`
  query QUERY_GET_USER_TO_PROCESS_HISTORY($patientId: uuid) {
    userToProcess(where: {userId: {_eq: $patientId}}) {
      descript
      id
      created_at
      isAcepted
      process {
        descript
        nameKey
        processName
      }
      processStage {
        id
        stageName
      }
      userToProcessHistories(order_by: {created_at: desc}) {
        id
        processId
        stageId
        doctorId
        descript
        created_at
        userToProcessId
        latestCustomFormId
        processStage {
          id
          stageName
        }
        doctor {
          name
          lastname
          picture
          gender
        }
      }
      userToProcessCalendars {
        created_at
        dateTime
        descript
        id
        isAccepted
        isNotify
        name
        place
        user {
          documentNumber
          email
          lastname
          name
          picture
          documentType
        }
      }
    }
  }
`

export const QUERY_GET_USER_TO_PROCESS_HISTORY_FILTERS = gql`
  query QUERY_GET_USER_TO_PROCESS_HISTORY(
    $patientId: uuid, 
    $processId: Int,
    $initialDate: timestamptz, 
    $finalDate: timestamptz, 
  ) {
    userToProcessHistory(where: {created_at: {_gte: $initialDate, _lte: $finalDate}, userToProcess: {userId: {_eq: $patientId}}, processId: {_eq: $processId}}, order_by: {created_at: desc}) {
      id
      processId
      stageId
      doctorId
      descript
      created_at
      userToProcessId
      latestCustomFormId
      customFormFieldChanges
      processStage {
        id
        stageName
      }
      doctor {
        name
        lastname
        picture
        gender
      }
    }
  }
`

export const QUERY_GET_USER_TO_PROCESS_HISTORY_BY_PATIENT = gql`
  query QUERY_GET_USER_TO_PROCESS_HISTORY_BY_PATIENT(
    $patientId: uuid!
  ) {
    userToProcessHistory(where: {
      userToProcess: {
        userId: {
          _eq: $patientId
        }
      }, 
    }
    order_by: {
      created_at: desc
    }
  ) {
      id
      processId
      stageId
      doctorId
      descript
      created_at
      userToProcessId
      latestCustomFormId
      customFormFieldChanges
      userToProcess {
        descript
        id
        created_at
        isAcepted
        process {
          descript
          nameKey
          processName
        }
      }
      processStage {
        id
        stageName
      }
      doctor {
        name
        lastname
        picture
        gender
      }
    }
  }
`