import React, { useMemo } from "react";
import * as S from "./CmpScrollMultipleOptionList.style";
import { useTranslation } from "react-i18next";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

// pipes
import * as P from "../../../../../../../utils/pipes";
import useChatVerification from "../../../../../../../hooks/useGetListOptions";

function CmpScrollMultipleOptionList({
  item,
  insertfunction,
  customFormChangesList,
  customFormChangeItem,
  customFormFields,
  isHistorical,
}) {
  const {
    i18n: { language },
  } = useTranslation("global");

  const itemParent = useMemo(() => {
    const itemParent = customFormChangesList.find(
      (change) => change.customFormFieldId === item?.customFormFieldParentId
    );
    return itemParent;
  }, [item, customFormChangesList]);

  const {listOptions} = useChatVerification(item, itemParent, customFormFields, isHistorical);

  const changeOptionItem = (fieldOption) => {
    const userToCustomFormField = {
      customFormFieldId: item?.id,
      parentKey: item?.customFormTypeField.fieldOptions.find(
        (field) => field.key === fieldOption.key
      ).parentKey,
      customFormFieldParentId: item?.customFormFieldParentId,
      value: P.dataTranslation(fieldOption.names, language),
      key: fieldOption.key,
    };
    insertfunction(userToCustomFormField);
  };

  const optionKey = customFormChangeItem && customFormChangeItem?.key;  

  const carouselButton = (position, disabled) => {
    return (
      <S.CarouselButton position={position} disabled={disabled} type="button">
        <i
          className={`icon ${
            position === "prev" ? "zaia-icono-atras" : "zaia-i-next"
          }`}
        />
      </S.CarouselButton>
    );
  };

  return (
    <S.CmpScrollMultipleOptionList active={true}>
      <div className="box-name">
        {P.dataTranslation(item?.fieldName, language)}
      </div>
      <div className="box-states">
        <AliceCarousel
          disableDotsControls
          autoWidth
          innerWidth
          renderPrevButton={(e) => carouselButton("prev", e.isDisabled)}
          renderNextButton={(e) => carouselButton("next", e.isDisabled)}
        >
          {listOptions.map((itemOption) => {
            return (
              <div className="box-state-container" key={itemOption.id}>
                <div
                  className={`box-state ${
                    itemOption.key === optionKey ? "box-state--active" : null
                  }`}
                  onClick={() =>
                    itemOption.key === optionKey
                      ? null
                      : changeOptionItem(itemOption)
                  }
                >
                  {P.dataTranslation(itemOption.names, language)}
                </div>
              </div>
            );
          })}
        </AliceCarousel>
      </div>
    </S.CmpScrollMultipleOptionList>
  );
}

export default CmpScrollMultipleOptionList;
