import React, { useState, useEffect, useContext, useRef } from "react";
import * as S from "./Box.style";
import { useTranslation } from "react-i18next";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import { ThemeContext } from 'styled-components';
import { getHexOpacity } from "../../../../../utils/pipes";

// Pipes
import * as P from "../../../../../utils/pipes";

// Assets
const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;

function Box({ 
  cardsInfo,
  filterSelect,

}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const [cardStyles, setColors] = useState({});
  const theme = useContext(ThemeContext);
  const [expandComment, setExpandComment] = useState(false);
  const commentSpace = useRef(null);
  const [showExpandButton, setShowExpandButton] = useState(false);

  console.log("dsfasdfasdf", cardsInfo);
  

  const levels = [
    {
      id: 1,
      name: t("globally.mild"),
      primaryColor: theme.primaryCardMildReport,
      secondaryColor: theme.secondaryCardMildReport+getHexOpacity(0.55),
      onCard: theme.onCardMildReport,
      onCardGrey: theme.onCardGreyMildReport,
      // boxShadow: theme.boxShadowMildReport
    },
    {
      id: 2,
      name: t("globally.medium"),
      primaryColor: theme.primaryCardAvarageReport,
      secondaryColor: theme.secondaryCardAvarageReport+getHexOpacity(0.55),
      onCard: theme.onCardAvarageReport,
      onCardGrey: theme.onCardGreyAvarageReport,
      // boxShadow: theme.boxShadowAvarageReport
    },
    {
      id: 3,
      name: t("globally.severe"),
      primaryColor: theme.primaryCardSeriousReport,
      secondaryColor: theme.secondaryCardSeriousReport+getHexOpacity(0.55),
      onCard: theme.onCardSeriousReport,
      onCardGrey: theme.onCardGreySeriousReport,
      // boxShadow: theme.boxShadowSeriousReport
    },
    {
      id: 4,
      name: t("globally.comments"),
      primaryColor: theme.primaryCardComment,
      secondaryColor: theme.secondaryCardComment+getHexOpacity(0.55),
      onCard: theme.onCardComment,
      onCardGrey: theme.onCardGreyComment,
      // boxShadow: theme.boxShadowComment
    },
  ]

  useEffect(() => {
    let cardStyle = {
      primaryColor: 'grey', 
      secundaryColor: 'black', 
      icon:'',
      title: ''
    };
    switch (1) {
      // symptoms
      case 1: 
        cardStyle.primaryColor = theme.secondarySectionSypmtoms;
        cardStyle.secundaryColor = theme.secondarySectionSypmtoms;
        cardStyle.icon = 'zaia-icono-sintomas';
        cardStyle.title = t("dashboard.boxes.symptom.title");
        break;
      // medicines
      case 2: 
        cardStyle.primaryColor = theme.secondarySectionMedicines;
        cardStyle.secundaryColor = theme.secondarySectionMedicines;
        cardStyle.icon = 'zaia-icono-medicina';
        cardStyle.title = t("dashboard.boxes.medicine.title");
        break;
      // vital signs 
      case 3: 
        cardStyle.primaryColor = theme.secondarySectionVitalSigns;
        cardStyle.secundaryColor = theme.secondarySectionVitalSigns;
        cardStyle.icon = 'zaia-icono-signos-vitales';
        cardStyle.title = t("dashboard.boxes.vitalSigns.title");
        break;
      // profile
      case 4: 
        cardStyle.primaryColor = theme.secondarySectionProfile;
        cardStyle.secundaryColor = theme.secondarySectionProfile;
        cardStyle.icon = 'zaia-icono-perfil';
        cardStyle.title = t("dashboard.boxes.profile.title");
        break;   
      // calendar   
      case 5: 
        cardStyle.primaryColor = theme.secondarySectionCalendar;
        cardStyle.secundaryColor = theme.secondarySectionCalendar;
        cardStyle.icon = 'zaia-icono-calendario';
        cardStyle.title = t("dashboard.boxes.calendar.title");
        break;
      default: break;
    }
    setColors(cardStyle);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getBoxSubtitle(type, description, t) {
    switch (type) {
      case 1:
        return (
          <p className="box__userInfoSubtitle">
            {t("dashboard.boxes.symptom.text1")} 
              {" "}
                <strong>{description}</strong>
              {" "}
            {t("dashboard.boxes.symptom.text2")}
          </p>
        );
      case 2:
        return (
          <p className="box__userInfoSubtitle">
            {t("dashboard.boxes.medicine.text")} 
            {" "}
            <strong>{description}.</strong>
          </p>
        );
      case 3:
        return (
          <p className="box__userInfoSubtitle">
            {t("dashboard.boxes.vitalSigns.text")} 
            {" "}
            <strong>{description}.</strong>
          </p>
        );
      case 4:
        return (
          <p className="box__userInfoSubtitle">
            {t("dashboard.boxes.profile.text")}
          </p>
        );
      case 5:
        return (
          <p className="box__userInfoSubtitle">
            {t("dashboard.boxes.calendar.text")} 
            {" "}
            <strong>{description}.</strong>
          </p>
        );
      default:
        return null;
    }
  }

  return (
    <S.Box 
      // type={type}
      // typeBoxColor={cardStyles.secundaryColor}
      // primaryColor={levels[`${levelReport-1}`].primaryColor}
      // secundaryColor={levels[`${levelReport-1}`].secondaryColor}
      // // onCard={levels[`${levelReport-1}`].onCard}
      // // onCardGrey={levels[`${levelReport-1}`].onCardGrey}
      // boxShadow={levels[`${levelReport-1}`].boxShadow}
      // completeBox={completeBox}
      // selected={false}
    >
      <table>
        {
          cardsInfo
          ?.slice(0)
          .reverse()
          .map((dataCard) => {

            if (!dataCard) {
              return null;
            }
            
            if(!(!filterSelect || (filterSelect === dataCard.type))) {
              return null;
            }

            const { id, user, orderDate, modifiedDate, userToDrug, description, type, reportlevel } = dataCard;
            const patientName = `${user?.name} ${user?.lastname}`;
            const orderDateObj = new Date(orderDate);
            const modifiedDateObj = modifiedDate ? new Date(modifiedDate) : null;

            return (
              <tr>
                <td>
                  <div className="patient-name">
                    <i className={`patient-name__icon-selection icon  ${false? "zaia-i-checkbox-on" : "zaia-i-checkbox-off"}`} />
                    <ZaiaImage 
                      img={user?.img} 
                      alt="" 
                      size={31} 
                      defaultImg={defaultUser} 
                      rounded
                    />
                    <div className="patient-name__text">{patientName}</div>
                  </div>
                </td>
                <td>
                  <div className="card-info">
                    {
                      type === 1
                      ? <div className="card">
                        <i className="card__icon icon zaia-i-message-outline"></i>
                        <div className="card__info">
                          <div className="card__title">
                            <div className="card__title-text">Comentario sobre el medicamento</div>
                            <div className="card__title-date">{P.hoursIn12format(orderDateObj.getHours(), orderDateObj.getMinutes())}</div>
                          </div>
                          <div className="card__comment">
                          <div className="comment-container"
                            style={{height: expandComment ? "auto" : "120px"}}
                          >
                            <div className="comment-space"
                              ref={commentSpace}
                            >
                              {"I really don't mean to come off as a broken record singing this band's praises, but I do believe we've got a surprising new high point for Osees on our hands. No guitar in sight, just grindy layers of synth and hints of sax to fill it all out, and it all rips just as hard."}
                            </div>
                          </div>
                          {
                            showExpandButton && 
                            <div className="expand-comment"
                              onClick={() => {setExpandComment(!expandComment)}}
                            >
                              {
                                expandComment ? t("globally.showLess") : t("globally.showMore")
                              }
                            </div>
                          }
                          </div>
                        </div>
                      </div>
                      : <div className="card">
                        <div className="card__left">
                          <div className="card__date">{P.shortDate(orderDateObj, t, true, language) + " " + P.hoursIn12format(orderDateObj.getHours(), orderDateObj.getMinutes())}</div>
                          <div className={`card__category ${true && "card__category--selected"}`}>
                            <div className="card__level">
                              <i className="card__level-icon icon zaia-i-health-indicator"></i>
                              <div className="card__level-text">{}</div>
                            </div>
                            <div className="card__type">{levels[`${reportlevel-1}`].name}</div>
                          </div>
                        </div>
                        <div className="card__right">
                          {
                            type === 1 
                            ? <div className="card__label">El paciente muestra alteraciones en:</div>
                            : <div className="card__label">Dolor de cabeza leve:</div>
                          }
                          {
                            type === 1
                            ? <div className="card__description">El paciente muestra anomalías <b>Dolor (8 Eva)</b>.</div>
                            : <div className="card__description"><b>Frecuencia cardiaca (110 bpm)</b>.</div>
                          }
                        </div>
                      </div>
                    }
                  </div>
                </td>
                <td>
                  <div className="ai-navigator">
                    <div className={`ai-navigator__button ${false && "ai-navigator__button--selected"}`}>
                      AI Navigator
                    </div>
                    <div className="ai-navigator__checked">
                      {
                        true
                        ? <div className="ai-navigator__checked-text">Esto no es una alerta</div>
                        : <div className="ai-navigator__checked-text ai-navigator__checked-text--checked">
                          Revisada
                        </div>
                      }
                      {
                        true
                        ? <i className="ai-navigator__checked-icon icon zaia-i-alerts ~changeicon"></i>
                        : <i className="ai-navigator__checked-icon icon zaia-i-down ~changeicon"></i>
                      }
                    </div>
                  </div>
                </td>
              </tr>
            )
          })
        }
      </table>


      {/* <div className="box__header">
        <div className="box__headerProfile">
          <div className="box__headerIcon">
            <div className={'icon ' + cardStyles.icon}></div>
          </div>
          <div className="box__headerTitle">
            {cardStyles.title}
            <p className="box__headerDate">{time}</p>
          </div>
        </div>
        <div className="box__headerTime">
          <p>{date}</p>
        </div>
      </div>
      <div className="box__userInfo">
        {
          type === 4 || type === 5 ? null :
          <div className="box__userInfoHeader">
            <div>
              <ZaiaImage
                size={25}
                img={patientPhoto}
                defaultImg={userDefaultImage}
                rounded={true}
              />
              <p>{patient}</p>
            </div>
            <button>{levels[`${levelReport-1}`].name}</button>
          </div>
        }
        { 
          levelReport === 4 ?
          <p className="box__userInfoSubtitle">{
            t("dashboard.boxes.comment.text") 
            + ": "}
            <strong>{description}{type === 1 && dateModified && timeModified ? '' : '.'}</strong>
            {
              type === 1 && dateModified && timeModified
              ? <>
                {" " + t("dashboard.boxes.comment.textDate") + " "}
                <strong>{dateModified}</strong>
                {" " + t("dashboard.boxes.comment.textTime") + " "}
                <strong>{timeModified}</strong>
              </>
              : ''
            }
          </p> :
          getBoxSubtitle(type, description, t)
        }
        {type === 4 ? <button className="skyBlueBox__btn">{buttonText}</button> : null}
      </div> */}
    </S.Box>
  );
}

export default Box;
