import { useState } from 'react';
import axios from 'axios';

export default function useSaveDoc() {
  const urlEndpoint = 'https://us-central1-zaia-health-dev.cloudfunctions.net/documentAiClient/save_document';
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  
  // const data = {
  //   key: 'value',
  //   anotherKey: 'anotherValue'
  // };
  

  const saveDoc = (docData) => {
    setLoading(true);
    // return new Promise(function(resolve, reject) {
    //   const xhr = new XMLHttpRequest();
    //   xhr.open('POST', urlEndpoint, true);
    //   xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    //   xhr.setRequestHeader('ACTION_SECRET', 'Nhk03CBnel');
    //   xhr.onreadystatechange = () => {
    //     if(xhr.readyState === 4) {
    //       if(xhr.status === 200) {
    //         const response = JSON.parse(xhr.responseText);
    //         setData(response);
    //         resolve(response);
    //       } else {
    //         const errorMessage = xhr.status + ' Error saving the archive';
    //         setError(errorMessage);
    //         reject(errorMessage);
    //       }
    //       setLoading(false);
    //     }
    //   };
    //   xhr.send(docData);
    // });

    console.log(docData);

    return new Promise(function(resolve, reject) {
      axios.post(urlEndpoint, docData, {
          headers: {
            'ACTION_SECRET': 'Nhk03CBnel',
            'Content-Type': 'application/json'
          }
      })
      .then(response => {
        console.log("respuestaaaa", response );    
        setData(response);
        resolve(response);
        setLoading(false);
      })
      .catch(error => {
        console.log("errorrrrr", error );    
        setError(error);
        reject(error)
        setLoading(false);
      });
    })

  }
  
  return {
    saveDoc,
    data,
    loading,
    error,
  }
}