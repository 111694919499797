import React from "react";
import ZaiaButton from "../zaiaButton/ZaiaButton.jsx";
import * as S from "./ZaiaModalResponseContent.style.js"

const errorImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error.svg`;
const correctImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-success.svg`;

function ZaiaModalResponseContent({
  button1,
  button2,
  formSuccess,
  title,
  subtitle,
  button1Text,
  button2Text,
  doubleButton=false,
}) {

  return (
    <S.ZaiaModalResponseContent
      formSuccess={formSuccess}
    >
      <div className="alert">
        <img src={
          formSuccess ? correctImg : errorImg
        } alt="" className="alert__image" />
        <div className="alert__title">{title}</div>
        {
          formSuccess === false &&
          <div className="alert__subtitle">{subtitle}</div>
        }
        <div className="alert__buttons">
          <div className="alert__button" onClick={() => button1()}>
            {
              formSuccess
              ? <ZaiaButton
                title={button1Text}
                type={"button"}
                option={"primary"}
                color={"#FFFFFF"}
                backgroundColor={"#00A600"}
                border={false}
                borderRadius={"10"}	
                fontSize={"20"}
                shadow={false}
                height={"auto"}
                padding={"10px"}
              />
              : <ZaiaButton
                title={button1Text}
                type={"button"}
                option={"primary"}
                color={"#FFFFFF"}
                backgroundColor={"#3E3DA3"}
                border={false}
                borderRadius={"10"}	
                fontSize={"20"}
                shadow={false}
                height={"auto"}
                padding={"10px"}
              />
            }
          </div>
          {
            doubleButton &&
            <div className="alert__second-button" onClick={() => button2()}>
            <ZaiaButton
              title={button2Text}
              type={"button"}
              option={"primary"}
              color={"#3E3DA3"}
              backgroundColor={"#FFFFFF"}
              // border={false}
              borderRadius={"10"}	
              fontSize={"20"}
              shadow={false}
              height={"auto"}
              padding={"10px"}
            />
          </div>
          }
        </div>
      </div>
    </S.ZaiaModalResponseContent>
  );
}

export default ZaiaModalResponseContent;
