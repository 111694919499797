import React from "react";
import * as S from "./CircleChartCard.style";

import CircleChart from "../circleChart";

const CircleChartCard = ({
  icon, 
  title, 
  children=null,
  percentagePrincipal,
  textPrincipal="", 
  subTextPrincipal="", 
  percentageSecundary,
  textSecundary, 
  subTextSecundary, 
  colorIcon, 
  colorPrimary, 
  colorSecundary
}) => {

  return (
    <S.CircleChartCard>
      <h4>{title}</h4>
      <div className="boxInfoPatient__reports-circle-graph">
        <CircleChart 
          colorIcon={colorIcon}
          colorPrimary={colorPrimary}
          colorSecundary={colorSecundary}
          icon={icon}
          percentage={percentagePrincipal}
        />
        <div className="boxInfoPatient__infoPercents">
          {children}
          {            
            children === null && (percentageSecundary || percentageSecundary === 0) 
            ? <>
                <CircleChartText
                  colorPrimary={colorPrimary}
                  colorSecundary={colorIcon}
                  percentage={percentagePrincipal}
                  textBold={textPrincipal}
                  text={subTextPrincipal}
                />
                <CircleChartText
                  colorPrimary={colorSecundary}
                  colorSecundary={colorIcon}
                  percentage={percentageSecundary}
                  textBold={textSecundary}
                  text={subTextSecundary}
                /> 
              </> 
            : <CircleChartTextBig
              colorPrimary={colorPrimary}
              colorSecundary={colorIcon}
              percentage={percentagePrincipal}
              textBold={textPrincipal}
            />
          }
        </div>
      </div>
    </S.CircleChartCard>
  );
};

const CircleChartText = ({colorPrimary, colorSecundary, percentage, textBold, text }) => {
  return (
    <S.CircleChartText  
      colorSecundary={colorSecundary}
      colorPrimary={colorPrimary}
    >
      <h2>{percentage}</h2>
      <p>
        {text 
          && <>
            <strong>{text}</strong>
            <br />{" "}
          </> 
        }
        {textBold}
      </p>
    </S.CircleChartText>
  )
}

const CircleChartTextBig = ({colorPrimary, colorSecundary, percentage, textBold}) => {
  return (
    <S.CircleChartTextBig  
      colorSecundary={colorSecundary}
      colorPrimary={colorPrimary}
    >
      <h2>{percentage}%</h2>
      <p>
        {textBold}
      </p>
    </S.CircleChartTextBig>
  )
}

export default CircleChartCard;
