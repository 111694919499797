import styled from "styled-components";

export const SymptomsSelected = styled.div`
  height: 100%;
  position: relative;
  .title {
    color: #3E3DA3;
    font-family: "Nunito Sans";
    font-size: 17px;
    padding: 20px 10px;
  }
  .symptoms-list {
    height: calc(100% - 47px);
    &__results {
      color: #C0BFDC;
      font-family: "Nunito Sans";
      font-size: 17px;
      font-style: italic;
      margin: 20px 27px;
      &--hidden {
        display: none;
      }
    }
    &__container {
      padding: 0 10px;
      overflow: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .symptom-box {
      padding: 20px 66px;
      display: flex;
      flex-direction: column;
      border-radius: 15px;
      background: #FFF;
      margin-bottom: 40px;
      &__top {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      &__name {
        color: #73748C;
        font-family: Nunito;
        font-size: 14px;
        align-items: center;
        color: #73748C;
      }
      &__icon {
        font-size: 20px;
        color: #3E3DA3;
        cursor: pointer;
      }
      &__bottom {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .button-quantity {
          display: flex;
          width: 35px;
          height: 35px;
          padding: 10px;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          background: #3E3DA3;
          border: none;
          outline: none;
          cursor: pointer;
          &__icon {
            color: #FFFFFF;
            font-size: 16px;
          }
        }
        .severity-value{
          display: flex;
          align-items: center;
          &__text {
            font-size: 13px;
            color: #3E3DA3;
          }
          &__img {
            width: 27px;
            margin-left: 10px;
          }
        }
      }

    }
    .zaia-reaction {
      height: 100%;
      display: flex;
    }
    .search-symptoms {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: #3E3DA3;
      margin: auto;
      &__img {
        width: 250px;
        justify-content: center;
        align-self: center;
      }
      &__text {
        color: #36369B;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 24px;
        margin-top: 40px;
      }
    }
    .empty-symptoms {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: #3E3DA3;
      margin: 40px;
      &__img {
        width: 200px;
        justify-content: center;
        align-self: center;
      }
      &__title {
        font-family: "Nunito Sans Bold";
        font-size: 19px;
        margin-top: 20px;
      }
      &__subtitle {
        margin-top: 20px;
        font-size: 15px;
        color: #37379B;
      }
    }
  }
`