import React, { useEffect, useState } from "react";
import * as S from "./CurrentState.style";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

// pipes
import * as P from "../../../../../../../utils/pipes";

// Hooks
import useDoctorData from "../../../../../../../hooks/useDoctorData";

// components
import ZaiaButton from "../../../../../../zaiaComponents/zaiaButton";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import ZaiaInputForm from "../../../../../../zaiaComponents/zaiaInputForm";
import ZaiaSelectForm from "../../../../../../zaiaComponents/zaiaSelectForm";

// GraphQL
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_USER_TO_PROCESS_HISTORY } from "../../../../../../../graphql/subscriptions/UserToProccesHistory";
import { QUERY_GET_PROCESS_STAGE } from "../../../../../../../graphql/queries/ProcessStage";
import { MUTATION_UPDATE_USER_TO_PROCESS } from "../../../../../../../graphql/mutations/UserToProcess";
import { QUERY_GET_CUSTOM_FORM_BY_IDS } from "../../../../../../../graphql/queries/CustomForm";
import { QUERY_GET_USER_TO_PROCESS_HISTORY_FILTERS } from "../../../../../../../graphql/queries/UserToProcessHistory";

// Assets
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaOk = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-success.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;

function CurrentState({ processId, patientId, eventFilter }) {
  const {t} = useTranslation("global");
  const { doctorData } = useDoctorData();
  const subsActive = window.navigator.userAgent.indexOf('Mac') === -1;

  const [addNewEvent, setAddNewEvent] = useState(false);
  const nowTime = new Date(Date.now());
  const [month, setMonth] = useState(nowTime.getMonth());
  const [year, setYear] = useState(nowTime.getFullYear());
  const [dateStartFilter, setDateStartFilter] = useState(new Date(nowTime));
  const [dateFinishFilter, setDateFinishFilter] = useState(new Date(nowTime));

  const {
    data: userToProcessHistorySub,
    loading: userToProcessHistoryLoadingSub,
    error: userToProcessHistoryErrorSub,
  } = useSubscription(SUBSCRIPTION_GET_USER_TO_PROCESS_HISTORY, {
    variables: {
      patientId: patientId,
      processId: processId,
      initialDate: new Date(dateStartFilter),
      finalDate: new Date(dateFinishFilter),
    },
  });

  const {
    data: userToProcessHistory,
    loading: userToProcessHistoryLoading,
    error: userToProcessHistoryError,
  } = useQuery(QUERY_GET_USER_TO_PROCESS_HISTORY_FILTERS, {
    variables: {
      patientId: patientId,
      processId: processId,
      initialDate: new Date(dateStartFilter),
      finalDate: new Date(dateFinishFilter),
    },
  });

  useEffect(() => {
    let dateStart = new Date(year, month, 1);
    let dateFinish = new Date(year, month, 1);
    const numberDaysMonth = new Date(year, month + 1, 0).getDate();
    dateFinish.setDate(numberDaysMonth);
    dateStart.setDate(1);
    setDateStartFilter(new Date(dateStart).setHours(0, 0, 0, 0));
    setDateFinishFilter(new Date(dateFinish).setHours(23, 59, 59, 999));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month, year]);

  const backMonth = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const nextMonth = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  return (
    <S.CurrentState>
      {/* <div className="current-state-header">
        <h5>{t("aiNavigator.outcomes.currentStatus")}</h5>
        <div className="current-state-header-date-selector">
          <div className="date-selector">
            <div className="date-selector-text">
              {P.nameMonth(month, "L", t)} {year}
            </div>
            <div className="date-selector-icons">
              <i
                className="date-selector-icon date-selector-icon--back icon zaia-icono-atras"
                onClick={() => backMonth()}
              />
              <i
                className="date-selector-icon date-selector-icon--next icon zaia-i-next"
                onClick={() => nextMonth()}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="current-state-add-event"
        onClick={!addNewEvent ? () => setAddNewEvent(true) : null}
      >
        <div className="current-state-add-event-form">
          {addNewEvent ? (
            <AddNewEvent
              setAddNewEvent={setAddNewEvent}
              patientId={patientId}
              processId={processId}
              doctorId={doctorData?.id}
            />
          ) : null}
        </div>
      </div> */}
      <div className="current-state-content">
        <ListOfEvents
          userToProcessHistory={subsActive ? userToProcessHistorySub : userToProcessHistory}
          userToProcessHistoryLoading={subsActive ? userToProcessHistoryLoadingSub : userToProcessHistoryLoading}
          userToProcessHistoryError={subsActive ? userToProcessHistoryErrorSub : userToProcessHistoryError}
          eventFilter={eventFilter}
        />
      </div>
    </S.CurrentState>
  );
}

function AddNewEvent({ setAddNewEvent, processId, patientId, doctorId }) {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const [typeForm] = useState("addEvent");

  const { data: processesStage } = useQuery(QUERY_GET_PROCESS_STAGE, {
    variables: {
      processId: processId,
    },
  });
  const [editUserToProcess] = useMutation(MUTATION_UPDATE_USER_TO_PROCESS);
  const dateNow = new Date(Date.now());

  const handleSubmit = async (values, { resetForm }) => {
    if (values.descriptionEvent && values.stageIdEvent) {
      try {
        const { data: returning } = await editUserToProcess({
          variables: {
            processId: processId,
            userId: patientId,
            doctorId: doctorId,
            stageId: values.stageIdEvent,
            descript: values.descriptionEvent,
            // userToProcess: {
            // }
          },
        });
        resetForm();
        setAddNewEvent(false);
      } catch (err) {
        resetForm();
        console.log(err);
      }
    }
  };

  return (
    <Formik
      initialValues={{
        stageIdEvent: "",
        timeEvent: `${dateNow.getFullYear()}-${
          dateNow.getMonth() + 1 < 10 ? "0" : ""
        }${dateNow.getMonth() + 1}-${dateNow.getDate()}`,
        descriptionEvent: "",
      }}
      // validationSchema={
      //   Yup.object().shape(
      //       {
      //         nameEvent: Yup.string()
      //           .min(3, t("register.validation.min3"))
      //           .max(15, t("register.validation.max"))
      //           .required(t("register.validation.required")),
      //         descriptionEvent: Yup.string()
      //           .min(3, t("register.validation.min3"))
      //           .max(15, t("register.validation.max"))
      //           .required(t("register.validation.required")),
      //       }
      //     )
      // }
      onSubmit={handleSubmit}
    >
      {(props) => (
        <S.CurrentStateForm absolute={true}>
          <form onSubmit={props.handleSubmit}>
            <div className="current-state-form-inputs">
              {
                typeForm === "addEvent" && (
                  <>
                    <div className="current-state-form-content-double">
                      <div className="current-state-form-input current-state-form-input--small">
                        <ZaiaSelectForm
                          placeholder={t("outcomes.currentStatus.which")}
                          type="select"
                          name="stageIdEvent"
                          padding={"5px"}
                          fontSize={"12px"}
                          errorOption={false}
                          items={processesStage?.processStage.map((stage) => {
                            return {
                              value: stage.id,
                              text: P.dataTranslation(stage.stageName, language),
                            };
                          })}
                        />
                      </div>
                      <div className="current-state-form-input current-state-form-input--small">
                        <ZaiaInputForm
                          type="date"
                          name="timeEvent"
                          icon="icono-cumpleanos"
                          errorOption={false}
                          padding={"5px"}
                          fontSize={"12px"}
                        />
                      </div>
                    </div>
                    <div className="current-state-form-input">
                      <ZaiaInputForm
                        placeholder={t("outcomes.currentStatus.eventDescript")}
                        type="text"
                        name="descriptionEvent"
                        padding={"5px"}
                        textarea={true}
                        fontSize={"12px"}
                        errorOption={false}
                      />
                    </div>
                  </>
                )
              } 
              {
                typeForm === "error" ? (
                  <ZaiaReaction
                    text={"error"}
                    widthText="240px"
                    widthImg="150px"
                    separation="20px"
                    zaiaImg={logoZaiaAlert}
                  />
                ) : (
                  <ZaiaReaction
                    text={"ok"}
                    widthText="240px"
                    widthImg="150px"
                    separation="20px"
                    zaiaImg={logoZaiaOk}
                  />
                )
              }
            </div>
            <div className="current-state-form-buttons">
              <div className="current-state-form-button">
                <ZaiaButton
                  type="submit"
                  option="primary"
                  marginTop="5"
                  title={"Añadir"}
                  borderRadius="5"
                  fontSize="10"
                  height="30"
                />
              </div>
              <div
                className="current-state-form-button"
                onClick={() => setAddNewEvent(false)}
              >
                <ZaiaButton
                  type="button"
                  option="secondary"
                  marginTop="5"
                  title={"Cancelar"}
                  borderRadius="5"
                  fontSize="10"
                  height="30"
                />
              </div>
            </div>
          </form>
        </S.CurrentStateForm>
      )}
    </Formik>
  );
}

// eslint-disable-next-line no-unused-vars
function EventCommentsContainer() {
  const [t] = useTranslation("global");
  const [addNewComment, setAddNewComment] = useState(false);

  return (
    <S.EventCommentsContainer>
      <div className="comments-container">
        <div className="comments-container-header">
          <h5>{t("globally.comment")}:</h5>
          <div
            className="comments-container-add-comment"
            onClick={!addNewComment ? () => setAddNewComment(true) : null}
          >
            <i className="comments-container-add-comment-icon icon zaia-icono-agregar" />
            <div className="comments-container-add-comment-text">
              {t("outcomes.currentStatus.addComment")}
            </div>
          </div>
        </div>
        <div className="comments-container-add-new-comment">
          {addNewComment ? (
            <AddNewComment setAddNewComment={setAddNewComment} />
          ) : null}
        </div>
        <div className="comments-container-content">
          <CommentBox
            comment="Se le aplicaron los medicamentos correspondientes al tratamiento indicado, el paciente estará en constante revisión."
            date="08 Octubre 2021"
            hour="10:30 am"
          />
        </div>
      </div>
    </S.EventCommentsContainer>
  );
}

function CommentBox({ comment, date, hour }) {
  const [t] = useTranslation("global");

  return (
    <S.CommentBox>
      <div className="comment-box-text">{comment}</div>
      <div className="comment-box-date-content">
        <div className="comment-box-date">{date},</div>
        <div className="comment-box-hour">
          {t("globally.hour")}: {hour}
        </div>
      </div>
    </S.CommentBox>
  );
}

function AddNewComment({ setAddNewComment }) {
  const [typeForm] = useState("newComment");

  const handleSubmit = (values, { resetForm }) => {
    console.log();
  };

  return (
    <Formik
      initialValues={{
        comment: "",
      }}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <S.CurrentStateForm absolute={false}>
          <form onSubmit={props.handleSubmit}>
            <div className="current-state-form-inputs">
            {
              typeForm === "newComment" && (
                <div className="current-state-form-input">
                  <ZaiaInputForm
                    type="text"
                    name="comment"
                    padding={"5px"}
                    textarea={true}
                    fontSize={"12px"}
                    errorOption={false}
                  />
                </div>
              )
            }
            {
              typeForm === "error" ? (
                <ZaiaReaction
                  text={"error"}
                  widthText="240px"
                  widthImg="150px"
                  separation="20px"
                  zaiaImg={logoZaiaAlert}
                />
              ) : (
                <ZaiaReaction
                  text={"ok"}
                  widthText="240px"
                  widthImg="150px"
                  separation="20px"
                  zaiaImg={logoZaiaOk}
                />
              )
            }
            </div>
            <div className="current-state-form-buttons">
              <div className="current-state-form-button">
                <ZaiaButton
                  type="submit"
                  option="primary"
                  marginTop="5"
                  title={"Añadir"}
                  borderRadius="5"
                  fontSize="10"
                  height="30"
                />
              </div>
              <div
                className="current-state-form-button"
                onClick={() => setAddNewComment(false)}
              >
                <ZaiaButton
                  type="button"
                  option="secondary"
                  marginTop="5"
                  title={"Cancelar"}
                  borderRadius="5"
                  fontSize="10"
                  height="30"
                />
              </div>
            </div>
          </form>
        </S.CurrentStateForm>
      )}
    </Formik>
  );
}

function ListOfEvents({
  userToProcessHistory,
  userToProcessHistoryLoading,
  userToProcessHistoryError,
  eventFilter
}) {

  const customFormIds = userToProcessHistory?.userToProcessHistory
    .map((history) => history.latestCustomFormId)
    .filter((n) => n);

  const {
    data: customForms
  } = useQuery(QUERY_GET_CUSTOM_FORM_BY_IDS, {
    variables: {
      customFormIds: customFormIds,
    },
  });

  const {
    t,
    i18n: { language },
  } = useTranslation("global");

  if (userToProcessHistoryLoading)
    return <ZaiaReaction widthImg="120px" zaiaImg={logoZaiaLoading} />;

  if (userToProcessHistoryError)
    return (
      <ZaiaReaction
        zaiaImg={logoZaiaAlert}
        widthImg="120px"
        text={t("aiNavigator.outcomes.errorTimeline")}
        sizeText="15px"
        widthText="240px"
      />
    );

  if (userToProcessHistory?.userToProcessHistory.length === 0)
    return (
      <ZaiaReaction
        zaiaImg={logoZaiaEmpty}
        widthImg="120px"
        text={t("aiNavigator.outcomes.noTimeline")}
        sizeText="15px"
        widthText="240px"
      />
    );
    
    return (
      <>
        {userToProcessHistory?.userToProcessHistory.filter(item => eventFilter.length > 0 ? eventFilter.filter(event => event === item.latestCustomFormId).length > 0 : true).map(
          (processHistory, index, { length }) => {
            return (
              <div key={processHistory.id}>
                <S.ReportBox line={index !== length - 1} point={index === 0} last={index === length - 1}>
                  <div className="report-box-top">
                    <div className="report-box-top__date">{P.longDate(processHistory.created_at, t, language)}</div>
                    <div className="report-box-top__process">{processHistory.doctor.name + " " + processHistory.doctor.lastname}</div>
                  </div>
                  <div className="report-box">
                    <div className="report-box__point"></div>
                    <div className="report-box__title">
                      <div className="report-box__event">
                        <div className="report-box__event-name">
                          {
                            P.dataTranslation(
                              customForms?.customForm?.find(
                                (customForm) =>
                                  customForm.id ===
                                  processHistory.latestCustomFormId
                              )?.customFormName,
                              language
                            )
                          }
                        </div>
                        {/* <div className="report-box__event-process">
                          <div className="response-box__label">{t("globally.typeOfEvent")}</div>
                          <div className="response-box__name">Cancer de prostata</div>
                        </div> */}
                      </div>
                      <div className="report-box__files">
                        <i className="report-box__files-icon icon zaia-icon-file"></i>
                        <div className="report-box__files-text">{t("aiNavigator.outcomes.showEventFiles")}</div>
                      </div>
                    </div>
                    {/* <div className="report-box__date">08/23/2023</div> */}
                    <div className="report-box__date">
                      {
                        processHistory.customFormFieldChanges?.split(",*-,&")
                        .map((item) => {
                          return (
                            <div 
                              className="report-box-info-text-change"
                              key={uuidv4()}
                            >
                              - {item}
                            </div>
                          );
                        })
                      }
                    </div>
                    <div className="report-box__comments">
                      <div className="report-box__comments-title">
                        <i className="report-box__comments-title-icon icon zaia-vector"></i>
                        <div className="report-box__comments-title-text">{t("globally.comments")}</div>
                      </div>
                      <div className="report-box__comment">
                        “
                        {
                          processHistory.descript
                        }
                        ”
                      </div>
                    </div>

                  </div>
                </S.ReportBox>
              </div>
            );
          }
        )}
      </>
    );

  return (
    <>
      {userToProcessHistory?.userToProcessHistory.filter(userToProcess => userToProcess.descript !== '  -').map(
        (processHistory, index, { length }) => {
          return (
            <div key={processHistory.id}>
              <S.ReportBox line={index !== length - 1} point={index === 0}>
                <div className="report-box-indicator">
                  <div className="report-box-indicator-point"></div>
                  <div className="report-box-indicator-line"></div>
                </div>
                <div className="report-box-info">
                  <div className="report-box-info-text">
                    {processHistory.descript.split("-")[0] !== "undefined "
                      ? processHistory.descript.split("-")[0]
                      : null}
                  </div>
                  {processHistory.descript
                    .split("-")[1]
                    ?.split(",")
                    .map((item) => {
                      return (
                        <div 
                          className="report-box-info-text-change"
                          key={uuidv4()}
                        >
                          - {item}
                        </div>
                      );
                    })}
                  <div className="report-box-info-options">
                    <div className="report-box-info-options-text">
                      <div className="report-box-info-options-text-date">
                        {P.longDate(processHistory.created_at, t, language)} •{" "}
                        {P.drTranslation(
                          processHistory.doctor.gender,
                          language
                        ) +
                          " " +
                          processHistory.doctor.name +
                          " " +
                          processHistory.doctor.lastname}{" "}
                        -{" "}
                      </div>
                      <div className="report-box-info-options-text-report">
                        {" "}
                        {
                          // esto no se muestra porque el trigger aun no guarda el id del custom form en el user to process history
                          P.dataTranslation(
                          customForms?.customForm?.find(
                            (customForm) =>
                              customForm.id ===
                              processHistory.latestCustomFormId
                          )?.customFormName,
                          language
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </S.ReportBox>
            </div>
          );
        }
      )}
    </>
  );
}

export default CurrentState;
