import { gql } from "@apollo/client";

export const MUTATION_ADD_MEDICINE_TO_PATIENT = gql`
  mutation MUTATION_ADD_MEDICINE_TO_PATIENT(
    $medicineRecipe: [userToDrug_insert_input!]!
  ) {
    insert_userToDrug(objects: $medicineRecipe) {
      affected_rows
      returning {
        comment
        daysOfWeek
        dose
        doseShape
        doseType
        drugId
        endTreatment
        frequency
        frequencyType
        id
        isFix
        isNotify
        isPeriodic
        quantity
        startHour
        startTreatment
        userId
        drug {
          genericName
        }
      }
    }
  }
`
  