import { gql } from "@apollo/client";

export const SUBSCRIPTION_DRUGS_COMMENT_REPORTS = gql`
  subscription SUBSCRIPTION_DRUGS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz,
    $processId: [Int!]
  ) {
    userToDrugHistory(
      where: {
        created_at: {
          _gte: $initialDate, 
          _lte: $finalDate
        }, 
        isTaken: {
          _eq: false
        }, 
        comment: {
          _is_null: false, 
          _neq: ""
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      id
      comment
      created_at
      delay
      dose
      doseType
      drugId
      userId
      userToDrug {
        drug {
          genericName
        }
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const SUBSCRIPTION_DRUGS_COMMENT_REPORTS_BY_USER = gql`
  subscription SUBSCRIPTION_DRUGS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz, 
    $userId: uuid,
    $processId: [Int!]
  ) {
    userToDrugHistory(
      where: {
        created_at: {
          _gte: $initialDate, 
          _lte: $finalDate
        }, 
        userId: {
          _eq: $userId
        }, 
        isTaken: {
          _eq: false
        }, 
        comment: {
          _is_null: false, 
          _neq: ""
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      id
      comment
      created_at
      delay
      dose
      doseType
      drugId
      userId
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`