import React, { useState, useContext, useEffect, useMemo } from "react";
import * as S from "./DoubleCalendarDashboard.style";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import { useTranslation } from "react-i18next";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { es, fr } from 'date-fns/locale';

const DoubleCalendarDashboard = ({
  setInitialDateRange,
  setFinishDateRange,
  initialDateRange,
  finishDateRange,
  topOptionsColor,
  weekDaysColor,
  numbersColor,
  dateSelectedPrimaryColor,
  dateSelectedSecondColor,
  currentDayColor,
  backgroundColor,
}) => {
  const { i18n: { language } } = useTranslation("global");

  
  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    setSelectedDate(
      {
        from: initialDateRange ? initialDateRange : null,
        to: finishDateRange ? finishDateRange : null,
      }
    )
  }, [initialDateRange, finishDateRange]);
  
  const handleCalendarSelectRange = (e) => {
    console.log(e);
    const dates = e;
    // const from = dates.from ? new Date(dates.from) : null;
    // const to = new Date(dates.to);

    if(e) {
      if(e.to && e.from) {
        if (JSON.stringify(e.to) === JSON.stringify(e.from)) {
          dates.to = null;
          dates.from = null;
          setInitialDateRange(null);
          setFinishDateRange(null);
        } else {
          setInitialDateRange(new Date(dates.from.getFullYear(), dates.from.getMonth(), dates.from.getDate(), 0,0,0));
          setFinishDateRange(new Date(dates.to.getFullYear(), dates.to.getMonth(), dates.to.getDate(), 23,59,59));
        }
      } else if(finishDateRange && initialDateRange) {
        dates.to = null;
        dates.from = null;
        setInitialDateRange(null);
        setFinishDateRange(null);
      }
    } else {
      setInitialDateRange(null);
      setFinishDateRange(null);
    }
    setSelectedDate(dates);
  }

  const getLanguage = () => {
    if(language === 'en') {
      return null;
    } else if(language.split("-")[0] === 'es') {
      return es;
    } else if(language.split("-")[0] === 'fr') {
      return fr;
    }
  }

  return (
    <S.DoubleCalendarDashboard 
      topOptionsColor={topOptionsColor}
      weekDaysColor={weekDaysColor}
      numbersColor={numbersColor} 
      dateSelectedPrimaryColor={dateSelectedPrimaryColor}
      dateSelectedSecondColor={dateSelectedSecondColor}
      currentDayColor={currentDayColor}
      backgroundColor={backgroundColor}
    >
      <DayPicker
        locale={getLanguage()}
        id="test"
        mode="range"
        selected={selectedDate}
        onSelect={handleCalendarSelectRange}
        captionLayout="dropdown-buttons"
        fromYear={2015} toYear={2025} numberOfMonths={2}
      />
    </S.DoubleCalendarDashboard>
  );

};

export default DoubleCalendarDashboard;