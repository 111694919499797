import { gql } from "@apollo/client";

export const MUTATION_CHAT_BOX = gql`
  mutation MUTATION_CHAT_BOX(
    $message: String!
    $doctorId: String
    $userId: String
  ) {
    chatBot(message: $message, doctorId: $doctorId, userId: $userId) {
      message
      payload
      sessionId
    }
  }
`;

export const MUTATION_CHAT_MESSAGES = gql`
  mutation MUTATION_CHAT_MESSAGES(
    $message: String!
    $groupChatId: uuid!
  ) {
    insert_message(objects: {message: $message, toChatId: $groupChatId}) {
      returning {
        id
        createdAt
        message
        toChatId
        isPublic
        fromDoctorId
        doctor {
          id
          name
          lastname
          gender
        }
        fromUserId
        user {
          id
          name
          lastname
          gender
        }
      }
    }
  }
`

export const MUTATION_EDIT_CHAT_GROUP = gql`
  mutation MyMutation(
    $idChatGroup: uuid!, 
    $newChatInfo: chatGroup_set_input!
  ) {
    update_chatGroup_by_pk(pk_columns: {id: $idChatGroup}, _set: $newChatInfo) {
      id
      imagen
      nameChat
    }
  }
`;

export const MEDICAL_PATIENT_CHATBOT = gql`
  mutation MEDICAL_PATIENT_CHATBOT(
    $language: String!, 
    $query: String!, 
    $user_id: String!
  ) {
    consult_the_user_clinical_history(
      language: $language, 
      query: $query, 
      user_id: $user_id
    ) {
      answer
      error_message
      status
    }
  }     
`;

export const MEDICAL_PROCESSES_PARTITION_CHATBOT = gql`
  query MEDICAL_PROCESSES_PARTITION_CHATBOT(
    $collection_name: String!, 
    $language: String!, 
    $partition_name: String!, 
    $question: String!
  ) {
    consult_chatbot_of_medical_process(   
      collection_name: $collection_name, 
      language: $language, 
      partition_name: $partition_name
      question: $question
    ) {
      answer
      status
    }
  }     
`;

export const CONSULT_MEDICAL_PROCESS_IN_FLORENCE = gql`
  query CONSULT_MEDICAL_PROCESS_IN_FLORENCE(
    $collection_name: String!, 
    $language: String!, 
    $partition_name: String!, 
    $question: String!,
    $technology: String!,
    $embedding_model: String!,
    $prediction_model: String!
  ) {
    consult_medical_process_in_florence( 
      question: $question, 
      language: $language, 
      collection_name: $collection_name, 
      partition_names: [$partition_name], 
      llm_technology: $technology, 
      embedding_model: $embedding_model, 
      prediction_model: $prediction_model
    ) {
        status
        error_message
        answer
    } 
  } 
  
`;

export const CONSULT_GENERAL_CHAT_IN_FLORENCE_VERTEX = gql`
  query CONSULT_GENERAL_CHAT_IN_FLORENCE_VERTEX($prompt: String!) {
    consult_prediction_model_from_vertex_ai(prompt: $prompt) {
      status
      error_message
      answer
    }
  }
`

export const CONSULT_GENERAL_CHAT_IN_FLORENCE_CHATGPT = gql`
  query CONSULT_GENERAL_CHAT_IN_FLORENCE_CHATGPT($prompt: String!) {
    consult_chat_gpt(model: "gpt-4", prompt: $prompt) {
      status
      error_message
      answer
    }
  }
`

export const CONSULT_GENERAL_CHAT_IN_FLORENCE_MEDPALM = gql`
  query CONSULT_GENERAL_CHAT_IN_FLORENCE_MEDPALM($prompt: String!) {
    consult_palm2_prediction_model_from_vertexAI(prompt: $prompt, model: "medpalm2@preview") {
      status
      error_message
      answer
    }
  }
`

export const CONSULT_PATIENT_HISTORY_CHAT_IN_FLORENCE = gql`
  query CONSULT_PATIENT_HISTORY_CHAT_IN_FLORENCE(
    $language: String!
    $question: String!
    $user_id: String!
  ) {
    consult_medical_history_in_florence( 
      user_id: $user_id, 
      language: $language, 
      question: $question, 
      technology: "openai", 
      embedding_model: "text-embedding-ada-002", 
      prediction_model: "gpt-4", 
      data_source_tag: "PDF"
      collection_name: "FLORENCE_PATIENT_MEDICAL_HISTORY" 
    ) {
        status
        error_message
        answer
        medical_history_metadata
    }
  }
`

export const CONSULT_PATIENT_HISTORY_CHAT_IN_FLORENCE_MEDPALM = gql`
  query CONSULT_PATIENT_HISTORY_CHAT_IN_FLORENCE_MEDPALM(
    $language: String!
    $question: String!
    $user_id: String!
  ) {
    consult_medical_history_in_florence( 
      user_id: $user_id, 
      language: $language, 
      question: $question, 
      technology: "google", 
      embedding_model: "textembedding-gecko-multilingual@001", 
      prediction_model: "medpalm2@preview", 
      data_source_tag: "PDF",
      collection_name: "FLORENCE_PATIENT_MEDICAL_HISTORY"
    ) {
      status
      error_message
      answer
    }
  }
`

export const CONSULT_MEDICAL_GUIDANCE_IN_FLORENCE = gql`
  query CONSULT_MEDICAL_GUIDANCE_IN_FLORENCE(
    $language: String!
    $partition_name: String!
    $clinical_history: String!
    $collection_name: String!
    $technology: String!,
    $embedding_model: String!,
    $prediction_model: String!
  ) {
    consult_medical_guidance_in_florence(
      language: $language, 
      collection_name: $collection_name, 
      partition_name: $partition_name, 
      clinical_history: $clinical_history,
      llm_technology: $technology, 
      embedding_model: $embedding_model, 
      prediction_model: $prediction_model
    ) {
      status
      error_message
      answer
    }
  }    
`;

export const CONSULT_MEDICAL_PROCESS_IN_FLORENCE_MEDICOL = gql`
  mutation CONSULT_MEDICAL_PROCESS_IN_FLORENCE_MEDICOL(
    $assistant_id: String!
    $message: String!
    $thread_id: String
    $user_id: String!  
  ) {
    consult_ai_assistant(
      assistant_id: $assistant_id, 
      message: $message, 
      thread_id: $thread_id, 
      user_id: $user_id
    ) {
      answer
      error_message
      status
      thread_for_completion
    }
  }
`

export const UPLOAD_PATIENT_GUIDE_FILE = gql`
  mutation UPLOAD_PATIENT_GUIDE_FILE(
    $collection_name: String!
    $data_source_id: String!
    $data_source_tag: String!
    $embedding_model: String!
    $embedding_technology: String!
    $file_bucket_name: String!
    $file_name: String!
    $model_vector_dimension: Int!
    $user_id: String!
  ) {
    upload_patient_medical_history(
      collection_name: $collection_name, 
      data_source_id: $data_source_id, 
      data_source_tag: $data_source_tag, 
      embedding_model: $embedding_model, 
      embedding_technology: $embedding_technology, 
      file_bucket_name: $file_bucket_name, 
      file_name: $file_name, 
      model_vector_dimension: $model_vector_dimension, 
      user_id: $user_id
    ) {
      error_message
      status
    }
  }
`