import styled from "styled-components";

export const FollowUpView = styled.div`
  height: 100%;
  width: 100%;
  .header {
    display: flex;
    padding: 20px 38px;
    align-items: center;
    .filters-button {
      display: flex;
      align-items: center;
      display: flex;
      padding: 10px 20px;
      align-items: center;
      border-radius: 43px;
      border: 1px solid #6C6BCC;
      color: #6C6BCC;
      cursor: pointer;
      &__icon {
        margin-right: 10px;
      }
    }
    .filter-state {
      display: flex;
      margin-left: 40px;
      cursor: pointer;
      display: flex;
    }
  }
  .follow-up-list {
    height: calc(100% - 88px);
    &__top {
      display: flex;
      justify-content: space-around;
      border-top: 1px solid rgba(35, 34, 157, 0.17);
      border-bottom: 1px solid rgba(35, 34, 157, 0.17);
      padding: 5px 0 5px;
      color: #707582;
      font-family: "Nunito Sans";
      font-size: 15px;
      font-style: normal;
    }
    &__center {
      height: calc(100% - 67px);
      overflow: auto;
      margin: 20px 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    } 
    &__bottom {
      display: flex;
      justify-content: center;
      .pagination {
        padding: 17px 0px;
        display: flex;
        align-items: center;
        &__arrow {
          cursor: pointer;
          color: #3E3DA3;
          font-size: 12px;
          &--back {
            margin-right: 45px;
          }
          &--next {
            margin-left: 5px;
          }
        }
        &__number {
          cursor: pointer;
          color: #6C6BCC;
          text-align: center;
          font-family: "Nunito Sans";
          font-size: 16px;
          letter-spacing: -0.144px;
          margin-right: 40px;
          &--selected {
            border-bottom: 1px solid #6C6BCC;
          }
        }
      }
      .add-patient {
        cursor: pointer;
        right: 0;
        bottom: 0;
        position: absolute;
        display: flex;
        align-items: center;
        margin: 17px 52px;
        color: #6C6BCC;
        font-family: "Nunito Sans";
        font-size: 17px;
        &__text {
          margin-right: 5px;
        }
        &__icon {
          font-size: 16px;
          opacity: 0.7;
        }
      }
    }
  }
`;

export const TabItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  .item-text {
    border-bottom: 1.5px solid ${({color, activeTab}) => activeTab ? color : "transparent"};
    color: ${({color}) => color};
    /* margin-right: 20px; */
  }
  .item-point {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-left: 5px;
    background-color: ${({color}) => color};
  }
`