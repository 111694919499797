import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalChangeProgramStatus = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    z-index: 200;
    background-color: var(--white);
    width: 1038px;
    margin: auto;
    max-height: 80%;
    border-radius: 14px;
    box-sizing: border-box;
    &--response {
      padding: 40px;
    }
    .header {
      display: flex;
      padding: 20px;
      border-bottom: 1px solid #E0E6ED;
      justify-content: space-between;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        color: #3E3E5B;
        font-family: Nunito Sans Extrabold;
        font-size: 30px;
        &__icon {
          margin-right: 5px;
          display: flex;
          align-items: center;
        }
      }
      .close {
        height: 28px;
        width: 28px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #36369B;
        cursor: pointer;
        outline: none;
        border: none;
        color: #FFF;
        font-size: 10px;
      }
    }
    .footer {
      display: flex;
      padding: 25px 20px;
      justify-content: space-between;
      border-top: 2px solid #F0EFF9;
      height: 50px;
      &__description {
        display: flex;
        align-items: center;
        &-icon {
          margin-right: 10px;
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #3E3DA3;
        }
        &-text {
          font-family: Nunito Sans;
          font-size: 18px;
          font-weight: 200;
          color: #707582;
          height: 100%;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__buttons {
        display: flex;
        margin-left: 50px;
        .button-status {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #36369B;
          color: #FFF;
          font-size: 14px;
          font-family: Nunito Sans;
          padding: 15px 15px;
          border-radius: 75px;
          cursor: pointer;
          border: none;
          outline: none;
          width: 432px;
          &__icon {
            margin-left: 10px;
            opacity: 0.4;
            font-size: 20px;
          }
          &--green {
            background-color: #00A600;
          }
          &--disabled {
            background-color: #EEEDF4;
            color: #707582;
            cursor: default;
          }
        }
        .button-back {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: transparent;
          border: none; 
          outline: none;
          padding: 15px;
          cursor: pointer;
          height: 50px;
          font-size: 14px;
          font-family: Nunito Sans;
          color: #3E3DA3;
          margin-right: 5px;
          &__icon {
            margin-right: 10px;
          }
        }
      }
    }

    .content {
      height: calc(100% - 82px - 102px);
      overflow: auto;
      background-color: #F0EFF9;
      &__patient-card {
        background-color: #FFF;
        border-radius: 20px;
        margin: 20px;
        padding: ${({programSelected}) => programSelected ? "10px 20px" : "20px"};
        display: flex;
        justify-content: space-between;
        &-left {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &-right {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
        .photo-container {
          padding: ${({programSelected}) => programSelected ? "4px" : "7px"};
          -webkit-backdrop-filter: blur(8.288250923156738px);
          backdrop-filter: blur(8.288250923156738px);
          overflow: hidden;
          box-shadow: 0px 0px 12px 0px rgba(35, 34, 157, 0.20);
          border-radius: 50%;
          height: -webkit-fit-content;
          height: -moz-fit-content;
          height: fit-content;
          margin-right: ${({programSelected}) => programSelected ? "10px" : "20px"};
          width: max-content;
          img {
            min-width: ${({programSelected}) => programSelected ? "43px" : "76px"};
            min-height: ${({programSelected}) => programSelected ? "43px" : "76px"};
            width: ${({programSelected}) => programSelected ? "43px" : "76px"};
            height: ${({programSelected}) => programSelected ? "43px" : "76px"};
            border-radius: 50%;
            object-fit: cover;
            box-shadow: 0px 2px 6px #0000001A;
            font-size: 8px;
            vertical-align: middle;
          }
        }
        .patient-info {
          &__top {
            display: flex;
            align-items: center;
          }
          &__bottom {
            display: flex;
            align-items: center;
          }
          &__name {
            font-family: Nunito Sans light;
            font-size: 28px;
            color: #1F1F52;
          }
          &__years {
            font-family: Nunito Sans light;
            font-size: 20px;
            color: #1F1F52;
            margin-left: 10px;	
          }
          &__program-label {
            font-family: Nunito Sans bold;
            font-size: 17px;
            color: #1F1F52;
          }
          &__program-select {
            margin-left: 10px;
            width: 285px;
          }
          &__program-selected {
            padding: 0 11px;
            background-color: #F0F0FF;
            color: #3E3E5B;
            border-radius: 4.44px;
            margin-left: 5px;
          }
        }
        .status-explained {
          display: flex;
          align-items: center;
          width: 317px;
          &__icon {
            font-size: 28px;
            opacity: 0.4;
            margin-right: 10px;
          }
          &__text {
            color: #707582;
            font-family: Nunito Sans;
            font-size: 15px;
            &-title {
              font-family: Nunito Sans bold;
            }
            &-descript {

            }
          }
        }
      }

      &__change-status {
        background-color: #FFF;
        margin: 20px 20px 0;
        border-top-right-radius: 14px;
        border-top-left-radius: 14px;
        padding: 20px;
        height: ${({programSelected}) => programSelected ? "calc(100% - 180px)" : "calc(100% - 210px)"};        
        .current-status {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
          &__left {
            align-items: center;
            background-color: #F0F0FF;
            border-radius: 20px;
            padding: 5px 20px 5px 20px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            .label-status {
              color: #707582;
              background-color: #F0F0FF;
            }
            .text-status {
              color: #1F1F52;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &__right {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            .info-status {
              width: 356px;
              display: flex;
              align-items: center;
              &__icon {
                color: #8A8AC3;
                margin-right: 10px;
              }
              &__text {
                font-family: Nunito Sans light;
                font-size: 14px;
              }
            }
          }
        }
        .status-list {
          display: flex;
          flex-wrap: wrap;
          overflow: auto;
          height: calc(100% - 47px);
          .status-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 10px 10px 7px 10px; 
            flex: 0 0 calc(33.333% - 20px); 
            min-width: 250px; 
            border-radius: 5px;
            &__button {
              border-radius: 14px;
              height: 66px;
              width: 100%;
              background-color: #EDEBF7;
              font-family: Nunito Sans;
              font-size: 17px;
              color: #3E3DA3; 
              text-align: center;
              outline: none;
              border: none;
              cursor: pointer;
              &--selected {
                background-color: #6C6BCC;
                font-family: Nunito Sans bold;
                color: #F0F0FF;
              }
              &--current {
                background-color: #EDEBF7;
                color: #1F1F52;
                font-family: Nunito Sans bold;
              }
              &--disabled {
                background-color: #EDEBF7;
                cursor: default;
                color: #707582;
                opacity: 0.45
              }
            }
            &__caption {
              font-size: 11px;
              color: #707582;
              font-family: Nunito Sans;
              font-size: 11.73px;
              height: 16px;
              &--selected {
                color: #3E3DA3;
              }
            }
          }
        }
      }

      &__change-form {
        background-color: #FFF;
        margin: 20px 20px 0;
        border-top-right-radius: 14px;
        border-top-left-radius: 14px;
        padding: 20px;
        height: ${({programSelected}) => programSelected ? "calc(100% - 180px)" : "calc(100% - 210px)"};  
        .status-data {
          display: flex;
          flex-direction: column;
          overflow: auto;
          height: calc(100% - 59px);
        }
        .status-changed {
          background: #F0F0FF;
          border-radius: 14px;
          padding: 11px 30px;
          display: flex;
          align-items: center;
          &__item {
            display: flex;
            align-items: center;
          }
          &__info {
            /* display: flex; */
            /* flex-direction: column; */
            align-items: center;
          }
          &__label {
            display: flex;
            align-items: center;
            &-icon {
              color: #8A8AC3;
              font-size: 15px;
              margin-right: 7.5px;
              &--new {
                color: #27AE60;
              }
            }
            &-text {
              font-family: Nunito Sans;
              font-size: 16px;
              color: #707582; 
              &--new {
                font-size: 20px;
              }
            }
          }
          &__status-name {
            color: #1F1F52;
            font-family: Nunito Sans;
            font-size: 18px;
            &--new {
              font-size: 22px;
            }
          }
          &__tooltip-info {
            &-icon {
              font-size: 16px;
              margin-left: 5px;
              color: #8A8AC3;
            }
          }
          &__tooltip-icon {
            font-size: 20px;
            margin: 0 20px;
            color: #3E3E5B;
            opacity: 0.4;
          }
        }
        .status-info {
          margin-top: 40px;
          display: flex;
          align-items: center;
          margin-bottom: 40px;
          &__icon {
            color: #8A8AC3;
            margin-right: 10px;
          }
          &__text {
            font-family: Nunito Sans;
            font-size: 18px;
            color: #707582;
          }
        }
        .status-field {
          margin-bottom: 20px;
          &__label {
            font-family: Nunito Sans;
            font-size: 17px;
            color: #3E3E5B;
            margin-bottom: 7.5px;
          }
          .input-container {
            border: none;
            border-bottom: 1px solid #6C6BCC;
          } 
        }
      }
    }

  }  
  
`;