import styled from "styled-components";

export const ChatbotPatientCase = styled.div` 
  height: 100%;
  .chatbot-container {
    height: ${({footerHeight, selectedPatient}) => `calc(100% - ${footerHeight}px - ${selectedPatient ? "68px" : "88px"})` };
  }
  .chatbot-selectors {
    height: ${({selectedPatient}) => selectedPatient ? "68px" : "88px"};
  }
`;


