import { gql } from "@apollo/client";

export const MUTATION_INSERT_USER_TO_PROCESS_CALENDAR = gql`
  mutation MUTATION_INSERT_USER_TO_PROCESS_CALENDAR(
    $objects: [userToProcessCalendar_insert_input!]!
  ) {
    insert_userToProcessCalendar(objects: $objects) {
      returning {
        dateTime
        descript
        id
        isAccepted
        isNotify
        name
        place
        userId
        userToProcessId
      }
    }
  }
`;

export const MUTATION_UPDATE_USER_TO_PROCESS_CALENDAR = gql`
  mutation MUTATION_UPDATE_USER_TO_PROCESS_CALENDAR(
    $objects: userToProcessCalendar_set_input, 
    $eventId: uuid
  ) {
    update_userToProcessCalendar(where: {id: {_eq: $eventId}}, _set: $objects) {
      returning {
        userToProcessId
        userId
        place
        name
        isNotify
        isAccepted
        id
        descript
        dateTime
        created_at
      }
    }
  }
`;