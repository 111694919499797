import { gql } from "@apollo/client";

export const SUBSCRIPTION_GET_USER_TO_PROCESS = gql`
  subscription SUBSCRIPTION_GET_USER_TO_PROCESS(
    $patientId: uuid, 
    $processId: Int,
  ) {
    userToProcess(where: {processId: {_eq: $processId}, userId: {_eq: $patientId}}) {
      created_at
      descript
      doctorId
      id
      isAcepted
      processId
      stageId
      updated_at
      userId
      processStage {
        id
        descript
        expirationTime
        isAutomatic
        nextStageId
        processId
        stageName
      }
    }
  }
`
export const SUBSCRIPTION_GET_USER_TO_PROCESS_BY_ISACEPTED = gql`
  subscription SUBSCRIPTION_GET_USER_TO_PROCESS_BY_ISACEPTED(
    $isAcepted: Boolean
  ) {
    userToProcess(where: {isAcepted: {_eq: $isAcepted}}) {
      id
      created_at
      doctorId
      isAcepted
      processId
      stageId
      updated_at
      userId
      isChecked
      nextUpdate
      user {
        documentType
        name
        lastname
        id
        documentNumber
        picture
        phone
        weight
        height
        birthdate
        phoneCarer
        nameCarer
        email
        clinicalHistoryPdfUrl
        enDocumentType {
          comment
        }
        userToProcesses {
          id
          isChecked
          nextUpdate
          processStage {
            id
            descript
            expirationTime
            isAutomatic
            nextStageId
            processId
            stageName
            frequency
            frequencyType
          } 
          userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
            created_at
            customFormFieldChanges
            date
            descript
            doctorId
            id
            isChecked
            latestCustomFormId
            processId
            stageId
            userToProcessId
          }
          process {
            id
            processName
            processToCustomForms(order_by: {customFormId: asc}) {
              customFormId
              id
              processId
            }
          }
        }
      }
      processStage {
        id
        descript
        expirationTime
        isAutomatic
        nextStageId
        processId
        stageName
        frequency
        frequencyType
      }
      process {
        id
        nameKey
        processName
      }
      userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
        created_at
        customFormFieldChanges
        date
        descript
        doctorId
        id
        isChecked
        latestCustomFormId
        processId
        stageId
        userToProcessId
      }
    }
  }
` 

export const SUBSCRIPTION_GET_USER_TO_PROCESS_BY_ISACEPTED_AND_ID = gql`
  subscription SUBSCRIPTION_GET_USER_TO_PROCESS_BY_ISACEPTED_AND_ID($isAcepted: Boolean, $userToProcessId: uuid) {
    userToProcess(where: {isAcepted: {_eq: $isAcepted}, id: {_eq: $userToProcessId}}) {
      id
      created_at
      doctorId
      isAcepted
      processId
      stageId
      updated_at
      userId
      isChecked
      nextUpdate
      user {
        documentType
        name
        lastname
        id
        documentNumber
        picture
        phone
        weight
        height
        birthdate
        phoneCarer
        nameCarer
        email
        clinicalHistoryPdfUrl
        enDocumentType {
          comment
        }
        userToProcesses {
          id
          isChecked
          nextUpdate
          processStage {
            id
            descript
            expirationTime
            isAutomatic
            nextStageId
            processId
            stageName
            frequency
            frequencyType
          } 
          userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
            created_at
            customFormFieldChanges
            date
            descript
            doctorId
            id
            isChecked
            latestCustomFormId
            processId
            stageId
            userToProcessId
          }
          process {
            id
            processName
            processToCustomForms(order_by: {customFormId: asc}) {
              customFormId
              id
              processId
            }
          }
        }
      }
      processStage {
        id
        descript
        expirationTime
        isAutomatic
        nextStageId
        processId
        stageName
        frequency
        frequencyType
      }
      process {
        id
        nameKey
        processName
      }
      userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
        created_at
        customFormFieldChanges
        date
        descript
        doctorId
        id
        isChecked
        latestCustomFormId
        processId
        stageId
        userToProcessId
      }
    }
  }
` 