import styled from "styled-components";
const bgFlorence = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/background-florence.svg`;

export const FollowUpStages = styled.div`
  background-color: #FFF;
  border-radius: 14px;
  height: 100%;
  padding: 10px 32px 0px 32px;
  box-sizing: border-box;
  .follow-up-header {
    display: flex;
    margin-bottom: 60px;
    align-items: center;
    .return-button {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      background-color: #EEF1F4;
      display: flex;
      cursor: pointer;
      margin-right: 10px;
    }
    .return-icon {
      display: flex;
      margin: auto;
      color: #6C6BCC;
    }
    .initial-text {
      color: #3E3E5B;
      font-family: "Nunito Sans";
      font-size: 17px;
    }
  }
  .follow-ups-list {
    height: calc(100% - 140px);
    overflow: auto;
  }
  .follow-up-item {
    padding-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(66, 66, 114, 0.06);
    &:first-child {
      padding-top: 0px;
    }
    &__program {
      color: #3E3E5B;
      font-family: "Nunito Sans";
      font-size: 17px;
      margin-left: 10px;
    }
    &__content {
      width: 100%;
      display: flex;
      padding: 20px 10px;
      justify-content: space-between;
      border-radius: 10px;
      background: #F8F8FF;
      align-items: center;
      box-sizing: border-box;
    }
    &__select {
      min-width: 260px;
    }
    &__frequency {
      color: #3E3E5B;
      font-family: "Nunito Sans";
      font-size: 17px;
      b {
        margin-left: 5px;
      }
    }
  }
`;