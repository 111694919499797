import styled from "styled-components";

export const DoubleCalendarDashboard = styled.div`

  .rdp {
    --rdp-cell-size: 42px;
  }
  .rdp-caption_dropdowns {
    color: ${({topOptionsColor}) => topOptionsColor};
    font-size: 19px !important;
  }
  .rdp-nav_button_previous{
    background-color: ${({topOptionsColor}) => topOptionsColor};
    color: white !important;
    height: 33.57px;
    width: 33.57px;
    &:hover {
      background-color: ${({topOptionsColor}) => topOptionsColor} !important;
      color: white !important;
    }
  }

  .rdp-nav_button_next{
    background-color: #36369B;
    color: white !important;
    height: 30px;
    width: 30px;
    &:hover {
      background-color: #36369B !important;
      color: white !important;
    }
  }

  .rdp-head {
    color: ${({weekDaysColor}) => weekDaysColor};
    font-size: 14px !important;
    font-family: "Nunito Sans Light" !important;
  }

  .rdp-caption_label {
    padding: 8px 10px;
    border: 1px solid #526479 !important;
    border-radius: 10px !important;
    background-color: transparent !important;
    text-transform: capitalize;
    font-size: 15px;
  }

  .rdp-dropdown_month {
    margin-right: 10px;
    cursor: pointer;
  }

  .rdp-dropdown_year {
    cursor: pointer;
  }

  .rdp-dropdown_year .rdp-dropdown_icon{
    margin-left: 26px;
  }

  .rdp-dropdown_month .rdp-dropdown_icon{
    margin-left: 33px;
  }
  
  .rdp-cell {
  }
  
  .rdp-day{
    color: ${({numbersColor}) => numbersColor};
    font-size: 15px;    
    padding: 22px !important;
    &:hover {
      background-color: #D0EAFC !important;
    }
  }

  .rdp:not([dir='rtl']) .rdp-day_range_start:not(.rdp-day_range_end) {
    background-color: #1294F2 !important;
    color: white !important;
  }

  .rdp:not([dir='rtl']) .rdp-day_range_end:not(.rdp-day_range_start) {
    background-color: #1294F2 !important;
    color: white !important;
  }
  
  .rdp-day_range_middle {
    background-color: #D0EAFC!important;
  }

  .rdp-day_range_end.rdp-day_range_start {
    background-color: #1294F2 !important;
    color: white !important;
  }

  .rdp-dropdown {
    padding: 10px;
    option {
      padding: 5px 10px;
      color: #36369B !important;
      text-transform: capitalize;
      font-size: 16px;
    }
  }

  .rdp-day_today:not(.rdp-day_outside) {
    color: #2F80ED;
  }
`