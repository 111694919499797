import styled from "styled-components";

export const AiNavigatorHealth = styled.div`
  .ainavigator-content__box {
    border-radius: 14px;
    overflow: auto;
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    .section-container {
      padding: 20px;  
      &__title {
        display: flex;
        margin-bottom: 21px;
        color: #3E3DA3;
        align-items: center;
        &-icon {
  	      opacity: 0.4;
          margin-right: 6px;
          font-size: 19px;
        }
        &-text {
          font-family: "Nunito Sans Bold";
          font-size: 24px;
        }
      }
    }
    .ainavigator-content__filters-box {
      display: flex;
      justify-content: space-between;
    }
    .ainavigator-content__filters {
      display: flex;
      z-index: 1;
      /* position: fixed; */
      border-radius: 103px;
      border: 1px solid #6C6BCC;
      justify-content: space-between;
      background-color: #FFFFFF;
      padding: 5px;
      margin: 10px 20px;
      width: fit-content;
    }
    .ainavigator-content__button-add-data {
      .button-add-data {
        margin: 11px 20px;
        cursor: pointer;
        display: flex;
        width: 173px;
        padding: 5px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background: #6C6BCC;
        color: #FFF;
        font-family: "Nunito Sans";
        font-size: 20px;
        &__icon {          
          color: #FFF;
          font-size: 12px;
          margin-left: 10px;
        }
      }
    }
    .specific-date-selector-container {
      align-items: center;
      display: flex;
    }
    .specific-date-selector {
      color: #36369B;
      margin: 0px 10px;
      position: relative;
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      font-family: "Nunito Sans";
      &_date {
        align-items: flex-end;
        strong {
          margin: 0 4px;
          font-size: 0.75rem;
        }
      }
      i {
        color: #6C6BCC; 
        margin-left: 5px;
        margin-top: 2px;
        display: flex;
      }   
      /* z-index: 1;
      position: fixed;
      display: flex;
      justify-content: center; */
      /* right: 600px; */
    }
    .time-filters {
      display: flex;
      .time-filter {
        cursor: pointer;
        border-radius: 60px;
        font-size: 14px;
        padding: 5px 10px;
        /* border: 1px solid #AAAADB; */
        color: #36369B;
        margin: 0 5px;
        background-color: #FFFFFF;
        &:hover {
          /* background-color: #E0E6ED;
          border: 1px transparent solid; */
        }
        &--selected {
          background-color: #36369B;
          border: 1px solid #36369B;
          color: #FFFFFF;
          &:hover {
            background-color: #36369B;
          }
        }
      }
    }
  }
`

export const AddOptionsTooltip = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: -4px 31px 54.4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  min-width: 387px;
  .add-item {
    display: flex;
    min-height: 109px;
    width: 100%;
    &:not(:last-child) {
      border-bottom: 1px solid #E2E2E2;
    }
    &__icon-container {
      align-items: center;
      display: flex;
      &-box {
        width: 49px;
        height: 49px;
        border-radius: 60.796px;
        background: #92D46E;
        box-shadow: 0px 0px 23.593px -1.815px rgba(146, 212, 110, 0.15);
        display: flex;
        
      }
    }
    &__icon {
      margin: auto;
      color: #fff;
      font-size: 20px;
    }
    &__text {
      padding: 13px 0;
      margin-left: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      &-title {
        color: var(--Night-blue, #1F1F52);
        font-family: "Nunito Sans Bold";
        font-size: 17px;
        margin-bottom: 10px;
      }
      &-option {
        cursor: pointer;
        color: #1F1F52;
        font-family: "Nunito Sans";
        font-size: 15px;
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }
  }
`

export const DateOptions = styled.div`
  position: relative;
  background-color: var(--white);
  height: 120px;
  width: 145px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  &::-webkit-scrollbar{
    display: none;
  }
  .select-date {
    font-size: 0.75rem;
    color: #36369B !important;
    cursor: pointer;
    padding: 5px 10px;
    font-size: 14px;
  }
`