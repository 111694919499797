import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import ReactGA from "react-ga";

const env = process.env.REACT_APP_ENV;

const PrivateRoute = ({ component: Component, authed, ...rest }) => {

  useEffect(() => {
    if (env === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, [Component])
  
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )
      }
    />
  );
};

export default PrivateRoute;
