import React from "react";
import * as S from "./FilterPrograms.style";
import { useTranslation } from "react-i18next";

import Popup from "reactjs-popup";


// Pipes
import * as P from '../../../../utils/pipes';

import DropdownCheck from "../dropdownCheck";
import { QUERY_GET_PROCESS_NAMES } from "../../../../graphql/queries/Process";
import { useQuery } from "@apollo/client";

import usePatientList from "../../../../hooks/usePatientList";

function FilterPrograms({
  processIds,
  setProcessIds,
  activeQuantity=true
}) {
  const [t] = useTranslation("global");
  const { i18n: { language } } = useTranslation("global");
  const { data: processData } = useQuery( QUERY_GET_PROCESS_NAMES );  
  const {getPatientsByProcessId} = usePatientList();

  return (
    <>
      <S.FilterPrograms>
        <p>{t("globally.filterBy")}</p>
        <Popup 
          trigger={
            <div className="dropdown-activer">
              <h5>
                {t("globally.programs")}
              </h5>
              <i
                className="icon zaia-icono-flechas-arriba-abajo"
                style={{ cursor: "pointer" }}
              ></i>
            </div>
          }
          position='bottom left'
        >
          <S.DropdownContainer>
            <DropdownCheck
              setOptionsSelectedIds={setProcessIds} 
              optionsSelectedIds={processIds} 
              quantityActive={activeQuantity} 
              multipleSelection={true}
              options={processData?.process.map(
                process => {
                  return {
                    id: process.id,
                    name: P.dataTranslation(process.processName, language),
                    quantity: getPatientsByProcessId(process.id)?.length
                  }
                }
              )}
            />
          </S.DropdownContainer>
        </Popup>
      </S.FilterPrograms>
    </>
  )
}

export default FilterPrograms;