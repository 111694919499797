import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./NoteCreationEditionMaxView.style";
import NoteText from "../noteText";
import NoteForm from "../noteForm";

function NoteCreationEditionMaxView({
  maxViewActived,
  setCreateNoteActived,
  editNoteActived,
  setEditNoteActived,
  formNoteText,
  setFormNoteText,
  patientSelected,
  userToProcessSelected,
  setUserToProcessSelected,
  refetchUserToProcessHistory
}) {

  const { t } = useTranslation("global"); 

  return (
    <S.NoteCreationEditionMaxView>
      <div className="notes-view">
        <div className="notes-view__left">
          <NoteText 
            maxViewActived={true}
            editNoteActived={editNoteActived}
            formNoteText={formNoteText}
            setFormNoteText={setFormNoteText}
            userToProcessSelected={userToProcessSelected}
          />
        </div>
        <div className="notes-view__right">
          <div className="notes-view__right-content">
            <NoteForm
              maxViewActived={true}
              setCreateNoteActived={setCreateNoteActived}
              editNoteActived={editNoteActived}
              setEditNoteActived={setEditNoteActived}
              patientSelected={patientSelected}
              formNoteText={formNoteText}
              setFormNoteText={setFormNoteText}              
              userToProcessSelected={userToProcessSelected}
              setUserToProcessSelected={setUserToProcessSelected}
              refetchUserToProcessHistory={refetchUserToProcessHistory}
            />
          </div> 
        </div>
      </div>
    </S.NoteCreationEditionMaxView>
  );
}

export default NoteCreationEditionMaxView;
