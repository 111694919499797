

import React, { useEffect, useMemo, useState } from "react";
import * as S from  "./PatientReport.style";
import * as P from "../../../../../utils/pipes";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import AliceCarousel from "react-alice-carousel";
import { useTranslation } from "react-i18next";
import ModalSurveys from "../modalSurveys/ModalSurveys";
import Modal from "../../../../../utils/modal";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

const zaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;

function PatientReport({
  reportSelected,
  setReportSelected,
  patientData
}) {  

  const {t, i18n: { language }} = useTranslation("global");
  const { patientID } = useParams();

  const [dateSelected, setDateSelected] = useState();
  const [modalSendSurveyActive, setModalSendSurveyActive] = useState();
  
  const carouselButton = (position, disabled) => {
    return (
      <S.CarouselButton position={position} disabled={disabled} type="button">
        <i
          className={`icon ${
            position === "prev" ? "zaia-icono-atras" : "zaia-i-next"
          }`}
        />
      </S.CarouselButton>
    );
  };

  const historyDates = useMemo(() => {
    return (
      reportSelected.customForm.userCustomFormHistories.map((history) => {
        const date = new Date(history.created_at);
        return {
          dateText: P.formatNumberDate(date, true, language),
          date: history.created_at 
        };
      })
    )
  }, [reportSelected])

  useEffect(() => {
    setDateSelected(historyDates[0]);
  }, [historyDates])


    return (
      <S.PatientReport>
        <Modal open={modalSendSurveyActive}>
          <ModalSurveys
            setModalActive={setModalSendSurveyActive}
            processId={patientData?.userToProcesses.find((userToProcess) => userToProcess.process?.nameKey === "Police-Homeless")?.process.id}
            patientId={patientID}
            surveyId={reportSelected.customForm.id}
            reportName={P.dataTranslation(reportSelected.customForm.customFormName, language)}
          />
        </Modal>
        <div className="header">
          <button className="button-back"
              onClick={() => setReportSelected(false)}
            >
              <i className="zaia-icono-atras icon button-back__icon"></i>
            <img src={patientData?.picture} alt="" className="patient-image" />
            <div className="patient-name">
              {patientData.name + " " + patientData.lastname}
            </div>
          </button>
        </div>
        <div className="report-data">
          <div className="report-info">
            <i className={`report-info__icon icon ${reportSelected.icon}`}></i>
            <div className="report-info__title">
              {P.dataTranslation(reportSelected.customForm.customFormName, language)}
            </div>
            <div className="report-info__number">
              {reportSelected.customForm.userCustomFormHistories.length}
            </div>
            <div className="report-info__time-reports">
              {t("police.reportsTotal")}
            </div>
          </div>
          <div className="report-values-list">
            {
              !reportSelected.customForm.userCustomFormHistories[0] &&
              // reportSelected.customForm.formGroups[0].customFormFields.map(
              //   (field) => {
              //     return (
              //       <div className="report-value">
              //         <div className="report-value__label">
              //           {P.dataTranslation(field.fieldName, language)}
              //         </div>
              //         <div className="report-value__value"></div>
              //       </div>
              //     )
              //   }
              // )
              <ZaiaReaction
                zaiaImg={zaiaEmpty}
                text={t("police.noResults")}
                // subText={}
                sizeText="33.25px"
                sizeSubText="15px"
                textColor="var(--blue-color)"
                widthImg="210px"
              />
            }
            {
              reportSelected.customForm.userCustomFormHistories[0] &&
              reportSelected.customForm.userCustomFormHistories.find((history) => history.created_at === dateSelected?.date)?.customFormFields?.map(
                (field) => {
                  return (
                    <div className="report-value">
                      <div className="report-value__label">
                        {P.dataTranslation(field.customFormField.fieldName, language)}
                      </div>
                      <div className="report-value__value">
                        {
                          field.customFormField.typeFieldValue === "dateInput"
                          ? P.formatNumberDate(new Date(field.value), true, language)
                          : field.value
                        }
                      </div>
                    </div>
                  )
                }
              )
            }
          </div>
          <div className="add-report">
            <button className="add-report__button" 
              onClick={() => setModalSendSurveyActive(true)}
            >
              <i className="add-report__icon zaia-icono-agregar icon"></i>
            </button>
          </div>
        </div>
        <div className="report-days-list">
          <div className="report-days-list__label">{t("police.selectReportDay")}</div>
          <div className="dates-container">
            <div className="slider-container">
              <AliceCarousel
                // mouseTracking
                disableDotsControls
                innerWidth
                autoWidth
                renderPrevButton={(e) => carouselButton("prev", e.isDisabled)}
                renderNextButton={(e) => carouselButton("next", e.isDisabled)}
              >
                { 
                  historyDates
                  .map((date) => {
                    return (
                      <S.SliderBox 
                        selected={date.date === dateSelected?.date}
                      >
                        <div
                          className="slider-box"
                          onClick={() => {
                            setDateSelected(date)
                          }}  
                        >
                          <div className="slider-box-title">
                            {
                              date.dateText
                            }
                          </div>
                        </div>
                      </S.SliderBox>
                    );
                  })
                }
              </AliceCarousel>
            </div>
          </div>
        </div>
      </S.PatientReport>
    )
  }
  
  export default PatientReport;