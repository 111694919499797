import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./PatientSelection.style";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import Modal from "../../../../../utils/modal";
import ModalSelectPatient from "../../../shared/modals/modalSelectPatient";

const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;
const feather = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/feather.svg`;

function PatientSelection({
  patientSelected,
  setPatientSelected,
  patientSelectionActived,
  setPatientSelectionActived
}) {

  const { t } = useTranslation("global"); 

  return (
    <S.PatientSelection>
      { 
        <Modal open={patientSelectionActived}>
          <ModalSelectPatient
            setModalActive={setPatientSelectionActived}
            setPatient={setPatientSelected}
          />
        </Modal>
      }
      <button className="button-patient-selector"
        onClick={() => setPatientSelectionActived(true)}
      >
        <div className="button-patient-selector__icon-container">
          <i className="button-patient-selector__icon icon zaia-i-person"></i>
        </div> 
        <div className="button-patient-selector__text">
          {t("notes.selectPatientStart")}
        </div>
      </button>
      <div className="message-space">
        <img src={feather} alt="" className="message-space__img" />
        <div className="message-space__text">
          {t("notes.addNotesText")}
        </div>
      </div>
      <div className="space-add-note">
        <button className="button-add-note">
          <div className="button-add-note__text">{t("notes.addNote")}</div>
          <div className="button-add-note__icon icon zaia-i-lock-chat"></div>
        </button>

      </div>
    </S.PatientSelection>
  );
}

export default PatientSelection;
