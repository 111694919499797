import styled from "styled-components";

export const Box = styled.div`
  /* box-shadow: 0px 0px 4px rgba(196, 20, 20, 0.2); */
  table {
    width: 100%; 
    border-spacing: 0 10px;
    border-collapse: separate;
    table-layout: fixed;
    tr {
      &:hover {
        background-color: #F0F0FF;
      }

      &:hover .ai-navigator__button {
        background-color: #FFF;
      }

      td {
        padding: 14px 35px;
        border-bottom: 1px solid #F6F6FF;
        vertical-align: middle;
        height: 100%;
      }


      .patient-name {
        font-family: 'Nunito Sans Bold';
        display: flex;
        align-items: center;
        &__icon-selection {
          font-size: 20px;
          color: #6C6BCC;
          margin-right: 10px;
        }
        &__text {
          font-family: Nunito Sans;
          font-size: 17px;
          margin-left: 5px;
        }
      }
      
      .card-info {
        .card {
          width: fit-content;
          padding: 7px 10px 7px 10px;
          border-radius: 12px;
          border: 1px solid #F0F0FF;
          display: flex;
          background-color: #FFF;
          &__left {
            margin-right: 10px;
            min-width: 228px;
          }
          &__right {
            min-width: 345px;
            color: #3E3E5B;
          }
          &__date {
            font-family: Nunito Sans;
            font-size: 14px;
            /* margin-left: 40px; */
            color: #707582;
          }
          &__level {
            display: flex;
            align-items: center;
            &-icon {
              font-size: 23px;
              margin-right: 6px;
            }
            &-text {
              font-family: Nunito Sans;
              font-size: 17px;
            }
          }
          &__type {
            font-family: Nunito Sans;
            font-size: 15px;
            text-align: center;
            color: #3E3E5B;
            margin-left: 10px;
          }
          &__category {
            display: flex;
            align-items: center;
            padding: 2px 10px;
            width: fit-content;
            &--selected {
              border-radius: 10px;
              background-color: #F0F0FF;
            }
          }
        }
      }

      .ai-navigator {
        display: flex;
        flex-direction: column;
        align-items: end;
        &__button {
          padding: 3px 10px;
          border-radius: 10px;
          width: fit-content;
          cursor: pointer;
          background-color: #F0F0FF;
          &--selected {
            background-color: #FFF;
          }
        }
        &__checked{
          justify-content: flex-end;
          display: flex;
          align-items: center;
          margin-top: 7.5px;
          &-text {
            font-family: Nunito Sans;
            font-size: 16px;
            color: #3E3E5B;
          }
          &-icon {
            font-size: 15px;
            margin-left: 10px;
            color: #3E3E5B;
          }
        }
      }
    }
  }
`;