import styled from "styled-components";

export const AlgorithmsView = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  height: 100%;
  width: 100%;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
    margin-bottom: 10px;
    padding: 0 20px;
    height: 70px;
    box-sizing: border-box;
    .rightsideAlgorithms__headerTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      .rightsideAlgorithms__headerIcon {
        background-color: var(--blue-color);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 30px;
        width: 30px;
        border-radius: 10px;
        color: white;
        box-shadow: 0 0 8px #dbdbe3;
      }
      h4 {
        margin-left: 5px;
        color: var(--title-register-color);
        font-family: "Nunito Bold";
        font-size: 15px;
        strong {
          font-family: "Nunito ExtraBold";
        }
      }
    }
  }
  span {
    color: var(--subtitle-color);
    font-size: 0.7rem;
    text-align: center;
    font-weight: 400;
  }
  .rightside__containerMsgs {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding: 10px 15px 10px 40px;
    height: -webkit-fill-available;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      /* display: none; */
    }
    &::-webkit-scrollbar-track {
      border: solid 10px transparent;


      box-shadow: inset 0 0 10px 10px transparent;      
    }
  }
  .messageDisplay__containerFooter {
    background-color: var(--footer-color);
    padding: 15px 0;
    display: flex;
    color: var(--blue-color);
    align-items: center;
    justify-content: space-evenly;
    input {
      border: none;
      border-radius: 20px;
      padding: 10px;
      width: 90%;
      margin: 0 5px 0 5px;
      outline: none;
      ::placeholder {
        color: #e3e3e3;
        font-family: "Nunito";
      }
    }
    button {
      color: var(--blue-color);
      border: none;
      cursor: pointer;  
      display: flex;
      i{
        font-size: 25px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .rightside__containerMsgs {
      padding: 10px 0px 10px 25px;
    }
    .messageDisplay__containerFooter {
    padding: 15px;
    }
  }
`;
