import styled from "styled-components";

export const ChatbotProcessCase = styled.div` 
  height: 100%;
  .chatbot-container {
    height: ${(
      {
        footerHeight,
        selectedProcess, 
        selectedPartition
      }
    ) => 
      `calc(
        100% - 
        ${footerHeight}px - 
        ${
          (selectedProcess && selectedPartition)
          ? "0px"
          : !selectedProcess && !selectedPartition ? "152px" : "86px"
        }
      )` 
    };
  }
  .chatbot-selectors {
    height: ${(
      {
        selectedProcess, 
        selectedPartition
      }
    ) => 
    selectedProcess && selectedPartition 
      ? "0px" 
      : !selectedProcess && !selectedPartition ? "132px" : "66px"
    };
    padding-top: ${(
      {
        selectedProcess, 
        selectedPartition
      }
    ) => 
    (selectedProcess && selectedPartition) 
      ? "0px" 
      : "20px"
    };
  }
`;


