import React, { useMemo } from "react";
import * as S from "./CmpVerticalOptions.style";
import { useTranslation } from "react-i18next";

// pipes
import * as P from "../../../../../../../utils/pipes";
import Popup from "reactjs-popup";
import useChatVerification from "../../../../../../../hooks/useGetListOptions";

function CmpVerticalOptions({
  title,
  item,
  insertfunction,
  customFormChangesList,
  customFormChangeItem,
  customFormFields,
  isHistorical,
}) {
  const {
    i18n: { language },
  } = useTranslation("global");  

  const itemParent = useMemo(() => {
    const itemParent = customFormChangesList.find(
      (change) => change.customFormFieldId === item?.customFormFieldParentId
    );
    return itemParent;
  }, [item, customFormChangesList]);

  const {listOptions} = useChatVerification(item, itemParent, customFormFields, isHistorical);

  const changeOptionItem = (fieldOption) => {
    const userToCustomFormField = {
      customFormFieldId: item?.id,
      parentKey: item?.customFormTypeField.fieldOptions.find(
        (field) => field.key === fieldOption.key
      ).parentKey,
      customFormFieldParentId: item?.customFormFieldParentId,
      value: P.dataTranslation(fieldOption.names, language),
      key: fieldOption.key,
    };
    insertfunction(userToCustomFormField);
  };

  const optionKey = customFormChangeItem && customFormChangeItem.key;  

  return (
    <S.CmpVerticalOptions>
      <div className="title">{P.dataTranslation(item?.fieldName, language)}</div>
      <div className="boxes-container">
        {listOptions.map((data) => {
          let payload;
          if (P.dataTranslation(data.payload, language)) {
            payload = P.dataTranslation(data.payload, language);
          } else {
            const payloadFormated = JSON.parse(data.payload);
            try {
              payload = "msg" in payloadFormated ? payloadFormated.msg : null;
            } catch (err) {
              payload = null;
            }
          }
          return (
            <S.Box
              active={data.key === optionKey}
              key={data.id}
              onClick={() => changeOptionItem(data)}
            >
              <div className="box">
                <div className="box-name">
                  {P.dataTranslation(data.names, language)}
                </div>
                {!payload ? null : (
                  <Popup
                    trigger={<i className="box-icon icon zaia-alert"></i>}
                    on={["hover", "focus"]}
                    closeOnDocumentClick
                    keepTooltipInside
                    position={["right"]}
                    nested
                  >
                    <S.PopUpPayload>
                      <p>{payload}</p>
                    </S.PopUpPayload>
                  </Popup>
                )}
              </div>
            </S.Box>
          );
        })}
      </div>
    </S.CmpVerticalOptions>
  );
}

export default CmpVerticalOptions;
