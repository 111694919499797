import React, { useState } from "react";
import * as S from  "./RangeDatesSelectorPanel.style";
import DoubleCalendarDashboard from "../../dashboard/components/doubleCalendarDashboard";
import { useTranslation } from "react-i18next";
import ZaiaButton from "../../../zaiaComponents/zaiaButton";


function RangeDatesSelectorPanel({
  setFilterOptionsActive,
  initialDateRange,
  finishDateRange,
  setInitialDateRange,
  setFinishDateRange,
}) {

    const [t] = useTranslation("global");
    const [initialDateRangeTemp, setInitialDateRangeTemp] = useState(initialDateRange);
    const [finishDateRangeTemp, setFinishDateRangeTemp] = useState(finishDateRange);

    const selectRange = () => {
      setInitialDateRange(initialDateRangeTemp);
      setFinishDateRange(finishDateRangeTemp);
      setFilterOptionsActive(null);
    }

    const deleteRange = () => {
      setInitialDateRange(null);
      setFinishDateRange(null);
      setInitialDateRangeTemp(null);
      setFinishDateRangeTemp(null);
      // setFilterOptionsActive(null);
    }

    return (
      <S.RangeDatesSelectorPanel>
        <div className="close" onClick={() => setFilterOptionsActive(null)}>
          <i className="close__icon icon zaia-icono-cerrar"/>
        </div>
        <div className="range-text">
          {t("filterPanel.calendar.calendarRangeText")}
        </div>
        <div className="calendars-container">
          <div className="calendar-container">
            <DoubleCalendarDashboard
              setInitialDateRange={setInitialDateRangeTemp}
              setFinishDateRange={setFinishDateRangeTemp}
              initialDateRange={initialDateRangeTemp}
              finishDateRange={finishDateRangeTemp}
              topOptionsColor="#36369B"
              weekDaysColor="#8F8FBB"
              numbersColor="#68688D"
              currentDayColor="#1294F2"
              dateSelectedPrimaryColor="#1294F2"
              dateSelectedSecondColor=""
              backgroundColor="#E0E6ED"
            />
          </div>
        </div>
        <div className="buttons-container">
          <div className="button-container"
            onClick={() => deleteRange()}
          >
            <ZaiaButton
              // text={t("filterPanel.calendar.cancelButton")}
              title={"Limpiar filtro"}
              height={"48"}
              fontSize={"20"}
              color={"#36369B"}
              border={true}
              borderRadius={"10"}
              shadow={false}
              backgroundColor={"#FFFFFF"}
            />
          </div>
          <div className="button-container"
            onClick={() => selectRange()}
          >
            <ZaiaButton
              title={"Aplicar filtro"}
              height={"48"}
              fontSize={"20"}
              color={"#FFFFFF"}
              border={false}
              borderRadius={"10"}
              shadow={false}
              backgroundColor={"#27AE60"}
            />
          </div>
        </div>
      </S.RangeDatesSelectorPanel>
    )
  }
  
  export default RangeDatesSelectorPanel