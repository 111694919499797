import styled from "styled-components";

export const CmpYesNoList = styled.div`
  border-radius: 10px;
  margin-bottom: ${({last}) => last ? '' : '5px'};
  /* color: var(--title-register-color); */
  /* display: flex; */
  justify-content: space-between;
  .yesNoList-name {
    font-family: "Nunito Sans";
    font-size: 13px;
    margin: auto;
  }
  .yesNoList-states {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 3px;
    .yesNoList-state {
      cursor: pointer;
      height: 22px;
      border-radius: 13px;
      width: 49%;
      color: var(--white);
      font-family: "Nunito Sans";
      font-size: 11px;
      display: flex;
      align-items: center;
      justify-content: center;   
      background-color: var(--text-area-grey);   


      color: #6C6BCC;
      font-family: "Nunito Sans";
      font-size: 19px;
      font-style: normal;
      display: flex;
      padding: 20px 0px;
      justify-content: center;
      align-items: center;
      border-radius: 14px;
      border: 1px solid #6C6BCC;
      background: #FFF;
      &--selected {
        color: white;
        background-color: #6C6BCC;    
      }
    }
  }
`
