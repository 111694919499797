import styled from "styled-components";

export const GraphNumericSurvey = styled.div`
  .tooltip-chart {
    max-width: 335px;
    &__header {
      display: flex;
      padding: 10px 10px 10px 31px;
      background: #F2F2FF;
      font-size: 14px;
    }
    &__content {
      display: flex;
      padding: 20px 10px;
      align-items: center;
    }
    &__point {
      min-width: 16px;
      height: 16px;
      background-color: #53C25D;
      border-radius: 50%;
      justify-content: center;
    }
    &__name {
      &-box {
        display: flex;
        flex-direction: column;
      }
      color: #36369B;
      font-family: "Nunito Sans";
      font-size: 14px;
      margin: 3px 5px;
      white-space: break-spaces;
    }
    &__value {
      font-family: "Nunito Sans Bold";
      font-size: 12px;
    }
    &__measure {
      font-family: "Nunito Sans Bold";
      font-size: 12px;
    }
    &__date {
      display: flex;
      align-items: center;
      margin-top: 10px; 
      color: #3E3E5B;
      font-family: "Nunito Sans";
      font-size: 14px;
      padding: 0 10px 10px 10px;
      .date-label {
        margin-left: 5px;
      }
      .date-text {
        margin-left: 5px;
      }
    }
    &__comment {
      border-radius: 5px;
      margin: 0 10px 10px 10px;
      background-color: #F2F2FA;
      padding: 10px 5px;
      .comment-title {
        display: flex;
        align-items: center;
      }
      .comment-label {
        color: #36369B;
        font-family: "Nunito Sans Bold";
        font-size: 14px;
        margin-left: 5px;
      }
      .comment-text {
        color: #36369B;
        margin: 0 20px;
      }
      .comment-icon {
        color: #8F8FBB;
        font-size: 16px;
      }
    }
  }
`