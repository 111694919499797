import React, { useEffect, useMemo, useState } from "react";
import * as S from "./SurveySearcher.style";
import { useTranslation } from "react-i18next";
import ZaiaInput from "../../../../../../zaiaComponents/zaiaInput";
import * as P from "../../../../../../../utils/pipes";
import { v4 as uuidv4 } from 'uuid';

function SurveySearcher({
  selectedProcess,
  changeFormField,
  processId,
  goNextInput,
  processList
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    // get patient with id patientId from patientList
    const process = processList?.find((process) => process.value === processId);
    if(!selectedProcess.value && process) {
      changeFormField(
        [
          {
            value: process.value,
            text: process.text,
            fieldName: "program"
          }
        ]
      )
    }
  }, [processId, processList]);

  const getFilterText = (text) => {
    return filterText 
      ? String(P.removeAccent(text)).toLowerCase().includes(P.removeAccent(filterText).toLowerCase())
      : true
  }

  const getMedicationList = () => {

    return (
      <>
        <div className={`list__results ${filterText === "" && "list__results--hidden"}`}>{t("globally.resultsFor")} "{filterText}"</div>
        <div className="list__container">
          {
            processList?.map((drug) => {
              if(getFilterText(drug.text)) {
                return (
                  <div 
                    key={uuidv4()}
                    className={`name ${drug.value === selectedProcess.value && drug.text === selectedProcess.text && "name--selected"}`}
                    onClick={() => 
                      {
                        if(drug.value !== selectedProcess.value && drug.text !== selectedProcess.text) {
                          changeFormField(
                            [
                              {
                                value: drug.value,
                                text: drug.text,
                                fieldName: "program"
                              },
                              {
                                value: null,
                                text: "",
                                fieldName: "survey"
                              },
                              {
                                value: [],
                                text: "",
                                fieldName: "patientsList"
                              },
                            ]
                          )
                          goNextInput()
                        }
                      }
                    }
                  >{drug.text}</div>
                )
              } else {
                return null
              }
            })
          }
        </div>
      </>
    )

  }

  return (
    <S.SurveySearcher
      searcher={filterText}
    >
      <div className="input-box">
        <ZaiaInput
          textColor={"#59599E"}
          borderColor={"#68688D"}
          placeholderTextColor={"#878792"}
          fontSize={"12px"}
          placeholder={t("surveys.sendSurveyModal.searchProgram")}
          type="text"
          name="description"
          padding={"9px 20px"}
          borderRadius={"10px"}
          errorOption={false}
          height={"100%"}
          getText={(e) => setFilterText(e)}
          backgroundColor={"transparent"}
          value={filterText}
        />
      </div>
      <div className="list">
        {
          getMedicationList()
        }
      </div>
      
    </S.SurveySearcher>
  );
}

export default SurveySearcher;
