import React, { useMemo } from "react";
import * as S from "./MedicationSelector.style";
import { useTranslation } from "react-i18next";
import * as P from "../../../../../../../../utils/pipes";

function MedicationSelector({
  selectedMedication,
  changeFormField,
  drugList
}) {

  const {t, i18n: { language }} = useTranslation("global");
  
  const drugsOrder = useMemo(() => {
    let drugPatientListTemp = []

    if(drugList) {
  
      drugPatientListTemp = JSON.parse(JSON.stringify(drugList))
  
      drugPatientListTemp.sort((a, b) => {
        
        const aDrugName = P.removeAccent(a.displayName ? a.displayName : a.drug.genericName);
        const bDrugName = P.removeAccent(b.displayName ? b.displayName : b.drug.genericName);
        if (aDrugName > bDrugName) {
          return 1;
        }
        if (aDrugName < bDrugName) {
          return -1;
        }
        return 0;
      });
  
    } 
    return drugPatientListTemp;

  }, [drugList])

  return (
    <S.MedicationSelector>
      <div className="title">Medications that the patient should take</div>
      <div className="medication-list">
        <div className="medication-list__container">
          {
            drugsOrder?.map((drug) => {
              return (
                <div 
                  key={drug.id}
                  className={`medication-name ${drug.id === selectedMedication.value && "medication-name--selected"}`}
                  onClick={() => 
                    changeFormField(
                      [
                        {
                          value: drug.id, 
                          text: drug.displayName ? drug.displayName : drug.drug.genericName, 
                          fieldName: "medication"
                        }
                      ]
                    )
                  }
                >
                  {drug.displayName ? drug.displayName : drug.drug.genericName}
                </div>
              )
            })
          }
        </div>
      </div>
    </S.MedicationSelector>
  );
}

export default MedicationSelector;
