import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalRangeCalendar = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: var(--white);
    width: 850px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 50px;
    display: flex;
    justify-content: center;
    position: relative;
    .history-table{
      padding: 25px 40px 25px 40px;
      box-sizing: border-box;
      height: calc(100% - 84px);
      overflow: auto;
      table {
        width: 100%;
        border-collapse: collapse;
        font-size: 15px;
        thead {
          padding: 10px;
          tr {
            th {
              /* border-bottom: 1.6px solid var(--no-notification-background); */
              color: var(--blue-color);
              color: #38379C;
              font-family: "Nunito Sans";
              font-size: 15px;
              padding: 18px 10px 15px 10px;
              text-align: left;
              font-weight: 400;
              text-align: center;
            }
            .th-event-type{
              font-family: "Nunito Sans Bold";
            }
          }
        }
        .tbody-content {
          /* width: 100%; */
          padding-bottom: 40px;
          border-bottom: 1px solid #DDDDDD;
          /* margin-bottom: 20px; */
        }
        tbody {
          .table-date-label {
            padding: 20px 0px 10px 20px;
            padding-top: 20px;
            padding-left: 20px;
            padding-bottom: 10px;
            color: #68688D;
            font-family: "Nunito Sans Bold";
            font-size: 25.5px;
          }
          i {
            /* display: inline; */
          }
          td:first-child,
          th:first-child {
            border-radius: 14px 0 0 14px;
          }
          td:last-child,
          th:last-child {
            border-radius: 0 14px 14px 0;
          }
          tr {
            .icon-save-form-field {
              /* margin-right: 10px;
              color: ${({theme}) => theme.inputSelected};
              cursor: pointer; */
            }
            .td-index0 {
              display: flex;
              align-items: center;
            }
            td {
              padding: 13px 10px;
              text-align: left;
              color: var(--blue-dark-color);
              /* vertical-align: text-top; */
              .detail-item {
                display: flex;
                align-items: center;
                color: var(--blue-color);
                font-family: "Nunito Sans Bold";
                font-size: 15px;
                margin-bottom: 10px;
              }
              .template-header-item-icon {
                color: var(--blue-color);
                font-size: 12px;
                margin-left: 5px;
              }
              .event-type {
                &__name {
                  color: #23234F;
                  font-family: "Nunito Sans Light";
                  font-size: 15px;
                  margin-bottom: 5px;
                  display: flex;
                  align-items: center;
                  .template-header-item-icon {
                    color: #38379C;
                    font-size: 13px;
                  }
                }
                &__option {
                  margin-top: 5px;
                  cursor: pointer;
                  font-family: "Nunito Sans";
                  font-size: 15px;
                  font-style: normal;
                  color: #38379C;
                  display: flex;
                  align-items: center;
                  &-icon {
                    font-size: 13px;
                    display: flex;
                    margin-right: 5px;
                  }
                  &-text {
                    text-decoration: underline;
                  }
                }
              }
            }
            .illness-control-document-icon {
              margin-left: 19px;
              font-size: 17px;
              display: flex;
              cursor: pointer;
              color: var(--blue-color);
            }
            :nth-child(even) {
              background-color: var(--table-color);
              border-radius: 14px;
            }
          }
        }
      }
    } 
  }
`;

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Open Sans';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`