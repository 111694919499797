import { gql } from "@apollo/client";

export const MUTATION_SIGN_UP_DOCTOR = gql`
  mutation MUTATION_SIGN_UP_DOCTOR(
    $email: String!
    $lastname: String!
    $name: String!
    $password: String!
    $captchaToken: String!
  ) {
    signupDoctor (
      email: $email
      lastname: $lastname
      name: $name
      password: $password
      captchaToken: $captchaToken
    ) {
      message
    }
  }
`;

export const MUTATION_CHANGE_PASS = gql`
  mutation MUTATION_CHANGE_PASS(
    $newPass: String!, 
    $pass: String!
  ) {
    change_doctor_password(
      new_password: $newPass, 
      old_password: $pass, 
      revoke_all_sessions_associated: false
    ) {
      failed_parameter
      message
      status
      success
    }
  }
`;

export const MUTATION_ACTIVE_TOKEN = gql`
  mutation MUTATION_ACTIVE_TOKEN(
    $email: String!, 
    $token: String!, 
    $pass: String
  ) {
    verifyTokenDoctor(email: $email, verifiedToken: $token, password: $pass) {
      accessToken
    }
  }
`