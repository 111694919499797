import styled from "styled-components";

export const SurveyPatients = styled.div`
  height: 100%;
  position: relative;
  .surveys-list-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      font-family: "Nunito Sans Bold";
      font-size: 16px;
      color: #1F1F52;
    }
    &__remove-selection {
      cursor: pointer;
      font-family: Nunito Sans;
      font-size: 11px;
      color: #3E3DA3;
      text-decoration: underline;
    }
  }
  .input-container {
    margin: 20px 0px;
  }
  .input-msg {
    color: #8C8DA4;
    font-family: Nunito Sans;
    font-size: 15px;
    margin-bottom: 20px;
  }
  .symptoms-list {
    height: ${({searcher}) => searcher === "" ? "calc(100% - 142px)" : "calc(100% - 186px)"} ;
    &__results {
      color: #C0BFDC;
      font-family: "Nunito Sans";
      font-size: 17px;
      font-style: italic;
      margin: 20px 27px;
        &--hidden {
          /* visibility: hidden; */
        display: none;
      }
    }
    &__container {
      padding: 0 10px;
      overflow: auto;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .symptom-box {
      padding: 23px 20px;
      color: #818298;
      font-family: "Nunito Sans";
      font-size: 19px;
      cursor: pointer;
      transition: 0.3s ease;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      &__icon {
        margin-right: 10px;
        font-size: 14px !important;
      }
      &__name {
        margin-left: 20px;
      }
      &--selected {
        color: #3E3DA3;
        font-family: "Nunito Sans";
        font-size: 23px;
        padding: 20px;
        background-color: #D6D6F6;
        border-radius: 5px;
      }
    }
    .zaia-reaction {
      height: 100%;
      display: flex;
    }
    .search-symptoms {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: #3E3DA3;
      margin: auto;
      &__img {
        width: 250px;
        justify-content: center;
        align-self: center;
      }
      &__text {
        color: #36369B;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 24px;
        margin-top: 40px;
      }
    }
    .empty-symptoms {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: #3E3DA3;
      margin: 40px;
      &__img {
        width: 200px;
        justify-content: center;
        align-self: center;
      }
      &__title {
        font-family: "Nunito Sans Bold";
        font-size: 30px;
        margin-top: 20px;
      }
      &__subtitle {
        margin-top: 20px;
        font-size: 1em;
        color: #37379B;
      }
    }
  }
`