import { gql } from "@apollo/client";

export const MUTATION_EDIT_DOCTOR = gql`
  mutation MUTATION_EDIT_DOCTOR(
    $changes: doctor_set_input,
    $doctorId: uuid
  ) {
    update_doctor(where: {id: {_eq: $doctorId}}, _set: $changes) {    
      affected_rows
      returning {
        id
        picture
        isEmailVerified
        email
        name
        lastname
        birthdate
        countryPhoneId
        phone
        documentType
        documentNumber
        medicalCode
      }
    }
  }
`;

// doctorData_insert_input = [{academicTitle: fpkValue , medicalField: fpkValue, universityName: text}]
export const MUTATION_INSERT_DOCTOR_DATA = gql`
  mutation MUTATION_INSERT_DOCTOR_DATA (
    $doctorData: [doctorData_insert_input!]!
  ) {
    insert_doctorData(objects: $doctorData) {
      returning {
        academicTitle
        doctorId
        id
        medicalField
        universityName
      }
    }
  }
`

// doctorData_set_input = {academicTitle: fpkValue , medicalField: fpkValue, universityName: text}
export const MUTATION_UPDATE_DOCTOR_DATA = gql`
  mutation MUTATION_UPDATE_DOCTOR_DATA(
    $idDoctorData: uuid!
    $updateDoctorData: doctorData_set_input!,
  ) {
    update_doctorData_by_pk(pk_columns: {id: $idDoctorData}, _set: $updateDoctorData) {
      academicTitle
      doctorId
      id
      universityName
      medicalField
    }
  }
`

export const MUTATION_UPDATE_DOCTOR_IMG = gql`
  mutation MUTATION_UPDATE_DOCTOR_IMG(
    $pictureInBase64: String!
  ) {
    uploadDoctorPicture(pictureInBase64: $pictureInBase64) {
      message
      pictureUrl
      success
    }
  }
`

export const MUTATION_INSERT_DOCTOR_TRACKING = gql`
  mutation MUTATION_INSERT_DOCTOR_TRACKING(
    $patientId: uuid
    $programId: Int
    $sectionId: String
    $doctorId: uuid
  ) {
    insert_doctorTracking(
      objects: {
        patientId: $patientId, 
        programId: $programId, 
        sectionId: $sectionId,
        doctorId: $doctorId
      }
    ) {
      returning {
        created_at
        doctorId
        id
        patientId
        programId
        sectionId
        updated_at
      }
    }
  }
`

export const MUTATION_INSERT_DOCTOR_TO_PROCESS_ROLE = gql`
  mutation MUTATION_INSERT_DOCTOR_TO_PROCESS_ROLE($doctorsInfo: [doctorToProcessRole_insert_input!]!) {
    insert_doctorToProcessRole(objects: $doctorsInfo) {
      returning {
        doctorId
        id
        processId
        roleId
        userToProcessId
      }
    }
  }
`

export const MUTATION_INSERT_ROLE_AND_DOCTOR_TO_PROCESS_ROLE = gql`
  mutation MUTATION_INSERT_ROLE_DOCTOR_TO_PROCESS_ROLE(
    $processId: Int
    $roleName: jsonb
    $doctorToProcessRoles: doctorToProcessRole_arr_rel_insert_input
    $id: String
  ) {
    insert_processRole(objects: {id: $id, processId: $processId, roleName: $roleName, doctorToProcessRoles: $doctorToProcessRoles}) {
      returning {
        id
        processId
        roleName
        doctorToProcessRoles {
          doctorId
          id
          processId
          roleId
          userToProcessId
        }
      }
    }
  }
`
