import React from "react";
import * as S from "./VitalSignTimeLineCard.style";

// pipes
import DatetimeXAxisAreaChart from "../datetimeXAxisAreaChart/DatetimeXAxisAreaChart";

const VitalSignTimeLineCard = ({ 
  activeHeader=true, 
  colorVariation, 
  colorPrimary, 
  colorSecundary,
  name,
  frequency,
  frequencyType,
  chartInfo,
  minDate,
  maxDate,
  unit,
  multipleChart
}) => {

  return (
    <S.VitalSignTimeLineCard
      colorVariation={colorVariation}
      colorSecundary={colorSecundary}
      colorPrimary={colorPrimary}
      activeHeader={activeHeader}
    >
      <div className="weekly-bars-card">
        { activeHeader ? 
          <div className="weekly-bars-card__header">
            <div className="weekly-bars-card__header-icon">
              <i className="icon zaia-icono-glucometria"></i>
            </div>
            <div className="weekly-bars-card__header-text">
              <h4>{name}</h4>
              {/* <p>{P.firstLetterUpper(t('patients.boxInfoPatient.report.every'))} {frequency} {frequencyType}</p> */}
            </div>
          </div> : ""
        }
        <div className="weekly-bars-card__bars-container">
          <DatetimeXAxisAreaChart
            colors={[colorPrimary, "#f68e9b"]}
            lineWidth={3}
            curveLine={false}
            multipleChart={multipleChart}
            dataChart={chartInfo}
            minDate={minDate}
            maxDate={maxDate}
            nameIndicator={unit}
          />
        </div>
      </div>
    </S.VitalSignTimeLineCard>
  );
};

export default VitalSignTimeLineCard;
