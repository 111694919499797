import { gql } from "@apollo/client";

export const QUERY_FEELINGS_COMMENT_REPORTS = gql`
  query QUERY_FEELINGS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz,
    $processId: [Int!]
  ) {
    userToFeeling(
      where: {
        date: {
          _gte: $initialDate, 
          _lte: $finalDate
        }, 
        description: {
          _is_null: false
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      date
      description
      id
      levelFeeling
      userId
      modifiedDate
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const QUERY_FEELINGS_COMMENT_REPORTS_BY_USER = gql`
  query QUERY_FEELINGS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz, 
    $userId: uuid,
    $processId: [Int!]
  ) {
    userToFeeling(
      where: {
        date: {
          _gte: $initialDate,
          _lte: $finalDate
        }, 
        userId: {
          _eq: $userId
        }, 
        description: {
          _is_null: false
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      date
      description
      id
      levelFeeling
      userId
      modifiedDate
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`


export const QUERY_FEELINGS_COMMENT_REPORTS_BY_USER_NO_DATES = gql`
  query QUERY_FEELINGS_COMMENT_REPORTS_BY_USER_NO_DATES(
    $userId: uuid,
    $processId: [Int!]
  ) {
    userToFeeling(
      where: { 
        userId: {
          _eq: $userId
        }, 
        description: {
          _is_null: false
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      date
      description
      id
      levelFeeling
      userId
      modifiedDate
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const QUERY_GET_USER_FEELINGS = gql`
  query QUERY_GET_USER_FEELINGS(
    $patientId: uuid
    $startDate: timestamptz!
    $finishDate: timestamptz!
  ) {
    user(where: { id: { _eq: $patientId } }) {
      userToFeelings(
        where: { _and: { date: { _gte: $startDate, _lte: $finishDate } } }
      ) {
        id
        levelFeeling
        date
        description
      }
    }
  }
`;

export const QUERY_GET_USER_FEELINGS_NO_DATES = gql`
  query QUERY_GET_ALL_USER_FEELINGS_INFO(
    $patientId: uuid
  ) {
    user(where: { id: { _eq: $patientId } }) {
      userToFeelings {
        id
        levelFeeling
        date
        description
      }
    }
  }
`;

export const QUERY_GET_ALL_USER_FEELINGS = gql`
  query QUERY_GET_ALL_USER_FEELINGS(
    $startDate: timestamptz
    $finishDate: timestamptz
    $processIds: [Int!]
  ) {
    userToFeeling(
      where: { 
        _and: { 
          date: { 
            _gte: $startDate, 
            _lte: $finishDate 
          },
          user: {
            userToProcesses: {
              processId: {
                _in: $processIds
              }
            }
          }
        } 
      }
    ) {
      id
      levelFeeling
      date
    }
  }
`;
