import { gql } from "@apollo/client";

export const MUTATION_INSERT_USER_TO_CUSTOM_FORM_FRECUENCY = gql`
  mutation MUTATION_INSERT_USER_TO_CUSTOM_FORM_FRECUENCY($objects: [userToCustomFormFrecuency_insert_input!]!) {
    insert_userToCustomFormFrecuency(objects: $objects) {
      returning {
        userId
        updated_at
        id
        expectedDateAnswer
        doctorId
        customFormId
        created_at
        IsComplete
        frequency
        frequencyType
        startDateTime
        endDateTime
      }
    }
  }
`
export const MUTATION_UPDATE_USER_TO_CUSTOM_FORM_FRECUENCY = gql`
  mutation MUTATION_UPDATE_USER_TO_CUSTOM_FORM_FRECUENCY(
    $updatesData: [userToCustomFormFrecuency_updates!]!
  ) {
    update_userToCustomFormFrecuency_many(
      updates: $updatesData
    ) {
      returning {
        IsComplete
        created_at
        customFormId
        doctorId
        endDateTime
        expectedDateAnswer
        frequency
        frequencyType
        id
        startDateTime
        updated_at
        userId
      }
    }
  }
`
