import React, { useMemo, useState } from "react";
import * as S from "./SurveyList.style";
import { useTranslation } from "react-i18next";
import ZaiaInput from "../../../../../../zaiaComponents/zaiaInput";
import * as P from "../../../../../../../utils/pipes";
import { v4 as uuidv4 } from 'uuid';
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";

const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function SurveyList({
  selectedSurvey,
  selectedProcess,
  changeFormField,
  surveyList,
  goNextInput,
  setPreviewSurveyView
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const [filterText, setFilterText] = useState("");

  const getFilterText = (text) => {
    return filterText 
      ? String(P.removeAccent(text)).toLowerCase().includes(P.removeAccent(filterText).toLowerCase())
      : true
  }

  const getMedicationList = () => {

    return (
      <>
        <div className={`list__results ${filterText === "" && "list__results--hidden"}`}>{t("globally.resultsFor")} "{filterText}"</div>
        <div className="list__container">
          {
            selectedProcess.value === null && 
            <div>Necesitas seleccionar un programa primero</div>
          }
          {
            surveyList?.map((survey) => {
              if(getFilterText(survey.text)) {
                return (
                  <div 
                    className={`survey-container ${survey.id === selectedSurvey.value && survey.text === selectedSurvey.text && "survey-container--selected"}`}
                    key={uuidv4()}
                    >
                    <div className="survey-container__name"
                      onClick={() => 
                        {
                          if(survey.id !== selectedSurvey.value && survey.text !== selectedSurvey.text) {
                            changeFormField(
                              [
                                {
                                  value: survey.id,
                                  text: survey.text,
                                  fieldName: "survey"
                                }
                              ]
                            )
                            goNextInput()
                          }
                        }
                      }
                    >{survey.text}</div>
                    <div className="survey-container__bottom">
                      <div className="questions-number">{survey.questionsNumber} {t("surveys.questions")}</div>
                      <div className="preview"
                        onClick={() => { 
                          setPreviewSurveyView(survey.id)
                        }}
                      >{t("surveys.preview")}</div>
                    </div>
                  </div>
                )
              } else {
                return null
              }
            })
          }
        </div>
      </>
    )

  }

  return (
    <S.SurveyList
      searcher={filterText}
    >
      <div className="surveys-list-title">
        {t("surveys.sendSurveyModal.surveyList")}
      </div>
      <div className="input-container">
        <ZaiaInput
          textColor={"#59599E"}
          borderColor={"#68688D"}
          placeholderTextColor={"#878792"}
          fontSize={"12px"}
          placeholder={t("surveys.sendSurveyModal.searchSurvey")}
          type="text"
          name="description"
          padding={"9px 20px"}
          borderRadius={"10px"}
          errorOption={false}
          height={"100%"}
          getText={(e) => setFilterText(e)}
          backgroundColor={"transparent"}
          value={filterText}
        />
      </div>
      <div className="list">
        {
          getMedicationList()
        }
      </div>
      
    </S.SurveyList>
  );
}

export default SurveyList;
