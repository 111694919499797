import { gql } from "@apollo/client";

export const QUERY_GET_USER_TO_PROCESS = gql`
  query QUERY_GET_USER_TO_PROCESS(
    $patientId: uuid, 
    $processId: Int,
  ) {
    userToProcess(where: {processId: {_eq: $processId}, userId: {_eq: $patientId}}) {
      created_at
      descript
      doctorId
      id
      isAcepted
      processId
      stageId
      updated_at
      userId
      isChecked
      nextUpdate
      user {
        phone
        documentNumber
        documentType
        id
        lastname
        name
        picture
        email
        enDocumentType {
          comment
          value
        }
      }
      processStage {
        id
        descript
        expirationTime
        isAutomatic
        nextStageId
        processId
        stageName
        frequency
        frequencyType
      }
      process {
        id
        nameKey
        processName
      }
      userToProcessHistories(order_by: {created_at: desc}, limit: 1, where: {isChecked: {_eq: true}}) {
        created_at
        customFormFieldChanges
        date
        descript
        doctorId
        id
        isChecked
        latestCustomFormId
        processId
        stageId
        userToProcessId
      }
    }
  }
`

export const QUERY_GET_USER_AND_USER_TO_PROCESS = gql`
  query QUERY_GET_USER_AND_USER_TO_PROCESS(
    $emailPatient: String, 
    $processId: Int,
  ) {
    user(where: {email: {_eq: $emailPatient}}) {
      birthdate
      email
      documentNumber
      documentType
      createdAt
      countryPhoneId
      id
      lastname
      name
      picture
      phone
      userToProcesses(where: {processId: {_eq: $processId}}) {
        created_at
        descript
        doctorId
        id
        isAcepted
        processId
        stageId
        updated_at
        userId
        isChecked
        nextUpdate
        user {
          phone
          documentNumber
          documentType
          id
          lastname
          name
          picture
          email
          enDocumentType {
            comment
            value
          }
        }
        processStage {
          id
          descript
          expirationTime
          isAutomatic
          nextStageId
          processId
          stageName
          frequency
          frequencyType
        }
        process {
          id
          nameKey
          processName
        }
        userToProcessHistories(order_by: {created_at: desc}, limit: 1, where: {isChecked: {_eq: true}}) {
          created_at
          customFormFieldChanges
          date
          descript
          doctorId
          id
          isChecked
          latestCustomFormId
          processId
          stageId
          userToProcessId
        }
      }
    }
  }
`

export const QUERY_GET_USER_AND_USER_TO_PROCESS_PHONE = gql`
  query QUERY_GET_USER_AND_USER_TO_PROCESS(
    $phonePatient: String, 
    $processId: Int,
  ) {
    user(where: {phone: {_eq: $phonePatient}}) {
      birthdate
      email
      documentNumber
      documentType
      createdAt
      countryPhoneId
      id
      lastname
      name
      phone
      picture
      userToProcesses(where: {processId: {_eq: $processId}}) {
        created_at
        descript
        doctorId
        id
        isAcepted
        processId
        stageId
        updated_at
        userId
        isChecked
        nextUpdate
        user {
          phone
          documentNumber
          documentType
          id
          lastname
          name
          picture
          email
          enDocumentType {
            comment
            value
          }
        }
        processStage {
          id
          descript
          expirationTime
          isAutomatic
          nextStageId
          processId
          stageName
          frequency
          frequencyType
        }
        process {
          id
          nameKey
          processName
        }
        userToProcessHistories(order_by: {created_at: desc}, limit: 1, where: {isChecked: {_eq: true}}) {
          created_at
          customFormFieldChanges
          date
          descript
          doctorId
          id
          isChecked
          latestCustomFormId
          processId
          stageId
          userToProcessId
        }
      }
    }
  }
`

export const QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED = gql`
  query QUERY_GET_USER_TO_PROCESS(
    $isAcepted: Boolean
  ) {
    userToProcess(where: {isAcepted: {_eq: $isAcepted}}) {
      id
      created_at
      doctorId
      isAcepted
      processId
      stageId
      updated_at
      userId
      isChecked
      nextUpdate
      user {
        documentNumber
        documentType
        id
        phone
        lastname
        name
        picture
        email
        phone
        countryPhoneId
        phoneCountry {
          phonecode
        }
        userToProcesses {
          descript
          process {
            id
            processName
          }
        }
        enDocumentType {
          comment
          value
        }
      }
      processStage {
        id
        descript
        expirationTime
        isAutomatic
        nextStageId
        processId
        stageName
        frequency
        frequencyType
      }
      process {
        id
        nameKey
        processName
      }
      userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
        created_at
        customFormFieldChanges
        date
        descript
        doctorId
        id
        isChecked
        latestCustomFormId
        processId
        stageId
        userToProcessId
      }
    }
  }
`

export const QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED_NULL = gql`
  query QUERY_GET_USER_TO_PROCESS_BY_ISACEPTED_NULL {
    userToProcess(where: {isAcepted: {_is_null: true}}) {
      id
      created_at
      doctorId
      isAcepted
      processId
      stageId
      updated_at
      userId
      isChecked
      nextUpdate
      user {
        documentNumber
        documentType
        id
        lastname
        name
        picture
        email
        enDocumentType {
          comment
          value
        }
      }
      processStage {
        id
        descript
        expirationTime
        isAutomatic
        nextStageId
        processId
        stageName
        frequency
        frequencyType
      }
      process {
        id
        nameKey
        processName
      }
      userToProcessHistories(order_by: {created_at: desc}, limit: 1, where: {isChecked: {_eq: true}}) {
        created_at
        customFormFieldChanges
        date
        descript
        doctorId
        id
        isChecked
        latestCustomFormId
        processId
        stageId
        userToProcessId
      }
    }
  }
`
export const QUERY_GET_PROCESS_STAGES_BY_PROCESS_IDS = gql`
  query QUERY_GET_PROCESS_STAGES_BY_PROCESS_IDS($processIds: [Int!]) {
    process(where: {id: {_in: $processIds}}) {
      processStages {
        id
        descript
        expirationTime
        isAutomatic
        nextStageId
        processId
        stageName
        frequency
        frequencyType
      }
      descript
      icon
      id
      nameKey
      processName
    }
  }
`
