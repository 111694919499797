import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalSendSurvey = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
    .rows-container {
      height: calc(100% - 62px);
      display: flex;
      overflow: auto;
      flex-direction: column;
    }
    .rows {
      overflow: auto;
      display: flex;
      padding: 20px 40px;
      box-sizing: border-box;
    }
    .principal-input {
      padding-right: 20px;
      width: 40%;
      &__text {
        font-family: Nunito Sans;
        font-size: 15px;
        color: #3E3E5B;
        margin-bottom: 10px;
      }
      &__form {
        height: 100%;
      }
      &__container {
        height: calc(100% - 30px);
        display: flex;
        flex-direction: column;
      }
      .warning-dependent-msg {
        font-family: "Nunito Sans";
        font-size: 13px;
        color: #1F1F52;
      }
    }
    .custom-form-inputs {
      padding: 20px 20px 0px 20px;
      background-color: #F1F3F5;
      width: 60%;
      border-radius: 10px;
      border-left: 5px solid #6C6BCC;
      box-sizing: border-box;
      min-height: 330px;
      max-height: 600px;
      .medication-inputs {
        height: 100%;
        display: none;
        &--selected {
          display: block;
        }
      }
    }
    .summary {
      height: 100%;
      width: 100%;
      display: flex;
      .summary__left {
        height: 100%;
        width: 50%;
        overflow: auto;
        .summary-title {
          /* margin-top: 20px; */
          color: #3E3DA3;
          font-family: Nunito Sans;
          font-size: 20px;
        }
        .summary-items {
          margin-top: 25px;
          .summary-item {
            margin-bottom: 20px;
            &__title {
              display: flex;
              align-items: center;
              color: #3E3E5B;
              font-family: Nunito Sans;
              font-size: 15px;
              margin-bottom: 10px;
            }
            &__icon {
              font-size: 13px;
              margin-right: 8px;
            }
            &__info {
              color: #6C6BCC;
              font-family: Nunito Sans Bold;
              font-size: 16px;                    
              .symptom-box {
                color: #3E3DA3;
                background-color: #D2D2FF;
                padding: 8px 16px;
                color: #3E3E5B;
                font-family: "Nunito Sans";
                font-size: 17px;
                cursor: pointer;
                transition: 0.3s ease;
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                border-radius: 14px;
                &__icon {
                  margin-right: 10px;
                  font-size: 14px !important;
                  color: #3E3DA3;
                }
                &__name {
                  margin-left: 20px;
                }
              }
            }
          }
        }
      }
      .summary__right {
        width: 50%;
        height: 100%;
        overflow: scroll;
        width: 50%;
        background-color: #F8F8FF;
        overflow: auto;
        padding: 20px;
        border-radius: 10px;
        box-sizing: border-box;
        margin-left: 20px;
      }
    }
    .input-box-time {
      padding: 12px 0px 30px 0px;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      &__icon {
        color: #3E3E5B; 
        margin-right: 10px;
      }
      .input-content {
        align-items: center;
        display: flex;      
        margin-right: 20px;
        &__label-bold {
          color: #3E3E5B; 
          white-space: nowrap;
          margin-right: 10px;
          font-size: 19px;
        }
      }
      &__separator {
        border-left: 1px solid #E6E6E6;
        height: 16px;
        margin-left: 9px;
        margin-right: 29px;
      }
      .button-add-favourite {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        border: 1px solid #AAAADB;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        &__icon {
          font-size: 13px;
          color: #36369B;
        }
      }
    }
    .input-box {
      /* padding-bottom: 33px; */
      color: #3E3E5B;
      display: flex;
      flex-direction: column;
      position: relative;
      &--border {
        .input-container {
          border-top: 2px solid #6C6BCC;
          border-right: 5px solid #6C6BCC;
          border-bottom: 2px solid #6C6BCC;
          border-left: 2px solid #6C6BCC;
        }
      }
      &--last {
        padding-bottom: 0px;
        height: 100%;
      }
      &__label {
        display: flex;
        align-items: center;
        font-size: 17px;
        margin-bottom: 6px;
        &-icon {
          margin-right: 10px;
        }
      }
      .input-value {
        color: #36369B;
        font-family: "Nunito Sans Bold";
        font-size: 19px;
        padding: 10px 26px 15px 26px;
      }
      .label-and-button {
        display: flex;
        justify-content: space-between;
        .highlight-button {
          display: flex;
          opacity: 0.5;
          color: #36369B;
          align-items: center;
          margin-bottom: 6px;
          cursor: pointer;
          &__icon {
            margin-right: 5px;
          }
          &--active {
            opacity: 1;
            font-family: "Nunito Sans Bold";
            color: #FFA800;
          }
        }
      }
      .shape-container {
        position: absolute;
        right: -23px;
        top: 6px;
      }

      &__input {
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 40px;
      border-top: 1px solid #0000002B;
      box-sizing: border-box;
      align-items: center;
      .button-container {
        width: 144px;
        &__text {
          font-family: Nunito Sans;
          font-size: 15px;
        }
      }
    }
    
  
  

  @media only screen and (max-width: 435px) {
    .buttons-container {
      .button-submit {
        button {
          /* width: 70%; */
          margin: auto;
        }
      }
      .button-close {

      }
    } 
  }
`;

export const InputOption = styled.div`
  padding: 12px 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-width: 100px;
  background-color: ${({theme, backgroundColor}) => backgroundColor ? backgroundColor : theme.inputBackground};
  margin-bottom: 10px;
  position: relative;
  opacity: ${({disabled}) => disabled ? "0.3" : ""};
  cursor: ${({disabled}) => disabled ? "" : "pointer"};
  border: ${
    ({ 
      selected
    }) => 
      {
        if(selected) {
          return '2px solid #6C6BCC'
        } else {
          return '1px solid #36369B'
        }
      }
  };
  .input-icon {
    font-size: 14px;
    margin-right: 5px;
    color: #36369B;
  }
  .input-text {
    font-family: "Nunito Sans";
    font-size: 14px;
    color: ${({selected, filled}) => selected ? "#36369B" : filled ? "#6C6BCC": "#3E3E5B"};
    cursor: ${({disabled}) => disabled ? "deafult" : "pointer"};
    b {
      margin-left: 5px;
    }
  }
  .input-icon-arrow {
    color: ${({selected}) => selected ? "#36369B" : "#6C6BCC"};
    font-size: 12px;
    transition: .3s ease;
    transform: ${({selected}) => selected ? 'rotate(270deg)' : 'rotate(90deg)'};
    margin-left: auto;
  }
  .input-icon-check {
    color: ${({selected}) => selected ? "#36369B" : "#6C6BCC"};
    font-size: 11px;
    margin-left: auto;
  }
  .shape-container {
    position: absolute;
    right: -23px;
    top: 7px;
  }
`