import styled from "styled-components";

export const ZaiaImage = styled.img`
  min-width: ${({size}) => size + "px"};
  min-height: ${({size}) => size + "px"};
  width: ${({size}) => size + "px"};
  height: ${({size}) => size + "px"};
  border-radius: ${({rounded}) => rounded ? "50%" : "25%"};
  object-fit: cover;
  box-shadow: 0px 2px 6px ${({theme}) => `${theme.blackShadow}1A`}; 
  font-size: 8px;
  vertical-align: middle;
`