import styled from "styled-components";

export const UserSelectorPanel = styled.div`
  height: 100%;
  width: 383px;
  display: flex;
  flex-direction: column;
  .header {
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-size: 20px;
      font-family: "Nunito Sans Bold";
      color: #36369B;
    }
    &__close {
      margin-left: auto;
      background-color: #E6E6E6;
      border-radius: 50%;
      width: 17.23px;
      height: 17.23px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      &-icon {
        color: #8C8DA4;
        font-size: 6px;
      }
    }
  }
  .users-list {
    padding-left: 20px;
    overflow-y: scroll;
    height: 100%;
    /* mask-image: linear-gradient(0deg,#000 95%,transparent); */
    ::-webkit-scrollbar {
      width: 48px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px transparent;
      margin: -20px 0px 0px 0px; 
    }
    ::-webkit-scrollbar-thumb {
      border: 21.5px solid rgba(0, 0, 0, 0);
      box-shadow: inset 0 0 10px 10px #E6E6E6;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: transparent;
    }
    &__responses {
      /* height: 100%; */
      text-align: center;
      margin: 100px auto;
      padding-left: 28px;
      display: flex;
    }
    .user-item {
      display: flex;
      padding: 8.5px 20px;
      border-radius: 10px;
      margin-bottom: 20px;
      background-color: #F8F8F8;
      align-items: center;
      color: #8C8DA4;
      cursor: pointer;
      &--selected {
        color: #3E3DA3;
        background-color: #D6D6F6;
      }
      &__info {
        margin-left: 10px;
      }
      &__name {
        font-size: 22px;
        font-family: "Nunito Sans";
      }
      &__email {
        font-size: 14px;
        font-family: "Nunito Sans Light";
      }
    }

  }
`;
