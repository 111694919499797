import styled from "styled-components";

export const OutcomesTabs = styled.div`
  display: flex;
  background-color: #F0F0FF;
  border-radius: 9px;
  .tab-box {
    border-radius: 9px;
    display: flex;
    padding: 4px 10px;
    align-items: center;
    font-family: "Nunito Sans";
    cursor: pointer;
    &__icon {
      margin-right: 10px;
      font-size: 13px;
      color: #8F8FBB;
      &--selected {
        font-size: 20px;
      }
    }
    &__name {
      margin-right: 10px;
      color: #36369B;
      font-size: 17px;
    }
    &__info-icon {
      color: #38379C;
      font-size: 13px;
    }
    &--selected {
      font-family: "Nunito Sans Bold";
      background-color: #D1D1FF;
    }
  }
`

export const TabDescription = styled.div`
  background: var(--white);
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  max-width: 200px;
  padding: 5px 10px;
  font-family: "Nunito Sans Light";
  font-size: 12px;
`

