import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalOutcomesEventHistoryInfo = styled.div`
  height: calc(100% - 102px);
  .modal-content {
    height: calc(100% - 57px);
    padding: 25px 40px 25px 40px;
    box-sizing: border-box;
    overflow: auto;
    .history-item {
      background-color: #F7F6FC;
      padding: 20px 20px 40px 20px;
      border-radius: 14px;
      margin-bottom: 20px;
      &__header {
        justify-content: space-between;
        display: flex;
        min-height: 50px;
        &-left {
          display: flex;
          align-items: center;
          .date {
            font-family: Nunito Sans Bold;
            font-size: 25.5px;
            color: #1F1F52;
          }
          .time {
            font-family: Nunito Sans light;
            font-size: 20px;
            color: #68688D;
            margin-left: 12px;
          }
          .doctor {
            display: flex;
            align-items: center;
            margin-left: 40px;
            &__label {
              font-family: Nunito Sans Bold;
              font-size: 15px;
              color: #3E3E5B;
            }
            &__name {
              font-family: Nunito Sans;
              font-size: 15px;
              color: #3E3E5B;
              margin-left: 5px;
            }
          }
        }
        &-right {
          align-items: center;
          display: flex;
          .history-action-button {
            background-color: #3E3DA3;
            cursor: pointer;
            outline: none;
            border: none;
            padding: 5px 15px;
            border-radius: 60px;
            display: flex;
            font-family: Nunito Sans;
            font-size: 15px;
            color: #F7F6FC;
            align-items: center;
            margin-left: 10px;
            &__icon {
              margin-left: 10px;
              font-size: 12px;  
            }
          }
        }
      }
      .history-fields-list {
        display: flex;
        margin-top: 20px;
        flex-wrap: wrap;
        .detail {
          margin-right: 40px;
          margin-top: 20px;
          &__label {
            display: flex;
            align-items: center;
            color: #3E3E5B;
            font-family: "Nunito Sans Bold";
            font-size: 15px;
            margin-bottom: 10px;
            &-icon {
              margin-left: 10px;
              color: #38379C;
            }
          }
          &__value {
            font-family: Nunito Sans;
            font-size: 15px;
            color: #1F1F52;
            &-icon {
              margin-left: 10px;
              color: #38379C;
            }
            .file-value {
              color:#6C6BCC;
              display: flex;
              align-items: center;
              &__icon {
                margin-right: 5px;
                font-size: 15px;
              }
              &__text {
                color:#6C6BCC;
                font-size: 15px;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
`;

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Open Sans';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`