import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ListOfBoxes.Style";
import { Actions } from "../../../../../utils/actionsIds";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";

// Pipes
import * as P from "../../../../../utils/pipes";

// GraphQL
import { useQuery, useSubscription } from "@apollo/client";
import { QUERY_GET_USERS } from "../../../../../graphql/queries/User";
import {
  QUERY_GET_PROCESS_NAMES,
  QUERY_GET_PROCESS_TYPES,
  QUERY_GET_PROCESS_TYPES_BY_ID,
} from "../../../../../graphql/queries/Process";
import {
  SUBSCRIPTION_GET_SIDE_EFFECTS,
  SUBSCRIPTION_GET_SIDE_EFFECTS_BY_USER,
} from "../../../../../graphql/subscriptions/SideEffects";
import {
  SUBSCRIPTION_GET_VITAL_SIGNS,
  SUBSCRIPTION_GET_VITAL_SIGNS_BY_USER,
  SUBSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS,
  SUBSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER,
} from "../../../../../graphql/subscriptions/VitalSigns";
import {
  SUBSCRIPTION_FEELINGS_COMMENT_REPORTS,
  SUBSCRIPTION_FEELINGS_COMMENT_REPORTS_BY_USER,
} from "../../../../../graphql/subscriptions/Feelings";
import {
  SUBSCRIPTION_SIDE_EFFECTS_COMMENT_REPORTS,
  SUBSCRIPTION_SIDE_EFFECTS_COMMENT_REPORTS_BY_USER,
} from "../../../../../graphql/subscriptions/SideEffects";
import {
  SUBSCRIPTION_DRUGS_COMMENT_REPORTS,
  SUBSCRIPTION_DRUGS_COMMENT_REPORTS_BY_USER,
} from "../../../../../graphql/subscriptions/drugs";
import {
  QUERY_GET_SIDE_EFFECTS,
  QUERY_GET_SIDE_EFFECTS_BY_USER,
} from "../../../../../graphql/queries/SideEffects";
import {
  QUERY_GET_VITAL_SIGNS,
  QUERY_GET_VITAL_SIGNS_BY_USER,
  QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS,
  QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER,
} from "../../../../../graphql/queries/VitalSigns";
import {
  QUERY_FEELINGS_COMMENT_REPORTS,
  QUERY_FEELINGS_COMMENT_REPORTS_BY_USER,
} from "../../../../../graphql/queries/Feelings";
import {
  QUERY_SIDE_EFFECTS_COMMENT_REPORTS,
  QUERY_SIDE_EFFECTS_COMMENT_REPORTS_BY_USER,
} from "../../../../../graphql/queries/SideEffects";
import {
  QUERY_DRUGS_COMMENT_REPORTS,
  QUERY_DRUGS_COMMENT_REPORTS_BY_USER,
} from "../../../../../graphql/queries/drugs";

// components
import Box from "../box";
import NoPatients from "../../../shared/noPatients";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-alerts.svg`;
const alertsEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/list-alerts-empty.svg`;

function ListOfBoxes({
  filterSelect,
  processIds,
  setAllNotificationNumber,
  setMildNotificationNumber,
  setMediumNotificationNumber,
  setSeriousNotificationNumber,
  setCommentNotificationNumber,
  levelReport,
  initialDate,
  finishDate= new Date(Date.now()),
  selectedPatient,
  setSelectedBox,
  subsActive=false
}) {

  const {
    t,
    i18n: { language },
  } = useTranslation("global");

  const { data: processData } = useQuery( QUERY_GET_PROCESS_NAMES);    
  const { recordAction } = useRecordNavigation();	

  const {
    data: usersData,
    error: usersError,
    loading: usersLoading,
  } = useQuery(QUERY_GET_USERS);

  const [queryProcessTypes, setQueryProcessTypes] = useState(QUERY_GET_PROCESS_TYPES);
  const [variablesProcessTypes, setVariablesProcessTypes] = useState();

  // sideEffects variables
  const [querySideEffects, setQuerySideEffects] = useState(QUERY_GET_SIDE_EFFECTS);
  const [subscriptionSideEffects, setSubscriptionSideEffects] = useState(SUBSCRIPTION_GET_SIDE_EFFECTS);
  const [variablesSideEffects1, setVariablesSideEffects1] = useState();
  const [variablesSideEffects2, setVariablesSideEffects2] = useState();
  const [variablesSideEffects3, setVariablesSideEffects3] = useState();

  // vitalSigns variables
  const [queryVitalSigns, setQueryVitalSigns] = useState(QUERY_GET_VITAL_SIGNS);
  const [subscriptionVitalSigns, setSubscriptionVitalSigns] = useState(SUBSCRIPTION_GET_VITAL_SIGNS);
  const [variablesVitalSigns1, setVariablesVitalSigns1] = useState();
  const [variablesVitalSigns2, setVariablesVitalSigns2] = useState();
  const [variablesVitalSigns3, setVariablesVitalSigns3] = useState();

  // comment variables
  const [querySideEffectsComments, setQuerySideEffectsComments] = useState(QUERY_FEELINGS_COMMENT_REPORTS);
  const [subscriptionSideEffectsComments, setSubscriptionSideEffectsComments] = useState(SUBSCRIPTION_SIDE_EFFECTS_COMMENT_REPORTS);
  const [queryDrugsComments, setQueryDrugsComments] = useState(QUERY_DRUGS_COMMENT_REPORTS);
  const [subscriptionDrugsComments, setSubscriptionDrugsComments] = useState(SUBSCRIPTION_DRUGS_COMMENT_REPORTS);
  const [queryVitalSignsComments, setQueryVitalSignsComments] = useState(QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS);
  const [subscriptionVitalSignsComments, setSubscriptionVitalSignsComments] = useState(SUBSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS);
  const [variablesComments, setVariablesComments] = useState();

  useEffect(() => {
    if(processIds.length === 0) {
      setQueryProcessTypes(QUERY_GET_PROCESS_TYPES);
    } else {
      setQueryProcessTypes(QUERY_GET_PROCESS_TYPES_BY_ID);
      setVariablesProcessTypes({ processId: processIds });
    }
  }, [processIds]);

  const { data: processTypes } = useQuery(queryProcessTypes, { variablesProcessTypes });

  const sideEffects = useMemo(() => {
    let sideEffects1 = [];
    let sideEffects2 = [];
    let sideEffects3 = [];
    processTypes?.process.forEach((process) => {
      process.processToSideEffects.forEach((sideEffect) => {
        if (sideEffect.severity === 1 || sideEffect.severity === 0) {
          sideEffects1.push({
            _and: {
              user: {
                userToProcesses: {
                  processId: {
                    _eq: process.id
                  }
                }
              }, 
              typeSideEffectVal: {
                value: {
                  _eq: sideEffect.typeSideEffect
                }
              }
            }
          });

          sideEffects2.push({
            _and: {
              user: {
                userToProcesses: {
                  processId: {
                    _eq: process.id
                  }
                }
              }, 
              typeSideEffectVal: {
                value: {
                  _eq: sideEffect.typeSideEffect
                }
              }
            }
          });

          sideEffects3.push({
            _and: {
              user: {
                userToProcesses: {
                  processId: {
                    _eq: process.id
                  }
                }
              }, 
              typeSideEffectVal: {
                value: {
                  _eq: sideEffect.typeSideEffect
                }
              }
            }
          });
        }
        if (sideEffect.severity === 2 || sideEffect.severity === 3) {
          sideEffects2.push({
            _and: {
              user: {
                userToProcesses: {
                  processId: {
                    _eq: process.id
                  }
                }
              }, 
              typeSideEffectVal: {
                value: {
                  _eq: sideEffect.typeSideEffect
                }
              }
            }
          });

          sideEffects3.push({
            _and: {
              user: {
                userToProcesses: {
                  processId: {
                    _eq: process.id
                  }
                }
              }, 
              typeSideEffectVal: {
                value: {
                  _eq: sideEffect.typeSideEffect
                }
              }
            }
          });
        }
        if (sideEffect.severity === 4 || sideEffect.severity === 5) {
          sideEffects3.push({
            _and: {
              user: {
                userToProcesses: {
                  processId: {
                    _eq: process.id
                  }
                }
              }, 
              typeSideEffectVal: {
                value: {
                  _eq: sideEffect.typeSideEffect
                }
              }
            }
          });
        }
      });
    }); 

    return [sideEffects1, sideEffects2, sideEffects3];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processTypes]);

  const vitalSigns = useMemo(() => {
    const vitalSignsBySeverity = [[], [], []];
  
    processTypes?.process.forEach((process) => {
      process.processToVitalSigns.forEach((vitalSign) => {
  
        const vitalSignFilter = {
          _and: {
            measure1: {},
            vitalSignVal: { value: { _eq: vitalSign.vitalSign } },
            user: {
              userToProcesses: {
                processId: { _eq: process.id }
              }
            }
          }
        };
  
        if (vitalSign.min) {
          vitalSignFilter._and.measure1._gte = vitalSign.min;
        }
  
        if (vitalSign.max) {
          vitalSignFilter._and.measure1._lte = vitalSign.max;
        }
  
        const severity = vitalSign.severity;
        if (severity >= 1 && severity <= 3) {
          vitalSignsBySeverity[severity - 1].push(vitalSignFilter);
        }
      });
    });
  
    return vitalSignsBySeverity;
  }, [processTypes]);

  const dateNow = new Date(Date.now());
  const dateTwoWeeksBefore = new Date(
    new Date(dateNow.setDate(dateNow.getDate() - 14)).setHours(0, 0, 0, 0)
  );

  //side effects variables

  useEffect(() => {
    setQuerySideEffects(
      selectedPatient
      ? QUERY_GET_SIDE_EFFECTS_BY_USER
      : QUERY_GET_SIDE_EFFECTS
    )
    setSubscriptionSideEffects( 
      selectedPatient
      ? SUBSCRIPTION_GET_SIDE_EFFECTS_BY_USER
      : SUBSCRIPTION_GET_SIDE_EFFECTS
    )

    const variablesSideEffects = {
      initialDate: initialDate || dateTwoWeeksBefore,
      finalDate: finishDate
    };

    const variablesSideEffects1 = JSON.parse(JSON.stringify(variablesSideEffects));
    const variablesSideEffects2 = JSON.parse(JSON.stringify(variablesSideEffects));
    const variablesSideEffects3 = JSON.parse(JSON.stringify(variablesSideEffects));

    variablesSideEffects1.sideEffects = sideEffects[0];
    variablesSideEffects1.severities = [0, 1];
    variablesSideEffects2.sideEffects = sideEffects[1];
    variablesSideEffects2.severities = [2, 3];
    variablesSideEffects3.sideEffects = sideEffects[2];
    variablesSideEffects3.severities = [4, 5];

    if (selectedPatient) {
      variablesSideEffects1.userId = selectedPatient;
      variablesSideEffects2.userId = selectedPatient;
      variablesSideEffects3.userId = selectedPatient;
    }

    setVariablesSideEffects1(variablesSideEffects1); 
    setVariablesSideEffects2(variablesSideEffects2);
    setVariablesSideEffects3(variablesSideEffects3);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate, finishDate, selectedPatient, sideEffects]);

  // vital signs variables

  useEffect(() => {
    setQueryVitalSigns(
      selectedPatient 
      ? QUERY_GET_VITAL_SIGNS_BY_USER 
      : QUERY_GET_VITAL_SIGNS
    )
    setSubscriptionVitalSigns( 
      selectedPatient 
      ? SUBSCRIPTION_GET_VITAL_SIGNS_BY_USER 
      : SUBSCRIPTION_GET_VITAL_SIGNS
    )

    const variablesVitalSigns = {
      initialDate: initialDate || dateTwoWeeksBefore,
      finalDate: finishDate,
    };

    const variablesVitalSigns1 = JSON.parse(JSON.stringify(variablesVitalSigns));
    const variablesVitalSigns2 = JSON.parse(JSON.stringify(variablesVitalSigns));
    const variablesVitalSigns3 = JSON.parse(JSON.stringify(variablesVitalSigns));

    variablesVitalSigns1.vitalSigns = vitalSigns[0];
    variablesVitalSigns2.vitalSigns = vitalSigns[1];
    variablesVitalSigns3.vitalSigns = vitalSigns[2];
  
    if (selectedPatient) {
      variablesVitalSigns1.userId = selectedPatient;
      variablesVitalSigns2.userId = selectedPatient;
      variablesVitalSigns3.userId = selectedPatient;
    }

    setVariablesVitalSigns1(variablesVitalSigns1);
    setVariablesVitalSigns2(variablesVitalSigns2);
    setVariablesVitalSigns3(variablesVitalSigns3);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate, finishDate, selectedPatient, vitalSigns]);

  // side effects 1    

  const {
    data: sideEffectsData1,
    loading: sideEffectsDataLoading1,
    error: sideEffectsDataError1,
  } = useQuery(
    querySideEffects, {
    variables: variablesSideEffects1,
    skip: subsActive,
  });

  const { 
    data: sideEffectsData1Sub, 
    loading: sideEffectsDataLoading1Sub, 
    error: sideEffectsDataError1Sub 
  } = useSubscription(
    subscriptionSideEffects,
    {
      variables: variablesSideEffects1,
      skip: !subsActive,
    }
  );

  // side effects 2

  const { 
    data: sideEffectsData2, 
    loading: sideEffectsDataLoading2, 
    error: sideEffectsDataError2 
  } = useQuery(
    querySideEffects, {
    variables: variablesSideEffects2,
    skip: subsActive,
  });

  const { 
    data: sideEffectsData2Sub, 
    loading: sideEffectsDataLoading2Sub, 
    error: sideEffectsDataError2Sub 
  } = useSubscription(
    subscriptionSideEffects, {
    variables: variablesSideEffects2,
    skip: !subsActive,
  });

  // side effects 3

  const { 
    data: sideEffectsData3, 
    loading: sideEffectsDataLoading3, 
    error: sideEffectsDataError3 
  } = useQuery(
    querySideEffects, {
    variables: variablesSideEffects3,
    skip: subsActive,
  });
  
  const { 
    data: sideEffectsData3Sub, 
    loading: sideEffectsDataLoading3Sub, 
    error: sideEffectsDataError3Sub 
  } = useSubscription(
    subscriptionSideEffects, {
    variables: variablesSideEffects3,
    skip: !subsActive,
  });
  
  // vital signs 1

  
  const { 
    data: vitalSignsData1, 
    loading: vitalSignsDataLoading1, 
    error: vitalSignsDataError1 
  } = useQuery(
    queryVitalSigns, {
    variables: variablesVitalSigns1,
    skip: subsActive,
  });

  const {
    data: vitalSignsData1Sub,
    loading: vitalSignsDataLoading1Sub,
    error: vitalSignsDataError1Sub
  } = useSubscription(
    subscriptionVitalSigns, {
    variables: variablesVitalSigns1,
    skip: !subsActive
  });

  // vital signs 2


  const { 
    data: vitalSignsData2, 
    loading: vitalSignsDataLoading2, 
    error: vitalSignsDataError2 
  } = useQuery(
      queryVitalSigns, {
    variables: variablesVitalSigns2,
    skip: subsActive,
  });

  const { 
    data: vitalSignsData2Sub, 
    loading: vitalSignsDataLoading2Sub, 
    error: vitalSignsDataError2Sub 
  } = useSubscription(
      subscriptionVitalSigns, {
    variables: variablesVitalSigns2,
    skip: !subsActive,
  });

  // vital signs 3


  const { 
    data: vitalSignsData3,
    loading: vitalSignsDataLoading3,
    error: vitalSignsDataError3 
  } = useQuery(
    queryVitalSigns, {
    variables: variablesVitalSigns3,
    skip: subsActive,
  });

  const { 
    data: vitalSignsData3Sub,
    loading: vitalSignsDataLoading3Sub,
    error: vitalSignsDataError3Sub 
  } = useSubscription(
    subscriptionVitalSigns, {
    variables: variablesVitalSigns3,
    skip: !subsActive,
  });

  // Comments reports

  useEffect(() => {
    const processIdFilter = processIds.length === 0 
    ? processData?.process.map((process) => {return process?.id}) 
    : processIds;

    setQuerySideEffectsComments(
      selectedPatient
      ? QUERY_SIDE_EFFECTS_COMMENT_REPORTS_BY_USER
      : QUERY_SIDE_EFFECTS_COMMENT_REPORTS
    )
    setSubscriptionSideEffectsComments(
      selectedPatient
      ? SUBSCRIPTION_SIDE_EFFECTS_COMMENT_REPORTS_BY_USER
      : SUBSCRIPTION_SIDE_EFFECTS_COMMENT_REPORTS
    )
    setQueryDrugsComments(
      selectedPatient
      ? QUERY_DRUGS_COMMENT_REPORTS_BY_USER
      : QUERY_DRUGS_COMMENT_REPORTS
    )
    setSubscriptionDrugsComments(
      selectedPatient
      ? SUBSCRIPTION_DRUGS_COMMENT_REPORTS_BY_USER
      : SUBSCRIPTION_DRUGS_COMMENT_REPORTS
    )
    setQueryVitalSignsComments(
      selectedPatient
      ? QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER
      : QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS
    )
    setSubscriptionVitalSignsComments(
      selectedPatient
      ? SUBSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER
      : SUBSCRIPTION_GET_VITAL_SIGNS_COMMENT_REPORTS
    )

    const variablesComments = {
      processId: processIdFilter,
      initialDate: initialDate || dateTwoWeeksBefore,
      finalDate: finishDate,
    };
  
    if (selectedPatient) {
      variablesComments.userId = selectedPatient;
    }

    setVariablesComments(variablesComments)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate, finishDate, selectedPatient, processIds, processData]);

  // feelings comments

  const { 
    data: sideEffectsComments,
    loading: sideEffectsCommentsLoading,
    error: sideEffectsCommentsError 
  } = useQuery(
    querySideEffectsComments, {
    variables: variablesComments,
    skip: !subsActive,
  });

  const { 
    data: sideEffectsCommentsSub,
    loading: sideEffectsCommentsLoadingSub,
    error: sideEffectsCommentsErrorSub 
  } = useSubscription(
    subscriptionSideEffectsComments, {
    variables: variablesComments,
  });
  
  // drugs comments

  const { 
    data: drugsComments,
    loading: drugsCommentsLoading,
    error: drugsCommentsError 
  } = useQuery(
    queryDrugsComments, {
    variables: variablesComments,
    skip: subsActive,
  });

  const { 
    data: drugsCommentsSub,
    loading: drugsCommentsLoadingSub,
    error: drugsCommentsErrorSub 
  } = useSubscription(
    subscriptionDrugsComments, {
    variables: variablesComments,
    skip: !subsActive,
  });

  // vitalsigns comments

  const { data: vitalSignsComments,
    loading: vitalSignsCommentsLoading,
    error: vitalSignsCommentsError } = useQuery(
    queryVitalSignsComments, {
    variables: variablesComments,
    skip: subsActive,
  });

  const { data: vitalSignsCommentsSub,
    loading: vitalSignsCommentsLoadingSub,
    error: vitalSignsCommentsErrorSub } = useSubscription(
    subscriptionVitalSignsComments, {
    variables: variablesComments,
    skip: !subsActive,
  });

  const getSideEffects = (sideEffect, reportlevel) => {
    const sideEffectLevelsText = [
      t("globally.veryMild"),
      t("globally.mild"),
      t("globally.medium"),
      t("globally.severe"),
      t("globally.verySevere")
    ]
    return sideEffect?.userToSideEffect.map((sideEffect) => {
      let sideEffectTemp = JSON.parse(JSON.stringify(sideEffect));
      sideEffectTemp.type = 1;
      sideEffectTemp.orderDate = sideEffect.date;
      sideEffectTemp.description = `${
        P.dataTranslation(
          sideEffect.typeSideEffectVal.comment,
          language
        ).toUpperCase()} 
        ${t("dashboard.boxes.symptom.textLevel")} 
        ${sideEffectLevelsText[reportlevel - 1].toUpperCase()}`;
      sideEffectTemp.reportlevel = reportlevel;
      return sideEffectTemp;
    });
  };

  const getVitalSigns = (vitalSign, reportlevel) => {
    return vitalSign?.userVitalSignHistory.map((vitalSign) => {

      let vitalSignTemp = JSON.parse(JSON.stringify(vitalSign));
      const vitalSignsOriginalValue = P.convertVitalSign(vitalSign.vitalSign, vitalSign.measure1, vitalSign.unit);
      const vitalSignsValue = vitalSignsOriginalValue - Math.floor(vitalSignsOriginalValue);

      const comment = P.dataTranslation(vitalSign.vitalSignVal.comment, language);
      const value = vitalSignsValue === 0 ? Number(vitalSignsOriginalValue).toFixed(0) : Number(vitalSignsOriginalValue);
      const unit = vitalSign.unit;


      vitalSignTemp.type = 3;
      vitalSignTemp.orderDate = vitalSign.created_at;
      if (reportlevel === 4) {
        vitalSignTemp.description = `"${vitalSign.comment}" ${t("dashboard.boxes.comment.symptom")} "${comment}"`;
      } else {
        vitalSignTemp.description = `${comment.toUpperCase()} (${value} ${unit})`;
      }
      vitalSignTemp.reportlevel = reportlevel;

      return vitalSignTemp;
    });
  };

  const getDrugs = (drug, reportlevel) => {
    return drug?.userToDrugHistory.map((drug) => {
      let drugTemp = JSON.parse(JSON.stringify(drug));
      drugTemp.type = 2;
      drugTemp.orderDate = drug.created_at;
      drugTemp.description = drug.comment;
      drugTemp.reportlevel = reportlevel;
      return drugTemp;
    });
  };

  const getSideEffectsComments = (sideEffect, reportlevel) => {
    return sideEffect?.userToSideEffect.map((item) => {
      let sideEffectTemp = JSON.parse(JSON.stringify(item));
      sideEffectTemp.type = 1;
      sideEffectTemp.orderDate = item.date;
      sideEffectTemp.modifiedDate = item.modifiedDate;
      sideEffectTemp.description = `"${item.description}" ${t("dashboard.boxes.comment.symptom")} ${P.dataTranslation(item.typeSideEffectVal.comment, language).toUpperCase()}`;
      sideEffectTemp.reportlevel = reportlevel;
      return sideEffectTemp;
    });
  };

  function cleanArray(actual) {
    let newArray = [];
    for (let i = 0, j = actual.length; i < j; i++) {
      if (actual[`${i}`]) {
        newArray.push(actual[`${i}`]);
      }
    }
    return newArray;
  }

  const dataForCard = useMemo(
    () => {
      let cardsInfo = [];

      let allCardsInfo = cleanArray(
        cardsInfo.concat(
          getVitalSigns(subsActive ? vitalSignsData1Sub : vitalSignsData1, 1),
          getSideEffects(subsActive ? sideEffectsData1Sub : sideEffectsData1, 1),
          getVitalSigns(subsActive ? vitalSignsData2Sub : vitalSignsData2, 2),
          getSideEffects(subsActive ? sideEffectsData2Sub : sideEffectsData2, 2),
          getVitalSigns(subsActive ? vitalSignsData3Sub : vitalSignsData3, 3),
          getSideEffects(subsActive ? sideEffectsData3Sub : sideEffectsData3, 3),
          getDrugs(subsActive ? drugsCommentsSub : drugsComments, 4),
          getVitalSigns(subsActive ? vitalSignsCommentsSub : vitalSignsComments, 4),
          getSideEffectsComments(subsActive ? sideEffectsCommentsSub : sideEffectsComments, 4)
        )
      );

      allCardsInfo = allCardsInfo
        .slice(0)
        .reverse()
        .filter((value, index, self) => {
          return self.findIndex((v) => v.id === value.id) === index;
        });

      const mildCardsInfo = allCardsInfo.filter((value) => {
        return value.reportlevel === 1;
      });

      const mediumCardsInfo = allCardsInfo.filter((value) => {
        return value.reportlevel === 2;
      });

      const seriousCardsInfo = allCardsInfo.filter((value) => {
        return value.reportlevel === 3;
      });

      const commentCardsInfo = allCardsInfo.filter((value) => {
        return value.reportlevel === 4;
      });

      if (levelReport === 0) {
        cardsInfo = allCardsInfo;
      } else if (levelReport === 1) {
        cardsInfo = mildCardsInfo;
      } else if (levelReport === 2) {
        cardsInfo = mediumCardsInfo;
      } else if (levelReport === 3) {
        cardsInfo = seriousCardsInfo;
      } else {
        cardsInfo = commentCardsInfo;
      }

      cardsInfo = cardsInfo.sort(function (a, b) {
        return new Date(a.orderDate) - new Date(b.orderDate);
      });

      return {
        cardsInfo,
        allCardsInfo,
        mildCardsInfo,
        mediumCardsInfo,
        seriousCardsInfo,
        commentCardsInfo,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      sideEffectsData1,
      sideEffectsData1Sub,
      sideEffectsData2,
      sideEffectsData2Sub,
      sideEffectsData3,
      sideEffectsData3Sub,
      vitalSignsData1,
      vitalSignsData1Sub,
      vitalSignsData2,
      vitalSignsData2Sub,
      vitalSignsData3,
      vitalSignsData3Sub,
      vitalSignsComments,
      vitalSignsCommentsSub,
      sideEffectsComments,
      sideEffectsCommentsSub,
      drugsComments,
      drugsCommentsSub,
      levelReport,
    ]
  );

  useEffect(() => {
    setAllNotificationNumber(dataForCard.allCardsInfo?.length);
    setMildNotificationNumber(dataForCard.mildCardsInfo?.length);
    setMediumNotificationNumber(dataForCard.mediumCardsInfo?.length);
    setSeriousNotificationNumber(dataForCard.seriousCardsInfo?.length);
    setCommentNotificationNumber(dataForCard.commentCardsInfo?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForCard]);

  if (
    sideEffectsDataLoading1 ||    
    sideEffectsDataLoading1Sub ||
    sideEffectsDataLoading2 ||    
    sideEffectsDataLoading2Sub ||
    sideEffectsDataLoading3 ||    
    sideEffectsDataLoading3Sub ||
    vitalSignsDataLoading1 ||    
    vitalSignsDataLoading1Sub ||
    vitalSignsDataLoading2 ||    
    vitalSignsDataLoading2Sub ||
    vitalSignsDataLoading3 ||    
    vitalSignsDataLoading3Sub ||
    sideEffectsCommentsLoading ||    
    sideEffectsCommentsLoadingSub ||
    drugsCommentsLoading ||    
    drugsCommentsLoadingSub ||
    vitalSignsCommentsLoading ||    
    vitalSignsCommentsLoadingSub ||
    usersLoading
  )
    return (
      <>
        <ZaiaReaction widthImg="150px" zaiaImg={logoZaiaLoading} />
      </>
    );

  if (
    sideEffectsDataError1 ||    
    sideEffectsDataError1Sub ||
    sideEffectsDataError2 ||    
    sideEffectsDataError2Sub ||
    sideEffectsDataError3 ||    
    sideEffectsDataError3Sub ||
    vitalSignsDataError1 ||    
    vitalSignsDataError1Sub ||
    vitalSignsDataError2 ||    
    vitalSignsDataError2Sub ||
    vitalSignsDataError3 ||    
    vitalSignsDataError3Sub ||
    sideEffectsCommentsError ||    
    sideEffectsCommentsErrorSub ||
    drugsCommentsError ||    
    drugsCommentsErrorSub ||
    vitalSignsCommentsError ||    
    vitalSignsCommentsErrorSub ||
    usersError
  )
    return (
      <>
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthImg="125px"
          text={t("dashboard.boxes.noAlertsError")}
          sizeText="17px"
          widthText="240px"
        />
      </>
    );

  if (usersData.user.length <= 0)
    return (
      <>
        <NoPatients title={t("dashboard.boxes.noAlerts")} img={logoZaiaEmpty} />
      </>
    );

  if (
    dataForCard.cardsInfo?.length < 1 ||
    dataForCard.cardsInfo?.filter(
      (dataCard) => !filterSelect || filterSelect === dataCard.type
    ).length <= 0
  )
    return (
      <>
        <ZaiaReaction
          zaiaImg={alertsEmpty}
          widthImg="289px"
          text={t("dashboard.boxes.noAlerts")}
          sizeText="33.25px"
          textColor={"#37379B"}
          widthText="500px"
          padding={"0px 0px 130px 0px"}
        />
      </>
    );

  return (
    <S.ListOfBoxes>
      <Box
        cardsInfo={dataForCard?.cardsInfo}
        filterSelect={filterSelect}
        // patient={patientName}
        // time={P.hoursIn12format(orderDateObj.getHours(), orderDateObj.getMinutes())}
        // timeModified={modifiedDateObj && P.hoursIn12format(modifiedDateObj.getHours(), modifiedDateObj.getMinutes())}
        // date={P.shortDate(orderDateObj, t, true, language)}
        // dateModified={modifiedDateObj && P.shortDate(modifiedDateObj, t, true, language)}
        // description={
        //   userToDrug?.drug?.genericName
        //     ? `${userToDrug.drug.genericName} - ${description}`
        //     : description
        // }
        // report={t("dashboard.boxes.vitalSigns.report")}
        // type={type}
        // patientPhoto={user?.picture}
        // levelReport={reportlevel}
        // onClick={
        //   () => {
        //     console.log("clickkkkkkkkkk");
            
        //   }
        // }
      />
    </S.ListOfBoxes>
  );
}

export default ListOfBoxes;
