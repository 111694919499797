import { gql } from "@apollo/client";

export const MUTATION_UPDATE_USER_TO_PROCESS_HISTORY_BY_ID = gql`
  mutation MUTATION_UPDATE_USER_TO_PROCESS_HISTORY_BY_ID(
    $userToProcessHistoryId: uuid, 
    $updates: userToProcessHistory_set_input
  ) {
    update_userToProcessHistory_by_pk(pk_columns: {id: $userToProcessHistoryId}, _set: $updates) {
      created_at
      customFormFieldChanges
      descript
      doctorId
      id
      isChecked
      latestCustomFormId
      processId
      stageId
      date
    }
  }
`