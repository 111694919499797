import React, { useEffect, useState } from "react";
import * as S from "./ProfileHeader.style";
import { useTranslation } from "react-i18next";

// Hooks
import useUploadTempImg from "../../../../../hooks/useUploadTempImg"

// pipe
import useActivateModal from "../../../../../hooks/useActivateModal";
import InvitationsManagement from "../../../patients/components/invitationsManagement";
import InvitePatientButton from "../../../patients/components/invitePatientButton";

// assets
const defaultDoctor = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-doctor.png`;

function ProfileHeader({
  setImageData, 
  doctorData, 
  documentTypeData,
  activeProfileEdition,
  setActiveProfileEdition,
}) {
  const { t } = useTranslation("global");
  const hiddenFileInput = React.useRef(null);
  const {tempImg, uploadImg} = useUploadTempImg(doctorData?.picture);
  const { activeModal, desactiveModal } = useActivateModal();  

  useEffect(() => {
    setImageData(tempImg);
  }, [setImageData, tempImg])
  
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const modalInvitationsManagement = () => {
    return (
      <>
        <InvitationsManagement/>
        <div onClick={() => desactiveModal()}>
          <InvitePatientButton activate={true} />
        </div>
      </>
    )
  } 

  const additionalSettings = [
    // {
    //   id: 0,
    //   name: "Notifications",
    //   icon: "zaia-alert",
    //   action: ""
    // },
    {
      id: 1,
      name: t("profile.patientsManagement"),
      icon: "zaia-i-patient-mangement",
      action: () => activeModal(() => modalInvitationsManagement())
    }
  ]

  return (
    <S.ProfileHeaderDiv>
      <div className="image-container">
        <div className="image-change">
          <img src={tempImg ? tempImg : defaultDoctor} alt="" className="doctor-img" />
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={uploadImg}
            style={{display: 'none'}}
            accept="image/*"  
          />
          {
            activeProfileEdition &&
            <div className="button-change-image" onClick={handleClick}>
              <i className="zaia-icono-nuevo-mensaje icon zaia-i-edit-1"></i>
            </div>
          }
        </div>
      </div>
      <div className="buttons-profile-edition">
        {
          activeProfileEdition
          ? <>
            <button 
              className="button-edition button-edition--submit" 
              type="submit"
            >
              <div className="button-edition__text">{t("profile.saveChanges")}</div>
            </button> 
            <div 
              className="button-edition button-edition--cancel" 
              onClick={() => setActiveProfileEdition(false)}
              type="button"
            >
              <div className="button-edition__text">{t("globally.cancel")}</div>
            </div>
          </>
          : <div 
            className="button-edition button-edition--edit" 
            onClick={() => setActiveProfileEdition(true)}
            type="button"
          >
            <div className="button-edition__text">{t("profile.editInformation")}</div>
            <i className="button-edition__icon icon zaia-i-edit-1"></i>
          </div>
        }
      </div>
      {/* <div className="additional-settings">
        <h3>{t("profile.additionalSettings")}</h3>
        {
          additionalSettings.map(setting => {
            return <div className="setting-box" onClick={setting.action} key={setting.id}>
              <div className="setting-box-icon">
                <i className={`settings-icon icon ${setting.icon}`}></i>
              </div>
              <div className="setting-box-text">{setting.name}</div>
            </div>
          })
        }
      </div> */}
    </S.ProfileHeaderDiv>
  );
}

export default ProfileHeader;
