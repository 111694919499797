import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalAddEventCalendar = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  /* background-color: var(--white); */
  /* background-color: ${`#090949${getHexOpacity(0.50)}`}; */
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    z-index: 200;
    background-color: var(--white);
    width: 956px;
    margin: auto;
    max-height: 80%;
    border-radius: 14px;
    padding: 40px;
    box-sizing: border-box;
  }  
  .settings__container {
    overflow: auto;
    height: 100%;
    padding-right: 15px;
    padding-bottom: 40px;
    box-sizing: border-box;
    .event-calendar-form {
      &__inputs {
        height: calc(100% - 65px);
        overflow: auto;
      }
    }
    .title-container {
      display: flex;
      align-items: center;
      &__close-button {
        display: flex;
        width: 46px;
        height: 46px;
        margin-left: 20px;
        outline: none;
        border: none;
        border-radius: 16px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &-icon {
          color: #8C8DA4;
          font-size: 11px;
        }
      }
    }
    .event-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 47px 0 20px 0;
      &__time {
        display: flex;
        align-items: center;
        &-icon {
          margin-right: 10px;
          font-size: 30px;
          color: #7877BF;
        }
      }
      &__separator {
        margin: 0 39px;
        height: 24px;
        border-left: 1px solid #51515C;
        opacity: 0.2;
        align-items: center;
      }
      &__patient {
        align-items: center;
        display: flex;
        &-image {
          margin-right: 10px;
        }
      }
    }
    .event-ubication {
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      &__icon {
        font-size: 30px;
        color: #7877BF;
        margin-right: 10px;
      }
    }
    .input-content {
      width: 100%;
      font-size: 15px;
      justify-content: space-between;
      &--margin-left {
        margin-left: 20px;
      }
      span {
      }
      &__label-bold {
        /* font-family: "Nunito Bold"; */
        margin: 0 0 10px 10px;
        display: block;
        font-size: 17px;
        color: #3E3DA3;
        inline-size: max-content;
      }
      /* &--left-margin {
        margin-left: 10px;
      } */
      &__double-content {
        justify-content: space-between;
        display: flex;
        align-items: center;
      }
    }
    .buttons-container {
      display: flex;
      margin-top: 47px;
      justify-content: space-between;
      .bottom-status {
        display: flex;
        background-color: #F5F6F9;
        border-radius: 4px;
        align-items: center;
        padding: 5px;
        &__point {
          border-radius: 50%;
          width: 11px;
          height: 11px;
          background-color: ${({ status }) => status ? "red" : "green"}
        }
        &__text {
          font-family: "Nunito Sans";
          color: #526479;
          margin-left: 10px;
        }
        &__icon {
          color: #3E3DA3;
          font-size: 14px;
          margin-left: 10px;
        }
      }

      .toogle-container {
        display: flex;
        align-items: center;
        span {
          font-family: "Nunito Sans Light";
          font-size: 17px;
          color: #526479;
          margin-right: 10px;
        }
      }
      .button-submit {
        width: 174.43px;
        height: 48.28px;
        border-radius: 14px;
        padding: 10px 5px;
        font-size: 20.28px;
        font-family: "Nunito";
        outline: none;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #27AE60;
        &--disabled {
          opacity: 0.4;
          cursor: default;
        }
      }
    } 
    .button-options {
      display: flex;
      .button-option {
        padding: 10px 20px;
        display: flex;
        outline: none;
        border: none;
        border-radius: 14px;
        font-size: 20.282px;
        color: #36369B;
        border: 1px solid #36369B;
        background-color: #fff;
        align-items: center;
        cursor: pointer;
        &--margin-left {
          margin-left: 10px;
        }
        &__icon {
          font-size: 14px;
          margin-right: 5px;
        }
      }
    }
  }  

  @media only screen and (max-width: 435px) {
    .buttons-container {
      .button-submit {
        button {
          /* width: 70%; */
          margin: auto;
        }
      }
      .button-close {

      }
    } 
  }
`;