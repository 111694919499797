import React, { useState } from "react";
import Cookies from "js-cookie";

import { RegisterDiv } from "./Register.style";

// components
import StepOne from "./components/RegisterForm/StepOne";
import StepTwo from "./components/RegisterForm/StepTwo";
import StepThree from "./components/RegisterForm/StepThree";
import StepFour from "./components/RegisterForm/StepFour";
import LastStep from "./components/RegisterForm/LastStep";
import StepFive from "./components/RegisterForm/StepFive";
import StepSix from "./components/RegisterForm/StepSix";
import StepSeven from "./components/RegisterForm/StepSeven";
import CoverPage from "../coverPage";

// assets
const footer = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-pequena-botton-violeta.svg`;

function Register() {
  const webView = Cookies.get('webView');
  const [activeStep, setActiveStep] = useState(15);

  return (
    <RegisterDiv webView={webView}>
      <CoverPage/>
      <div className="register__right">
        <StepOne activeStep={activeStep} setActiveStep={setActiveStep} />
        <StepTwo activeStep={activeStep} setActiveStep={setActiveStep} />
        <StepThree activeStep={activeStep} setActiveStep={setActiveStep} />
        <StepFour activeStep={activeStep} setActiveStep={setActiveStep} />
        <StepFive activeStep={activeStep} setActiveStep={setActiveStep} />
        <StepSix activeStep={activeStep} setActiveStep={setActiveStep} />
        <StepSeven activeStep={activeStep} setActiveStep={setActiveStep} />
        <LastStep activeStep={activeStep} setActiveStep={setActiveStep} />
        <img alt="footerImg" className="footer" src={footer} />
      </div>
    </RegisterDiv>
  );
}

export default Register;
