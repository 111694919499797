import { createGlobalStyle } from "styled-components"

export const lightTheme = {

  /* PRIMARY COLORS */

  primaryColor: "#3E3DA9",
  primaryVariantColor: "#1F1F55",
  onPrimaryColor: "#FFFFFF",
  background: "#FFFFFF",
  onBackground: "#1F1F55",
  onBackgroundVariant: "#191919",
  onBackgroundSoft: "#4E4E4E",
  warning: "#f14b5f",


  /* CARDS */

  // primaryCardComment: "#12738F",
  // // primaryCardSeriousReport: "#CD0C36"
  // primaryCardSeriousReport: "#f43e66",
  // // primaryCardMildReport: "#5CCB5F",
  // primaryCardMildReport: "#77d47a",
  // // primaryCardAvarageReport: "#FF9800",
  // primaryCardAvarageReport: "#ffb84d",
  // secondaryCardComment: "#055052",
  // secondaryCardSeriousReport: "#8A0515",
  // secondaryCardMildReport: "#427F2C",
  // secondaryCardAvarageReport: "#FF6D2A",

  // primaryCardComment: "#E9F7FF",
  // primaryCardSeriousReport: "#FFF6F7",
  // primaryCardMildReport: "#E9FFEA",
  // primaryCardAvarageReport: "#FFF2E5",
  // secondaryCardComment: "#D9F2FF",
  // secondaryCardSeriousReport: "#FFEAEC",
  // secondaryCardMildReport: "#D7FFD6",
  // secondaryCardAvarageReport: "#FFE7CE",

  primaryCardComment: "#7bb4c5",
  primaryCardSeriousReport: "#f58995",
  primaryCardMildReport: "#92cb93",
  primaryCardAvarageReport: "#ffc46c",
  secondaryCardComment: "#367576",
  secondaryCardSeriousReport: "#d33b4d", // 8c
  secondaryCardMildReport: "#5e9f41", //8f
  secondaryCardAvarageReport: "#ff8a34",

  // onCardSeriousReport: "#d5c5c7",
  onCardGreySeriousReport: "#7a0a17;",
  // onCardMildReport: "#54C35D",
  onCardGreyMildReport: "#1c4c06;",
  // onCardAvarageReport: "#ffd2a8",
  onCardGreyAvarageReport: "#8b3b00",
  // onCardComment: "#36647C",
  onCardGreyComment: "#0b5354",
  // boxShadowSeriousReport: "#c4141433",
  // boxShadowMildReport: "#b4e9b7cf",
  // boxShadowAvarageReport: "#fe772869",
  // boxShadowComment: "#6d7f884d",
  onCard: "#FFFFFF",


  /* SECTIONS */

  primarySectionVitalSigns: "#ED1E37",
  primarySectionMedicines: "#7dd65E",
  primarySectionCalendar: "#ffC43B",
  primarySectionProfile: "#62CEEE",
  primarySectionSypmtoms: "#3E3DA9",
  secondarySectionVitalSigns: "#8A0515",
  secondarySectionMedicines: "#427F2C",
  secondarySectionCalendar: "#B98201",
  secondarySectionProfile: "#12738F",
  secondarySectionSypmtoms: "#1F1F55",
  onSection: "#FFFFFF",

  /* PROFILE */

  mainProfileColor: "#62CEEE",
  backgroundProfile: "#FFFFFF",
  onBackgroundProfile: "#12738F",
  onBackgroundProfileVariant: "#191919",


  /* MISCELLANEOUS */

  disableButton: "#E3E3E3",
  inputText: "#191919",
  inputBackground: "#FFFFFF",
  inputPlaceholder: "#E3E3E3",
  inputBorder: "#909090",
  greyBackground: "#707070",
  onGreyBackground: "#FFFFFF",

  // added
  inputBackgroundVariant: "#FFFFFF", // selects pequeños
  inputSelected: "#62CEEE", // boder input cuando esta seleccionado
  onBackgroundTextSoft: "#909090", // texto gris cuando no hay datos o es reaccion de zaia
  blackShadow: '#000000', // black shadow
  primaryColorShadow: "#1F1F55", // blue shadow
  lineSeparation: "#E3E3E3", //line div separation
  onWelcomeCard: "#1F1F55", // text on welcome card
  onPrimaryColorPrimary: "#6C6BD3", // filter user sidebar
  onCardFilter: "#1F1F55", // text on filter by type report
  hoverGrey: "#E3E3E3", // cuando se pasa el mouse por una lista de seleccion

  // sections
  allSections: "#6C6BD3", // dashboard filters 
  allCards: "#6C6BD3",
  
  // new
  cardFilter: '#E3E3F2', // grey filter card type report
  variantBackground: "#FAFAFA", // background color pacientes
  welcomeCard: "#C0EBF8", // welcome card background

}

export const darkTheme = {

  /* PRIMARY COLORS */

  primaryColor: "#6C6BD3",
  primaryVariantColor: "#1F1F55",
  onPrimaryColor: "#FFFFFF",
  background: "#00001C",
  onBackground: "#FFFFFFDE",
  onBackgroundVariant: "#C0EBF8",
  onBackgroundSoft: "#4E4E4E",
  warning: "#f14b5f",


  /* CARDS */

  primaryCardComment: "#12738F",
  primaryCardSeriousReport: "#CD0C36",
  primaryCardMildReport: "#5CCB5F",
  primaryCardAvarageReport: "#FF9800",
  secondaryCardComment: "#055052",
  secondaryCardSeriousReport: "#8A0515",
  secondaryCardMildReport: "#427F2C",
  secondaryCardAvarageReport: "#FF6D2A",
  onCard: "#FFFFFF",


  /* SECTIONS */

  primarySectionVitalSigns: "#ED1E37",
  primarySectionMedicines: "#7dd65E",
  primarySectionCalendar: "#ffC43B",
  primarySectionProfile: "#62CEEE",
  primarySectionSypmtoms: "#3E3DA9",
  secondarySectionVitalSigns: "#8A0515",
  secondarySectionMedicines: "#427F2C",
  secondarySectionCalendar: "#B98201",
  secondarySectionProfile: "#12738F",
  secondarySectionSypmtoms: "#1F1F55",
  onSection: "#FFFFFF",

  /* PROFILE */

  mainProfileColor: "#62CEEE",
  backgroundProfile: "#FFFFFF",
  onBackgroundProfile: "#12738F",
  onBackgroundProfileVariant: "#191919",


  /* MISCELLANEOUS */

  disableButton: "#E3E3E3",
  inputText: "#FFFFFF",
  inputBackground: "#00001C",
  inputPlaceholder: "#E3E3E3",
  inputBorder: "#909090",
  greyBackground: "#707070",
  onGreyBackground: "#FFFFFF",
  
  // added
  inputBackgroundVariant: "#FFFFFF",
  inputSelected: "#62CEEE",
  onBackgroundTextSoft: "#909090",
  blackShadow: '#000000',
  primaryColorShadow: "#3E3DA9",
  lineSeparation: "#E3E3E3",
  welcomeCard: "#C0EBF8",
  onWelcomeCard: "#1F1F55",
  onPrimaryColorPrimary: "#3E3DA9",
  onCardFilter: "#1F1F55",
  hoverGrey: "#E3E3E3",

  // sections
  allSections: "#6C6BD3",
  allCards: "#6C6BD3",
  
  // new
  cardFilter: "#E3E3F2",
  variantBackground: "#00001C",
  
}

export const  GlobalStyles = createGlobalStyle`
  input:-webkit-autofill {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({theme}) => theme.inputText};
    font-family: "Open Sans";
    font-size: 14px;
  }
  .popup-content {
    div {
      /* color: ${({theme}) => theme.background} !important; */
    }
  } 
`