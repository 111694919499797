import styled from "styled-components";
const waveBottom = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-pequena-botton-violeta.svg`;
const ZaiaError = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/error-404.svg`

export const NotFound = styled.div`
  display: flex;
  height: ${({webView}) => webView ? '100vh' : '100svh'};
  width: 100vw;
  background: url(${waveBottom}), url(${ZaiaError});
  background-repeat: no-repeat;
  background-color: ${({theme}) => theme.background};
  background-position: bottom, top;
  background-position-y: 140%, -5vh;
  background-position-x: 0, 83%;
  background-size: auto, 25%;
  overflow-y: auto;
  .left {
    width: 60%;
    display: flex;
    padding: 0 30px;
    box-sizing: border-box;
    .container-message {
      width: 310px;
      margin: auto;
      .title {
        font-family: "Nunito Bold";
        font-size: 30px;
        color: ${({theme}) => theme.primaryColor};
        text-align: center;
      }
      .title-underline {
        color: ${({theme}) => theme.primaryColor};
        border-bottom: 2px solid ${({theme}) => theme.primaryColor};
        width: 150px;
        margin: 4px auto 30px auto;
      }
      .descript {
        font-family: "Nunito SemiBold";
        color: ${({theme}) => theme.onBackground};
        margin-bottom: 20px;
        text-align: center;
      }
      .recommed {
        font-family: "Nunito SemiBold";
        color: ${({theme}) => theme.onBackground};
        margin-bottom: 20px;
        text-align: center;
      }
      .button {
        padding: 0 40px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    background-position-y: 100%, -5svh !important;
    .left {
      width: 100%;
    }
  }
`