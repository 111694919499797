import React, { useMemo } from "react";
import * as S from "./TooltipChats.style";
import { Link } from "react-router-dom"
import * as P from "../../../../utils/pipes";

// Components
import ZaiaImage from "../../../zaiaComponents/zaiaImage";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_SHORT_GET_GROUP_CHATS } from "../../../../graphql/queries/Chat";
import { useTranslation } from "react-i18next";

// Assets
const defaultChatGroup = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-chat-group.png`;

function TooltipChats({patientID, hey}) {

  const { i18n: { language }} = useTranslation("global");

  // console.log(patientID);
  // console.log(hey);

  const {data: groupChatsData, error} = useQuery(QUERY_SHORT_GET_GROUP_CHATS, {
    variables: { participantID: patientID },
  });

  console.log(error);

  const chatsList = useMemo(() => {
    return groupChatsData?.chatGroup.filter((item) => item.userToProcess.process).map(group => {
      let newGroup = JSON.parse(JSON.stringify(group)); 
      let chatPhotoUser = group.imagen;
      newGroup.chatNameProcess = P.dataTranslation(group.userToProcess.process?.processName, language); 
      newGroup.chatPhotoUser = chatPhotoUser; 
      return newGroup;
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupChatsData])

  return (
    <div onClick={() => hey()}>
      <S.TooltipChats oneChat={groupChatsData?.chatGroup >= 1 ? false : true}>
        {
          chatsList?.map(chat => {
            return (
              <Link to={`/chat/${chat?.id}`} key={chat.id}>
                <div className="box-chat">
                  <ZaiaImage 
                    img={chat.chatPhotoUser}
                    size={30}
                    defaultImg={defaultChatGroup}
                  />
                  <div className="name-chat">{`${chat.chatNameProcess}`}</div>
                </div>
              </Link> 
            )
          })
        }
      </S.TooltipChats>
    </div>
  )
}

export default TooltipChats;