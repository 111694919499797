import React from "react";
import * as S from "./ChatbotInput.style";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

function ChatbotInput({ chatbotDisabled, handleSubmit, disabledText }) {
  const { t, i18n: { language } }= useTranslation("global");

  const handleKeyUp = (e, msg) => {
    if(e.target.scrollHeight !== 56) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`; 
    }
    if(msg === ""){
      e.target.style.height = '56px';
    }
    // In case you have a limitation
    // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
  }

  const handleKeyDown = (event, handleSubmitFunc) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      // Si se presiona Enter sin mantener presionada la tecla Shift
      event.preventDefault(); // Evita el salto de línea por defecto
      handleSubmitFunc(event); // Llama a la función del formulario
    }
  };

  return (
    <S.ChatbotInput
      chatbotDisabled={chatbotDisabled}
    >
      <Formik
        initialValues={{
          msg: "",
        }}
        validationSchema={
            Yup.object({
              msg: Yup.string()
              .required(),
            })
        }
        onSubmit={handleSubmit}
      >
        {(props) => (
          <form
            onSubmit={props.handleSubmit}
            className="form-container"
          >
            {
              chatbotDisabled &&
              <i className="form-container__disabled-icon icon zaia-i-lock-chat"/>
            }
            <textarea
              placeholder={chatbotDisabled ? disabledText : t("globally.writeMessage")}
              name="msg"
              type="text"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.msg}
              autoComplete="off"
              disabled={chatbotDisabled}
              onKeyUp={(e) => handleKeyUp(e, props.values.msg)}
              onKeyDown={(e) => handleKeyDown(e, props.handleSubmit)}
              autoFocus  
            />
            <button type={
                chatbotDisabled
                ? "button" 
                : "submit"
              } disabled={props.isSubmitting}>
              <i className="send-icon icon zaia-send"></i>
            </button>
          </form>
        )}
      </Formik>
    </S.ChatbotInput>
  );
};

export default ChatbotInput;
