import React, { useState } from "react";
import * as S from "./PatientHeaderSections.style";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import { useHistory } from "react-router-dom";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";

// Components
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_BY_ID } from "../../../../../graphql/queries/User";

// pipes
import * as P from "../../../../../utils/pipes";
import ProcessName from "../../../shared/processName";
import Modal from "../../../../../utils/modal";
import ModalSelectPatient from "../../../shared/modals/modalSelectPatient";
import useAssignSelectPatient from "../../../../../hooks/useAssignSelectPatient";

// Assets
const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;
const defaultPatientImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user2.svg`

function PatientHeaderSections({ 
  patientID, 
  sections,
  selectedSection, 
  setSelectedSection,
  patientDeselectRedirectionRoute,
  selectPatientAction,
  patientSelectedVisible=true
}) {

  const { t, i18n: { language }}  = useTranslation("global");
  const [additionalInfoActive, setAdditionalInfoActive] = useState(false);
  const history = useHistory();
  const { recordAction } = useRecordNavigation();
  const [activeModalPatient, setActiveModalPatient] = useState(false);
  const { selectPatient, removePatient } = useAssignSelectPatient();

  const { data: userData } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: { patientId: patientID },
  });

  const patient = {
    id: userData?.user[0]?.id,
    img: userData?.user[0]?.picture,
    name: userData?.user[0]?.name ? userData?.user[0]?.name + " " + userData?.user[0]?.lastname : '',
    process: userData?.user[0]?.userToProcesses?.length > 1 ? t("globally.variousProcesses") : P.dataTranslation(userData?.user[0]?.userToProcesses[0]?.process?.processName, language),
    phone: userData?.user[0]?.phone,
    weight: userData?.user[0]?.weight,
    height: userData?.user[0]?.height,
    birthdate: P.getAge(userData?.user[0]?.birthdate),
    nameCarer: userData?.user[0]?.nameCarer,
    phoneCarer: userData?.user[0]?.phoneCarer
  }

  const [{ selectedPatient }] = useStateValue();

  const removePatientAction = () => {
    removePatient();
    if(patientDeselectRedirectionRoute) {
      history.push(patientDeselectRedirectionRoute); 
    }
  }

  return (
    <S.PatientHeaderSections additionalInfoActive={additionalInfoActive}>
      { 
        <Modal open={activeModalPatient}>
          <ModalSelectPatient
            setModalActive={setActiveModalPatient}
            setPatient={(patient) => selectPatient(patient.id)}
            idAction={selectPatientAction}
          />
        </Modal>
      }
      <div className="dashboard-patient-header">
        {
          patientSelectedVisible
          ? <div className="dashboard-patient-header__left">
            {
              selectedPatient
              ? <div className="dashboard-patient-header__filtered">
                <button className="dashboard-patient-header__left-close" onClick={() => removePatientAction()}>
                  <i className="dashboard-patient-header__left-close-icon icon zaia-icono-cerrar"></i>
                </button>
                <div className="dashboard-patient-header__left-filtered-by">
                  {t("globally.filterBy")}
                </div>
                <div className="dashboard-patient-header__left-img">
                  <ZaiaImage 
                    img={patient?.img} 
                    alt="" 
                    size={20} 
                    defaultImg={defaultUser} 
                    rounded
                  />
                </div>
                <div className="dashboard-patient-header__left-text">
                  <div className="dashboard-patient-header__left-text-title">
                    {patient.name}
                  </div>
                </div>
              </div>
              : <div className="patient-selector" onClick={() => setActiveModalPatient(true)}>
                <div className="patient-selector__box-icon">
                  {
                    defaultPatientImg &&
                    <img src={defaultPatientImg} alt="user" className="patient-selector__icon" />
                  }
                </div>
                <div className="patient-selector__text">
                  {t("filterPanel.filterByPatient")}
                </div>
              </div>
            }
          </div>
          : <div
            className="dashboard-patient-header__left"
          ></div>
        }
        <div className="dashboard-patient-header__right">
          <div className="patient-options">
            {
              sections.map((section) => {
                return(
                  <div
                    key={section.id} 
                    className={`patient-option ${selectedSection.id === section.id && 'patient-option--selected'}`} 
                    onClick={
                      () => {
                        if(section.action) recordAction(section.action);
                        setSelectedSection(section);
                      }
                    }
                  >
                    <div className="patient-option__text">{section.name}</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </S.PatientHeaderSections>
  );
}

export default PatientHeaderSections;
