import React, { useMemo, useState } from "react";
import * as S from "./FollowUpView.style";
import { useTranslation } from "react-i18next";
import FollowUpBox from "../followUpBox/FollowUpBox";
import { useSubscription } from "@apollo/client";
import { useStateValue } from "../../../../../../../contextAPI/StateProvider";
import { SUBSCRIPTION_GET_USER_TO_PROCESS_BY_ISACEPTED } from "../../../../../../../graphql/subscriptions/UserToProcess";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import useRecordNavigation from "../../../../../../../hooks/useRecordNavigation";
import { Actions } from "../../../../../../../utils/actionsIds";
import useAssignSelectPatient from "../../../../../../../hooks/useAssignSelectPatient";


const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function FollowUpView({
  inputText
}) {

  const {t, i18n: { language }} = useTranslation("global");
  const [, dispatch] = useStateValue();
  const { recordAction } = useRecordNavigation();
  const { selectPatient } = useAssignSelectPatient();

  const { data: userToProcesses, error: userToProcessesError, loading: userToProcessesLoading } = useSubscription(SUBSCRIPTION_GET_USER_TO_PROCESS_BY_ISACEPTED, {
    variables: { isAcepted: true },
  });

  const followUpInfo = useMemo(() => {
    return userToProcesses?.userToProcess
    .filter(userToProcess => userToProcess.processStage.id !== "enroll")
    .sort((a, b) => new Date(a.nextUpdate) - new Date(b.nextUpdate))
    .map((userToProcess, index) => {
      return (userToProcess)
    })
  }, [userToProcesses]);

  const setFollowUpActive = (userToProcess) => {
    dispatch({
      type: "SET_FOLLOWUP_ACTIVE",
      followUpActive: userToProcess,
    });
    dispatch({
      type: "SET_FOLLOWUP_MINIMIZED",
      followUpMinimized: false,
    });
  }
  
  const stages = [
    {
      id: 0,
      key: "all",
      name: t("globally.all"),
      color: "#3E3DA3"
    },
    {
      id: 1,
      key: "due",
      name: t("followUp.due"),
      color: "#FF8181",
      hoverColor: "#F15050",
      backgroundColor: "#FFF8F8"
    },
    {
      id: 2,
      key: "today",
      name: t("globally.today"),
      color: "#4DB4FF",
      hoverColor: "#0F9AFF",
      backgroundColor: "#E7F5FF"
    },
    {
      id: 3,
      key: "next",
      name: t("followUp.next"),
      color: "#2ED078",
      hoverColor: "#019947",
      backgroundColor: "#E9F9F0"
    }
  ]

  const currentStage = (nextUpdate) => {
    const nextUpdateDate = new Date(nextUpdate);

    if(
      nextUpdateDate.getDate() === new Date(Date.now()).getDate() &&
      nextUpdateDate.getMonth() === new Date(Date.now()).getMonth() &&
      nextUpdateDate.getFullYear() === new Date(Date.now()).getFullYear()
    ) {
      return stages[2]
    } else if(
      nextUpdateDate > new Date(Date.now())
    ) {
      return stages[3]
    } else {
      return stages[1]
    }
  }

  const [selectedState, setSelectedState ] = useState(stages[0]);

  const selectFollowUpPatient = (userToProcess) => {
    recordAction(Actions.AINAVIGATOR.SELECT_FOLLOW_UP);
    setFollowUpActive(userToProcess);
    selectPatient(userToProcess.user.id)
  }

  const getFilterText = (text) => {
    return inputText 
      ? String(text).toLowerCase().includes(inputText.toLowerCase())
      : true
  }  
  
  return (
    <S.FollowUpView>
      <div className="header">
        <div className="filter-state">
          {
            stages.map((state, index) => {
              return (
                <div
                  key={state.id}
                  onClick={() => setSelectedState(state)}
                >
                  <S.TabItem
                    activeTab={selectedState.id === state.id}
                    key={index}
                    color={state.color}
                  > 
                    <div className="item-text">
                      {state.name}
                    </div>
                    { 
                      state.id !== 0 &&
                      <div className="item-point"></div>
                    }
                  </S.TabItem>
                </div>
              )
            })
  
          }
        </div>
        <div className="filters-button"
          style={{visibility: "hidden"}}
        >
          <i className="filters-button__icon icon zaia-menu"></i>
          <div className="filters-button__text">{t("globally.filterBy")}</div>
        </div>
      </div>
      <div className="follow-up-list">
        <div className="follow-up-list__top">
          <div className="title title--left">{t("globally.patientName")}</div>
          <div className="title title--center">{t("followUp.nextFollowUp")}</div>
          <div className="title title--right">{t("followUp.lastPatientReview")}</div>
        </div>
        <div className="follow-up-list__center">
          {
            followUpInfo?.filter((item) => getFilterText(`${item.user.name} ${item.user.lastname}`) || getFilterText(item.user.email))?.map((userToProcess, index) => {
              return (
                (currentStage(userToProcess.nextUpdate).key === selectedState.key || selectedState.key === "all") &&
                <div className="follow-up__item" 
                  key={userToProcess.id}
                  onClick={() => 
                    selectFollowUpPatient(userToProcess)
                  }
                >
                  <FollowUpBox
                    key={userToProcess.id}
                    userToProcess={userToProcess}
                    currentStage={currentStage(userToProcess.nextUpdate)}
                  />
                </div>
              )
            })
          }
          {
            followUpInfo?.length === 0 && 
              <ZaiaReaction
                zaiaImg={logoZaiaEmpty}
                text={t("globally.noResults")}
                subText={t("globally.noMatchingPatients")}
                sizeText="33.25px"
                sizeSubText="15px"
                textColor="var(--blue-color)"
                widthImg="210px"
              />
          }
          {
            userToProcessesError &&
              <ZaiaReaction
                zaiaImg={logoZaiaAlert}
                sizeText="20px"
                text={t("globally.genericError")}
                textColor="var(--blue-color)"
                widthImg="150px"
              />
          }
          {
            userToProcessesLoading &&
              <ZaiaReaction
                zaiaImg={logoZaiaLoading}
                sizeText="20px"
                textColor="var(--blue-color)"
                widthImg="150px"
              />
          }
        </div>
        <div className="follow-up-list__bottom">
          {/* <div className="pagination">
            <i className="pagination__arrow pagination__arrow--back icon zaia-icono-atras"></i>
            <div className="pagination__number pagination__number--selected">1</div>
            <div className="pagination__number">2</div>
            <div className="pagination__number">3</div>
            <div className="pagination__number">4</div>
            <div className="pagination__number">5</div>
            <i className="pagination__arrow pagination__arrow--next icon zaia-i-next"></i>
          </div> */}

          {/* <div className="add-patient">
            <div className="add-patient__text">Add patient</div>
            <i className="add-patient__icon icon zaia-i-invitepatient"></i>
          </div> */}
        </div>
      </div>
    </S.FollowUpView>
  );
}

export default FollowUpView;
