import styled from "styled-components";

export const SymptomsBoxInfo = styled.div`

  .boxInfoPatient__info {
    width: 100% ;
    display: flex;
    
    &-left {
      display: flex;
      width: calc(100% - 185px);
      
      &-first {
        width: 20%;
        padding-right: 10px;
        min-width: 260px;
        display: flex;
      }

      &-second {
        width: 80%;
        min-width: 250px;
        padding: 0 10px 0 10px;
      }
    }

    &-right {
      width: 185px;
      padding-left: 10px;
      /* display: flex; */
      align-self: center;
    }

    h4 {
      margin-bottom: 10px;
      font-family: "Open Sans Bold";
      font-size: 15px;
    }
  }

  @media only screen and (max-width: 768px) {
    .boxInfoPatient__info {
      flex-direction: column;
      align-items: center;
      &-left {
        width: 100%;
        margin-bottom: 20px;
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;
        height: 250px;
        &-first {
          width: 100%;        
          min-width: auto;
          padding: 0px;
          margin-bottom: 10px;
        }
        &-second {
          width: 100%;        
          padding: 0px;
          margin-bottom: 10px;
        }
      }
      &-right {
        width: 100%;
      }
    }
  }
`;

export const CircleChartTextBig = styled.div`
  display: flex;
  /* flex-direction: column; */
  margin: 4px 0;
  align-items: center;
  h2 {
    color: ${(props) => props.colorPrimary};
    font-family: "Nunito ExtraBold";
    font-size: 40px;
    margin-left: auto;
  }
  p {
    padding-left: 10px;
    color: ${(props) => props.colorSecundary};
    font-size: 13px;
    font-family: "Open Sans Bold";
    width: 100px;
    margin-right: auto;
  }
`