import React from "react";
import * as S from "./MedicationPresentation.style";
import { useTranslation } from "react-i18next";
import * as P from "../../../../../../../../utils/pipes";

function MedicationPresentation({
  selectedPresentation,
  changeFormField,
  presentationList,
  goNextInput
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const getPresentationList = () => {

    return (
      <div className="presentation-list__container">
        {
          presentationList?.map((presentation) => {
            return (
              <div 
                key={presentation.value}
                className={`presentation-name ${presentation.value === selectedPresentation.value && "presentation-name--selected"}`}
                onClick={() => 
                  {
                    changeFormField(
                      [
                        {
                          value: presentation.value,
                          text: P.dataTranslation(presentation.comment, language),
                          fieldName: "presentation"
                        }
                      ]
                    );
                    goNextInput()
                  }
                }
              >{P.dataTranslation(presentation.comment, language)}</div>
            )
          })
        }
      </div>
    )

  }

  return (
    <S.MedicationPresentation>
      <div className="title">{t("healthAddData.medication.medicationPresentation")}</div>
      <div className="presentation-list">
        {
          getPresentationList()
        }
      </div>
    </S.MedicationPresentation>
  );
}

export default MedicationPresentation;
