import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, useField } from "formik";
import * as S from "./PatientCreation.style";
import * as P from "../../../../../utils/pipes";
import * as Yup from "yup";
import { ThemeContext } from "styled-components";
import CryptoJS from 'crypto-js';

// Components
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";
import ZaiaSelectForm from "../../../../zaiaComponents/zaiaSelectForm";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaToggleCircular from "../../../../zaiaComponents/zaiaToggleCircular";

// graphQL
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { MUTATION_EDIT_USER, MUTATION_ENRROLL_PATIENCE, MUTATION_UPDATE_PATIENT_IMG } from "../../../../../graphql/mutations/Patience";
import { QUERY_GET_PROCESS_TYPES } from "../../../../../graphql/queries/Process";
import { MUTATION_SIGN_UP_PATIENT } from "../../../../../graphql/mutations/User";

// hooks
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";
import { QUERY_GET_COUNTRIES } from "../../../../../graphql/queries/Country";
import useUploadTempImg from "../../../../../hooks/useUploadTempImg";
import { MUTATION_UPDATE_USER_TO_PROCESS_ID } from "../../../../../graphql/mutations/UserToProcess";
import { QUERY_GET_USER_BY_EMAIL } from "../../../../../graphql/queries/User";

// Assets
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaOk = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-success.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function PatientCreation({
  setActiveCreatePatient,
  patientData
}) {

  const [typeForm, setTypeForm] = useState("invitation");
  const NEW_USERS_PASSWORD = process.env.REACT_APP_NEW_USERS_PASSWORD;
  const { t, i18n: { language } } = useTranslation("global");
  const [formInfo, setFormInfo] = useState(null);
  const browserLang = language.split("-")[0];

  const [changePatientImg, { loading: changePatientImgLoading, error }] = useMutation(MUTATION_UPDATE_PATIENT_IMG);
  const [enrrollPatience, { loading: enrrollLoading }] = useMutation(MUTATION_ENRROLL_PATIENCE);
  const [registerUserByEmail, { loading: userRegisterUserByEmail }] = useMutation(MUTATION_SIGN_UP_PATIENT);
  const [editUser, { loading: editUserLoading }] = useMutation(MUTATION_EDIT_USER);
  const [editUserToProcess, { loading: editUserToProcessLoading }] = useMutation(MUTATION_UPDATE_USER_TO_PROCESS_ID);
  const [getUserByEmail, { loading: updateUserLoading }] = useLazyQuery(
    QUERY_GET_USER_BY_EMAIL,
    {
      fetchPolicy: "network-only"
    } 
  );
  
  const { data: processData } = useQuery(QUERY_GET_PROCESS_TYPES);
  const [imageData, setImageData] = useState();
  
  const [formSuccess, setFormSuccess] = useState(null);
  const [formSuccessText, setFormSuccessText] = useState("");
  const [formErrorText, setFormErrorText] = useState("");
  const [button2SuccessText, setButton2SuccessText] = useState("");
  
  const handleSubmit =  async (values, { resetForm }) => {
    if(patientData) {
      const {modifyInfo, modifyDescript} = getModifyInfo(values);
      editPatientData(modifyInfo, modifyDescript);
    } else {
      registerPatient(values, resetForm);
    }
  };

  const editPatientData = async (modifyInfo, modifyDescript, userId) => {
    let fileImg

    let uploadPhoto = false; 

    if(patientData) {
      if (imageData) {
        try {
          fileImg = await changePatientImg({
            variables: {
              pictureInBase64: imageData.split('base64,')[1],
              userId: patientData.id
            },
          });
  
          if (imageData && fileImg) modifyInfo.picture = fileImg.data.uploadPatientPicture.pictureUrl.toString();
          uploadPhoto = true;
        } catch (err) {
          uploadPhoto = false;
        }
      } 
    }

    try {
      await editUser({
        variables: {
          userEdited: modifyInfo,
          userId: patientData ? patientData.id : userId
        },
      });

      if(modifyDescript) {
        await editUserToProcess({
          variables: {
            userToProcessChanges: {
              descript: modifyDescript
            },
            userToProcessId: patientData?.userToProcesses.find((userToProcess) => userToProcess?.process.nameKey === "Police-Homeless").id
          },
        });
      }
      if(patientData) {
        if(uploadPhoto) {
          setFormSuccessText(t("police.changesSuccess"));
        } else {
          setFormSuccessText(t("police.changeSuccessInfoNoPhoto"));
        }
      } else {
        setFormSuccessText(t("police.registerSuccess"));
      }
      setFormSuccess(true);
      // resetForm()
    } catch (err) {
      if(patientData) {
        setFormSuccess(false);
        setFormErrorText(t("police.changeErrorInfo"));
      } else {
        setFormSuccess(true);
        setFormSuccessText(t("police.registerSuccessInfoNoPhoto"));
      }
    }
  }

  const uploadImg = async (md5Hash) => {

    let fileImg

    const {
      data: {
        user: users
      }
    } = await getUserByEmail({
      variables: {
        emailPatient: `${md5Hash}@yopmail.com`
      }
    })
    
    if (imageData) {
      try {
        fileImg = await changePatientImg({
          variables: {
            pictureInBase64: imageData.split('base64,')[1],
            userId: users[0].id
          },
        });
        editPatientData(
          {picture: fileImg.data.uploadPatientPicture.pictureUrl.toString()},
          null,
          users[0].id
        );
        setFormSuccessText(t("police.registerSuccess"));
      } catch (err) {
        setFormSuccessText(t("police.registerSuccessInfoNoPhoto"));
      }
    }
  }

  const registerPatient = async ( values, resetForm, fileImg ) => {
    let enrrollMessage;

    const md5Hash = CryptoJS.MD5(values.lastname + values.name + new Date().getTime()).toString();

    const registerVariables = {
      lastname: values.lastname,
      name: values.name,
      email: `${md5Hash}@yopmail.com`,
      lang: browserLang === 'es' || browserLang === 'en' || browserLang === 'fr' ? browserLang : "en",
      password: NEW_USERS_PASSWORD,
      birthdate: values.birthdate,
      gender: values.gender,
      picture: fileImg ? fileImg : null
    }

    try {
      const {
        data: {
          userSignUp
        }
      } = await registerUserByEmail({
        variables: registerVariables,
      });

      try {
        enrrollMessage = P.dataTranslation(JSON.parse(userSignUp.message), language);
        setFormSuccess(true);
        setFormSuccessText(enrrollMessage);
        setButton2SuccessText(t("inviteCreatePatient.registerAnotherUser"));
      } catch (err) {
        enrrollMessage = `${t("globally.genericError")},${t("globally.tryAgain")}`;
        setFormSuccess(false);
        setFormErrorText(enrrollMessage);
      }
      enrrollPatient(md5Hash, values, resetForm);
    } catch (err) {
      try {
        enrrollMessage = P.dataTranslation(JSON.parse(err.message), language);
      } catch (err) {
        enrrollMessage = `${t("globally.genericError")},${t("globally.tryAgain")}`;
      }
      setFormSuccess(false);
      setFormErrorText(enrrollMessage);
      console.log("error", err);
    }
    setFormInfo(null); 
    resetForm();
  }

  const enrrollPatient = async (md5Hash, values, resetForm ) => {
    let enrrollMessage;

    const enrrollPatientVareiables = {
      descript: values.descript,
      processId: Number(processData?.process.find((process) => process?.nameKey === "Police-Homeless")?.id),
      stageId: 'enroll',
      email: `${md5Hash}@yopmail.com`,
      lastname: ""
    }

    try {
      const {
        data: {
          enroll_user_to_process: { message }
        }
      } = await enrrollPatience({
        variables: enrrollPatientVareiables,
      });
      try {
        uploadImg(md5Hash);
        enrrollMessage = P.dataTranslation(JSON.parse(message), language);
        setFormSuccess(true);
        setFormSuccessText(enrrollMessage);
      } catch (err) {
        setFormSuccess(false);
        setFormErrorText(t("police.registerErrorInfo"));
      }
    } catch (err) {
      try {
        enrrollMessage = P.dataTranslation(JSON.parse(err.message), language);
      } catch (err) {
        enrrollMessage = "";
      }
      setFormSuccess(false);
      setFormErrorText(enrrollMessage ? enrrollMessage : t("police.registerErrorInfo"));
      console.log("error", err);
    }
    setFormInfo(null); 
  }

  const getModifyInfo = (values, fileImg) => {

    const modifyInfo = {};

    const {
      name,
      lastname,
      birthdate,
      gender
    } = patientData;

    const descript = patientData?.userToProcesses.find((userToProcess) => userToProcess?.process.nameKey === "Police-Homeless").descript 

    if (values.name !== name) modifyInfo.name = values.name;
    if (values.lastname !== lastname) modifyInfo.lastname = values.lastname;
    if (values.birthdate !== birthdate) modifyInfo.birthdate = values.birthdate;
    if (values.gender !== gender) modifyInfo.gender = values.gender;

    return {
      modifyInfo: modifyInfo, 
      modifyDescript: values.descript !== descript ? values.descript: null
    };
  }

  if(
    enrrollLoading || userRegisterUserByEmail || changePatientImgLoading
  ) 
    return(
      <S.PatientCreation>
        <div className="modal-reaction">
          <ZaiaReaction
            widthImg={"150px"}
            zaiaImg={logoZaiaLoading}
            padding={"200px 0px"}
          />
        </div>
      </S.PatientCreation>
    )

  if(formSuccess)
    return(
      <S.PatientCreation>
        <div className="modal-reaction">
          <ZaiaModalResponseContent
            button1={() => setActiveCreatePatient(false)}
            formSuccess={formSuccess}
            button1Text={t("globally.done")}
            title={formSuccessText}
          />
        </div>
      </S.PatientCreation>
    )

  if(formSuccess === false)
    return(
      <S.PatientCreation>
        <div className="modal-reaction">
          <ZaiaModalResponseContent
            button1={() => setFormSuccess(null)}
            button2={() => setActiveCreatePatient(false)}
            formSuccess={false}
            button1Text={t("globally.tryAgain")}
            button2Text={t("inviteCreatePatient.tryLater")}
            title={t("globally.errorForm")}
            subtitle={formErrorText}
            doubleButton={true}
          />
        </div>
      </S.PatientCreation>
    )

  return (
    <S.PatientCreation>
      <div className="settings__container">
        <Formik
          initialValues={{
            name: patientData?.name ? patientData?.name: "",
            lastname: patientData?.lastname ? patientData?.lastname : "",
            birthdate: patientData?.birthdate ? patientData?.birthdate : "",
            gender: patientData?.gender ? patientData?.gender : "",
            descript: 
              patientData?.userToProcesses.find((userToProcess) => userToProcess?.process.nameKey === "Police-Homeless").descript 
              ? patientData?.userToProcesses.find((userToProcess) => userToProcess?.process.nameKey === "Police-Homeless").descript 
              : "",
          }}
          validationSchema={
            Yup.object().shape(
                {
                  name: Yup.string()
                    .min(3, t("register.validation.min3"))
                    .required(t("register.validation.required")),
                  lastname: Yup.string()
                    .min(3, t("register.validation.min3"))
                    .required(t("register.validation.required")),
                  birthdate: Yup.string()
                    .required(t("register.validation.required")),
                  gender: Yup.string()
                   .required(t("register.validation.required")),
                }, ['email', 'document']
              )
          }
          onSubmit={handleSubmit}
        >
          {({isValid, handleSubmit, touched, errors, values, setFieldValue}) => (
            <S.InvitePatientsModalDiv>
              <div className="modal-header">
                <div className="modal-header__title">
                {
                  patientData
                  ? t("police.editPatient")
                  : t("inviteCreatePatient.createPatient")
                }
                </div>
                <div className="modal-header__close" onClick={() => setActiveCreatePatient(false)}>
                  <i className="modal-header__close-icon icon zaia-icono-cerrar"/>
                </div>
              </div>
              <form onSubmit={ handleSubmit }>
                <div className="card-inputs">
                  <FormRegisterInputs
                    touched={touched}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                    setImageData={setImageData}
                    patientData={patientData}
                  /> 
                </div>
                <div className="card-buttons">
                  <ZaiaButton
                    type="submit"
                    option="primary"
                    title={isValid ? t("globally.done") : t("police.createNewUser")}
                    backgroundColor={ isValid ? "#17AF54" : "#707582"}
                    borderRadius="43"
                    fontSize="32.25"
                    height="64"
                    fontFamily={"Nunito Sans"}
                    shadow={false}
                    disabled={!isValid}
                  />
                  {
                    !isValid &&
                    <div className="button-warning">
                      {t("police.createUserWarning")}
                      <b className="button-warning__required"> *</b>
                    </div>
                  }
                </div>
              </form>
            </S.InvitePatientsModalDiv>
          )}
        </Formik>
      </div>
    </S.PatientCreation>
  );
}


const FormRegisterInputs = ({
  touched,
  errors,
  setFieldValue,
  values,
  setImageData,
  patientData
}) => {
  const { t, i18n: { language } } = useTranslation("global");
  const theme = useContext(ThemeContext);
  const hiddenFileInput = React.useRef(null);
  const {tempImg, uploadImg} = useUploadTempImg();

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    setImageData(tempImg);
  }, [setImageData, tempImg])

  return(
    <>
      <div className="card-inputs-container">
        <div className="picture-selector" onClick={handleClick}>
          <div className="picture-selector__img">
            {
              (tempImg || patientData?.picture) &&
              <img src={tempImg ? tempImg : patientData?.picture} alt="" className="picture-selector__img-tag" />
            }
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={uploadImg}
              style={{display: 'none'}}
              accept="image/*"  
            />
            {
              !tempImg && !patientData?.picture &&
              <div className="picture-selector__button">
                <i className="picture-selector__icon icon zaia-i-other"></i>
              </div>
            }
          </div>
          <div className="picture-selector__label">{
            patientData
            ? t("police.changePhoto")
            : t("police.uploadPhoto")
          }</div>
        </div>

        <div className="label">
          {t("globally.patientName")}
        </div>
        <ZaiaInputForm 
          placeholder={t("globally.patientName")}
          type="text"
          name="name"
          padding={"20px 0px"}
          fontSize={"19px"}
          textColor={"#1F1F52"}
          borderColor={"#526479"}
          placeholderTextColor={"#878792"}
          borderRadius={"0px"}
          onlyBottomBorder={true}
        />
        <div className="label">
          {t("inviteCreatePatient.patientLastName")}
        </div>
        <ZaiaInputForm 
          placeholder={t("inviteCreatePatient.patientLastName")}
          type="text"
          name="lastname"
          padding={"20px 0px"}
          fontSize={"19px"}
          textColor={"#1F1F52"}
          borderColor={"#526479"}
          placeholderTextColor={"#878792"}
          borderRadius={"0px"}
          onlyBottomBorder={true}
        />
        
        
        <div className="label">
          {t("globally.gender")}
        </div>
        <div className="options-list">
          <div 
            className={`option ${values.gender === "masculine" && "option--selected"}`}
            onClick={() => setFieldValue("gender", "masculine")}
          >
            {t("globally.male")}
          </div>
          <div 
            className={`option ${values.gender === "femenine" && "option--selected"}`}
            onClick={() => setFieldValue("gender", "femenine")}
          >
            {t("globally.female")}
          </div>
        </div>
        {
          errors.gender && touched.gender &&
          <div className="input-error">
            <>
              <i className="icon zaia-icono-ayuda"></i>
              <div> {errors.gender} </div>
            </>
          </div>
        }

        <div className="label">
          {t("globally.dateOfBirth")}
        </div>
        <ZaiaInputForm 
          placeholder={t("inviteCreatePatient.patientLastName")}
          type="date"
          name="birthdate"
          padding={"20px 0"}
          fontSize={"19px"}
          textColor={"#878792"}
          borderColor={"#526479"}
          borderRadius={"0px"}
          onlyBottomBorder={true}
        />
        <div className="label">
          {t("police.userDescription")}
        </div>
        <div className="comment-content">
          <InputParagraph
            placeholder={t("police.userDescriptionText")}
            name="descript"
            type="textarea"
          />
        </div>
      </div>
    </>
  )
}

const InputParagraph = ({ placeholder, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <S.InputParagraphDiv>
      <textarea className="text-area-input" placeholder={placeholder} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </S.InputParagraphDiv>
  );
};

export default PatientCreation;


