import styled from "styled-components";

export const ChatbotInput = styled.div`
  display: flex;
  color: var(--blue-color);
  align-items: center;
  justify-content: space-evenly;
  padding: 10px 10px 20px 10px;
  background-color: ${({chatbotDisabled}) => chatbotDisabled ? '#ededff85' : '#ededffe6' };
  .form-container{
    align-items: center;
    display: flex;
    width: 100%;
    &__disabled-icon {
      position: absolute;
      margin-left: 20px;
      font-size: 20px;
      opacity: 0.6;
    }
  }
  textarea {
    border: none;
    border-radius: 75px;
    padding: ${({chatbotDisabled}) => chatbotDisabled ? '18px 20px 18px 40px' : '16px 20px 16px 40px' }; 
    box-sizing: border-box;
    margin-right: 20px;
    outline: none;
    background-color: ${({chatbotDisabled}) => chatbotDisabled ? '#FCFCFE' : '#fff' };
    background-color: #fff;
    font-size: ${({chatbotDisabled}) => chatbotDisabled ? '14px' : '16px' }; 
    color: var(--blue-color);
    resize: none;
    height: 56px;
    max-height: 100px;
    width: calc(100% - 45px);
    color: #3E3DA3;
    font-family: "Nunito Sans";
    overflow: auto;
    ::-webkit-scrollbar {
      width: 40px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px transparent;
      /* margin: 1px 0px;  */
    }
    ::-webkit-scrollbar-thumb {
      border: 17px solid rgba(0, 0, 0, 0);
      box-shadow: inset 0 0 10px 10px #3E3DA366;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: transparent;
    }
    ::placeholder {
      color: #3E3DA3;
      font-family: "Nunito Sans";
    }
  }
  button {
    opacity: ${({chatbotDisabled}) => chatbotDisabled ? '0.5' : '1' };
    background-color: var(--background-patient);
    color: var(--blue-color);
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    outline: none;
    cursor: ${({chatbotDisabled}) => chatbotDisabled ? 'auto' : 'pointer'};  
    background-color: #36369B;
    display: flex;
    opacity: ${({chatbotDisabled}) => chatbotDisabled ? '0.4' : '1'};
    i{
      color: #fff;
      font-size: 10px;
      margin: auto
    }
  }
`;
