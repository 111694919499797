import styled from "styled-components";

export const NoteCreationEditionMaxView = styled.div`
  width: 100%;
  height: 100%;
  .notes-view {
    width: 100%;
    height: 100%;
    display: flex;
    &__left {
      width: 70%;
      padding: 0px 20px 27px 37px;
    } 
    &__right {
      padding: 0px 37px 27px 0px;
      width: 30%;
      &-content {
        padding-top: 10px; 
        height: 100%;
        width: 100%;
        background-color: #FFFFFF;
        border-radius: 14px;
        box-sizing: border-box;
      }
    }
  }
`
