import styled from "styled-components";

export const ChatbotGeneralCase = styled.div` 
  height: 100%;
  .chatbot-container {
    height: ${({footerHeight}) => `calc(100% - ${footerHeight}px)` };
  }
`;


