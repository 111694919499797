import React from "react";
import * as S from "./ZaiaCounter.style";

function ZaiaCounter({
  counterNumber,
  setCounterNumber,
  color
}) {

  return (
    <S.ZaiaCounter
      color={color} 
    >
        <div className={`zaia-counter__button zaia-counter__button--back ${counterNumber <= 1 && 'zaia-counter__button--disabled'}`} onClick={counterNumber <= 1 ? null : () => setCounterNumber(counterNumber - 1)}>
          {/* <i className="zaia-counter__button-icon">-</i> */}
          -
        </div>
        <div className="zaia-counter__number">
          {counterNumber}
        </div>
        <div className="zaia-counter__button zaia-counter__button--next" onClick={() => setCounterNumber(counterNumber + 1)}>
          {/* <i className="zaia-counter__button-icon">+</i> */}
          +
        </div>
    </S.ZaiaCounter>
  )
}

export default ZaiaCounter;