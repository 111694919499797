import styled from "styled-components";

export const MedicationMeasure = styled.div`
	.title {
		color: #3E3DA3;
		font-family: "Nunito Sans";
		font-size: 20px;
		padding: 20px 10px;
	}
	.responses-list {
		display: flex;
		flex-direction: column;
		.response-item {
			border-radius: 14px;
			border: none;
			border-bottom: 1px solid #6C6BCC;
			background: #FFF;
			display: flex;
			padding: 20px 391.09px 20px 27px;
			color: #6C6BCC;
			font-family: Nunito Sans;
			font-size: 19px;
			cursor: pointer;
			align-items: center;
			margin-bottom: 40px;
			&__circle {
				background-color: transparent;
				border-radius: 50%;
				width: 8px;
				height: 8px;
				border: 2px solid #6C6BCC;
				margin-right: 5px;
				&--selected {
					border: 2px solid #FFF;
				}
			}
			&--selected {
				background-color: #6C6BCC;
				color: #FFF
			}
		}
	}
`