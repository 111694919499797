import styled from "styled-components";

export const ModalOutcomesEventHistoryEditing = styled.div`
  height: calc(100% - 129px);
  .modal-content {
    height: calc(100% - 57px);
    padding: 25px 40px 25px 40px;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    flex-direction: column;
    .history-data {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      .date {
        font-family: Nunito Sans Bold;
        font-size: 25.5px;
        color: #707582;
      }
      .time {
        font-family: Nunito Sans light;
        font-size: 20px;
        color: #707582;
        margin-left: 10px;
      }
      .doctor {
        display: flex;
        align-items: center;
        margin-left: 20px;
        &__label {
          font-family: Nunito Sans;
          font-size: 15px;
          color: #3E3E5B;
        }
        &__name {
          font-family: Nunito Sans;
          font-size: 15px;
          color: #3E3E5B;
          margin-left: 5px;
        }
      }
    }
    .separation-icon {
      font-size: 18.14px;
      color: #3E3E5B;
      opacity: 0.4;
      margin: 10px auto;
      transform: rotate(90deg);
    }
    .fields-box {
      &__title {
        display: flex;
        align-items: center;
        &-icon {
          font-size: 24px;
          &--actual {
            color: #8A8AC3;
          }
          &--new {
            color: #27AE60;
          }
        }
        &-text {
          margin-left: 10px;
          font-family: Nunito Sans Bold;
          font-size: 25.5px;
        }
      }
      &__fields {
        display: flex;
        flex-wrap: wrap;
        margin-top: 10px;
        padding: 10px 0 0 0;
        .detail {
          margin-right: 40px;
          margin-bottom: 20px;
          &__label {
            display: flex;
            align-items: center;
            color: #3E3E5B;
            font-family: "Nunito Sans Bold";
            font-size: 15px;
            margin-bottom: 10px;
            &-icon {
              margin-left: 10px;
              color: #38379C;
            }
          }
          &__value {
            font-family: Nunito Sans;
            font-size: 15px;
            color: #1F1F52;
            &-icon {
              margin-left: 10px;
              color: #38379C;
            }
            .file-value {
              color:#6C6BCC;
              display: flex;
              align-items: center;
              &__icon {
                margin-right: 5px;
                font-size: 15px;
              }
              &__text {
                color:#6C6BCC;
                font-size: 15px;
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
        &--new {
          padding: 0 20px 50px 20px;
          border-radius: 14px;
          background-color: #F7F6FC;
        }
      }
    }
  }
`;

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Open Sans';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`