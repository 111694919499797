import React, { useRef, useState, useEffect, useContext  } from "react";
import { useField } from "formik";
import * as S from "./ZaiaInputHour.style.js";
import { ThemeContext } from 'styled-components';

function ZaiaInputHour({ 
  placeholder, 
  icon,
  setErrors,
  padding,
  height,
  fontSize,
  textarea,
  errorOption = true,
  ...props
}) {  
  const container = useRef(null);
  const textInput = useRef(null);
  const [field, meta, helpers] = useField(props);
  const [active, setActive] = useState(false);
  const { setValue } = helpers;
  const theme = useContext(ThemeContext);

  useEffect(() => {
    const handleClickOutside = e => {
      if (!container.current.contains(e.target)) {
        setActive(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const activeInput = () => {
    if (!props.disabled) {
      textInput.current.focus(); 
      setActive(true);
    } 
  } 

  const setPmAm = (am) => {
    if(field.value) {
      let fieldValue = field.value.split(":")[0];
      if(am) {
        setValue(`${Number(fieldValue)-12 < 10 ? "0" : ''}${Number(fieldValue)-12}:${field.value.split(":")[1]}`);
      } else {
        setValue(`${Number(fieldValue)+12}:${field.value.split(":")[1]}`);
      }
    } else {
      if(am) {
        setValue("00:00");
      } else {
        setValue("12:00");
      }
    }
  } 
  return (
    <S.ZaiaInputHour 
      padding={padding} 
      active={active} 
      error={meta} 
      disabled={props.disabled} 
      fontSize={fontSize}
    >
      <div className="input-container" onClick={() => activeInput()} ref={container}>
        <i className={'icon-input-form icon zaia-' + icon} style={{ color: active ? theme.inputSelected : theme.inputPlaceholder }} />
          <input 
            ref={textInput}
            name={field.name}
            value={field.value.split(":")[0]}
            {...props}
            {...field}
          />
          <button 
            className={`set-pm-am-button ${(field.value.split(":")[0] >= 12) ? "set-pm-am-button--disabled" : ''}`} 
            type="button" 
            onClick={() => (field.value.split(":")[0] >= 12) ? setPmAm(true) : null}>
              AM
          </button>
          <button 
            className={`set-pm-am-button ${(field.value.split(":")[0] < 12) ? "set-pm-am-button--disabled" : ''}`} 
            type="button" 
            onClick={() => (field.value.split(":")[0] < 12) ? setPmAm(false) : null}>
              PM
          </button>
      </div>
      {
        errorOption ?
        <div className="input-error">{
          meta.touched && meta.error ? 
          <>
            <i className="icon zaia-icono-ayuda"></i>
            <div> {meta.error } </div>
          </>
          : null}
        </div> : null
      }
    </S.ZaiaInputHour>
  );
}

export default ZaiaInputHour;
