import styled from "styled-components";

export const SurveyQuestionsCharts = styled.div`
  .chart-list-container {
    &__title {
      font-size: 22px;
      color: #36369B;
      margin-left: 23px;
      margin-top: 40px;
      font-family: "Nunito Sans Bold";
    }
  }
  .chart-container {
    padding: 36px 20px 0 20px;
    &__label {
      margin-left: 23px;
      font-family: Nunito Sans;
      font-size: 22px;
      color: #707582;
    }
    &__type {
      margin-left: 23px;
      background-color: #E0E6ED;
      padding: 4px 10px 4px 10px;
      border-radius: 5px;
      margin-bottom: 40px;
      font-family: Nunito Sans;
      font-size: 17px;
      box-sizing: border-box;
      color: #707582;
      width: fit-content;
      margin-top: 5px;
      
    }
  }
`;