/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import * as S from "./EmailSent.style";
import ZaiaButton from "../../zaiaComponents/zaiaButton";
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

// Assets
const zaiaImage = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-mail.svg`;

function EmailSent({email, setEmailSent, message1, message2}) {
  const [t] = useTranslation("global");
  const webView = Cookies.get('webView');

  return (
    <S.EmailSent webView={webView}>
      <div className="content-email-sent">
        <h2 className="content-email-sent__title">{t("emailSent.haveEmail")}</h2>
        <img className="zaia-image" src={zaiaImage} alt="ZAIA" />
        <div className="message">{message1}</div>
        <div className="email-container">
          <h3>{email}</h3>
        </div>
        <div className="message">{message2}</div>
        <div className="button-container">
          <Link to="/">
            <ZaiaButton 
              title={t("home.goToHome")}
              shadow={true}
            />
          </Link>
          <div className="button-message">
            <p>
              {t("emailSent.dontReceive")}
              <Link to="#" onClick={() => setEmailSent(false)}> {t("emailSent.sendAgain")}</Link>
            </p>
          </div>
        </div>
      </div>
    </S.EmailSent>
  );
}

export default EmailSent;
