import React, { useEffect, useRef, useState } from "react";
import * as S from "./ModalPDFReader.style"
import { useTranslation } from "react-i18next";
import useUploadTempDoc from "../../../../../hooks/useUploadTempDoc";
import useUploadDoc from "../../../../../hooks/useUploadDoc";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import useSaveDoc from "../../../../../hooks/useSaveDoc";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";

const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function ModalPDFReader({ 
    setModalActive
}) {
  
  const {t, i18n: { language }} = useTranslation("global"); 

  const {tempDoc, dataDoc, errorFormat, uploadTempDoc, deleteDoc} = useUploadTempDoc();
  const {uploadDoc, data, loading, error} = useUploadDoc();
  const {saveDoc, data: saveDocData, loading: saveDocLoading, error: saveDocError} = useSaveDoc();
  const FILE_READER_ENDPOINT = process.env.REACT_APP_FILE_READER_ENDPOINT;

  const [processingDocumentLoading, setProcessingDocumentLoading] = useState(false);
  const [uploadedDocument, setUploadedDocument] = useState(false);
  const [processedDocument, setProcessedDocument] = useState(false);
  const [proccessingDocumentFinished, setProccessingDocumentFinished] = useState(false);

  const [errorProcessingDocument, setErrorProcessingDocument] = useState(false);

  const [documentInfo, setDocumentInfo] = useState([]);

  const [savedDocumentSuccess, setSavedDocumentSuccess] = useState()

  const fileInput = useRef(null);

  const inputStates = [
    {
      id: 0,
      name: t("fileReading.lowConfidence"),
      color: '#FF4A4A',
    },
    {
      id: 1,
      name: t("fileReading.mediumConfidence"),
      color: '#FF964A',
    },
    {
      id: 2,
      name: t("fileReading.edited"),
      color: '#1F1F52',
    },
  ]

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setModalActive(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  useEffect(() => {
    if(dataDoc) {
      setUploadedDocument(true);
    }
  }, [dataDoc]);

  const handleClick = (e) => {
    fileInput.current.click();
  }

  const processingDocumentCancel = () => {
    setProcessingDocumentLoading(false);
    setProccessingDocumentFinished(false);
    setErrorProcessingDocument(false);
    setProcessedDocument(false);
  }

  const processingDocumentDone = () => {
    setProcessingDocumentLoading(false);
    setProccessingDocumentFinished(true);
  }

  const processDocument = async () => {
    setProcessedDocument(false);
    setProcessingDocumentLoading(true);

    if (tempDoc) {
      try {
        let fileInfo = await uploadDoc(tempDoc, FILE_READER_ENDPOINT);
        console.log("fileInfo 123", fileInfo);
        setDocumentInfo(fileInfo?.data.processed_document.document_fields);
        setProcessedDocument(true);
      } catch (err) {
        setProcessedDocument(false);
        setErrorProcessingDocument(true);
        deleteDoc();
        setUploadedDocument();
      }
    }
  }

  const  saveDocument = async () => {
    setSavedDocumentSuccess(null);
    if (documentInfo) {
      const value = {
        input: {
          document_data: documentInfo
        }
      }
      try {
        await saveDoc(
          JSON.stringify(value)
        );
        setSavedDocumentSuccess(true);        
      } catch (err) {
        setSavedDocumentSuccess(false);      
      }
    }
  }

  const processDocumentCancel = () => {
    deleteDoc();
    setUploadedDocument(false);
    setProcessedDocument(false);
    setProccessingDocumentFinished(false);
  }


  const FooterButtons = () => {
   
    if (!proccessingDocumentFinished)
      return(
        <div className="footer__buttons">
          <button className={`button ${!uploadedDocument && "button--disabled"}`} onClick={ uploadedDocument ? () => processDocument() : null}>
            {
              !uploadedDocument &&
              <i className="button__icon-block icon zaia-i-lock-chat"></i>
            }
            <div className="button__text">{t("fileReading.process")}</div>
          </button>
        </div>
      )

    return(
      <div className="footer__buttons">
        <button className="button" 
          onClick={() => processDocumentCancel()}
        >
          <i className="button__icon icon zaia-i-upload"></i>
          <div className="button__text">{t("fileReading.uploadAgain")}</div>
        </button>
        <button className="button button--success" 
          onClick={() => saveDocument()}
        >
          <div className="button__text">{t("fileReading.saveFile")}</div>
        </button>
      </div>
    )
  }

  const textareasKeyRef = useRef([]);
  const textareasValueRef = useRef([]);

  useEffect(() => {
    textareasKeyRef?.current.forEach((textarea) => {
      try {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight-20}px`;
      } catch (e) {
        console.log(e);
      }
    });
  }, [proccessingDocumentFinished]);

  useEffect(() => {
    textareasValueRef?.current.forEach((textarea) => {
        try {
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight-20}px`;
        } catch (e) {
          console.log(e);
        }
      });
  }, [proccessingDocumentFinished]);

  const textAreaChange = (e) => {
    console.log(e.value);
    e.style.height = 'auto';
    e.style.height = `${e.scrollHeight-20}px`;
  }

  // if(
  //   saveDocLoading
  // ) 
  //   return(
  //     <S.ModalPDFReader>
  //       <div className="modal-background">
  //       </div>
        
  //     </S.ModalPDFReader>
  //   )

  // if(savedDocumentSuccess)
  //   return(
  //     <S.ModalPDFReader>
  //       <div className="modal-background">
  //       </div>
  //     </S.ModalPDFReader>
  //   )

  // if(savedDocumentSuccess === false)
  //   return(
  //     <S.ModalPDFReader>
  //       <div className="modal-background">
  //       </div>
        
  //     </S.ModalPDFReader>
  //   )

  return (
    <S.ModalPDFReader>
      
      <div className="modal-background">
      </div>
      <div className="modal-container" style={{display: saveDocLoading ? "flex" : "none"}}>
        <ZaiaReaction
          widthImg={"150px"}
          zaiaImg={logoZaiaLoading}
          padding={"200px 0px"}
        />
      </div>
      <div className="modal-container" style={{display: savedDocumentSuccess ? "flex" : "none"}}>
        <ZaiaModalResponseContent
          button1={() => {
            setSavedDocumentSuccess(null)
          }}
          formSuccess={true}
          title={t("fileReading.saveSuccess")}
          // button1Text={t("globally.done")}
          button1Text={"done"}
          // button2Text={"Document saved"}
          // title={""}
          doubleButton={false}
        />
      </div>
      <div className="modal-container" style={{display: savedDocumentSuccess === false ? "flex" : "none"}}>
        <ZaiaModalResponseContent
          button1={() => saveDocument()}
          button2={() => setSavedDocumentSuccess(null)}
          formSuccess={false}
          // button1Text={t("globally.tryAgain")}
          button1Text={t("globally.tryAgain")}
          // button2Text={t("inviteCreatePatient.tryLater")}
          button2Text={t("globally.tryItLater")}
          title={t("fileReading.saveError")}
          // subtitle={formErrorText}
          doubleButton={true}
        />
      </div>
      <div className="modal-container" style={{display: saveDocLoading || savedDocumentSuccess || savedDocumentSuccess === false ? "none" : "flex"}}>
        <div className="header">
          <div className="header__text">
            <div className="header__title">{t("fileReading.readDocument")}</div>
          </div>
          {/* <div className="header__close" onClick={() => setModalActive(false)}>
            <i className="header__close-icon icon zaia-icono-cerrar"/>
          </div> */}
        </div>
        <div className="content">
          {
            processingDocumentLoading 
            ?
              <div className="document-loading">
                <div className="circle">
                  <i className="document-icon icon zaia-icon-file"></i>
                </div>
                {
                  !processedDocument && !errorProcessingDocument &&
                  <div className="process-text">
                    {t("fileReading.processing")}
                    <S.ZaiaLoadingPoints
                      color={"#9292B1"}
                    >
                      <div className="dot-flashing"></div>
                    </S.ZaiaLoadingPoints>
                  </div>
                }
                {
                  !errorProcessingDocument &&
                    (
                      !processedDocument
                      ? <button className="button"
                        onClick={() => processingDocumentCancel()}
                      >{t("globally.cancel")}</button>
                      : <button className="button button--success"
                        onClick={() => processingDocumentDone()}
                      >{t("globally.done")}</button>
                    )
                }
                {
                  errorProcessingDocument && 
                  <div className="error-processing">
                    <div className="error-processing__text">
                      {t("fileReading.errorProcessing")}
                    </div>
                    <button className="button" onClick={() => processingDocumentCancel()}>
                      {t("fileReading.goBack")}
                    </button>
                  </div>
                }
              </div>
            : <>
              {
                !proccessingDocumentFinished && 
                <div className="upload-document">
                  <input
                    type="file"
                    ref={fileInput}
                    onChange={(e) => uploadTempDoc(e)}
                    style={{display: 'none'}}
                    // accept="image/*"  
                  />
                  {
                    uploadedDocument && tempDoc
                    ? <div className="upload-document__box" onClick={handleClick}>
                      <i className="upload-document__box-icon icon zaia-icon-file"></i>
                      <div className="upload-document__box-text">{dataDoc?.name}</div>
                    </div>
                    : <div className="upload-document__box" onClick={handleClick}>
                      <i className="upload-document__box-icon icon zaia-i-upload"></i>
                      <div className="upload-document__box-text">{t("fileReading.uploadDocument")}</div>
                    </div>
                  }
                  {
                    errorFormat &&
                    <div className="upload-document__error-format">
                      {t("fileReading.formatError")}
                    </div>
                  }
                </div>
              }
              {
                proccessingDocumentFinished &&
                <div className="uploaded-document">
                  
                  <div className="uploaded-document__text">
                    {t("fileReading.confidenceText1")} <b>{t("fileReading.confidenceText2")}</b> {t("fileReading.confidenceText3")}
                  </div>
                  <div className="uploaded-document__content">
                    {/* <button className="process-again" onClick={() => processDocument()}>
                      <i className="process-again__icon icon zaia-i-refresh"></i>
                      <div className="process-again__text">Process again</div>
                    </button> */}
                    <table className="document-info-table">
                      <thead>
                        <tr>
                          <th>{t("fileReading.typeData")}</th>
                          <th>{t("fileReading.data")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          documentInfo.map((info, index) => {
                            
                            let confidenceKey;
                            let confidenceValue;

                            confidenceKey = info.field_name.key_confidence < 0.4 
                            ? inputStates[0]
                            : info.field_name.key_confidence < 0.7
                              ? inputStates[1]
                              : null

                            confidenceValue = info.field_value.value_confidence < 0.4 
                            ? inputStates[0]
                            : info.field_value.value_confidence < 0.7
                              ? inputStates[1]
                              : null

                            return(
                              <tr>
                                <td>
                                  <S.TableItem
                                    color={
                                      // textareasKeyRef.current[index]?.value !== info.field_name.key
                                      //   ? inputStates[2].color
                                      //   : 
                                      confidenceKey?.color
                                    }
                                  >
                                    <div className="input-container">
                                      <textarea 
                                        rows={1}
                                        key={index}                                      
                                        type="text" 
                                        className="table-text" 
                                        defaultValue={info.field_name.key}
                                        // defaultValue={info.field_value.value}
                                        ref={(el) => (textareasKeyRef.current[index] = el)}
                                        style={{ width: '100%', resize: 'none' }}
                                        onKeyUp={(e) => textAreaChange(textareasKeyRef.current[index] )}
                                        />
                                        <div className="input-indicator">
                                          {
                                            // textareasKeyRef.current[index]?.value !== info.field_name.key
                                            // ? inputStates[2].name
                                            // : 
                                            confidenceKey?.name
                                          }
                                        </div>
                                    </div>
                                    <div className="table-separator"></div>
                                  </S.TableItem>
                                </td>
                                <td>
                                  <S.TableItem
                                    color={
                                      // textareasValueRef.current[index]?.value !== info.field_value.value
                                      //   ? inputStates[2].color
                                      //   : 
                                        confidenceValue?.color
                                    }
                                  >
                                    <div className="input-container">
                                      <textarea 
                                        rows={1}
                                        key={index}                                      
                                        type="text" 
                                        className="table-text" 
                                        // defaultValue={info.field_name.key}
                                          defaultValue={info.field_value.value}
                                        ref={(el) => (textareasValueRef.current[index] = el)}
                                        style={{ width: '100%', resize: 'none' }}
                                        onKeyUp={(e) => textAreaChange(textareasValueRef.current[index])}
                                        onChange={(e) => textAreaChange(textareasValueRef.current[index])}
                                      />
                                      <div className="input-indicator">
                                        {
                                          // textareasValueRef.current[index]?.value !== info.field_value.value
                                          // ? inputStates[2].name
                                          // : 
                                          confidenceValue?.name
                                          // textareasValueRef.current[index]?.value
                                        }
                                      </div>
                                    </div>
                                  </S.TableItem>
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              }
            </>
          }
        </div>
        {
          processingDocumentLoading &&
          <div className="loading-footer"></div>
        }
        {
          !processingDocumentLoading &&
          <div className="footer">
            <div className="footer__text">
              {
                !proccessingDocumentFinished
                ? t("fileReading.selectDocumentAdvice")
                : t("fileReading.checkAndEdit")
              }
            </div>
            <FooterButtons />
          </div>
        }
      </div>
    </S.ModalPDFReader>
  );
}

export default ModalPDFReader;