import { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import ReactGA from "react-ga";
import { Routes } from "../../utils/constants";

const env = process.env.REACT_APP_ENV;

const PublicRoute = ({ component: Component, authed, ...rest }) => {
  const { AINAVIGATOR } = Routes;

  useEffect(() => {
    if (env === 'production') {
      ReactGA.pageview(window.location.pathname);
    }
  }, [Component])

  return (
    <Route
      {...rest}
      render={(props) =>
        authed === false ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{  pathname: AINAVIGATOR.route, state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default PublicRoute;
