import styled from "styled-components";

export const MedicationDosage = styled.div`
  .title {
    color: #3E3DA3;
    font-family: "Nunito Sans";
    font-size: 16px;
    padding: 20px 0px;
  }
  .doseage-inputs {
    margin-top: 40px;
    .input-space {
      position: relative;
      &--dose {
        margin-top: 30px;
      }
      .input-label {
        color: #3E3E5B;
        font-family: "Nunito Sans";
        font-size: 14px;
        margin-bottom: 10px;
      }
      .input-msg {
        color: #8C8DA4;
        font-family: "Nunito Sans";
        font-size: 14px;
        padding: 10px;
      }
      &__number-icon{
        position: absolute;
        z-index: 1;
        right: 0;
        margin: 20px 26px 20px 0;
        color: #878792;
        font-size: 17px;
      }
      .input-container {
        border: none;
        border-bottom: 1px solid #6C6BCC;
        input {
          font-family: "Nunito Sans Bold";
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      }
    }
  }
`