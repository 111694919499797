import React, { useState, useEffect } from "react";
import * as S from "./Alerts.style";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../contextAPI/StateProvider";
import { useHistory } from "react-router-dom"

// Components
import Box from "./components/box";
import TooltipChats from "../shared/tooltipChats";
import ListOfBoxes from "./components/listOfBoxes";
import ZaiaButton from "../../zaiaComponents/zaiaButton";
import InvitePatientButton from "../patients/components/invitePatientButton";
import DashboardFilterPanel from "./components/dashboardFilterPanel";

// Pipes
import * as P from "../../../utils/pipes"

// Hooks
import useRecordNavigation from "../../../hooks/useRecordNavigation";
import PatientHeaderSections from "../aiNavigator/components/patientHeaderSections";
import AlertsList from "./components/views/alertsList";
import { Actions } from "../../../utils/actionsIds";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function Alerts() {
  const { t, i18n: { language }} = useTranslation("global");
  const location = useLocation();  
  const history = useHistory();

  const [{ selectedPatient }, dispatch] = useStateValue();


  const [filterSelected, setFilterSelected] = useState(0);
  // const [processIds, setProcessIds] = useState([]);
  const [allNotificationNumber, setAllNotificationNumber] = useState(0);
  const [mildNotificationNumber, setMildNotificationNumber] = useState(0);
  const [mediumNotificationNumber, setMediumNotificationNumber] = useState(0);
  const [seriousNotificationNumber, setSeriousNotificationNumber] = useState(0);
  const [commentNotificationNumber, setCommentNotificationNumber] = useState(0);
  const [levelReport, setLevelReport] = useState(0);
  const [initialDateRange, setInitialDateRange] = useState(null);
  const [finishDateRange, setFinishDateRange] = useState(null);
  const [nowDate, setNowDate] = useState(new Date(Date.now()));
  const [selectedBox, setSelectedBox] = useState();
  const [seePhone, setSeePhone] = useState(false);
  const [filtersActive, setFiltersActive] = useState(false);
  const [rightSideActive, setRightSideActive] = useState(false); 
  const [processesFiltered, setProcessesFiltered] = useState([]);
  const { recordAction } = useRecordNavigation();
  const [filterAZ, setFilterAZ] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [selectorAll, setSelectorAll] = useState(null);
  
  const sections = [
    {
      id: 1,
      name: t("dashboard.compactView"),
      action: Actions.ALERTS.COMPACT_VIEW
    },
    {
      id: 2,
      name: t("dashboard.alertsList"),
      action: Actions.ALERTS.ALERTS_VIEW
    }
  ]

  const [selectedSection, setSelectedSection] = useState(sections[0]);

  useEffect(() => {
    const searchLocation = location.search;
    const params = new URLSearchParams(searchLocation);
    if(selectedPatient) {
      setSelectedSection(sections[1])
    } else {
      if(Boolean(params.get("view")) === 2) {
        setSelectedSection(sections[1])
      } else {
        setSelectedSection(sections[0])
      }
    }
  }, [])

  useEffect(() => {
    if(processesFiltered.length === 1) {
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: processesFiltered[0].id,
      });
    } else if (processesFiltered.length === 0 || processesFiltered.length > 1) {
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: null,
      });
    }

  }, [processesFiltered])

  useEffect(() => {
    if(selectedBox) {
      dispatch({
        type: "SET_CONTEXT_PATIENT",
        contextPatient: selectedBox.user?.id,
      });
    } else {
      dispatch({
        type: "SET_CONTEXT_PATIENT",
        contextPatient: null,
      });
    }
  }, [selectedBox])

  const severitiesList = [
    {
      id: 0,
      name: t("globally.all"),
      color: "#5D5FEF"
    },
    {
      id: 1,
      name: t("globally.mild"),
      color: "#53C25D"
    },
    {
      id: 2,
      name: t("globally.medium"),
      color: "#FEBE45"
    },
    {
      id: 3,
      name: t("globally.severe"),
      color: "#C92241"
    }
  ]

  const getDayOrRange = () => {
    // const initialDateRangeYear = initialDateRange?.getFullYear();
    // const initialDateRangeMonth = initialDateRange?.getMonth();
    // const initialDateRangeDay = initialDateRange?.getDate();
    // const finishDateRangeYear = finishDateRange?.getFullYear();
    // const finishDateRangeMonth = finishDateRange?.getMonth();
    // const finishDateRangeDay = finishDateRange?.getDate();

    // if(
    //   initialDateRangeDay === finishDateRangeDay && 
    //   initialDateRangeMonth === finishDateRangeMonth && 
    //   initialDateRangeYear === finishDateRangeYear
    // ){
    //   return `${"Day"} (${P.longDate(initialDateRange, t, language)})`; 
    // } else {
      return `${"Range"} (${P.shortDate(initialDateRange, t, language)} - ${P.shortDate(finishDateRange, t, language)})`;
    // }
  }

  const filteredPatientsByAZ = () => {
    if (filterAZ === null) {
        return [];
    } else if (filterAZ === false) {
        return [...[]].sort((a, b) => {
            const nameA = " " + a.user.lastname;
            const nameB = " " + b.user.lastname;

            return nameA.localeCompare(nameB);  
        });
    } else {
        return [...[]].sort((a, b) => {
            const nameA = " " + a.user.lastname;
            const nameB = " " + b.user.lastname;

            return nameB.localeCompare(nameA); 
        });
    }
  };

  useEffect(() => {
    // setUsersList(filteredPatientsByAZ());
  }, [filterAZ]);

  return (
    <S.Alerts selectedBox={selectedBox} rightSideActive={rightSideActive}>
        <div className="header-patient-container">
          <PatientHeaderSections
            patientID={selectedPatient?.id}
            sections={sections}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            patientSelectedActive={!(selectedSection.id === 1)}
            selectPatientAction={Actions.ALERTS.SELECT_PATIENT}
            patientSelectedVisible={selectedSection.id === 2}
          />
        </div>
        <div className="alerts-container">
          <div className="filters">
            <div className="filters__left">
              <div className="filter">
                <div className="filter__label filter__label--selector-all">{"Selección masiva"}</div>
                <div className="filter__space">
                  <button 
                    className={`patient-selector-all ${selectorAll !== null && "patient-order--actived"}`}
                    onClick={() => {}}
                  >
                    <i className={`patient-selector-all__icon icon ${selectorAll ? "zaia-i-checkbox-on patient-order__icon--actived" : "zaia-i-checkbox-off"}`}></i>
                    <div className="patient-selector-all__text">
                      {"Seleccionar todos " + 44}
                    </div>
                  </button>
                </div>
              </div>
              <div className="filter filter--patient-name"> 
                <div className="filter__label">{"Ordenar por nombre"}</div>
                <div className="filter__space">
                  <button 
                    className={`patient-order ${filterAZ !== null && "patient-order--actived"}`}
                    onClick={() => setFilterAZ(
                      filterAZ === null ? true : (filterAZ === false ? null : false)
                    )}
                  >
                    <i className={`patient-order__icon icon zaia-i-filters ~iconchange__text ${filterAZ !== null && "patient-order__icon--actived"}`}></i>
                    <div className="patient-order__text">
                      {t("aiNavigator.filters.orderAZ")} <b>
                        {
                          filterAZ === false 
                          ? "Z-A"
                          : "A-Z"
                        }
                      </b>
                    </div>
                  </button>
                </div>
              </div>
              <div className="filter filter--program">
                <div className="filter__label">{"Ordenar por Nivel y tipo de alerta"}</div>
                <div className="filter__space">
                  <Popup
                    trigger={
                      <button className={`select-filter ${filterDate !== null && "select-filter--actived"}`}>
                        <div className="select-filter__text">
                          {
                            // filterProgram ? P.dataTranslation(processTypes?.process.find((item) => item.id === filterProgram)?.processName, language) 
                            true 
                          ? "Nivel"
                          : t("globally.all")}
                        </div>
                        <i className="select-filter__icon icon zaia-icono-dropdown-light"></i>
                      </button>
                    }
                    closeOnDocumentClick
                    keepTooltipInside
                    position={["bottom center"]}
                    nested
                  >
                    {
                      close => (
                        <S.LevelSelector>
                          <div className="header">
                            <div className="header__label">{t("globally.program")}</div>
                            <div className="header__label">{t("globally.patient")}</div>
                          </div>
                          <div className="list-programs">
                            <div className={`program-item ${false && "program-item--actived"}`}
                              onClick={() => {
                                close();
                              }}
                            >
                              <div className="program-item__name">{t("globally.all")}</div>
                              <div className="program-item__quantity">
                                <i className="program-item__icon icon zaia-i-patient-mangement"></i>
                                <div className="program-item__number">{61}</div>
                              </div>
                            </div>
                            {
                              [1,2,3,4,5].map((item) => {
                                return (                                
                                  <div className={`program-item ${false && "program-item--actived"}`}
                                    onClick={() => {
                                      close();
                                    }}
                                  >
                                    <div className="program-item__name">{item}</div>
                                    <div className="program-item__quantity">
                                      <i className="program-item__icon icon zaia-i-patient-mangement"></i>
                                      <div className="program-item__number">{item}</div>
                                    </div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </S.LevelSelector>
                      )
                    }
                  </Popup>                   
                </div>
              </div>
              <div className="filter filter--date">
                <div className="filter__label">{"Ordenar por fecha de inscripción"}:</div>
                <div className="filter__space">
                  <button 
                    className={`patient-order ${filterDate !== null && "patient-order--actived"}`}
                    onClick={() => setFilterDate(
                      filterDate === null ? true : (filterDate === false ? null : false)
                    )}
                  >
                    <div className="patient-order__text">
                      {
                        filterDate === false
                        ? t("aiNavigator.filters.oldest")
                        : filterDate === true
                          ? t("aiNavigator.filters.mostRecent")
                          : t("aiNavigator.filters.tapToOrder")
                      }
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className="filters__right">
              <div className="filter filter--program">
                <div className="filter__label">{"Ordenar por rango de tiempo"}</div>
                <div className="filter__space">
                  <Popup
                    trigger={
                      <button className={`select-filter ${filterDate !== null && "select-filter--actived"}`}>
                        <div className="select-filter__text">
                          {
                            // filterProgram ? P.dataTranslation(processTypes?.process.find((item) => item.id === filterProgram)?.processName, language) 
                            true 
                          ? "Nivel"
                          : t("globally.all")}
                        </div>
                        <i className="select-filter__icon icon zaia-icono-dropdown-light"></i>
                      </button>
                    }
                    closeOnDocumentClick
                    keepTooltipInside
                    position={["bottom center"]}
                    nested
                  >
                    {
                      close => (
                        <S.LevelSelector>
                          <div className="list-programs">
                            <div className={`program-item ${false && "program-item--actived"}`}
                              onClick={() => {
                                close();
                              }}
                            >
                              <div className="program-item__name">{t("globally.all")}</div>
                              <div className="program-item__quantity">
                                <i className="program-item__icon icon zaia-i-patient-mangement"></i>
                                <div className="program-item__number">{61}</div>
                              </div>
                            </div>
                            {
                              [1,2,3,4,5].map((item) => {
                                return (                                
                                  <div className={`program-item ${false && "program-item--actived"}`}
                                    onClick={() => {
                                      close();
                                    }}
                                  >
                                    <div className="program-item__name">{item}</div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </S.LevelSelector>
                      )
                    }
                  </Popup>                   
                </div>
              </div>
              <div className="filter filter--program">
                <div className="filter__label">{"Estado de gestión"}</div>
                <div className="filter__space">
                  <Popup
                    trigger={
                      <button className={`select-filter select-filter--management-status ${filterDate !== null && "select-filter--actived"}`}>
                        <div className="select-filter__text">
                          {
                            // filterProgram ? P.dataTranslation(processTypes?.process.find((item) => item.id === filterProgram)?.processName, language) 
                            true 
                          ? "Nivel"
                          : t("globally.all")}
                        </div>
                        <i className="select-filter__icon icon zaia-icono-dropdown-light"></i>
                      </button>
                    }
                    closeOnDocumentClick
                    keepTooltipInside
                    position={["bottom center"]}
                    nested
                  >
                    {
                      close => (
                        <S.LevelSelector>
                          <div className="list-programs">
                            <div className={`program-item ${false && "program-item--actived"}`}
                              onClick={() => {
                                close();
                              }}
                            >
                              <div className="program-item__name">{t("globally.all")}</div>
                              <div className="program-item__quantity">
                                <i className="program-item__icon icon zaia-i-patient-mangement"></i>
                                <div className="program-item__number">{61}</div>
                              </div>
                            </div>
                            {
                              [1,2,3,4,5].map((item) => {
                                return (                                
                                  <div className={`program-item ${false && "program-item--actived"}`}
                                    onClick={() => {
                                      close();
                                    }}
                                  >
                                    <div className="program-item__name">{item}</div>
                                  </div>
                                )
                              })
                            }
                          </div>
                        </S.LevelSelector>
                      )
                    }
                  </Popup>                   
                </div>
              </div>
            </div>
          </div>
          <div className="alerts-container__box">
            {
              <div className="actions-list" style={{"display": selectedSection.id === 1 ? "flex" : "none" }}>
                <AlertsList
                  sections={sections}
                  setSelectedSection={setSelectedSection}
                />
              </div>
            }
            {
              // <div className="dashboard__container" style={{"display": selectedSection.id === 2 ? "flex" : "none" }}>
              //   <div className="dashboard__container-left">
              //     <DashboardFilterPanel
              //       processesFiltered={processesFiltered}
              //       setProcessesFiltered={setProcessesFiltered}
              //       levelReport={levelReport}
              //       setLevelReport={setLevelReport}
              //       filterSelected={filterSelected}
              //       setFilterSelected={setFilterSelected}
              //       severitiesList={severitiesList}
              //       initialDateRange={initialDateRange}
              //       finishDateRange={finishDateRange}
              //       setInitialDateRange={setInitialDateRange}
              //       setFinishDateRange={setFinishDateRange}
              //     />
              //   </div>
              //   <div className="dashboard__container-right">
              //     {
              //       !selectedBox ? null : 
              //       <div className="dashboard__container-right-modal">
              //         <div className="box-container">
              //           <Box
              //             patient={selectedBox.user?.name + " " + selectedBox.user?.lastname}
              //             time={P.hoursIn12format(new Date(selectedBox.orderDate).getHours(), new Date(selectedBox.orderDate).getMinutes())}
              //             timeModified={
              //               selectedBox?.modifiedDate
              //               ? P.hoursIn12format(
              //                 new Date(selectedBox?.modifiedDate).getHours(),
              //                 new Date(selectedBox?.modifiedDate).getMinutes()
              //               ) 
              //               : null
              //             }
              //             date={P.shortDate(new Date(selectedBox.orderDate), t, true, language)}
              //             dateModified={selectedBox?.modifiedDate ? P.shortDate(new Date(selectedBox?.modifiedDate), t, true, language) : null}
              //             description={selectedBox.description}
              //             report={t("dashboard.boxes.vitalSigns.report")}
              //             type={selectedBox.type}
              //             patientPhoto={selectedBox.user?.picture}
              //             levelReport={selectedBox.reportlevel}
              //             completeBox={true}
              //           />
              //           <div className="buttons-box-container">
              //             <div className="buttons-box-patient">
              //               <div className="button-box-patient" onClick={() => setSeePhone(!seePhone)}>
              //                 <ZaiaButton
              //                   type="button"
              //                   option="primary"
              //                   marginTop="20"
              //                   title={t("dashboard.boxes.boxOption.call")}
              //                   icon="zaia-icono-llamar"
              //                   fontFamily="Nunito ExtraBold"
              //                   fontSize="15"
              //                   borderRadius="10"
              //                   iconMargin="10"
              //                   shadow={true}
              //                 />
              //               </div>                    
              //               <Popup
              //                 trigger={
              //                   <div className="button-box-patient">
              //                     <ZaiaButton
              //                       type="button"
              //                       option="primary"
              //                       marginTop="20"
              //                       title={t("dashboard.boxes.boxOption.chat")}
              //                       icon="zaia-icono-chat"
              //                       fontFamily="Nunito ExtraBold"
              //                       fontSize="15"
              //                       borderRadius="10"
              //                       iconMargin="10"
              //                       shadow={true}
              //                     />
              //                   </div>
              //                 }
              //               >
              //                 <TooltipChats patientID={selectedBox?.user.id}/>
              //               </Popup>
              //             </div>
              //             <div className="buttons-box-patient">
              //               <div className="button-box-patient" onClick={() => history?.push(`/ainavigator/${selectedBox?.user.id}?panelActive=false`)}>
              //                 <ZaiaButton
              //                   type="button"
              //                   option="primary"
              //                   marginTop="20"
              //                   title={t("dashboard.boxes.boxOption.seePatient")}
              //                   icon="zaia-icono-pacient"
              //                   fontFamily="Nunito ExtraBold"
              //                   fontSize="15"
              //                   borderRadius="10"
              //                   shadow={true}
              //                   iconMargin="10"
              //                 />
              //               </div>
              //             </div>
              //             <div className="phone-number-container">
              //               {
              //                 seePhone ? 
              //                 <div className="contact-info">
              //                   <div className="contact-info-box contact-info-box--patient">
              //                     <div className="label label--patient">
              //                       {t("dashboard.boxes.boxOption.callPatient")}
              //                     </div>
              //                     <div className="number number--patient">
              //                       {selectedBox.user.phone}
              //                     </div>
              //                   </div>
              //                   {
              //                     selectedBox.user.nameCarer && selectedBox.user.phoneCarer
              //                     ? <div className="contact-info-box contact-info-box--emergency">
              //                       <div className="label label--emergency">
              //                         {t("dashboard.boxes.boxOption.emergencyContact")}
              //                       </div>
              //                       <div className="name">
              //                         {selectedBox.user.nameCarer}
              //                       </div>
              //                       <div className="number number--emergency">
              //                         {selectedBox.user.phoneCarer}
              //                       </div>
              //                     </div>
              //                     : null
              //                   }
              //                 </div>
              //                 : null
              //               }
              //             </div>
              //           </div>
              //         </div> 
              //         <div onClick={() => setSelectedBox(null)}>
              //           <InvitePatientButton activate={true} />
              //         </div>
              //       </div> 
              //     }
              //     <div className="dashboard__container-right-content">
              //       <div className="text-container">
              //         <div className="text-container__title">
              //           {t("dashboard.alertsTitle")}
              //         </div>
              //         <p className="text-container__subtitle">
              //           {t("dashboard.alertsSubtitle1")}
              //           <b>
              //             {t("dashboard.alertsSubtitle2")}
              //           </b>
              //         </p>
              //       </div>
              //       <div className="severity-filtered">
              //         {
              //           (levelReport !== 0 && levelReport !== 4)&&
              //           <>
              //             <p className="severity-filtered__label">
              //               {t("globally.filterBy")}
              //               <b className="severity-filtered__level-bold">{` ${t("globally.severity")} (${ severitiesList.find((severity) => severity.id === levelReport).name })`}</b>
              //             </p>
              //             <div className="severity-filtered__close-button" onClick={() => setLevelReport(0)}>
              //               <i className="severity-filtered__close-button-icon icon zaia-icono-cerrar"/>
              //             </div>
              //           </>
              //         }
              //       </div>
              //       <div className="severity-filtered">
              //         {
              //           (initialDateRange && finishDateRange) &&
              //           <>
              //             <p className="severity-filtered__label">
              //               {t("globally.filterBy")}
              //               <b className="severity-filtered__level-bold">{` ${ getDayOrRange() }`}</b>
              //             </p>
              //             <div className="severity-filtered__close-button" onClick={() => {setInitialDateRange(); setFinishDateRange(null)}}>
              //               <i className="severity-filtered__close-button-icon icon zaia-icono-cerrar"/>
              //             </div>
              //           </>
              //         }
              //       </div>
              //       <div className="dashboard__boxes-container">
              //         <div className="dashboard__boxes-area">
              //           {                  
              //             <ListOfBoxes 
              //               filterSelect={filterSelected} 
              //               processIds={processesFiltered.map(process => process.id)} 
              //               setAllNotificationNumber={setAllNotificationNumber}
              //               setMildNotificationNumber={setMildNotificationNumber}
              //               setMediumNotificationNumber={setMediumNotificationNumber}
              //               setSeriousNotificationNumber={setSeriousNotificationNumber}
              //               setCommentNotificationNumber={setCommentNotificationNumber}
              //               levelReport={levelReport}
              //               initialDate={initialDateRange}
              //               finishDate={finishDateRange || nowDate}
              //               selectedPatient={selectedPatient?.id}
              //               setSelectedBox={setSelectedBox}
              //               subsActive={window.navigator.userAgent.indexOf('Mac') === -1}
              //             />
              //           }
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </div>
              <ListOfBoxes 
                filterSelect={filterSelected} 
                processIds={processesFiltered.map(process => process.id)} 
                setAllNotificationNumber={setAllNotificationNumber}
                setMildNotificationNumber={setMildNotificationNumber}
                setMediumNotificationNumber={setMediumNotificationNumber}
                setSeriousNotificationNumber={setSeriousNotificationNumber}
                setCommentNotificationNumber={setCommentNotificationNumber}
                levelReport={levelReport}
                initialDate={initialDateRange}
                finishDate={finishDateRange || nowDate}
                selectedPatient={selectedPatient?.id}
                setSelectedBox={setSelectedBox}
                subsActive={window.navigator.userAgent.indexOf('Mac') === -1}
              />
            }
          </div>
        </div>
    </S.Alerts>
  );
}

export default Alerts;
