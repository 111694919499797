import React from "react";
import * as S from "./YesOrNo.style";
import { useTranslation } from "react-i18next";

function YesOrNo({
  option,
  selectOption,
  noEditable
}) {
  const {t} = useTranslation("global");

  const yesNoItems = [
    {
      key: 'yes',
      name: t("globally.yes")
    },
    {
      key: 'no',
      name: t("globally.no")
    }
  ]

  return (
    <S.YesOrNo>
      <div className="yesOrNo-states">
        <div className={`yesOrNo-state ${option === yesNoItems[0].name && 'yesOrNo-state--selected'}`} onClick={() => option === yesNoItems[0].name || noEditable? null : selectOption(yesNoItems[0].name)}>{yesNoItems[0].name}</div>
        <div className={`yesOrNo-state ${option === yesNoItems[1].name && 'yesOrNo-state--selected'}`} onClick={() => option === yesNoItems[1].name || noEditable? null : selectOption(yesNoItems[1].name)}>{yesNoItems[1].name}</div>
      </div>
    </S.YesOrNo>
  );
}

export default YesOrNo;
