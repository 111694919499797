import React, { useEffect } from "react";
import * as S from "./UserSelectorPanel.style";
import { useStateValue } from "../../../../contextAPI/StateProvider";
import { Actions } from "../../../../utils/actionsIds";
import { useTranslation } from "react-i18next";

// GraphQL
import { useLazyQuery } from "@apollo/client";
import useRecordNavigation from "../../../../hooks/useRecordNavigation";

import { QUERY_GET_USERS_BY_FILTER } from "../../../../graphql/queries/User";
import ZaiaImage from "../../../zaiaComponents/zaiaImage";
import ZaiaReaction from "../../../zaiaComponents/zaiaReaction/ZaiaReaction";
import useAssignSelectPatient from "../../../../hooks/useAssignSelectPatient";

const eventsCalendarEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function UserSelectorPanel({
  setFilterOptionsActive,
  inputText
}) {
  const [t] = useTranslation("global"); 
  const { selectPatient } = useAssignSelectPatient();
  const { recordAction } = useRecordNavigation();
  const [{ selectedPatient }] = useStateValue();

  const [ getPatients, { 
    data: patientsData,
    loading: loadingPatientsData,
    error: errorPatientsData,
  }] = useLazyQuery(
    QUERY_GET_USERS_BY_FILTER
  );

  useEffect(() => {
    if ( inputText.length > 1 ) 
    getPatients(
      {
        variables: {
          text: `%${inputText}%`,
        }
      }
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputText]);

  return (
    <S.UserSelectorPanel>
      <div className="header">
        <div className="header__title">
          {t("filterPanel.filterByPatient")} 
        </div>
        <div className="header__close" onClick={() => setFilterOptionsActive(null)}>
          <i className="header__close-icon icon zaia-icono-cerrar"/>
        </div>
      </div>
      <div className="users-list">
        
          {
            errorPatientsData && 
            <div className="users-list__responses">
              <ZaiaReaction
                zaiaImg={logoZaiaAlert}
                sizeText="20px"
                textColor="var(--blue-color)"
                widthImg="110px"
              />
            </div>
          }
          {
            loadingPatientsData && 
            <div className="users-list__responses">
              <ZaiaReaction
                zaiaImg={logoZaiaLoading}
                sizeText="20px"
                textColor="var(--blue-color)"
                widthImg="110px"
              />
            </div>
          }
          {
            !errorPatientsData && !loadingPatientsData && patientsData?.user?.length === 0 && 
            <div className="users-list__responses">
              <ZaiaReaction
                zaiaImg={eventsCalendarEmpty}
                text={t("globally.noResults")}
                subText={t("globally.noMatchingPatients")}
                sizeText="33.25px"
                sizeSubText="15px"
                textColor="var(--blue-color)"
                widthImg="210px"
              />
            </div>
          }
        {
          !errorPatientsData && !loadingPatientsData && patientsData?.user?.length !== 0 &&
          patientsData?.user.map((patient) => (
            <div className={`user-item ${selectedPatient?.id === patient?.id && 'user-item--selected'}`}  onClick={() => 
              {
                if(selectedPatient?.id !== patient?.id) {
                  // recordAction(Actions.SELECT_PATIENT, patient.id);
                  selectPatient(patient.id)
                }
              }
            }>
              <ZaiaImage
                size={28}
                img={patient.picture}
                rounded={true}
              />
              <div className="user-item__info">
                <div className="user-item__name">{`${patient.name} ${patient.lastname}`}</div>
                <div className="user-item__email">{`${patient.email}`}</div>
              </div>
            </div>
          ))
        }
      </div>
    </S.UserSelectorPanel>
  )
}

export default UserSelectorPanel;
