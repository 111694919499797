import styled from "styled-components";

export const GeneralTemplate = styled.div`
  border-radius: 10px;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  box-sizing: border-box;
  .template-header {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    &-item-icon {
      color: #36369B;
      margin-left: 5px;
      font-size: 13px;
    }
    &-title{
      font-family: "Nunito Sans SemiBold";
      /* color: var(--blue-dark-color); */
      color: #36369B;
      font-size: 18px;
    }
  }
  .template-content {
    /* overflow: auto; */
    .title {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      font-family: "Nunito Sans";
      font-size: 17px;
    }
  }
  .template-content-item {
    margin: 20px 0 50px;
    .extra-data {
      font-size: 11px;
      color: var(--blue-color);
      font-weight: 900;
      text-align: right;
      margin-top: 5px;
    }
  }
`;

export const PopUpPayload = styled.div`
  background: ${(theme) => theme.primaryCardComment};
  background: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Open Sans';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`