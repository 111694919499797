import React from "react";
import * as S from "./ZaiaToggleCircular.style";

function ZaiaToggleCircular({ id, active, color, toggle }) {

  return (
    <S.ZaiaToggleCircular color={color} active={active} >
      <div className="toggle-back" id={id} onClick={() => toggle(!active)}>
        <div className="circle-toggle"></div>
      </div>
    </S.ZaiaToggleCircular>
  )
}

export default ZaiaToggleCircular;