import { gql } from "@apollo/client";

export const QUERY_GET_MESSAGES = gql`
  query QUERY_GET_MESSAGES(
    $groupChatId: uuid!
    $offset: Int
  ) {
    message(where: {toChatId: {_eq: $groupChatId}}, limit: 20, offset: $offset, order_by: {createdAt: desc}) {
      id
      createdAt
      message
      fromDoctorId
      doctor {
        id
        name
        lastname
        gender
      }
      fromUserId
      user {
        id
        name
        lastname
        gender
      }
    }
  }
`

export const QUERY_GET_USERS_CHAT_GROUP = gql`
  query QUERY_GET_USERS_CHAT_GROUP(
    $groupChatId: uuid!
  ) {
    chatGroup(where: {id: {_eq: $groupChatId}}) {
      id
      ownerUserId
      ownerUser {
        id
        picture
        name
        lastname
      }
      ownerDoctorId
      ownerDoctor {
        id
        picture
        name
        lastname
      }
      personToChats {
        id
        chatGroupId
        doctorId
        doctor {
          name
          lastname
          picture
        }
        userId
        user {
          id
          name
          lastname
          picture
        }
      }
    }
  }
`

export const QUERY_SHORT_GET_GROUP_CHATS = gql`
  query QUERY_SHORT_GET_GROUP_CHATS(
    $participantID: uuid!
  ) {
    chatGroup(where: {personToChats: {_or: [{userId: {_eq: $participantID}}, {doctorId: {_eq: $participantID}}]}}) {
      id
      imagen
      nameChat
      userToProcess {
        processId
        process {
          processName
        }
      }
    }
  }
`

export const QUERY_GET_GROUP_CHAT_BY_ID = gql`
  query QUERY_GET_GROUP_CHAT_BY_ID(
    $chatId: uuid 
  ) {
    chatGroup(where: {id: {_eq: $chatId}}) {
      id
      imagen
      nameChat
    }
  }
`

export const QUERY_GET_GROUP_CHATS = gql`
  query QUERY_GET_GROUP_CHATS {
    chatGroup {
      id
      imagen
      nameChat
      ownerUserId
      ownerUser {
        id
        picture
        name
        lastname
      }
      ownerDoctorId
      ownerDoctor {
        id
        picture
        name
        lastname
      }
      personToChats {
        id
        chatGroupId
        doctorId
        doctor {
          name
          lastname
          picture
        }
        userId
        user {
          id
          name
          lastname
          picture
        }
      }
      userToProcess {
        processId
        process {
          id
          processName
        }
        isAcepted
      }
      messages(limit: 1, order_by: {createdAt: desc}) {
        id
        createdAt
        message
        fromDoctorId
        doctor {
          id
          name
          lastname
          gender
        }
        fromUserId
        user {
          id
          name
          lastname
          gender
        }
      }
    }
  }
`

export const QUERY_GET_GROUP_CHATS_BY_USER = gql`
  query QUERY_GET_GROUP_CHATS_BY_USER (
    $userId: uuid
  ) {
    chatGroup(where: {_or: [{personToChats: {userId: {_eq: $userId}}},{ownerUserId: {_eq: $userId}}]}) {
      id
      imagen
      nameChat
      ownerUserId
      ownerUser {
        id
        picture
        name
        lastname
      }
      ownerDoctorId
      ownerDoctor {
        id
        picture
        name
        lastname
      }
      personToChats {
        id
        chatGroupId
        doctorId
        doctor {
          name
          lastname
          picture
        }
        userId
        user {
          id
          name
          lastname
          picture
        }
      }
      userToProcess {
        processId
        process {
          id
          processName
        }
      }
      messages(limit: 1, order_by: {createdAt: desc}) {
        id
        createdAt
        message
        fromDoctorId
        doctor {
          id
          name
          lastname
          gender
        }
        fromUserId
        user {
          id
          name
          lastname
          gender
        }
      }
    }
  }
`