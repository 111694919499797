import styled from "styled-components";

export const Police = styled.div`
  height: 100%;
  .patient-selector-view {
    height: 100%;
  }
  .patient-dashboard-view {
    height: 100%;
  }
`;

