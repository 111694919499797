import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./NotesMaxView.style";
import NoteComplete from "../noteComplete";
import ListNotes from "../listNotes/ListNotes";

const feather = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/feather.svg`;

function NotesMaxView({
  listNotes,
  maxViewActived,
  createNoteActived,
  setCreateNoteActived,
  setNoteSelected,
  noteSelected,
  setEditNoteActived,
  patientSelected
}) {

  const { t } = useTranslation("global"); 

  return (
    <S.NotesMaxView>
      <div className="notes-view">
        <div className="notes-view__left">
          {
            noteSelected &&
            <NoteComplete 
              maxView={true}
              noteSelected={noteSelected}
              setNoteSelected={setNoteSelected}
              setEditNoteActived={setEditNoteActived}
            />
          }
          {
            !noteSelected &&
            <div className="notes-view__left-empty">
              <img src={feather} alt="" className="empty-img" />
              <div className="empty-text">
                {t("notes.selectNoteToView")}
              </div>
            </div>
          }
        </div>
        <div className="notes-view__right">
          <div className="notes-view__right-content">
            <ListNotes
              listNotes={listNotes}
              maxViewActived={maxViewActived}
              setCreateNoteActived={setCreateNoteActived}
              createNoteActived={createNoteActived}
              noteSelected={noteSelected}
              setNoteSelected={setNoteSelected}
              setEditNoteActived={setEditNoteActived}
              patientSelected={patientSelected}
            />
          </div>
        </div>
      </div>
    </S.NotesMaxView>
  );
}

export default NotesMaxView;
