import styled from "styled-components";

export const MedicationSearcher = styled.div`
  height: 100%;
  position: relative;
  .input-container {
    margin: 20px 0px;
  }
  .medication-list {
    height: ${({searcher}) => searcher === "" ? "calc(100% - 80px)" : "calc(100% - 120px)"} ;
    &__results {
      color: #C0BFDC;
      font-family: "Nunito Sans";
      font-size: 16px;
      font-style: italic;
      margin: 20px 0px;
      &--hidden {
        /* visibility: hidden; */
        display: none;
      }
    }
    &__container {
      padding: 0 10px;
      overflow: auto;
      height: 100%;
    }
    .medication-name {
      padding: 5px 10px;
      color: #818298;
      font-family: "Nunito Sans";
      font-size: 14px;
      margin-bottom: 20px;
      cursor: pointer;
      transition: 0.3s ease;
      &--selected {
        color: #3E3DA3;
        font-family: "Nunito Sans Bold";
        font-size: 24px;
      }
    }
  }
`