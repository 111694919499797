import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalOutcomesEventHistory = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: var(--white);
    width: 1280px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    &--response {
      padding: 40px;
      width: auto;
    }
    &--loading {
      padding: 60px;
      width: auto;
      min-width: 550px;
    }
    .header {
      padding: 20px 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #E0E6ED;
      &__title {
        color: #3E3E5B;
        font-family: "Nunito Sans Black";
        font-size: 30px;
      }
      &__note {
        color: #38379C;
        font-family: "Nunito Sans";
        font-size: 15px;
      }
      &__editing {
        font-family: Nunito Sans;
        font-size: 20px;
        color: #3E3E5B;
      }
      &__close {
        width: 28px;
        height: 28px;
        background-color: #36369B;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        cursor: pointer;
        color: #fff;
      }
    }
    .footer {
      justify-content: flex-end;
      display: flex;
      padding: 13px 40px;
      border-top: 1px solid #E0E6ED;
      &__button {
        display: flex;
        font-family: Nunito Sans;
        font-size: 15px;
        background-color: #6C6BCC;
        color: #F7F6FC;
        padding: 5px 20px;
        border-radius: 60px;
        outline: none;
        border: none;
        align-items: center;
        width: 206px;
        justify-content: center;
        cursor: pointer;
        &--save {
          background-color: #27AE60;
          width: auto;
          margin-left: 10px;
        }
        &--cancel {
          background-color: #3E3DA3;
          width: auto;
        }
        &-text {
          margin-right: 5px;
        }
        &-icon {
          font-size: 10px;
        }
      }
    }
  }
`;

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Open Sans';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`