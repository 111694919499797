import styled from 'styled-components';
import { getHexOpacity } from "../../../../../utils/pipes";

export const PatientSelector = styled.div`
  height: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  background: linear-gradient(0deg,rgba(247,247,255,1) 0%,rgba(255,255,255,1) 130%,rgba(255,255,255,1) 100%);
  position: relative;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .navigator-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 30px 43px 0px;
    &__icon {
      color: #6D6F8B;
      opacity: 60%;
      font-size: 39px;
      margin-right: 15px;
    }
    &__text {
      margin-top: 10px;
      color: #6D6F8B;
      font-size: 28px;
      font-family: "Nunito Sans ExtraBold";
    }
    &__text-description {
      color: #707582;
      margin-top: 10px;
      font-size: 16px;
      font-family: "Nunito Sans";
    }
  }
  .no-patients-container {
    display: flex;
    flex-direction: column;
    height: calc(100% - 49px);
    .no-patients {
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin: auto;
      &__button {
        padding: 10px 0;
        font-family: Nunito Sans;
        font-size: 18px;
        background-color: #36369B;
        color: white;
        border: none;
        outline: none;

        border-radius: 14px;
        margin-top: 20px;
        cursor: pointer ;
      }
    }
  }
  .searcher-container{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100px;
    .searcher-content {
      position: absolute;
      width: 90%;
      padding: 20px;
      box-shadow: 0px 0px 29px 3px #23229D1A;
      border-radius: 10px;
      background-color: #FCFCFE;
      margin: 30px 19px;
      /* width: 1089px; */
      box-sizing: border-box;
      cursor: text;
      z-index: 100;
    }
    .searcher-input {
      display: flex;
      justify-content: space-between;
      &__input {
        margin: 0 17px;
        border: none;
        outline: none;
        width: 100%;
        background-color: transparent;
        font-family: "Nunito Sans";	
        font-size: 19px;
        color: #36369B;
        ::placeholder {
          color: #36369B;
        }
      }
      &__icon {
        color: #36369B;
        font-size: 28px;
      }
    }
    .searcher-options {
      margin-top: 27px;
      padding: 17px 17px 0 17px; 
      border-top: 0.5px solid ${"#36369B" + getHexOpacity(0.23)};
      cursor: default;
      .last-search {
        display: flex;
        justify-content: space-between;
        margin-bottom: 17px;
        &__text {
          color: #68688D;
          font-size: 17px;
        }
        &__clear {
          font-size: 17px;
          color: #36369B;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .options-list {
        overflow: auto;
        max-height: 299px;
        ::-webkit-scrollbar {
          display: none;
        }
        .patient-option {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          margin-bottom: 20px;
          border-radius: 14px;
          cursor: pointer;
          transition: 0.5s;
          &__left {
            display: flex;
            align-items: center;
          }
          &__right {
            /* visibility: hidden; */
          }
          &__info {
            margin-left: 4px;
            align-self: center;
            &-name {
              margin-left: 5px;
              color: #36369B;
              font-size: 20px;
              font-family: "Nunito Sans";
            }
            &-program {
              font-family: "Nunito Sans";
              padding: 5px;
              color: #526479;
              font-size: 15px;
              border-radius: 4px;
              display: flex;
              width: fit-content;
              font-style: italic;
              strong {
                margin-left: 5px;
              }
            }
          }
          &__quick-actions {
            display: flex;
            .quick-action {
              margin-left: 10px;
              background-color: #F3F3FC;
              border-radius: 10.75px;
              width: 61.46px;
              height: 33px;
              display: flex;
              &__icon {
                color: #3E3DA3;
                font-size: 16.97px;
                margin: auto;
              }
              .tooltip {
                z-index: 200;
                position: absolute;
                margin-top: 40px;
                display: none;
              }
            }
          }
          &__flag {
            margin-top: 10px;
            /* display: flex; */
            display: none;
            justify-content: end;
            &-icon {
              color: #FF4A4A;
              font-size: 12px;
              
            }
          }
          :hover {
            padding: 24px 10px;
            background-color: #EEF1F4;
            .quick-action {
              background-color: #FFFFFF;
            }
          }
        }
      }
    }
  }
  .suggestion-test {
    width: 1089px;
    margin: 0 auto 10px;
    padding-left: 37px;
    color: #8686A9;
    font-size: 19px;
  }
  .suggestion-list {
    position: relative;
    display: flex;
    width: 100%;
    height: ${({heightTitle}) => `calc(100% - ${heightTitle}px - 100px)`};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    .search-figure {
      
    }
    .search-label {
      width: 100%;
      font-family: Nunito Sans;
      font-size: 20px;
      color: #707582;
      text-align: center;
      width: 90%;
    }
    .button-container {
      justify-content: center;
      width: 100%;
      .button-add-patient {
        width: 90%;
        justify-content: center;
        display: flex;
        align-items: center;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 17px 10px 17px 10px;
        font-size: 20px;
        font-family: "Nunito Sans";
        background-color: #3E3DA3;
        color: #FFFFFF;
        border-radius: 12px;
        margin: 20px auto;
        box-shadow: 0px 5px 19px 0px #23229D5E;
        &__icon {
          margin-right: 10px;
          font-size: 21px;
          color: #FFFFFF;
          opacity: 0.6;
        }
      }
    }
  }
`

export const ProcessNameContainer = styled.div` 
  background-color: ${({theme}) => theme.background};
  padding: 5px;
  border-radius: 7px;
  cursor: default;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
`

export const ProcessName = styled.div` 
  font-size: 17px;
  font-family: "Nunito Sans Light";
  border-bottom: ${({last, theme}) => !last ? '1px solid ' + theme.lineSeparation : null};
  /* margin: 5px 0px; */
  padding: 2px 5px; 
  .card-selected-patient-process {
    color: #526479 !important;
  }
`
