import styled from "styled-components";

export const CmpDateSelection = styled.div`
  /* background-color: var(--white);
  border-radius: 10px;
  padding: 13px 10px 10px 10px; */
  h5 {
    font-family: "Nunito Sans Bold";
  }
  .selection-container {
    margin-top: 10px;
    /* display: flex; */
    align-items: center;
    &__item {
      align-items: center;
      display: flex;
      width: 100%;
    }
    i {
      margin-left: 5px;
    }
  }
  i {
    margin-right: 5px;
  }
  .progression-free-survival-form-input {
    margin-bottom: 0px;
  }
  .input-container {
    width: min-content;
    input {
      text-transform: uppercase;
    }
  }
`;

// export const SelectorFormDiv = styled.div`
//   background-color: var(--white);
//   border-radius: 10px;
//   box-shadow: ${({boxShadow}) => boxShadow ? "0px 3px 6px var(--box-shadow)" : ""};
//   padding: ${({boxShadow}) => boxShadow ? "10px 15px": ""};
//   .date-selector {
//     &-title {
//       color: var(--blue-dark-color);
//       font-size: 10px;
//       font-family: "Nunito ExtraBold";
//       margin-bottom: 5px
//     }
//   }
// `;


// export const SelectorForm = styled.div`
//   background-color: var(--white);
//   z-index: 100;
//   width: 100%;
//   /* padding: 5px 0 10px; */
//   .progression-free-survival-form {
//     &-date {
//       margin: 10px 0 10px 0;
//       display: flex;
//       &-icon {
//         color: var(--blue-color);
//         margin-top: 2px
//       }
//       &-text {
//         font-family: "Open Sans";
//         margin-left: 10px
//       }
//     }
//     &-buttons {
//       display: flex;
//       justify-content: start;
//     }
//     &-button {
//       width: 100px;
//     }
//   }
// `
