import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ChangePassDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;  
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 200;
    background-color: var(--white);
    width: 450px;
    margin: auto;
    box-sizing: border-box;
    max-height: 80%;
    overflow-y: auto;
    border-radius: 14px;
    padding: 40px;
    /* padding: 20px 0px; */
    form {
      box-sizing: border-box;
      width: 100%;
      /* height: 415px; */
      margin: auto;
      .title {
        display: flex;
        align-items: center;
        color: #8C8DA4;
        font-family: "Nunito Sans";
        font-size: 25.044px;
        margin-bottom: 40px;
        &__icon {
          font-size: 18.266px;
        }
      }
      .card-inputs {
        background-color: var(--white);
        border-radius: 30px;
        .label {
          display: flex;
          align-items: center;
          font-family: "Nunito Sans Bold";
          font-size: 17px;
          margin: 20px 0 0 20px;
          color: #3E3DA3;
          font-family: "Nunito Sans";
          font-size: 20px;
          &--error {
            color: #FC0808;
          }
          &__icon {
            font-size: 11px;
            margin-left: 10px;
            &--error {
              visibility: hidden;
            }
          }
        }
        .error-same-password {
          color: #8A939D;
          font-family: "Nunito Sans";
          font-size: 16px;
          margin-left: 20px;
        }
        .input-container {
          margin-top: 20px;
        }
        .invitePatientsModal__msgContainer {
          background-color: var(--background-header-input);
          padding: 5px 5px 60px 5px;
          border-radius: 10px;
          margin-top: 10px;
          max-width: 310px;
          p {
            line-height: 1.2;
          }
        }
      }
      .conditions {
        margin-top: 30px;
        .condition {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          color: #3E3DA3;
          font-family: "Nunito Sans";
          font-size: 15px;
          &--error {
            color: #FC0808;
          }
          &__icon {
            font-size: 8px;
            padding: 5px;
            margin-right: 10px;
            opacity: 0.4;
            &--error {
              visibility: hidden;
            }
          }
        }
      }
      .card-buttons {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .cancel-button {
          display: flex;
          width: 48%;
          height: 61px;
          justify-content: center;
          align-items: center;
          border-radius: 10px;
          border: 1px solid #3E3DA3;
          color: #3E3DA3;
          cursor: pointer;
          font-family: Nunito;
          font-size: 20px;
          box-shadow: 0px 7px 7px 0px rgba(35, 34, 157, 0.00);
        }
        .submit-button {
          display: flex;
          outline: none;
          border: none;
          width: 48%;
          height: 61px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 10px;
          font-family: Nunito;
          font-size: 20px;
          box-shadow: 0px 7px 7px 0px rgba(35, 34, 157, 0.00);
          background-color: #3E3DA3;
          color: #FFF;
          cursor: pointer;
          &--disabled {
            cursor: default;
            background-color: #E0E6ED;
            color: #8A939D;
          }
        }
      }
    }
  }
`;
