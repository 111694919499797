import styled from "styled-components";

export const MedicineSettingsChat = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  height: 100%;
  width: 100%;
  .settings__header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    box-sizing: border-box;
    &-icon {
      font-size: 15px;
      margin-bottom: -2px;
      margin-right: 15px;
      cursor: pointer;
      position: absolute;
    }
    .settings__header-title {
      display: flex;
      align-items: center;
      margin: auto;     
      .settings__header-title-icon {
        font-family: "Open Sans";
        font-size: 17px;
        color: var(--white) !important;
        display: flex;
        justify-content: center;
        background-color: ${({theme}) => theme.primarySectionMedicines};
        border-radius: 10px;
        padding: 8px;
        margin: 10px 8px 10px 0px;
        i {
          display: flex;
        }
      }
      h4 {
        font-family: "Nunito ExtraBold";
        color: ${({theme}) => theme.primarySectionMedicines};
        font-size: 17px;
      }
    }
  }  
  .settings__container {
    height: calc(100% - 60px);
    overflow: auto;
    .image-container {
      display: flex;
      padding: 20px 0 40px;
      justify-content: center;
      .image-change {
        position: relative;
        .button-change-image {
          position: absolute;
          border-radius: 50%;
          background-color: var(--blue-dark-color);
          font-size: 15px;
          width: 40px;
          height: 40px;
          box-shadow: 0px 3px 10px #1f1f5559;
          bottom: -17px;
          right: -5px;
          color: var(--background-header-input);
          display: flex;
          cursor: pointer;
          font-size: 20px;
          i {
            margin: auto 9px 3px auto;
          }
        }
      }
    }
    .bottom-container {
      padding: 0 20px;
      height: 100%;
      .medicine-recipe-form {
        height: 100%;
        &__inputs {
          height: calc(100% - 65px);
          overflow: auto;
        }
      }
      .input-content {
        font-size: 13px;
        font-family: 'Open Sans';
        padding: 12px 0;
        /* color: var(--blue-color); */
        span {
          padding: 6px 0;
          display: block;
        }
        &__label-bold {
          font-family: "Nunito ExtraBold";
          font-size: 17px;
        }
        &__double-content {
          display: flex;
          align-items: center;
        }
      }
      .participats-info {
        display: flex;
        color: var(--blue-color);
        justify-content: space-between;
        font-family: "Nunito SemiBold";
        font-size: 15px;
        i {
          cursor: pointer;
        }
      }
    }
  }
  .error-message {
    height: 250px;
    display: flex;
    flex-direction: column;
    margin: auto;
    padding-bottom: 40px;
  }
`;
