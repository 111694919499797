import React from "react";
import * as S from "./SurveyFrecuency.style";
import { useTranslation } from "react-i18next";

import ZaiaSelect from "../../../../../../zaiaComponents/zaiaSelect";
import ZaiaInput from "../../../../../../zaiaComponents/zaiaInput";
import ZaiaToggleCircular from "../../../../../../zaiaComponents/zaiaToggleCircular";

function SurveyFrecuency({
  selectedWithFrequency,
  selectedInterval,
  selectedReminderHour,
  changeFormField,
  frequenciesList,
  goNextInput,
}) {

  const { t } = useTranslation("global");

  function getLocalDate() {
    const today = new Date();
    today.setMinutes(today.getMinutes() - today.getTimezoneOffset());
    return today.toISOString().split('T')[0];
  }

  return (
    <S.SurveyFrecuency>
      <div className="title">{t("globally.frequency")}</div>
      <div className="doseage-inputs">
        <div className="input-toogle">
          <div className="input-toogle__text">{t("surveys.sendSurveyModal.withoutFrequency")}</div>
          <div className="input-toogle__toogle">
            <ZaiaToggleCircular
              id={'patient-alert-toggle'}
              color={"#27AE60"}
              toggle={(e) => {
                changeFormField(
                  [
                    {
                      value: !e, 
                      text: "", 
                      fieldName: "withFrequency"
                    },
                    {
                      value: null, 
                      text: "", 
                      fieldName: "interval"
                    },
                    {
                      value: selectedWithFrequency.value ? "" : null, 
                      text: "", 
                      fieldName: "rangeStart"
                    },
                    {
                      value: selectedWithFrequency.value ? "" : null,  
                      text: "", 
                      fieldName: "rangeFinish"
                    }
                  ]
                )
              }}
              active={!selectedWithFrequency.value}
            />
          </div>
        </div>
        {
          selectedWithFrequency.value &&
          <div className="input-space">
            <div className="input-label">
              {t("globally.chooseInterval")}
            </div>
            <div className="input-space__number-icon">
            </div>
            <ZaiaSelect
              placeholder={t("globally.chooseInterval")}
              textColor="#6C6BCC"
              placeholderTextColor="#6C6BCC"
              fontSize={"15px"}
              // height={"33px"}
              errorOption={false}
              maxHeightList={"200px"}
              padding={"20px 27px"}
              borderColor={"#6C6BCC"}
              borderRadius={"14px"}
              heightList={"200px"}
              value={selectedInterval.value}
              setValue={(value) => 
                changeFormField(
                  [
                    {
                      value: value, 
                      text: frequenciesList.find((frequency) => frequency.value === value).text + ", ", 
                      fieldName: "interval"
                    }
                  ]
                )
              }
              items={frequenciesList}
            />
            <div className="input-msg">{t("globally.recieveNotificationEveryDay")}</div>
          </div>
        }
        {
          !selectedWithFrequency.value &&
          <div className="input-space input-space--dose">
            <div className="input-label">
              {t("surveys.sendSurveyModal.chooseSendDate")}
            </div>
            <ZaiaInput 
              type={"date"}
              name="data"
              errorOption={false}
              min={getLocalDate()}
              getText={(e) => {
                if(e) {
                  if(new Date(e) < new Date(getLocalDate())) {
                    changeFormField(
                      [
                        {
                          value: getLocalDate(), 
                          text: getLocalDate() + " ", 
                          fieldName: "interval"
                        }
                      ]
                    )
                  } else {
                    changeFormField(
                      [
                        {
                          value: e, 
                          text: e + " ", 
                          fieldName: "interval"
                        }
                      ]
                    )
                  }
                } else {
                  changeFormField(
                    [
                      {
                        value: null, 
                        text: "", 
                        fieldName: "interval"
                      }
                    ]
                  )
                }
              }}
              value={selectedInterval.value ?? ""}
              padding={"20px"}
              borderRadius={"14px"}
              textColor={"#6C6BCC"}
              borderColor={"transparent"}
              placeholderTextColor={"#878792"}
              fontSize={"15px"}
              />
          </div>
        }
        <div className="input-space">
          <div className="input-label">
            {t("surveys.sendSurveyModal.reminderHour")}
          </div>
          <div className="input-space__number-icon">
          </div>
          {/* <ZaiaSelect
            placeholder={t("surveys.sendSurveyModal.reminderHour")}
            textColor="#6C6BCC"
            placeholderTextColor="#6C6BCC"
            fontSize={"15px"}
            height={"33px"}
            errorOption={false}
            maxHeightList={"200px"}
            padding={"20px 27px"}
            borderColor={"#6C6BCC"}
            borderRadius={"14px"}
            heightList={"200px"}
            value={selectedReminderHour.value}
            setValue={(value) => {
              changeFormField(
                [
                  {
                    value: value, 
                    text: frequenciesList.find((frequency) => frequency.value === value).text, 
                    fieldName: "reminderHour"
                  }
                ]
              )
              goNextInput()
            }
            }
            items={frequenciesList}
          /> */}
          <ZaiaInput
            placeholder={''}
            type="time"
            activeBorder={false}
            textColor="#6C6BCC"
            placeholderTextColor="#6C6BCC"
            borderColor={"#6C6BCC"}
            fontSize={"17px"}
            height={"33px"}
            errorOption={false}
            borderRadius={"14px"}
            padding={"20px 27px"}
            getText={(value) => {
              changeFormField(
                [
                  {
                    value: value, 
                    text: value, 
                    fieldName: "reminderHour"
                  }
                ]
              )
              goNextInput()
            }
            }
            value={selectedReminderHour.value ?? ""}
            // disabled={event && editionEvent ? true : false}
          />
        </div>
      </div>
    </S.SurveyFrecuency>
  );
}

export default SurveyFrecuency;
