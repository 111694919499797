import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";
const bgFlorence = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/background-florence.svg`;

export const ModalUserAiNavigator = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: #F0EFF9;
    max-width: 1038px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    justify-content: end;
    display: flex;
    flex-direction: column;
    position: relative;
    &__reaction {
      width: 1038px;
      margin: 200px 0;
    }
    .header{
      position: absolute;
      top: 20px;
      left: 25px;
      z-index: 100;
      .close {
        width: 36px;
        height: 36px;
        background-color: #FCFCFE;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        cursor: pointer;
        color: #36369B;
        box-shadow: 0px 0px 17.9px 0px #DADAF6;
      }
    }

    .content {    
      display: flex;
      flex-direction: column;
      padding: 30px 35px 30px 35px;
      width: 100%;
      box-sizing: border-box;
      position: relative;
      .patient-card {
        margin-bottom: 20px;
        padding: 20px 26px;
        border-radius: 14px;
        background: #FFF;
        width: 963.65px;
        box-sizing: border-box;
        display: flex;
        &__top {
          display: flex;
          justify-content: space-between;
        }
        &__info {
          align-items: center;
          display: flex;
          .patient-image {
            padding: 6.75px;
            backdrop-filter: blur(8.288250923156738px);
            overflow: hidden;
            box-shadow: 0px 0px 12px 0px rgba(35, 34, 157, 0.20);
            border-radius: 50%;
            height: fit-content;
            margin-right: 20px;
          }
          .patient-info {
            &__name {
              color: #1F1F52;
              font-family: "Nunito Sans Bold";
              font-size: 35.368px;
              margin-bottom: 5px;
            }
            &_
            &__email {
              color: #1F1F52;
              font-family: "Nunito Sans";
              font-size: 17px;
              margin-bottom: 10px;
            }
          }
        }
        &__left {
          width: 80%;
        }
        &__right {
          width: 20%;
          .numbers {
            display: flex;
            padding: 10px 40px 10px 20px;
            flex-direction: column;
            border-radius: 10px;
            background: #F0EFF9;
            box-sizing: border-box;
            &__title {
                display: flex;
                &-text {
                color: #1F1F52;
                font-family: "Nunito Sans Bold";
                font-size: 14px;
                margin-right: 10px;
                white-space: nowrap;
              }
              &-icon {
                font-size: 15px;
              }
            }
            .number {
              margin-top: 10px;
              &__title {
                font-family: Nunito Sans;
                font-size: 12px;
                font-weight: 300;
                color: #4A4A75; 
              }
              &__phone {
                font-family: Nunito Sans;
                font-size: 14px;
                font-weight: 300;
                color: #1F1F52;
              }
            }
          }
          .button-email {
            display: flex;
            width: 244px;
            margin-top: 10px;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            background: #6C6BCC;
            color: #FFF;
            text-align: center;
            box-shadow: 0px 0px 10px -3.111px rgba(35, 34, 157, 0.25);
            border: none;
            outline: none;
            cursor: pointer;
            &__text {
                font-family: "Nunito Sans Light";
                font-size: 14px;
            }
          }
        }
        .patient-info {
          &__program {
            /* font-family: "Nunito Sans";
            padding: 0px 5px;
            color: #526479;
            font-size: 17px;
            background-color: #EEF1F4;
            width: fit-content;
            border-radius: 4px;
            display: flex;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 1.2em;
            white-space: nowrap;
            margin-bottom: 10px; */
            margin: 10px 0px 19px;
            display: flex;
            overflow: auto;
            .program-box {
              font-size: 14px;
              margin-right: 5px;
              padding: 0px 10px;
              border-radius: 10px;
              background-color: #F0F0FF;
              color: #3E3E5B;
              &__name {
                white-space: nowrap;
              }
              &__status {
                white-space: nowrap;
              }
            }
          }
          &__shortcuts {
            display: flex;
            .shortcut {
              display: flex;
              cursor: pointer;
              padding: 4px 10px;
              border-radius: 28px;
              background: #EFEEF8;
              margin-right: 12px;
              color: #36369B;
              text-align: center;
              text-shadow: 0px 0px 11.4px rgba(255, 255, 255, 0.73);
              font-family: "Nunito Sans";
              font-size: 16px;
              &__icon {
                font-size: 22px;
                margin-left: 10px;
                color: #6C6BC6;
              } 
            }
          }
        }
      }  
      .user-ainavigator-boxes {
        height: calc(100% - 177px);
        position: relative;
        overflow: auto;
      }
    }
  }
`;