import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const NoteForm = styled.div`
  height: 100%;
  position: relative;
  .select-program {
    display: flex;
    flex-direction: ${({ maxViewActived }) => (maxViewActived ? "column" : "")};
    align-items: center;
    padding: 5px 20px 0px; 
    align-items: ${({ maxViewActived }) => (maxViewActived ? "start" : "")};
    &__label {
      margin-bottom: ${({ maxViewActived }) => (maxViewActived ? "10px" : "")};
      font-family: Nunito;
      font-size: 17px;
      margin-right: 9px;
      white-space: nowrap;
    }
    &__select {
      width: 100%;
    }
  }
  .current-program {
    display: flex;
    padding: 17px 20px 0px; 
    &__name {
      font-family: Nunito Sans Bold;
      font-size: 17px;
      margin-left: 5px;
    }
  }
  .note-text {
    height: ${({ maxViewActived }) => (maxViewActived ? "calc(100% - 161px)" : "calc(100% - 128px)")};    
    overflow: auto;
    padding: 32px 20px 0px;
    box-sizing: border-box;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__img {
      width: 400px;
    }
    &__text {
      color: #7776BE;
      font-family: Nunito Sans;
      font-size: 16px;
      top: -70px;
      position: relative;
    }
  }
  .buttons {
    padding: 16px 20px 26px;
    box-shadow: ${({ maxViewActived }) => (maxViewActived ? "" : "0px -1px 6.4px 0px #23229D40")};    
    display: flex;
    z-index: 200;
    position: relative;
    .button {
      height: 46px;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 23px;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: #36369B;
      font-family: Nunito;
      font-size: 15.69px;
      color: white;
      &--save {
        width: 70%;
        margin-right: 6px;
        background-color: #27AE60;
        color: #FFFFFF;
      }
      &--cancel {
        width: 30%;
        background-color: #FFFFFF;
        color: #36369B;
        border: 1px solid #36369B;
      }
      &--disabled {
        opacity: 0.5;
      }
    }
  }
`

export const ModalResponse = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 300;
    background-color: var(--white);
    width: 900px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 50px 0;
  }
`
