import styled from 'styled-components';

export const PeriodOfTimeSelect = styled.div`
  .label {
    display: flex;
    align-items: center;
    .icon {
      font-size: 18px;
      margin-right: 10px;
      color: #7B7C99;
    }
    .text {
      font-family: Nunito;
      font-size: 17px;
      color: #7B7C99;
    }
    .icon-info {
      margin-left: 10px;
      font-size: 13px;
      color: #38379C;
    }
  }
  .input-container {
    margin-top: 10px;
    .input {
      width: 356px;
      padding: 5px 20px;
      border-radius: 40px;
      align-items: center;
      /* box-sizing: border-box; */
      background-color: #F1F1F6;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      &__text {
        font-family: Nunito;
        font-size: 17px;
        color: #7D7C8B;
      }
      &__icon {
        color: #6C6BCC;
        font-size: 12px;
      }
    }
  }
`

export const PopUpitems = styled.div`
  background-color: white;
  width: 356px;
  padding: 20px 15px 20px 15px;
  border-radius: 15px;
  border: 1px solid #AAAADB;
  .item {
    width: 100%;
    padding: 8.87px 10px 8.87px 20px;
    border-radius: 60px;
    color: #707582;
    box-sizing: border-box;
    margin-bottom: 16px;
    cursor: pointer;
    &:hover {
      background: #EFEFFF;
      color: #36369B;
    }
    &--selected {
      color: #36369B;
      background: #EFEFFF;
    }
    &--custom {
      margin-bottom: 0px;
    }
  }
  .item-separator {
    border-bottom: 0.5px solid #E0E6ED;
    margin: 14px 0 16px
  }
`

export const TabDescription = styled.div`
  background: var(--white);
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  max-width: 200px;
  padding: 5px 10px;
  font-family: "Nunito Sans Light";
  font-size: 12px;
`

