import styled from "styled-components";

export const NotesMaxView = styled.div`
  width: 100%;
  height: 100%;
  .notes-view {
    width: 100%;
    height: 100%;
    display: flex;
    &__left {
      width: 70%;
      height: 100%;
      padding: 0px 20px 27px 37px;
      box-sizing: border-box;
      &-empty {
        height: 100%;
        width: 100%;
        overflow: auto;
        background-color: #FFFFFF;
        border-radius: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .empty-img {
          width: 400px;
        }
        .empty-text {
          color: #7776BE;
          font-family: Nunito Sans;
          font-size: 16px;
          top: -70px;
          position: relative;
        }
      }
    } 
    &__right {
      padding: 0px 37px 27px 0px;
      width: 30%;
      box-sizing: border-box;
      overflow: auto;
      &-content {
        padding-top: 10px; 
        height: 100%;
        width: 100%;
        background-color: #FFFFFF;
        border-radius: 14px;
        box-sizing: border-box;
      }
    }
  }
`
