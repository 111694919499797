import styled from "styled-components";

export const ListOfCommentCards = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  border-radius: 20px;
  justify-content: space-between;
  .box-container{
    width: 49.5%;
    display: flex;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 672px) {
    .box-container {
      width: 100%;
    }
  }
`;

export default ListOfCommentCards;
