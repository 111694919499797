import React, { useEffect } from "react";
import * as S from "./HealthIndicatorMeasureType.style";
import { useTranslation } from "react-i18next";

function HealthIndicatorMeasureType({
  selectedMeasure,
  selectedHealthIndicator,
  changeFormField,
  measureList,
  goNextInput
}) {

  const { t } = useTranslation("global");

  useEffect(() => {
    if(measureList?.length === 1) {
      changeFormField([
        {
          value: measureList[0],
          text: measureList[0],
          fieldName: "measureType"
        }
      ])
    }
  }, [measureList])

  return (
    <S.HealthIndicatorMeasureType>
      <div className="title">{t("healthAddData.healthIndicators.unitMeasure")}</div>
      {
        !selectedHealthIndicator?.value &&
        <div className={`unit-list unit-list--disabled`}>  
          <button 
            className={`unit-item unit-item--disabled`} 
          >
            <div className={`unit-item__circle`}></div>
            <div className="unit-item__text">-----</div>
          </button>
          <div className="input-msg">{t("healthAddData.healthIndicators.healthIndicatorWarning")}</div>
        </div>
      }
      <div className="unit-list">
        {
          measureList?.map(
            (measure) => {
              return (
                <button 
                  key={measure}
                  className={`unit-item ${measure === selectedMeasure.value && 'unit-item--selected'}`} 
                  onClick={
                    () => {
                      changeFormField(
                        [
                          {
                            value: measure, 
                            text: measure, 
                            fieldName: "measureType"
                          }
                        ]
                      );
                      goNextInput()
                    }
                  }
                >
                  <div className={`unit-item__circle ${measure === selectedMeasure.value && 'unit-item__circle--selected'}`}></div>
                  <div className="unit-item__text">{measure}</div>
                </button>
              )
            }
          )
        }
      </div>
    </S.HealthIndicatorMeasureType>
  );
}

export default HealthIndicatorMeasureType;
