import { gql } from "@apollo/client";

export const INSERT_USER_TO_FEELING = gql`
  mutation Insert_userToFeeling($data: [userToFeeling_insert_input!]!) {
    insert_userToFeeling(objects: $data) {
      returning {
        id
        levelFeeling
        description
        date
      }
    }       
  }
`

export const INSERT_USER_TO_SIDE_EFFECT = gql`
  mutation Insert_userToSideEffect($data: [userToSideEffect_insert_input!]!) {
    insert_userToSideEffect(objects: $data) {
      returning {
        id
        typeSideEffect
        date
        severity
        userId
      }
    }      
  }
`

export const INSERT_USER_TO_DRUG_HISTORY = gql`
  mutation Insert_userToDrugHistory($data:[userToDrugHistory_insert_input!]!){
    insert_userToDrugHistory(objects: $data) {
      returning {
        id
        doseType
        dose
        delay
        userToDrugId
        created_at
        isTaken
        comment
      }
    }
  }
`

export const INSERT_USER_TO_DRUG = gql`
  mutation Insert_userToDrug($data: userToDrug_insert_input!){
    insert_userToDrug_one(object: $data) {
      id
      drugId
      quantity
      doseShape
      displayName
      startTreatment
      endTreatment
      startHour
      dose
      doseType
      frequency
      frequencyType
      isNotify
      isFix
      comment
      isPeriodic
      daysOfWeek
      userToDrugDates {
        userToDrugId
        dateTime
      }
      drugDose {
        doseTypeVal {
          value
          comment
        }
        drug {
          id
          genericName
        }
      }
      doseShapeValue {
        value
        comment
      }
      frequencyTypeValue {
        value
        comment
      }
    }
  }
`

export const INSERT_VITAL_SIGN = gql`
  mutation Insert_vitalsign($data : [userToVitalSign_insert_input!]!) {
    insert_userToVitalSign(objects: $data ) {
      returning {
        id
        vitalSign
        frequency
        unit
        createdAt
        startHour
        frequencyType
        vitalSignVal {
          value
          comment
        }
        enFrequencyTypeValue {
          value
          comment
        }
      }
    }        
  }
`

export const INSERT_USER_VITAL_SIGN_HISTORY = gql`
  mutation Insert_userVitalSignHistory($data : [userVitalSignHistory_insert_input!]!) {
    insert_userVitalSignHistory(objects: $data) {
      returning {
        id
        vitalSign
        unit
        measure1
        measure2
        created_at
      }
    }       
  }
`