import React, { useEffect, useState } from "react";
import * as S from "./MedicationRange.style";
import { useTranslation } from "react-i18next";
import CalendarRange from "./calendarRange";
import ZaiaInput from "../../../../../../../zaiaComponents/zaiaInput";
import * as P from "../../../../../../../../utils/pipes";
import ZaiaToggleCircular from "../../../../../../../zaiaComponents/zaiaToggleCircular";

function MedicationRange({
  selectedRangeStart,
  selectedRangeFinish,
  // changeFormFieldDates,
  changeFormField
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const [initialDate, setInitialDate] = useState();
  const [finishDate, setFinishDate] = useState();

  const [disableFinishDate, setDisableFinishDate] = useState(false);

  const saveDates = async () => {
    if(initialDate && finishDate) {
      changeFormField(
        [
          {
            value: P.formatNumberDateInput(initialDate), 
            text: P.formatNumberDate(initialDate, true),
            fieldName: "rangeStart",
          },
          {
            value: P.formatNumberDateInput(finishDate), 
            text: " - " + P.formatNumberDate(finishDate, true), 
            fieldName: "rangeFinish",
          }
        ]
      )
    } else if (initialDate) {
        changeFormField(
          [
            {
              value: P.formatNumberDateInput(initialDate), 
              text: P.formatNumberDate(initialDate, true),
              fieldName: "rangeStart",
            },
            {
              value: disableFinishDate ? true : null, 
              text: "", 
              fieldName: "rangeFinish",
            }
          ]
        )
      } else {
        if(!selectedRangeStart.value && !selectedRangeFinish.value) {
          changeFormField(
            [
              {
                value: null, 
                text: "",
                fieldName: "rangeStart",
              },
              {
                value: null, 
                text: "", 
                fieldName: "rangeFinish",
              }
            ]
          )
        } else {
          setInitialDate(new Date(selectedRangeStart.value.replace(/-/g,'/').replace('T',' ')));
          setFinishDate(new Date(selectedRangeFinish.value.replace(/-/g,'/').replace('T',' ')));
        }
    }
  }

  useEffect(() => {
    saveDates();    
  }, [initialDate, finishDate])

  useEffect(() => {
    changeFormField(
      [
        {
          value: null, 
          text: "",
          fieldName: "rangeStart",
        },
        {
          value: null, 
          text: "", 
          fieldName: "rangeFinish",
        }
      ]
    )
  }, [disableFinishDate])

  return (
    <S.MedicationRange>
      <div className="title">
        {t("globally.chooseInitialDate")}
      </div>
      <div className="calendar-inputs">
        <div className="input-container">
          <div className="input-label">{t("globally.initial")}</div>
          <ZaiaInput 
            type={"date"}
            name="data"
            errorOption={false}
            getText={(e) => {
              setInitialDate(new Date(e));
              // setInitialDate(new Date(e.replace(/-/g,'/').replace('T',' ')));
            }}
            disabled={true}
            value={selectedRangeStart.value ? selectedRangeStart.value : ""}
            padding={"5px 10px"}
            borderRadius={"4px"}
            textColor={"#6C6BCC"}
            borderColor={"transparent"}
            fontSize="15px"
            />
        </div>
        <div className={`input-container ${disableFinishDate && "input-container--unfocus"}`}>
          <div className="input-label">{t("globally.final")}</div>
          <ZaiaInput
            type={"date"}
            name="data"
            errorOption={false}
            getText={(e) => {
              setFinishDate(new Date(e.replace(/-/g,'/').replace('T',' ')));
              // setFinishDate(new Date(e.replace(/-/g,'/').replace('T',' ')));
            }}
            disabled={true}
            value={selectedRangeFinish.value ? selectedRangeFinish.value : ""}
            padding={"5px 10px"}
            borderRadius={"4px"}
            textColor={"#6C6BCC"}
            borderColor={"transparent"}
            placeholderTextColor={"#878792"}
            fontSize="15px"
          />
        </div>
      </div>
      <div className="calendar-range-container">
        <div className="calendar-range">
          <CalendarRange
            initialDate={initialDate}
            finishDate={finishDate}
            setInitialDate={setInitialDate}
            setFinishDate={setFinishDate}
            rangeActive={!disableFinishDate}
            titleColor={"#37358F"}
            arrowsColor={"#37358F"}
            textColor={"#6A6898"}
            dateSelectedPrimaryColor={"#37358F"}
            dateSelectedSecondColor={"#37358F"}
            currentDayColor={"#37358F"}
            backgroundColor={"#F1F3F5"}
            fontSizeCalendar={"8px"}
          />
        </div>
      </div>
      <div className="toogle-container">
        <span>{t("globally.withoutEndDate") }</span>
        <ZaiaToggleCircular
          id={'end-date-toggle'}
          color={"#37358F"}
          toggle={setDisableFinishDate}
          active={disableFinishDate}
        />
      </div>
    </S.MedicationRange>
  );
}

export default MedicationRange;
