import React, { useContext, useMemo, useState } from "react";
import * as S from "./OutcomesTable.style";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

// pipes
import * as P from "../../../../../../../../utils/pipes";

// Hooks
import useDoctorData from "../../../../../../../../hooks/useDoctorData";

// GraphQL
import { useMutation } from "@apollo/client";
import {
  MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD,
  MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD,
  MUTATION_UPDATE_DOCTOR_TO_USER_PROCESS_BOARD,
} from "../../../../../../../../graphql/mutations/DoctorToUserProcessBoard";
import Popup from "reactjs-popup";
import ZaiaReaction from "../../../../../../../zaiaComponents/zaiaReaction";
import { ThemeContext } from "styled-components";
import useActivateModal from "../../../../../../../../hooks/useActivateModal";
import NewEventModal from "../newEventModal";
import InvitePatientButton from "../../../../../../patients/components/invitePatientButton";
import Modal from "../../../../../../../../utils/modal";
import ModalAddOutcomesEvent from "../../../../../../shared/modals/modalAddOutcomesEvent";
import ModalOutcomesEventHistory from "../../../../../../shared/modals/modalOutcomesEventHistory";

const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;

function OutcomesTable({
  patientID,
  processID,
  processToCustomFormID,
  customFormData,
  customFormLoading,
  userToProcessData,
  highlightsFilter,
  eventFilter,
  setCreateEventActive
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { doctorData } = useDoctorData();
  const theme = useContext(ThemeContext);
  const { activeModal, desactiveModal } = useActivateModal();
  const [createEventCalendarActive, setCreateEventCalendarActive] = useState(false);
  const [seeEventHistoryActive, setSeeEventHistoryActive] = useState(false);
  const [cmpIdSelected, setCmpIdSelected] = useState(null);

  const [insertDoctorToUserProcessBoard] = useMutation(
    MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD
  );
  const [deleteDoctorToUserProcessBoard] = useMutation(
    MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD
  );
  const [updateDoctorToUserProcessBoard] = useMutation(
    MUTATION_UPDATE_DOCTOR_TO_USER_PROCESS_BOARD
  );

  const insertBoardData = async (customFormId) => {
    try {
      await insertDoctorToUserProcessBoard({
        variables: {
          customFormId: customFormId,
          typeView: "new",
          doctorId: doctorData.id,
          userToProcessId: userToProcessData.userToProcess[0].id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateBoardData = async (doctorToUserProcessBoardId, state) => {
    try {
      await updateDoctorToUserProcessBoard({
        variables: {
          id: doctorToUserProcessBoardId,
          typeView: state ? "new" : "history",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteBoardData = async (doctorToUserProcessBoardId) => {
    try {
      await deleteDoctorToUserProcessBoard({
        variables: {
          id: doctorToUserProcessBoardId,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getOptionsWithPayload = (formFields) => {
    return formFields.map((option) => {
      const { customFormTypeField, userToCustomFormFields } = option;
  
      if (
        !customFormTypeField?.fieldOptions?.length ||
        !userToCustomFormFields?.length
      ) {
        return { ...option, payload: null };
      }
  
      const fieldOption = customFormTypeField.fieldOptions.find(
        (field) => field.key === userToCustomFormFields?.[0].key
      );
  
      if (!fieldOption?.payload) {
        return { ...option, payload: null };
      }
  
      let payloadFormated;
  
      try {
        payloadFormated = JSON.parse(fieldOption.payload);
        option.payload =
          "msg" in payloadFormated ? payloadFormated.msg : null;
      } catch (err) {
        option.payload = null;
      }
  
      return option;
    });
  }

  const customFormOrder = useMemo(() => {
    let formGroupsFilter = 
      highlightsFilter
      ? customFormData?.customForm_by_pk.formGroups.filter(
          (formGroup) => formGroup.DoctorToUserProcessBoards[0]
        )
      :
       customFormData?.customForm_by_pk.formGroups;
  
    if(formGroupsFilter?.length === 0) {
      formGroupsFilter = customFormData?.customForm_by_pk.formGroups;
    }

    return formGroupsFilter?.map((formGroup) => [    
      formGroup,    
      getOptionsWithPayload(formGroup.customFormFields),    
      formGroup?.customFormFields[0]?.userToCustomFormFields &&
      formGroup?.customFormFields[0]?.userToCustomFormFields[0]?.doctor
        ? `${formGroup?.customFormFields[0]?.userToCustomFormFields[0]?.doctor.name} ${formGroup?.customFormFields[0]?.userToCustomFormFields[0]?.doctor.lastname}`
        : "–",
      formGroup?.customFormFields[0]?.userToCustomFormFields
      ? P.formatNumberDate(
          formGroup?.customFormFields[0]?.userToCustomFormFields[0]?.created_at,
          true,
          language
        )
      : "–",
      formGroup?.userToProcessHistories[0] && formGroup?.userToProcessHistories[0]?.descript
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFormData, highlightsFilter]);

  const modalPatient = (formFieldID) => {
    return (
      <>
        <NewEventModal
          patientID={patientID}
          processID={processID}
          processToCustomFormID={processToCustomFormID}
          customFormFieldID={formFieldID}
        />
        <div onClick={() => desactiveModal()}>
          <InvitePatientButton activate={true} />
        </div>
      </>
    );
  };

  function getFormattedValue(item) {
    const userField = item.userToCustomFormFields[0];
    let formattedValue = "–";
    if (userField) {
      formattedValue =
        item.typeFieldValue === "dateInput"
          ? P.formatNumberDate(userField.value, true, language)
          : userField.value;
    }
    return formattedValue;
  }


  const renderCustomFormGroupsindex1 = (formGroup, language) => {
    return (
      <td key={uuidv4()}>
        {formGroup.length <= 0 && "-"}
        {formGroup.length > 0 && 
          formGroup.map((item, index) => {
            return (
              <div key={item.id}>
                {
                  index !== 0 && 
                  <br/>
                }

                <div className="detail">
                  <div className="detail-item">
                    {P.dataTranslation(item.fieldName, language)}
                    {item.descript && (
                      <Popup
                        trigger={
                          <i className="template-header-item-icon icon zaia-alert"></i>
                        }
                        on={["hover", "focus"]}
                        closeOnDocumentClick
                        keepTooltipInside
                        position={["right center"]}
                        nested
                      >
                        <S.PopUpPayload>
                          <p>
                            {P.dataTranslation(item.descript, language)}
                          </p>
                        </S.PopUpPayload>
                      </Popup>
                    )}
                  </div>
                  {getFormattedValue(item)}
                  {item.payload && (
                    <Popup
                      trigger={
                        <i className="template-header-item-icon icon zaia-alert"></i>
                      }
                      on={["hover", "focus"]}
                      closeOnDocumentClick
                      keepTooltipInside
                      position={["right"]}
                      nested
                    >
                      <S.PopUpPayload>
                        <p>{item.payload}</p>
                      </S.PopUpPayload>
                    </Popup>
                  )}
                </div>
              </div>
            );
          })
        }
      </td>
    );
  }

  const renderCustomFormGroupsIndex0 = (formGroup, language) => { 
    return (
      <td
        key={uuidv4()}
        style={{
          color: "",
          fontFamily: "Nunito ExtraBold",
          verticalAlign: "middle",
        }}
        className="td-index0"
      >
          {formGroup.DoctorToUserProcessBoards[0] ? (
            <div className="highlight-button highlight-button--active">
              <i
                className="icon-save-form-field icon zaia-star-on"
                onClick={() =>
                  deleteBoardData(
                    formGroup.DoctorToUserProcessBoards[0].id
                  )
                }
              ></i>
            </div>
          ) : (
            <div className="highlight-button">
              <i
                className="icon-save-form-field icon zaia-star-off"
                onClick={() => insertBoardData(formGroup.id)}
              ></i>
            </div>
          )}
        {/* {formGroup.DoctorToUserProcessBoards[0] && (
          <i
            className="icon-save-form-field icon zaia-timeline"
            style={{
              color:
                formGroup.DoctorToUserProcessBoards[0]
                  .typeView === "history"
                  ? theme.inputSelected
                  : theme.onBackgroundSoft,
            }}
            onClick={() =>
              formGroup.DoctorToUserProcessBoards[0]
                .typeView === "history"
                ? updateBoardData(
                    formGroup.DoctorToUserProcessBoards[0].id,
                    true
                  )
                : updateBoardData(
                    formGroup.DoctorToUserProcessBoards[0].id,
                    false
                  )
            }
          />
        )} */}
        <div className="event-type">
          <div className="event-type__name">
            {P.dataTranslation(formGroup.customFormName, language)}
            {formGroup.descript && (
              <Popup
                trigger={
                  <i className="template-header-item-icon icon zaia-alert"></i>
                }
                on={["hover", "focus"]}
                closeOnDocumentClick
                keepTooltipInside
                position={["right"]}
                nested
              >
                <S.PopUpPayload>
                  <p>
                    {P.dataTranslation(
                      formGroup.descript,
                      language
                    )}
                  </p>
                </S.PopUpPayload>
              </Popup>
            )}
          </div>
          <div className="event-type__options">
            <div className="event-type__option" 
              onClick={() =>{
                  // activeModal(() => modalPatient(formGroup.id))
                  setCmpIdSelected(formGroup.id);
                  setCreateEventCalendarActive(true)
                }
              }
            >
              <i className="event-type__option-icon icon zaia-alert"></i>
              <div className="event-type__option-text">{t("aiNavigator.outcomes.updateEvent")}</div>
            </div>
            {/* <div className="event-type__option">
              <i className="event-type__option-icon icon zaia-alert"></i>
              <div className="event-type__option-text">{t("aiNavigator.outcomes.showEventFiles")}</div>
            </div> */}
            <div className="event-type__option"
              onClick={() =>{
                // activeModal(() => modalPatient(formGroup.id))
                setCmpIdSelected(formGroup.id);
                setSeeEventHistoryActive(true);
              }
            }
            >
              <i className="event-type__option-icon icon zaia-alert"></i>
              <div className="event-type__option-text">{t("aiNavigator.outcomes.showUpdateHistory")}</div>
            </div>
          </div>
        </div>
      </td>
    );
  }

  return (
    <S.OutcomesTable>
      <Modal open={setCreateEventActive}>
        <ModalAddOutcomesEvent
          setCreateEventActive={setCreateEventActive}
          cmpID={cmpIdSelected}
          patientID={patientID}
          processID={processID}
        />
      </Modal>
      <Modal open={seeEventHistoryActive}>
        <ModalOutcomesEventHistory
          setSeeEventHistoryActive={setSeeEventHistoryActive}
          cmpID={cmpIdSelected}
          patientID={patientID}
          setCreateEventActive={setCreateEventActive}
        />
      </Modal>
      <table className="illness-control-table">
        <thead>
          <tr>
            <th className="th-event-type">{t("globally.eventType")}</th>
            <th>{t("aiNavigator.outcomes.details")}</th>
            <th>{t("aiNavigator.outcomes.doctorWhoEdited")}</th>
            <th></th>
            {/* <th>{t("globally.date")}</th> */}
            <th>{t("globally.comment")}</th>
          </tr>
        </thead>
        <tbody>
          {customFormOrder?.map((formGroup) => {
            return (
              (eventFilter?.find((event) => event === formGroup[0].id) || eventFilter?.length === 0) &&
              <tr key={formGroup[0].id}>
                {formGroup?.map((formGroup, index) => {
                  if (index === 1) {
                    return(renderCustomFormGroupsindex1(formGroup, language))
                  }
                  if (index === 0) {
                    return(renderCustomFormGroupsIndex0(formGroup, language))
                  }
                  return <td 
                    key={uuidv4()}
                    style={{
                      verticalAlign: "middle",
                      textAlign: formGroup === "–" ? "center" : "left",
                    }}                   
                  >{formGroup}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {customFormLoading && (
        <div className="illness-control-reaction">
          <ZaiaReaction widthImg="170px" zaiaImg={logoZaiaLoading} />
        </div>
      )}
      {!customFormLoading && customFormData === undefined && (
        <div className="illness-control-reaction">
          <ZaiaReaction
            zaiaImg={logoZaiaEmpty}
            widthImg="170px"
            text={t("outcomes.tableOutcomes.empty")}
            sizeText="18px"
            widthText="200px"
          />
        </div>
      )}
    </S.OutcomesTable>
  );
}

export default OutcomesTable;
