import styled from "styled-components";

export const DashboardAllPatients = styled.div`
  background-color: var(--background-patient);
  width: 100%; 
  height: 100%;
  display: flex;
  flex-direction: column;
  .rightSidePatient__title {
    color: var(--title-register-color);
    height: 20px;
    margin-bottom: 10px;
    font-family: 'Nunito ExtraBold';
  }
  .rightSidePatient__containerFilter {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 5px;
    height: 20px
    h5 {
      margin-left: 5px;
      color: var(--title-register-color);
      cursor: pointer;
    }
    p {
      color: var(--blue-color);
      font-size: 0.65rem;
    }
    div {
      margin: 6px 0 0 5px;
      font-size: small;
    }
  }
  .cards-container {
    overflow-y: auto;
    height: calc(100% - 60px);
    margin-top: 10px;
  }

  @media only screen and (max-width: 768px) {
    .cards-container {
      ::-webkit-scrollbar {
        display: none;
      }
    }
  }
`;
