import styled from "styled-components";
import { getHexOpacity } from '../../../utils/pipes';

export const ZaiaRoundInput = styled.div`
  width: 100%;
  .zaia-input {
    width: 100%;
    position: relative;
    /* display: flex; */
    .zaia-input-container {
      border: none;
      padding: ${({padding}) => padding};
      display: flex;
      align-items: center;
      min-width: 100px;
      width: 100%;
      box-sizing: border-box;
      border-radius: 10px;
      border: 1px solid #68688D;
      cursor: text;
      .icon-search {
        margin: 0 5px 0px 0;
        /* color: ${({theme}) => theme.inputBorder}; */
        color: #59599E;
      }
      input {
        border: none;
        background-color: transparent;
        width: 100%;
        font-family: "Nunito Sans";
        &:focus {
          outline: none;
        }
        &::placeholder { 
          color: ${({theme}) => theme.inputBorder};
          font-size: 14px;
          margin-left: 30px;
          font-family: "Open sans";
        }
      }
      .zaia-input-tag-selected {
        position: absolute;
        display: flex;
        margin-left: 20px;
        padding: 0px 8px;
        background-color: ${({theme}) => theme.background};
        border: 1px solid ${({theme}) => theme.primaryColor};
        border-radius: 10px;
        font-family: "Open Sans";
        align-items: center;
        &-text {
          font-size: 12px;
          color: ${({theme}) => theme.onBackgroundVariant};
        }
        &-icon {
          font-size: 6px;
          color: ${({theme}) => theme.onBackgroundVariant};
          padding: 0 0 0 5px;
          cursor: pointer;
        }
      }
    }
    .zaia-input-options-container {
      display: ${({listActive}) => listActive ? 'block' :'none' };
      background-color: ${({theme}) => theme.background};
      box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
      max-height: 150px;
      padding: 10px;
      overflow: auto;
      border-radius: 10px;
      margin-left: 5px;
      position: absolute;
      width: 100%;
      box-sizing: border-box;
      z-index: 100;
      .zaia-input-option {
        min-height: 15px;
        cursor: pointer;
        margin-bottom: 5px;
        display: flex;
        &-image-container {
          place-self: center;
        }
        &-info-container {
          margin-left: 8px;
        }
        &-title {
          font-size: 12px; 
          font-family: "Open Sans Bold";
          color: ${({theme}) => theme.onBackgroundVariant};
        }
        &-sub-title {
          font-size: 10px;
          font-family: "Open Sans";
          color: ${({theme}) => theme.inputBorder};
        }
      }
    }
  }
`