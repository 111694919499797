import React, { useEffect, useState } from "react";
import * as S from "./SettingsChat.style";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

// Components
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import ZaiaModal from "../../../../zaiaComponents/zaiaModal";
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";

// graphQL
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GET_USERS_CHAT_GROUP } from "../../../../../graphql/queries/Chat";
import { MUTATION_EDIT_CHAT_GROUP } from "../../../../../graphql/mutations/Chat";

//Hooks
import useUploadImg from "../../../../../hooks/useUploadImage";
import useActivateModal from "../../../../../hooks/useActivateModal";
import useUploadTempImg from "../../../../../hooks/useUploadTempImg";


// Assets
const defaultChatGroup = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-chat-group.png`;
const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;

function SettingsChat({ 
  setSettingsGroup,
  selectedChat
}) {
  const {t} = useTranslation("global");
  const [participantsNumber, setParticipantsNumber] = useState();
  const {dataImg, tempImg} = useUploadTempImg();

  const {activeModal} = useActivateModal()
  const {uploadImage} = useUploadImg();

  const [editInfo] = useMutation(MUTATION_EDIT_CHAT_GROUP);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    let submitPhoto = { load: false, success: true }
    let fileImg
    if (dataImg) {
      submitPhoto.load = true;
      try {
        fileImg = await uploadImage(dataImg);
      } catch (err) {
        submitPhoto.success = false;
      }
    }
    const modifyInfo = {};
    if(dataImg) {
      modifyInfo.imagen = fileImg ? fileImg.file.toString() : null;
    }

    try {
      // eslint-disable-next-line no-unused-vars
      await editInfo({
        variables: {
          idChatGroup: selectedChat.id,
          newChatInfo: modifyInfo
        },
      });
      triggerModal(submitPhoto, true);
      // resetForm()
    } catch (err) {
      triggerModal(submitPhoto, false);
      resetForm()
    }
  }

  const triggerModal = (submitPhoto, submitInfo) => {
    if (submitInfo) {
      if(submitPhoto.load) {
        if (submitPhoto.success) {
          callModal(false, t("profile.modalMessages.successWithPhoto"))  // update success with photo
        } else {
          callModal(true, t("profile.modalMessages.successInfoNoPhoto"))  // update success without photo
        }
      } else {
        callModal(false, t("profile.modalMessages.success"))  // update success
      }
    } else {
      callModal(true, t("profile.modalMessages.errorInfo"))  // update error
    }
  }

  const callModal = (error, text) => {
    activeModal(() => <ZaiaModal errorModal={error} text={text}/>)
  }

  return (
    <S.SettingsChat>
      <header className="settings__header">
        <i className="icon zaia-icono-cerrar settings__header-icon" onClick={() => setSettingsGroup(false)}></i>
        <h4>{t("chat.settingsChat.info")}</h4>
      </header>
      <div className="settings__container">
        <div className="image-container">
          <div className="image-change">
            <ZaiaImage 
              img={tempImg ? tempImg : selectedChat?.chatPhotoUser}
              size={100}
              defaultImg={defaultChatGroup}
            />
            {/* <input
              type="file"
              ref={hiddenFileInput}
              onChange={uploadImg}
              style={{display: 'none'}}
              accept="image/*"  
            />
            <div className="button-change-image" onClick={() => hiddenFileInput.current.click()}>
              <i className="zaia-icono-nuevo-mensaje"></i>
            </div> */}
          </div>
        </div>
        <div className="bottom-container">
        <Formik
            initialValues={{
              name: selectedChat?.chatNameUser ? selectedChat?.chatNameUser : '',
            }}
            enableReinitialize={true}
            validationSchema={Yup.object({
              name: Yup.string()
                .min(3, t("register.validation.min"))
                .max(15, t("register.validation.max"))
                .required(t("register.validation.required")),
            })}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <form className="info-chat-group" onSubmit={ props.handleSubmit }>
                <div className="input-content">
                  <span>{t("globally.name")}</span>
                  <ZaiaInputForm 
                    placeholder={t("globally.name")}
                    type="text"
                    name="name"
                    icon="icono-nombre"
                    disabled={true}
                  />
                </div>
                <div className="participats-info">
                  <div className="participants-number">{participantsNumber} {t("chat.settingsChat.participants")}</div>
                  {/* <i className="icon-search zaia-icono-search"></i> */}
                </div>
                <div className="participats-list">
                  <ChatPaticipants setParticipantsNumber={setParticipantsNumber} selectedChat={selectedChat}/>
                </div>
                {/* <div className="button-container">
                  <ZaiaButton 
                    type="submit"
                    title={
                      editInfoLoading || uploadImgLoading ?
                      `${t('globally.loading')}...` :
                      t("profile.saveChanges")
                    }
                  />
                </div> */}
              </form>
            )}
          </Formik>
        </div>
      </div>
    </S.SettingsChat>
  );
}

const ChatPaticipants = ({setParticipantsNumber , selectedChat}) => {
  const [chatMembers, setChatGroupData] = useState();

  // eslint-disable-next-line no-unused-vars
  const { data: ChatGroupMembersData } = useQuery(QUERY_GET_USERS_CHAT_GROUP, { 
    variables: { groupChatId: selectedChat?.id }
  });

  useEffect(() => {
    let usersParticipant = [];
    if (ChatGroupMembersData?.chatGroup[0]?.ownerUserId) {
      usersParticipant?.push(ChatGroupMembersData?.chatGroup[0]?.ownerUser);
    } else {
      usersParticipant?.push(ChatGroupMembersData?.chatGroup[0]?.ownerDoctor);
    }
    ChatGroupMembersData?.chatGroup[0]?.personToChats.forEach(person => {
      if (person.userId) {
        usersParticipant?.push(person.user)
      } else {
        usersParticipant?.push(person.doctor)
      }
    });
    setChatGroupData(usersParticipant);
    setParticipantsNumber(ChatGroupMembersData?.chatGroup[0]?.personToChats.length + 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ChatGroupMembersData])

   return (
     <S.ChatPaticipants>
       {
         chatMembers?.map(chatMember => {
           return (
            chatMember ? 
            <div className="box-participant" key={chatMember?.id}>
              <ZaiaImage 
                img={chatMember?.picture}
                size={30}
                defaultImg={defaultUser}
              />
              <div className="name-participant">{`${chatMember?.name} ${chatMember?.lastname}`}</div>
            </div> : null
          )
         })
       }
     </S.ChatPaticipants>
   )
 } 

export default SettingsChat;
