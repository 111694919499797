import { gql } from "@apollo/client";

export const MUTATION_SIGN_UP_PATIENT = gql`
  mutation MUTATION_SIGN_UP_PATIENT(
    $name: String!
    $lastname: String!
    $email: String!
    $birthdate: date
    $gender: String
  ) {
    signUpByPasswordless(
      name: $name
      lastname: $lastname
      email: $email
      tenantId: "user"
      birthdate: $birthdate
      gender: $gender
    ) {
      status
      message
    }
  }
`;

export const MUTATION_SIGN_UP_PATIENT_BY_PHONE = gql`
  mutation MUTATION_SIGN_UP_PATIENT(
    $name: String!
    $lastname: String!
    $phone_number: String!
  ) {
    signUpByPasswordless(
      name: $name
      lastname: $lastname
      phoneNumber: $phone_number
      tenantId: "user"
    ) {
      status
      message
    }
  }
`;
