import { gql } from "@apollo/client";

export const SUBSCRIPTION_GET_GROUP_CHATS = gql`
  subscription SUBSCRIPTION_GET_GROUP_CHATS {
    chatGroup {
      id
      imagen
      nameChat
      ownerUserId
      ownerUser {
        id
        picture
        name
        lastname
      }
      ownerDoctorId
      ownerDoctor {
        id
        picture
        name
        lastname
      }
      personToChats {
        id
        chatGroupId
        doctorId
        doctor {
          name
          lastname
          picture
        }
        userId
        user {
          id
          name
          lastname
          picture
        }
      }
      userToProcess {
        processId
        process {
          id
          processName
        }
        isAcepted
      }
      messages(limit: 1, order_by: {createdAt: desc}) {
        id
        createdAt
        message
        fromDoctorId
        doctor {
          id
          name
          lastname
          gender
        }
        fromUserId
        user {
          id
          name
          lastname
          gender
        }
      }
    }
  }
`

export const SUBSCRIPTION_GET_GROUP_CHATS_BY_USER = gql`
  subscription SUBSCRIPTION_GET_GROUP_CHATS_BY_USER (
    $userId: uuid
  ) {
    chatGroup(where: {_or: [{personToChats: {userId: {_eq: $userId}}},{ownerUserId: {_eq: $userId}}]}) {
      id
      imagen
      nameChat
      ownerUserId
      ownerUser {
        id
        picture
        name
        lastname
      }
      ownerDoctorId
      ownerDoctor {
        id
        picture
        name
        lastname
      }
      personToChats {
        id
        chatGroupId
        doctorId
        doctor {
          name
          lastname
          picture
        }
        userId
        user {
          id
          name
          lastname
          picture
        }
      }
      userToProcess {
        processId
        process {
          id
          processName
        }
      }
      messages(limit: 1, order_by: {createdAt: desc}) {
        id
        createdAt
        message
        fromDoctorId
        doctor {
          id
          name
          lastname
          gender
        }
        fromUserId
        user {
          id
          name
          lastname
          gender
        }
      }
    }
  }
`

export const SUBSCRIPTION_GET_LAST_MESSAGE = gql`
  subscription SUBSCRIPTION_GET_LAST_MESSAGE(
    $doctorId: uuid
  ) {
    message(limit: 1, order_by: {createdAt: desc}, where: {chatGroup:{_or:[{ownerDoctorId:{_eq: $doctorId }},{personToChats:{doctorId:{_eq: $doctorId}}}]}}) {
      createdAt
      fromDoctorId
      fromUserId
      id
      message
      chatGroup {
        id
        ownerDoctorId
        personToChats {
          doctorId
        }
      }
    }
  }
`
