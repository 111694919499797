import React from "react";
import { Link } from "react-router-dom";
import * as S from "./Header.style";

function Header({ route }) {


  return (
    <S.Header>
      {
        <Link to={route}>
          <div className="icon-back icon zaia-icono-atras" style={{ color: "white" }}></div>
        </Link>
      }
    </S.Header>
  );
}

export default Header;
