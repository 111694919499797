  import styled from "styled-components";

  export const ZaiaToggleCircular = styled.div`
    display: flex;
    cursor: pointer;
    .toggle-back {
      width: 36px;
      height: 20px;
      background-color: ${({theme, active, color}) => active ? color : theme.disableButton};
      border-radius: 20px;
      padding: 3px;
      transition: all 1s ease;
      margin: auto;
      box-sizing: border-box;
      .circle-toggle {
        width: 14px;
        height: 14px;
        background-color: ${({theme}) => theme.onGreyBackground};
        border-radius: 50%;
        box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
        /* margin-left: ${({active}) => active ? 'auto' : '0'}; */
        /* float: ${({active}) => active ? 'right' : 'left'}; */
        transform: ${({active}) => active ? 'translateX(16px)' : 'none'};
        transition: .5s ease;
      }
    }
  `