import styled from "styled-components";

export const FeelingsBoxInfo = styled.div`

  .boxInfoPatient__info {
    width: 100% ;
    display: flex;
    
    &-left {
      display: flex;
      width: calc(100% - 200px);
      overflow-x: auto;
      
      &-first {
        width: 40%;
        padding-right: 10px;     
        min-width: 314px;
        display: flex;
        box-sizing: border-box;
      }

      &-second {
        width: 60%;
        padding: 0 10px 0 10px;
        box-sizing: border-box;
      }
    }

    &-right {
      width: 200px;
      padding-left: 10px;
      display: flex;
      min-width: 200px;
    }
  }

  @media only screen and (max-width: 768px) {
    .boxInfoPatient__info {
      flex-direction: column;
      align-items: center;
      &-left {
        width: 100%;
        margin-bottom: 20px;
        align-items: center;
        flex-direction: column;
        margin-bottom: 10px;
        &-first {
          width: 100%;        
          min-width: auto;
          padding: 0px;
          margin-bottom: 10px;
        }
        &-second {
          width: 100%;        
          padding: 0px;
          margin-bottom: 10px;
        }
      }
      &-right {
        width: 100%;
      }
    }
  }
`;