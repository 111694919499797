import { useState, useEffect } from "react";
// cookies
import Session from "supertokens-auth-react/recipe/session";
import jwt_decode from "jwt-decode";

export default function useInfoToken() {
  const [token, setToken] = useState();
  const [userId, setUserId] = useState();
  const [expiration, setExpiration] = useState();
  const [creation, setCreation] = useState();

  useEffect(() => {
    getTokenInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getJWT() {
    if (await Session.doesSessionExist()) {
      let jwt = await Session.getAccessToken();
      return jwt;
    } else {
      return "";
    }
  }

  const getTokenInfo = async () => {
    try {
      let token = await getJWT();
      let dateNow = new Date(Date.now()).getTime();
      if (token) {
        setToken(token);
        const tokenDecoded = jwt_decode(token);
        setUserId(tokenDecoded.sub);
        setExpiration(tokenDecoded.exp * 1000);
        setCreation(tokenDecoded.iat * 1000);
        if (dateNow > tokenDecoded.exp * 1000) {
          return null;
        } else {
          return {
            token,
            userId: tokenDecoded.sub,
            expiration: tokenDecoded.exp * 1000,
            creation: tokenDecoded.iat * 1000,
          };
        }
      } else {
        return null;
      }
    } catch (e) {
      return null;
    }
  };

  return {
    token,
    userId,
    expiration,
    creation,
    getTokenInfo,
  };
}
