import styled from 'styled-components';

export const SurveyView = styled.div`
  height: 100%;
  width: 100%;
  .survey-header {
    display: flex;
    justify-content: space-between;
    margin: 20px 32.5px 0px;
    box-sizing: border-box;
    &__left {
      display: flex;
      .back-button {
        background-color: #EFEFFF;
        display: flex;
        height: 32px;
        width: 32px;
        border-radius: 67px;
        border: none;
        outline: none;
        margin-right: 10px;
        cursor: pointer;
        &__icon {
          font-size: 14px;
          color: #36369B;
          margin: auto;
        }
      }
      .survey-info {
        &__title {
          font-family: Nunito Sans Light;
          font-size: 24px;
          color: #3E3E5B;
        }
        &__process{
          font-family: Nunito Sans Bold;
          font-size: 14px;
          color: #3E3E5B;
        }
        &__actions {
          display: flex;
          .survey-action {
            font-family: Nunito Sans;
            font-size: 16px;
            padding: 9px 0px;
            margin-right: 10px;
            color: #3E3DA3;
            cursor: pointer;
          }
        }
      }
    }
    &__right {
      align-self: center;
      margin-right: 18px;
      .survey-date {
        color: #707582;
        font-family: Nunito Sans;
        font-size: 16px;
      }
      .survey-delicate {
        display: flex;
        align-items: center;
        &__icon-container {
          background-color: #FFECEC;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          display: flex;
        }
        &__icon {
          margin: auto;
          color: #FF3030;
          font-size: 15px;
        }
        &__text {
          color: #707582;
          font-family: Nunito Sans;
          font-size: 16px;
          margin-left: 5px;
        }
      }
    }
  }
  .filters-section {
    display: flex;
    margin: 10px 31px 0 72px;
    justify-content: space-between;
    &__left {
      display: flex;
      align-items: self-end;
      .filter-text {
        width: 294px;
        margin-left: 7px;
      }
      .filter-favourite {
        align-items: center;
        height: 34px;
        padding: 5px 15px 5px 15px;
        border-radius: 60px;
        font-family: Nunito Sans Semibold;
        font-size: 17px;
        display: flex;
        background-color: #FFA800;
        border: none;
        outline: none;
        margin-left: 12px;
        color: #FFFFFF;
        cursor: pointer;
        &__icon {
          margin-right: 5px;
        }
      }
    }
    &__right {
      display: flex;
      .survey-option {
        display: flex;
        min-width: 145px;
        height: 30px;
        gap: 10px;
        border-radius: 10px;
        border: none;
        margin-left: 10px;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: #F0F0FF;
        outline: none;
        font-family: Nunito Sans; 
        font-size: 15px;
        color: #6C6BCC;
        &__icon {
        }
        &--selected {
          font-family: Nunito Sans Bold; 
          background-color: #D2D2FF;
        }
      } 
    }
  }
  .surveys-section {
    display: flex;
    flex-direction: column;
    height: calc(100% - 214px);;
    margin: 36px 52px 0px 52px;
  }
  .survey-list {
    overflow: auto;
    &--reaction {
      margin: auto;
    }
    table {
      width: 100%;
      height: 100%;
      border-collapse: collapse;
      th {
        padding: 0 20px 20px 20px;
        text-align: start;
        color: #707582;
        font-family: Nunito Sans Light;
        font-size: 16px;
        &:first-child {
          /* text-align: center !important; */
          padding: 0 20px 20px 95px;
        }
      }
      td {
        padding: 20px;
      }
    }
  }
`