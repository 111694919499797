import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalSelectProgram = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: var(--white);
    width: 800px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 10px 40px 0 40px;
    display: flex;
    justify-content: center;
    position: relative;
    .history-table{
      padding: 25px 40px 25px 40px;
      box-sizing: border-box;
      height: calc(100% - 84px);
      overflow: auto;
      table {
        width: 100%;
        border-collapse: collapse;
        font-size: 15px;
        thead {
          padding: 10px;
          tr {
            th {
              /* border-bottom: 1.6px solid var(--no-notification-background); */
              color: var(--blue-color);
              color: #38379C;
              font-family: "Nunito Sans";
              font-size: 15px;
              padding: 18px 10px 15px 10px;
              text-align: left;
              font-weight: 400;
              text-align: center;
            }
            .th-event-type{
              font-family: "Nunito Sans Bold";
            }
          }
        }
        .tbody-content {
          /* width: 100%; */
          padding-bottom: 40px;
          border-bottom: 1px solid #DDDDDD;
          /* margin-bottom: 20px; */
        }
        tbody {
          .table-date-label {
            padding: 20px 0px 10px 20px;
            padding-top: 20px;
            padding-left: 20px;
            padding-bottom: 10px;
            color: #68688D;
            font-family: "Nunito Sans Bold";
            font-size: 25.5px;
          }
          i {
            /* display: inline; */
          }
          td:first-child,
          th:first-child {
            border-radius: 14px 0 0 14px;
          }
          td:last-child,
          th:last-child {
            border-radius: 0 14px 14px 0;
          }
          tr {
            .icon-save-form-field {
              /* margin-right: 10px;
              color: ${({theme}) => theme.inputSelected};
              cursor: pointer; */
            }
            .td-index0 {
              display: flex;
              align-items: center;
            }
            td {
              padding: 13px 10px;
              text-align: left;
              color: var(--blue-dark-color);
              /* vertical-align: text-top; */
              .detail-item {
                display: flex;
                align-items: center;
                color: var(--blue-color);
                font-family: "Nunito Sans Bold";
                font-size: 15px;
                margin-bottom: 10px;
              }
              .template-header-item-icon {
                color: var(--blue-color);
                font-size: 12px;
                margin-left: 5px;
              }
              .event-type {
                &__name {
                  color: #23234F;
                  font-family: "Nunito Sans Light";
                  font-size: 15px;
                  margin-bottom: 5px;
                  display: flex;
                  align-items: center;
                  .template-header-item-icon {
                    color: #38379C;
                    font-size: 13px;
                  }
                }
                &__option {
                  margin-top: 5px;
                  cursor: pointer;
                  font-family: "Nunito Sans";
                  font-size: 15px;
                  font-style: normal;
                  color: #38379C;
                  display: flex;
                  align-items: center;
                  &-icon {
                    font-size: 13px;
                    display: flex;
                    margin-right: 5px;
                  }
                  &-text {
                    text-decoration: underline;
                  }
                }
              }
            }
            .illness-control-document-icon {
              margin-left: 19px;
              font-size: 17px;
              display: flex;
              cursor: pointer;
              color: var(--blue-color);
            }
            :nth-child(even) {
              background-color: var(--table-color);
              border-radius: 14px;
            }
          }
        }
      }
    } 
  }
`;

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Open Sans';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`

export const ProcessFiltered = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .header {
    padding: 30px 20px 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-size: 20px;
      font-family: "Nunito Sans Bold";
      color: #36369B;
    }
    &__close {
      margin-left: auto;
      background-color: #E6E6E6;
      border-radius: 50%;
      width: 17.23px;
      height: 17.23px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      &-icon {
        color: #8C8DA4;
        font-size: 6px;
      }
    }
  }
  .clear-filters {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 30px;
    cursor: pointer;
    &__text {
      color: #8A939D;
      font-family: "Nunito Sans";
      font-size: 17px;
      text-decoration-line: underline;
    }
    &__icon {
      color: #8A939D;
      font-size: 11px;
      margin-left: 5px;
    }
  }
  .programs-list {
    padding-left: 20px;
    overflow-y: scroll;
    /* mask-image: linear-gradient(0deg,#000 95%,transparent); */
    ::-webkit-scrollbar {
      width: 48px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px transparent;
      margin: -20px 0px 0px 0px; 
    }
    ::-webkit-scrollbar-thumb {
      border: 21.5px solid rgba(0, 0, 0, 0);
      box-shadow: inset 0 0 10px 10px #E6E6E6;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: transparent;
    }
    .program-item {
      display: flex;
      padding: 23px 20px;
      border-radius: 10px;
      margin-bottom: 20px;
      background-color: #F8F8F8;
      align-items: center;
      color: #8C8DA4;
      cursor: pointer;
      &--selected {
        color: #3E3DA3;
        background-color: #D6D6F6;
      }
      &__check-icon {
        margin-right: 10px;
        font-size: 14px;
      }
      &__text {
        font-size: 19px;
        font-family: "Nunito Sans";
      }
    }

  }
`;