import React, { useContext, useEffect, useState } from "react";
import * as S from "./Message.style"
import { ThemeContext } from 'styled-components';

function Message({ 
  message, 
  option, 
  time, 
  date, 
  senderName, 
  link,
  children 
}) {

  const theme = useContext(ThemeContext);
  
  const [colorByType, setColorByType] = useState("");
  const [messageEdited, setMessageEdited] = useState("");

  useEffect(() => {
    let messageTemp = message;
    messageTemp = messageTemp?.replace('{{medicine}}','');
    setMessageEdited(messageTemp);

    let result = message?.includes("{{medicine}}");
    if(result) {
      setColorByType(theme.primarySectionMedicines);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function renderMessage(children, link, messageEdited) {
    let message;
    if (children) {
      message = children;
    } else {
      if (link) {
        message = <a href={messageEdited} target="_blank" rel="noopener noreferrer">{messageEdited}</a>;
      } else {
        message = messageEdited;
      }
    }
    return message;
  }

  return (
    <S.Message 
      time={time} 
      option={option} 
      color={colorByType}
    >
      {
        senderName ? 
        <div className="message__sender-name">{senderName}</div> : null
      }
      { option === 'doctor' ? 
        <div className="message message--patient" time={time}>
          {
            children 
            ? children 
            : messageEdited
          }
        </div> :
        <div className="message message--reciever">
          {renderMessage(children, link, messageEdited)}
        </div>
      }
      {
        time && date ? 
        <div className="message__time">{time} • {date}</div> : null
      }
    </S.Message>
  );
}

export default Message;
