import styled from "styled-components";
const waveRight = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-pequena-botton-violeta.svg`;

export const ResetPasswordDiv = styled.div`
  display: flex;
  height: ${({webView}) => webView ? '100vh' : '100svh'};
  .resetPassword__right {
    flex: 0.5;
    background-image: url(${waveRight});
    background-repeat: no-repeat;
    background-position: bottom;
    background-position-y: 110%;
    background-color: ${({theme}) => theme.background};
    .reset-pass-form {
      height: ${({webView}) => webView ? 'calc(100vh - 75px)' : 'calc(100svh - 75px)'};
    }
  }

  @media only screen and (max-width: 768px) {
    .resetPassword__right {
      flex: 1;
      background-position-y: 100%;
    }
  }
`;

export const ResetPasswordFormDiv = styled.div`
  overflow: auto;
  height: 100%;
  display: flex;
  .reset-pass-container {
    margin: auto;
    width: 300px;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
      color: ${({theme}) => theme.primaryColor};
      margin: 10px 0 20px 0;
      font-family: "Nunito Black";
    }
    p {
      margin: 40px 0 40px 0;
      max-width: 270px;
      font-family: "Open Sans";
      color: ${({theme}) => theme.onBackground};
    }
    .resetPassword__email {
      width: 100%;
      h3 {
        color: ${({theme}) => theme.onBackground};
        font-family: "Nunito ExtraBold";
      }
    }
    .title-register{
      margin-bottom: 10px;
    }
  }
`;
