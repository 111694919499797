import styled from 'styled-components';

export const RangeDatesSelectorPanel = styled.div`
  width: 1000px;
  align-self: center;
  height: 100%;
  .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 30px;
    background-color: #E6E6E6;
    border-radius: 50%;
    width: 17.23px;
    height: 17.23px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    cursor: pointer;
    &__icon {
      color: #8C8DA4;
      font-size: 6px;
    }
  }
  .calendars-container {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: auto;
    height: calc(100% - 160px);
    overflow: auto;
    .calendar-container {
      width: fit-content;
      
      /* width: 380px; */
    }
  }
  .range-text {
    text-align: center;
    font-size: 15.31px;
    color: #3E3DA3;
    margin-bottom: 62.03px;
    font-family: 'Nunito Sans Italic';
  }
  .buttons-container {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    .button-container {
      width: 45%;
    }
  }
`