import CmpSelect from "./shared/outcomesHistoryEditionCmps/cmpSelect";
import CmpYesNoList from "./shared/outcomesHistoryEditionCmps/cmpYesNoList";
import CmpDiagnostics from "./shared/outcomesHistoryEditionCmps/cmpDiagnostics";
import CmpDateSelection from "./shared/outcomesHistoryEditionCmps/cmpDateSelection";
import CmpNumberInput from "./shared/outcomesHistoryEditionCmps/cmpNumberInput";
import CmpTextInput from "./shared/outcomesHistoryEditionCmps/cmpTextInput";
import CmpTextAreaInput from "./shared/outcomesHistoryEditionCmps/cmpTextAreaInput";
import CmpMarkerInput from "./shared/outcomesHistoryEditionCmps/cmpMarkerInput";
import CmpFileInput from "./shared/outcomesHistoryEditionCmps/cmpFileInput";

const outcomesComponentsListHistoryEdition = [
  {
    id: "dropdown",
    key: "dropdown",
    component: (props) => <CmpSelect {...props} />,
  },
  {
    id: "cmpYesNoList",
    key: "cmpYesNoList",
    component: (props) => <CmpYesNoList {...props} />,
  },
  {
    id: "cmpDiagnostics",
    key: "cmpDiagnostics",
    component: (props) => <CmpDiagnostics {...props} />,
  },
  {
    id: "dateInput",
    key: "dateInput",
    component: (props) => <CmpDateSelection {...props} />,
  },
  {
    id: "textAreaInput",
    key: "textAreaInput",
    component: (props) => <CmpTextAreaInput {...props} />,
  },
  {
    id: "textInput",
    key: "textInput",
    component: (props) => <CmpTextInput {...props} />,
  },
  {
    id: "numberInput",
    key: "numberInput",
    component: (props) => <CmpNumberInput {...props} />,
  },
  {
    id: "floatInput",
    key: "floatInput",
    component: (props) => <CmpNumberInput {...props} />,
  },
  {
    id: "markerInput",
    key: "markerInput",
    component: (props) => <CmpMarkerInput {...props} />,
  },
  {
    id: "fileInput",
    key: "fileInput",
    component: (props) => <CmpFileInput {...props} />,
  },
];

export default outcomesComponentsListHistoryEdition;
