import React, { useEffect, useMemo, useState } from "react";
import * as S from  "./ChartsOutcomes.style";
import { useTranslation } from "react-i18next";
import ZaiaSelect from "../../../../../zaiaComponents/zaiaSelect";
import GraphOutcomes from "./graphOutcomes";
import { useQuery, useSubscription } from "@apollo/client";
import { QUERY_GET_CUSTOM_FORMS_ADDITIONAL_MARKERS, QUERY_GET_CUSTOM_FORMS_FIELD_MARKERS, QUERY_GET_CUSTOM_FORM_FIELDS_BY_CUSTOM_FORM_PRINCIPAL, QUERY_GET_USER_CUSTOM_FORM_FIELDS_BY_ID, QUERY_GET_USER_TO_CUSTOM_FORM_HISTORY } from "../../../../../../graphql/queries/CustomForm";
import * as P from "../../../../../../utils/pipes"
import ZaiaReaction from "../../../../../zaiaComponents/zaiaReaction";
import { QUERY_GET_USER_BY_ID } from "../../../../../../graphql/queries/User";
import { SUBSCRIPTION_GET_USER_TO_CUSTOM_FORM_HISTORY } from "../../../../../../graphql/subscriptions/CustomForm";
import OutcomesTabs from "../../outcomesTabs";
import Modal from "../../../../../../utils/modal";
import ModalSelectEventMarker from "./modalSelectEventMarker";

const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;

function ChartsOutcomes({
  userId,
  customFormParent,
  setViewTab,
  viewTab,
  tabs
}) {

  const {data: userData} = useQuery(QUERY_GET_USER_BY_ID, {
    variables: {
      patientId: userId
    }
  });

  const [markerSelected, setMarkerSelected] = useState();
  const [additionalMarkers, setAdditionalMarkers] = useState([]);
  const [eventMarkersModalActive, setEventMarkersModalActive] = useState(false);

  const { i18n: { language }, t } = useTranslation("global");

  const {data: customFormFields} = useQuery(QUERY_GET_CUSTOM_FORM_FIELDS_BY_CUSTOM_FORM_PRINCIPAL,
  {
    variables: {
      customFormParent: customFormParent
    }
  });

  const { data: markerFormField } = useQuery(QUERY_GET_CUSTOM_FORMS_FIELD_MARKERS, {
    variables: { customFormParent: customFormParent },
  })

  const { data: additionalMarkerFormField, loading: additionalMarkerFormFieldLoading, error: additionalMarkerFormFieldError } = useQuery(QUERY_GET_CUSTOM_FORMS_ADDITIONAL_MARKERS, {
    variables: { customFormParent: customFormParent },
  })

  const { data: userToCustomFormsHistories } = useSubscription(SUBSCRIPTION_GET_USER_TO_CUSTOM_FORM_HISTORY, {
    variables: {
      customFormIds:  [markerFormField?.customFormField.find((marker) =>  marker.id === markerSelected)?.customForm.id],
      userID: userId
    }
  })

  const { data: userToCustomFormsHistoryAddMarker } = useSubscription(SUBSCRIPTION_GET_USER_TO_CUSTOM_FORM_HISTORY, {
    variables: {
      customFormIds: additionalMarkers,
      userID: userId
    }
  })  

  const { data: userToCustomFormFields } = useQuery(QUERY_GET_USER_CUSTOM_FORM_FIELDS_BY_ID, {
    variables: {
      customFormField: additionalMarkers,
      userId: userId
    }
  });

  useEffect(() => {
    setMarkerSelected(markerFormField?.customFormField[0]?.id)
  }, [markerFormField])

  
  const lineGraph = useMemo(() => {
    
    const graphInfo =  userToCustomFormsHistories?.userToCustomFormHistory.filter((history) => history.customFormFields.length > 0).map(
      item => {
        const fieldMarker = item.customFormFields.find((field) => field.customFormField.typeFieldValue === "markerInput");
        const fieldDate = item.customFormFields.find((field) => field.customFormField.typeFieldVariant === "outcomeDate");
        const fieldComment = item.customFormFields.find((field) => field.customFormField.typeFieldVariant === "outcomeComment");

        console.log("hola", fieldMarker);
        

        return ({
          info: {
            customFormName: P.dataTranslation(item.customForm.customFormName, language),
            customFormFieldName: P.dataTranslation(fieldMarker?.customFormField.fieldName, language),
            date: fieldDate?.value,
            comment: fieldComment?.value
          },
          data: [fieldDate ? new Date(fieldDate?.value).getTime() :  new Date(item.created_at).getTime(), fieldMarker?.value]
        }
        )
      }
    ).sort(function(a,b){
      return new Date(a.data[0]) - new Date(b.data[0]);
    }) 

    return graphInfo;
    
  }, [userToCustomFormsHistories])

  const markersGraph = useMemo(() => {

    const markerMap = additionalMarkers.map((marker) => {
      return userToCustomFormsHistoryAddMarker?.userToCustomFormHistory.filter((item) => item.customForm.id === marker).map((item) => {
        const fieldsFilter = item.customFormFields.filter(({customFormField: {typeFieldVariant: type}}) => type !== "outcomeDate" && type !== "outcomeComment" && type !== "outcomePrice")
        const fieldDate = item.customFormFields.find((field) => field.customFormField.typeFieldVariant === "outcomeDate");
        const fieldComment = item.customFormFields.find((field) => field.customFormField.typeFieldVariant === "outcomeComment");

        return(
          {
            info: {
              values: fieldsFilter.map((item) => {return ({
                label: P.dataTranslation(item.customFormField.fieldName, language),
                value: item.value
              })}),
              customFormFieldName: P.dataTranslation(item.customForm.customFormName, language),
              color: item.customForm.color,
              date: fieldDate?.value,
              comment: fieldComment?.value
            },
            data: [fieldDate ? new Date(fieldDate?.value).getTime() :  new Date(item.created_at).getTime()]
          }
        )
      })
    })

    return markerMap.filter((item) => item !== undefined)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToCustomFormsHistoryAddMarker, additionalMarkers])

  const removeMarker = (event) => {
    setAdditionalMarkers(additionalMarkers.filter((marker) => marker !== event))
  }

  return(
    <S.ChartsOutcomes>
      { 
        <Modal open={eventMarkersModalActive}>
          <ModalSelectEventMarker
            setModalActive={setEventMarkersModalActive}
            eventMarkersSeleted={additionalMarkers}
            setEventMarkersSeleted={setAdditionalMarkers}
            markersList={additionalMarkerFormField}
            markersListLoading={additionalMarkerFormFieldLoading}
            markersListError={additionalMarkerFormFieldError}
          />
        </Modal>
      }
      <div className="chart-header">
        <div className="chart-header__tabs">
          {/* <OutcomesTabs
            setViewTab={setViewTab}
            viewTab={viewTab}
            tabs={tabs}
          /> */}
        </div>
        {/* <div className="chart-header__title">
          <div className="chart-header__title-circle"></div>
          <div className="chart-header__title-text">
            {
              lineGraph &&
              lineGraph[0]?.info?.customFormName
            } CHART
          </div>
        </div> */}
        <div className="chart-header__selects">
          <div className="select-box">
            <div className="label">
              {/* <div className="label__marker-circle"></div> */}
              <div className="label__text">{t("aiNavigator.outcomes.selectChart")}</div>
            </div>
            <div className="select-container select-container--first">
              <ZaiaSelect
                borderColor={"#AAAADB"}
                value={markerSelected}
                setValue={(e) => setMarkerSelected(e)}
                items={markerFormField?.customFormField.map((marker) => {
                  return {
                    value: marker.id,
                    text: P.dataTranslation(marker.fieldName, language)
                  };
                })}
              />
            </div>
          </div>
          <button className="button-add-event" onClick={() => setEventMarkersModalActive(true)}>{t("aiNavigator.outcomes.addEventMarker")}</button>
          {/* <div className="select-box">
            <div className="label">
              <div className="label__text">Select Event</div>
            </div>
            <div className="select-container">
              <ZaiaSelect
                borderColor={"#AAAADB"}
                setValue={(e) => setAdditionalMarker(e)}
                value={additionalMarker}
                items={additionalMarkerFormField?.customForm.map(
                  (customForm) => {
                    return(
                      {
                        value: customForm.id,
                        text: P.dataTranslation(customForm.customFormName, language) 
                      }
                    )
                  }
                )
              }
                heightList={"300px"}
              />
            </div>
          </div> */}
        </div>
      </div>
      <div className="chart-details">
        <div className="chart-details__title">
          <div className="chart-details__title-circle"></div>
          <div className="chart-details__title-pre-text">{t("aiNavigator.outcomes.graphing").toUpperCase()}</div>
          <div className="chart-details__title-text">
            {
              lineGraph &&
              lineGraph[0]?.info?.customFormName
            }
          </div>
          {/* <div className="chart-details__title-post-text">CHART</div> */}
        </div>
        <div className="chart-details__info">
          <div className="chart-details__left">
            <div className="cards">
              <div className="card card--left">
                <div className="card__title">{t("aiNavigator.outcomes.timeDiagnosis")}</div>
                {
                  lineGraph &&
                  <div className="card__text">{P.getAge(userData?.user[0]?.birthdate, lineGraph[0]?.data[0])} {t("aiNavigator.outcomes.yearsOld")}</div>
                }
                {/* <div className="card__text">Gleason Score <b>7 (3+4)</b></div> */}
                {
                  lineGraph &&
                  <div className="card__text card__text--last">{t("globally.initial")} {lineGraph[0]?.info?.customFormName} <b>{lineGraph[0]?.data[1]} {lineGraph[0]?.info?.customFormFieldName}</b></div>
                }
              </div>
              <div className="card card--right">
                <div className="card__title">{t("aiNavigator.outcomes.currentStatus")}</div>
                <div className="card__text-flex">
                  <div className="card__text">{P.getAge(userData?.user[0]?.birthdate)} {t("aiNavigator.outcomes.yearsOld")}</div>
                  {/* <div className="card__text">Gleason Score <b>7 (3+4)</b></div> */}
                </div>
                {
                  lineGraph &&
                  <div className="card__text">{lineGraph[0]?.info.customFormName}  <b>{lineGraph[lineGraph?.length - 1]?.data[1]} {lineGraph[0]?.info.customFormFieldName}</b></div>
                }
                {/* <div className="card__text card__text--last">PSADT <b>11 months</b></div> */}
              </div>
            </div>
          </div>
          <div className="chart-details__right">
            <div className="events-container">
              <div className="events-list">
                {
                  additionalMarkerFormField?.customForm.map(
                    (customForm) => {
                      if(additionalMarkers?.find((marker) => marker === customForm.id)) {
                        return(
                          <S.Event color={customForm.color ?? "#FF6D2A"}>
                            <div className="circle"></div>
                            <div className="name">{ P.dataTranslation(customForm.customFormName, language)}</div>
                            <div className="close" onClick={() => removeMarker(customForm.id)}>
                              <i className="close__icon icon zaia-icono-cerrar"></i>
                            </div>
                          </S.Event>
                        )
                      } else {
                        return null;
                      }
                    }
                  )
                }
              </div>
              {
                additionalMarkers.length > 0 
                ? <div className="events-options">
                  <div className="events-options__clear" onClick={() => setAdditionalMarkers([])}>{t("aiNavigator.outcomes.clearSelection")}</div>
                  {
                    additionalMarkers.length > 10 &&
                    <div className="events-options__all" onClick={() => setEventMarkersModalActive(true)}>{t("aiNavigator.outcomes.seeAll")}</div>
                  }
                </div>
                : <div className="events-empty">
                  {t("aiNavigator.outcomes.eventMarkers")}
                </div>
              } 
            </div>
          </div>
        </div>
      </div>
      <div className="chart-container">
        {
          lineGraph?.length > 0 
          ? <GraphOutcomes
            lineGraphData={lineGraph?.map((item) => {return(item.data)}) || []}
            markersGraphData={markersGraph?.map((marker) => { return marker?.map((item) => {return(item.data)})}) || []}
            lineGraphInfo={lineGraph?.map((item) => {return(item.info)}) || []}
            markersGraphInfo={markersGraph?.map((marker) => { return marker?.map((item) => {return(item.info)})})}
          />
          :
          <div className="chart-container__reaction">
            <ZaiaReaction
              zaiaImg={logoZaiaEmpty}
              widthImg="250px"
              text={"There's no data on this outcomes"}
              sizeText="20px"
              widthText="300px"
            />
          </div>
        }
      </div>
    </S.ChartsOutcomes>
  );
}

export default ChartsOutcomes;