import styled from "styled-components";

export const CmpFileInput = styled.div`
  /* background-color: var(--white);
  border-radius: 10px;
  padding: 13px 10px 10px 10px; */
  h5 {
    font-family: "Nunito Sans Bold";
  }
  .template-header-item-icon {
    margin-left: 5px;
  }
  .selection-container {
    position: relative;
    margin-top: 10px;
  }
  .chat-container {
    display: flex;
    align-items: center;
    .upload-document {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 5px;
      color: #6C6BCC;
      min-width: 170px;
      background-color: #FFFFFF;
      border-radius: 5px;
      cursor: pointer;  
      font-size: 15px;
      height: 32px;
    }
    .document-container {
      display: flex;
      width: auto;
      align-items: center;
      justify-content: space-between;
      padding: 3px 10px;
      background-color: #DEDEFF;
      color: #1F1F52;
      border-radius: 44px;
      font-size: 15px;
      &__icon-container {
        background-color: #36369B;
        border-radius: 50%;
        padding: 5.86px;
        color: #fff;
        font-size: 6.27px;
        cursor: pointer;
        margin-left: 5px;
      }
    }
    .document-label {
      font-size: 20px;
      color: #707582;
      margin-left: 20px;
    }
  }
`;
