import React, { useEffect } from "react";
import * as S from "./ModalSelectProgram.style"

// Pipes
import * as P from '../../../../../utils/pipes';
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../graphql/queries/Process";

function ModalSelectProgram({ 
  setFilterProgramsActive,
  processesFiltered,
  setProcessesFiltered,
  deleteProcess
}) {

  const { i18n: { language }, t } = useTranslation("global");
  const { data: processData } = useQuery( QUERY_GET_PROCESS_NAMES );  

  const addProcess = (process) => {
    if(processesFiltered.indexOf(process) === -1) {
      setProcessesFiltered([...processesFiltered, process]);
    }
  }

  const addAllProcesses = () => {
    setProcessesFiltered(processData?.process);
  }

  const deleteAllProcesses = () => {
    setProcessesFiltered([]);
  }
  
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setFilterProgramsActive(null);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  return (
    <S.ModalSelectProgram>
      <div className="modal-background" onClick={() => setFilterProgramsActive(null)}>
      </div>
      <div className="modal-container">
        <S.ProcessFiltered>
          <div className="header">
            <div className="header__title">
            {t("filterPanel.programsPanel.title")}

            </div>
            <div className="header__close" onClick={() => setFilterProgramsActive(null)}>
              <i className="header__close-icon icon zaia-icono-cerrar"/>
            </div>
          </div>
          <div className="clear-filters" 
              onClick={() => deleteAllProcesses()}
          >
            <div className="clear-filters__text">{t("globally.clear")}</div>
            <i className="clear-filters__icon icon zaia-i-mop"/>
          </div>
          <div className="programs-list">
            <div className={`program-item ${ processesFiltered.length === processData?.process.length && "program-item--selected"}`}
              onClick={() => addAllProcesses()}
            >
              <i className={`program-item__check-icon icon ${ processesFiltered.length !== processData?.process.length ? "zaia-i-checkbox-off" : "zaia-i-checkbox-on"}`}/>
              <div className="program-item__text">{"All"}</div>
            </div>
            {
              processData?.process.map(
                process => (
                  <div className={`program-item ${ processesFiltered.indexOf(process) !== -1 && "program-item--selected"}`} 
                    onClick={
                      () => {
                        if(processesFiltered.indexOf(process) === -1) {
                          addProcess(process);
                        } else {
                          deleteProcess(process);
                        }
                      }
                    }>
                    <i className={`program-item__check-icon icon ${ processesFiltered.indexOf(process) === -1 ? "zaia-i-checkbox-off" : "zaia-i-checkbox-on"}`}/>
                    <div className="program-item__text">{P.dataTranslation(process.processName, language)}</div>
                  </div>
                )
              )
            }
          </div>
        </S.ProcessFiltered>
      </div>
    </S.ModalSelectProgram>
  );
}

export default ModalSelectProgram;
