import { useState } from 'react';
import axios from 'axios';

export default function useUploadDoc() {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const ACTION_SECRET = process.env.REACT_APP_ACTION_SECRET_FILE_UPLOADING;

  const uploadDoc = (docData, endpoint, bucket) => {
    setLoading(true);
    // return new Promise(function(resolve, reject) {
    //   const xhr = new XMLHttpRequest();
    //   const fd = new FormData();
    //   xhr.open('POST', urlEndpoint, true);
    //   // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
    //   xhr.setRequestHeader('ACTION_SECRET', 'Nhk03CBnel');
    //   xhr.onreadystatechange = () => {
    //     if(xhr.readyState === 4) {
    //       if(xhr.status === 200) {
    //         const response = JSON.parse(xhr.responseText);
    //         setData(response);
    //         resolve(response);
    //       } else {
    //         const errorMessage = xhr.status + ' Error uploading the archive';
    //         setError(errorMessage);
    //         reject(errorMessage);
    //       }
    //       setLoading(false);
    //     }
    //   };
    //   fd.append('file', docData);
    //   xhr.send(fd);
    // });

    const fd = new FormData();
    fd.append('file', docData);

    let customHeaders = {
      'ACTION_SECRET': ACTION_SECRET.toString(),
      'Content-Type': 'multipart/form-data'
    }

    if(bucket) {
      customHeaders = {
        'BUCKET_NAME': bucket.toString(),
        'ACTION_SECRET': ACTION_SECRET.toString(),
        'Content-Type': 'multipart/form-data'
      }
    }

    return new Promise(function(resolve, reject) {

      axios.post(endpoint.toString(), {
        data: docData
      }, {
          headers: customHeaders
      })
      .then(response => {
        console.log("respuestaaaa", response );    
        setLoading(false);
        setData(response);
        resolve(response);
      })
      .catch(error => {
        console.log("errorrrrr", error );    
        setLoading(false);
        setError(error);
        reject(error);
      });
    })
  }
  
  return {
    uploadDoc,
    data,
    loading,
    error,
  }
}