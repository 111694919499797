import React from "react";
import * as S from "./patientBox.style";

import ZaiaImage from "../../../../zaiaComponents/zaiaImage";

// Assets
const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;


function PatientBox({ img, patient, id, medicalProcedure, active }) {
  
  return (
    <S.PatientBox active={active}>
      <ZaiaImage img={img} alt="Zaia user" size={56} defaultImg={defaultUser}/>
      <div className="patient-box-info">
        <h4>{patient}</h4>
        <p>
          {id}
        </p>
        <p>{medicalProcedure}</p>
      </div>
    </S.PatientBox>
  );
}

export default PatientBox;
