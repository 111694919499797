import styled from "styled-components";
import { getHexOpacity } from '../../../utils/pipes';
const waveTop = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-grande-top-violeta.svg`;
const waveBottom = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-pequena-botton-violeta.svg`;

export const EmailVerified = styled.div`
  display: flex;
  height: ${({webView}) => webView ? '100vh' : '100svh'};
  width: 100vw;
  background: url(${waveTop}), url(${waveBottom});
  background-repeat: no-repeat;
  background-color: ${({theme}) => theme.background};
  background-position: top, bottom;
  background-position-y: 0, 150%;
  overflow-y: auto;
  .content-email-verified {
    text-align: center;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: auto;
    .content-email-verified__title {
      color: ${({theme}) => theme.primaryColor};
      font-family: "Nunito Black";
    }
    .zaia-image {
      width: 150px;
      margin: 50px auto 30px;
    }
    .message {
      font-family: 'Open Sans';
      font-size: 13px;
      margin-top: 20px;
      color: ${({theme}) => theme.onBackground};
    }
    .email-container {
      background-color: ${({theme}) => `${theme.primaryColor}${getHexOpacity(0.1)}`};
      padding: 8px;
      border-radius: 10px;
      color: ${({theme}) => theme.primaryColor};
      margin: 25px 0;
      font-family: "Nunito ExtraBold";
    }
    .button-container {
      width: 267px;
      margin: 100px auto 0;
      .button-message {
        margin-top: 20px;
        padding: 0 15px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    background-position-y: 0, 100%;
  }
`;
