import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GlobalStyles } from "./themes";

// Routes
import publicRoutesList from "./routes/publicRoutesList";
import PublicRoute from "./routes/components/PublicRoute";
import privateRoutesList from "./routes/privateRoutesList";

// Components
import Private from "./pages/private"
import NotFound from "./pages/public/foundError"

// SEO
import SEO from "./utils/SEO"

// Supertokens
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react"; 
import { SuperTokensConfig } from "./superTokensConfig";
import ZaiaSplash from "./pages/zaiaComponents/zaiaSplash";

SuperTokens.init(SuperTokensConfig);

function App({ existToken }) {

  if (existToken === null) {
    return (
      <ZaiaSplash />
    )
  }
  
  return (
    existToken !== null
      && <SuperTokensWrapper>
          <GlobalStyles />
          <Router>
            <SEO/>
            <Switch>    
              <Route exact path={privateRoutesList.map(route => route.path)}>
                <Private authed={existToken}/>
              </Route>
  
              <Route exact path={publicRoutesList.map(route => route.path)}>
                <Switch>
                  {publicRoutesList.map(publicRoute => (
                    <PublicRoute 
                      {...publicRoute}
                      exact
                      authed={existToken}
                    />
                  ))}
                </Switch>
              </Route>
              <Route path="*">
                <Switch>
                  <Route component={NotFound} />
                </Switch>
              </Route>
            </Switch>
          </Router>
        </SuperTokensWrapper>
  );
  
}

export default App;
