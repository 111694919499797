import ReactDOM from "react-dom";

function Modal({open, children}) {

  const modalStyles = {
    position: 'absolute', 
    zIndex: 900 , 
    width: '100%', 
    height: '100%',
    display: 'flex',
  }

  if (!open) return null;

  return ReactDOM.createPortal(
    <div style={modalStyles}>
      {children}
    </div>
    , document.getElementById('portal')
  )
}

export default Modal;
