import { useState, useEffect } from "react";

// graphQL
import { useQuery } from "@apollo/client";
import {
  QUERY_GET_USER_BY_PROCESS,
  QUERY_GET_USERS_WITH_PROCESS,
} from "../graphql/queries/User";


const multipleProcessesFilter = (userToProcesses, ids) => {
  return userToProcesses.some((userToProcess) =>
    [].concat(ids).includes(userToProcess.process?.id)
  );
};

const filterPatients = (patients, ids) => {
  let patientListTemp = patients?.filter((patient) => {
    if(patient.userToProcesses.length > 1)
      return multipleProcessesFilter(patient.userToProcesses, ids)
    else
      return [].concat(ids)?.includes(patient.userToProcesses[0]?.process?.id)
  });
  return patientListTemp;
};

export default function usePatientList(processIdFilter, filterProccessIds) {

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useQuery(
    processIdFilter 
      ? QUERY_GET_USER_BY_PROCESS 
      : QUERY_GET_USERS_WITH_PROCESS,
    { variables: 
      processIdFilter 
      ? { 
        processId: processIdFilter
      } 
      : null 
    }
  );

  const [patientsList, setpatientsList] = useState([]);

  useEffect(() => {
    if (filterProccessIds?.length > 0) {
      setpatientsList(filterPatients(usersData?.user, filterProccessIds));
    } else {
      setpatientsList(usersData?.user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersData, filterProccessIds, processIdFilter]);

  const getPatientsByProcessId = (id) => {    
    return filterPatients(usersData?.user, id);
  }  

  return {
    patientList: patientsList,
    patientListLoading: usersLoading,
    patientListError: usersError,
    patientQuantity: patientsList?.length,
    getPatientsByProcessId
  };
}
