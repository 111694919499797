import styled, { keyframes } from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";


const dotFlashing = () => keyframes`
  0% {
    background-color: ${({color}) => color};
  }
  50%,100% {
    background-color: rgba(62, 61, 169, 0.2);
  }
`;


export const ModalPDFReader = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    /* background-color: ${`#090949${getHexOpacity(0.50)}`}; */
    background-color: #FCFCFE;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: var(--white);
    width: 929px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    .header {
      min-height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 40px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.17);
      &__text {
        font-size: 19px;
        font-family: Nunito Sans Bold;
        color: #36369B
      }
      &__close {
        color: #1F1F52;
        font-size: 12px;
        cursor: pointer;
      }
    }
    .footer {
      display: flex;
      min-height: 56px;
      align-items: center;
      justify-content: space-between;
      padding: 0px 40px;
      border-top: 1px solid rgba(0, 0, 0, 0.17);
      &__text {
        color: #707582;
        font-family: "Nunito Sans";
        font-size: 13px;
      }
      &__buttons {
        display: flex;
      }
    }
    .loading-footer {
      height: 56px;
    }
    .content {
      flex-direction: column;
      height: 500px;
      overflow: auto;
      display: flex;
      .upload-document {
        justify-content: center;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__box {
          padding: 40px 0;
          border-radius: 14px;
          border: 1px dashed #6C6BCC;
          cursor: pointer;
          display: flex;
          width: 359px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          padding: 20px;
          &-icon {
            font-size: 47px;
            margin-bottom: 10px;
            color: #6C6BCC;
          }
          &-text {
            color: #3E3DA3;
            font-family: "Nunito Sans";
            font-size: 20px;
            text-align: center;
            line-break: anywhere;
          }
        }
        &__error-format {
          color: #FC0808;
          font-family: "Nunito Sans";
          /* font-size: 14px; */
          margin-top: 10px;
        }
      }
      .document-loading {
        /* padding: 27px 0 43px; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        align-items: center;
        .circle {
          width: 230px;
          height: 230px;
          border-radius: 50%;
          background: #F0F0FF;
          display: flex;
          margin-bottom: 20px;
          .document-icon {
            display: flex;
            margin: auto;
            font-size: 110px;
            border-radius: 66px;
            opacity: 0.45;
            color: #1F1F52;
          }
        }
        .process-text {
          display: flex;
          justify-content: center;
          color: #9292B1;
          margin-bottom: 20px;
        }
        .error-processing {
          align-items: center;
          display: flex;  
          flex-direction: column;
          &__text {
            color: #FC0808;
            font-family: "Nunito Sans";
            /* font-size: 14px; */
            margin: 10px 0 20px;
          }
        }
      }
      .uploaded-document {
        padding: 24px 40px 0 40px;
        &__text {
          color: #707582;
          font-family: "Nunito Sans";
          font-size: 13px;         
          margin-bottom: 13px; 
        }
        &__content {
          position: relative;
          .process-again {
            position: absolute;
            display: flex;
            border: none;
            outline: none;
            cursor: pointer;
            border-radius: 49px;
            background: #F9F9FE;
            padding: 10px;
            color: #6C6BCC;
            font-family: "Nunito Sans";
            font-size: 14px;
            right: 40px;
            &__icon {
              margin-right: 10px;
            }
          }
          .document-info-table {
            width: 100%;
            thead {
              th {
                color: #707582;
                font-family: "Nunito Sans Light";
                font-weight: 300;
                font-size: 17px;
                padding: 10px 20px;
                text-align: start;
              }
            }
            tbody {
              td {
                padding-top: 10px;
                position: relative;
                width: 50%;
              }
            }
          }
        }
      }
    }
    .footer__buttons {
      margin: 0 20px;
      .button {
        margin-left: 10px;
      }
    }
    .button {
      display: flex;
      align-items: center;
      min-height: 36px;
      padding: 0px 37px;
      background-color: #6C6BCC;
      color: #FFF;
      border: none;
      outline: none;
      border-radius: 75px;
      cursor: pointer;  
      justify-content: center;
      color: #FFF;
      font-family: Nunito Sans;
      font-size: 14px;
      text-align: center;
      &__icon {
        font-size: 14px;
        margin-right: 10px;
        fill: #FFF;
        opacity: 0.4;
      }
      &__icon-block {
        font-size: 20px;
        /* margin-right: 10px; */
      }
      &--success {
        background-color: #00A600;
      }
      &--disabled {
        color: #3E3DA3;
        background-color: #EEEDF4;
      }
    }
  }

  @media only screen and (max-width: 1124px) {
    .modal-container {
      width: 100%;	
      height: 100%;
      max-height: none;
      .content {
        height: 100%;
        .upload-document{
          padding: 0 20px;
          &__box {
            width: auto;
          }
        }
      }
      .footer {
        &__text {
          margin: 10px 0;
        }
        height: auto;
        min-height: auto;
        padding: 20px 20px;
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
`;

export const TableItem = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
  .input-container {
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
    width: 100%;
    .table-text {
      resize: none;
      width: stretch;
      padding: 10px 20px 10px 20px; 
      border: none;
      color: var(--TEXT-II, #3E3E5B);
      font-family: "Nunito Sans Bold";
      font-size: 17px;
      outline: none;
      border-bottom: ${({color}) => `1px solid ${color ? color : "#3E3E5B"}`};
      margin-bottom: 20px;
    }
    .input-indicator {
      position: absolute;
      right: 0;
      bottom: 0;
      text-align: right;
      font-family: "Nunito Sans";
      font-size: 11px;
      color: ${({color}) => color};
    }
  }
  .table-separator {
    height: 10px;
    border-right: 0.5px solid #3E3E5B;
    margin: 0 10px;
    bottom: 15px;
  }
`

export const ZaiaLoadingPoints = styled.div`
  padding: 6.5px 8px;
  align-self: flex-end;
  .dot-flashing {
    position: relative;
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: ${({color}) => color};
    color: ${({color}) => color};
    animation: ${dotFlashing()} 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -6px;
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: ${({color}) => color};
    color: ${({color}) => color};
    animation: ${dotFlashing()} 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 6px;
    width: 3px;
    height: 3px;
    border-radius: 3px;
    background-color: ${({color}) => color};
    color: ${({color}) => color};
    animation: ${dotFlashing()} 1s infinite alternate;
    animation-delay: 1s;
  }  
`
