import styled from "styled-components";

export const NoteComplete = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #FFFFFF;
  border-radius: 14px;
  .header {
    &__back-button {
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #D2D2FFA6;
      .back-button {
        padding: 10px 20px;
        display: flex;
        background-color: #3E3DA3;
        cursor: pointer;
        outline: none;
        border-radius: 20px;
        color: #FFFFFF;
        border: none;
        &__icon {
          font-size: 16px;
          margin-right: 5px;
        }
        &__text {
          font-family: Nunito Sans Bold;
          font-size: 13px;
        }
      }
    }
    &__option {
      display: flex;
      background-color: #F0F0FF;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      cursor: pointer;
      &-icon {
        color: #6C6BCC;
        font-size: 12px;
        margin: auto;
      }
    }
    &__note-info {
      border-bottom: ${({ maxView }) => (maxView ? "" : "1px solid #D2D2FFA6")};
      padding: 20px;
      display: flex;
      justify-content: space-between;
      .program-info {
        font-family: Nunito Sans;
        font-size: 26px;
      }
      .written-by {
        margin-top: 5px;
        font-family: Nunito Sans;
        font-size: 13px;
        color: #3E3E5B;
        display: flex;
        &__label {
          margin-right: 5px;
        }
        &__name {
          font-family: Nunito Sans Light;
        }
      }
      .note-date {
        margin-top: 5px;
        font-family: Nunito Sans;
        font-size: 13px;
        color: #3E3E5B;
        display: flex;
        &__label {
          margin-right: 5px;
        }
        &__date {
          font-family: Nunito Sans Bold;
          margin-right: 5px;
        }
        &__time {
          margin-right: 5px;
        }
      }
    }
  }
  .note {
    padding: ${({ maxView }) => (maxView ? "0px 20px 20px 20px" : "20px")};
    height: ${({ maxView }) => (maxView ? "calc(100% - 106px)" : "calc(100% - 221px)")};
    overflow: auto;
    &__label {
      font-family: Nunito Sans;
      font-size: 16px;
      color: #707582;
      margin-bottom: 5px;
    }
    &__container {
      font-family: Nunito Sans;
      font-size: 19px;
      color: #1F1F52;
    }
  }
`

export const NoteOptions = styled.div`
  width: 121px;
  padding: 10px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 12px 0px #23229D33;
  .note-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &__icon {
      margin-right: 10px;
      color: #3E3DA3;
      font-size: 1opx;
    }
    &__text {
      font-family: Nunito Sans;
      font-size: 17px;
      color: #3E3DA3;
    }
  }
`