import styled from "styled-components";
import { getHexOpacity } from "../../../../../../../utils/pipes";

export const CmpSliderHorizontalOptions = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  padding: 13px 10px 10px 10px;
  h5 {
    font-family: "Open Sans Bold";
  }
  .boxes-container {
    margin-top: 8px;
    display: flex;
    position: relative;
    z-index: 1;
    &:hover .alice-carousel__prev-btn, &:hover .alice-carousel__next-btn{
      opacity: 1;
      transition: 1s;
    }
  }
  .big-info-card{
    padding: 20px;
    border-radius: 10px;    
    box-shadow: 0px 3px 6px var(--box-shadow);
    h4 {
      font-size: 15px;
      font-family: "Open Sans Semibold";
      color: var(--blue-color);
    }
    ul {
      margin-top: 10px;
      padding-left: 18px;
    }
  }
  .alice-carousel__prev-btn {
    position: absolute;
    height: 100%;
    width: 30px;
    top: 0;
    right: left;
    padding: 0;
    opacity: 0;
    transition: 0.5s;
  }
  .alice-carousel__next-btn {
    opacity: 0;;
    position: absolute;
    height: 100%;
    width: 30px;
    top: 0;
    right: 0;
    padding: 0;
    transition: 0.5s;
  }
`;

export const SliderBox = styled.div`
  padding: 10px;
  max-width: 150px;
  .slider-box {
    border-radius: 10px;
    background-color: ${({active}) => active ? 'var(--blue-color)' : 'var(--no-notification-background)'};
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 180px;
    font-family: "Open Sans Semibold";
    color: ${({active}) => active ? 'var(--white)' : 'var(--blue-dark-color)'};
    text-align: center;
    margin: 0 10px 0 10px;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    &-title {
      font-size: 15px;
      margin: 5px 0 28px;
    }
    &-subtitle {
      font-size: 13px;
      margin-bottom: 15px;
    }
    &-code {
      font-size: 16px;
    }
  }
`

export const CarouselButton = styled.button`
  height: 100%;
  width: 100%;
  display: ${({disabled}) => disabled ? 'none' : 'block'};
  cursor: pointer;
  border: none;
  background-color: ${({theme}) => `${theme.onBackground}${theme.onBackground[7] ? '' : getHexOpacity(0.2)}`};
  .icon {
    color: ${({theme}) => theme.background}
  }
  left: ${({position}) => position === 'prev' ? 0 : '' };
  right: ${({position}) => position === 'prev' ? '' : 0 };
  border-top-left-radius: ${({position}) => position === 'prev' ? '13px' : '' };
  border-bottom-left-radius: ${({position}) => position === 'prev' ? '13px' : '' };
  border-top-right-radius: ${({position}) => position === 'prev' ? '' : '13px' };
  border-bottom-right-radius: ${({position}) => position === 'prev' ? '' : '13px' };
` 