import { useState, useEffect, useCallback } from 'react';

export default function useSize(myRef) {
    const [size, setSize] = useState({ width: 0, height: 0 });

    const handleResize = useCallback(entries => {
        const { width, height } = entries[0].contentRect;
        setSize({ width, height });
    }, []);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(handleResize);
        if (myRef.current) {
            resizeObserver.observe(myRef.current);

            // Actualizar el tamaño una vez al inicio
            const { width, height } = myRef.current.getBoundingClientRect();
            setSize({ width, height });
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [myRef, handleResize]);

    return size;
}