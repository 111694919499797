import { gql } from "@apollo/client";

export const SUSCRIPTION_SURVEYS_BY_ID_PROCESS = gql`
  query SUSCRIPTION_CUSTOM_FORM_BY_IDS(
    $processId: Int!
  ) {
    customForm(offset: 1, order_by: {id: asc}, where: {processToCustomForms: {processId: {_eq: $processId}}}) {
      id
      customFormName
      descript
      formGroups(order_by: {order: asc}) {
        id
      }
    }
  }
`

export const SUSCRIPTION_ALL_SURVEYS_BY_ID_PROCESS = gql`
  query SUSCRIPTION_CUSTOM_FORM_BY_IDS(
    $processId: Int!
  ) {
    customForm(offset: 1, order_by: {id: asc}, where: {processToCustomForms: {processId: {_eq: $processId}}}) {
      id
      customFormName
      descript
      DoctorToUserProcessBoards {
        customFormId
        doctorId
        id
        typeView
        userToProcessId
      }
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        DoctorToUserProcessBoards {
          customFormId
          doctorId
          id
          typeView
          userToProcessId
        }
        userToProcesses {
          userId
          descript
          created_at
        }
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
          userToCustomFormFields(order_by: {created_at: desc}) {
            value
            id
            key
            updated_at
            created_at
            created_at
            doctor {
              name
              lastname
            }
            customFormField {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              customFormTypeField {
                isOptions
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
                value
              }
            }
          }
        }
      }
    }
  }
`


export const SUSCRIPTION_SURVEY_BY_ID = gql`
  query SUSCRIPTION_SURVEY_BY_ID($userId: uuid, $customFormId: Int!) {
    customForm(where: {id: { _eq: $customFormId }}) {
      id
      customFormName
      descript
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        DoctorToUserProcessBoards {
          customFormId
          doctorId
          id
          typeView
          userToProcessId
        }
        userToProcesses(where: {userId: {_eq: $userId}}) {
          userId
          descript
          created_at
        }
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
          userToCustomFormFields(where: {userId: {_eq: $userId}}, order_by: {created_at: desc}) {
            value
            id
            key
            updated_at
            created_at
            created_at
            doctor {
              name
              lastname
            }
            customFormField {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              customFormTypeField {
                isOptions
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
                value
              }
            }
          }
        }
      }
    }
  }
`

export const SUBSCRIPTION_GET_CUSTOM_FORM_BY_PROCESS = gql`
  subscription SUBSCRIPTION_GET_CUSTOM_FORM_BY_PROCESS($userId: uuid, $userToProcessId: uuid!, $processId: Int) {
    process(where: {id: {_eq: $processId}}) {
      processName
      id
      icon
      processToCustomForms {
        customFormId
        icon
        id
        customForm {
          id
          customFormName
          descript
          userCustomFormHistories(order_by: {created_at: desc}, where: {userId: {_eq: $userId}}) {
            created_at
            customFormId
            doctorId
            id
            updated_at
            userId
            customFormFields {
              value
              userId
              updated_at
              key
              id
              doctorId
              customFormHistoryId
              customFormFieldId
              created_at
              customFormField {
                id
                customFormFieldParentId
                descript
                fieldName
                typeFieldValue
              }
            }
          }
          formGroups(order_by: {order: asc}) {
            id
            customFormName
            descript
            DoctorToUserProcessBoards(where: {userToProcessId: {_eq: $userToProcessId}}) {
              customFormId
              doctorId
              id
              typeView
              userToProcessId
            }
            userToProcesses(where: {userId: {_eq: $userId}}) {
              userId
              descript
              created_at
            }
            userToProcessHistories(where: {userToProcessId: {_eq: $userToProcessId}}, order_by: {created_at: desc}) {
              created_at
              descript
              id
              customFormFieldChanges
            }
            customFormFields(order_by: {order: asc}) {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              userToCustomFormFields(order_by: {created_at: desc}) {
                created_at
                doctorId
                customFormHistoryId
                customFormFieldId
                id
                key
                updated_at
                userId
                value
              }
              customFormTypeField {
                isOptions
                value
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
              }
            }
          }
        }
      }
    }
  }
`