import { gql } from "@apollo/client";

export const QUERY_GET_CUSTOM_FORM_BY_IDS = gql`
  query QUERY_GET_CUSTOM_FORM_BY_IDS(
    $customFormIds: [bigint!]!
  ) {
    customForm(where: {id: {_in: $customFormIds}}) {
      id
      customFormName
      descript
    }
  }
`

export const QUERY_GET_CUSTOM_FORM_BY_ID = gql`
  query QUERY_GET_CUSTOM_FORM_BY_ID($userId: uuid) {
    customForm {
      id
      customFormName
      descript
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        DoctorToUserProcessBoards {
          customFormId
          doctorId
          id
          typeView
          userToProcessId
        }
        userToProcesses(where: {userId: {_eq: $userId}}) {
          userId
          descript
          created_at
        }
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
          userToCustomFormFields(where: {userId: {_eq: $userId}}, order_by: {created_at: desc}) {
            value
            id
            key
            updated_at
            created_at
            created_at
            doctor {
              name
              lastname
            }
            customFormField {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              customFormTypeField {
                isOptions
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
                value
              }
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_CUSTOM_FORMS_BY_ID = gql`
  query QUERY_GET_CUSTOM_FORMS_BY_ID(
    $customFormId: bigint!
  ) {
    customForm_by_pk(id: $customFormId) {
      id
      customFormName
      descript
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
        }
      }
    }
  }
`
export const QUERY_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES = gql`
  query QUERY_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES( 
    $userId: uuid
    $customFormId: bigint!
  ) {
    customForm_by_pk(id: $customFormId) {
      id
      customFormName
      descript
      userCustomFormHistories(order_by: {created_at: desc}, where: {userId: {_eq: $userId}}) {
        created_at
        customFormId
        doctorId
        updated_at
        userId
        savedByDoctor
      }
    }
  }
`

export const QUERY_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES_DATES = gql`
  query QUERY_GET_CUSTOM_FORMS_BY_ID_AND_HISTORIES_DATES( 
    $userId: uuid
    $customFormId: bigint!
    $userToProcessId: uuid!
    $initialDate: timestamptz
    $finalDate: timestamptz
  ) {
    customForm_by_pk(id: $customFormId) {
      id
      customFormName
      descript
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        DoctorToUserProcessBoards(where: {userToProcessId: {_eq: $userToProcessId}}) {
          customFormId
          doctorId
          id
          typeView
          userToProcessId
        }
        userToProcesses(where: {userId: {_eq: $userId}}) {
          userId
          descript
          created_at
        }
        userToProcessHistories(where: {userToProcessId: {_eq: $userToProcessId}}, order_by: {created_at: desc}) {
          created_at
          descript
          id
          customFormFieldChanges
        }
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
          userToCustomFormFields(
            where: {
              userId: {_eq: $userId}, 
              created_at: {_gte: $initialDate, _lte: $finalDate}
            }, 
            order_by: {created_at: desc}
          ) {
            value
            id
            key
            updated_at
            created_at
            created_at
            doctor {
              name
              lastname
            }
            customFormField {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              customFormTypeField {
                isOptions
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
                value
              }
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_CUSTOM_FORMS_BY_IDS = gql`
  query DashBoardProstate( 
    $userId: uuid
    $customFormId: bigint!
    $userToProcessId: uuid!
  ) {
    customForm_by_pk(id: $customFormId) {
      id
      customFormName
      descript
      formGroups(order_by: {order: asc}) {
        id
        customFormName
        descript
        DoctorToUserProcessBoards(where: {userToProcessId: {_eq: $userToProcessId}}) {
          customFormId
          doctorId
          id
          typeView
          userToProcessId
        }
        userToProcesses(where: {userId: {_eq: $userId}}) {
          userId
          descript
          created_at
        }
        userToProcessHistories(where: {userToProcessId: {_eq: $userToProcessId}}, order_by: {created_at: desc}) {
          created_at
          descript
          id
          customFormFieldChanges
        }
        customFormFields(order_by: {order: asc}) {
          id
          customFormFieldParentId
          descript
          fieldName
          typeFieldValue
          customFormTypeField {
            isOptions
            fieldOptions {
              id
              key
              names
              parentKey
              payload
            }
            value
          }
          userToCustomFormFields(where: {_or: {userId: {_eq: $userId}}}, order_by: {created_at: desc}) {
            value
            id
            key
            updated_at
            created_at
            created_at
            doctor {
              name
              lastname
            }
            customFormField {
              id
              customFormFieldParentId
              descript
              fieldName
              typeFieldValue
              customFormTypeField {
                isOptions
                fieldOptions {
                  id
                  key
                  names
                  parentKey
                  payload
                }
                value
              }
            }
          }
        }
      }
    }
  }
`
export const QUERY_GET_CUSTOM_FORMS_HISTORY_BY_ID = gql`
  query QUERY_GET_CUSTOM_FORMS_HISTORY_BY_ID($customFormId: bigint, $userId: uuid) {
    userToCustomFormField(where: {customFormField: {customForm: {id: {_eq: $customFormId}}}, userId: {_eq: $userId}}, order_by: {customFormField: {order: asc}}) {
      created_at
      id
      updated_at
      value
      key
      customFormHistoryId
      doctor {
        name
        lastname
      }
      user {
        email
        id
        lastname
        name
      }
      customFormField {
        descript
        fieldName
        id
        typeFieldValue
        order
        customForm {
          descript
          id
          customFormName
          customFormParentId
        }
      }
    }
  }
`

export const QUERY_GET_CUSTOM_FORMS_FIELD_MARKERS = gql`
  query QUERY_GET_CUSTOM_FORMS_FIELD_MARKERS($customFormParent: bigint) {
    customFormField(where: {typeFieldValue: {_eq: "markerInput"}, customForm: {customFormParentId: {_eq: $customFormParent}}}) {
      fieldName
      id
      descript
      typeFieldProcess
      typeFieldValue
      customForm {
        id
      }
    }
  }
`

export const QUERY_GET_CUSTOM_FORMS_ADDITIONAL_MARKERS = gql`
  query QUERY_GET_CUSTOM_FORMS_ADDITIONAL_MARKERS($customFormParent: bigint) {
    customForm(where: {customFormFields: {typeFieldValue: {_neq: "markerInput"}, _and: {typeFieldValue: {_neq: "dateInput"}}}, customFormParentId: {_eq: $customFormParent}}) {
      id
      customFormName
      customFormParentId
      descript
      color
      customFormFields {
        fieldName
        typeFieldValue
      }
    }
  }
`

export const QUERY_GET_USER_TO_CUSTOM_FORM_HISTORY = gql`
  query QUERY_GET_USER_TO_CUSTOM_FORM_HISTORY(
    $customFormID: bigint 
    $userID: uuid
  ) {
    userToCustomFormHistory(where: {customFormId: {_eq: $customFormID}, userId: {_eq: $userID}}) {
      created_at
      customFormId
      doctorId
      id
      updated_at
      userId
      customForm {
        id
        customFormName
      }
      customFormFields {
        created_at
        customFormFieldId
        customFormHistoryId
        doctorId
        id
        key
        updated_at
        userId
        value
        customFormField {
          fieldName
          id
          descript
          typeFieldProcess
          typeFieldValue
          customForm {
            id
            customFormName
          }
        }
      }
    }
  }
`

export const QUERY_GET_CUSTOM_FORM_FIELDS_BY_CUSTOM_FORM_PRINCIPAL = gql`
  query QUERY_GET_CUSTOM_FORM_FIELDS_BY_CUSTOM_FORM_PRINCIPAL($customFormParent: bigint) {
    customFormField(where: {typeFieldValue: {_neq: "dateInput"}, _and: {typeFieldValue: {_neq: "markerInput"}}, customForm: {customFormParentId: {_eq: $customFormParent}}}) {
      fieldName
      descript
      id
      customForm {
        customFormName
        id
      }
    }
  }
`

export const QUERY_GET_USER_CUSTOM_FORM_FIELDS_BY_ID = gql`
  query QUERY_GET_USER_CUSTOM_FORM_FIELDS_BY_ID($customFormField: uuid, $userId: uuid) {
    userToCustomFormField(where: {customFormField: {id: {_eq: $customFormField}}, userId: {_eq: $userId}}) {
      created_at
      doctorId
      id
      key
      updated_at
      userId
      value
      customFormField {
        fieldName
        id
        descript
        typeFieldProcess
        typeFieldValue
        customForm {
          id
        }
      }
    }
  }
`