import styled from "styled-components";
import { getHexOpacity } from "../../../utils/pipes";

export const Alerts = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: rgb(243, 243, 252);
  .header-patient-container {
    /* padding: 20px 30px 25px; */
  }
  .alerts-container {
    height: calc(100% - 56.44px);
    display: flex;
    position: relative;
    z-index: 200;
    width: 100%;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    &__box {
      overflow: auto;
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
    }
  }
  .filters {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px 20px;
    border-bottom: 0.5px solid #23229D40;
    .filter {
      margin-left: 10px;
      &--program-status {
        &__label {
          text-align: end;
        }
        &__space {
          display: flex;
          justify-content: end;
        }
      }
      &__label {
        color: #7D7C8B;
        font-family: Nunito Sans;
        font-size: 13px;
        &--selector-all {
          color: #36369B;
        }
      }
      .patient-selector-all {
        background-color: #F0F0FF;
        height: 34px;
        padding: 5px 15px 5px 15px;
        border-radius: 31px;
        align-items: center;
        display: flex;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: Nunito Sans;
        font-size: 17px;
        color: #36369B;
        border: 1px solid #3E3DA3;
        box-shadow: 0px 0px 8.1px 0px #DADAF6;
        &__icon {
          font-size: 20px;
        }
        &__text {
          margin-left: 10px ;
        }
      }
      .patient-order {
        background-color: #F0F0FF;
        width: 206px;
        height: 34px;
        padding: 5px 15px 5px 15px;
        border-radius: 31px;
        align-items: center;
        display: flex;
        border: none;
        outline: none;
        cursor: pointer;
        color: #36369B;
        &--actived {
          background-color: #6C6BCC;
          color: #fff;
        }
        &__icon {
          font-size: 18px;
          color: #3E3DA3;
          opacity: 0.4;
          margin-right: 14.5px;
          &--actived {
            color: #F6F6FF;
          }
        }
        &__text {
          font-family: Nunito Sans;
          font-size: 17px;
          white-space: nowrap;
        }
      }
      .date-order {
        background-color: #F0F0FF;
        width: 206px;
        height: 34px;
        padding: 5px 15px 5px 15px;
        border-radius: 31px;
        align-items: center;
        display: flex;
        border: none;
        outline: none;
        cursor: pointer;
        font-family: Nunito Sans;
        font-size: 17px;
        color: #36369B;
      }
      .select-filter {
        background-color: #F0F0FF;
        width: 206px;
        height: 34px;
        padding: 5px 15px 5px 15px;
        border-radius: 31px;
        align-items: center;
        display: flex;
        border: none;
        outline: none;
        cursor: pointer;
        justify-content: space-between;
        color: #36369B;
        font-family: Nunito Sans;
        font-size: 17px;
        &--management-status {
          width: 104px;
        }
        &__text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .status-all {
        display: flex;
        align-items: center;
        height: 34px;
        &__icon {
          font-size: 12px;
        }
        &__text {
          font-size: 17px;
        }
      }
      &__program {
        
      }
    }
    &__left {
      display: flex;
    }
    &__right {
      display: flex;
    }	
  }



  
  .dashboard__container {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .actions-list {
    display: flex;
    height: 100%;
    box-sizing: border-box;
  }
  .dashboard__container-right{
    position: relative;
    width: calc(100% - 282px);
    .dashboard__container-right-modal {
      background-color: ${({theme}) => `${theme.background}${getHexOpacity(0.8)}`};
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      display: flex;
      .box-container {
        margin: auto;
        width: 49%;
        display: flex;
        flex-direction: column;
        min-width: 400px;
        justify-content: center;
        .buttons-box-container {
          .buttons-box-patient {
            display: flex;
            justify-content: space-between;
            .button-box-patient {
              width: 48%;
            }
            &--center {
              justify-content: center;
            }
          }
          .phone-number-container {
            /* height: 43px; */
            width: 212px;
            position: relative;
            margin: 30px auto 0;
            .contact-info {
              background-color: ${
                ({
                  theme,
                }) => 
                  theme.background
              };
              border-radius: 10px;
              box-shadow: ${
                ({theme}) => 
                `3px 3px 5px ${theme.blackShadow}29`
              };
              &-box {
                padding: 10px 15px 7px 15px;
                &--emergency{ 
                  border-top: solid 1px ${({theme}) => theme.lineSeparation};
                  padding: 7px 15px 10px 15px;
                }
                .label {
                  font-size: 12px;
                  font-family: 'Nunito Bold';  
                  color: ${({theme}) => theme.primaryColor};
                  &--emergency{ 
                    color: ${({theme}) => theme.primaryVariantColor};
                  }
                }
                .name {
                  font-family: 'Open Sans';
                  font-size: 15px;  
                  color: ${({theme}) => theme.primaryVariantColor};
                }
                .number {
                  font-family: 'Nunito ExtraBold';  
                  font-size: 18px;
                  color: ${({theme}) => theme.primaryColor};
                  &--emergency{ 
                    color: ${({theme}) => theme.primaryVariantColor};
                  }
                }
              }
            }
          }
          .buttons-box-close {
            width: 35%;
            margin: auto;
          }
        }
      }
    }
    .dashboard__container-right-content{
      padding: 36px 53px 0 62px;
      filter: ${({selectedBox}) => selectedBox ? "blur(4px)" : "none"};
      height: 100%;
      box-sizing: border-box;
      .text-container{
        margin-bottom: 61px;
        margin-left: 5px;
        &__title {
          font-size: 32.25px;
          font-family: 'Nunito Sans Bold';
          color: #3E3DA3;
        }
        &__subtitle {
          /* display: flex; */
          font-size: 18px;
          color: #68688D;
          b {
            margin-left: 5px;
          }
        }
      }
      .severity-filtered {
        margin-bottom: 20px;
        display: flex;
        font-size: 16px;
        color: #68688D;
        width: fit-content;
        align-items: center;
        height: 17.23px;
        &__close-button {
          margin-left: 10px;
          background-color: #E6E6E6;
          border-radius: 50%;
          width: 17.23px;
          height: 17.23px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          cursor: pointer;
          &-icon {
            color: #8C8DA4;
            font-size: 6px;
          }
        }
      }
      .dashboard__notifications {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        align-items: center;
      }
      .dashboard__filters {
        display: flex;
        position: relative;
        width: calc(100% - 150px);
        overflow: auto;
        mask-image: linear-gradient(90deg, #000 90%, transparent);
        ::-webkit-scrollbar {
          display: none;
        }
        /* flex-wrap: wrap; */
        .notification-container {
          display: flex;
          padding: 2px 0;
        }
        .notification-list-end {
          padding: 0px 10px;
          background-color: transparent
        }
      }
      .dashboard__filter-by {
        display: none;
        width: 140px;
        margin-left: 10px;
        .dropdown-activer {
          color: ${({theme}) => theme.onBackgroundSoft};
          display: flex;
          align-items: center;
          user-select: none; 
          white-space: nowrap;
          p {
            font-family: 'Nunito Semibold';
            font-size: 16px;
            /* color: ${({theme}) => theme.onBackgroundSoft} */
          }
          i {
            font-size: 18px;
            display: flex;
            margin-left: 5px;
          }
        }
      }
      .dashboard__filter-programs {
        margin-left: 10px;
        width: 140px;
      }
      .dashboard__boxes-container {
        display: flex;
        height: 100%;
        height: calc(100% - 194.62px);
        overflow: hidden;
        .dashboard__boxes-area {
          overflow: hidden;
          width: 100%;
          display: flex;
        }
        .dashboard__boxes-filter {
          width: 60px;
          padding-left: 20px;
        }
      }
    }
  }

  .dashboard__input-patient-calendar {
    display: none;
    width: 100%;
    &-input-container {
      display: flex; 
      padding: 10px 0px 30px;
      width: calc(100% - 25px);
    }
    &-trigger-button {
      height: 40px;
      width: 45px;
      margin-top: 7px;
      border-radius: 10px;
      margin-left: 25px;
      background-color: ${({theme}) => theme.primarySectionCalendar};
      display: flex;
      cursor: pointer;
      &-icon {
        margin: auto;
        color: ${({theme}) => theme.onPrimaryColor};
        display: flex;
        font-size: 20px;
      }
    }
  }


  .dashboard__container-left {
    position: relative;
    /* border-left: 1px solid ${({theme}) => theme.lineSeparation}; */
    box-sizing: border-box;
    height: 100%;
    z-index: 100;
  }

  .invite-modal {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 1024px) {
    .dashboard__container-right-modal {
      .box-container {
        width: 500px !important;
      }
    }
    .dashboard__container-right {
      width: 100% !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .dashboard__container-right{
      width: 100%;
    }
    .dashboard__container-right {
      display: ${({rightSideActive}) => rightSideActive ? "block" : "none"};
      width: auto;
      position: absolute;
      border-left: 1px solid ${({theme}) => `${theme.background}`};
      right: 0;
      box-sizing: border-box;
      height: 100%;
      background-color: white;
      box-shadow: ${
            ({theme}) => 
            `4px -13px 16px ${theme.blackShadow}29`};
      box-sizing: border-box;
      /* border-top-left-radius: 30px;
      border-bottom-left-radius: 30px; */
    }
    .dashboard__input-patient-calendar {
      display: flex;
    }
    .dashboard__boxes-filter {
      display: none;
    }
    .dashboard__boxes-area {
      width: 100% !important;
    }
    .dashboard__filters {
      display: none !important;
    }
    .dashboard__filter-programs {
      display: none; 
    } 
    .dashboard__filter-by {
      display: block !important;
    }
    .dashboard__boxes-container {
      height: calc(100% - 150px - 67px) !important;
    }
  }

  @media only screen and (max-width: 500px) {
    .dashboard__container-left-modal {
      .box-container {
        margin: auto 15px !important;
        min-width: initial !important;
      }
    }
  }
  
  @media only screen and (max-width: 375px) {
    .buttons-box-patient {
      display: block !important;
    }
    .button-box-patient {
      width: 100% !important;
    }
  }
`;


export const LevelSelector = styled.div`
  width: 297px;
  height: 225px;
  padding: 10px 10px 0px 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 12px 0px #23229D33;
  background-color: #fff;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 14px;
    &__label {
      color: #707582;
    }
  }
  .list-programs {
    height: calc(100% - 29px);
    overflow: auto;
    .program-item {
      background-color: #F6F6FF;
      padding: 10px;
      border-radius: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      margin-bottom: 10px;
      &--actived {
        background-color: #C6C5FF;
        font-family: Nunito Sans Bold;
      }
      &__name {
        font-size: 14px;
        color: #36369B;
      }
      &__quantity {
        display: flex;
        padding: 0px 10px;
        border-radius: 20px;
        background-color: #8A8AC3;
        align-items: center;
      }
      &__icon {
        font-size: 12px;
        color: #F6F6FF;
        margin-right: 5px;
      }
      &__number {
        font-size: 14px;
        color: #F6F6FF;
      }
    }
  }

`;

export default Alerts;
