import { gql } from "@apollo/client";

export const MUTATION_ENRROLL_PATIENCE = gql`
  mutation MUTATION_ENRROLL_PATIENCE(
    $descript: String!
    $lastname: String!
    $processId: Int!  
    $stageId: String!
    $email: String
    $phone_number: String
  ) { 
  enroll_user_to_process(
    descript: $descript, 
    lastname: $lastname, 
    process_id: $processId, 
    stage_id: $stageId, 
    email: $email,
    phone_number: $phone_number,
  ) {
      status
      required_parameter_failure   
      optinal_parameter_failure
    }
  }
`;

export const MUTATION_EDIT_USER = gql`
  mutation MUTATION_EDIT_USER(
    $userEdited: user_set_input 
    $userId: uuid!
  ) {
    update_user_by_pk(pk_columns: {id: $userId}, _set: $userEdited) {
      birthdate
      createdAt
      email
      gender
      id
      lastname
      name
      nameCarer
    }
  }
`

export const MUTATION_UPDATE_PATIENT_IMG = gql`
  mutation MUTATION_UPDATE_PATIENT_IMG(
    $pictureInBase64: String!
    $userId: String!
  ) {
    uploadPatientPicture(pictureInBase64: $pictureInBase64, userId: $userId) {
      pictureUrl
      status
      error_message
    }
  }
`