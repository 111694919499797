import React from "react";
import * as S from './NotFound.style';
import { useTranslation } from "react-i18next";
import { Routes } from "../../../utils/constants";

// components
import ZaiaButton from "../../zaiaComponents/zaiaButton";
import { Link } from "react-router-dom";
const { AINAVIGATOR } = Routes;

function NotFound() {
  const [t] = useTranslation("global");

  return (
    <S.NotFound>
      <div className="left">
        <div className="container-message">
          <div className="title">{t("notFound.title")}</div>
          <div className="title-underline"></div>
          <div className="descript">{t("notFound.sorry")}</div>
          <div className="recommed">{t("notFound.recommend")}</div>
          <div className="button">
            <Link to={'/'}>
              <ZaiaButton
                title={t("notFound.button")}
                shadow={true}
                />
            </Link>
          </div>
        </div>
      </div>
    </S.NotFound>
  )
}

export default NotFound;