import React, { useEffect } from "react";
import * as S from "./ModalRangeCalendar.style"

// pipes
import RangeDatesSelectorPanel from "../../rangeDatesSelectorPanel";

function ModalRangeCalendar({ 
  setSeeDoubleCalendarRangeActive,
  setInitialDateRange,
  setFinishDateRange,
  initialDateRange,
  finishDateRange,
}) {
  
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setSeeDoubleCalendarRangeActive(null);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  return (
    <S.ModalRangeCalendar>
      <div className="modal-background" onClick={() => setSeeDoubleCalendarRangeActive(null)}>
      </div>
      <div className="modal-container">
        <RangeDatesSelectorPanel
          setFilterOptionsActive={setSeeDoubleCalendarRangeActive}
          setInitialDateRange={setInitialDateRange}
          setFinishDateRange={setFinishDateRange}
          initialDateRange={initialDateRange}
          finishDateRange={finishDateRange}
        />
      </div>
    </S.ModalRangeCalendar>
  );
}

export default ModalRangeCalendar;
