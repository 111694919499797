import React from "react";
import ListItems from "../ListItems";
import ImgMessage from "../imgMessage";
import YesOrNo from "../yesOrNo";
import Message from "../message/Message";

function MessageType({ 
  itemsList, 
  patientMessage, 
  botMessage, 
  autoScroll, 
  message,
  yesOrNoDisabled
}) {

   const selectYesOrNoOption = (e) => {
    patientMessage(e, 'yesOrNo');
    botMessage(e);
    autoScroll();
  }

  const selectListOption = (e) => {
    patientMessage(e.toString(), 'doctor');
    botMessage(e.toString());
    autoScroll();
  }

  return (
    <>
      {
        itemsList?.map((item) => {
          const itemType = item.structValue.fields.type.stringValue;
          const itemInfo = item.structValue.fields.info

          if (itemType === 'optionsList') {
            return <ListItems
              key={itemType}
              items={itemInfo.listValue.values}
              selectOption={selectListOption}
            />
          }
            
          if (itemType === 'yesNoOptions') {
            return yesOrNoDisabled 
              ? <YesOrNo
                option={message.message}
                noEditable={false}
                selectOption={selectYesOrNoOption}
              />
              : null
          }

          if (itemType === 'img') {
            return itemInfo.listValue.values.map((imgValue) => {
              return <ImgMessage
                key={imgValue.structValue.fields.value.stringValue}
                img={imgValue.structValue.fields.value.stringValue}
                height={500}
              />
            })
          }

          if (itemType === 'whatsappLink') {
            return itemInfo.listValue.values.map((value) => {
              return <Message
                key={value.structValue.fields.value.stringValue}
                message={value.structValue.fields.value.stringValue}
                option={message.type}
                link
              />
            })
          }

          if (itemType === 'audio') {
            return itemInfo.listValue.values.map((value) => {
              return <audio controls
                key={value.structValue.fields.value.stringValue}
                src={value.structValue.fields.value.stringValue}>
              </audio>
            })
          }

          return <></>
        })
      }
    </>
  );
}

export default MessageType;
