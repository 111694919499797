import React, { useEffect, useState } from "react";
import * as S from "./SurveyPreview.style";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { QUERY_GET_CUSTOM_FORMS_BY_ID } from "../../../../../../../graphql/queries/CustomForm";
import * as P from "../../../../../../../utils/pipes";
import GenericTemplate from "../../../../../outcomes/components/outcomesComponents/shared/genericTemplate";

function SurveyPreview({
  previewSurveyView
}) {

  const { t, i18n: { language } }= useTranslation("global");

  const { 
    data: customForm, 
    loading: customFormLoading 
  } = useQuery(
    QUERY_GET_CUSTOM_FORMS_BY_ID,
    {
      variables: {
        customFormId: previewSurveyView
      },
    }
  );


  return (
    <S.SurveyPreview>
      <div className="preview-left">
        <div className="title">{t("surveys.sectionsAndQuestions")}</div>
        <div className="survey-info">
          <div className="survey-info__title">{P.dataTranslation(customForm?.customForm_by_pk.customFormName, language)}</div>
          <div className="survey-info__description">{P.dataTranslation(customForm?.customForm_by_pk.descript, language)}</div>
        </div>
        {
          customForm?.customForm_by_pk.formGroups.map((form) => {
            return(
              <div className="survey-module">
                <div className="survey-module__title">{P.dataTranslation(form.customFormName, language)}</div>
                {
                  form.customFormFields.map((question) => {
                    return(
                      <div className="survey-question">
                        <div className="survey-question__type">{t("componentsList." + question.typeFieldValue)}</div>
                        <div className="survey-question__title">{P.dataTranslation(question.fieldName, language)}</div>
                        {
                          question.customFormTypeField?.fieldOptions.length > 0 && 
                          <div className="survey-question__options">
                            <span className="survey-question__options-label">{t("globally.options")}: </span>
                            <span>
                              {
                                question.customFormTypeField.fieldOptions.map((options) => {
                                  return (P.dataTranslation(options.names, language))
                                }).join(", ")
                              }
                            </span>
                          </div>
                        }
                        {
                          question.typeFieldValue === "cmpYesNoList" &&
                          <div className="survey-question__options">
                            <span className="survey-question__options-label">{t("globally.options")}: </span>
                            <span>
                              {
                                t("globally.yes") + ", " + t("globally.no")
                              }
                            </span>
                          </div>
                        }
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
      <div className="preview-right">
        <div className="survey-name">{P.dataTranslation(customForm?.customForm_by_pk.customFormName, language)}</div>
        <div className="survey-modules">
          <div className="survey-module">
            {
              customForm?.customForm_by_pk.formGroups.map((form) => {
                return(
                  <GenericTemplate
                    title={P.dataTranslation(form.customFormName, language)}
                    dataForm={form}
                  />
                )
              })
            }
          </div>
        </div>
      </div>      
    </S.SurveyPreview>
  );
}

export default SurveyPreview;
