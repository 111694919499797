import React from "react";
import * as S from "./SymptomsSelected.style";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

// Assets
const noData = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;
const iconVeryMild = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/severities/veryMild.svg`;
const iconMild = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/severities/mild.svg`;
const iconMedium = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/severities/medium.svg`;
const iconSevere = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/severities/severe.svg`;
const iconVerySevere = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/severities/verySevere.svg`;


function SymptomsSelected({
  selectedSymptomsList,
  changeFormField
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const changeSymptomsSeverity = (symptom, severity) => {
    const tempSymptomsList = JSON.parse(JSON.stringify(selectedSymptomsList.value));
    const symptomToChangeIndex = tempSymptomsList.findIndex((item) => item.value === symptom.value);
    tempSymptomsList[symptomToChangeIndex].severity = severity;
    changeFormField(
      [
        {
          value: tempSymptomsList, 
          text: `(${tempSymptomsList.length})`, 
          fieldName: "symptomsList"
        }
      ]
    )
  }
  
  const removeSymptoms = (symptom) => {
    const tempSymptomsList = JSON.parse(JSON.stringify(selectedSymptomsList.value));
    changeFormField(
      [
        {
          value: tempSymptomsList.filter((item) => item.value !== symptom.value), 
          text: `(${tempSymptomsList.length - 1})`, 
          fieldName: "symptomsList"
        }
      ]
    )
  }

  const getSeverityIcon = (severity) => {
    switch (severity) {
      case 1:
        return iconVeryMild;
      case 2:
        return iconMild;
      case 3:
        return iconMedium;
      case 4:
        return iconSevere;
      case 5:
        return iconVerySevere;
      default:
        return ""
    }
  } 

  const getSeverityText = (severity) => {
    switch (severity) {
      case 1:
        return t("globally.veryMild");
      case 2:
        return t("globally.mild");
      case 3:
        return t("globally.medium");
      case 4:
        return t("globally.severe");
      case 5:
        return t("globally.verySevere");
      default:
        return t("globally.severity");
    }
  } 



  const getSymptomsList = () => {

    if(selectedSymptomsList?.value.length === 0)
      return (
        <div className="empty-symptoms">
          <img src={noData} alt="" className="empty-symptoms__img" />
          <div className="empty-symptoms__title">
            {t("healthAddData.symptoms.noSymptomsSelected")}
          </div>
          <div className="empty-symptoms__subtitle">
            {t("healthAddData.symptoms.noSymptomsSelectedDescription")}
          </div>
        </div>
      )

    return(
      selectedSymptomsList.value?.map((symptom) => {
        return (
          <div 
            key={uuidv4()}
            className={`symptom-box`}
          >
            <div className="symptom-box__top">
              <div className={`symptom-box__name`}>
                {symptom.text}
              </div>
              <i 
                class="symptom-box__icon icon zaia-i-trash-can" 
                onClick={() => removeSymptoms(symptom)}
              ></i>
            </div>
            <div className="symptom-box__bottom">
              <button 
                className="button-quantity button-quantity__subtract"
                onClick={() => symptom.severity >= 2 && changeSymptomsSeverity(symptom, symptom.severity - 1)}
              >
                <i class="button-quantity__icon icon zaia-i-next-1"></i>
              </button>
              <div className="severity-value">
                <div className="severity-value__text">{getSeverityText(symptom.severity)}</div>
                <img src={getSeverityIcon(symptom.severity)} alt="" className="severity-value__img" />
              </div>
              <button 
                className="button-quantity button-quantity__subtract"
                onClick={() => symptom.severity < 5 && changeSymptomsSeverity(symptom, symptom.severity + 1)}
              >
                <i class="button-quantity__icon icon zaia-icono-agregar"></i>
              </button>
            </div>
          </div>
        )
      })
    )
  }

  return (
    <S.SymptomsSelected
    >
      <div className="title">{t("globally.severity")}</div>
      <div className="symptoms-list">
        <div className="symptoms-list__container">
          {  
            getSymptomsList()
          }
        </div>
      </div>
      
    </S.SymptomsSelected>
  );
}

export default SymptomsSelected;
