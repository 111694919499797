export const initialState = {
  stepOne: undefined,
  stepTwo: undefined,
  stepThree: undefined,
  stepFour: undefined,
  stepFive: undefined,
  stepSix: undefined,
  stepSeven: undefined,
  lastStep: undefined,
  
  isAuth: false,
  popUp: {
    isActive: false,
    modal: () => <></>
  },
  colorTheme: 'light',
  selectedPatient: undefined,
  doctorData: undefined,
  contextPatient: undefined,
  contextProcess: undefined,

  florenceActive: false,
  florenceView: 0,  
  florencePatient: undefined,
  florenceProcess: undefined,
  florencePartition: undefined,

  medpalmFlorenceActive: false,
  medpalmFlorenceView: 0,  
  medpalmFlorencePatient: undefined,
  medpalmFlorenceProcess: undefined,
  medpalmFlorencePartition: undefined,

  followUpActive: undefined,
  followUpMinimized: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_AUTH":
      return {
        ...state,
        isAuth: action.isAuth,
      };
    case "SET_POP_UP":
      return {
        ...state,
        popUp: action.popUp,
      };
    case "SET_COLOR_THEME":
      return {
        ...state,
        colorTheme: action.colorTheme,
      };
    case "SET_SELECTED_PATIENT":
      return {
        ...state,
        selectedPatient: action.selectedPatient,
      };
    case "SET_DOCTOR_DATA":
      return {
        ...state,
        doctorData: action.doctorData,
      };
    case "SET_CONTEXT_PATIENT":
      return {
        ...state,
        contextPatient: action.contextPatient,
      };
    case "SET_CONTEXT_PROCESS":
      return {
        ...state,
        contextProcess: action.contextProcess,
      };


    case "SET_FLORENCE_ACTIVE":
      return {
        ...state,
        florenceActive: action.florenceActive,
      };
    case "SET_FLORENCE_VIEW":
      return {
        ...state,
        florenceView: action.florenceView,
      };
    case "SET_FLORENCE_PATIENT":
      return {
        ...state,
        florencePatient: action.florencePatient,
      };
    case "SET_FLORENCE_PROCESS":
      return {
        ...state,
        florenceProcess: action.florenceProcess,
      };
    case "SET_FLORENCE_PARTITION":
      return {
        ...state,
        florencePartition: action.florencePartition,
      };


    case "SET_MEDPALM_FLORENCE_ACTIVE":
      return {
        ...state,
        medpalmFlorenceActive: action.medpalmFlorenceActive,
      };
    case "SET_MEDPALM_FLORENCE_VIEW":
      return {
        ...state,
        medpalmFlorenceView: action.medpalmFlorenceView,
      };
    case "SET_MEDPALM_FLORENCE_PATIENT":
      return {
        ...state,
        medpalmFlorencePatient: action.medpalmFlorencePatient,
      };
    case "SET_MEDPALM_FLORENCE_PROCESS":
      return {
        ...state,
        medpalmFlorenceProcess: action.medpalmFlorenceProcess,
      };
    case "SET_MEDPALM_FLORENCE_PARTITION":
      return {
        ...state,
        medpalmFlorencePartition: action.medpalmFlorencePartition,
      };

    
    case "SET_FOLLOWUP_ACTIVE":
      return {
        ...state,
        followUpActive: action.followUpActive,
      };

    case "SET_FOLLOWUP_MINIMIZED":
      return {
        ...state,
        followUpMinimized: action.followUpMinimized,
      };

    case "SET_STEPONE":
      return {
        ...state,
        stepOne: action.stepOne,
      };
    case "SET_STEPTWO":
      return {
        ...state,
        stepTwo: action.stepTwo,
      };
    case "SET_STEPTHREE":
      return {
        ...state,
        stepThree: action.stepThree,
      };
    case "SET_STEPFOUR":
      return {
        ...state,
        stepFour: action.stepFour,
      };
    case "SET_STEPFIVE":
      return {
        ...state,
        stepFive: action.stepFive,
      };
    case "SET_STEPSIX":
      return {
        ...state,
        stepSix: action.stepSix,
      };
    case "SET_STEPSEVEN":
      return {
        ...state,
        stepSeven: action.stepSeven,
      };
    case "SET_LASTSTEP":
      return {
        ...state,
        lastStep: action.lastStep,
      };
    default:
      return state;
  }
};

export default reducer;
