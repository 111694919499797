import styled from "styled-components";

export const DashboardPatient = styled.div`
  background-color: var(--background-patient);
  width: 100%; 
  height: 100%;
  position: relative;
  .cards-container {
    overflow-y: auto;
    height: calc(100% - 60px);
    z-index: 400;
    position: relative;
  }
  
  @media only screen and (max-width: 768px) {
    .cards-container {
      /* ::-webkit-scrollbar {
        display: none;
      } */
    }
  }
  @media only screen and (max-width: 435px) {
    /* .add-calendar-event {
      width: 100vw !important;
      padding-right: 20px;
    } */
  }

  @media only screen and (max-width: 1024px) {
    /* .add-calendar-event {
      padding-right: 20px;
    } */
  }
`;