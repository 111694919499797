
import styled from "styled-components";

export const ListOfBoxes = styled.div`
  /* width: 100%;
  align-items: flex-start;
  overflow-y: scroll; */
`;


export default ListOfBoxes;