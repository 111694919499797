import styled from "styled-components";
import { getHexOpacity } from "../../../../../../../utils/pipes";

export const ModalSelectEventMarker = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: var(--white);
    width: 783px;
    padding: 20px 55.75px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    justify-content: center;
    position: relative;
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__title {
        color: #59599E;
        font-family: "Nunito Sans Bold";
        font-size: 30.309px;
      }
      &__close {
        display: flex;
        padding: 11.864px;
        background-color: #36369B;
        border-radius: 50%;
        font-size: 10px;
        background: #EFEFFF;
        cursor: pointer;
        color: #36369B;
      }
    }
    .input-button {
      display: flex;
      margin-top: 10px;
    }
    .select-all-button{
      display: flex;
      height: 46px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 14px;
      border-bottom: 1px solid #6C6BCC;
      background: #FAFAFF;
      color: #36369B;
      font-family: "Nunito Sans";
      font-size: 15px;
      cursor: pointer;
      margin: 0 20px;
      min-width: 149px;
    }
    .filter-patient {
      width: 100%;
      height: 47px;
      background-color: #E0E6ED;
      padding: 10px 20px;
      align-items: center;
      box-sizing: border-box;
      /* width: 503px;  */
      border-radius: 10px;
      display: flex;
      cursor: text;
      &__input {
        background-color: transparent;
        font-family: "Nunito Sans";
        border: none;
        outline: none;
        font-size: 20px;
        color: #59599E;
        width: 100%;
        ::placeholder {
          color: #59599E;
          font-family: "Nunito Sans";
        }
      }
      &__icon {
        color: #59599E !important;
        font-size: 20px;
      }
    }
    .users-list {
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;
      overflow-y: scroll;
      height: 430px;
      align-items: flex-start;
      align-content: flex-start;
      ::-webkit-scrollbar {
        width: 25px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px transparent;
        /* margin: 1px 0px;  */
      }
      ::-webkit-scrollbar-thumb {
        border: 10px solid rgba(0, 0, 0, 0);
        box-shadow: inset 0 0 10px 10px #3E3DA366;
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: transparent;
      }
      &__responses {
        height: 100%;
        text-align: center;
        margin: auto;
        padding-left: 28px;
        display: flex;
      }
      .marker {
        /* display: flex;
        padding: 8.5px 20px;
        border-radius: 12px;
        background: #FCFCFE;
        align-items: center;
        color: #1F1F52; */
        width: 290px;
        margin-bottom: 10px;
        display: flex;
        padding: 4px 5px;
        align-items: center;
        border-radius: 15px;
        background: #FFF;
        box-shadow: 0px 0px 1.8px 0px rgba(35, 34, 157, 0.25);
        color: #424272;
        padding: 10px 14px;
        cursor: pointer;
        :nth-child(odd) {
          margin-right: 10px;
        }
        &--selected {
          color: #FCFCFE;
          background-color: #6C6BCC;
        }
        &__circle {
          width: 13px;
          height: 13px;
          box-shadow: 0px 0px 0px 3px #FF858566;
          background-color: #FF8585;
          border-radius: 50%;
          margin: 0px 3px;
        }
        &__name {
          margin: 0px 5px;
          font-family: "Nunito Sans";
          font-size: 14px;
        }
        &__close {
          display: flex;
          padding: 5px;
          margin-left: auto;
          border-radius: 32.887px;
          background: #EEF1F4;
          font-size: 5px;
          cursor: pointer;
        }
      }
    }
  }
`;

export const Event = styled.div`
  display: flex;
  align-items: center;
  .circle {
    width: 13px;
    height: 13px;
    box-shadow: 0px 0px 0px 3px ${({color}) => color + 66};
    background-color: ${({color}) => color};
    border-radius: 50%;
    margin: 0px 3px;
  }
  .name {
    margin: 0px 5px;
    font-family: "Nunito Sans";
    font-size: 14px;
  }
`