import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./NoteForm.style";
import NoteText from "../noteText";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../graphql/queries/Process";

import * as P from "../../../../../utils/pipes";
import Modal from "../../../../../utils/modal";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";
import { MUTATION_UPDATE_USER_TO_PROCESS_FOLLOW_UP } from "../../../../../graphql/mutations/UserToProcess";
import { MUTATION_UPDATE_USER_TO_PROCESS_HISTORY_BY_ID } from "../../../../../graphql/mutations/UserToProcessHistory";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

const feather = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/feather.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function NoteForm({
  maxViewActived,
  setCreateNoteActived,
  editNoteActived,
  setEditNoteActived,
  patientSelected,
  formNoteText,
  setFormNoteText,
  userToProcessSelected,
  setUserToProcessSelected
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const [success, setSuccess] = useState(null);

  const [updateUserToProcess, {loading: updateUserToProcessLoading}] = useMutation(MUTATION_UPDATE_USER_TO_PROCESS_FOLLOW_UP);
  const [updateUserToProcessHistory, {loading: updateUserToProcessHistoryLoading}] = useMutation(MUTATION_UPDATE_USER_TO_PROCESS_HISTORY_BY_ID);

  const processSelection = (userToProcessId) => {    
    setUserToProcessSelected(patientSelected.userToProcesses.find(userToProcess => userToProcess.id === userToProcessId))
  }  

  useEffect(() => {
    setFormNoteText(editNoteActived?.descript || "");
    setUserToProcessSelected(editNoteActived?.userToProcess);
  }, [editNoteActived])

  const handleSubmitNewNote = async () => {    
    try {
      await updateUserToProcess({
        variables: {
          updates: {
            descript: formNoteText
          },
          userToProcessId: userToProcessSelected.id
        },
      });
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      console.log(error);
    }
  }

  const handleSubmitEditNote = async () => {
    try {
      await updateUserToProcessHistory({
        variables: {
          updates: {
             descript: formNoteText
          },
          userToProcessHistoryId: editNoteActived.id
        },
      });
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      console.log(error);
    }
  }

  const disabledButton = () => {
    if(editNoteActived) {
      return (
        !formNoteText ||
        !userToProcessSelected ||
        formNoteText === editNoteActived.descript
      )
    } else {
      return (
        !formNoteText ||
        !userToProcessSelected
      )
    }
  }

  return (
    <S.NoteForm
      maxViewActived={maxViewActived}
    >
      <Modal
        open={updateUserToProcessLoading || updateUserToProcessHistoryLoading}
      >
        <S.ModalResponse>
          <div className="modal-background">
          </div>
          <div className="modal-container">
            <ZaiaReaction
              zaiaImg={logoZaiaLoading}
              sizeText="20px"
              textColor="var(--blue-color)"
              widthImg="110px"
            />
          </div>
        </S.ModalResponse>
      </Modal>
      <Modal
        open={success || success === false}
      >
        <S.ModalResponse>
          <div className="modal-background" onClick={() => setSuccess(null)}>
          </div>
          <div className="modal-container">
              <ZaiaModalResponseContent
                button1={() => {setEditNoteActived(null); setCreateNoteActived(false);}}
                formSuccess={success}
                button1Text={success ? t("followUp.confirm") : t("globally.accept")}
                title={
                  success
                  ? editNoteActived 
                    ? t("notes.editNoteSuccess")
                    : t("notes.createNoteSuccess")
                  : t("globally.errorForm")
                }
                subtitle={editNoteActived && !success ? t("notes.editNoteError") : t("notes.createNoteError")}
              />
          </div>
        </S.ModalResponse>
      </Modal>
      {
        editNoteActived &&
        <div className="current-program">
          <div className="current-program__label">{t("globally.program")}</div>
          <div className="current-program__name">{P.dataTranslation(userToProcessSelected?.process.processName, language)}</div>
        </div>
      }
      {
        !editNoteActived &&
        <div className="select-program">
          <div className="select-program__label">
            {t("globally.selectProgram")}
          </div>
          <div className="select-program__select">
            <ZaiaSelect
              type="select"
              name="phoneLocation"
              setValue={(e) => 
                processSelection(e)
              }
              value={userToProcessSelected?.id}
              heightList={"250px"}
              items={
                patientSelected.userToProcesses.map(
                  userToProcess => {
                    return {
                      value: userToProcess.id,
                      text: P.dataTranslation(userToProcess.process.processName, language)
                    }
                  }
                )
              }
              shadow={false}
              fontSize="14px"
              backgroundColor={"trans"}
              borderRadius={"10px"}
              textColor={"#3E3DA3"}
              arrowColor={"#3E3DA3"}
              borderColor={"#6C6BCC"}
            />
          </div>
        </div>
      }
      <div className="note-text">
        {
          maxViewActived
          ? <>
            <img src={feather} alt="" className="note-text__img" />
            <div className="note-text__text">
              {t("notes.medicalNotesForPatient")}
            </div>
          </>
          : <NoteText
            maxViewActived={maxViewActived}
            editNoteActived={editNoteActived}
            formNoteText={formNoteText}
            setFormNoteText={setFormNoteText}
            userToProcessSelected={userToProcessSelected}
          />
        }
      </div>
      <div className="buttons">
         <button className={`button button--save ${
          disabledButton() &&
          "button--disabled"
        }`}
            onClick={ () => 
              disabledButton()
                ? null
                : (
                  editNoteActived
                  ? handleSubmitEditNote()
                  : handleSubmitNewNote()
                )
            }
         >
            {t('globally.save')}
         </button>
          <button className="button button--cancel"
            onClick={() => { 
              setCreateNoteActived(false);
              setEditNoteActived(false);
            }}
          >
            {t('globally.cancel')}
          </button>
      </div>
    </S.NoteForm>
  );
}

export default NoteForm;
