import styled from "styled-components";

export const MedicinesBoxInfo = styled.div`

  .boxInfoPatient__info {
    display: flex;
    
    &-left {
      width: 25%;
      padding-right: 10px;
      display: flex;
    }

    &-center {
      width: 35%;
      padding: 0 10px 0 10px;
      display: flex;
    }

    &-right {
      width: 40%;
      padding-left: 10px;
    }

    .boxInfoPatient__infoReport {
      position: relative;
      h5 {
        margin-bottom: 10px;
      }
    }

    .boxInfoPatient__infoMedicineData {
      margin-left: 10px;
      display: flex;
      flex-direction: ${(props) => !props.hide && "column"};
      justify-content: center;
      align-items: ${(props) => props.hide && "center"};
      color: ${(props) => props.color};
      width: auto;
      h2 {
        font-family: "Nunito ExtraBold";
        color: ${(props) => props.colorPrimary};
        span {
          color: ${(props) => props.colorSecundary};
          color: ${(props) => props.transparentColor};
        }
      }
      p {
        color: ${(props) => props.colorDark};
        font-size: 10px;
        font-family: "Open Sans"
      }
    }

  }
  @media only screen and (max-width: 768px) {
    .boxInfoPatient__info {
      flex-direction: column;
      align-items: center;
      &-left {
        width: 100%;
        margin-bottom: 20px;
      }
      &-center {
        width: 100%;
        margin-bottom: 20px;
      }
      &-right {
        width: 100%;
      }
    }
  }
`;