import { gql } from "@apollo/client";

export const SUBSCRIPTION_GET_USER_TO_PROCESS_CALENDAR_BY_USER = gql`
  subscription SUBSCRIPTION_GET_USER_TO_PROCESS_CALENDAR_BY_USER(
    $patientId: uuid, 
    $_lte: bigint, 
    $_gte: bigint
  ) {
    userToProcessCalendar(where: {dateTime: {_gte: $_gte, _lte: $_lte}, userId: {_eq: $patientId}}) {
      created_at
      dateTime
      descript
      id
      isAccepted
      isNotify
      name
      place
      userId
      userToProcessId
      user {
        documentNumber
        email
        lastname
        name
        picture
        documentType
        userToProcesses {
          id
          process {
            id
            processName
            processToCustomForms(order_by: {customFormId: asc}) {
              customFormId
              id
              processId
            }
          }
        }
      }
    }
  } 
`

export const SUBSCRIPTION_GET_ALL_USER_TO_PROCESS_CALENDAR_BY_USER = gql`
  subscription SUBSCRIPTION_GET_ALL_USER_TO_PROCESS_CALENDAR_BY_USER($patientId: uuid) {
    userToProcess(where: {userId: {_eq: $patientId}}) {
      descript
      id
      process {
        descript
        nameKey
        processName
      }
      userToProcessCalendars {
        created_at
        dateTime
        descript
        id
        isAccepted
        isNotify
        name
        place
        user {
          documentNumber
          email
          lastname
          name
          picture
          documentType
        }
      }
    }
  } 
`

export const SUBSCRIPTION_GET_USER_TO_PROCESS_CALENDAR = gql`
  subscription SUBSCRIPTION_GET_USER_TO_PROCESS_CALENDAR(
    $_lte: bigint, 
    $_gte: bigint
  ) {
    userToProcessCalendar(where: {dateTime: {_gte: $_gte, _lte: $_lte}}) {
      created_at
      dateTime
      descript
      id
      isAccepted
      isNotify
      name
      place
      userId
      userToProcessId
      user {
        documentNumber
        email
        lastname
        name
        picture
        documentType
        userToProcesses {
          id
          process {
            id
            processName
            processToCustomForms(order_by: {customFormId: asc}) {
              customFormId
              id
              processId
            }
          }
        }
      }
    }
  } 
`