import styled from "styled-components";
const waveBottom = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-pequena-botton-violeta.svg`;

export const GenerativeAI = styled.div`
  display: flex;
  height: 100%;
  /* width: 100%; */
  background-color: var(--background-patient);
  background-image: url(${waveBottom});
  background-repeat: no-repeat;
  background-position: bottom;
  background-position-y: 125%;
  border-radius: 14px 14px 0px 0px;
  overflow: hidden;
  box-shadow: 0px 0px 29px -10px rgba(35, 34, 157, 0.10);
  background: #F9F9FC;
  .partition-selected{
    width: 39px;
    display: flex;
    padding: 10px 10px 10px 10px;
    justify-content: center;
    align-items: center;
    &__container {
      display: flex;
      align-items: center;
      transform: rotate(-90deg);
    }
    &__point {
      width: 21px;
      height: 21px;
      border-radius: 17px;
      background: #9393FF;
      margin-right: 10px;
    }
    &__text {
      color: #1F1F52;
      font-family: "Nunito Sans";
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .chatbot {
    height: 100%;
  }
  .chat-container {
    width: 500px;
    height: 592px;
    border-radius: 14px 14px 0px 0px;
    background: #FFF;
  }
  .chatbot-selectors {
    display: flex;
    flex-direction: column;
    .patient-selector {
      height: 58px;
      width: 331px;
      margin: 20px auto 10px;
      padding: 10px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      border-radius: 14px;
      /* border: 1px solid #C3C3E1; */
      background: linear-gradient(44deg, rgba(214, 214, 246, 0.23) 9.33%, rgba(79, 79, 255, 0.00) 235.78%);
      -webkit-box-shadow: -2px 2px 2px -1px #C3C3E1;
      -moz-box-shadow: -2px 2px 2px -1px #C3C3E1;
      box-shadow: -2px 2px 2px -1px #C3C3E1;
      cursor: pointer;
      &__box-icon {
        filter: drop-shadow(0px 0px 4.015px rgba(172, 172, 207, 0.45));
        border-radius: 50%;
        background-color: #FFF;
        padding: 3px;
        display: flex;
        margin-right: 10px;
      }
      &__icon {
        border-radius: 50%;
      }
      &__text {
        color: #1F1F52;
        text-align: center;
        font-family: "Nunito Sans";
        font-size: 14px;
      }
    }

    .patient-selected {
      box-sizing: border-box;
      margin: 10px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      background-color: #F9F9FC;
      border-radius: 20px;
      cursor: pointer;
      &__box-image {
        filter: drop-shadow(0px 0px 4.015px rgba(172, 172, 207, 0.45));
        border-radius: 50%;
        background-color: #FFF;
        padding: 3px;
        display: flex;
        margin-right: 10px;
        overflow: hidden;
        img {
          width: 31.666px;
          border-radius: 50%;
          height: 31.666px;
        }
      }
      &__name {
        color: #1F1F52;
        text-align: center;
        font-family: "Nunito Sans";
        font-size: 14px;
      }
    }

    .chatbot_selector{
      padding: 0px 20px 10px 20px;
      .title {
        display: flex;
        &__text {
          color: #36369B;
          font-family: "Nunito Sans";
          font-size: 14px;
          font-style: normal;
        }
        &__required {
          color: #F30C0C;
          margin-left: 5px;
        }
      }
      .input-container {
      .input-container {
          border: transparent;
          border-bottom: 1px solid #6C6BCC;
        }
      }
    }
  }

  .chatbot-container {
    box-sizing: border-box;
    padding: 0px 14px 0px 29px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    ::-webkit-scrollbar {
      width: 30px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px transparent;
      /* margin: 1px 0px;  */
    }
    ::-webkit-scrollbar-thumb {
      border: 12px solid rgba(0, 0, 0, 0);
      box-shadow: inset 0 0 10px 10px #3E3DA366;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: transparent;
    }
    &__empty {
      text-align: center;
      margin: auto;
      &-image {
        
      }
      &-msg {
        margin-top: 48px;
        color: #6C6BCC;
        text-align: center;
        font-family: Nunito Sans;
        font-size: 24.648px;
      }
    }
  }
`

export const HeaderDiv = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    min-height: 77px;
    padding: 0 20px;
    box-sizing: border-box;
    align-items: center;
    &__title {
      margin: 10px 0px;
      &-logo {
        width: 250px;
        height: fit-content;
      }
    }
    &__subtitle {
      color: ${({theme}) => theme.primaryColor};
      font-family: "Nunito Sans";
      font-size: 14px;
      color: #36369B;
    }
    &__process-name {
      color: #1F1F52;
      font-family: "Nunito Sans";
      font-size: 14px;
      padding: 10px 0px;
    }
    &__right {
      display: flex;
      position: absolute;
      right: 20px;
      margin: 10px 0;
    }
    &__reset-button {
      display: flex;
      width: 111px;
      padding: 8.894px;
      justify-content: center;
      align-items: center;
      font-family: "Nunito Sans Bold";
      font-size: 14px;
      background: rgba(109, 109, 185, 0.12);
      cursor: pointer;
      height: fit-content;
      margin-right: 10px;
      border-radius: 15px;
      color: #36369B
    }
    &__close-chat {
      height: fit-content;
      background: rgba(109, 109, 185, 0.12);
      display: flex;
      padding: 14px;
      font-size: 9px;
      border-radius: 50%;
      cursor: pointer;
      &-icon {
        display: flex;
        color: ${({theme}) => theme.primaryColor};
      }
    }
  }
`

export const ChatbotChooseOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .view-options {
    margin: auto;
    width: 292px;
  }
  .view-option {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px;
    &__button {
      border-radius: 10px;
      background: #36369B;  
      display: flex;
      padding: 10px;
      justify-content: center;
      border: none;
      outline: none;
      color: #FFF;
      font-family: "Nunito Sans";
      font-size: 14px;
      font-style: normal;
      cursor: pointer;
      margin-bottom: 10px;
    }
    &__description {
      color: #93A9C0;
      text-align: center;
      font-family: "Nunito Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
`
