import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";
const bgFlorence = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/background-florence.svg`;

export const ModalFiltersAiNavigator = styled.div`
 display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: #FFFFFF;
    max-width: 1038px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    justify-content: end;
    .header{
      padding: 10px 10px 0px 10px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #0000002B;
      align-items: center;
      padding: 16px 40px;
      .title {
        display: flex;
        align-items: center;
        &__icon  {
          font-size: 18px;
          margin-right: 10px;

        }
        &__text {
          font-family: Nunito Sans Bold;
          font-size: 19.08px;
        }
      }
      .close {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        cursor: pointer;
        color: #36369B;
      }
    }
    .content {    
      display: flex;
      flex-direction: column;
      padding: 28px 40px 0 40px;
      width: 800px;
      box-sizing: border-box;
      position: relative;
      max-height: 500px;
      overflow: auto;
      /* height: calc(100% - 34px); */
      .filter-space {
        margin-bottom: 30px;
        &__title {
          font-family: Nunito Sans Bold;
          font-size: 15px;          
        }
        &__subtitle {
          font-family: Nunito Sans;
          font-size: 15px;
          margin-top: 5px;
        }
        &__content {
          margin-top: 20px;
        }
        .process-list {
          display: flex;
          flex-wrap: wrap;
          .process-item {
            cursor: pointer;
            padding: 10px 16px;
            border-radius: 14px;
            display: flex;
            align-items: center;
            background-color: #F6F6FF;
            margin-bottom: 10px;
            width: 43.9%;
            color: #3E3E5B;
            :nth-child(odd) {
              margin-right: 20px;
            }
            &--actived {
              background-color: #D2D2FF;
              font-family: Nunito Sans Bold;
            }
            &__check-icon {
              font-size: 14px; 
              margin-right: 20px;
              &--on {
                color: #3E3DA3;
              }
              &--off {
                color: #BBBBBB;
              }
            }
            &__text {
              font-family: Nunito Sans;
              font-size: 17px;
            }
          }
        }
        .gender-tabs {
          display: flex;
          .gender-item {
            background-color: #F6F6FF;
            border-radius: 22.03px;
            width: 50%;
            padding: 24px 0;
            text-align: center;
            cursor: pointer;
            color: #6C6BCC;            ;
            :nth-child(odd) {
              margin-right: 20px;
            }
            &--actived {
              background-color: #D2D2FF;
              font-family: Nunito Sans Bold;
            }
            &__icon {
              font-size: 12.65px;
              margin-bottom: 5.51px;
              justify-content: center;
              font-size: 14.46px;
            }
            &__text {
              font-size: 16.53px;
              font-family: Nunito Sans Bold;
            }
          }
        }
      }
    }
    .footer {
      display: flex;
      padding: 10px 40px;
      justify-content: space-between;
      align-items: center;
      border-top: 1px solid #0000002B;
      &__label {
        font-size: 15px;
        font-family: Nunito Sans;
      }
      &__button {
        .filter-button {
          padding: 8px 43px;
          border-radius: 75px;
          font-size: 14px;
          font-family: Nunito Sans;
          margin-left: 10px;
          border: none;
          outline: none;
          background-color: #EEEDF4;
          color: #3E3DA3;
          display: flex;
          align-items: center;
          cursor: auto;
          &__icon {
            font-size: 20px;
            opacity: 0.6;
            display: flex;
          }
          &--actived {
            cursor: pointer;
            background-color: #27AE60;
            color: #FFFFFF;
          }
        }
      }
    } 
  }
`;