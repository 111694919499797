import React, { useEffect, useState } from "react";
import * as S from "./Agenda.style.js";
import AgendaFilterPanel from "./components/agendaFilterPanel/AgendaFilterPanel.jsx";
import { useStateValue } from "../../../contextAPI/StateProvider.js";
import { useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_USER_TO_PROCESS_CALENDAR, SUBSCRIPTION_GET_USER_TO_PROCESS_CALENDAR_BY_USER } from "../../../graphql/subscriptions/UserToProcessCalendar";
import useRecordNavigation from "../../../hooks/useRecordNavigation";
import { Actions } from "../../../utils/actionsIds";

// utils
import * as P from "../../../utils/pipes";
import { useTranslation } from "react-i18next";
import ZaiaReaction from "../../zaiaComponents/zaiaReaction/ZaiaReaction.jsx";
import Modal from "../../../utils/modal.js";
import ModalAddEventCalendar from "./components/modalAddEventCalendar";
import Popup from "reactjs-popup";
import ProcessName from "../shared/processName/ProcessName.jsx";

// Assets
const eventsCalendarEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/list-events-empty.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function Agenda() {
  const { t, i18n: { language } }= useTranslation("global");

  const [{ selectedPatient }] = useStateValue();
  const [eventTypeSelected, setEventTypeSelected] = useState(0);
  const [eventsTimeSelected, setEventTimeSelected] = useState(1);
  const [initDate] = useState(new Date(Date.now()).getTime());
  const [initialDateRange, setInitialDateRange] = useState();
  const [finishDateRange, setFinishDateRange] = useState();
  const [ dinamicEventList, setDinamicEventList ] = useState([]);
  const [ cardEventSelected, setCardEventSelected ] = useState();
  const [ createEventCalendarActive, setCreateEventCalendarActive ] = useState(false);
  const { recordAction } = useRecordNavigation();

  const [eventsFilterVariables, setEventsFilterVariables] = useState();

  useEffect(() => {
    const gte = initialDateRange?.getTime() || initDate;
    const lte = finishDateRange?.getTime() || initDate + (30 * 86400000);

    let gteVariable, lteVariable;
    if (eventsTimeSelected === 1) {
      gteVariable = initDate;
      lteVariable = initDate + (90 * 86400000);
    } else if (eventsTimeSelected === 0) {
      gteVariable = initDate - (90 * 86400000);
      lteVariable = initDate;
    }

    const filterVariables = {
      ...(selectedPatient && { patientId: selectedPatient?.id }),
      _gte: gteVariable || gte,
      _lte: lteVariable || lte
    };

    setEventsFilterVariables(filterVariables);

  }, [selectedPatient, eventsTimeSelected, initialDateRange, finishDateRange])

  const {
    data: eventList,
    loading: loadingEventList,
    error: errorEventList
  } = useSubscription(
    selectedPatient
      ? SUBSCRIPTION_GET_USER_TO_PROCESS_CALENDAR_BY_USER
      : SUBSCRIPTION_GET_USER_TO_PROCESS_CALENDAR,
    {
      variables: eventsFilterVariables,
    }
  );
  
  useEffect(() => {
    if(eventTypeSelected === 0){
      setDinamicEventList(eventList?.userToProcessCalendar);
    } else if(eventTypeSelected === 2){
      setDinamicEventList(eventList?.userToProcessCalendar?.filter((event) => event.isAccepted === null));
    } else if(eventTypeSelected === 1){
      setDinamicEventList(eventList?.userToProcessCalendar?.filter((event) => event.isAccepted === true));
    } else {
      setDinamicEventList(eventList?.userToProcessCalendar?.filter((event) => event.isAccepted === false));
    }
  }, [eventTypeSelected])

  useEffect(() => {
    setDinamicEventList(eventList?.userToProcessCalendar);
  }, [eventList])

  const eventTimes = [
    {
      id: 0,
      name: t("agenda.pastEvents"),
    },
    {
      id: 1,
      name: t("agenda.upcomingEvents"),
    }
  ]
  
  

  const eventTypeList = [
    {
      id: 0,
      name: t("globally.all"),
      color: "#5D5FEF",
    },
    {
      id: 1,
      name: t("globally.accepted"),
      color: "#53C25D",
    },
    {
      id: 2,
      name: t("globally.pending"),
      color: "#CFAB29",
    },
    {
      id: 3,
      name: t("globally.rejected"),
      color: "#FE4545",
    },
    // {
    //   id: 4,
    //   name: "Canceled",
    //   color: "#0D0750",
    // }
  ]

  const getDayOrRange = () => {
    const initialDateRangeYear = initialDateRange?.getFullYear();
    const initialDateRangeMonth = initialDateRange?.getMonth();
    const initialDateRangeDay = initialDateRange?.getDate();
    const finishDateRangeYear = finishDateRange?.getFullYear();
    const finishDateRangeMonth = finishDateRange?.getMonth();
    const finishDateRangeDay = finishDateRange?.getDate();

    if(
      initialDateRangeDay === finishDateRangeDay && 
      initialDateRangeMonth === finishDateRangeMonth && 
      initialDateRangeYear === finishDateRangeYear
    ){
      return `${"Day"} (${P.longDate(initialDateRange, t, language)})`; 
    } else {
      return `${"Range"} (${P.shortDate(initialDateRange, t, language)} - ${P.shortDate(finishDateRange, t, language)})`;
    }
  }

  return <S.Agenda
    englishLanguage={language === 'en-US' || language === 'en'}
  >
    <Modal open={cardEventSelected || createEventCalendarActive}>
      <ModalAddEventCalendar
        event={cardEventSelected}
        setCardEventSelected={setCardEventSelected}
        setCreateEventCalendarActive={setCreateEventCalendarActive}
      />
    </Modal>
    <div className="agenda__container-left">
      <AgendaFilterPanel
        eventTypeSelected={eventTypeSelected} 
        setEventTypeSelected={setEventTypeSelected}
        initialDateRange={initialDateRange}
        finishDateRange={finishDateRange}
        setInitialDateRange={setInitialDateRange}
        setFinishDateRange={setFinishDateRange}
        eventTypeList={eventTypeList}
      />
    </div>
    <div className="agenda__container-right">
      <div className="agenda__header">
        <div className="date-container">
          <div className="date-container__day-month">
            <div className="date-container__text date-container__text--day">{new Date(Date.now()).getDate()}</div>
            <div className="date-container__text">{P.nameMonth(new Date(Date.now()).getMonth(), "L", t)}</div>
          </div>
          {/* {(language === 'en-US' || language === 'en') && <div className="date-container__day">{new Date(Date.now()).getDate()}</div>} */}
          <div className="date-container__year">{new Date(Date.now()).getFullYear()}</div>
        </div>
        <div className="agenda-message">
          <div className="agenda-message__text">{t("agenda.message")}</div>
        </div>
      </div>
      {
        (initialDateRange && finishDateRange) ?
          <div className="time-filters-range">
            <p className="time-filters-range__label">
              {t("globally.filterBy")}
              <b className="time-filters-range__level-bold">{` ${ getDayOrRange() }`}</b>
            </p>
            <div className="time-filters-range__close-button" onClick={() => {setInitialDateRange(null); setFinishDateRange(null)}}>
              <i className="time-filters-range__close-button-icon icon zaia-icono-cerrar"/>
            </div>
          </div>
          : <div className="time-filters">
            {
              eventTimes.map((item) => {
                return (
                  <div
                    key={item.id}
                    className={`time-filter ${eventsTimeSelected === item.id ? "time-filter--selected" : ""}`}
                    onClick={
                      () => {
                        setEventTimeSelected(item.id);
                        recordAction(Actions.AGENDA.FILTERS);
                      }
                    }
                  >
                    {item.name}
                  </div>
                )
              })
            }
          </div>
      }
      <div className="events-list">
        {
          errorEventList && <ZaiaReaction
            zaiaImg={logoZaiaAlert}
            sizeText="20px"
            textColor="var(--blue-color)"
            widthImg="110px"
          />
        }
        {
          loadingEventList && <ZaiaReaction
            zaiaImg={logoZaiaLoading}
            sizeText="20px"
            textColor="var(--blue-color)"
            widthImg="110px"
          />
        }
        {
          !loadingEventList && !errorEventList && dinamicEventList?.length === 0 &&
          <div className="events-list__empty">
            <ZaiaReaction
              zaiaImg={eventsCalendarEmpty}
              text={t("agenda.noEvents")}
              subText={t("globally.noMatchingPatients")}
              sizeText="33.25px"
              sizeSubText="15px"
              textColor="var(--blue-color)"
              widthImg="250px"
            />
          </div>
        }
        {
          !loadingEventList && !errorEventList && dinamicEventList?.length !== 0 &&
          dinamicEventList?.map((event) => {
            return (
              <EventCard
                key={event.id}
                event={event}
                setCardEventSelected={setCardEventSelected}
                oldCard={new Date(event.dateTime).getTime() < new Date(Date.now()).getTime()}
              />
            )
          })
        }        
      </div>
    </div>
  </S.Agenda>
}

function EventCard(
  {
    event,
    oldCard,
    setCardEventSelected
  }
){
  const { t, i18n: { language } }= useTranslation("global");
  const { recordAction } = useRecordNavigation();

  const status = [
    {
      value: null,
      text: t("globally.pending"),
      color: "#CFAB29"
    },
    {
      value: true,
      text: t("globally.accepted"),
      color: "#53C25D"
    },
    {
      value: false,
      text: t("globally.rejected"),
      color: "#FE4545"
    }
  ]

  return (
    <S.EventCard
      oldCard={oldCard}
      onClick={
        () => {
          setCardEventSelected(event);
          recordAction(Actions.AGENDA.CARD);
        } 
      }
    >
      <div className="event-card__date">
        <div className="event-card__date-day-week">{P.nameMonth(new Date(event.dateTime).getDay(), "S", t).toUpperCase()}</div>
        <div className="event-card__date-day">{new Date(event.dateTime).getDate()}</div>
      </div>
      <div className="event-card__center-separator"></div>
      <div className="event-card__info">
        <div className="event-card__top">
          <div className="event-card__top-title">{event.name}</div>
          <div className="event-card__top-ubication">
            <i className="event-card__top-ubication-icon zaia-i-location" />
            <div className="event-card__top-ubication-text">{event.place}</div>
          </div>
        </div>
        <div className="event-card__middle">
          <i className="event-card__middle-icon zaia-timeline"/>
          <div className="event-card__middle-date">{P.longDate(new Date(event.dateTime), t, language)}</div>
          <div className="event-card__middle-separator"></div>
          <div className="event-card__middle-time">{P.hoursIn12format(new Date(event.dateTime).getHours(), new Date(event.dateTime).getMinutes())}</div>
        </div>
        <div className="event-card__bottom">
          <div className="event-card__bottom-patient-info">
            <img className="event-card__bottom-patient-img"
              src={event.user?.picture}
              alt="patient-img"
            />
            <div className="event-card__bottom-patient-name">{`${event.user?.name} ${event.user?.lastname}`}</div>
            {
                <Popup
                  trigger={
                    <div className="patient-option__info-program-detail">
                      {
                        event.user?.userToProcesses?.length > 1 
                        ? <div className="event-card__bottom-patient-program" style={{"textDecoration": "underline"}}>{t("globally.variousProcesses")}</div> : 
                        <div className="event-card__bottom-patient-program">{P.dataTranslation(event.user?.userToProcesses[0]?.process.processName, language)}</div>
                        
                      }
                    </div>
                  }
                  keepTooltipInside
                  nested
                  // position={"right bottom"}
                  on="hover"
                  disabled={event.user?.userToProcesses?.length > 1 ? false : true}
                >
                  {
                    <ProcessName userToProcesses={event.user?.userToProcesses}/>
                  }
                </Popup>
              }
          </div>
          <div className="event-card__bottom-status">
            <div className="event-card__bottom-status-point"
              style={{backgroundColor: status.find((item) => item.value === event.isAccepted)?.color}}
            ></div>
            <div className="event-card__bottom-status-text">
              {
                status.find((item) => item.value === event.isAccepted)?.text
              }
            </div>
            {/* <i className="event-card__bottom-status-icon zaia-vector"/> */}
          </div>
        </div>
      </div>
    </S.EventCard>
  )
}

export default Agenda;