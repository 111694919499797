import styled from "styled-components";

export const SurveyPreview = styled.div`
  display: flex;
  width: 100%;
  .preview-left {
    width: 50%;
    overflow: auto;
    .title {
      color: #1F1F52;
      font-family: Nunito Sans;
      font-size: 20px;
      margin-bottom: 25px;
    }
    .survey-info {
      &__title {
        font-family: Nunito Sans Bold;
        font-size: 20px;
        color: #36369B;
      }
      &__description {
        font-family: "Nunito Sans";
        font-size: 14px;
        font-style: italic;
      }
    }
    .survey-module {
      margin: 30px 0px 20px;
      &__title {
        font-family: "Nunito Sans SemiBold";
        color: #36369B;
        font-size: 18px;
      }
      .survey-question {
        margin: 20px 0px;
        &__type {
          color: #3E3E5B;
          font-size: 15px;
        }
        &__title {
          font-family: Nunito Sans Bold;
          font-size: 19px;
          color: #1F1F52;
        }
        &__options {
          font-family: Nunito Sans;
          font-size: 16px;
          &-label {
            font-family: Nunito Sans Semibold;
            color: #3E3DA3;
          }
        }
      }
    }
  }
  .preview-right {
    width: 50%;
    background-color: #F8F8FF;
    overflow: auto;
    padding: 20px;
    border-radius: 10px;
    .survey-name {
      font-family: Nunito Sans Bold;
      font-size: 19px;
      color: #36369B;
      margin: 0 15px;
    }
  }
`