import React, { useEffect, useState } from "react";
import * as S from "./periodOfTimeSelect.style.js";
import * as P from "../../../../../utils/pipes.js";

// utils
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import Modal from "../../../../../utils/modal.js";
import ModalRangeCalendar from "../../../shared/modals/modalRangeCalendar/ModalRangeCalendar.jsx";

function PeriodOfTimeSelect({
  initialDateRange,
  finishDateRange,
  setInitialDateRange,
  setFinishDateRange,
}) {
  const { i18n: { language }, t } = useTranslation("global");
  const [ modalCustomRangeActive, setModalCustomRangeActive ] = useState(false);
  const currentDate = new Date(Date.now());
  const currentDateWithoutTime = new Date(currentDate.setHours(0,0,0,0));
  const aDayTimeQuantity = 24 * 60 * 60 * 1000;
  const [rangeSelected, setRangeSelected] = useState();

  const [customInitialDateRange, setCustomInitialDateRange] = useState();
  const [customFinishDateRange, setCustomFinishDateRange] = useState();

  const rangeTimesList = [
    {
      id: 1,
      text: t("globally.lastWeek"),
      initialDate: new Date(currentDateWithoutTime.getTime() - 7 * aDayTimeQuantity),
      finishDate: new Date(Date.now())
    },
    {
      id: 2,
      text: t("globally.lastTwoWeeks"),
      initialDate: new Date(currentDateWithoutTime.getTime() - 14 * aDayTimeQuantity),
      finishDate: new Date(Date.now())
    },
    {
      id: 3,
      text: t("globally.lastThreeWeeks"),
      initialDate: new Date(currentDateWithoutTime.getTime() - 21 * aDayTimeQuantity),
      finishDate: new Date(Date.now())
    }
  ];

  useEffect(() => {
    setRangeSelected(rangeTimesList[0])
  }, [])

  useEffect(() => {
    if(rangeSelected) {
      setCustomInitialDateRange(null)
      setCustomFinishDateRange(null)
      setInitialDateRange(rangeSelected.initialDate);
      setFinishDateRange(rangeSelected.finishDate);
    }
  }, [rangeSelected])

  useEffect(() => {
    if(customInitialDateRange && customFinishDateRange) {
      setInitialDateRange(customInitialDateRange);
      setFinishDateRange(customFinishDateRange);
    } 
  }, [customInitialDateRange, customFinishDateRange])

  return <S.PeriodOfTimeSelect>
    <Modal open={modalCustomRangeActive}>
      <ModalRangeCalendar
        setSeeDoubleCalendarRangeActive={setModalCustomRangeActive}
        initialDateRange={customInitialDateRange}
        finishDateRange={customFinishDateRange}
        setInitialDateRange={setCustomInitialDateRange}
        setFinishDateRange={setCustomFinishDateRange}

      />
    </Modal>
    <div className="label">
      <i className="icon icon zaia-i-time icono-faltante"></i>
      <div className="text">{t("globally.selectPeriodOfTime")}</div>
      <Popup
        trigger={
          <i className="icon-info icon zaia-i-info"></i>
        }
        on={["hover", "focus"]}
        closeOnDocumentClick
        keepTooltipInside
        position={["right center"]}
        nested
      >
        <S.TabDescription>
          {t("globally.customRangeDescription")}
        </S.TabDescription>
      </Popup>
    </div>
    <div className="input-container">
      <Popup
        trigger={
          <div className="input">
            <div className="input__text">
              {
                customInitialDateRange && customFinishDateRange 
                  ? `${P.shortDate(customInitialDateRange, t, true, language)} - ${P.shortDate(customFinishDateRange, t, true, language)}` 
                  : rangeSelected?.text
              }
            </div>
            <i className="input__icon icon zaia-icono-dropdown-light"></i>
          </div>
        }
        closeOnDocumentClick
      >
        {
          close => (
            <S.PopUpitems>
              {
                rangeTimesList.map((item) => {
                  return (
                    <div 
                      className={`item ${item.id === rangeSelected?.id && (!customInitialDateRange && !customFinishDateRange) && "item--selected"}`}
                      key={item.id}
                      onClick={() => {
                        setRangeSelected(item);
                        close();
                      }}
                    >{item.text}</div>
                  )
                })
              }
              <div className="item-separator"></div>
              <div className={`item item--custom ${customInitialDateRange && customFinishDateRange && "item--selected"}`}
                onClick={() => {
                  setModalCustomRangeActive(true);
                  close();
                }}
              >
                {t("globally.customRange")}
              </div>
            </S.PopUpitems>
          )
        }
      </Popup>
    </div>
  </S.PeriodOfTimeSelect>
}

export default PeriodOfTimeSelect;