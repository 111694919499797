import React from "react";
import * as S from './ErrorMessage.style';

function ErrorMessage({
  errorMessage,
  activeError,
  color
}) {

  return (
    <S.ErrorMessage 
      activeError={activeError}
      color={color}
    >
      {errorMessage}
    </S.ErrorMessage>
  )
}

export default ErrorMessage;