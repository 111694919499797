import styled from "styled-components";

export const ChartsOutcomes = styled.div`
  border-top: 0.5px solid #EEEEEE;
  padding-top: 17px;
  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__tabs {
      
    }

    &__title {

    }
    &__info {
    }

    &__selects {
      align-items: flex-end;
      display: flex;
      .select-box{
        margin-left: 10px;
      }
      .button-add-event {
        display: flex;
        padding: 8px 10px 8px 10px;
        border-radius: 5px;
        background: var(--Purple-II, #6C6BCC);
        color: #FEFEFF;
        font-family: "Nunito Sans";
        font-size: 15px;
        outline: none;
        border: none;
        cursor: pointer;
        margin-left: 10px;
      }
      .label {
        display: flex;
        color: #36369B;
        font-family: "Nunito Sans";
        font-size: 14px;
        margin-bottom: 5px;
        &__marker-circle {
          background-color: #FFF;
          border: 2px #3E8DF3 solid;
          border-radius: 50%;
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
    }
  }
  .chart-details {
    background-color: #F2F3F8;
    border-radius: 8px;
    padding: 12px 19px 20px;
    margin-top: 7px;
    &__title {
      display: flex;
      align-items: center;
      margin-bottom: 13px;
      justify-content: flex-end;
      &-circle {
        margin-right: 5px;
        background-color: #FFFFFF;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        border: 4px solid #3E8DF3;
      }
      &-pre-text {
        color: #3E8DF3;
        font-family: "Nunito Sans";
        font-size: 12px;
      }
      &-text {
        margin: 0px 5px;
        color: #3E8DF3;
        font-family: "Nunito Sans Bold";
        font-size: 12px;
      }
      &-post-text {
        color: #3E8DF3;
        font-family: "Nunito Sans Bold";
        font-size: 12px;
      }
    }
    &__info {
      display: flex;
      justify-content: space-between;
    }
    &__left {
      .cards {
        border-radius: 14px;
        display: flex;
        background: #FFF;
        height: 100%;
        .card {
          padding: 10px 17px;
          &--left {
          }
          &--right {
            /* border-left: 1px solid #DCDCDC; */
            min-width: 279px;
          }
          &__title {
            white-space: nowrap;
            color: #3E3E5B;
            font-family: "Nunito Sans";
            font-size: 15px;
            margin-bottom: 6px;
          }
          &__text {
            white-space: nowrap;
            &-flex {
              display: flex;
              justify-content: space-between;
            }
            color: #424272;
            font-family: "Nunito Sans";
            font-size: 12px;
            margin-bottom: 3px;
            &--last {
              margin-bottom: 0px;
            }
          }
        }
      }
    }
    &__right {
      /* width: 390px; */
      margin-left: 15px;
      .events-container {
        background-color: #FFF;
        border-radius: 14px;
        height: 100%;
        /* padding: 10px; */
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        .events-list {
          display: flex;
          flex-wrap: wrap;
          padding: 10px 0px 0px 10px;
          justify-content: end;
        }
        .events-options {
          justify-content: end;
          display: flex;
          margin: auto 10px 10px 10px;
          text-decoration: underline;
          font-size: 16px;
          color: #526479;
          font-family: "Nunito Sans";
          &__clear {
            cursor: pointer;
          }
          &__all {
            cursor: pointer;
            margin-left: 12px;
            opacity: 0.4;
            color: #526479;
          }
        }
        .events-empty {
          width: 182px;
          height: 64px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #7D7C8B;
          font-family: "Nunito Sans";
          font-size: 18.137px;
          border-radius: 14px;
          margin: 0px 10px 10px 10px;
        }
      }
    }
  }
  .chart-container {
    margin-top: 20px;
    &__reaction {
      margin-top: 150px;
    }
  }
`

export const Event = styled.div`
  margin: 0px 10px 10px 0px;
  display: flex;
  padding: 4px 5px;
  align-items: center;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 0px 1.8px 0px rgba(35, 34, 157, 0.25);
  .circle {
    width: 13px;
    height: 13px;
    box-shadow: 0px 0px 0px 3px ${({color}) => color + 66};
    background-color: ${({color}) => color};
    border-radius: 50%;
    margin: 0px 3px;
  }
  .name {
    margin: 0px 5px;
    color: #424272;
    font-family: "Nunito Sans";
    font-size: 14px;
  }
  .close {
    display: flex;
    padding: 5px;
    justify-content: center;
    border-radius: 32.887px;
    background: #EEF1F4;
    font-size: 5px;
    cursor: pointer;
  }
`