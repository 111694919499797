import styled from "styled-components";

export const SurveyList = styled.div`
  height: 100%;
  position: relative;
  .surveys-list-title {
    color: #1F1F52;
    font-family: "Nunito Sans Bold";
    font-size: 16px;
  }
  .input-container {
    margin: 20px 0px;
  }
  .list {
    height: ${({searcher}) => searcher === "" ? "calc(100% - 100px)" : "calc(100% - 142px)"} ;
    &__results {
      color: #C0BFDC;
      font-family: "Nunito Sans";
      font-size: 17px;
      font-style: italic;
      margin: 20px 0px;
      &--hidden {
        /* visibility: hidden; */
        display: none;
      }
    }
    &__container {
      /* padding: 0 10px; */
      overflow: auto;
      height: 100%;
    }
    .survey-container {
      cursor: pointer;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px #23229D24;
      margin-bottom: 20px;
      background-color: white;
      position: relative;
      &__name {
        padding: 15px 10px 40px 10px;
        font-family: Nunito Sans;
        font-size: 14px;
      }
      &__bottom {
        position: absolute;
        right: 15px;
        bottom: 10px;
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        color: #3E3DA3;
        font-family: "Nunito Sans";
        font-size: 14px;
        .preview {
          cursor: pointer;
          margin-left: 12px;
          text-decoration: underline;
        }
      }
      &--selected {
        background: #D2D2FF;
        .survey-container__name {
          font-family: "Nunito Sans Bold";
          color: #36369B;
        }
      }
    }
  }
`