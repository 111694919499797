import React, { useEffect, useRef, useState } from "react";
import * as S from "./GenerativeAIMedpalm.style";
import * as P from "../../../utils/pipes";
import { useTranslation } from "react-i18next";

// Components
import ChatbotGeneralCase from "./components/cases/chatbotGeneralCase";
import ChatbotPatientCase from "./components/cases/chatbotPatientCase";
import ChatbotProcessCase from "./components/cases/chatbotProcessCase";
import ChatbotGuidanceCase from "./components/cases/chatbotGuidanceCase";
import { useStateValue } from "../../../contextAPI/StateProvider";
import { Actions } from "../../../utils/actionsIds";
import useRecordNavigation from "../../../hooks/useRecordNavigation";
import useUploadTempDoc from "../../../hooks/useUploadTempDoc";
import useUploadDoc from "../../../hooks/useUploadDoc";
import { useMutation } from "@apollo/client";
import { UPLOAD_PATIENT_GUIDE_FILE } from "../../../graphql/mutations/Chat";
import Modal from "../../../utils/modal";
import ZaiaModalResponseContent from "../../zaiaComponents/zaiaModalResponseContent";
import ZaiaReaction from "../../zaiaComponents/zaiaReaction";

// assets
const florenceLogo = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/monaco-ai.svg`
const florenceMedpalmLogo = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/florence-medpalm.svg`
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function GenerativeAIMedpalm({
  closeFunc,
  maxHeight,
}) {    
  const {t, i18n: { language }} = useTranslation("global");

  const [{ 
    medpalmFlorenceView,
    medpalmFlorencePatient,
    medpalmFlorenceProcess,
    medpalmFlorencePartition
   }, dispatch] = useStateValue();

  const chatbotViews = [
    {
      id: 1,
      name: t("chatbotAI.general.title"),
      description: t("chatbotAI.general.subtitle"),
      action: Actions.FLORENCE.CHAT_MEDICOL_GENERAL
    },
    {
      id: 2,
      name: t("globally.patients"),
      description: t("chatbotAI.patients.subtitle"),
      action: Actions.FLORENCE.CHAT_MEDICOL_PATIENTS
    },
    {
      id: 3,
      name: t("chatbotAI.guidelines.title"),
      description: t("chatbotAI.guidelines.subtitle"),
      action: Actions.FLORENCE.CHAT_MEDICOL_GUIDES
    },
    {
      id: 4,
      name: t("chatbotAI.patientsGuidelines.title"),
      description: t("chatbotAI.patientsGuidelines.subtitle"),
      action: Actions.FLORENCE.CHAT_MEDICOL_GUIDES_AND_PATIENTS
    }
  ]

  const changeView = (view) => {
    dispatch({
      type: "SET_MEDPALM_FLORENCE_VIEW",
      medpalmFlorenceView: view,
    });
    selectPatient();
    selectProcess();
    selectPartition();
  } 

  const selectPatient = (patient) => {
    dispatch({
      type: "SET_MEDPALM_FLORENCE_PATIENT",
      medpalmFlorencePatient: patient,
    });
  }

  const selectProcess = (process) => {
    dispatch({
      type: "SET_MEDPALM_FLORENCE_PROCESS",
      medpalmFlorenceProcess: process,
    });
  }

  const selectPartition = (partition) => {
    dispatch({
      type: "SET_MEDPALM_FLORENCE_PARTITION",
      medpalmFlorencePartition: partition,
    });
  }

  return (
    <S.GenerativeAIMedpalm>
      {
        medpalmFlorencePartition &&
        <div className="partition-selected">
          <div className="partition-selected__container">
            <div className="partition-selected__point"></div>
            <div className="partition-selected__text">
              {medpalmFlorencePartition.text}
            </div>
          </div>
        </div>
      }
      <div className="chatbot">
        <Header 
          closeFunc={closeFunc}
          setChatbotViewSelected={changeView}
          chatbotViewSelected={medpalmFlorenceView}
          chatbotViews={chatbotViews}
          processNameSelected={P.dataTranslation(medpalmFlorenceProcess?.processName, language)}
        />
        <div className="chat-container">
          {
            medpalmFlorenceView === 0 &&
            <ChatbotChooseOptions
              setChatbotViewSelected={changeView}
              chatbotViews={chatbotViews}
            />
          }
          {
            medpalmFlorenceView === 1 &&
            <ChatbotGeneralCase/>
          }
          {
            medpalmFlorenceView === 2 &&
            <ChatbotPatientCase
              selectedPatient={medpalmFlorencePatient}
              selectPatient={selectPatient}
            />
          }
          {
            medpalmFlorenceView === 3 &&
            <ChatbotProcessCase
              selectedProcess={medpalmFlorenceProcess}
              selectedPartition={medpalmFlorencePartition}
              selectProcess={selectProcess}
              selectPartition={selectPartition}
            />
          }
          {
            medpalmFlorenceView === 4 &&
            <ChatbotGuidanceCase
              selectedPatient={medpalmFlorencePatient}
              selectedProcess={medpalmFlorenceProcess}
              selectedPartition={medpalmFlorencePartition}
              selectPatient={selectPatient}
              selectProcess={selectProcess}
              selectPartition={selectPartition}
            />
          }
        </div>
      </div>
    </S.GenerativeAIMedpalm>
  );

}

const Header = ({ 
  closeFunc, 
  setChatbotViewSelected,
  chatbotViewSelected,
  chatbotViews,
  processNameSelected
}) => {

  const {t, i18n: { language }} = useTranslation("global");

  return (
    <S.HeaderDiv>
      <div className="header">
        <div className="header__title">
          <div className="header__title-medpalm">
            <img className="header__title-logo" src={florenceLogo} alt={t("globally.chatbotName")} />
            <div className="header__title-medpalm-text">
              Med-Gemeni
              {/* <img src={florenceMedpalmLogo} alt="" className="header__title-medpalm-icon" /> */}
            </div>
          </div>
          <div className="header__subtitle">{chatbotViews.find((item) => item.id === chatbotViewSelected)?.name}</div>
          {
            processNameSelected &&
            <div className="header__process-name">
              {processNameSelected}
            </div>
          }
        </div>
        <div className="header__right">
          {
            chatbotViewSelected !== 0 &&
            <div className="header__reset-button" onClick={() => setChatbotViewSelected(0)}>{t("chatbotAI.reset")}</div>
          }
          <div className="header__close-chat" onClick={() => closeFunc(false)}>
            <i className="header__close-chat-icon icon zaia-icono-cerrar" />
          </div>
        </div>
      </div>
    </S.HeaderDiv>
  );
};

const ChatbotChooseOptions = ({ 
  chatbotViews, 
  setChatbotViewSelected 
}) => {
  const { recordAction } = useRecordNavigation();
  const {t} = useTranslation("global");
  const UPLOAD_GUIDE_PATIENT_ENDPOINT = process.env.REACT_APP_PATIENT_GUIDE_MEDPALM_ENDPOINT;
  const BUCKET_GUIDE_PATIENT = process.env.REACT_APP_BUCKET_CHATBOT_PATIENT_GUIDE;
  const ID_STANDARD_PATIENT_GUIDE_MEDPALM = process.env.REACT_APP_ID_STANDARD_PATIENT_GUIDE_MEDPALM;

  const {tempDoc, dataDoc, errorFormat, uploadTempDoc, deleteDoc} = useUploadTempDoc();
  const {uploadDoc, data, loading, error} = useUploadDoc();
  const fileInput = useRef(null);
  const [saveDoc, {data: saveDocData, loading: saveDocLoading, error: saveDocError}] = useMutation(UPLOAD_PATIENT_GUIDE_FILE);
  const [uploadDocResponse, setUploadDocResponse] = useState(null);

  const handleClick = (e) => {
    fileInput.current.click();
  }

  useEffect(() => {
    if (tempDoc && !errorFormat) {
      uplodPatientGuide()
    }
  }, [tempDoc]);

  const uplodPatientGuide = async () => {
      try {
        let { data: { uploaded_file_data } } = await uploadDoc(tempDoc, UPLOAD_GUIDE_PATIENT_ENDPOINT, BUCKET_GUIDE_PATIENT);
        await saveDoc({
          variables: {
            collection_name: "FLORENCE_PATIENT_MEDICAL_HISTORY",
            data_source_id: "PDF",
            data_source_tag: "PDF",
            embedding_model: "textembedding-gecko-multilingual@001",
            embedding_technology: "google",
            file_bucket_name: uploaded_file_data[0].file_bucket_name,
            file_name: uploaded_file_data[0].file_name,
            model_vector_dimension: 768,
            user_id: ID_STANDARD_PATIENT_GUIDE_MEDPALM
          },
        });
        setUploadDocResponse(true);
        deleteDoc()
        fileInput.current.value = null;
      } catch (err) {
        setUploadDocResponse(false);
        deleteDoc();
        fileInput.current.value = null;
      }
  }

  return (
    <S.ChatbotChooseOptions>
      <div className="view-options">
      <Modal open={uploadDocResponse === true || uploadDocResponse === false || loading || saveDocLoading}>
        <S.ModalResponseReaction>
          <div className="modal-background" onClick={() => {setUploadDocResponse(null); deleteDoc()}}>
          </div>
          <div className="modal-container">
            {
              loading || saveDocLoading 
              ? <ZaiaReaction
                zaiaImg={logoZaiaLoading}
                sizeText="20px"
                textColor="var(--blue-color)"
                widthImg="110px"
              />
              : <ZaiaModalResponseContent
                button1={() => {setUploadDocResponse(null); deleteDoc()}}
                formSuccess={uploadDocResponse}
                title={uploadDocResponse === true ? t("chatbotAI.uploadPatientGuideSuccess") : uploadDocResponse === false ? t("chatbotAI.uploadPatientGuideError") : ""}
                button1Text={t("globally.continue")}
              />
            }
          </div>
        </S.ModalResponseReaction>
      </Modal>
        <div className="chat-container__upload-file">
          <input
            type="file"
            ref={fileInput}
            onChange={(e) => uploadTempDoc(e, true)}
            style={{display: 'none'}}
            // accept="image/*"  
          />
          {
            // uploadedDocument && tempDoc
            // ? <div className="upload-document__box" onClick={handleClick}>
            //   <i className="upload-document__box-icon icon zaia-icon-file"></i>
            //   <div className="upload-document__box-text">{dataDoc?.name}</div>
            // </div>
            // : 
            <div className="upload-document" onClick={handleClick}>
              <div className={`upload-document__text ${errorFormat && "upload-document__text--error"}`}>
                {
                  errorFormat 
                  ? t("chatbotAI.formatError")
                  : t("chatbotAI.uploadPatientGuide")
                }
              </div>
              <i className="upload-document__icon icon zaia-i-upload"></i>
            </div>
          }
        </div>
        {
          chatbotViews.map(
            (view) => {
              return(
                <div className="view-option">
                  <button 
                    className="view-option__button"
                    onClick={() => {
                      recordAction(view.action)
                      setChatbotViewSelected(view.id)}
                    }>
                    {view.name}
                  </button>
                  <div className="view-option__description">
                    {view.description}
                  </div>
                </div>
              )
            }
          )
        }
      </div>
    </S.ChatbotChooseOptions>
  );
};

export default GenerativeAIMedpalm;
