import styled from 'styled-components';

export const PatientReport = styled.div`
  height: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 22px;
    .button-back {
      display: flex;
      background-color: #F8F8FF;
      border-radius: 54.69px;
      outline: none;
      cursor: pointer;
      border: none;
      padding: 5px 20px; 
      align-items: center;
      width: 100%;
      .patient-image {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
      .patient-name {
        font-family: Nunito Sans;
        font-size: 19px;
        margin-left: 6px;
      }
      &__icon {
        font-size: 16px;
        margin-right: 10px;
        color: #36369B;
      }
    }
  }
  .report-data {
    overflow: auto;
    height: calc(100% - 215px);
    /* position: relative; */
    ::-webkit-scrollbar {
      display: none;
    }
    .report-info {
      text-align: center;
      justify-content: center;
      padding: 46px 40px 42px;
      background: linear-gradient(92.46deg, #F4FBFF -4.63%, #D4E3FF 102.31%);
      &__icon {
        font-size: 26px;
        color: #7FABFF;
        justify-content: center;
      }
      &__title {
        margin-top: 10px;
        font-family: Nunito Sans Bold;
        font-size: 20px;
        color: #1F1F52;
      }
      &__number {
        font-family: Nunito Sans light;
        font-size: 33px;
        color: #1F1F52;
        margin-top: 10px;
      }
      &__time-reports {
        font-family: Nunito Sans;
        font-size: 19px;
        color: #1F1F52;
        padding-bottom: 20px;
        margin-top: 10px;
      }
    }
    .report-values-list {
      padding: 25px 20px 110px 20px;
      .report-value {
        &__label {
          margin-left: 10px;
          font-family: Nunito Sans;
          font-size: 19px;
        }
        &__value {
          padding: 10px;
          border-radius: 15px;
          background-color: #F4F4FF;
          font-family: Nunito Sans Bold;
          font-size: 18px;
          color: #1F1F52;
          margin-bottom: 20px;
          min-height: 24px;
        }
      }
    }
    .add-report {
      bottom: 164px;
      right: 15px;
      position: absolute;
      &__button {
        box-shadow: 0px 0px 63.41px 1.47px #23229D5E;
        width: 83px;
        height: 83px;
        border-radius: 50%;
        background-color: #FFFFFF;
        display: flex;
        outline: none;
        border: none;
      }
      &__icon {
        font-size: 27px;
        margin: auto;
        color: #3E3DA3;
      }
    }
  }
  .report-days-list {
    padding: 20px 0px 40px 0px;
    border-radius: 15px 15px 0px 0px;
    position: relative;
    box-shadow: 8px -4px 17px 0px #37358F2B;
    text-align: center;
    &__label {
      font-family: Nunito Sans;
      font-size: 19px;
    }
    .dates-container {
      margin: 15px 10px 0px 10px;
      display: flex;
      /* flex-direction: column; */
      position: relative;
      .slider-container {
        /* width: calc(100% - 30px); */
        width: 100%;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 0 38px 0 35px;
        .alice-carousel__prev-btn {
          position: absolute;
          height: 100%;
          width: 30px;
          top: 0;
          left: -35px;
          padding: 0;
          transition: 0.5s;
        }
        .alice-carousel__next-btn {
          position: absolute;
          height: 100%;
          width: 30px;
          top: 0;
          right: -38px;
          padding: 0;
          transition: 0.5s;
        }
      }
    }
  }
`

export const CarouselButton = styled.button`
  height: 30px;
  width: 30px;
  opacity: ${({disabled}) => disabled ? '0.4' : '1'};
  cursor: pointer;
  border: none;
  background-color: #EFEFFF;
  .icon {
    color: #36369B;
    justify-content: center;
  }
  left: ${({position}) => position === 'prev' ? 0 : '' };
  right: ${({position}) => position === 'prev' ? '' : 0 };
  border-radius: 50%
`

export const SliderBox = styled.div`
  /* padding: 0px 2.5px; */
    width: 182px;
  /* max-width: 230px; */
  /* padding: 0 25px; */
  padding-right: 5px;
  &:first-child {
    margin-left: 0px;
  }
  .slider-box {
    padding: 5px 20px;
    border-radius: 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Nunito Sans;
    font-size: 15px;
    color: ${({selected}) => selected ? "#36369B" : "#6C6BCC"};
    background-color: ${({selected}) => selected ? "#D2D2FF" : "#F0F0FF"};
    font-size: ${({selected}) => selected ? "Nunito Sans Bold" : "Nunito Sans"};

    text-align: center;
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    &-title {
      font-size: 15px;
    }   
    &-subtitle {
      font-size: 13px;
      margin-bottom: 15px;
    }
    &-code {
      font-size: 16px;
    }
    &--selected {
      font-size: Nunito Sans Bold;
    }
  }
`