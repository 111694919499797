import React, { useEffect, useRef, useState } from "react";
import * as S from "./CmpFileInput.style";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";
import { PopUpPayload } from "../../genericTemplate/GenericTemplate.style";

// pipes
import * as P from "../../../../../../../../utils/pipes";

// Components
import SelectorForm from "../selectorForm";
import useUploadTempDoc from "../../../../../../../../hooks/useUploadTempDoc";

function CmpFileInput({
  item,
  insertfunction,
  customFormChangeItem,
  typeView
}) {  

  const { t, i18n: { language } } = useTranslation("global");
  const fileInput = useRef(null);
  const {tempDoc, dataDoc, errorFormat, uploadTempDoc, deleteDoc} = useUploadTempDoc();  
  const [newFile, setNewFile] = useState(true);
  
  
  const handleClick = () => {    
    fileInput.current.click();
  }

  useEffect(() => {    
    if(tempDoc) {
      const userToCustomFormField = {
        customFormFieldParentId: item?.customFormFieldParentId,
        customFormFieldId: item.id,
        value: tempDoc,
        key: tempDoc,
        recordId: customFormChangeItem.recordId,
        documentToSave: tempDoc
      }
      
      insertfunction(userToCustomFormField);
      setNewFile(false);                                                                                                                                                              
    }
  }, [tempDoc]);

  useEffect(() => { 
    setNewFile(customFormChangeItem?.value ? false : true)
  }, [customFormChangeItem]);  

  return (
    <S.CmpFileInput>
      <div className="title">{item.fieldName && P.dataTranslation(item.fieldName, language)}
        <Popup
          trigger={
            <i className="template-header-item-icon icon zaia-alert"></i>
          }
          on={['hover', 'focus']}
          closeOnDocumentClick
          keepTooltipInside
          position={['right center']}
          nested
        >
          <PopUpPayload>
            <p>
            {P.dataTranslation(item.descript, language)}
            </p>
          </PopUpPayload>
        </Popup>
      </div>
      <div className="selection-container">
        <div className="chat-container">
          {
            !newFile
            ? <div className="document-container">
              <div className="document-container__text">{tempDoc ? tempDoc.name : customFormChangeItem?.value}</div>
              <div className="document-container__icon-container"
                onClick={() => {
                  setNewFile(true);
                  deleteDoc();
                  fileInput.current.value = null;
                }} 
              >
                <i className="document-container__icon icon zaia-icono-cerrar"></i>
              </div>
            </div>
            : <>
              <input
                type="file"
                ref={fileInput}
                onChange={(e) => uploadTempDoc(e, true)}
                style={{display: 'none'}}
                // accept="image/*"  
              />
              <div className="upload-document" onClick={handleClick}>
                <div className={`upload-document__text ${errorFormat && "upload-document__text--error"}`}>
                  {
                    errorFormat 
                    ? t("chatbotAI.formatError")
                    : t("globally.chooseFile")
                  }
                </div>
                <i className="upload-document__icon icon zaia-i-upload"></i>
              </div>
            </>
          }
          {
            // tempDoc || customFormChangeItem?.value
            // ? (
            //   <div className="document-container">
            //     <div className="document-container__text">{tempDoc ? tempDoc.name : customFormChangeItem?.value}</div>
            //     <div className="document-container__icon-container"
            //       onClick={() => {
            //         deleteDoc();
            //         fileInput.current.value = null;
            //       }} 
            //     >
            //       <i className="document-container__icon icon zaia-icono-cerrar"></i>
            //     </div>
            //   </div>
            // )
            // : (
            //   <div className="document-label">
            //     Select file to upload
            //   </div>
            // )
          }
        </div>
      </div>
    </S.CmpFileInput>
  );
}

export default CmpFileInput;
