import styled from "styled-components";

export const NoteText = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 32px;
  box-sizing: border-box;
  .note-label {
    font-family: Nunito Sans;
    font-size: 17px;
    display: flex;
    align-items: center;
    margin-bottom: ${({maxViewActived}) => maxViewActived ? "15px" : "8px"};
    &__icon { 
      margin-right: 5px;
    }
  }
  .note-space-text {
    position: relative;
    height: ${({maxViewActived}) => maxViewActived ? "calc(100% - 38px)" : "calc(100% - 31px)"};
    &__templates {
      position: absolute;
      right: 15px;
      cursor: pointer;
      bottom: 44px;
      display: flex;
      background-color: #F0F0FF;
      height: 24px;
      border-radius: 50px;
      align-items: center;
      padding: 4px 10px;
      color: #6C6BCC;
      font-family: Nunito Sans;
      font-size: 12px;
      &-icon {
        margin-left: 5px;
      }
    }
  }
`

export const TemplatesOptions = styled.div`
  padding: 10px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 12px 0px #23229D33;
  .title {
    color: #1F1F52; 
    font-family: Nunito Sans Bold;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .card-options {
    max-height: 300px;
    overflow: auto;
  }
  .card-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    width: 188px;
    background-color: #D2D2FF;
    color: #3E3DA3;
    border-radius: 20px;
    font-family: Nunito Sans;
    font-size: 12px;
    padding: 5px 10px;
    justify-content: center;
    text-align: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
`
