import React, { useEffect } from "react";
import * as S from "./ListOfChats.style";
import { useTranslation } from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

// Components
import OneSingleChat from "../OneSingleChat";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// Pipes 
import * as P from "../../../../../utils/pipes";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";
import { useStateValue } from "../../../../../contextAPI/StateProvider";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;

function ListOfChats({
  setQuantityChats, 
  setSelectedChat, 
  groupChatsOrderRecent,  
  groupChatsLoading, 
  groupChatsError,
  filterText,
  filterProccessIds
}) {
  const {t} = useTranslation("global");
  const { chatID } = useParams();

  useEffect(() => {
    setQuantityChats(groupChatsOrderRecent?.length);
  },[groupChatsOrderRecent, setQuantityChats, chatID])

  const getFilterText = (filterText, group) => {
    return filterText 
      ? String(group.chatNameUser).toLowerCase().includes(filterText.toLowerCase())
      : true
  }

  if(groupChatsLoading) 
    return (
      <S.ListOfGroups>
        <ZaiaReaction
          widthImg="150px"
          zaiaImg={logoZaiaLoading}
        />  
      </S.ListOfGroups>
    );

  if(groupChatsError) 
    return (
      <S.ListOfGroups>
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthImg="100px"
          text={t("chat.errorLoadingChats")}
          sizeText="15px"
          widthText="240px"
        />
      </S.ListOfGroups>
    );

  if(groupChatsOrderRecent?.length < 1) 
    return (
      <S.ListOfGroups>
        <ZaiaReaction
          zaiaImg={logoZaiaEmpty}
          widthImg="100px"
          text={t("chat.emptyChats")}
          sizeText="15px"
          widthText="200px"
        />
      </S.ListOfGroups>
    );

  return (
    <S.ListOfGroups>
      {
        groupChatsOrderRecent?.map(group => {
          if(group.chatNameUser) {
            if (
              getFilterText(filterText, group)
            ) {
              if( filterProccessIds?.length > 0) {
                if (
                  filterProccessIds?.indexOf(group.userToProcess.processId) !== -1
                ) {
                  return (
                    <SingleChat 
                      key={group.id}
                      group={group} 
                      setSelectedChat={setSelectedChat}
                      chatID={chatID}
                    />
                  ) 
                } else return null
              } else {
                return (
                  <SingleChat 
                    key={group.id}
                    group={group} 
                    setSelectedChat={setSelectedChat}
                    chatID={chatID}
                  />
                ) 
              }
            } else return null
          } else return null          
        })
      }
    </S.ListOfGroups>
  )
}

function SingleChat({
  group,
  chatID,
  setSelectedChat
}) {
  const {t, i18n: { language }} = useTranslation("global");
  const { width } = useWindowDimensions();
  const history = useHistory();
  const location = history.location.pathname.split('/')[1];
  const [, dispatch] = useStateValue();


  const handleClick = (group) => {
    
    dispatch({
      type: "SET_CONTEXT_PROCESS",
      contextProcess: group.userToProcess.process?.id,
    });

    history?.push(`/${location}/` + group.id);
    setSelectedChat(group);
  }
  

  return (
    <div className="chat-box" 
      key={group.id}
      onClick={() => handleClick(group)}>
      <OneSingleChat
        active={group.id === chatID && width >= 768 ? true : false}
        img={group.chatPhotoUser}
        patient={group.chatNameUser}
        processName={P.dataTranslation(group.userToProcess.process?.processName, language)}
        time={group.messages[0]?.createdAt ? P.getDynamicTime(group.messages[0]?.createdAt, t, language) : null}
        numMsg={null}
        message={group.messages[0]?.message.replace('{{medicine}}','')}
        online
      />
    </div>
  ) 
} 


export default ListOfChats;
