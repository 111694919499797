import React, { useContext } from "react";
import * as S from "./ZaiaSplash.style";
import ZaiaReaction from "../zaiaReaction";
import Cookies from "js-cookie";
import { ThemeContext } from 'styled-components';
import { useTranslation } from "react-i18next";

const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const WaveZaiaSplash = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/Wave-ZAIA-Splash.svg`;

function ZaiaSplash({
  blur = false
}) {
  const [t] = useTranslation("global");
  const webView = Cookies.get('webView');
  const theme = useContext(ThemeContext);

  return(
    <S.ZaiaSplash webView={webView}>
      {blur && <div className="zaia-splash-blur"/>}
      <div className="zaia-loader">
        <ZaiaReaction 
          widthImg="170px"
          zaiaImg={logoZaiaLoading}     
          text={t("globally.loadingFlorenz")}
          weightText={false}
          textColor={theme.primaryColor}
          loadingPoints={true}  
        />
      </div>
      <div className="wave-top">
        <img src={WaveZaiaSplash} alt="" />
      </div>
      <div className="wave-bottom">
        <img src={WaveZaiaSplash} alt="" />
      </div>
    </S.ZaiaSplash>
  );
};

export default ZaiaSplash;