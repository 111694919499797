import React, { useMemo } from "react";
import * as S from "./CmpSliderHorizontalOptions.style";
import * as P from "../../../../../../../utils/pipes";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useTranslation } from "react-i18next";
import useChatVerification from "../../../../../../../hooks/useGetListOptions";

function CmpSliderHorizontalOptions({
  title,
  item,
  insertfunction,
  customFormChangesList,
  customFormChangeItem,
  customFormFields,
  isHistorical,
}) {
  const {
    i18n: { language },
  } = useTranslation("global");

  const itemParent = useMemo(() => {
    const itemParent = customFormChangesList.find(
      (change) => change.customFormFieldId === item?.customFormFieldParentId
    );
    return itemParent;
  }, [item, customFormChangesList]);

  const {listOptions} = useChatVerification(item, itemParent, customFormFields, isHistorical);

  const changeOptionItem = (fieldOption) => {
    const userToCustomFormField = {
      customFormFieldId: item?.id,
      parentKey: item?.customFormTypeField.fieldOptions.find(
        (field) => field.key === fieldOption.key
      ).parentKey,
      customFormFieldParentId: item?.customFormFieldParentId,
      value: P.dataTranslation(fieldOption.names, language),
      key: fieldOption.key,
    };
    insertfunction(userToCustomFormField);
  };

  const optionKey = customFormChangeItem && customFormChangeItem.key;

  const currentPayload = useMemo(() => {
    const currentOption = listOptions.find((item) => item.key === optionKey);
    let payload;
    if (P.dataTranslation(currentOption.payload, language)) {
      payload = P.dataTranslation(currentOption.payload, language);
    } else {
      const payloadFormated = JSON.parse(currentOption.payload);
      try {
        payload = "msg" in payloadFormated ? payloadFormated.msg : null;
      } catch (err) {
        payload = null;
      }
    }

    return payload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionKey]);

  const carouselButton = (position, disabled) => {
    return (
      <S.CarouselButton position={position} disabled={disabled} type="button">
        <i
          className={`icon ${
            position === "prev" ? "zaia-icono-atras" : "zaia-i-next"
          }`}
        />
      </S.CarouselButton>
    );
  };

  return (
    <S.CmpSliderHorizontalOptions>
      <h5>{title}</h5>
      <div className="boxes-container">
        <AliceCarousel
          mouseTracking
          disableDotsControls
          autoWidth
          renderPrevButton={(e) => carouselButton("prev", e.isDisabled)}
          renderNextButton={(e) => carouselButton("next", e.isDisabled)}
        >
          {listOptions.map((data) => {
            return (
              <S.SliderBox active={data.key === optionKey} key={data.id}>
                <div
                  className="slider-box"
                  onClick={() => changeOptionItem(data)}
                >
                  <div className="slider-box-title">
                    {P.dataTranslation(data.names, language)}
                  </div>
                </div>
              </S.SliderBox>
            );
          })}
        </AliceCarousel>
      </div>
      {!currentPayload ? null : (
        <div className="big-info-card">
          <h4>{currentPayload}</h4>
        </div>
      )}
    </S.CmpSliderHorizontalOptions>
  );
}

export default CmpSliderHorizontalOptions;
