import { gql } from "@apollo/client";

export const SUBSCRIPTION_GET_USER_TO_PROCESS_HISTORY = gql`
  subscription SUBSCRIPTION_GET_USER_TO_PROCESS_HISTORY(
    $patientId: uuid, 
    $processId: Int,
    $initialDate: timestamptz, 
    $finalDate: timestamptz, 
  ) {
    userToProcessHistory(where: {created_at: {_gte: $initialDate, _lte: $finalDate}, userToProcess: {userId: {_eq: $patientId}}, processId: {_eq: $processId}}, order_by: {created_at: desc}) {
      id
      processId
      stageId
      doctorId
      descript
      created_at
      userToProcessId
      latestCustomFormId
      customFormFieldChanges
      processStage {
        id
        stageName
      }
      doctor {
        name
        lastname
        picture
        gender
      }
    }
  }
`