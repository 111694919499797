import { useStateValue } from "../contextAPI/StateProvider";

// Utils
import { useMutation } from "@apollo/client";
import { MUTATION_INSERT_DOCTOR_TRACKING } from "../graphql/mutations/Doctor";
import useDoctorData from "./useDoctorData";

export default function useRecordNavigation() {

  const [{ selectedPatient }] = useStateValue();
  const [insertDoctorTracking] = useMutation(MUTATION_INSERT_DOCTOR_TRACKING);

  const {doctorData} = useDoctorData();

  const recordAction = async (id, patientId, processId) => {    
    try {
      const variables = {}
      if(patientId || selectedPatient?.id) {
        variables.patientId = patientId || selectedPatient?.id;
      }
      if(processId) {
        variables.programId = processId;
      }
      if(id) {
        variables.sectionId = id;
      }
      if(doctorData?.id) {
        variables.doctorId = doctorData?.id;
      }
      if(Object.keys(variables).length > 0) {
        await insertDoctorTracking({
          variables: variables,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  return {
    recordAction: recordAction
  };
}