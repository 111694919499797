import React from "react";
import * as S from "./AINavigator.style.js";
import { useParams } from "react-router-dom";

// utils
import PatientDashboardView from "./components/patientDashboardView/PatientDashboardView.jsx";
import PatientSelectorView from "./components/patientSelectorView/PatientSelectorView.jsx";

function AINavigator() {
  const { patientID } = useParams();

  return <S.AINavigator>
    {
      patientID 
      ? <div className="patient-dashboard-view">
        <PatientDashboardView />
      </div>
      : <div className="patient-selector-view">
        <PatientSelectorView />
      </div>
    }
  </S.AINavigator>
}

export default AINavigator;