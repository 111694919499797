import styled from "styled-components";

export const CheckboxRoundDiv = styled.div`
  display: flex;
  .icon-checkbox {  
    cursor: pointer;
    font-size: 15px;
    color: var(--blue-color);
    margin-right: 7px;
    display: flex;
    margin: auto 6px auto 0 
  }
  .text-checkbox {
    font-size: ${({fontSize}) => fontSize ? fontSize : '10px'};
    font-family: 'Nunito Sans';
    display: flex;
    margin: auto 0
  }
`;
