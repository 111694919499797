import React, { useEffect, useState } from "react";
import * as S from  "./AiNavigatorSurveys.style";
import { useTranslation } from "react-i18next";
import { Actions } from "../../../../../utils/actionsIds";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";

// pipes
import * as P from "../../../../../utils/pipes";
import Modal from "../../../../../utils/modal";
import { QUERY_GET_CUSTOM_FORMS_FIELD_MARKERS } from "../../../../../graphql/queries/CustomForm";
import { useQuery } from "@apollo/client";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import OutcomesTabs from "../outcomesTabs/";
import SurveyQuestionsList from "./surveyQuestionsList";
import SurveyQuestionsCharts from "./surveyQuestionsCharts/SurveyQuestionsCharts";
import { QUERY_GET_SURVEYS_BY_ID_PROCESS } from "../../../../../graphql/queries/Surveys";
import Popup from "reactjs-popup";
import ModalSendSurvey from "../../../surveys/components/modalSendSurvey";
import ModalAddSurveyData from "../../../surveys/components/modalAddSurveyData";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import ModalSurveys from "../../../surveys/components/modalSurveys/ModalSurveys";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";

const noDataAvailable = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-available.svg`;

function AiNavigatorSurveys() {

  const { t, i18n: { language } } = useTranslation("global");
  const location = useLocation();  
  const [{ selectedPatient }] = useStateValue();
  const [processSelected, setProcessSelected] = useState(selectedPatient?.userToProcesses.filter((item) => item?.process && item.process.processToCustomForms.length > 1)[0]?.process?.id);
  const { recordAction } = useRecordNavigation();
  const [surveySelected, setSurveySelected] = useState();
  const [modalSendSurveyActive, setModalSendSurveyActive] = useState(false);
  const [modalAddSurveyDataActive, setModalAddSurveyDataActive] = useState(false);

  const { data: markerFormField, refetch: markerFormFieldRefetch } = useQuery(QUERY_GET_CUSTOM_FORMS_FIELD_MARKERS, {
    variables: { 
      customFormParent: selectedPatient?.userToProcesses.find((userToProcess) => userToProcess?.process?.id === processSelected)?.process?.processToCustomForms[0]?.customFormId 
    },
  })

  const { data: surveysData } = useQuery(QUERY_GET_SURVEYS_BY_ID_PROCESS, {
    variables: {
      processId: processSelected
    }
  })

  useEffect(() => {
    const searchLocation = location.search;
    const params = new URLSearchParams(searchLocation);

    if(surveysData) {
      
      if(Boolean(params.get("survey")) === true) {
        console.log(Boolean(params.get("survey")));
        if(surveysData?.customForm.find((survey) => survey.id === parseInt(params.get("survey")))) {
          setSurveySelected(surveysData?.customForm.find((survey) => survey.id === parseInt(params.get("survey")))?.id)
        } else {
          setSurveySelected(surveysData?.customForm[0]?.id);
        }
      } else {
        setSurveySelected(surveysData?.customForm[0]?.id);
      }
    }
  }, [surveysData])

  
  

  // useEffect(() => {
  //   const searchLocation = location.search;
  //   const params = new URLSearchParams(searchLocation);
  //   if(Boolean(params.get("survey")) === true) {
  //     setSurveySelected(sections[1])
  //   } 
  // }, [])


  useEffect(() => {
    markerFormFieldRefetch();
  }, [selectedPatient])

  const [viewTab, setViewTab] = useState(1);

  const [tabs, setTabs] = useState([])

  useEffect(() => {
    const inputNumberExist = surveysData?.customForm.find((item) => item.id === surveySelected)?.formGroups.some((survey) => survey.customFormFields.some((field) => field.typeFieldValue === "numberInput"));
    if(inputNumberExist) {
      setTabs([...tabs, {
        id: 2,
        icon: "zaia-i-survey-chart",
        name: t("surveys.surveyChart"),
        description: t("surveys.surveyChartDescription"),
        action: Actions.OUTCOMES.MEDICAL_GRAPHIC
      }])
    } else {
      setTabs([
        {
          id: 1,
          icon: "zaia-i-table-view",
          name: t("surveys.generalView"),
          description: t("surveys.generalViewDescription"),
          action: Actions.OUTCOMES.MEDICAL_TABLE
        }
      ])
    }
  }, [surveySelected])

  if(selectedPatient?.userToProcesses.filter((item) => item.process && item.process.processToCustomForms.length > 1).length === 0) 
    return (
      <S.AiNavigatorSurveys>
        <div className="reaction">
          <ZaiaReaction
            zaiaImg={noDataAvailable}
            text={t("globally.noData")}
            subText={t("globally.noDataDescription")}
            sizeText="33.25px"
            sizeSubText="15px"
            textColor="var(--blue-color)"
            widthImg="210px"
          />
        </div>
      </S.AiNavigatorSurveys>
    )

  return(
    <S.AiNavigatorSurveys>
      <Modal open={modalSendSurveyActive}>
        <ModalSurveys
          setModalActive={setModalSendSurveyActive}
          processId={processSelected}
          patientId={selectedPatient?.id}
          surveyId={surveySelected}
        />
      </Modal>

      <div className="header-options">
        <div className="header-options__section">
          <div className="principal-selects-container">
            <div className="select-container select-container--program">
              <div className="select-container__label">
                {t("globally.programName")}
              </div>
              <ZaiaSelect
                type="select"
                setValue={(e) => {
                    recordAction(Actions.OUTCOMES.SELECT_PROGRAM, e);
                    setProcessSelected(e)
                  }
                }
                value={processSelected}
                items={selectedPatient?.userToProcesses.filter((item) => item.process && item.process.processToCustomForms.length > 1).map(
                  (userToProcess) => {
                    return {
                      value: userToProcess.process?.id,
                      text: P.dataTranslation(userToProcess.process?.processName, language)
                    }
                  }
                )}
                shadow={false}
                fontSize="13px"
                backgroundColor={"#36369B"}
                borderRadius={"10px"}
                heightList={"200px"}
                textColor={"#FFFFFF"}
                arrowColor={"#FFFFFF"}
                borderColor={"#36369B"}
                textItemsColor={"#36369B"}
              />
            </div>
            <div className="select-container select-container--survey">
              <div className="select-container__label">
                {t("surveys.surveyName")}
              </div>
              <ZaiaSelect
                type="select"
                setValue={(e) => {
                    setSurveySelected(e)
                  }
                }
                value={surveySelected}
                items={surveysData?.customForm.map((survey) => {
                    return {
                      value: survey.id,
                      text: P.dataTranslation(survey.customFormName, language)
                    }
                  })
                }
                shadow={false}
                fontSize="13px"
                backgroundColor={"#FFFFFF"}
                borderRadius={"10px"}
                heightList={"200px"}
                textColor={"#6C6BCC"}
                arrowColor={"#6C6BCC"}
                borderColor={"#FFFFFF"}
                textItemsColor={"#111111"}
              />
            </div>
          </div>
          <div className="update-outcome-button">
            <button className="update-outcome-button__button" onClick={
              () => {
                setModalSendSurveyActive(true);
                recordAction(Actions.OUTCOMES.ADD_UPDATE);
              }
            }>
              <div className="update-outcome-button__text">
                {t("globally.newSurvey")}
              </div>
              <i className="update-outcome-button__icon icon zaia-icono-agregar"></i>
            </button>
          </div>
        </div>
        <div className="header-options__section">
          <div className="outcomes-tabs">
            <OutcomesTabs
              setViewTab={setViewTab}
              viewTab={viewTab}
              tabs={tabs}
            />
          </div>
          {/* {
            viewTab === 1 &&
            <div className="filter-buttons-container">
              {
                viewSelected === 1 &&
                <>
                  <div className={`highlights-button ${ highlightsFilter && "highlights-button--active"}`} onClick={() => setHighlightsFilter(!highlightsFilter)}>
                    <i className={`highlights-button__icon icon ${ highlightsFilter ? "zaia-star-on" : "zaia-star-off"}`}></i>
                  </div>
                  <div className="separator"></div>
                </>
              }
              <div className="event-type-select">
                <ZaiaSelect
                  placeholder={t("aiNavigator.outcomes.selectEventType")}
                  textColor="#36369B"
                  placeholderTextColor="#36369B"
                  fontSize={"17px"}
                  height={"33px"}
                  errorOption={false}
                  maxHeightList={"200px"}
                  padding={"5px 15px"}
                  borderColor={"#AAAADB"}
                  borderRadius={"5px"}
                  setValue={(value) => addEvent(value)}
                  items={customFormsList?.map((customForm) => {
                    return {
                      value: customForm.id,
                      text: P.dataTranslation(
                        customForm.customFormName,
                        language  
                      ),
                    };
                  })}
                />
              </div>
            </div>
          } */}
        </div>
      </div>




      {
        surveysData?.customForm.length === 0 &&
        <div className="no-surveys">
          <ZaiaReaction
            zaiaImg={noDataAvailable}
            text={t("globally.noData")}
            subText={t("globally.noDataDescription")}
            sizeText="33.25px"
            sizeSubText="15px"
            textColor="var(--blue-color)"
            widthImg="210px"
          />
        </div>
        
      }
      {
        surveysData?.customForm.length > 0 &&
        <>
          {
            viewTab === 2 &&
            <SurveyQuestionsCharts
              patientID={selectedPatient?.id}
              processID={processSelected}
              customFormId={surveySelected?.id} 
            />
          }
          {
            viewTab === 1 &&
            <> 
              <div className="outcomes-content">
                <SurveyQuestionsList
                  patientID={selectedPatient?.id}
                  processID={processSelected}
                  customFormId={surveySelected} 
                />
              </div>
            </>
          }
        </>
      }
    </S.AiNavigatorSurveys>
  );
}

export default AiNavigatorSurveys;