/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalSurveys.style";

// Hooks
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";

import useDoctorData from "../../../../../hooks/useDoctorData";
import ModalSendSurvey from "../modalSendSurvey";
import ModalAddSurveyData from "../modalAddSurveyData";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function ModalSurveys({ 
  setModalActive,
  surveyForPreview,
  processId,
  patientId,
  surveyId
}) {

  const { t, i18n: { language } } = useTranslation("global");

  const { doctorData } = useDoctorData();
  const [formSuccess, setFormSuccess] = useState(null); 

  const [loadingData, setLoadingData] = useState(false)
  const [tabSelected, setTabSelected] = useState(1)
  


  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setModalActive(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  if(formSuccess)
    return(
      <S.ModalSendSurvey>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container">
          <div className="modal-reaction">
            <ZaiaModalResponseContent
              button1={() => setModalActive(null)}
              formSuccess={formSuccess}
              button1Text={t("globally.done")}
              title={t("surveys.sendSurveyModal.surveySent")}
            />
          </div>  
        </div>
      </S.ModalSendSurvey>
    )

  if(formSuccess === false)
    return(
      <S.ModalSendSurvey>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container">
          <div className="modal-reaction">
            <ZaiaModalResponseContent
              button1={() => setModalActive(null)}
              formSuccess={formSuccess}
              title={t("globally.errorForm")}
              subtitle={t("surveys.sendSurveyModal.surveySentError")}
              button1Text={t("globally.accept")}            
            />
          </div>
        </div>
      </S.ModalSendSurvey>
    )

  return (
    <>
      <S.ModalSendSurvey
        hidden={!loadingData}
      >
        <div className="modal-background">
        </div>
        <div className="modal-container">
          <div className="modal-reaction">
            <ZaiaReaction
              widthImg={"150px"}
              zaiaImg={logoZaiaLoading}
              padding={"200px 0px"}
            />
          </div>
        </div>
      </S.ModalSendSurvey>
      <S.ModalSendSurvey
        hidden={loadingData}
      >
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container">
          <div className="header">
            <div className="header__text">
              <div className="header__title">{
                surveyForPreview
                ? t("surveys.surveyPreview")
                : t("surveys.sendSurvey")
              }</div>
            </div>
            <div className="header__close" onClick={() => setModalActive(false)}>
              <i className="header__close-icon icon zaia-icono-cerrar"/>
            </div>
          </div>
          {
            !surveyForPreview &&
            <div className="modal-tabs">
              <div
                className={`tab ${1 === tabSelected && "tab tab--selected"}`}
                onClick={() => 
                  {
                    setTabSelected(1)
                  }
                }
              >
                {t("surveys.sendSurvey")}
              </div>
              <div
                className={`tab ${2 === tabSelected && "tab tab--selected"}`}
                onClick={() => 
                  {
                    setTabSelected(2)
                  }
                }
              >
                {t("surveys.fillOutSurvey")}
              </div>
            </div>
          }
          <div className="modal-content-dinamic">
            <div 
              className="dinamic-component"
              style={
                {display: tabSelected === 2 ? "none" : "flex"}
              }
            >
              <ModalSendSurvey
                surveyForPreview={surveyForPreview}
                processId={processId}
                patientId={patientId}
                setFormSuccess={setFormSuccess}
                setLoadingData={setLoadingData}
              />
            </div>
            <div 
              className="dinamic-component"
              style={
                {display: tabSelected === 1 ? "none" : "flex"}
              }
            >
              <ModalAddSurveyData
                patientID={patientId}
                processID={processId}
                surveyId={surveyId}
                setFormSuccess={setFormSuccess}
                setLoadingData={setLoadingData}
              />
            </div>
          </div>
        </div>
      </S.ModalSendSurvey>
    </>
  );
}


export default ModalSurveys;
