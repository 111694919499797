import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./Algorithms.style";
import { useStateValue } from "../../../contextAPI/StateProvider";

// Components
import AlgorithmsView from "./components/algorithmsView";
import ZaiaReaction from "../../zaiaComponents/zaiaReaction";

// pipes
import * as P from "../../../utils/pipes";

import { Actions } from "../../../utils/actionsIds";
import useRecordNavigation from "../../../hooks/useRecordNavigation";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_PROCESS_NAMES } from "../../../graphql/queries/Process";
import { useEffect } from "react";

// Assets
const algorithmsImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;

function Algorithms() {
  const { t, i18n: { language } }= useTranslation("global");
  const [{ selectedPatient }, dispatch] = useStateValue();
  const { recordAction } = useRecordNavigation();
  
  const [programSelected, setProgramSelected] = useState();

  const { data: processData } = useQuery( 
    QUERY_GET_PROCESS_NAMES, 
    { 
      variables: { 
        whereFilter: 
        selectedPatient 
        ? {
          userToProcesses: {
            userId: {
              _in: [ selectedPatient?.id ]
            }
          }
        } 
        : null
      } 
    } 
  );

  const algorithmLinks = [
    {
      processName: {
        en: 'HACHOP',
        es: 'HACHOP'
      },
      icon: 'zaia-acho-covid',
      action: 'link',
      link: 'https://maia-survey.web.app/public'
    },
    {
      processName: {
        en: 'ORT Risk Calculator',
        es: 'Calculadora de riesgo ORT'
      },
      icon: 'zaia-i-opiods',
      action: 'link',
      link: 'https://landing.laboratorioslegrand.com/calculadora-de-riesgo-ort'
    }
  ]

  useEffect(() => {
    if(programSelected){
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: programSelected.id
      });
    } else {
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: null
      });
    }
  }, [programSelected])

  if(!programSelected)
    return (
      <S.Algorithms>
        <S.ProgramBoxes>
          <div className="reaction-content">
            <ZaiaReaction 
              zaiaImg={algorithmsImg}
              text={t("algorithms.title")}
              sizeText="20px"
              textColor="var(--blue-color)"
              widthImg="110px"
            />
          </div>
          <div className="algorithms__containerBoxes" >
            {
              processData?.process.map((process, index) => {
                return (
                  <div className="box-container" 
                    key={process.id} 
                    onClick={() => {
                      recordAction(Actions.ALGORITHMS.SELECT_PROGRAM, null, process.id);
                      setProgramSelected(process);
                    }}
                  >
                      <Box
                        icon={process.icon}
                        title={P.dataTranslation(process.processName, language)}
                        process={process}
                      />
                    {/* </ConditionalLink> */}
                  </div>
                )                
              })
            }
            {
              algorithmLinks.map((item) => {
                return (
                  <div className="box-container" 
                    key={item.processName.en} 
                    onClick={() => {
                      window.location.assign(item.link)
                    }}
                  >
                      <Box
                        icon={item.icon}
                        title={P.dataTranslation(item.processName, language)}
                      />
                  </div>
                )
              })
            }
          </div>
        </S.ProgramBoxes>
      </S.Algorithms>
    )

  return (
    <S.Algorithms>
      <AlgorithmsView
        algorithm={programSelected}
        setProgramSelected={setProgramSelected}
      />
    </S.Algorithms>
  );
}

const Box = ({ icon, title }) => {
  return (
    <div>
      <S.BoxDiv>
        <div className="box__iconContainer">
          <i className={`icon ${icon}`} />
        </div>
        <h5>{title}</h5>
      </S.BoxDiv>
    </div>
  );
};

export default Algorithms;
