import styled from 'styled-components';

export const Agenda = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  .agenda__container-left{
  }
  .agenda__container-right{
    width: calc(100% - 343.27px);
    padding-top: 40px;
    justify-content: center;
    .agenda__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 749px;
      margin: 0 auto;
      .date-container {
        display: flex;
        font-size: 24.72px;
        color: #526479;
        font-family: 'Nunito Sans Light';
        &__day-month {
          display: flex;
          flex-direction: ${({englishLanguage}) => englishLanguage ? "row-reverse" : "row"};
        }
        &__text {
          &:last-child {
            margin-left: ${({englishLanguage}) => englishLanguage ? "0px" : "10px"}; 
          }
          &:first-child {
            margin-left: ${({englishLanguage}) => !englishLanguage ? "0px" : "10px"}; 
          }
        }
        &__text--day {
          font-family: "Nunito Sans Bold";
        }
        &__year {
          margin-left: 10px;
        }
      }
      .agenda-message {
        &__text {
          font-family: 'Nunito Sans Light';
          color: #68688D;
        }
      }
    }
    
    .time-filters-range {
      font-size: 16px;
      color: #68688D;
      align-items: center;
      width: 749px;
      margin: 40px auto 20px auto;
      display: flex;
      height: 35px;
      justify-content: flex-start;
      &__close-button {
        margin-left: 10px;
        background-color: #E6E6E6;
        border-radius: 50%;
        width: 17.23px;
        height: 17.23px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        &-icon {
          color: #8C8DA4;
          font-size: 6px;
        }
      }
    }
    .time-filters {
      max-width: 749px;
      margin: 0 auto;
      margin-top: 40px;
      display: flex;
      margin-bottom: 20px;
      .time-filter {
        border-radius: 60px;
        padding: 5px 15px;
        margin-right: 10px;
        border: 1px solid #68688D;
        color: #68688D;
        font-size: 17px;
        font-family: "Nunito Sans";
        cursor: pointer;
        &--selected {
          background-color: #E0E6ED;
          color: #3E3DA3;	
          border: none;
          font-family: "Nunito Sans Bold";
        }
      }
    }
    .events-list{
      box-sizing: border-box;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 48px;
      height: calc(100% - 127.77px);
      margin: auto;
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      /* mask-image: linear-gradient(0deg,#000 97%,transparent); */
      &__empty {
        width: 100%;
        height: auto;
        text-align: center;
        margin: auto;
        padding-bottom: 90px;
      }
      ::-webkit-scrollbar {
        width: 48px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px transparent;
        margin: 5px 0px; 
      }
      ::-webkit-scrollbar-thumb {
        border: 21.5px solid rgba(0, 0, 0, 0);
        box-shadow: inset 0 0 10px 10px #C8CFD8;
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: transparent;
      }     
    }
  }
`

export const EventCard = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 162px;
  width: 746px;
  padding: 20px;
  border: ${({ oldCard }) => !oldCard ? "1px solid #3E3DA3" : "1px solid #99A8B9"};
  border-radius: 14px;
  margin: 0 auto 10px auto;
  box-sizing: border-box;
  .event-card {
    &__date {
      text-align: center;
      &-day-week {
        font-size: 12.78px;
        color: #526479;
        font-family: "Nunito Sans";
      }
      &-day {
        font-family: "Nunito Sans";
        font-size: 40px;
        text-decoration: ${({ oldCard }) => !oldCard ? "none" : "line-through"};
        color: ${({ oldCard }) => !oldCard ? "#3E3DA3" : "#526479"};
      }
    }
    &__center-separator{
      height: 17px;
      border-left: 1px solid #526479;
      margin: 0 30px;
    }
    &__info {
      width: calc(100% - 108px);
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }
    &__top {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      &-title {
        font-size: 24.72px;
        color: #526479;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }
      &-ubication {
        font-size: 17px;
        color: #3E3DA3;
        display: flex;
        align-items: center;
        &-text {
          margin-left: 5px;
          white-space: nowrap;
        }
        &-icon {
          font-size: 19px;
        }
      }
    }
    &__middle{
      display: flex;
      align-items: center;
      font-size: 17px;
      color: #526479;
      &-date {
        margin-left: 5px;
      }
      &-separator {
        margin: 0px 20px;
        height: 17px;
        border-left: solid 1px #647487;
      }
    }
    &__bottom {
      display: flex;
      font-size: 17px;
      justify-content: space-between;
      align-items: center;
      &-patient {
        &-info {
          padding: 5px;
          display: flex;
          align-items: center;
          background-color: #EEF1F4;
          border-radius: 4px;
          color: #526479;
        }
        &-img {
          width: 18px;
          height: 18px;
          border-radius: 50%;
          margin-right: 10px;
        }
        &-name {
          font-family: "Nunito Sans";
          margin-right: 10px;
        }
        &-program {
          font-family: "Nunito Sans Bold";
        }
      }
      &-status {
        display: flex;
        background-color: #F5F6F9;
        border-radius: 4px;
        align-items: center;
        padding: 5px;
        &-point {
          border-radius: 50%;
          width: 11px;
          height: 11px;
          background-color: ${({ status }) => status ? "red" : "green"}
        }
        &-text {
          font-family: "Nunito Sans";
          color: #526479;
          margin-left: 10px;
        }
        &-icon {
          color: #3E3DA3;
          font-size: 14px;
          margin-left: 10px;
        }
      }
    }
  }
`