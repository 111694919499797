import React from "react";
import * as S from "./ProcessName.style";
import { useTranslation } from "react-i18next";

// pipes
import * as P from "../../../../utils/pipes"

const ProcessName = ({userToProcesses}) => {
  const { i18n: { language } } = useTranslation("global");

  return (
    <S.ProcessNameContainer>
        {
          userToProcesses.filter((item) => item.process)?.map((userToProcess, index) => {
            return (
              <div key={userToProcess.id}>
                <S.ProcessName last={index === userToProcesses.filter((item) => item.process).length - 1} >
                  <div className="card-selected-patient-process">
                    {P.dataTranslation(userToProcess.process?.processName, language)}
                  </div>
                </S.ProcessName>
              </div>
            )
          })
        }
    </S.ProcessNameContainer>
  )
}

export default ProcessName;
