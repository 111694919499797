import React, { useState, useEffect } from "react";
import * as S from "./ChangePassModal.style";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

// components
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";

// Hooks 
import useActivateModal from "../../../../../hooks/useActivateModal";

// Apollo
import { useMutation } from "@apollo/client";
import { MUTATION_CHANGE_PASS } from "../../../../../graphql/mutations/Auth";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";

// Assets
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaOk = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-success.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function ChangePassModal({ 
  doctorEmail,
  setModalActive
}) {

  const { t } = useTranslation("global");
  const {desactiveModal} = useActivateModal();

  const [typeForm, setTypeForm] = useState("changePass");
  const [changePass, { loading: changePassLoading }] = useMutation(MUTATION_CHANGE_PASS);

  const handleSubmit =  (values, { resetForm }) => {
    changePassword(values, resetForm)
  };

  const changePassword = async ( values, resetForm ) => {
    try {
      // eslint-disable-next-line no-unused-vars
      await changePass({
        variables: {
          newPass: values.confirmNewPass,
          pass: values.oldPass,
        },
      });
      setTypeForm("success");
    } catch (err) {
      console.log(err);
      setTypeForm("error");
    }
    resetForm();
  }

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setModalActive(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);


  if(changePassLoading) {
    return(
      <S.ChangePassDiv>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container">
          <ZaiaReaction
            widthImg="200px"
            zaiaImg={logoZaiaLoading}
          />
        </div>
      </S.ChangePassDiv>
    )
  }


  if(typeForm === "success")
    return(
      <S.ChangePassDiv>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container">
          <ZaiaModalResponseContent
            button1={() => setModalActive(null)}
            formSuccess={true}
            button1Text={t("globally.done")}
            title={t("profile.changePassCorretly")}
          />
        </div>
      </S.ChangePassDiv>
    )

  if(typeForm === "error")
    return(
      <S.ChangePassDiv>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container">
          <ZaiaModalResponseContent
            button1={() => setModalActive(null)}
            formSuccess={false}
            title={t("globally.errorForm")}
            button1Text={t("globally.accept")}               
            subtitle={t("profile.changePassError")}       
          />
        </div>
      </S.ChangePassDiv>
    )
  
  return (
    <S.ChangePassDiv>
      <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
      <div className="modal-container">
        <Formik
          initialValues={{
            oldPass: "",
            newPass: "",
            confirmNewPass: "",
          }}
          validationSchema={Yup.object({
            oldPass: Yup.string()
              .min(3, `${t("register.validation.min")}`)
              .required(`${t("register.validation.password")}`)
              // .matches(
              //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              //   `${t("register.validation.matches")}`
              // )
              ,
            newPass: Yup.string()
              .min(8, `1`)
              .required(`2`)
              .matches(
                /^(?=.*?[A-ZÁ-Ú])(?=.*?[a-zá-ú])(?=.*?\d)(?=.*?[^\w\d\s]).{8,}$/,
                `3`
              ),
            confirmNewPass: Yup.string()
              .min(8, `${t("register.validation.min")}`)
              .required(`${t("register.validation.confirmPass")}`)
              .when("newPass", {
                is: (newPass) => (newPass && newPass.length > 0 ? true : false),
                then: Yup.string().oneOf(
                  [Yup.ref("newPass")],
                  `${t("register.validation.match")}`
                ),
              }),
          })}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <form onSubmit={ props.handleSubmit }>
              <div className="title">
                <div className="title__icon icon zaia-i-lock-chat"></div>
                <div className="title__text">{t("profile.changePass")}</div>
              </div>
              <div className="card-inputs">
                <FormChangePassInputs
                  props={props}
                /> 
              </div>
              <div className="conditions">
                <div className={`condition ${(props.errors?.newPass === "1" || (props.values?.newPass === "" && props.touched?.newPass)) && "condition--error"}`}>
                  <i className={`condition__icon icon zaia-i-check-1 ${(props.errors?.newPass === "1" || (props.values?.newPass === "" )) && "condition__icon--error"}`}></i>
                  <div className="condition__text">Tu contraseña debe tener al menos 8 caracteres</div>
                </div>
                <div className={`condition ${props.errors?.newPass && "condition--error"}`}>
                  <i className={`condition__icon icon zaia-i-check-1 ${(props.errors?.newPass || !props.touched?.newPass) && "condition__icon--error"}`}></i>
                  <div className="condition__text">Tu contraseña debe incluir mayusculas, minusculas y al menos un caracter especial</div>
                </div>
              </div>
              <div className="card-buttons">
                <>
                  <button 
                    className={
                      `
                        submit-button 
                        ${
                          !(( 
                            Boolean(props.touched?.confirmNewPass) ||   
                            Boolean(props.touched?.oldPass) || 
                            Boolean(props.touched?.newPass) 
                          ) && 
                          props.isValid) &&
                          "submit-button--disabled"
                        }
                      ` 
                    }
                    disabled={!( 
                      Boolean(props.touched?.confirmNewPass) ||   
                      Boolean(props.touched?.oldPass) || 
                      Boolean(props.touched?.newPass) 
                    ) && 
                    props.isValid}
                    type={"submit"}
                  >
                    {t("globally.save")}
                  </button>
                  <div onClick={() => setModalActive(false)} className="cancel-button">
                    {t("globally.cancel")}
                  </div>
                </> 
              </div>
            </form>
          )}
        </Formik>
      </div>
    </S.ChangePassDiv>
  );
}
  
  
// components

const FormChangePassInputs = ({props}) => {
  const { t } = useTranslation("global");

  return(
    <>
      <div className={`label ${props.errors?.oldPass && props.touched?.oldPass && "label--error"}`}>
        <div className="label__text">
          {t("profile.newPass")}
          {props.errors?.oldPass && props.touched?.oldPass && "*"}
        </div>
        {
          !props.errors?.oldPass && props.touched?.oldPass &&
          <i className="label__icon icon zaia-i-check-1"></i>
        }
      </div>
      <div className="input-container">
        <ZaiaInputForm 
          placeholder={t("profile.currentPass")}
          type="password"
          name="oldPass"
          padding={"17px 20px 17px 20px"}
          activeBorder={false}
          backgroundColor={"#F2F2F4"}
          textColor={"#424469"}
          placeholderTextColor={"#8A939D"}
          fontSize={"20px"}
          iconColor={"#8C8DA4"}
          errorOption={false}
        />
      </div>
      <div className={`label ${props.errors?.newPass && props.touched?.newPass && "label--error"}`}>
        <div className="label__text">
          {t("profile.newPass")}
          {props.errors?.newPass && props.touched?.newPass && "*"}
        </div>
        {
          !props.errors?.newPass && props.touched?.newPass &&
          <i className="label__icon icon zaia-i-check-1"></i>
        }
      </div>
      <div className="input-container">
        <ZaiaInputForm 
          placeholder={t("profile.newPass")}
          type="password"
          name="newPass"
          padding={"17px 20px 17px 20px"}
          activeBorder={false}
          backgroundColor={"#F2F2F4"}
          textColor={"#424469"}
          placeholderTextColor={"#8A939D"}
          fontSize={"20px"}
          iconColor={"#8C8DA4"}
          errorOption={false}
        />
      </div>
      <div className={`label ${props.errors?.confirmNewPass && props.touched?.confirmNewPass && "label--error"}`}>
        <div className="label__text">
          {t("profile.confimNewPass")}
          {props.errors?.confirmNewPass && props.touched?.confirmNewPass && "*"}
        </div>
        {
          !props.errors?.confirmNewPass && props.touched?.confirmNewPass &&
          <i className="label__icon icon zaia-i-check-1"></i>
        }
      </div>
      {
        props.errors?.confirmNewPass && props.touched?.confirmNewPass && 
        <div className="error-same-password">
          {t("register.validation.match")}
        </div>
      }
      <div className="input-container">
        <ZaiaInputForm 
          placeholder={t("profile.confimNewPass")}
          type="password"
          name="confirmNewPass"
          padding={"17px 20px 17px 20px"}
          activeBorder={false}
          backgroundColor={"#F2F2F4"}
          textColor={"#424469"}
          placeholderTextColor={"#8A939D"}
          fontSize={"20px"}
          iconColor={"#8C8DA4"}
          errorOption={false}
        />
      </div>
    </>
  )
}


export default ChangePassModal;
