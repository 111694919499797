import styled from "styled-components";
import { getHexOpacity } from '../../../../../utils/pipes';

export const InvitationsManagement = styled.div`
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  display: flex;
  box-sizing: border-box;
  .card {
    width: 100%;  
    height: 100%;
    margin: auto;
    .card-info {
      height: 100%;
      background-color: ${({theme}) => theme.background};
      padding: 30px 50px;
      border-radius: 0 0 30px 30px;
      overflow: auto;
      margin-bottom: 40px;
      box-sizing: border-box;
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 40px;
        border-bottom: 1px solid #D0D8E0;
        .input-container {
          width: 315px;
        }
      }
      ::-webkit-scrollbar {
        display: none;
      }
      &__invitations-list {
        margin-top: 40px;
        min-height: 305px;
        overflow: auto;
        display: flex;
        height: calc(100% - 92px);
        flex-direction: column;
        .reaction {
          display: flex;
          margin: auto;
          padding: 50px;
        }
        &-header {
          margin-bottom: 20px;
          display: flex;
          padding: 13px 88px 13px 50px;
          justify-content: space-between;
          color: #526479;
          font-family: "Nunito Sans";
          font-size: 14px;
        }
        table {
          width: 100%;
          thead {
            th {
              color: #526479;
              font-family: "Nunito Sans";
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
        .box-status {
          display: flex;
          justify-content: center;
          align-items: center;
          color: #526479;
          font-family: "Nunito Sans";
          font-size: 14px;
          &__point {
            margin-right: 10px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            &--rejected {
              background-color: #FF4A4A;
            }
            &--pending {
              background-color: #4AA8FF;
            }
          }
        }
        .box-date {
          color: #526479;
          font-family: "Nunito Sans";
          justify-content: center;
          font-size: 14px;
          display: flex;
        }
        .box-options {
          display: flex;
          align-items: center;
          justify-content: center;
          .box-option__button {
            display: flex;
            /* width: 135px; */
            height: 53px;
            justify-content: center;
            border-radius: 10.754px;
            background-color: #F3F3FC;
            border: none;
            cursor: pointer;
            outline: none;
            align-items: center;
            padding: 15px;
            box-sizing: border-box;
            &:not(:last-child) {
              margin-right: 20px;
            }
            &-icon {
              margin-left: 10px;
              color: #3E3DA3;
              opacity: 0.5;
            }
            &-text {
              color: #3E3DA3;
              font-family: "Nunito Sans";
              font-size: 14px;
            }
          }
        }
        .empty-list {
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: auto;
          &__img {
          }
          &__title {
            color: #37379B;
            font-family: "Nunito Sans Bold";
            font-size: 33.25px;
          }
          &__subtitle {
            color: #37379B;
            text-align: center;
            font-family: "Nunito Sans";
            font-size: 15px;
          }
        }
      }
    }
    .card-buttons {
      padding-bottom: 20px;
      max-width: 400px;
      margin: auto;
      text-align: center;
      p {
        font-size: .9rem;
      }
    }
  }

  @media only screen and (max-width: 840px) {
    .card {
      padding: 15px !important;
      .card-info {
        padding: 20px 20px !important ;
      }
    }
  }
`;

export const ToggleContainer = styled.div`
  height: 28px;
  max-width: 300px;
  width: 218px;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #68688D;
`;

export const PatientBox = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  color: ${({active}) => active ? "var(--white)" : ""};
  background-color: ${({active}) => active ? 'var(--blue-color)' : 'var(--white)' };
  justify-content: space-between;
  margin-bottom: 30px;
  .box-info {
    display: flex;
    align-items: center;
    &__text {
      margin-left: 10px;
      &-name {
        color: #3E3DA3;
        font-family: "Nunito Sans";
        font-size: 17px;
      }
      &-email {
        color: #68688D;
        font-family: "Nunito Sans";
        font-size: 15px;
      }
      &-process {
        color: #3E3DA3;
        font-family: "Nunito Sans";
        margin-top: 5px;
        font-size: 14px;
      }
    }
  }
  div {
    font-family: "Open Sans";
    .first-text {
      margin-top: 5px;
    }
    .second-text {
      margin-top: 2px;
      color: ${({active}) => active ? "var(--white)" : "var(--subtitle-color)"};
      font-size: 0.67rem;
      margin: 5px 0;
    }
  }

  @media only screen and (max-width: 840px) {
    padding: 10px 0;
    .box-options-text-date {
      font-size: 12px !important;
    }
  }
`;

export const BoxSettings = styled.div`
  position: relative;
  background-color: var(--white);
  height: auto;
  width: 180px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  &::-webkit-scrollbar{
    display: none;
  }
  .settings-box {
    font-family: "Open Sans";
    font-size: 20px;
    color: ${(({theme}) => theme.warning)} !important;
    display: flex;
    padding: 10px 0;
    justify-content: center;
    cursor: pointer;
    .icono-settings {
      font-size: 20px;
      margin-right: 10px;
      margin-bottom: -6px;
    }
  }
`


export const deleteInvitationModal = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  margin: auto;
  display: flex;
  .content-delete {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 450px;
    margin: auto;
    .zaia-image {
      width: 280px;
      margin: 0px auto 30px;
    }
    .content-delete__title {
      color: ${({theme}) => theme.primaryColor};
      font-family: "Nunito Black";
    }
    .message {
      font-family: 'Open Sans';
      font-size: 17px;
      margin-top: 20px;
      color: ${({theme}) => theme.onBackground};
    }
    .email-container {
      background-color: ${({theme}) => `${theme.primaryColor}${getHexOpacity(0.1)}`};
      padding: 8px;
      border-radius: 10px;
      color: ${({theme}) => theme.primaryColor};
      margin: 25px 0;
      font-family: "Nunito ExtraBold";
    }
    .button-container-principal {
      width: 300px;
      margin: 50px auto 0;
      .button-message {
        margin-top: 20px;
        padding: 0 15px;
      }
    }
    .button-container-secondary {
      width: 300px;
      margin: 20px auto 0;
      .button-message {
        margin-top: 20px;
        padding: 0 15px;
      }
    }
    .cancel-button {
      text-align: center;
      font-family: "Open Sans";
      margin-top: 30px;
      color: var(--blue-color);
      font-size: 13px;
      font-weight: bold;
      cursor: pointer;
    }
  }
`

export const ModalResponseReaction = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    z-index: 200;
    background-color: var(--white);
    width: 700px;
    margin: auto;
    box-sizing: border-box;
    max-height: 80%;
    border-radius: 14px;
    padding: 40px; 
  } 
`