import React from "react";
import * as S from "./FilterInputText.style";
import { useTranslation } from "react-i18next";

import ZaiaRoundInput from "../../../zaiaComponents/zaiaRoundInput";

function FilterInputText({ filterBy, placeholder, type, inline=false, setInputFilter }) {
  const [t] = useTranslation("global");

  return (
    <>
      <S.FilterInputText
        inline={inline}
      >
        <p>{t("globally.filterBy")} {filterBy}:</p>
        <ZaiaRoundInput placeholder={placeholder} type={type} getText={setInputFilter}/>
      </S.FilterInputText>
    </>
  )
}

export default FilterInputText;