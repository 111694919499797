import styled from "styled-components";

export const SidebarPatients = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  background-color: ${({theme}) => theme.variantBackground};
  .sidebar-patient {
    width: 300px;
    background-color: ${({theme}) => theme.background};

    &__top {
      height: ${({heightTop}) => heightTop};
    }

    &__list {
      height: ${({heightTop}) => 'calc(100% - ' + heightTop + ')'};
      padding: 0 10px;
      /* .patients__algorithmsInput {
        background-color: var(--background-header-input);
        border: none;
        padding: 6px;
        width: 95%;
        border-radius: 20px;
        display: flex;
        align-items: center;
        margin-left: 5px;
        margin-top: 10px;
        input {
          border: none;
          background-color: var(--background-header-input);
          width: 300px;
          margin-left: 2px;
          &:focus {
            outline: none;
          }
        }
      } */
    }
  }

  @media only screen and (max-width: 1330px) {
    background-color: transparent !important;
    /* display: none; */
    .sidebar-patient {
      box-shadow: -5px 0px 20px 0px ${({theme}) => theme.blackShadow}29;
    }
  }

  @media only screen and (max-width: 768px) {
    .sidebar-patient {
      width: 100vw;
    }
  }
`;

export const SidebarPatientsButton = styled.button`
  position: relative;
  margin: auto;
  border: none;
  background: none;
  outline: none;
  .sidebarPatientsButton__icon {
    padding: 5px 2px 5px 1px;
    background-color: ${({theme}) => theme.background};
    font-size: 13px;
    color: ${({theme}) => theme.onBackground};
    display: flex;
  }

  .sidebarPatientsButton__icon-content {
    padding: 5px 2px 5px 1px;
    background-color: ${({theme}) => theme.background};
    border-radius: 0 10px 10px 0;
  }
    
  .sidebarPatientsButton__icon--patients {
    display: none;
  }

  @media only screen and (max-width: 435px) {
    display: ${({hide, theme}) => hide ? "" : "none"};
  }

  @media only screen and (max-width: 1330px) {
    .sidebarPatientsButton__icon-content {
      box-shadow: 4px 2px 7px -2px ${({theme}) => theme.blackShadow}29;
    }
  }

  @media only screen and (max-width: 1024px) {
    position: ${({hide}) => hide ? "absolute" : "relative"};
    bottom: ${({hide}) => hide ? 0 : 'auto'};
    z-index: 1;
    margin-bottom: ${({hide}) => hide ? '25px' : 'auto'};
    .sidebarPatientsButton__icon-content {
      background-color: ${({hide, theme}) => hide ? theme.primaryColor : theme.background};
    }
    .sidebarPatientsButton__icon-container {
      background-color: ${({hide, theme}) => hide ? theme.primaryColor : theme.background};
      padding: 3px 2px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;	
      display: flex;
      align-items: center;
    }
    .sidebarPatientsButton__icon {
      padding: 5px 2px 5px 1px;
      background-color: ${({hide, theme}) => hide ? theme.primaryColor : theme.background};
      border-radius: 0 10px 10px 0;
      font-size: 13px;
      color: ${({hide, theme}) => hide ? theme.onPrimaryColor : theme.onBackground};
      display: flex;
    } 
    .sidebarPatientsButton__icon--patients {
      display: flex;
      font-size: 24px;
    }
  }
`
