import styled from "styled-components";

export const DasboardOutcomesByPatientDiv = styled.div`
  background-color: var(--background-patient);
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  .checkboxes-header-container {
    display: flex;
    margin-bottom: 15px;
    .checkbox-container {
      margin-right: 15px;
    }
  }
  .graphs-outcomes-container {
    /* overflow-y: auto; */
    height: calc(100% - 60px)
  }
  .add-calendar-event {
    right: 0;
    position: absolute;
    margin: -20px -25px;
    height: calc(100% + 40px);
    box-shadow: -1px 2px 11px -3px ${({theme}) => theme.blackShadow}29;
    width: 330px;
    z-index: 500;
  }

  @media only screen and (max-width: 435px) {
    .add-calendar-event {
      width: 100vw !important;
      padding-right: 20px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .add-calendar-event {
      padding-right: 20px;
    }
  }
`
