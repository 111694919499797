import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ListItems.style";

function ListItems({
  option,
  selectOption,
  noEditable,
  items
}) {

  const { t } = useTranslation("global"); 

  return (
    <S.ListItems>
      <div className="listItems-states">
        {
          items.map((item) => {
            return (
              <div 
                className={`listItems-state ${option === item.structValue.fields.value.stringValue && 'listItems-state--selected'}`} 
                key={item.structValue.fields.value.stringValue}
                onClick={() => 
                  option === item.structValue.fields.value.stringValue || noEditable
                  ? null 
                  : selectOption(item.structValue.fields.value.stringValue)
                }
              >
                {
                  item.structValue.fields.text.stringValue 
                  ? item.structValue.fields.text.stringValue
                  : t('globally.option') + ' ' + item.structValue.fields.value.stringValue
                }
              </div>
            )
          })
        }
      </div>
    </S.ListItems>
  );
}

export default ListItems;
