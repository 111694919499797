import styled from "styled-components";

export const Patients = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  .patients-container {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    background-color: var(--white);
    filter: ${(p) => p.modal ? "blur(4px)" : "none"};
    overflow: hidden;
    position: relative;
    &__sidebar-patients {
      width: auto;
      position: relative;
      z-index: 400;
    }
    &__info-content {
      z-index: 300;
      position: relative;
      background-color: var(--background-patient);
      width: 100%;
      padding: 20px 25px 20px ${({selectedPatient}) => selectedPatient ? "25px" : '10px'};
      overflow: hidden;
    }
  }

  .add-calendar-event {
    right: 0;
    position: absolute;
    /* margin: -20px -25px;
    height: calc(100% + 40px); */
    height: 100%;
    box-shadow: -1px 2px 11px -3px ${({theme}) => theme.blackShadow}29;
    width: 330px;
    z-index: 500;
    top: 0
  }

  .invite-modal {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 1325px) {
    .patients-container__sidebar-patients {
      position: absolute;
      z-index: 400;
      height: 100%;
      /* width: 100% !important; */
    }
    .patients-container {
      &__info-content {
        padding: 20px 10px 20px 10px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .patients-container__sidebar-patients {
      /* width: 100%; */
    } 
    .add-calendar-event {
      width: 100vw !important;
      /* padding-right: 20px; */
    }
  }
`;

