import styled from "styled-components";

export const DashboardFilterPanel = styled.div`
  width: 261px;
  height: 100%;
  .panel {
    position: absolute;
    padding: 36px 0 41px 30px; 
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    &__container {
      display: flex;
      height: auto;
      overflow: auto;
      /* transition-duration: 1s; */
      /* transition: all 0.5s ease-in-out; */
      box-shadow: ${({panelActive}) => panelActive ? "21px 0px 35px -7px rgba(35, 34, 157, 0.20)" : ""};
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 14px;
      height: 100%;
    }
    &__content{
      height: 100%;
    }
    .all-filters {
      box-sizing: border-box;
      height: ${({patientSelected}) => `calc(100% - ${patientSelected ? 67 : "0"}px)`};
      width: 252px;
      background-color: #F8F8FF;
      border-radius: 14px;
      display: flex;
      flex-direction: column;
      &__header {
        display: flex;
        justify-content: space-between;
        padding: 24px 19px 14px 19px;
        align-items: center;
        .title {
          display: flex;
          color: #566377;
          &__text {
            font-family: "Nunito Sans Bold";
            font-size: 14px;
            margin-left: 10px;
          }
          &__icon {
            font-size: 17px;
          }
        }
        .clear-button {
          color: #36369B;
          font-family: "Nunito Sans";
          font-size: 15px;
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .filters-container {
        height: 100%;
        padding: 20px 0px 20px 20px;
        padding: ${({overflowActive}) => overflowActive ? "20px 20px 20px 20px" : "20px 0px 20px 20px"};
        mask-image: linear-gradient(0deg,#000 97%,transparent);
        overflow-y: ${({overflowActive}) => overflowActive ? "hidden" : "scroll"};
        ::-webkit-scrollbar {
          width: 25px;
        }
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 10px 10px transparent;
          margin: 5px 0px; 
        }
        ::-webkit-scrollbar-thumb {
          border: 10px solid rgba(0, 0, 0, 0);
          box-shadow: inset 0 0 10px 10px #AFAFD7;
          background-clip: padding-box;
          border-radius: 9999px;
          background-color: transparent;
        }
        .filter {
          display: flex;
          flex-direction: column;
          &__comments-check {
            margin-top: 10px;
            display: flex;
            color: #606C7A;
            padding: 10px;
            font-size: 14px;
            border-radius: 5px;
            align-items: center;
            &--selected {
              color: #36369B;
            }
            &-icon {
              margin-right: 10px;
              font-size: 18px;
            }
            .toogle-container{
              margin-left: auto;
            }
          }
          &__title {
            color: #59599E;
            font-family: "Nunito Sans Bold";
            font-size: 20px;
            margin-bottom: 10px;
          }
          &__tags-list {
            margin-top: 20px;
            .tag {
              padding: 5px;
              border-radius: 5px;
              background-color: #8A939D;
              color: #FFFFFF;
              display: flex;
              align-items: center;
              width: fit-content;
              &:not(:last-child) {
                margin-bottom: 10px;
              }
              &__name {
                font-size: 14px;
                font-family: "Nunito Sans Light";
                margin-right: 5px;
              }
              &__close{
                margin-left: auto;
                background-color: #FFFFFF;
                border-radius: 50%;
                width: 17.23px;
                height: 17.23px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                cursor: pointer;
                &-icon {
                  color: #8A939D;
                  font-size: 6px;
                }
              }
            }
          }
          &__title-collapsible {
            display: flex;
            align-items: center;
            color: #59599E;
            cursor: pointer;
            padding: 10px;
            margin-bottom: 10px;
            &-text {
              font-family: "Nunito Sans";
              font-size: 14px;
              &--expanded {
                font-family: "Nunito Sans Bold";
              }
            }
            &-principal-icon {
              font-size: 19px;
              color: #AFAFD7;
              margin-right: 10px;
            }
            &-icon {
              font-size: 14px;
              transform: rotate(180deg);
              margin-left: auto;
              &--expanded {
                transform: rotate(0deg);
              }
            }
          }
          &__content-collapsible {
            display: flex;
            width: 100%;
            &-items-list {
              display: flex;
              flex-direction: column;
              width: 100%;
              box-sizing: border-box;
              padding: 0 10px;
              .filter-item {
                box-sizing: border-box;
                width: 100%;
                margin: 0 0 10px 0;
                cursor: pointer;
                width: 100%;
                padding: 10px 13px;
                border-radius: 14px;
                align-items: center;
                display: flex;
                background-color: #FFFFFF;
                &--symptoms {
                  color: #36369B;
                }
                &__point {
                  width: 11px;
                  height: 11px;
                  margin-right: 5px;
                  border-radius: 50%;
                  &--selected{
                    background-color: #FFFFFF !important;
                  }
                }
                &__icon {
                  margin-right: 8px;
                  font-size: 15px;
                }
                &:hover {
                  /* background-color: #FCFCFE; */
                }
                &--selected{
                  color: #FFF !important;
                  background-color: #72C069;
                }
                &--selected-symptom {
                  color: #FFF !important;
                  background-color: #36369B;
                }
              }
            }
          }
          &__items-selected-list {
            .selected-item {
              margin-top: 10px;
              padding: 5px;
              border-radius: 5px;
              align-items: center;
              color: #526479;
              width: fit-content;
            }
          }
        }
        .filter-separator {
          border-bottom: 1px solid #B1B1C8;
          width: 112px;
          margin: 20px auto;
        }
      }
    }
    &__right-filter-options {
      height: 100%;
      min-width: 383px;
      background-color: #FFFFFF;
      position: relative;
      box-sizing: border-box;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      display: ${({panelActive}) => panelActive ? "flex" : "none"};
    }
  }
`;

export const DashboardFilterPanelRight = styled.div`
  width: 383px;
  margin-top: 36px;
  margin-bottom: 36px;
  height: calc(100% - 77px);
  max-height: 780px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: red;
  top: 0;
  margin-left: 261px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  position: absolute;
`;

export const Trigger = styled.button`
  border-radius: 14px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* color: ${({isActive}) => isActive ? "#FFFFFF" : "#8A939D"}; */
  align-items: flex-start;
  width: 100%;
  border: none;
  border-bottom: 1px solid #6C6BCC;
  position: relative;
  margin-top: 10px;
  .trigger {
    position: relative;
    box-sizing: border-box;
    padding: ${({dataSelected}) => dataSelected ? "10px 9px 36px 18px" : "10px 9px 10px 18px"};
    width: 100%;
    text-align: left;
  }
  .trigger-title{
    color: #6C6BCC;
    font-family: "Nunito Sans";
    font-size: 14px;
  }
  .trigger-data-selected {
    color: #566377;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
  }
  .trigger-clear-button {
    position: absolute;
    right: 9px;
    bottom: 10px;
    color: #36369B;
    font-family: "Nunito Sans";
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
  }
`;

export const UsersFiltered = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .header {
    padding: 30px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-size: 20px;
      font-family: "Nunito Sans Bold";
      color: #36369B;
    }
    &__close {
      margin-left: auto;
      background-color: #E6E6E6;
      border-radius: 50%;
      width: 17.23px;
      height: 17.23px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      &-icon {
        color: #8C8DA4;
        font-size: 6px;
      }
    }
  }
  .users-list {
    padding-left: 20px;
    overflow-y: scroll;
    /* mask-image: linear-gradient(0deg,#000 95%,transparent); */
    ::-webkit-scrollbar {
      width: 48px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px transparent;
      margin: -20px 0px 0px 0px; 
    }
    ::-webkit-scrollbar-thumb {
      border: 21.5px solid rgba(0, 0, 0, 0);
      box-shadow: inset 0 0 10px 10px #E6E6E6;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: transparent;
    }
    .user-item {
      display: flex;
      padding: 8.5px 20px;
      border-radius: 10px;
      margin-bottom: 20px;
      background-color: #F8F8F8;
      align-items: center;
      color: #8C8DA4;
      cursor: pointer;
      &--selected {
        color: #3E3DA3;
        background-color: #D6D6F6;
      }
      &__info {
        margin-left: 10px;
      }
      &__name {
        font-size: 22px;
        font-family: "Nunito Sans";
      }
      &__email {
        font-size: 14px;
        font-family: "Nunito Sans Light";
      }
    }

  }
`;

export const ProcessFiltered = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .header {
    padding: 30px 20px 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      font-size: 20px;
      font-family: "Nunito Sans Bold";
      color: #36369B;
    }
    &__close {
      margin-left: auto;
      background-color: #E6E6E6;
      border-radius: 50%;
      width: 17.23px;
      height: 17.23px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      cursor: pointer;
      &-icon {
        color: #8C8DA4;
        font-size: 6px;
      }
    }
  }
  .clear-filters {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 30px;
    cursor: pointer;
    &__text {
      color: #8A939D;
      font-family: "Nunito Sans";
      font-size: 17px;
      text-decoration-line: underline;
    }
    &__icon {
      color: #8A939D;
      font-size: 11px;
      margin-left: 5px;
    }
  }
  .programs-list {
    padding-left: 20px;
    overflow-y: scroll;
    /* mask-image: linear-gradient(0deg,#000 95%,transparent); */
    ::-webkit-scrollbar {
      width: 48px;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px transparent;
      margin: -20px 0px 0px 0px; 
    }
    ::-webkit-scrollbar-thumb {
      border: 21.5px solid rgba(0, 0, 0, 0);
      box-shadow: inset 0 0 10px 10px #E6E6E6;
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: transparent;
    }
    .program-item {
      display: flex;
      padding: 23px 20px;
      border-radius: 10px;
      margin-bottom: 20px;
      background-color: #F8F8F8;
      align-items: center;
      color: #8C8DA4;
      cursor: pointer;
      &--selected {
        color: #3E3DA3;
        background-color: #D6D6F6;
      }
      &__check-icon {
        margin-right: 10px;
        font-size: 14px;
      }
      &__text {
        font-size: 19px;
        font-family: "Nunito Sans";
      }
    }

  }
`;

export const ProcessNameContainer = styled.div` 
  background-color: ${({theme}) => theme.background};
  padding: 5px;
  border-radius: 7px;
  cursor: default;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
`

export const ProcessName = styled.div` 
  font-size: 12px;
  font-family: "Nunito Bold";
  border-bottom: ${({last, theme}) => !last ? '1px solid ' + theme.lineSeparation : null};
  /* margin: 5px 0px; */
  padding: 2px 5px; 
  .card-selected-patient-process {
    color: ${({theme}) => theme.onBackgroundVariant} !important;
  }
`