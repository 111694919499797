import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./TimeHorizontalBars.style";

// pipes
import * as P from "../../../../../utils/pipes";

// Components
import ReverseProgressBar from '../reverseProgressBar';
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// assets
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;

const TimeHorizontalBars = ({ 
  activeHeader=true, 
  colorVariation, 
  colorPrimary, 
  colorSecundary,
  time,
  chartInfo,
  listLevels, 

  listLevelsStripe = false, 
  name,
  frequency,
  frequencyType
}) => {
  const [t] = useTranslation("global");

  return (
    <S.TimeHorizontalBars
      colorVariation={colorVariation}
      colorSecundary={colorSecundary}
      colorPrimary={colorPrimary}
      activeHeader={activeHeader}
    >
      <div className="weekly-bars-card">
        { activeHeader ? 
          <div className="weekly-bars-card__header">
            <div className="weekly-bars-card__header-icon">
              <i className="icon zaia-icono-glucometria"></i>
            </div>
            <div className="weekly-bars-card__header-text">
              <h4>{name}</h4>
              <p>{P.firstLetterUpper(t('patients.boxInfoPatient.report.every'))} {frequency} {frequencyType}</p>
            </div>
          </div> : ""
        }
        <div className="weekly-bars-card__bars-container">
          <div className="weekly-bars-card__bars-container-listlevels">
            { listLevels?.map(level => <p key={level}>{level}</p>) }
            { listLevelsStripe ? <p key='-'>-</p> : null }
          </div>
          <div className="weekly-bars-card__bars-container-bars">
            {
              chartInfo.length === 0 ?
              <ZaiaReaction
                zaiaImg={logoZaiaEmpty}
                widthImg="100px"
                text={t('globally.noReports')}
                sizeText="15px"
                widthText="240px"
              /> :
              chartInfo?.map((info, index) => 
                <HorizontalProgressBar 
                  key={index}
                  colorDark={colorPrimary} 
                  percentage={info.percentage} 
                  day={info.day} 
                  dayName={info.dayName}
                  time={time}
                  colorSecundary={colorSecundary}
                  colorVariation={colorVariation}
                />
              )              
            }
          </div>
        </div>
      </div>
    </S.TimeHorizontalBars>
  );
};

const HorizontalProgressBar = ({percentage, day, colorDark, time, colorSecundary, colorVariation}) => {
  const active = false
  
  return (
    <S.HorizontalProgressBarDiv
      percentage={percentage + "%"}
      colorDark={colorDark}
      active={active}
    >
      <div className="vertical-text">
        <p className="vertical-text__number">{day}</p>
      </div>
      <div className="bar-container">
        <ReverseProgressBar 
          bgColor={active ? colorVariation : colorDark}
          completed={percentage}
          bgColor2={active ? "#D7D7D7" : '#F4F4F4'}
          noText
          height='11'
          shadow={active ? colorSecundary : ''}
        />
      </div>
    </S.HorizontalProgressBarDiv>
  )
}

export default TimeHorizontalBars;
