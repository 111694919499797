import React, { useEffect, useState } from "react";
import * as S from "./SidebarPatients.style";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../../contextAPI/StateProvider";

// components
import ListOfPatients from "../../patients/components/listOfPatients";

function SidebarPatients({
  buttonHide,
  children,
  heightTop = "100px",
  defaultHide,
  urlRedirection,
  processIdFilter,
  component,
}) {
  const [t] = useTranslation("global");
  const [hide, setHide] = useState(defaultHide ? defaultHide : false);
  const [processIds, setProcessIds] = useState([]);
  const [patientsQuantity, setPatientsQuantity] = useState(0);
  const [idInputFilter, setInputFilter] = useState("");
  const [, dispatch] = useStateValue();

  const hideSection = () => {
    !hide ? setHide(true) : setHide(false);
  };

  useEffect(() => {
    if(processIds.length === 1) {
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: processIds[0],
      });
    } else if (processIds.length === 0 || processIds.length > 1) {
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: null,
      });
    }
  }, [processIds])

  return (
    <S.SidebarPatients heightTop={heightTop}>
      {hide ? null : (
        <div className="sidebar-patient">
          <div className="sidebar-patient__top">
            {React.isValidElement(children)
              ? React.cloneElement(children, {
                  processIds: processIds,
                  setInputFilter: setInputFilter,
                  setProcessIds: setProcessIds,
                  quantity: patientsQuantity,
                  typeFilter: "text",
                  filterText: t("patients.email"),
                  filterPlaceholder: t("patients.patientList.search"),
                })
              : null}
          </div>
          <div className="sidebar-patient__list">
            <ListOfPatients
              filterProccessIds={processIds}
              idPatientFilter={idInputFilter}
              patientsQuantity={patientsQuantity}
              setPatientsQuantity={setPatientsQuantity}
              urlRedirection={urlRedirection}
              processIdFilter={processIdFilter}
              component={component}
              setHide={setHide}
            />
          </div>
        </div>
      )}
      {buttonHide ? (
        <S.SidebarPatientsButton hide={hide}>
          <div
            style={{ cursor: "pointer" }}
            className="sidebarPatientsButton__icon-content"
            onClick={hideSection}
          >
            {!hide ? (
              <i className="sidebarPatientsButton__icon icon zaia-icon-ionic-md-arrow-dropdown" />
            ) : (
              <div className="sidebarPatientsButton__icon-container">
                <i className="sidebarPatientsButton__icon sidebarPatientsButton__icon--patients icon zaia-icono-pacient" />
                <i className="sidebarPatientsButton__icon sidebarPatientsButton__icon--arrow icon zaia-icon-ionic-md-arrow-dropdown-1" />
              </div>
            )}
          </div>
        </S.SidebarPatientsButton>
      ) : null}
    </S.SidebarPatients>
  );
}

export default SidebarPatients;
