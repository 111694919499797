import React, { useRef, useState } from "react";
import * as S from "./ChatbotProcessCase.style"
import * as P from "../../../../../../utils/pipes"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { CONSULT_MEDICAL_PROCESS_IN_FLORENCE, CONSULT_MEDICAL_PROCESS_IN_FLORENCE_MEDICOL } from "../../../../../../graphql/mutations/Chat";
import useSize from "../../../../../../hooks/useSize";
import { useTranslation } from "react-i18next";
import useDoctorData from "../../../../../../hooks/useDoctorData";
import ZaiaLoadingPoints from "../../../../../zaiaComponents/zaiaLoadingPoints";
import ChatbotInput from "../../chatbotInput";
import Message from "../../message";
import ModalSelectPatient from "../../../../shared/modals/modalSelectPatient";
import Modal from "../../../../../../utils/modal";
import ZaiaSelect from "../../../../../zaiaComponents/zaiaSelect";
import { QUERY_GET_PROCESS_NAMES, QUERY_GET_PROCESS_TYPES_BY_ID } from "../../../../../../graphql/queries/Process";
import { useMemo } from "react";

const bgChatbot = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/chat-background.svg`
const defaultPatientImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user2.svg`

function ChatbotProcessCase(
  {
    selectedPatient,
    selectPatient,
    selectedProcess,
    selectedPartition,
    selectProcess,
    selectPartition
  }
) {

  const footerRef = useRef();
  const { height } = useSize(footerRef);
  const element = document.getElementById("chatbot-container");
  const {t, i18n: { language }} = useTranslation("global");
  const { doctorData } = useDoctorData();

  const [mediccolActived, setMediccolActived] = useState(false);
  const [threadId, setThreadId] = useState(null);

  // const [partitionSelected, setPartitionSelected] = useState();
  // const [processSelected, setProcessSelected] = useState();
  const { data: processTypes } = useQuery(
    QUERY_GET_PROCESS_TYPES_BY_ID,
    { 
      variables: { 
        processId: [selectedProcess?.id],
        technology: "google" 
      } 
    }
  );
  const { data: processData } = useQuery( QUERY_GET_PROCESS_NAMES );  

  const disabledChatbot = () => {
    return !(selectedProcess && processTypes?.process[0]?.vector_context_collections[0]?.vector_context_collection_partitions?.length > 0)
  }

  const processTypesOptions = useMemo(() => {
    const list = processTypes?.process[0]?.vector_context_collections[0]?.vector_context_collection_partitions?.map((partition) => {
      return {
        value: partition.partition_name,
        text: partition.document_name
      };
    });

    return list ? list : [];
  }, [processTypes]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    patientMessage(values.msg, 'doctor');
    sendBotMessage(values.msg, setSubmitting, resetForm);
    autoScroll();
  };

  const sendBotMessage = async (msg, setSubmitting, resetForm) => {    
    try {
      if(resetForm) resetForm();
      if(setSubmitting) setSubmitting(false);
      if(mediccolActived) {
        sendChatbotMessageMediccol(msg);
      } else {
        sendChatbotMessage(msg);
      }
    } catch (error) {
      console.log(error);
    }
  }


  const patientMessage = (msg, type) => {
    let allMessages = messages;
    allMessages.push(
      {
        id: Date.now(),
        message: msg,
        senderName: `${doctorData?.name} ${doctorData?.lastname}`,
        type: type
      }
    )
    setMessages([...allMessages])
  }

  const autoScroll = () => {
    if(element) element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  const [chatBotProcess, {loading: chatBotProcessLoading}] = useLazyQuery(
    CONSULT_MEDICAL_PROCESS_IN_FLORENCE,
    {
      fetchPolicy: "network-only"
    }  
  );

  const [chatBotProcessMediccol, {loading: chatBotProcessMediccolLoading}] = useMutation(
    CONSULT_MEDICAL_PROCESS_IN_FLORENCE_MEDICOL  );

  const sendChatbotMessage = async (msg) => {
    const partitionSelected = processTypes?.process[0]?.vector_context_collections[0].vector_context_collection_partitions.find((item) => item.partition_name === selectedPartition.value); 

    const {
      data: {
        consult_medical_process_in_florence,
      },
    } = await chatBotProcess({
      variables: {
        question: msg,
        language: language.split('-')[0],
        partition_name: partitionSelected.partition_name,
        collection_name: partitionSelected.vector_context_collection.collection_name,
        technology: partitionSelected.vector_context_collection.embedding_technology,
        embedding_model: partitionSelected.vector_context_collection.embedding_model,
        prediction_model: "medpalm2@preview" 
      },
    });

    if (!chatBotProcessLoading ) {
      setMessages(
        [
          ...messages,
          {
            id: Date.now(),
            message: consult_medical_process_in_florence.answer,
            senderName: t("globally.chatbotName"),
            type: 'bot',
          }
        ]
      )
      autoScroll()
    }
  }

  // MEDDICOL_ASSISTANT CASE
  const sendChatbotMessageMediccol = async (msg) => {

    const {
      data: { consult_ai_assistant }
    } = await chatBotProcessMediccol({
      variables: {
        assistant_id: "asst_HAiYwvFnX9rl7zAxJ8qqjZBH",
        message: msg,
        thread_id: threadId,
        user_id: doctorData.id,
      },
    });

    // console.log("dataaaaa", consult_ai_assistant);
    if (!chatBotProcessMediccolLoading ) {
      if(!threadId) setThreadId(consult_ai_assistant.thread_for_completion)
      setMessages(
        [
          ...messages,
          {
            id: Date.now(),
            message: consult_ai_assistant.answer,
            senderName: t("globally.chatbotName"),
            type: 'bot',
          }
        ]
      )
      autoScroll()
    }
  }


  const processSelection = (processId) => {
    selectProcess(processData?.process.find(process => process.id === processId))
  }

  const partitionSelection = (partitionId) => {
    selectPartition(processTypesOptions.find(partition => partition.value === partitionId))
  }
  
  const [messages, setMessages] = useState([]);

  return (
    <S.ChatbotProcessCase 
      footerHeight={height}
      selectedProcess={selectedProcess}
      selectedPartition={selectedPartition}
    >
      <div className="chatbot-selectors">
        { 
          !selectedProcess &&
          <div className="chatbot_selector chatbot_selector--process">
            <div className="title">
              {/* <i className="title__icon"></i> */}
              <div className="title__text">
                {t("chatbotAI.processToConsult")}
              </div>
              <div className="title__required">*</div>
            </div>
            <div className="input-container">
              <ZaiaSelect
                type="select"
                name="phoneLocation"
                setValue={(e) => 
                  processSelection(e)
                }
                value={selectedProcess?.id}
                heightList={"250px"}
                items={processData?.process.map(
                  process => {
                    return {
                      value: process.id,
                      text: P.dataTranslation(process.processName, language)
                    }
                  }
                )}
                shadow={false}
                fontSize="14px"
                backgroundColor={"#F9F9FC"}
                borderRadius={"10px"}
                textColor={"#36369B"}
                arrowColor={"#59599E"}
              />
            </div>
          </div>
        }
        { 
          !selectedPartition &&
          <div className="chatbot_selector chatbot_selector--guide">
            <div className="title">
              {/* <i className="title__icon"></i> */}
              <div className="title__text">
                {t("chatbotAI.guidelineToConsult")}
              </div>
              <div className="title__required">*</div>
            </div>
            <div className="input-container">
              <ZaiaSelect
                type="select"
                name="phoneLocation"
                setValue={(e) => 
                  {
                    if(e === "MEDICCOL_ASSISTANT") {
                      setMediccolActived(true)
                    }
                    partitionSelection(e)
                  }
                }
                value={selectedPartition?.id}
                items={processTypesOptions}
                shadow={false}
                fontSize="14px"
                disabled={disabledChatbot()}
                backgroundColor={"#F9F9FC"}
                borderRadius={"10px"}
                textColor={"#36369B"}
                arrowColor={"#59599E"}
              />
            </div>
          </div>
        }
      </div>
      <div className="chatbot-container" id="chatbot-container">
        {
          messages.length === 0 &&
          <div className="chatbot-container__empty">
            <img src={bgChatbot} alt="" className="chatbot-container__empty-image" />
            <div className="chatbot-container__empty-msg">
              {t("chatbotAI.guidelines.letsTalk")}
            </div>
          </div>
        }
        {messages.map((message, index) => (
          <div key={message.id}>
            { 
              <Message
                key={index}
                message={message.message?.replace('0chatgpt', '')}
                senderName={message.senderName}
                option={message.type}
              />
            }
          </div>
        ))}
        { 
          chatBotProcessLoading || chatBotProcessMediccolLoading
          ? <Message
            senderName={t("globally.chatbotName")}
          >
            <ZaiaLoadingPoints />
          </Message> 
          : null
        }
      </div>
      <div className="chatbot-footer" ref={footerRef}>
        <ChatbotInput
          chatbotDisabled={!(selectedProcess && selectedPartition)}
          disabledText={t("chatbotAI.guidelines.inputCondition")}
          handleSubmit={handleSubmit}
        />
      </div>
    </S.ChatbotProcessCase>
  );

}

export default ChatbotProcessCase;
