import React, { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import * as S from "./OutcomesTable.style";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

// pipes
import * as P from "../../../../../../../../../utils/pipes";

// Hooks
import useDoctorData from "../../../../../../../../../hooks/useDoctorData";

// GraphQL
import { useMutation } from "@apollo/client";
import {
  MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD,
  MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD,
  MUTATION_UPDATE_DOCTOR_TO_USER_PROCESS_BOARD,
} from "../../../../../../../../../graphql/mutations/DoctorToUserProcessBoard";
import Popup from "reactjs-popup";
import ZaiaReaction from "../../../../../../../../zaiaComponents/zaiaReaction";
import { ThemeContext } from "styled-components";
import useActivateModal from "../../../../../../../../../hooks/useActivateModal";
import NewEventModal from "../newEventModal";
import InvitePatientButton from "../../../../../../../patients/components/invitePatientButton";
import Modal from "../../../../../../../../../utils/modal";
import ModalAddOutcomesEvent from "../../../../../../../shared/modals/modalAddOutcomesEvent";
import ModalOutcomesEventHistory from "../../../../../../../shared/modals/modalOutcomesEventHistory";

const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;

function OutcomesTable({
  patientID,
  processID,
  processToCustomFormID,
  customFormData,
  customFormLoading,
  userToProcessData,
  highlightsFilter,
  eventFilter
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { doctorData } = useDoctorData();
  const theme = useContext(ThemeContext);
  const { activeModal, desactiveModal } = useActivateModal();
  const [createEventCalendarActive, setCreateEventCalendarActive] = useState(false);
  const [seeEventHistoryActive, setSeeEventHistoryActive] = useState(false);
  const [cmpIdSelected, setCmpIdSelected] = useState(null);

  const [insertDoctorToUserProcessBoard] = useMutation(
    MUTATION_INSERT_DOCTOR_TO_USER_PROCESS_BOARD
  );
  const [deleteDoctorToUserProcessBoard] = useMutation(
    MUTATION_DELETE_DOCTOR_TO_USER_PROCESS_BOARD
  );
  const [updateDoctorToUserProcessBoard] = useMutation(
    MUTATION_UPDATE_DOCTOR_TO_USER_PROCESS_BOARD
  );

  const insertBoardData = async (customFormId) => {
    try {
      await insertDoctorToUserProcessBoard({
        variables: {
          customFormId: customFormId,
          typeView: "new",
          doctorId: doctorData.id,
          userToProcessId: userToProcessData.userToProcess[0].id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const updateBoardData = async (doctorToUserProcessBoardId, state) => {
    try {
      await updateDoctorToUserProcessBoard({
        variables: {
          id: doctorToUserProcessBoardId,
          typeView: state ? "new" : "history",
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteBoardData = async (doctorToUserProcessBoardId) => {
    try {
      await deleteDoctorToUserProcessBoard({
        variables: {
          id: doctorToUserProcessBoardId,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getOptionsWithPayload = (formFields) => {
    return formFields.map((option) => {
      const { customFormTypeField, userToCustomFormFields } = option;
  
      if (
        !customFormTypeField?.fieldOptions?.length ||
        !userToCustomFormFields?.length
      ) {
        return { ...option, payload: null };
      }
  
      const fieldOption = customFormTypeField.fieldOptions.find(
        (field) => field.key === userToCustomFormFields?.[0].key
      );
  
      if (!fieldOption?.payload) {
        return { ...option, payload: null };
      }
  
      let payloadFormated;
  
      try {
        payloadFormated = JSON.parse(fieldOption.payload);
        option.payload =
          "msg" in payloadFormated ? payloadFormated.msg : null;
      } catch (err) {
        option.payload = null;
      }
  
      return option;
    });
  }

  const customFormOrder = useMemo(() => {
    let formGroupsFilter = 
      highlightsFilter
      ? customFormData?.customForm_by_pk.formGroups.filter(
          (formGroup) => formGroup.DoctorToUserProcessBoards[0]
        )
      : customFormData?.customForm_by_pk.formGroups;
  
    if(formGroupsFilter?.length === 0) {
      formGroupsFilter = customFormData?.customForm_by_pk.formGroups;
    }

    return formGroupsFilter?.map((formGroup) => {

      const validation = !formGroup?.customFormFields[0]?.userToCustomFormFields || formGroup?.customFormFields[0]?.userToCustomFormFields?.length <= 0 ? false : true;      

      return [    
        formGroup,    
        {
          info: getOptionsWithPayload(formGroup.customFormFields),
          date: validation
            ? P.formatNumberDate(
              formGroup?.customFormFields[0]?.userToCustomFormFields?.[0]?.created_at,
              true,
              language
            )
            : "-",
          doctor: validation &&
            formGroup?.customFormFields[0]?.userToCustomFormFields?.[0]?.doctor
              ? `${formGroup?.customFormFields[0]?.userToCustomFormFields?.[0]?.doctor.name} ${formGroup?.customFormFields[0]?.userToCustomFormFields?.[0]?.doctor.lastname}`
              : "–"
        },
        formGroup?.userToProcessHistories[0] && formGroup.userToProcessHistories[0]?.descript
      ]
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFormData, highlightsFilter]);

  const modalPatient = (formFieldID) => {
    return (
      <>
        <NewEventModal
          patientID={patientID}
          processID={processID}
          processToCustomFormID={processToCustomFormID}
          customFormFieldID={formFieldID}
        />
        <div onClick={() => desactiveModal()}>
          <InvitePatientButton activate={true} />
        </div>
      </>
    );
  };

  return (
    <S.OutcomesTable>
      <Modal open={createEventCalendarActive}>
        <ModalAddOutcomesEvent
          setCreateEventActive={setCreateEventCalendarActive}
          cmpID={cmpIdSelected}
          patientID={patientID}
          processID={processID}
        />
      </Modal>
      <Modal open={seeEventHistoryActive}>
        <ModalOutcomesEventHistory
          setSeeEventHistoryActive={setSeeEventHistoryActive}
          cmpID={cmpIdSelected}
          patientID={patientID}
          processID={processID}
          setCreateEventActive={setCreateEventCalendarActive}
        />
      </Modal>
      <table className="illness-control-table">
        <thead>
          <tr>
            <th className="th-event-type">{t("globally.eventType")}</th>
            <th>{t("outcomes.tableOutcomes.details")}</th>
            {/* <th>{t("outcomes.tableOutcomes.doctorInCharge")}</th> */}
            {/* <th></th> */}
            {/* <th>{t("globally.date")}</th> */}
            <th>{t("globally.comment")}</th>
          </tr>
        </thead>
        <tbody>
          {customFormOrder?.map((formGroup) => {
            return (
              (eventFilter?.find((event) => event === formGroup[0].id) || eventFilter?.length === 0) &&
              <tr key={formGroup[0].id}>
                {formGroup?.map((formGroup, index) => {
                  if (index === 1) {
                    return(<RenderCustomFormGroupsindex1 
                      key={index}
                      formGroup={formGroup}
                      />)
                    }
                    if (index === 0) {
                      return(<RenderCustomFormGroupsIndex0 
                        key={index}
                        formGroup={formGroup} 
                        deleteBoardData={deleteBoardData}
                        insertBoardData={insertBoardData}
                        setCreateEventCalendarActive={setCreateEventCalendarActive}
                        setCmpIdSelected={setCmpIdSelected}
                        setSeeEventHistoryActive={setSeeEventHistoryActive}
                      />)
                    }
                    return (<RenderCustomFormGroupsindex2
                      key={index}
                      formGroup={formGroup}
                    />);
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {customFormLoading && (
        <div className="illness-control-reaction">
          <ZaiaReaction widthImg="170px" zaiaImg={logoZaiaLoading} />
        </div>
      )}
      {!customFormLoading && customFormData === undefined && (
        <div className="illness-control-reaction">
          <ZaiaReaction
            zaiaImg={logoZaiaEmpty}
            widthImg="170px"
            text={t("outcomes.tableOutcomes.empty")}
            sizeText="18px"
            widthText="200px"
          />
        </div>
      )}
    </S.OutcomesTable>
  );
}

function RenderCustomFormGroupsindex2({formGroup}) {

  const [expandComment, setExpandComment] = useState(false);
  const commentSpace = useRef(null);
  const {t} = useTranslation("global");
  const [showExpandButton, setShowExpandButton] = useState(false);

  useLayoutEffect(() => {
    if (commentSpace.current?.clientHeight > 120) {
      setShowExpandButton(true);
    }
  }, []); 

  return (
    <td 
      key={uuidv4()}
      className="td-2"                 
    >
      <div className="comment-container"
        style={{height: expandComment ? "auto" : "120px"}}
      >
        <div className="comment-space"
          ref={commentSpace}
        >
          {formGroup}
        </div>
      </div>
      {
        showExpandButton && 
        <div className="expand-comment"
          onClick={() => {setExpandComment(!expandComment)}}
        >
          {
            expandComment ? t("globally.showLess") : t("globally.showMore")
          }
        </div>
      }
    </td>
  )
}

function RenderCustomFormGroupsIndex0(
  {
    formGroup, 
    deleteBoardData,
    insertBoardData,
    setCreateEventCalendarActive,
    setCmpIdSelected,
    setSeeEventHistoryActive
  }
) { 

  const {t, i18n: { language }} = useTranslation("global");
        
  return (
    <td
      key={uuidv4()}
      className="td-0"
    >
      <div className="event-info">
        {formGroup.DoctorToUserProcessBoards[0] ? (
          <div className="highlight-button highlight-button--active">
            <i
              className="icon-save-form-field icon zaia-star-on"
              onClick={() =>
                deleteBoardData(
                  formGroup.DoctorToUserProcessBoards[0].id
                )
              }
            ></i>
          </div>
        ) : (
          <div className="highlight-button">
            <i
              className="icon-save-form-field icon zaia-star-off"
              onClick={() => insertBoardData(formGroup.id)}
            ></i>
          </div>
        )}
        <div className="event-type">
          <div className="event-type__name">
            {P.dataTranslation(formGroup.customFormName, language)}
            {formGroup.descript && (
              <Popup
                trigger={
                  <i className="template-header-item-icon icon zaia-alert"></i>
                }
                on={["hover", "focus"]}
                closeOnDocumentClick
                keepTooltipInside
                position={["right"]}
                nested
              >
                <S.PopUpPayload>
                  <p>
                    {P.dataTranslation(
                      formGroup.descript,
                      language
                    )}
                  </p>
                </S.PopUpPayload>
              </Popup>
            )}
          </div>
          <div className="event-type__options">
            <div className="event-type__option" 
              onClick={() =>{
                  setCmpIdSelected(formGroup.id);
                  setCreateEventCalendarActive(true)
                }
              }
            >
              <div className="event-type__option-text">{t("aiNavigator.outcomes.updateEvent")}</div>
              <i className="event-type__option-icon icon zaia-icono-agregar"></i>
            </div>
            {
              
              formGroup.userToProcessHistories.length > 0 &&
              <div className="event-type__option"
                onClick={() =>{
                  setCmpIdSelected(formGroup.id);
                  setSeeEventHistoryActive(true);
                }
              }
              >
                <div className="event-type__option-text">{t("aiNavigator.outcomes.showUpdateHistory")}</div>
                <i className="event-type__option-icon icon zaia-icono-editar"></i>
              </div>
            }
          </div>
        </div>
      </div>
    </td>
  );
}

function RenderCustomFormGroupsindex1({formGroup}) {

  const [expandList, setExpandList] = useState(false);
  const outcomesList = useRef(null);
  const {t, i18n: { language }} = useTranslation("global");

  const [showExpandButton, setShowExpandButton] = useState(false);

  useLayoutEffect(() => {
    if (outcomesList.current?.clientHeight > 70) {
      setShowExpandButton(true);
    }
  }, []); 

  function getFormattedValue(item) {
    const userField = item.userToCustomFormFields?.[0];
    let formattedValue = "–";
    if (userField) {
      formattedValue =
        item.typeFieldValue === "dateInput"
          ? P.formatNumberDate(userField.value, true, language)
          : userField.value;
    }
    return formattedValue;
  }  

  useEffect(() => {
  }, [outcomesList]);

  return (
    <td 
      className="td-1"
      key={uuidv4()}
    >
      <div className="outcomes-data">
        <div className="changes-info">
          <div className="changes-item">
            <div className="changes-item__label">{t("aiNavigator.outcomes.doctorWhoEdited")}</div>
            <div className="changes-item__info">{formGroup.doctor}</div>
          </div>  
          <div className="changes-item">
            <div className="changes-item__label">{t("globally.date")}</div>
            <div className="changes-item__info">{formGroup.date}</div>
          </div>
        </div>
        <div 
          className="outcomes-list-container"
          style={{height: expandList ? "auto" : "70px"}}
          >
          <div 
            className="outcomes-list"
            ref={outcomesList}
          >
            {formGroup.info.length <= 0 && "-"}
            {formGroup.info.length > 0 && 
              formGroup.info.map((item, index) => {
                return (
                  <div key={item.id}>
                    <div className="detail">
                      <div className="detail__label">
                        {P.dataTranslation(item.fieldName, language)}
                        {item.descript && (
                          <Popup
                            trigger={
                              <i className="template-header-item-icon icon zaia-alert"></i>
                            }
                            on={["hover", "focus"]}
                            closeOnDocumentClick
                            keepTooltipInside
                            position={["right center"]}
                            nested
                          >
                            <S.PopUpPayload>
                              <p>
                                {P.dataTranslation(item.descript, language)}
                              </p>
                            </S.PopUpPayload>
                          </Popup>
                        )}
                      </div>
                      <div className="detail__data">
                        {
                          item.typeFieldValue === "fileInput"
                          ? <div className="file-value">
                            <i className="file-value__icon icon zaia-i-doc-normal"></i>
                            <a className="file-value__text"  target="_blank" rel="noopener noreferrer" href={item.userToCustomFormFields?.[0]?.key} download={getFormattedValue(item)}>{getFormattedValue(item)}</a>
                          </div>
                          : getFormattedValue(item)
                        }
                        {item.payload && (
                          <Popup
                            trigger={
                              <i className="template-header-item-icon icon zaia-alert"></i>
                            }
                            on={["hover", "focus"]}
                            closeOnDocumentClick
                            keepTooltipInside
                            position={["right"]}
                            nested
                          >
                            <S.PopUpPayload>
                              <p>{item.payload}</p>
                            </S.PopUpPayload>
                          </Popup>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            }
          </div>
        </div>
        {
          showExpandButton && 
          <button className="expand-list"
            onClick={() => {setExpandList(!expandList)}}
          >
            <div className="expand-list__name">
              {
                expandList ? t("globally.showLess") : t("globally.showMore")
              }
            </div>
            <i className={`expand-list__icon icon ${expandList ? "zaia-i-up" : "zaia-icono-dropdown-light"} `}></i>
          </button>
        }
      </div>
    </td>
  );
}

export default OutcomesTable;
