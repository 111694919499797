import React, { useRef, useState, useEffect, useContext } from "react";
import * as S from "./ZaiaRoundInput.style.js";
import ZaiaImage from "../zaiaImage";
import { ThemeContext } from 'styled-components';
const defaultUserImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;

function ZaiaRoundInput({ 
  placeholder, 
  padding="4px", 
  type="text", 
  getText,
  items,
  tagSelectedText,
  selectItem,
  deleteSelect
}) {
  const theme = useContext(ThemeContext);

  const container = useRef(null);
  const textInput = useRef(null);
  const [colorIcon, setColorIcon] = useState(theme.inputBorder)

  const [listActive, setlistActive] = useState(false)

  
  useEffect(() => {
    const handleClickOutside = e => {
      if (!container.current.contains(e.target)) {
        setlistActive(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  function handleChange(item) {
    setlistActive(false);
    selectItem(item.patient);
  }

  const focusInput = () => {
    setColorIcon(theme.inputSelected);
    textInput.current.focus();
  }

  const blurInput = () => {
    setColorIcon(theme.inputBorder);
  }

  return (
    <S.ZaiaRoundInput padding={padding} listActive={listActive} tabIndex={0} onFocus={() => focusInput()}  onBlur={() => blurInput()}>
      <div className="zaia-input" ref={container}>
        <div className="zaia-input-container" onClick={(e) => setlistActive(e)}>
          <input 
            ref={textInput} 
            type={type} 
            placeholder={placeholder} 
            onChange={(event) => getText(event.target.value)}
            disabled={tagSelectedText ? true : false}
          />
          {
            tagSelectedText ? 
            <div className="zaia-input-tag-selected">
              <div className="zaia-input-tag-selected-text">{tagSelectedText}</div>
              <i className="zaia-input-tag-selected-icon icon zaia-icono-cerrar" onClick={() => deleteSelect()}/>
            </div> : null 
          }
          <i className="icon-search icon zaia-icono-search" 
            // style={{ color: colorIcon }}
          ></i>
        </div>
        {
          items?.length > 0 ? 
          <div className="zaia-input-options-container" >
            {
              items.map(item => {
                return (
                  <div className="zaia-input-option" key={item.value} onClick={() => handleChange(item)}>
                    <div className="zaia-input-option-image-container">
                      <ZaiaImage
                        size="40"
                        img={item.image}
                        defaultImg={defaultUserImg}
                      />
                    </div>
                    <div className="zaia-input-option-info-container">
                      <div className="zaia-input-option-title">{item.title}</div>
                      <div className="zaia-input-option-sub-title">{item.subtitle}</div>
                    </div>
                  </div>
                )
              })
            }
          </div> : null
        }
      </div>
    </S.ZaiaRoundInput>
  );
}

export default ZaiaRoundInput;
