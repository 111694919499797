import React, { useEffect, useMemo, useState } from "react";
import * as S from  "./SurveyQuestionsCharts.style";
import GraphNumericSurvey from "../graphNumericSurvey";
import { SUBSCRIPTION_GET_CUSTOM_FORM_BY_ID } from "../../../../../../graphql/subscriptions/CustomForm";
import { QUERY_GET_CUSTOM_FORMS_BY_IDS } from "../../../../../../graphql/queries/CustomForm";
import { useQuery, useSubscription } from "@apollo/client";
import { QUERY_GET_USER_TO_PROCESS } from "../../../../../../graphql/queries/UserToProcess";
import { useTranslation } from "react-i18next";
import * as P from "../../../../../../utils/pipes";
import ZaiaReaction from "../../../../../zaiaComponents/zaiaReaction";

const zaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function SurveyQuestionsCharts({
  patientID,
  processID,
  customFormId
}) {
  const {t, i18n: { language } } = useTranslation("global");
  const subsActive = window.navigator.userAgent.indexOf('Mac') === -1;

  const { data: userToProcessData } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientID, processId: processID },
  });

  const { 
    data: customFormSub, 
    loading: customFormLoadingSub 
  } = useSubscription(
    SUBSCRIPTION_GET_CUSTOM_FORM_BY_ID,
    {
      variables: {
        userId: patientID,
        // doctorId: doctorData.id,
        customFormId: customFormId,
        userToProcessId: userToProcessData?.userToProcess[0]?.id,
      },
    }
  );

  const { 
    data: customForm, 
    loading: customFormLoading 
  } = useQuery(
    QUERY_GET_CUSTOM_FORMS_BY_IDS,
    {
      variables: {
        userId: patientID,
        customFormId: customFormId,
        userToProcessId: userToProcessData?.userToProcess[0]?.id,
      },
    }
  );

  const customFormDate = useMemo(() => {
    return(
      subsActive ? customFormSub : customForm
    )
  }, [subsActive,   customFormSub, customForm])

  const getLineGraphData = (data) => {
    return data.map((item) => {
      const dateWithoutOffset = new Date(new Date(item.created_at).getTime() - (new Date(item.created_at).getTimezoneOffset() * 60000));   

      return (
        [
          new Date(dateWithoutOffset),
          item.value
        ]
      )
    })
  }

  const getLineGraphInfo = (data) => {
    return data.map((item) => {
      return (
        {
          customFormName: P.dataTranslation(item.customFormField.fieldName, language),
          customFormFieldName: P.dataTranslation(item.customFormField.fieldName, language),
          date: item?.created_at,
        }
      )
    })
  }

  if(customFormLoadingSub || customFormLoading)
    return (
      <S.SurveyQuestionsCharts>
        <div className="no-surveys">
          <ZaiaReaction
            zaiaImg={logoZaiaLoading}
            sizeText="20px"
            textColor="var(--blue-color)"
            widthImg="110px"
          />
        </div>
      </S.SurveyQuestionsCharts>
    )

  if(!customFormDate?.customForm_by_pk?.formGroups.find((formGroup) => formGroup.customFormFields.find(field => field.typeFieldValue === "numberInput" || field.typeFieldValue === "floatInput")))
    return (
      <S.SurveyQuestionsCharts>
          <div className="no-surveys">
            <ZaiaReaction
              zaiaImg={zaiaEmpty}
              text={t("surveys.noQuestionsForGraph")}
              // subText={}
              sizeText="33.25px"
              sizeSubText="15px"
              textColor="var(--blue-color)"
              widthImg="210px"
            />
          </div>
        </S.SurveyQuestionsCharts>
    )

  return(
    <S.SurveyQuestionsCharts>
      {
        customFormDate?.customForm_by_pk?.formGroups.map((formGroup, index) => {
          if(formGroup.customFormFields.find(field => field.typeFieldValue === "numberInput" || field.typeFieldValue === "floatInput")) {
            return (
              <div className="chart-list-container">
                <div className="chart-list-container__title">{P.dataTranslation(formGroup.customFormName, language)}</div>
                {
                  formGroup.customFormFields.map((field, index) => {
                    if(field.typeFieldValue === "numberInput" || field.typeFieldValue === "floatInput") {
                      getLineGraphData(field.userToCustomFormFields);
                      return (
                        <div className="chart-container">
                          <div className="chart-container__label">{index + 1}. {P.dataTranslation(field.fieldName, language)}</div>
                          <div className="chart-container__type">
                            Numerical
                          </div>
                          <div className="chart-container__chart">
                            <GraphNumericSurvey
                              // lineGraphData={getLineGraphData(field.userToCustomFormFields)}
                              lineGraphData={
                                getLineGraphData(field.userToCustomFormFields)
                                // [
                                //   [
                                //       1707338024103,
                                //       "160"
                                //   ],
                                //   [
                                //       1707338032934,
                                //       "155"
                                //   ],
                                //   [
                                //       1707338040381,
                                //       "165"
                                //   ],
                                //   [
                                //       1707338048599,
                                //       "180"
                                //   ]
                                // ]
                              }
                              markersGraphData={[]}
                              lineGraphInfo={
                                getLineGraphInfo(field.userToCustomFormFields)
                                // [
                                //   {
                                //       "customFormName": "Colesterol c-LDL",
                                //       "customFormFieldName": "Colesterol c-LDL"
                                //   },
                                //   {
                                //       "customFormName": "Colesterol c-LDL",
                                //       "customFormFieldName": "Colesterol c-LDL"
                                //   },
                                //   {
                                //       "customFormName": "Colesterol c-LDL",
                                //       "customFormFieldName": "Colesterol c-LDL"
                                //   },
                                //   {
                                //       "customFormName": "Colesterol c-LDL",
                                //       "customFormFieldName": "Colesterol c-LDL"
                                //   }
                                // ]
                              }
                              markersGraphInfo={[]}
                            />
                          </div>
                        </div>
                      )
                    } else {
                      return null
                    }
                  })
                }
              </div>
            )
          } else {
            return null
          }
        })
      }
    </S.SurveyQuestionsCharts>
  );
}

export default SurveyQuestionsCharts;