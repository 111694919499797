import React, { useState } from "react";
import * as S from "./ModalPatientCard.style";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";

// Components
import Box from "../../../dashboard/components/box";

// pipes
import * as P from "../../../../../utils/pipes"

// Hooks
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import TooltipChats from "../../tooltipChats";
import TooltipProcesses from "../../tooltipProcesses";


function ModalPatientCard({ selectedBox }) {
  const { t, i18n: { language }} = useTranslation("global");
  const [seePhone, setSeePhone] = useState(false);

  return (
    <S.ModalPatientCard>
      <div className="box-container">
        <Box
          patient={selectedBox.user?.name + " " + selectedBox.user?.lastname}
          time={P.hoursIn12format(new Date(selectedBox.orderDate).getHours(), new Date(selectedBox.orderDate).getMinutes())}
          date={P.shortDate(new Date(selectedBox.orderDate), t, true, language)}
          description={selectedBox.description}
          report={t("dashboard.boxes.vitalSigns.report")}
          type={selectedBox.type}
          patientPhoto={selectedBox.user?.picture}
          levelReport={selectedBox.reportlevel}
          completeBox={true}
        />
        <div className="buttons-box-container">
          <div className="buttons-box-patient">
            <div className="button-box-patient" onClick={() => setSeePhone(!seePhone)}>
              <ZaiaButton
                type="button"
                option="primary"
                marginTop="20"
                title={t("dashboard.boxes.boxOption.call")}
                icon="zaia-icono-llamar"
                fontFamily="Nunito ExtraBold"
                fontSize="15"
                borderRadius="10"
                shadow={true}
              />
            </div>
            
            <Popup
              trigger={
                <div className="button-box-patient">
                  <ZaiaButton
                    type="button"
                    option="primary"
                    marginTop="20"
                    title={t("dashboard.boxes.boxOption.chat")}
                    icon="zaia-icono-chat"
                    fontFamily="Nunito ExtraBold"
                    fontSize="15"
                    borderRadius="10"
                    shadow={true}
                  />
                </div>
              }
            >
              <TooltipChats patientID={selectedBox?.user.id}/>
            </Popup>
          </div>
          <div className="buttons-box-patient">
            
            <Popup
              trigger={
                <div className="button-box-patient">
                  <ZaiaButton
                    type="button"
                    option="primary"
                    marginTop="20"
                    title={t("globally.outcomes")}
                    icon="zaia-icono-resultados"
                    fontFamily="Nunito ExtraBold"
                    fontSize="15"
                    borderRadius="10"
                    shadow={true}
                  />
                </div>
              }
            >
              <TooltipProcesses patientID={selectedBox?.user.id}/>
            </Popup>
          </div>
          <div className="phone-number-container">
            {
              seePhone ? 
              <div className="contact-info">
                <div className="contact-info-box contact-info-box--patient">
                  <div className="label label--patient">
                    {t("dashboard.boxes.boxOption.callPatient")}
                  </div>
                  <div className="number number--patient">
                    {selectedBox.user.phone}
                  </div>
                </div>
                <div className="contact-info-box contact-info-box--emergency">
                  <div className="label label--emergency">
                    {t("dashboard.boxes.boxOption.emergencyContact")}
                  </div>
                  <div className="name">
                    {selectedBox.user.nameCarer}
                  </div>
                  <div className="number number--emergency">
                    {selectedBox.user.phoneCarer}
                  </div>
                </div>
              </div>
              // <ZaiaButton
              //   type="button"
              //   option="primary"
              //   marginTop=""
              //   title={selectedBox.user.phone}
              //   shadow={true}
              //   fontFamily="Nunito ExtraBold"
              //   fontSize="20"
              //   color='var(--blue-color)'
              //   backgroundColor='var(--white)'
              //   border={false}
              // /> 
              : null
            }
          </div>
        </div>
      </div> 
    </S.ModalPatientCard> 
  );
}

export default ModalPatientCard;
