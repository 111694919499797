import React from "react";
import useActivateModal from "../../../../../hooks/useActivateModal.js";
import InvitePatientButton from "../../../patients/components/invitePatientButton/InvitePatientButton.jsx";
import * as S from "./ImgMessage.style.js";

function ImgMessage({ img, alt, height, width, rounded=true, pointer=true, interation=true }) {

  const { activeModal, desactiveModal } = useActivateModal();

  const modalImage = () => {
    return (
      <>
        <S.ModalContainer>
          <S.ImgMessage 
            src={img}
            alt={alt}
            height={height}
            // width={width}
            rounded={false}
            pointer={false}
            marginAuto={false}
          />
        </S.ModalContainer>
        <div onClick={() => desactiveModal()}>
          <InvitePatientButton activate={true} />
        </div>
      </>
    )
  } 

  return (
    <S.ImgMessage 
      height={250}  
      src={img}
      alt={alt}
      rounded={true}
      pointer={true}
      marginAuto={true}
      onClick={() => activeModal(() => modalImage())}
    />
  );
}

export default ImgMessage;
