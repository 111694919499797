import React, { useState } from "react";
import * as S from "./Surveys.style.js";

// utils
import SurveyList from "./components/surveyList/SurveyList.jsx";
import SurveyView from "./components/surveyView/SurveyView.jsx";

function Surveys() {
  
  const [surveySelected, setSurveySelected] = useState(false);

  return <S.Surveys>
      <div className="survey-component"
        style={{display: surveySelected && "none"}}
      >
        <SurveyList 
          setSurveySelected={setSurveySelected}
        />
      </div>
    <div className="survey-component" 
      style={{display: !surveySelected && "none"}}
    >
      <SurveyView 
        surveySelected={surveySelected}
        setSurveySelected={setSurveySelected}
      />
    </div>
  </S.Surveys>
}

export default Surveys;