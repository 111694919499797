import styled, { keyframes } from "styled-components";

const dotFlashing = () => keyframes`
  0% {
    background-color: ${({color}) => color};
  }
  50%,100% {
    background-color: rgba(62, 61, 169, 0.2);
  }
`;

export const ZaiaReaction = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  margin: auto;
  padding: ${({padding}) => padding ? padding : ""};
  img {
    width: ${(p) => p.widthImg};
    margin-bottom: ${(p) => p.separation};
  }
  .zaia-reaction-text {
    font-family: ${(p) => p.weightText ? "Nunito Sans Bold" : "Nunito Sans" };
    color: ${(p) => p.textColor ? p.textColor : "#37379B"};
    width: ${(p) => p.widthText};
    margin: auto;
  }
  .reaction-text {
    display: flex;
    flex-direction: ${({loadingPoints}) => loadingPoints ? "" : "column"};
    .zaia-reaction-text {
      font-size: ${(p) => p.sizeText ? p.sizeText : "1.5em"};
      margin-top: 20px;
    }
    .zaia-reaction-subtext {
      margin-top: ${(p) => p.sizeBetweenText ? p.sizeBetweenText : "20px"};
      font-size: ${(p) => p.sizeSubText ? p.sizeSubText : "1em"};
      color: #37379B;
    }
  }
`

export const ZaiaLoadingPoints = styled.div`
  padding: 8px 18px;
  align-self: flex-end;
  .dot-flashing {
    position: relative;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${({color}) => color};
    color: ${({color}) => color};
    animation: ${dotFlashing()} 1s infinite linear alternate;
    animation-delay: 0.5s;
  }
  .dot-flashing::before, .dot-flashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-flashing::before {
    left: -10px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${({color}) => color};
    color: ${({color}) => color};
    animation: ${dotFlashing()} 1s infinite alternate;
    animation-delay: 0s;
  }
  .dot-flashing::after {
    left: 10px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: ${({color}) => color};
    color: ${({color}) => color};
    animation: ${dotFlashing()} 1s infinite alternate;
    animation-delay: 1s;
  }  
`

