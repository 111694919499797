import React, { useContext } from "react";
import * as S from "./ZaiaModal.style";
import { useTranslation } from "react-i18next";
import { ThemeContext } from 'styled-components'

// components
import ZaiaButton from "../zaiaButton";
import ZaiaReaction from "../zaiaReaction";

// Hooks 
import useActivateModal from "../../../hooks/useActivateModal";

const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaOk = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-success.svg`;

function ZaiaModal({ 
  errorModal, 
  text,
  okText,
  okAction,
  cancelText,
  cancelButton, 
  cancelAction 
}){
  const { t } = useTranslation("global");
  const {desactiveModal} = useActivateModal();
  const theme = useContext(ThemeContext);
  
  return (
    <S.ZaiaModal>
      <div className="modal-container">
        <div className="card-inputs">
          { 
            <ZaiaReaction
              text={errorModal ? (text || 'Error!') : (text || 'Success')}
              textColor={theme.primaryVariantColor}
              widthText="240px"
              widthImg="150px"
              separation="20px"
              zaiaImg={errorModal ? logoZaiaAlert : logoZaiaOk}
            />
          }
        </div>
        <div className="card-buttons">
          {
            <div>
              <div onClick={okAction ? okAction : () => desactiveModal()}>
                <ZaiaButton
                  option="primary"
                  marginTop="20"
                  backgroundColor= {errorModal ? theme.warning : null}
                  title={okText || (errorModal ? t("globally.tryAgain") : t("patients.ResponseBtnPrimary"))}
                  shadow={true}
                />
              </div>
                {
                  cancelButton ?
                  <div onClick={() => cancelAction()} className="cancel-button">
                    {cancelText ? cancelText : t("globally.cancel")}
                  </div> : null
                }
            </div>
          }
        </div>
      </div>
    </S.ZaiaModal>
  );
}

export default ZaiaModal;
