import styled from "styled-components";

export const SettingsChat = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  height: 100%;
  width: 100%;
  .settings__header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    box-sizing: border-box;
    &-icon {
      font-size: 15px;
      margin-bottom: -2px;
      margin-right: 15px;
      cursor: pointer;
    }
    h4 {
      font-family: "Nunito ExtraBold";
    }
  }  
  .settings__container {
    height: calc(100% - 60px);
    overflow: auto;
    padding-bottom: 20px;
    .image-container {
      display: flex;
      padding: 20px 0 40px;
      justify-content: center;
      .image-change {
        position: relative;
        .button-change-image {
          position: absolute;
          border-radius: 50%;
          background-color: var(--blue-dark-color);
          font-size: 15px;
          width: 40px;
          height: 40px;
          box-shadow: 0px 3px 10px #1f1f5559;
          bottom: -17px;
          right: -5px;
          color: var(--background-header-input);
          display: flex;
          cursor: pointer;
          font-size: 20px;
          i {
            margin: auto 9px 3px auto;
          }
        }
      }
    }
    .bottom-container {
      padding: 0 20px;
      .input-content {
        font-size: 13px;
        font-family: 'Open Sans';
        color: var(--blue-color);
      }
      .participats-info {
        display: flex;
        color: var(--blue-color);
        justify-content: space-between;
        font-family: "Nunito SemiBold";
        font-size: 15px;
        i {
          cursor: pointer;
        }
      }
    }
  }
  
`;

export const ChatPaticipants = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  width: 100%;
  height: 245px;
  margin: 10px 0 20px 0;
  box-sizing: border-box;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  .box-participant {
    display: flex;
    height: 40px;
    border-bottom: 1px solid var(--text-area-grey);
    padding: 5px 0;
    box-sizing: border-box;
    font-size: 13px;
    font-family: "Nunito Bold";
    .name-participant {
      align-self: center;
      margin-left: 8px;
    }
  }
`;