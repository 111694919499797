import styled from "styled-components";

export const ListNotes = styled.div`
  height: 100%;
  position: relative;
  .select-program {
    display: flex;
    flex-direction: ${({ maxViewActived }) => (maxViewActived ? "column" : "")};
    align-items: center;
    padding: 5px 20px 0px;
    align-items: ${({ maxViewActived }) => (maxViewActived ? "start" : "")};
    &__label {
      margin-bottom: ${({ maxViewActived }) => (maxViewActived ? "10px" : "")};
      font-family: Nunito;
      font-size: 17px;
      margin-right: 9px;
      white-space: nowrap;
    }
    &__select {
      width: 100%;
    }
  }
  .list-notes {
    height: ${({ maxViewActived }) => (maxViewActived ? "calc(100% - 161px)" : "calc(100% - 128px)")};    
    overflow: auto;
    padding: 32px 20px 0px;
    box-sizing: border-box;
    .note-card {
      cursor: pointer;  
      background-color: #FFFFFF;
      border-radius: 14px;
      box-shadow: 0px 0px 18.3px -6px #38379C4A;
      margin-bottom: 15px;
      padding: 10px 20px;
      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 4px;
      }
      &__date {
        display: flex;
        font-size: 13px;
        font-family: Nunito Sans;
        color: #878792;
        &-date {
          font-family: Nunito Sans Bold;
          margin-right: 5px;
        }
        &-time {
        }
      }
      &__option {
        display: flex;
        background-color: #F0F0FF;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        &-icon {
          color: #6C6BCC;
          font-size: 12px;
          margin: auto;
        }
      }
      &__program {
        color: #526479;
        font-family: Nunito Sans;
        font-size: 13px;
        background-color: #EEF1F4;
        padding: 0 5px;
        border-radius: 4px;
        width: fit-content;
        margin-bottom: 4px;
      }
      &__note {
        font-family: Nunito Sans;
        font-size: 16px;
        .expand-comment {
          color: #6C6BCC;
          font-family: Nunito Sans;
          font-size: 16px;
          text-decoration: underline;
          cursor: pointer;
          font-weight: 400;
        }
      } 
      &__written-by {
        font-family: Nunito Sans;
        font-size: 13px;
        display: flex;
        align-items: center;
        &-label {
          color: #878792;
          margin-right: 5px;
        }
        &-name {
          color: #3E3E5B;
        }
      }
    }
  }
  .add-note-container {
    padding: 16px 20px 26px;
    box-shadow: ${({ maxViewActived }) => (maxViewActived ? "" : "0px -1px 6.4px 0px #23229D40")};    
    background-color: ${({ maxViewActived }) => (maxViewActived ? "" : "0px -1px 6.4px 0px #23229D40")};
    .add-note {
      width: 100%;
      height: 46px;
      align-items: center;
      justify-content: center;
      display: flex;
      border-radius: 23px;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: #36369B;
      font-family: Nunito;
      font-size: 15.69px;
      color: white;
    }
  }
`


export const CardOptions = styled.div`
  width: 121px;
  padding: 10px;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 12px 0px #23229D33;
  .card-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &__icon {
      margin-right: 10px;
      color: #3E3DA3;
      font-size: 1opx;
    }
    &__text {
      font-family: Nunito Sans;
      font-size: 17px;
      color: #3E3DA3;
    }
  }
`