import React, { useState } from "react";
import * as S from  "./OutcomesTabs.style";
import Popup from "reactjs-popup";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";

function OutcomesTabs({
  tabs,
  setViewTab,
  viewTab
}) {
  const { recordAction } = useRecordNavigation();

  return(
    <S.OutcomesTabs>
      {
        tabs.map(
          (tab) => {
            return(
              <div 
                className={`tab-box ${tab.id === viewTab && "tab-box--selected"}`} 
                onClick={() => {
                  setViewTab(tab.id);
                  recordAction(tab.action);
                }}
                key={tab.id}
              >
                <i className={`tab-box__icon icon ${tab.icon} ${tab.id === viewTab && "tab-box__icon--selected"}`}></i>
                <div className="tab-box__name">{tab.name}</div>
                <Popup
                  trigger={
                    <i className="tab-box__info-icon icon zaia-alert"></i>
                  }
                  on={["hover", "focus"]}
                  closeOnDocumentClick
                  keepTooltipInside
                  position={["right center"]}
                  nested
                >
                  <S.TabDescription>
                    {/* <p>{P.dataTranslation(item.descript, language)}</p> */}
                    {tab.description}
                  </S.TabDescription>
                </Popup>
              </div>
            )
          }
        )
      }
    </S.OutcomesTabs>
  );
}

export default OutcomesTabs;