import styled from "styled-components";

export const AgendaFilterPanel = styled.div`
  width: 343.28px;
  height: 100%;
  .panel {
    position: absolute;
    padding: 40px 0 41px 30px; 
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    &__container {
      display: flex;
      height: auto;
      overflow: auto;
      box-shadow: ${({panelActive}) => panelActive ? "21px 0px 35px -7px rgba(35, 34, 157, 0.20)" : ""};
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 14px;
      height: 100%;
    }
    &__content{
      height: 100%;
      overflow: hidden;
    }
    .filter-patient {
      height: 47px;
      background-color: #E0E6ED;
      padding: 10px 20px;
      align-items: center;
      box-sizing: border-box;
      width: 313.28px;
      border-radius: 10px;
      display: flex;
      cursor: text;
      transition-duration: 0.5s; 
      &--focus {
        background-color: #36369B;
      }
      &__input {
        transition-duration: 0.5s;
        background-color: transparent;
        font-family: "Nunito Sans";
        border: none;
        outline: none;
        font-size: 20px;
        color: #59599E;
        width: 100%;
        &--focus {
          /* background-color: #36369B; */
          color: #EDEDF7;
          ::placeholder {
            color: #EDEDF7 !important;
            font-family: "Nunito Sans";
          }
        }
        ::placeholder {
          color: #59599E;
          font-family: "Nunito Sans";
        }
      }
      &__icon {
        transition-duration: 0.5s;
        color: #59599E !important;
        font-size: 20px;
        &--focus {
          color: #EDEDF7 !important;
        }
      }
    }
    .patient-selector {
      height: 57px;
      width: 313.28px;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      border-radius: 14px;
      /* border: 1px solid #C3C3E1; */
      background: linear-gradient(44deg, rgba(214, 214, 246, 0.23) 9.33%, rgba(79, 79, 255, 0.00) 235.78%);
      -webkit-box-shadow: -2px 2px 2px -1px #C3C3E1;
      -moz-box-shadow: -2px 2px 2px -1px #C3C3E1;
      box-shadow: -2px 2px 2px -1px #C3C3E1;
      cursor: pointer;
      &__box-icon {
        filter: drop-shadow(0px 0px 4.015px rgba(172, 172, 207, 0.45));
        border-radius: 50%;
        background-color: #FFF;
        padding: 3px;
        display: flex;
        margin-right: 10px;
        height: 26.721px;
        width: 26.721px;
      }
      &__icon {
        border-radius: 50%;
      }
      &__text {
        color: #1F1F52;
        text-align: center;
        font-family: "Nunito Sans Bold";
        font-size: 14px;
      }
    }
    .filter-patient-selected {
      background-color: #8A939D;
      margin: 10px 0;
      overflow: hidden;
      box-sizing: border-box;
      display: flex;
      border-radius: 10.12px;
      height: 57px;
      padding: 10px;
      align-items: center;
      width: 313.28px;
      overflow: hidden;
      /* background: linear-gradient(44deg,#D6D6F6 28.33%,rgba(79,79,255,0.00) 123.78%); */
      background: linear-gradient(44deg, #D6D6F6 59%, rgba(79, 79, 255, 0.00) 111.78%);
      &__img {
        width: 31.666px;
        height: 31.666px;
        border-radius: 31.666px;
        border: 3.29px solid #f1f1ff;
        margin-right: 10px;
      }
      &__text-container {
        height: 100%;
        overflow: hidden;
        margin-right: 10px;
        display: flex;
      }
      &__text {
        font-size: 20px;
        color: #1F1F52;
        font-family: "Nunito Sans";
        font-size: 14px;
        text-align: left;
        margin: auto 0;
      }
      &__close {
        display: flex;
        padding: 8.894px;
        align-items: flex-start;
        border-radius: 29.351px;
        background: #FCFCFE;
        cursor: pointer;
        font-size: 6.196px; 
        margin-left: auto;
      }
    }
    .all-filters {
      box-sizing: border-box;
      height: calc(100% - 57px);
      margin-top: 10px;
      width: 313.28px;
      background-color: #F8F8FF;
      border-radius: 14px;
      display: flex;
      flex-direction: column;
      &__header {
        display: flex;
        justify-content: space-between;
        padding: 24px 20px 14px 20px;
        align-items: center;
        .title {
          display: flex;
          color: #566377;
          &__text {
            font-family: "Nunito Sans Bold";
            font-size: 14px;
            margin-left: 10px;
          }
          &__icon {
            font-size: 17px;
          }
        }
        .clear-button {
          color: #36369B;
          font-family: "Nunito Sans";
          font-size: 15px;
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .button-container{
        margin: 20px 20px 0 20px;
        .create-event-button{
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #6C6BCC;
          color: #FFFFFF;
          padding: 19px 21px;
          border: none;
          outline: none;
          border-radius: 14px;
          width: 100%;
          &__text {
            font-family: "Nunito Sans";
            font-size: 20px;
          }
          &__icon {
            font-size: 20px;
          }

        }
      }
      .filters-container {
        height: 100%;
        padding: ${({overflowActive}) => overflowActive ? "5px 20px 20px 20px" : "5px 0px 20px 20px"};
        overflow-y: ${({overflowActive}) => overflowActive ? "hidden" : "scroll"};
        mask-image: linear-gradient(0deg,#000 97%,transparent);
        overflow-y: ${({overflowActive}) => overflowActive ? "hidden" : "scroll"};
        /* mask-image: linear-gradient(0deg,#000 97%,transparent); */
        ::-webkit-scrollbar {
          width: 25px;
        }
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 10px 10px transparent;
          margin: 5px 0px; 
        }
        ::-webkit-scrollbar-thumb {
          border: 10px solid rgba(0, 0, 0, 0);
          box-shadow: inset 0 0 10px 10px #AFAFD7;
          background-clip: padding-box;
          border-radius: 9999px;
          background-color: transparent;
        }
        .filter {
          display: flex;
          flex-direction: column;
          &--calendar-container {
            height: 315px;
          }
          &__comments-check {
            display: flex;
            cursor: pointer;
            background-color: #FFFFFF;
            color: #606C7A;
            padding: 9px 20px;
            font-size: 18px;
            border-radius: 5px;
            align-items: center;
            margin-bottom: 20px;
            &--selected {
              color: #36369B;
            }
            &-icon {
              margin-right: 10px;
              font-size: 14px;
            }
          }
          &__title {
            color: #59599E;
            font-family: "Nunito Sans Bold";
            font-size: 14px;
            margin-bottom: 10px;
          }
          &__tags-list {
            margin-top: 20px;
            .tag {
              padding: 5px;
              border-radius: 5px;
              background-color: #8A939D;
              color: #FFFFFF;
              display: flex;
              align-items: center;
              width: fit-content;
              &:not(:last-child) {
                margin-bottom: 10px;
              }
              &__name {
                font-size: 14px;
                font-family: "Nunito Sans Light";
                margin-right: 5px;
              }
              &__close{
                margin-left: auto;
                background-color: #FFFFFF;
                border-radius: 50%;
                width: 17.23px;
                height: 17.23px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                cursor: pointer;
                &-icon {
                  color: #8A939D;
                  font-size: 6px;
                }
              }
            }
          }
          &__title-collapsible {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #59599E;
            margin: 30px 10px 20px 10px;
            cursor: pointer;
            &-text {
              font-family: "Nunito Sans";
              font-size: 14px;
              &--expanded {
                font-family: "Nunito Sans Bold";
              }
            }
            &-icon {
              font-size: 14px;
              transform: rotate(180deg);
              &--expanded {
                transform: rotate(0deg);
              }
            }
          }
          &__content-collapsible {
            display: flex;
            width: 100%;
            &-items-list {
              display: flex;
              flex-direction: column;
              width: 100%;
              box-sizing: border-box;
              padding: 0 10px;
              .filter-item {
                box-sizing: border-box;
                width: 100%;
                margin: 0 0 10px 0;
                cursor: pointer;
                width: 100%;
                padding: 10px 13px;
                border-radius: 14px;
                align-items: center;
                display: flex;
                background-color: #FFFFFF;
                &--symptoms {
                  color: #36369B;
                }
                &__point {
                  width: 11px;
                  height: 11px;
                  margin-right: 5px;
                  border-radius: 50%;
                  &--selected{
                    background-color: #FFFFFF !important;
                  }
                }
                &__icon {
                  margin-right: 8px;
                  font-size: 15px;
                }
                &:hover {
                  /* background-color: #FCFCFE; */
                }
                &--selected{
                  color: #FFF !important;
                  background-color: #72C069;
                }
                &--selected-symptom {
                  color: #FFF !important;
                  background-color: #36369B;
                }
              }
            }
          }
          &__items-selected-list {
            .selected-item {
              margin-top: 10px;
              font-family: "Nunito Sans";
              padding: 5px;
              border-radius: 5px;
              align-items: center;
              color: #526479;
              width: fit-content;
            }
          }
        }
        .filter-separator {
          border-bottom: 1px solid #B1B1C8;
          width: 112px;
          margin: 20px auto;
          /* &--big {
            margin: 40px 0 20px 0;
          } */
        }
      }
    }
    &__right-filter-options {
      height: 100%;
      width: auto;
      box-sizing: border-box;
      position: relative;
      background-color: #FFFFFF;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
      display: ${({panelActive}) => panelActive ? "flex" : "none"};
    }
  }
`

export const Trigger = styled.button`
  border-radius: 14px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* color: ${({isActive}) => isActive ? "#FFFFFF" : "#8A939D"}; */
  align-items: flex-start;
  width: 100%;
  border: none;
  border-bottom: 1px solid #6C6BCC;
  position: relative;
  margin-top: 10px;
  .trigger {
    position: relative;
    box-sizing: border-box;
    padding: ${({dataSelected}) => dataSelected ? "10px 9px 36px 18px" : "10px 9px 10px 18px"};
    width: 100%;
    text-align: left;
  }
  .trigger-title{
    color: #6C6BCC;
    font-family: "Nunito Sans";
    font-size: 14px;
  }
  .trigger-data-selected {
    color: #566377;
    font-family: "Nunito Sans";
    font-size: 12px;
    font-style: normal;
  }
  .trigger-clear-button {
    position: absolute;
    right: 9px;
    bottom: 10px;
    color: #36369B;
    font-family: "Nunito Sans";
    font-size: 12px;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 10px;
  }
`;

