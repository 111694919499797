import React, { useState, useEffect } from "react";
import * as S from  "./PatientDashboardView.style";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { useLocation } from 'react-router-dom';
import { useStateValue } from "../../../../../contextAPI/StateProvider";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_BY_ID } from "../../../../../graphql/queries/User";

// Utils
import { Routes } from "../../../../../utils/constants.js";
import { Actions } from "../../../../../utils/actionsIds";

// Component
import AiNavigatorHealth from "../aiNavigatorHealth";
import PatientHeaderSections from "../patientHeaderSections";
import AiNavigatorOutcomes from "../aiNavigatorOutcomes";
import AiNavigatorSurveys from "../aiNavigatorSurveys/AiNavigatorSurveys.jsx";
import useAssignSelectPatient from "../../../../../hooks/useAssignSelectPatient.js";

function PatientDashboardView() {
  const { t }= useTranslation("global");

  const location = useLocation();  
  const { patientID } = useParams();
  const { AINAVIGATOR } = Routes;
  const { selectPatient } = useAssignSelectPatient();

  const [{ selectedPatient }] = useStateValue();

  const initialSections = [
    {
      id: 1,
      name: t("globally.health"),
      action: Actions.HEALTH.VIEW
    },
    {
      id: 2,
      name: t("globally.outcomes"),
      action: Actions.OUTCOMES.VIEW
    },
    {
      id: 3,
      name: t("globally.surveys"),
      action: Actions.SUVERYS_SECTIONS.VIEW
    }
  ]

  const [sections, setSections] = useState(initialSections);

  const [selectedSection, setSelectedSection] = useState(sections[0]);

  useEffect(() => {
    const searchLocation = location.search;
    const params = new URLSearchParams(searchLocation);
    if(Boolean(params.get("outcomes")) === true) {
      setSelectedSection(sections[1])
    } else if (Boolean(params.get("survey")) === true) {
      setSelectedSection(sections[2])
    }
  }, [])

  const { data: userData } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: { patientId: patientID },
  });

  useEffect(() => {
    selectPatient(userData?.user[0]?.id)
  }, [userData])


    return (
      <S.PatientDashboardView>
        <div className="header-patient-container">
          <PatientHeaderSections
            patientID={patientID}
            sections={sections}
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            patientDeselectRedirectionRoute={`${AINAVIGATOR.route}/`}
            selectPatientAction={Actions.ALERTS.AINAVIGATOR}
          />
        </div>
        <div className="ainavigator-content">
          <div className="ainavigator-content__box">
              {
                selectedSection.id === 1  &&
                <AiNavigatorHealth/>
              } 
              {
                selectedSection.id === 2 && selectedPatient &&
                <AiNavigatorOutcomes/>
              }
              {
                selectedSection.id === 3 && selectedPatient &&
                <AiNavigatorSurveys/>
              }
          </div>
        </div>
      </S.PatientDashboardView>
    )
  }
  
  export default PatientDashboardView