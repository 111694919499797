import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalShareAccessToPatient = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: var(--white);
    width: 900px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    &__reaction {
      padding: 50px 0;
    }
    .header {
      padding: 15px 40px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #0000002B;
      &__left {
        display: flex;
        align-items: center;
      }
      &__title {
        font-size: 32.25px;
        font-family: Nunito Sans Bold;
        font-size: 19.08px;
        color: #000000;
      }
      &__patient {
        display: flex;
        align-items: center;
        margin-top: 5px;
        &-name {
          margin-left: 5px;
        }
        &-process {
          margin-left: 5px;
          font-size: 14px;
          color: #3E3DA3;
        }
      }
      &__summary {
        font-family: Nunito Sans;
        font-size: 19.08px;
        font-weight: 400;
        margin-left: 5px;
      }
      &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        cursor: pointer;
        color: #000;
        background-color: #F8F8FF;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        &--back {
          margin-right: 10px;
        }
      }
    }
    .modal-content {
      padding: 28px 40px 0 40px;
      height: 514px;
      .users-list {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
        overflow-y: scroll;
        width: calc(100% + 23px);
        height: calc(514px - 56px);
        /* height: 430px; */
        align-items: flex-start;
        align-content: flex-start;
        justify-content: center;
        ::-webkit-scrollbar {
          width: 25px;
        }
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 10px 10px transparent;
          /* margin: 1px 0px;  */
        }
        ::-webkit-scrollbar-thumb {
          border: 10px solid rgba(0, 0, 0, 0);
          box-shadow: inset 0 0 10px 10px #3E3DA366;
          background-clip: padding-box;
          border-radius: 9999px;
          background-color: transparent;
        }
        &__responses {
          height: 100%;
          text-align: center;
          margin: auto;
          padding-left: 28px;
          display: flex;
        }
        &--summary {
          height: calc(514px - 284px);
        }
      }
      .patient-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 76px auto 40px;
        &__name {
          font-family: Nunito Sans;
          font-size: 26px;
          margin-top: 10px;
          color: #1F1F52;
          
        }
        &__email {
          font-family: Nunito Sans;
          font-size: 14px;
          color: #3E3E5B;
        }
        &__process {
          font-family: Nunito Sans;
          font-size: 14px;
          color: #3E3DA3;
        }
      }
    }  
    .footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 40px;
      border-top: 1px solid #0000002B;
      box-sizing: border-box;
      align-items: center;
      .button-container {
        min-width: 220px;
        &__text {
          font-family: Nunito Sans;
          font-size: 15px;
        }
      }
    }
  }
`

export const UserCard = styled.div`
  width: calc(50% - 45px);
  display: flex;
  padding: 8.5px 20px;
  border-radius: 12px;
  margin-bottom: 20px;
  align-items: center;
  color: ${({exist}) => exist ? `#3E3E5B` : `#1F1F52`};
  background-color: ${({exist}) => exist ? `#D2D2FF` : `#FCFCFE`};
  cursor: pointer;
  :nth-child(odd) {
    margin-right: 10px;
  }
  :nth-child(odd):last-child {
    margin-left: 10px;
  }
  .icon {
    margin-right: 10px;
    color: #3E3DA3;
    color: ${({exist}) => exist ? `#3E3DA3` : `#BBBBBB`};
  }
  .info {
    margin-left: 10px;
  }
  .name {
    font-size: 17px;
    font-family: ${({exist}) => exist ? `Nunito Sans Bold` : `Nunito Sans`};
  }
  .program {
    font-family: "Nunito Sans";
    font-size: 12px;
    color: ${({exist}) => exist ? `#3E3E5B` : `#8A939D`};
  }
`;