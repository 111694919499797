import styled from "styled-components";

export const CmpRadioListInfo = styled.div`
  .title {
    font-family: "Nunito Sans";
  }
  .list-scores-container {
    margin: 10px 0 18px;
    .score-item {
      padding: 9px 0;
      border-bottom: 1px solid var(--grey-items-separaton);
    }
  }
  .grade-group-text {
    font-size: 17px;
    font-family: "Nunito Sans";
    color: var(--blue-color);
    margin: 20px 0;
    text-align: center;
  } 
`;

