import styled from "styled-components";

export const PatientSelection = styled.div`
  height: calc(100% - 40px);
  margin-top: 40px;
  box-sizing: border-box;
  background-color: white;
  padding-top: 40px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  .button-patient-selector {
    background-color: #D6D6F63B;
    height: 58px;
    width: calc(100% - 20px);
    margin: 0 10px 0;
    padding: 5px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    outline: none;
    border: none;
    &__icon-container {
      width: 37.94px;
      height: 37.94px;
      border-radius: 50%;
      background-color: #FFFFFF;
      display: flex;
      box-shadow: 0px 0px 23.59px -1.81px #23229D40;
      margin-right: 10px;
    }
    &__icon {
      font-size: 31.61px; 
      margin: auto;
      color: #6C6BCC;
    }
    &__text {
      font-family: Nunito Sans;
      font-size: 20px;
      color: #1F1F52;
    }
  }
  .message-space {
    height: calc(100% - 146px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__img {
      width: 400px;
    }
    &__text {
      color: #7776BE;
      font-family: Nunito Sans;
      font-size: 20px;
      top: -70px;
      position: relative;
    }
  }
  .space-add-note {
    padding: 16px 0 26px;
    display: flex;
    justify-content: center;
    background-color: #D6D6F63B;
    .button-add-note {
      width: 462px;
      height: 46px;
      padding: 7px 0;
      border-radius: 23px;
      background-color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      &__text {
        font-family: Nunito Sans;
        font-size: 20px;
        color: #707582;
      }
      &__icon {
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }
`
