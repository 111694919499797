import React from "react";
import * as S from "./HealthIndicatorSelector.style";
import { useTranslation } from "react-i18next";
import * as P from "../../../../../../../../utils/pipes";
import { useEffect } from "react";

function HealthIndicatorSelector({
  selectedHealthIndicator,
  changeFormField,
  healthIndicatorList,
  goNextInput
}) {

  const {t, i18n: { language }} = useTranslation("global");

  return (
    <S.HealthIndicatorSelector>
      <div className="title">{t("healthAddData.healthIndicators.selectHealthIndicator")}</div>
      <div className="health-indicators-list">
        <div className="health-indicators-list__container">
          {
            healthIndicatorList?.map((healthIndicator) => {
              return (
                <div 
                  key={healthIndicator?.value}
                  className={`health-indicators-name ${healthIndicator?.value === selectedHealthIndicator.value && "health-indicators-name--selected"}`}
                  onClick={() => {
                    changeFormField([
                      {
                        value: healthIndicator.value, 
                        text: P.dataTranslation(healthIndicator.comment, language), 
                        fieldName: "healthIndicator",
                      },
                      {
                        value: null, 
                        text: "", 
                        fieldName: "measureType"
                      },
                      {
                        value: null, 
                        text: "", 
                        fieldName: "measure"
                      }
                    ]);
                    goNextInput();
                  }}                                               
                >{P.dataTranslation(healthIndicator.comment, language)}</div>
              )
            })
          }
        </div>
      </div>
    </S.HealthIndicatorSelector>
  );
}

export default HealthIndicatorSelector;
