import React from "react";
import * as S from "./CmpTextInput.style";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";
import { PopUpPayload } from "../genericTemplate/GenericTemplate.style";

// pipes
import * as P from "../../../../../../../utils/pipes";

// Components
import SelectorForm from "../selectorForm";

function CmpTextInput({
  item,
  insertfunction,
  customFormChangeItem,
  typeView
}) {  
  const { i18n: { language } } = useTranslation("global");

  const changeOptionItem = (value) => {
    const userToCustomFormField = {
      customFormFieldParentId: item?.customFormFieldParentId,
      customFormFieldId: item.id,
      value: value.toString(),
      key: value.toString()
    }
    insertfunction(userToCustomFormField);
  }

  return (
    <S.CmpTextInput>
      <div className="selection-container">
        <div className="title">
          {item.fieldName && P.dataTranslation(item.fieldName, language)}
          <Popup
            trigger={
              <i className="template-header-item-icon icon zaia-alert"></i>
            }
            on={['hover', 'focus']}
            closeOnDocumentClick
            keepTooltipInside
            position={['right center']}
            nested
          >
            <PopUpPayload>
              <p>
              {P.dataTranslation(item.descript, language)}
              </p>
            </PopUpPayload>
          </Popup>
        </div>
        {
          <SelectorForm 
            boxShadow={false}
            type='text'
            dataInput={customFormChangeItem && customFormChangeItem.value?.toString()}
            handleSubmit={changeOptionItem}
            disabled={typeView === 'view'}
          /> 
        }
      </div>
    </S.CmpTextInput>
  );
}

export default CmpTextInput;
