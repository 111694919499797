import styled from "styled-components";
const waveLeft = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-grande-top-inicio.svg`;

export const CoverPage = styled.div`
  display: flex;
  height: ${({webView}) => webView ? '100vh' : '100svh'};
  flex: 0.5;
  background: url(${waveLeft});
  background-repeat: no-repeat;
  background-color: ${({theme}) => theme.primaryVariantColor};
  background-position-y: -50px;
  background-size: 100%;
  display: flex;
  .zaia-image {
    margin: auto;
    width: 280px;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
