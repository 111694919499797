import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalOutcomesEventHistoryEditing.style"
import * as P from "../../../../../utils/pipes";
import Popup from "reactjs-popup";
import { QUERY_GET_USER_TO_PROCESS } from "../../../../../graphql/queries/UserToProcess";
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_GET_CUSTOM_FORMS_BY_IDS } from "../../../../../graphql/queries/CustomForm";
import GenericTemplate from "../../../outcomes/components/outcomesComponents/shared/genericTemplate";
import { QUERY_GET_PROCESS_BY_ID } from "../../../../../graphql/queries/Process";
import GenericTemplateHistoryEdition from "../../../outcomes/components/outcomesComponents/shared/genericTemplateHistoryEdition";
import useDoctorData from "../../../../../hooks/useDoctorData";
import { MUTATION_INSERT_UPDATE_HISTORY_CUSTOM_FORMS_RECORDS } from "../../../../../graphql/mutations/UserToCustomForm";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";
import Modal from "../../../../../utils/modal";
import useUploadDoc from "../../../../../hooks/useUploadDoc";
const UPLOAD_DOCUMENT_ENDPOINT = process.env.REACT_APP_PATIENT_GUIDE_MEDPALM_ENDPOINT;
const BUCKET_OUTCOMES_FORMS = process.env.REACT_APP_BUCKET_FORM;

function ModalOutcomesEventHistory({ 
  patientID,
  processID,
  setActiveConfirmationModal,
  itemsActived,
  setFormSuccess,
  setFormLoading
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const [customFormChangesList, setCustomFormChangesList] = useState([]);
  const [customFormInitialList, setCustomFormInitialList] = useState([]);
  const {uploadDoc, data, loading, error} = useUploadDoc();
  const { doctorData } = useDoctorData();
  const [insertUpdateUserToCustomFormFields, {loading: insertUpdateUserToCustomFormFieldsLoading}] = useMutation(
    MUTATION_INSERT_UPDATE_HISTORY_CUSTOM_FORMS_RECORDS
  );

  const insertFormField = async (e) => {    
    let tempList = customFormChangesList.filter(
      (item) =>
        item.customFormFieldId !== e.customFormFieldId &&
        (item.customFormFieldParentId === e.customFormFieldId
          ? item.parentKey === e.key
          : true)
    );
    setCustomFormChangesList([...tempList, e]);
  };  

  const handleSubmitEditing = async () => {    
    
    setFormLoading(true);

    if (customFormChangesList?.find(item => item.documentToSave)) {
      for (const item of customFormChangesList.filter(item => item.documentToSave)) {
        let { data: { uploaded_file_data } } = await uploadDoc(item.documentToSave, UPLOAD_DOCUMENT_ENDPOINT, BUCKET_OUTCOMES_FORMS);
        item.value = item.documentToSave.name;
        item.key = uploaded_file_data[0].file_url;
      }
    }

    const objectCustomFormChanges = customFormChangesList?.filter(item => (item.key && item.value)).map((item) => { 
      
      const record = {
        updated_at: new Date(Date.now()),
        created_at: itemsActived[0][0].created_at,
        value: item.value,
        key: item.key,
        doctorId: doctorData.id,
        userId: patientID,
        customFormFieldId: item.customFormFieldId
      }        

      if(item.recordId) {
        record.id = item.recordId;
      }

      return record;
    });
  
    const changesString = customFormChangesList?.filter(item => (item.key && item.value)).map((item) => {
      if (item.value instanceof Date)
        return P.shortDate(item.value, t, true, language);
      else return item.value;
    });   
      
    const objectCustomFormChangesFiltered = []; 
      
    objectCustomFormChanges?.forEach((item) => {   
      
      
      if(item.id) {
        const currentItem = customFormInitialList.find((element) => element.customFormFieldId === item.customFormFieldId);
        if(item.key !== currentItem.key || item.value !== currentItem.value) {
          objectCustomFormChangesFiltered.push(item);
        }
      } else {
        objectCustomFormChangesFiltered.push(item);
      }
    });

    if(objectCustomFormChangesFiltered.length > 0) {
      insertUpdateUserToCustomFormFieldsFunction(
        changesString,
        objectCustomFormChangesFiltered
      );
    } else {
      setFormSuccess(true);
    }
  };

  const insertUpdateUserToCustomFormFieldsFunction = async (
    changesString,
    objectCustomFormChanges
  ) => {    
    try {
      await insertUpdateUserToCustomFormFields({
        variables: {
          userToCustomFormFieldList: objectCustomFormChanges,
        },
      });
      setFormLoading(false);
      setFormSuccess(true);
    } catch (err) {
      setFormLoading(false);
      setFormSuccess(false);
      console.log("Error updating user to process", err); 
    }
  }

  function getFormattedValue(item) {
    const userField = item;
    let formattedValue = "–";
    if (userField) {
      formattedValue =
        item?.customFormField.typeFieldValue === "dateInput"
          ? P.formatNumberDate(userField.value, true, language)
          : userField.value;
    }
    return formattedValue;
  }

  const {data: process} = useQuery(
    QUERY_GET_PROCESS_BY_ID,
    {
      variables: {
        processId: processID,
      },
    }
  );  

  const {
    data: userToProcessData,
    loading: userToProcessDataLoading,
    error: userToProcessDataError,
  } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientID, processId: processID },
  });

  const {
    data: customForm,
    loading: customFormLoading,
    error: customFormError,
  } = useQuery(QUERY_GET_CUSTOM_FORMS_BY_IDS, {
    variables: {
      userId: patientID,
      customFormId: process?.process[0].processToCustomForms[0]?.customFormId,
      userToProcessId: userToProcessData?.userToProcess[0]?.id,
    },
  });

  useEffect(() => {
    const customFormFieldsInitTemp = customForm?.customForm_by_pk.formGroups?.find(
      (cmp) => cmp.id === itemsActived[0][0].customFormField.customForm?.id
    )?.customFormFields.map(
      (item) => {
        const parentKey = item?.customFormTypeField?.fieldOptions.find(
          (field) => field.key === item?.userToCustomFormFields[0]?.key
        )?.parentKey;
        let value;
        let key;
        let recordId;
        if(itemsActived.find((element) => element[0].customFormField.id === item.id)) {
          if(item?.customFormTypeField?.value === "dateInput") {            
            value = new Date(itemsActived.find((element) => element[0].customFormField.id === item.id)[0]?.value);
            key = new Date(itemsActived.find((element) => element[0].customFormField.id === item.id)[0]?.key);
          } else {
            value = itemsActived.find((element) => element[0].customFormField.id === item.id)[0]?.value;
            key = itemsActived.find((element) => element[0].customFormField.id === item.id)[0]?.key;
          }
          recordId = itemsActived.find((element) => element[0].customFormField.id === item.id)[0]?.id;
        }

        return {
          customFormFieldId: item.id,
          parentKey: parentKey ? parentKey : null,
          customFormFieldParentId: item?.customFormFieldParentId,
          value: value,
          key: key,
          recordId: recordId
        };
      }
    );    
    
    setCustomFormChangesList(customFormFieldsInitTemp);
    setCustomFormInitialList(customFormFieldsInitTemp);
  }, [customForm, itemsActived]);

  
  const renderCustomFormGroupsindex1 = (item, language) => {    

    return (
      <div 
        className="detail"
        key={item.id}
      >
        <div className="detail__label">
          {P.dataTranslation(item.customFormField.fieldName, language)}
          {item.customFormField.descript && (
            <Popup
              trigger={
                <i className="detail__label-icon icon zaia-alert"></i>
              }
              on={["hover", "focus"]}
              closeOnDocumentClick
              keepTooltipInside
              position={["right center"]}
              nested
            >
              <S.PopUpPayload>
                <p>
                  {P.dataTranslation(item.descript, language)}
                </p>
              </S.PopUpPayload>
            </Popup>
          )}
        </div>
        <div className="detail__value">
          {
            item?.customFormField?.typeFieldValue === "fileInput"
            ? <div className="file-value">
              <i className="file-value__icon icon zaia-i-doc-normal"></i>
              <a className="file-value__text"  target="_blank" rel="noopener noreferrer" href={item.key} download={getFormattedValue(item)}>{getFormattedValue(item)}</a>
            </div>
            : getFormattedValue(item)
          }
          {item?.payload && (
            <Popup
              trigger={
                <i className="detail__value-icon icon zaia-alert"></i>
              }
              on={["hover", "focus"]}
              closeOnDocumentClick
              keepTooltipInside
              position={["right"]}
              nested
            >
              <S.PopUpPayload>
                <p>{item.payload}</p>
              </S.PopUpPayload>
            </Popup>
          )}
        </div>
      </div>
    );
  }    

  return (
    <S.ModalOutcomesEventHistoryEditing>
      <div className="modal-content">
        <div className="history-data">
          <div className="date">
            {itemsActived[0][2]}
          </div>
          <div className="time">
            {itemsActived[0][3]}
          </div>
          {
            itemsActived[0][1] && 
            <div className="doctor">
              <div className="doctor__label">{t("aiNavigator.outcomes.doctorWhoEdited")}</div>
              <div className="doctor__name">{`${itemsActived[0][1]}`}</div>
            </div>
          }
        </div>
        <div className="fields-box">
          <div className="fields-box__title">
            <i className="fields-box__title-icon icon fields-box__title-icon--actual zaia-i-routine-activity"></i>
            <div className="fields-box__title-text">{t("aiNavigator.outcomes.currentRecord")}</div>
          </div>
          <div className="fields-box__fields">
            {
              itemsActived.map((item) => {
                return (
                  renderCustomFormGroupsindex1(item[0], language)
                )
              })
            }
          </div>
        </div>
        <i className="separation-icon icon zaia-i-next-1"></i>
        <div className="fields-box">
          <div className="fields-box__title">
            <i className="fields-box__title-icon icon fields-box__title-icon--new zaia-i-new-record"></i>
            <div className="fields-box__title-text">{t("aiNavigator.outcomes.newRecord")}</div>
          </div>
          <div className="fields-box__fields fields-box__fields--new">
            {/* <div className="custom-form-inputs"> */}
              <GenericTemplateHistoryEdition
                title={P.dataTranslation(
                  itemsActived[0][0].customFormField.customForm?.customFormName,
                  language
                )}
                dataForm={customForm?.customForm_by_pk.formGroups?.find(
                  (cmp) => cmp.id === itemsActived[0][0].customFormField.customForm?.id
                )}
                insertfunction={insertFormField}
                customFormChangesList={customFormChangesList}
              />
            {/* </div>   */}
          </div>
        </div>
      </div>
      <div className="footer">
        <button className="footer__button footer__button--cancel"
          onClick={() => setActiveConfirmationModal(true)}
        >
          <div className="footer__button-text">{t("globally.cancel")}</div>
          <i className="footer__button-icon icon zaia-icono-guardar"></i>
        </button>
        <button className="footer__button footer__button--save"
          onClick={() => handleSubmitEditing()}
        >
          <div className="footer__button-text">{t("globally.save")}</div>
          <i className="footer__button-icon icon zaia-icono-guardar"></i>
        </button>
      </div>
    </S.ModalOutcomesEventHistoryEditing>
  );
}

export default ModalOutcomesEventHistory;
