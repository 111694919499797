import styled from 'styled-components';

export const PatientData = styled.div`
  height: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 22px 22px;
    &__center {
      font-family: Nunito Sans;
      font-size: 18px;
      color: #1F1F52;
    }
    &__left {
      width: 40px;
    }
    &__right {
      .close-button {
        display: flex;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        outline: none;
        cursor: pointer;
        border: none;
        background-color: transparent;
        &__icon {
          font-size: 13px;
          margin: auto;
          color: #3E3DA3;
        }
      }
      width: 40px;
    }
  }
  .patient-info-loading {
    height: calc(100% - 84px);
    display: flex;
  }
  .patient-info {
    height: calc(100% - 84px);
    overflow: auto;
    ::-webkit-scrollbar {
      display: none;
    }
    background-color: #F6F6FF;
    &__pricipal {
      padding: 34px 19px;
    }
    &__top {
      display: flex;
      align-items: center;
      .patient-image {
        width: 73px;
        height: 73px;
        border-radius: 50%;
      }
      .text-info {
        margin-left: 5px;
        .patient-name {
          font-family: Nunito Sans Bold;
          font-size: 35px;
          color: #1F1F52;
        }
        .patient-data {
          display: flex;
          margin-top: 10px;
          font-size: 19px;
          align-items: center;
          &__separator {
            height: 9px;
            border-right: 1px #7D7C8B solid;
            margin: 0 5px;
          }
          .patient-gender {
            display: flex;
            align-items: center;
            &__icon {
              font-size: 11px;
              margin-right: 7.5px;
              color: #3E3E5B;
            }
          }
          .patient-attitude {
            background-color: #FFD8D8;
            padding: 0 5px;
            border-radius: 40px;
            font-family: Nunito Sans Bold;
            margin-left: 10px;
          }
        }
      }
    }
    .patient-description {
      font-family: Nunito Sans;
      font-size: 19px;
      font-style: italic;
      color: #707582;
      margin-left: 78px;
    }
    &__buttons {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      min-height: 25px;
      .button-action {
        align-items: center;
        height: 36px;
        /* padding: 10px 20px 10px 20px; */
        border-radius: 15px;
        border: 1px #3E3DA3 solid;
        background-color: transparent;
        font-family: Nunito sans; 
        outline: none;
        display: flex;
        color: #3E3DA3;
        min-inline-size: max-content;
        width: 100%;
        font-size: 19px;
        justify-content: center;
        &:not(:last-child) {
          margin-right: 10px;
        }
        &__icon {
          font-size: 16px;
          margin-right: 5px;
        }
      }
    }
    &__reports {
      border-top-left-radius: 40px;
      border-top-right-radius: 40px;
      background-color: #FFFFFF;
      margin-top: 6px;
      padding: 30px 20px 30px;
      .reports-title {
        font-family: Nunito Sans;
        font-size: 35px;
        color: #1F1F52;
      }
      .reports-list {
        margin-top: 20px;
        .report-box {
          background-color: #ECF6FF;
          display: flex;
          width: 100%;
          padding: 15px;
          border-radius: 30px;
          box-sizing: border-box;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;
          &__left {
            display: flex;
            align-items: center;
          }
          &__right {
            
          }
          &__icon {
            font-size: 40px;
            color: #7FABFF;
            margin-right: 13px;
          }
          &__text {
            color: #707582;
            margin-right: 20px;
          }
          &__title {
            font-family: Nunito Sans Bold;
            font-size: 20px;
          }
          &__number-reports {
            font-family: Nunito Sans;
            font-size: 16px;
          }
          &__redirect-button {
            width: 40px;
            height: 40px;
            /* padding: 13px; */
            border-radius: 50%;
            background-color: #FFFFFF;
            color: #36369B;
            border: none;
            outline: none;
            display: flex;
          }
          &__redirect-icon {
            margin: auto;
            font-size: 16px;
          }
        }
      }
    }
  }
`