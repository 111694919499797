import React, { useState } from "react";
import * as S from "./ProfileForm.style";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

// GraphQL
import { QUERY_GET_COUNTRIES } from "../../../../../graphql/queries/Country";
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GET_DOCUMENT_TYPES } from "../../../../../graphql/queries/DocumentType";
import { QUERY_GET_MEDICAL_FIELDS } from "../../../../../graphql/queries/MedicalFields";
import { QUERY_GET_COMPLETE_DOCTOR_DATA } from "../../../../../graphql/queries/Doctor";
import { MUTATION_EDIT_DOCTOR, MUTATION_UPDATE_DOCTOR_DATA, MUTATION_INSERT_DOCTOR_DATA, MUTATION_UPDATE_DOCTOR_IMG} from "../../../../../graphql/mutations/Doctor";

// components
import ProfileHeader from "../ProfileHeader/ProfileHeader";
import ZaiaModal from "../../../../zaiaComponents/zaiaModal";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";
import ZaiaSelectForm from "../../../../zaiaComponents/zaiaSelectForm";
import ChangePassModal from "../changePassModal";

// pipes
import * as P from "../../../../../utils/pipes";
import useActivateModal from "../../../../../hooks/useActivateModal";
import Modal from "../../../../../utils/modal";
import useInfoToken from "../../../../../hooks/useInfoToken";

// assets
const zaiaError = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const zaiaLoad = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function ProfileForm() {
  const {t, i18n: { language } } = useTranslation("global");
  const [doctorData, setdoctorData] = useState();
  const [imageData, setImageData] = useState();
  const [resetImage] = useState(true);
  const {activeModal, desactiveModal} = useActivateModal();
  const [changePassActive, setChangePassActive] = useState(false);
  
  const [activeProfileEdition, setActiveProfileEdition] = useState(false);
  const { userId } = useInfoToken();

  const { 
    data: doctorDataQuery, 
    loading: doctorLoadingQuery, 
    error: doctorErrorQuery, 
    refetch 
  } = useQuery(
    QUERY_GET_COMPLETE_DOCTOR_DATA, 
    { 
      variables: { 
        doctorId: userId
      }, 
      onCompleted: (data) => {
        setdoctorData(data.doctor[0]);
      }
    },
  );

  const [editInfo, { loading: editInfoLoading }] = useMutation(MUTATION_EDIT_DOCTOR);
  const [changeDoctorImg, { loading: changeDoctorImgLoading, error }] = useMutation(MUTATION_UPDATE_DOCTOR_IMG);
  const [editDoctorData] = useMutation(MUTATION_UPDATE_DOCTOR_DATA);
  const [insertDoctorData] = useMutation(MUTATION_INSERT_DOCTOR_DATA);
  const { data: documentTypeData } = useQuery(QUERY_GET_DOCUMENT_TYPES);
  const { data: countriesData } = useQuery(QUERY_GET_COUNTRIES);
  const { data: medicalFieldsData } = useQuery(QUERY_GET_MEDICAL_FIELDS);

  const handleSubmit = async (values, { resetForm }) => {
    let submitPhoto = { load: false, success: true }
    let fileImg
    if (imageData && doctorDataQuery?.doctor[0]?.picture !== imageData) {
      submitPhoto.load = true;
      try {
        fileImg = await changeDoctorImg({
          variables: {
            pictureInBase64: imageData.split('base64,')[1]
          },
        });
      } catch (err) {
        submitPhoto.success = false;
      }
    } else {
      submitPhoto.load = false;
    }
    
    let modifydoctorData;
    
    const modifyInfo = getModifyInfo(values, fileImg);

    const { doctorData } = doctorDataQuery?.doctor[0];

    // doctor data
    if (values.primarySpeciality && (values.primarySpeciality !== doctorData[0]?.medicalField)) {
      modifydoctorData = { 
        medicalField: values.primarySpeciality 
      };
      if (!doctorData[0]) {
        modifydoctorData.universityName = "";
      }
    }
    
    changeInfoDoctor(modifyInfo, modifydoctorData, submitPhoto, resetForm);
  }

  const getModifyInfo = (values, fileImg) => {

    const modifyInfo = {};

    const {
      phone,
      documentNumber,
      name,
      lastname,
      birthdate,
      countryPhoneId,
      documentType,
      medicalCode
    } = doctorDataQuery?.doctor[0];

    const phoneNumber = Number(values.phoneNumber);
    const doctorPhoneNumber = Number(phone);
    const documentNumberValue = Number(values.document);
    const doctorDocumentNumber = Number(documentNumber);

    if (imageData && fileImg) modifyInfo.picture = fileImg.data.uploadDoctorPicture.pictureUrl.toString();
    if (values.name !== name) modifyInfo.name = values.name;
    if (values.lastname !== lastname) modifyInfo.lastname = values.lastname;
    if (values.birthdate !== birthdate) modifyInfo.birthdate = values.birthdate;
    if (values.phoneLocation !== countryPhoneId) modifyInfo.countryPhoneId = values.phoneLocation;
    if (phoneNumber !== doctorPhoneNumber) modifyInfo.phone = phoneNumber.toString();
    if (values.typeDocument !== documentType) modifyInfo.documentType = values.typeDocument;
    if (documentNumberValue !== doctorDocumentNumber) modifyInfo.documentNumber = documentNumberValue.toString();
    if (values.medicalCode !== medicalCode) modifyInfo.medicalCode = values.medicalCode;

    return modifyInfo;
  }

  const changeInfoDoctor = async (modifyInfo, modifydoctorData, submitPhoto, resetForm) => {
    try {
      await editInfo({
        variables: {
          changes: modifyInfo,
          doctorId: doctorDataQuery?.doctor[0].id
        },
      });

      if(modifydoctorData) {
        if(doctorDataQuery?.doctor[0].doctorData[0]) {
          await editDoctorData({
            variables: {
              idDoctorData: doctorDataQuery?.doctor[0].doctorData[0].id,
              updateDoctorData: modifydoctorData
            },
          });
        } else {
          await insertDoctorData({
            variables: {
              doctorData: modifydoctorData
            },
          });
        }
      }

      refetch();
      triggerModal(submitPhoto, true);
      // resetForm()
    } catch (err) {
      triggerModal(submitPhoto, false);
      resetForm()
      console.log(err);
    }
  }

  const triggerModal = (submitPhoto, submitInfo) => {
    if (submitInfo) {
      if(submitPhoto.load) {
        if (submitPhoto.success) {
          callModal(false, t("profile.modalMessages.successWithPhoto"))  // update success with photo
        } else {
          callModal(true, t("profile.modalMessages.successInfoNoPhoto"))  // update success without photo
        }
      } else {
        callModal(false, t("profile.modalMessages.success"))  // update success
      }
    } else {
      callModal(true, t("profile.modalMessages.errorInfo"))  // update error
    }
  }

  const callModal = (error, text) => {
    activeModal(() => <ZaiaModal errorModal={error} text={text}/>)
  }

  // const changePassModal = () => {
  //   return (
  //     <>  
  //       <ChangePassForm doctorEmail={doctorDataQuery?.doctor[0].email}/>
  //       <div onClick={() => desactiveModal()}>
  //         <InvitePatientButton activate={true} />
  //       </div>
  //     </>
  //   )
  // }

  if (doctorLoadingQuery) 
    return (
      <S.ProfileFormDiv>
        <ZaiaReaction
          widthImg="150px"
          zaiaImg={zaiaLoad}
        />  
      </S.ProfileFormDiv>
    );

  if (doctorErrorQuery) 
    return (
      <S.ProfileFormDiv>
        <ZaiaReaction
          zaiaImg={zaiaError}
          widthImg="125px"
          text={t("profile.errorInfo")}
          sizeText="17px"
          widthText="240px"
        />
      </S.ProfileFormDiv>
    );

  return (
    <S.ProfileFormDiv>
      <Modal open={changePassActive}>
        <ChangePassModal
          setModalActive={setChangePassActive}
          doctorEmail={doctorDataQuery?.doctor[0].email}
        />
      </Modal>
      <Formik
        initialValues={{
          email: doctorData?.email ? doctorData?.email : '',
          name: doctorData?.name ? doctorData?.name : '',
          lastname: doctorData?.lastname ? doctorData.lastname : '',
          birthdate: doctorData?.birthdate ? doctorData?.birthdate : '',
          phoneLocation: doctorData?.countryPhoneId ? doctorData?.countryPhoneId : '',
          phoneNumber: doctorData?.phone ? doctorData?.phone : '',
          typeDocument: doctorData?.documentType ? doctorData?.documentType : '',
          document: doctorData?.documentNumber ? doctorData?.documentNumber : '',
          medicalCode: doctorData?.medicalCode ? doctorData?.medicalCode : '',
          primarySpeciality: doctorData?.doctorData[0]?.medicalField ? doctorData?.doctorData[0]?.medicalField : '' 
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          email: Yup.string()
            .email(t("register.validation.email"))
            .required(t("register.validation.required")),
          name: Yup.string()
            .min(3, t("register.validation.min"))
            .max(15, t("register.validation.max"))
            .required(t("register.validation.required")),
          lastname: Yup.string()
            .min(3, t("register.validation.min"))
            .max(15, t("register.validation.max"))
            .required(t("register.validation.required")),
          birthdate: Yup.string()
            .required(t("register.validation.required")),
          phoneLocation: Yup.string()
            .required(t("register.validation.required")),
          phoneNumber: Yup.string()
            .required(t("register.validation.required")),
          typeDocument: Yup.string()
            .required(t("register.validation.required")),
          document: Yup.string()
            .required(t("register.validation.required")),
          medicalCode: Yup.string()
            .required(t("register.validation.required")),
          // primarySpeciality: Yup.string()
          //   .required(t("register.validation.required")),
          // secundarySpeciality: Yup.string()
          //   .required(t("register.validation.required")),
        })}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <>
            <form className="profile-form" onSubmit={ props.handleSubmit }>
              <div className="profile-left">
                <ProfileHeader 
                  setImageData={setImageData} 
                  resetImage={resetImage} 
                  doctorData={doctorData} 
                  documentTypeData={documentTypeData}
                  activeProfileEdition={activeProfileEdition}
                  setActiveProfileEdition={setActiveProfileEdition}
                />
              </div>
              <div className="profile-right">
                <div className="form-container">
                  <div className="inputs-container">
                    <div className="inputs-title">{t("profile.userInfo")}</div>
                    <div className="inputs-flex">
                      <div className="input-content">
                        <span>{t("globally.email")}</span>
                        <ZaiaInputForm 
                          placeholder={t("globally.email")}
                          type="text"
                          name="email"
                          borderRadius={"14px"}
                          fontSize={"20px"}
                          disabled={true}
                          padding={"22px 24px 17px 27px"}
                          textColor={"#1F1F52"}
                          borderColor={"#6C6BCC"}
                          disabledColor={"#EDEDFF"}
                          onlyBottomBorder
                          disabledTextColor={"#53536B"}
                          disabledIconColor={"#878792"}
                        />
                      </div>
                      <div className="input-content">
                        <span>{t("globally.password")}</span>
                          <div onClick={() => setChangePassActive(true)}>
                            <div className="change-pass-button">
                              <div className="change-pass-button__text">{t("profile.changePass")}</div>
                              <i className="change-pass-button__icon icon icono-telefono"></i>
                            </div>
                          </div>
                      </div>
                    </div>
                    <div className="inputs-title inputs-title--second">{t("profile.generalInfo")}</div>
                    <div className="inputs-flex">
                      <div className="input-content">
                        <span>{t("globally.name")}</span>
                        <ZaiaInputForm 
                          placeholder={t("globally.name")}
                          type="text"
                          name="name"
                          borderRadius={"14px"}
                          fontSize={"20px"}
                          textColor={"#1F1F52"}
                          padding={"22px 24px 17px 27px"}
                          borderColor={"#6C6BCC"}
                          disabledColor={"#EDEDFF"}
                          onlyBottomBorder
                          disabled={!activeProfileEdition}
                          disabledTextColor={"#53536B"}
                          disabledIconColor={"#878792"}
                        />
                      </div>
                      <div className="input-content">
                        <span>{t("profile.lastname")}</span>
                        <ZaiaInputForm 
                          placeholder={t("profile.lastname")}
                          type="text"
                          name="lastname"
                          borderRadius={"14px"}
                          fontSize={"20px"}
                          textColor={"#1F1F52"}
                          padding={"22px 24px 17px 27px"}
                          borderColor={"#6C6BCC"}
                          disabledColor={"#EDEDFF"}
                          onlyBottomBorder
                          disabled={!activeProfileEdition}
                          disabledTextColor={"#53536B"}
                          disabledIconColor={"#878792"}
                        />
                      </div>
                      <div className="input-content">
                        <span>{t("profile.birthday")}</span>
                        <ZaiaInputForm 
                          type="date"
                          name="birthdate"
                          borderRadius={"14px"}
                          fontSize={"20px"}
                          textColor={"#1F1F52"}
                          padding={"22px 24px 17px 27px"}
                          disabledColor={"#EDEDFF"}
                          activeBorder={false}
                          disabled={!activeProfileEdition}
                          disabledTextColor={"#53536B"}
                          disabledIconColor={"#878792"}
                        />
                      </div>
                    </div>
                    <div className="inputs-flex">
                      <div className="input-content input-content--small-select">
                        <span>{t("profile.document")}</span>
                        <ZaiaSelectForm
                          type="select"
                          name="typeDocument"
                          borderRadius={"14px"}
                          fontSize={"20px"}
                          padding={"22px 24px 17px 27px"}
                          borderColor={"#6C6BCC"}
                          fontFamily={"Nunito Sans Bold"}
                          textColor={"#3E3DA3"}
                          disabledColor={"#EDEDFF"}
                          onlyBottomBorder
                          disabled={!activeProfileEdition}
                          disabledTextColor={"#53536B"}
                          disabledIconColor={"#878792"}
                          items={
                            documentTypeData?.enDocumentType.map((type) => {
                              return ({
                                value: type.value,
                                text: type.comment.code
                              })
                            })
                          }
                        />
                      </div>
                      <div className="input-content">
                        <ZaiaInputForm 
                          placeholder={t("profile.document")}
                          type="number"
                          name="document"
                          icon="icono-id"
                          iconColor={"#36369B"}
                          borderRadius={"14px"}
                          fontSize={"20px"}
                          textColor={"#1F1F52"}
                          padding={"22px 24px 17px 27px"}
                          borderColor={"#6C6BCC"}
                          disabledColor={"#EDEDFF"}
                          onlyBottomBorder
                          disabled={!activeProfileEdition}
                          disabledTextColor={"#53536B"}
                          disabledIconColor={"#878792"}
                        />
                      </div>
                      <div className="input-content input-content--small-select">
                        <span>{t("globally.phoneNumber")}</span>
                        <ZaiaSelectForm
                          type="select"
                          name="phoneLocation"
                          maxHeightList={"200px"}
                          borderRadius={"14px"}
                          fontSize={"20px"}
                          padding={"22px 24px 17px 27px"}
                          fontFamily={"Nunito Sans Bold"}
                          textColor={"#3E3DA3"}
                          borderColor={"#6C6BCC"}
                          disabledColor={"#EDEDFF"}
                          onlyBottomBorder
                          disabled={!activeProfileEdition}
                          disabledTextColor={"#53536B"}
                          disabledIconColor={"#878792"}
                          items={
                            countriesData?.country.map((country) => {
                              return ({
                                value: country.id,
                                text: "+" + country.phonecode
                                // text: country.sortname,
                                // textBold: '+' + country.phonecode
                              })
                            })
                          }
                        />
                      </div>
                      <div className="input-content">
                        {/* <span>kjjgklfd</span> */}
                        <ZaiaInputForm 
                          placeholder={t("globally.phoneNumber")}
                          type="number"
                          name="phoneNumber"
                          icon="icono-telefono"
                          iconColor={"#36369B"}
                          borderRadius={"14px"}
                          fontSize={"20px"}
                          padding={"22px 24px 17px 27px"}
                          textColor={"#1F1F52"}
                          borderColor={"#6C6BCC"}
                          disabledColor={"#EDEDFF"}
                          disabledTextColor={"#53536B"}
                          disabledIconColor={"#878792"}
                          onlyBottomBorder
                          disabled={!activeProfileEdition}
                        />
                      </div>
                    </div>
                    <div className="inputs-flex">
                      <div className="input-content">
                        <span>{t("profile.primarySpeciality")}</span>
                        <ZaiaSelectForm 
                          placeholder={t("profile.primarySpeciality")}
                          type="text"
                          name="primarySpeciality"
                          borderRadius={"14px"}
                          fontSize={"20px"}
                          padding={"22px 24px 17px 27px"}
                          activeBorder={false}
                          disabled={!activeProfileEdition}
                          disabledColor={"#EDEDFF"}
                          disabledTextColor={"#53536B"}
                          disabledIconColor={"#878792"}
                          items={
                            medicalFieldsData?.enMedicalField.map((medicalField) => {
                              return ({
                                value: medicalField.value,
                                text: P.dataTranslation(medicalField.comment, language)
                              })
                            })
                          }
                        />
                      </div>
                      <div className="input-content">
                        <span>{t("profile.medicalRegister")}</span>
                        <ZaiaInputForm 
                          placeholder={t("profile.medicalRegister")}
                          type="text"
                          name="medicalCode"
                          icon="icono-id"
                          iconColor={"#36369B"}
                          borderRadius={"14px"}
                          fontSize={"20px"}
                          textColor={"#1F1F52"}
                          padding={"22px 24px 17px 27px"}
                          borderColor={"#6C6BCC"}
                          disabledColor={"#EDEDFF"}
                          disabledTextColor={"#53536B"}
                          disabledIconColor={"#878792"}
                          onlyBottomBorder
                          disabled={!activeProfileEdition}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="button-container">
                    <ZaiaButton 
                      type="submit"
                      title={
                        editInfoLoading || changeDoctorImgLoading ?
                        `${t('globally.loading')}...` :
                        t("profile.saveChanges")
                      }
                    />
                  </div> */}
                </div>
              </div>
            </form>
          </> 
        )}
      </Formik>
    </S.ProfileFormDiv>
  );
}

export default ProfileForm;
