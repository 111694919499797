// graphQL
import { useMemo } from "react";


export default function useChatVerification(item, itemParent, customFormFields, isHistorical) {
  
  const listOptions = useMemo(() => {
    let options = item?.customFormTypeField.fieldOptions;
    if (item?.customFormFieldParentId && !isHistorical) {
      const fields = customFormFields.find(
        (field) => field.id === item.customFormFieldParentId
      )?.userToCustomFormFields 

      const parentKey = itemParent
        ? itemParent.key
        : fields
          ? fields[0]?.key
            : null;
      options = options.filter((field) => field.parentKey === parentKey);
    }
    return options;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, itemParent, customFormFields]);

  return { 
    listOptions: listOptions
  };
}