import { gql } from "@apollo/client";

export const SUBSCRIPTION_GET_SIDE_EFFECTS = gql`
  subscription SUBSCRIPTION_GET_SIDE_EFFECTS(
    $sideEffects: [userToSideEffect_bool_exp]
    $initialDate: timestamptz
    $finalDate: timestamptz
    $severities: [Int!]
  ) {
    userToSideEffect(where: {_or: $sideEffects, date: {_gte: $initialDate, _lte: $finalDate}, severity: {_in: $severities}}) {
      id
      typeSideEffect
      date
      typeSideEffectVal {
        value
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const SUBSCRIPTION_GET_SIDE_EFFECTS_BY_USER = gql`
  subscription SUBSCRIPTION_GET_SIDE_EFFECTS_BY_USER(
    $sideEffects: [userToSideEffect_bool_exp]
    $initialDate: timestamptz
    $finalDate: timestamptz
    $userId: uuid
    $severities: [Int!]
  ) {
    userToSideEffect(where: {_or: $sideEffects, date: {_gte: $initialDate, _lte: $finalDate}, severity: {_in: $severities}, userId: {_eq: $userId}}) {
      id
      typeSideEffect
      date
      typeSideEffectVal {
        value
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
        phoneCarer
        nameCarer
      }
    }
  }
`

export const SUBSCRIPTION_SIDE_EFFECTS_COMMENT_REPORTS = gql`
  subscription SUBSCRIPTION_SIDE_EFFECTS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz,
    $processId: [Int!]
  ) {
    userToSideEffect(
      where: {
        date: {
          _gte: $initialDate, 
          _lte: $finalDate
        }, 
        description: {
          _is_null: false
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      date
      description
      id
      userId
      modifiedDate
      severity
      typeSideEffectVal {
        comment
        value
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const SUBSCRIPTION_SIDE_EFFECTS_COMMENT_REPORTS_BY_USER = gql`
  subscription SUBSCRIPTION_SIDE_EFFECTS_COMMENT_REPORTS_BY_USER(
    $initialDate: timestamptz, 
    $finalDate: timestamptz, 
    $userId: uuid,
    $processId: [Int!]
  ) {
    userToSideEffect(
      where: {
        date: {
          _gte: $initialDate,
          _lte: $finalDate
        }, 
        userId: {
          _eq: $userId
        }, 
        description: {
          _is_null: false
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      date
      description
      id
      userId
      modifiedDate
      severity
      typeSideEffectVal {
        comment
        value
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`