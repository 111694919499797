import styled from "styled-components";

export const TimeHorizontalBars = styled.div`
  display: flex;
  min-height: 126px;
  .weekly-bars-card {
    /* width: ${({activeHeader}) => activeHeader ? '314px' : 'auto'};; */
    min-width: 314px;
    height: auto;
    border-radius: 10px;
    background-color: var(--white);
    box-shadow: ${({activeHeader}) => activeHeader ? '0px 3px 6px #00000029' : '' };
    padding: ${({activeHeader}) => activeHeader ? '5px' : '0 5px'};
    display: flex;
    flex-direction: column;
    margin: ${({activeHeader}) => activeHeader ? '10px' : 'auto'};
    &__header {
      display: flex;
      height: 52px;
      align-items: center;
      &-icon {
        background-color: ${({colorPrimary}) => colorPrimary};
        width: 45px;
        border-top-left-radius: 10px;
        display: flex;
        color: var(--white);
        font-size: 27px;
        padding-top: 14px; 
        i {
          margin: auto;
        }
      }
      &-text {
        margin-left: 10px;
        p {
          margin-top: 5px;
          font-family: "Open Sans";
          color: var(--text-toggle-gray);
          font-size: 12px;
        }
      }
    }
    &__bars-container {
      padding: ${({activeHeader}) => activeHeader ? '10px 0 0 0' : '0 10px'};
      height: 100%;
      display: flex;
      flex-direction: column;
      width: fit-content;
      margin: auto;
      &-listlevels {
        width: 250px;
        height: auto;
        align-self: flex-end;
        display: flex;
        justify-content: space-between;
        color: ${({colorPrimary}) => colorPrimary};
        margin-bottom: 10px;
        p {
          font-size: 9px;
          font-family: "Open Sans SemiBold"
        } 
      }
      &-bars {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        overflow: auto;
        height: 116px;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .weekly-bars-card {
      min-width: 270px;
      /* width: 250px; */
    }
  }
`;

export const HorizontalProgressBarDiv = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  align-items: center;
  .vertical-text {
    font-family: "Open Sans";
    background-color: ${({active}) => active ? '#E3E3E399'  : ''};
    border-radius: 10px;
    margin-right: 15px;
    min-width: 44px;
    &__number {
      width: 100%;
      font-size: 10px;
    }
  }
  .bar-container {
    width: 250px;
  }
`