import React, { useMemo, useState } from "react";
import * as S from "./SymptomsBoxInfo.style";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// pipes
import * as P from "../../../../../../../utils/pipes";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_SIDE_EFFECT } from "../../../../../../../graphql/queries/SideEffects";

// Components
import DatetimeScatterChart from "../../../../../shared/charts/datetimeScatterChart";

function SymptomsBoxInfo({ time, icon }) {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { patientID } = useParams();
  const { data: userToSideEffect } = useQuery(QUERY_GET_USER_SIDE_EFFECT, {
    variables: {
      patientId: patientID,
      startDate: new Date(time?.startDate),
      finishDate: new Date(time?.finishDate),
    },
  });
  // eslint-disable-next-line no-unused-vars
  const [, setTotalPercentage] = useState(0);

  const color = {
    primary: "var(--blue-color)",
    secundary: "var(--box-shadow-blue-color)",
    dark: "var(--blue-dark-color)",
    varitation: "var(--dashboard-color)",
  };

  const filterDataByDay = (info) => {
    let SympstomsByDay = [];
    let infoJoinedBySymptom = joinInfoBySymptom(info);
    const maxValue = getMaxValueOfReports(infoJoinedBySymptom);

    infoJoinedBySymptom.forEach((infoSymptoms) => {
      const percentageByDay = P.getPercentage(
        infoSymptoms.reports.length,
        maxValue
      );
      SympstomsByDay.push({
        name: P.dataTranslation(infoSymptoms.symptomName, language),
        data: infoSymptoms.reports.map((report) => {
          return [new Date(report.date), report.severity];
        }),
        percentage: percentageByDay,
      });
    });

    setTotalPercentage(infoJoinedBySymptom.length > 0 ? 100 : 0);
    return SympstomsByDay;
  };

  const filterDataByWeek = (info) => {
    let SympstomsByWeek = [];
    let infoJoinedBySymptom = joinInfoBySymptom(info);

    infoJoinedBySymptom.forEach((infoSymptoms) => {
      let reportsTimesJoined = timesJoined(infoSymptoms.reports);
      const percentageByWeek = P.getPercentage(reportsTimesJoined.length, 7);
      SympstomsByWeek.push({
        name: P.dataTranslation(infoSymptoms.symptomName, language),
        data: infoSymptoms.reports.map((report) => {
          return [new Date(report.date), report.severity];
        }),
        percentage: percentageByWeek,
      });
    });

    // get total percentage

    let allreports = [];
    infoJoinedBySymptom.forEach(
      (symptom) => (allreports = allreports.concat(symptom.reports))
    );
    let reportsTimesJoined = timesJoined(allreports);
    const totalPercentageWeek = P.getPercentage(reportsTimesJoined.length, 7);
    setTotalPercentage(totalPercentageWeek);
    
    return SympstomsByWeek;
  };

  const filterDataByMonth = (info) => {
    let SympstomsByMonth = [];
    let infoJoinedBySymptom = joinInfoBySymptom(info);

    let dateStartMonth = new Date(time.startDate);
    const numberDaysMonth = new Date(
      dateStartMonth.getFullYear(),
      dateStartMonth.getMonth() + 1,
      0
    ).getDate();

    infoJoinedBySymptom.forEach((infoSymptoms) => {
      let reportsTimesJoined = timesJoined(infoSymptoms.reports);
      const percentageByWeek = P.getPercentage(
        reportsTimesJoined.length,
        numberDaysMonth
      );
      SympstomsByMonth.push({
        name: P.dataTranslation(infoSymptoms.symptomName, language),
        data: infoSymptoms.reports.map((report) => {
          return [new Date(report.date), report.severity];
        }),
        percentage: percentageByWeek,
      });
    });

    // get total percentage

    let allreports = [];
    infoJoinedBySymptom.forEach(
      (symptom) => (allreports = allreports.concat(symptom.reports))
    );
    let reportsTimesJoined = timesJoined(allreports);
    const totalPercentageMonth = P.getPercentage(
      reportsTimesJoined.length,
      numberDaysMonth
    );
    setTotalPercentage(totalPercentageMonth);

    return SympstomsByMonth;
  };

  const getMaxValueOfReports = (sympstomsList) => {
    let max = 0;

    for (let symptom of sympstomsList) {
      if (max < symptom.reports.length) max = symptom.reports.length;
    }

    return max;
  };

  const timesJoined = (info) => {
    let filteredDates = [];
    info?.forEach((date) => {
      const searchDate = filteredDates.find(
        (findDate) => new Date(findDate).getDate() === new Date(date).getDate()
      );
      if (!searchDate) {
        const newDate = date;
        filteredDates.push(newDate);
      }
    });
    return filteredDates;
  };

  const joinInfoBySymptom = (info) => {
    let filteredSymptoms = [];
    info?.forEach((report) => {
      const searchSymptom = filteredSymptoms.find(
        (symReport) => symReport.symptom === report.typeSideEffectVal.value
      );
      if (!searchSymptom) {
        const newReport = {
          symptomName: report.typeSideEffectVal.comment,
          symptom: report.typeSideEffectVal.value,
          reports: [
            {
              date: report.date,
              severity: report.severity,
            },
          ],
        };
        filteredSymptoms.push(newReport);
      } else {
        const feelIndex = filteredSymptoms.indexOf(searchSymptom);
        let reportsArray = filteredSymptoms[`${feelIndex}`].reports;
        reportsArray.push({
          date: report.date,
          severity: report.severity,
        });
        filteredSymptoms[`${feelIndex}`].reports = reportsArray;
      }
    });
    return filteredSymptoms;
  };

  const averageByDay = useMemo(() => {
    const info = userToSideEffect?.user[0].userToSideEffects;
    if (time?.timeOp === "day") return filterDataByDay(info);
    if (time?.timeOp === "week") return filterDataByWeek(info);
    if (time?.timeOp === "month") return filterDataByMonth(info);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToSideEffect, time]);

  const colorDinamicArray = useMemo(() => {
    return generateColor("000000", "6e6dbe", averageByDay?.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [averageByDay]);

  function processHEX(val) {
    //does the hex contain extra char?
    let hex = val.length > 6 ? val.substr(1, val.length - 1) : val;
    let r;
    let g;
    let b;
    // is it a six character hex?
    if (hex.length > 3) {
      //scrape out the numerics
      r = hex.substr(0, 2);
      g = hex.substr(2, 2);
      b = hex.substr(4, 2);

      // if not six character hex,
      // then work as if its a three character hex
    } else {
      // just concat the pieces with themselves
      r = hex.substr(0, 1) + hex.substr(0, 1);
      g = hex.substr(1, 1) + hex.substr(1, 1);
      b = hex.substr(2, 1) + hex.substr(2, 1);
    }
    // return our clean values
    return [parseInt(r, 16), parseInt(g, 16), parseInt(b, 16)];
  }

  function generateColor(val1El, val2El, stepsInt) {
    const val1RGB = processHEX(val1El);
    const val2RGB = processHEX(val2El);
    let colors = [
      // somewhere to dump gradient
    ];

    //the percentage representation of the step
    const stepsPerc = 100 / (stepsInt + 1);

    // diffs between two values
    let valClampRGB = [
      val2RGB[0] - val1RGB[0],
      val2RGB[1] - val1RGB[1],
      val2RGB[2] - val1RGB[2],
    ];

    // build the color array out with color steps
    for (let i = 0; i < stepsInt; i++) {
      let clampedR =
        valClampRGB[0] > 0
          ? pad(
              Math.round(
                (valClampRGB[0] / 100) * (stepsPerc * (i + 1))
              ).toString(16),
              2
            )
          : pad(
              Math.round(
                val1RGB[0] + (valClampRGB[0] / 100) * (stepsPerc * (i + 1))
              ).toString(16),
              2
            );

      let clampedG =
        valClampRGB[1] > 0
          ? pad(
              Math.round(
                (valClampRGB[1] / 100) * (stepsPerc * (i + 1))
              ).toString(16),
              2
            )
          : pad(
              Math.round(
                val1RGB[1] + (valClampRGB[1] / 100) * (stepsPerc * (i + 1))
              ).toString(16),
              2
            );

      let clampedB =
        valClampRGB[2] > 0
          ? pad(
              Math.round(
                (valClampRGB[2] / 100) * (stepsPerc * (i + 1))
              ).toString(16),
              2
            )
          : pad(
              Math.round(
                val1RGB[2] + (valClampRGB[2] / 100) * (stepsPerc * (i + 1))
              ).toString(16),
              2
            );
      colors[i] = `#${clampedR}${clampedG}${clampedB}`;
    }

    //update the pre element
    return colors;
  }

  function pad(n, width) {
    const z = "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  const severityLabels = [
    {
      id: 1,
      name: t("patients.boxInfoPatient.symptoms.veryMild"),
    },
    {
      id: 2,
      name: t("globally.mild"),
    },
    {
      id: 3,
      name: t("globally.medium"),
    },
    {
      id: 4,
      name: t("globally.severe"),
    },
    {
      id: 5,
      name: t("patients.boxInfoPatient.symptoms.verySevere"),
    },
  ];

  return (
    <S.SymptomsBoxInfo>
      <div className="boxInfoPatient__info">
        <div className="boxInfoPatient__info-left">
          <DatetimeScatterChart
            colors={colorDinamicArray}
            dataChart={averageByDay}
            minDate={time?.startDate}
            maxDate={time?.finishDate}
            chartYMax={5}
            chartYMin={1}
            labels={severityLabels}
          />
        </div>
        <div className="boxInfoPatient__info-right">
          <h4>{t("patients.boxInfoPatient.report.symptoms")}</h4>
          <S.CircleChartTextBig
            colorSecundary={color.dark}
            colorPrimary={color.primary}
          >
            <h2>{userToSideEffect?.user[0].userToSideEffects.length || 0}</h2>
            <p>{t("patients.boxInfoPatient.report.symptomsReported")}</p>
          </S.CircleChartTextBig>
        </div>
      </div>
    </S.SymptomsBoxInfo>
  );
}

export default SymptomsBoxInfo;
