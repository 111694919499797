import styled from "styled-components";

export const SelectorFormDiv = styled.div`
  border-radius: 10px;
  box-shadow: ${({boxShadow}) => boxShadow ? "0px 3px 6px var(--box-shadow)" : ""};
  padding: ${({boxShadow}) => boxShadow ? "10px 15px": ""};
`;


export const SelectorForm = styled.div`
  z-index: 100;
  width: 100%;
  .progression-free-survival-form {
    &-input {
      margin-bottom: 5px;
    }
    &-buttons {
      display: flex;
      justify-content: start;
    }
    &-button {
      width: 100px;
    }
  }
`
