import React, { useEffect, useState } from "react";
import * as S from "./ModalSelectEventMarker.style"
import * as P from "../../../../../../../utils/pipes";

// pipes
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import { useTranslation } from "react-i18next";

const eventsCalendarEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function ModalSelectEventMarker({ 
  setModalActive,
  eventMarkersSeleted,
  setEventMarkersSeleted,
  markersList,
  markersListLoading,
  markersListError,
}) {

  const {t, i18n: { language }} = useTranslation("global"); 

  const [inputText, setInputText] = useState('');
  
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setModalActive(null);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const getFilterText = (text) => {
    return inputText 
      ? String(text).toLowerCase().includes(inputText.toLowerCase())
      : true
  }

  const handleEventMarkerSelector = (event) => {
    if(eventMarkersSeleted.includes(event)) {
      setEventMarkersSeleted(eventMarkersSeleted.filter((marker) => marker !== event))
    } else {
      setEventMarkersSeleted([...eventMarkersSeleted, event])
    }
  }

  const selectAllMarkers = () => {
    setEventMarkersSeleted(
      markersList?.customForm.map((marker) => {return marker.id})
    )
  }

  return (
    <S.ModalSelectEventMarker>
      <div className="modal-background" onClick={() => setModalActive(false)}>
      </div>
      <div className="modal-container">
        <div className="header">
          <div className="header__text">
            <div className="header__title">{t("aiNavigator.outcomes.selectMarker")}</div>
          </div>
          <div className="header__close" onClick={() => setModalActive(false)}>
            <i className="header__close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        <div className="input-button">
          <div className={`filter-patient`}>
            <input 
              className={`filter-patient__input`} 
              type="text" 
              placeholder={t("aiNavigator.outcomes.searchMarker")}
              onChange={(event) => setInputText(event.target.value)}
            />
            <i className={`filter-patient__icon icon zaia-search`}></i>
          </div>
          <button className="select-all-button" onClick={() => selectAllMarkers()}>
            {t("aiNavigator.outcomes.selectAll")}
          </button>
        </div>
        <div className="users-list">
          {
            markersListError && 
            <div className="users-list__responses">
              <ZaiaReaction
                zaiaImg={logoZaiaAlert}
                sizeText="20px"
                textColor="var(--blue-color)"
                widthImg="110px"
              />
            </div>
          }
          {
            markersListLoading && 
            <div className="users-list__responses">
              <ZaiaReaction
                zaiaImg={logoZaiaLoading}
                sizeText="20px"
                textColor="var(--blue-color)"
                widthImg="110px"
              />
            </div>
          }
          {
            !markersListLoading && !markersListError && (markersList?.customForm?.length === 0 || markersList?.customForm.filter((marker) => getFilterText(P.dataTranslation(marker.customFormName, language))).length === 0) && 
            <div className="users-list__responses">
              <ZaiaReaction
                zaiaImg={eventsCalendarEmpty}
                text={t("globally.noResults")}
                subText={t("globally.noMatchingPatients")}
                sizeText="33.25px"
                sizeSubText="15px"
                textColor="var(--blue-color)"
                widthImg="210px"
              />
            </div>
          }
          {
            !markersListLoading && !markersListError && markersList?.customForm?.length !== 0 &&
            markersList?.customForm.map((marker) => 
              {
                if(getFilterText(P.dataTranslation(marker.customFormName, language))) {
                  return (
                    <div className={`marker ${ eventMarkersSeleted.includes(marker.id) && 'marker--selected'}`}  onClick={() => 
                      {
                        handleEventMarkerSelector(marker.id)
                      }
                    }>
                      <S.Event color={marker.color ?? "#FF6D2A"}>
                        <div className="circle"></div>
                        <div className="name">{P.dataTranslation(marker.customFormName, language)}</div>
                      </S.Event>
                    </div>
                  )
                  
                } else {
                  return null
                }
              }
            )
          }
        </div>
      </div>
    </S.ModalSelectEventMarker>
  );
}

export default ModalSelectEventMarker;
