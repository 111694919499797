import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { useStateValue } from "../contextAPI/StateProvider";
import { QUERY_GET_USERS_BY_USER_ID } from "../graphql/queries/User";

export default function useAssignSelectPatient() {
  const [, dispatch] = useStateValue();
  const [ getPatientData, { data } ] = useLazyQuery(
    QUERY_GET_USERS_BY_USER_ID
  );

  useEffect(() => {
    if (data && data.user && data.user.length > 0) {
      dispatch({
        type: "SET_SELECTED_PATIENT",
        selectedPatient: data.user[0],
      });
    }
  }, [data, dispatch]);

  const selectPatient = (patientId) => {
    getPatientData({ variables: { patientId: patientId } });
  };

  const removePatient = () => {
    dispatch({
      type: "SET_SELECTED_PATIENT",
      selectedPatient: null,
    });
  };

  return { 
    selectPatient,
    removePatient
  };
}