import styled from "styled-components";

export const ProfileFormDiv = styled.div`
  width: 100%;
  height: 100%; 
  display: flex;
  margin-top: 34px;
  overflow: auto;
  /* padding-bottom: 60px; */
  .profile-form {
    display: flex;
  }
  .profile-left{
    height: 100%;
    margin-right: 60px;
  }
  .profile-right{
    height: 100%;
    width: 100%;
    .form-container {
      height: 100%;
      /* padding: 20px 70px 0 40px; */
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      .inputs-container{
        .inputs-title {
          font-family: "Nunito Sans";
          font-size: 30px;
          color: #68688D;
          margin-bottom: 30px;
          &--second {
            margin-top: 30px;
          }
        }
        .inputs-flex {
          display: flex;
          width: 100%;
          justify-content: space-between;
          .input-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-self: flex-end;
            span {
              color: #000;
              padding-bottom: 7.5px;
              white-space: nowrap;
            }
            &--small-select {
              width: 130px;
              min-width: 130px;
            }
            &:not(:last-child) {
              margin-right: 40px;
            }
          }
        }
      }
      .change-pass-button{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 67px;
        border-radius: 10px;
        border: 1px solid #36369B;
        cursor: pointer;
        color: #36369B;
        margin-bottom: 17px; 
      }
      .button-container {
        margin: 20px auto;
        width: 300px;
        height: 50px;
      }
    }
  }

  @media only screen and (max-width: 840px) {
    display: block;
    overflow: auto;
    .profile-left {
      margin: auto;
      height: auto;
    }
    .profile-right {
      height: auto;
    }
    .profile-form {
      padding: 10px 40px 0 40px !important;
    }
    .inputs-container {
      height: auto;
    }
    .button-container {
      padding: 20px 0px 20px;
    }
  }

  @media only screen and (max-width: 600px) {
    .inputs-container {
      height: auto;
    }
    .inputs-flex {
      flex-direction: column;
    }
    .input-content {
      width: 100% !important;
    }
    .button-container {
      padding: 20px 0px 20px;
    }
  }

  @media only screen and (max-width: 425px) {
    .profile-form {
      padding: 10px 20px 0 20px !important;
    }
    .button-container {
      width: auto !important;
      min-width: 200px !important;
    }
  }
`;
