import React, { useContext, useEffect, useState } from "react";
import * as S from "./Message.style"
import { ThemeContext } from 'styled-components';

function Message({ 
  message, 
  option, 
  time, 
  date, 
  senderName, 
  link,
  children 
}) {

  const theme = useContext(ThemeContext);
  
  const [colorByType, setColorByType] = useState("");
  const [messageEdited, setMessageEdited] = useState("");

  useEffect(() => {
    let messageTemp = message;
    messageTemp = messageTemp?.replace('{{medicine}}','');
    setMessageEdited(messageTemp);

    let result = message?.includes("{{medicine}}");
    if(result) {
      setColorByType(theme.primarySectionMedicines);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function renderMessage(children, link, messageEdited) {
    let message;
    if (children) {
      message = children;
    } else {
      if (link) {
        message = <a href={messageEdited} target="_blank" rel="noopener noreferrer">{messageEdited}</a>;
      } else {
        message = messageEdited;
      }
    }
    return message;
  }

  return (
    <S.Message 
      time={time} 
      option={option} 
      color={colorByType}
    >
      {
        senderName ? 
        <div className="message__sender-name">{senderName}</div> : null
      }
      { option === 'doctor' ? 
        <div className="message message--patient" time={time}>
          {
            children 
            ? children 
            : messageEdited
          }
        </div> :
        <div className="message message--reciever">
          {renderMessage(children, link, messageEdited)}
        </div>
      }
      {
        time && date ? 
        <div className="message__time">{time} • {date}</div> : null
      }
      {
        option === 'doctor' 
        ? <div className="message__tail--patient">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18" fill="none">
            <path d="M19 16.7502C9.89773 18.4052 2.96759 13.6472 0.640306 11.0613L0.898893 3.30368C3.39857 3.82085 8.81166 4.28631 10.4666 2.01074C10.208 11.3199 17.1899 15.9745 19 16.7502Z" fill="#1F1F52" stroke="#1F1F52" stroke-width="0.805851"/>
          </svg>
        </div>
        : <div className="message__tail--reciever">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="18" viewBox="0 0 21 18" fill="none">
            <path d="M2 16.7502C11.1023 18.4052 18.0324 13.6472 20.3597 11.0613L20.1011 3.30368C17.6014 3.82085 12.1883 4.28631 10.5334 2.01074C10.792 11.3199 3.81011 15.9745 2 16.7502Z" fill="#E8E7F4" stroke="#E8E7F4" stroke-width="0.805851"/>
          </svg>
        </div>
      }
    </S.Message>
  );
}

export default Message;
