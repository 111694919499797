
import styled from "styled-components";

export const ListOfBoxes = styled.div`
  width: 100%;
  align-items: flex-start;
  overflow-y: scroll;
  /* border-radius: 20px; */
  /* padding: 50px; */
  .box-container{
    width: 32%;
    display: inline-block;
    /* vertical-align: text-top; */
    margin: 10px 5px;
  }
  /* .box-container:nth-child(odd) {
  }
  .box-container:nth-child(even) {
    margin-left: 10px
  } */
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 1200px) {
    .box-container{
      width: 98%;
    }
    .box-container:nth-child(even) {
      margin-left: 0px
    }
  }
`

export default ListOfBoxes;