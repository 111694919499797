import styled from "styled-components";

export const ZaiaButton = styled.button`
  opacity: ${({disabled}) => disabled ? 0.5 : 1};
  cursor: ${({disabled}) => disabled ? "default" : "pointer"};
  background-color: ${
      ({
        theme,
        backgroundColor, 
        option
      }) => 
      {
        if (backgroundColor) {
          return backgroundColor;
        } else {
          if (option === "secondary") {
            return theme.background;
          } else {
            return theme.primaryColor;
          }
        }
      }
    };
  border: ${
      ({
        theme,
        color, 
        option, 
        border
      }) => 
      {
        if (border) {
          if (color) {
            return `1px solid ${color}`;
          } else {
            if (option === "secondary") {
              return `1px solid ${theme.primaryColor}`;
            } else {
              return "none";
            }
          }
        } else {
          return "none";
        }
      }
    };
  border-radius: ${
      ({borderRadius}) => 
      borderRadius 
        ? borderRadius 
        : '20'
    }px;
  color: ${
      ({
        theme,
        color, 
        option
      }) => 
        {
          if (color) {
            return color;
          } else {
            if (option === "secondary") {
              return theme.primaryColor;
            } else {
              return theme.onPrimaryColor;
            }
          }
        }
    };
  /* box-shadow: ${({theme, shadow, color, option}) => `0px 3px 10px ${theme.primaryColorShadow}56`}; */
  box-shadow: ${
      ({theme, shadow, color, option}) => 
        {
          if (shadow) {
            if (color) {
              return `3px 3px 5px ${theme.blackShadow}29`;
            } else {
              if (option === "secondary") {
                return "";
              } else {
                return `0px 3px 10px ${theme.primaryColorShadow}56`;
              }
            }
          } else {
            return "";
          }
        }
    };
  width: 100%;
  height: ${
      ({height}) => 
        height 
        ? height 
        : '42'
    }px;
  font-family:  ${
      ({fontFamily}) => 
        fontFamily 
        ? fontFamily 
        : 'Nunito Sans'
    };
  padding: ${
      ({padding}) => 
        padding 
        ? padding 
        : '0'
    };
  margin-top: ${({marginTop}) => marginTop}px;
  margin-bottom: ${({marginBottom}) => marginBottom}px;
  outline: none;
  font-size: ${({fontSize}) => fontSize}px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .icon-container {
    .button-icon {
      margin-right: ${({iconMargin}) => iconMargin}px;
      font-size: ${({iconSize}) => iconSize}px;
    }
  }
`