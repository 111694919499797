import { gql } from "@apollo/client";

export const QUERY_GET_DOCTOR_DATA = gql`
  query QUERY_GET_DOCTOR_DATA($doctorId: uuid) {
    doctor(where: {id: {_eq: $doctorId}}) {
      id
      email
      name
      lastname
      picture
      isEmailVerified
      gender
    }
  }
`;

export const QUERY_GET_COMPLETE_DOCTOR_DATA = gql`
  query QUERY_GET_COMPLETE_DOCTOR_DATA($doctorId: uuid) {
    doctor(where: {id: {_eq: $doctorId}}) {
      id
      picture
      isEmailVerified
      email
      name
      lastname
      birthdate
      countryPhoneId
      phone
      documentType
      documentNumber
      medicalCode
      gender
      doctorData {
        id
        medicalField
        academicTitle
        universityName
      }
    }
  }
`;

export const QUERY_GET_DOCTORS_DATA = gql`
  query QUERY_GET_DOCTORS_DATA($doctorId: String!, $processId: Int!) {
    health_system_colleagues_by_process(doctor_id: $doctorId, process_id: $processId) {
      status
      message
      health_system_colleagues
    }
  }
`;



