import { gql } from "@apollo/client";

export const QUERY_GET_SIDE_EFFECTS = gql`
  query QUERY_GET_SIDE_EFFECTS(
    $sideEffects: [userToSideEffect_bool_exp]
    $initialDate: timestamptz
    $finalDate: timestamptz
    $severities: [Int!]
  ) {
    userToSideEffect(where: {_or: $sideEffects, date: {_gte: $initialDate, _lte: $finalDate}, severity: {_in: $severities}}) {
      id
      typeSideEffect
      date
      typeSideEffectVal {
        value
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const QUERY_GET_SIDE_EFFECTS_BY_USER = gql`
  query QUERY_GET_SIDE_EFFECTS_BY_USER(
    $sideEffects: [userToSideEffect_bool_exp]
    $initialDate: timestamptz
    $finalDate: timestamptz
    $userId: uuid
    $severities: [Int!]
  ) {
    userToSideEffect(where: {_or: $sideEffects, date: {_gte: $initialDate, _lte: $finalDate}, severity: {_in: $severities}, userId: {_eq: $userId}}) {
      id
      typeSideEffect
      date
      typeSideEffectVal {
        value
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
        phoneCarer
        nameCarer
      }
    }
  }
`;

export const QUERY_GET_SIDE_EFFECTS_BY_USER_COUNTER = gql`
  query QUERY_GET_SIDE_EFFECTS_BY_USER_COUNTER(
    $sideEffects1: [userToSideEffect_bool_exp]
    $severities1: [Int!]
    $sideEffects2: [userToSideEffect_bool_exp]
    $severities2: [Int!]
    $sideEffects3: [userToSideEffect_bool_exp]
    $severities3: [Int!]
    $initialDate: timestamptz
    $finalDate: timestamptz
  ) {
    user(where: {userToProcesses: {isAcepted: {_eq: true}}}) {
      id
      name
      lastname
      documentNumber
      picture
      phone
      weight
      height
      birthdate
      phoneCarer
      nameCarer
      email
      phoneCountry {
        phonecode
      }
      carerPhoneCountry {
        phonecode
      }
      userToProcesses {
        id
        nextUpdate
        stageId
        processStage {
          id
          descript
          expirationTime
          isAutomatic
          nextStageId
          processId
          stageName
          frequency
          frequencyType
        } 
        userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
          created_at
          customFormFieldChanges
          date
          descript
          doctorId
          id
          isChecked
          latestCustomFormId
          processId
          stageId
          userToProcessId
        }
        process {
          id
          processName
          processToCustomForms(order_by: {customFormId: asc}) {
            customFormId
            id
            processId
          }
        }
      }
      sideEffects1Count: userToSideEffects_aggregate(where: {_or: $sideEffects1, date: {_gte: $initialDate, _lte: $finalDate}, severity: {_in: $severities1}}) {
        aggregate {
          count
        }
      },
      sideEffects2Count: userToSideEffects_aggregate(where: {_or: $sideEffects2, date: {_gte: $initialDate, _lte: $finalDate}, severity: {_in: $severities2}}) {
        aggregate {
          count
        }
      },
      sideEffects3Count: userToSideEffects_aggregate(where: {_or: $sideEffects3, date: {_gte: $initialDate, _lte: $finalDate}, severity: {_in: $severities3}}) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const QUERY_GET_SIDE_EFFECTS_BY_USER_COUNTER_ID = gql`
  query QUERY_GET_SIDE_EFFECTS_BY_USER_COUNTER(
    $sideEffects1: [userToSideEffect_bool_exp]
    $severities1: [Int!]
    $sideEffects2: [userToSideEffect_bool_exp]
    $severities2: [Int!]
    $sideEffects3: [userToSideEffect_bool_exp]
    $severities3: [Int!]
    $initialDate: timestamptz
    $finalDate: timestamptz
    $userId: uuid
  ) {
    user(where: {id: {_eq: $userId}, userToProcesses: {isAcepted: {_eq: true}}}) {
      id
      name
      lastname
      documentNumber
      picture
      phone
      weight
      height
      birthdate
      phoneCarer
      nameCarer
      email
      phoneCountry {
        phonecode
      }
      carerPhoneCountry {
        phonecode
      }
      userToProcesses {
        id
        nextUpdate
        stageId
        processStage {
          id
          descript
          expirationTime
          isAutomatic
          nextStageId
          processId
          stageName
          frequency
          frequencyType
        } 
        userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
          created_at
          customFormFieldChanges
          date
          descript
          doctorId
          id
          isChecked
          latestCustomFormId
          processId
          stageId
          userToProcessId
        }
        process {
          id
          processName
          processToCustomForms(order_by: {customFormId: asc}) {
            customFormId
            id
            processId
          }
        }
      }
      sideEffects1Count: userToSideEffects_aggregate(where: {_or: $sideEffects1, date: {_gte: $initialDate, _lte: $finalDate}, severity: {_in: $severities1}}) {
        aggregate {
          count
        }
      },
      sideEffects2Count: userToSideEffects_aggregate(where: {_or: $sideEffects2, date: {_gte: $initialDate, _lte: $finalDate}, severity: {_in: $severities2}}) {
        aggregate {
          count
        }
      },
      sideEffects3Count: userToSideEffects_aggregate(where: {_or: $sideEffects3, date: {_gte: $initialDate, _lte: $finalDate}, severity: {_in: $severities3}}) {
        aggregate {
          count
        }
      }
    }
  }
`;

export const QUERY_SIDE_EFFECTS_COMMENT_REPORTS = gql`
  query QUERY_SIDE_EFFECTS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz,
    $processId: [Int!]
  ) {
    userToSideEffect(
      where: {
        date: {
          _gte: $initialDate, 
          _lte: $finalDate
        }, 
        description: {
          _is_null: false
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      date
      description
      id
      userId
      modifiedDate
      severity
      typeSideEffectVal {
        comment
        value
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const QUERY_SIDE_EFFECTS_COMMENT_REPORTS_BY_USER = gql`
  query QUERY_SIDE_EFFECTS_COMMENT_REPORTS_BY_USER(
    $initialDate: timestamptz, 
    $finalDate: timestamptz, 
    $userId: uuid,
    $processId: [Int!]
  ) {
    userToSideEffect(
      where: {
        date: {
          _gte: $initialDate,
          _lte: $finalDate
        }, 
        userId: {
          _eq: $userId
        }, 
        description: {
          _is_null: false
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      date
      description
      id
      userId
      modifiedDate
      severity
      typeSideEffectVal {
        comment
        value
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const QUERY_GET_USER_SIDE_EFFECT = gql`
  query QUERY_GET_USER_SIDE_EFFECT(
    $patientId: uuid
    $startDate: timestamptz
    $finishDate: timestamptz
  ) {
    user(where: { id: { _eq: $patientId } }) {
      userToSideEffects(
        where: { _and: { date: { _gte: $startDate, _lte: $finishDate } } }
      ) {
        id
        date
        severity
        typeSideEffectVal {
          value
          comment
          groupSideEffect
        }
      }
    }
  }
`;

export const QUERY_GET_USER_SIDE_EFFECT_NO_DATES = gql`
  query QUERY_GET_USER_SIDE_EFFECT_NO_DATES(
    $patientId: uuid
  ) {
    user(where: { id: { _eq: $patientId } }) {
      userToSideEffects {
        id
        date
        severity
        typeSideEffectVal {
          value
          comment
          groupSideEffect
        }
      }
    }
  }
`;

export const QUERY_GET_ALL_USER_SIDE_EFFECT = gql`
  query QUERY_GET_ALL_USER_SIDE_EFFECT(
    $startDate: timestamptz
    $finishDate: timestamptz
    $processIds: [Int!]
  ) {
    userToSideEffect(
      where: { 
        _and: { 
          date: { 
            _gte: $startDate, 
            _lte: $finishDate 
          },
          user: {
            userToProcesses: {
              processId: {
                _in: $processIds
              }
            }
          }
        } 
      }
    ) {
      id
      date
      userId
      typeSideEffectVal {
        value
        comment
        groupSideEffect
      }
    }
  }
`;

export const QUERY_GET_TYPE_SIDEEFFECTS = gql`
  query QUERY_GET_TYPE_SIDEEFFECTS($filterText: String) {
    typeSideEffect(where: {comment: {_cast: {String: {_ilike: $filterText}}}}, limit: 200) {
      comment
      value
    }
  }
`