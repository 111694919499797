import { gql } from "@apollo/client";

export const MUTATION_DELETE_USER_TO_PROCESS_CALENDAR = gql`
  mutation MUTATION_DELETE_USER_TO_PROCESS_CALENDAR(
    $eventId: uuid!
  ) {
    delete_userToProcessCalendar_by_pk(id: $eventId) {
      id
    }
  }
`;