import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalSendSurvey = styled.div`
  display: ${({hidden}) =>  hidden ? 'none' : 'flex' };
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-reaction {
    padding: 40px 40px;
    margin: auto;
  }
  .modal-container {
    height: 100%;
    width: 100%;
    z-index: 200;
    background-color: var(--white);
    margin: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* max-height: 80%; */
    /* overflow-y: auto; */
    border-radius: 14px;
    /* padding: 40px 0px; */
    .header {
      padding: 15px 40px;
      display: flex;
      min-height: 52px;
      justify-content: space-between;
      border-bottom: 1px solid #0000002B;
      align-items: center;
      &__title {
        font-size: 32.25px;
        font-family: Nunito Sans Bold;
        font-size: 19.08px;
        color: #000000;
      }
      &__close {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        cursor: pointer;
        color: #000;
      }
    }
    .modal-content-dinamic {
      height: calc(100% - 83px);
      .dinamic-component {
        height: 100%;
      }
    }
    .modal-tabs {
      display: flex;
      padding: 20px 40px 0;
      .tab {
        cursor: pointer;
        display: flex;
        width: 188px;
        padding: 10px 20px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        box-shadow: 0px 4px 18.5px -3px rgba(0, 0, 255, 0.06) inset;
        font-family: "Nunito Sans";
        font-size: 14px;
        margin-right: 10px;
        color: #6C6BCC;
        background: #F0F0FF;
        &--selected {
          font-family: "Nunito Sans Bold";
          color: #36369B;
          background: #D2D2FF;
        }
      }
    }     
  }
  
  

  @media only screen and (max-width: 435px) {
    .buttons-container {
      .button-submit {
        button {
          /* width: 70%; */
          margin: auto;
        }
      }
      .button-close {

      }
    } 
  }
`;

export const InputOption = styled.div`
  padding: 12px 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  min-width: 100px;
  background-color: ${({theme, backgroundColor}) => backgroundColor ? backgroundColor : theme.inputBackground};
  margin-bottom: 10px;
  position: relative;
  opacity: ${({disabled}) => disabled ? "0.3" : ""};
  cursor: ${({disabled}) => disabled ? "" : "pointer"};
  border: ${
    ({ 
      selected
    }) => 
      {
        if(selected) {
          return '2px solid #6C6BCC'
        } else {
          return '1px solid #36369B'
        }
      }
  };
  .input-icon {
    font-size: 14px;
    margin-right: 5px;
    color: #36369B;
  }
  .input-text {
    font-family: "Nunito Sans";
    font-size: 14px;
    color: ${({selected, filled}) => selected ? "#36369B" : filled ? "#6C6BCC": "#3E3E5B"};
    cursor: ${({disabled}) => disabled ? "deafult" : "pointer"};
    b {
      margin-left: 5px;
    }
  }
  .input-icon-arrow {
    color: ${({selected}) => selected ? "#36369B" : "#6C6BCC"};
    font-size: 12px;
    transition: .3s ease;
    transform: ${({selected}) => selected ? 'rotate(270deg)' : 'rotate(90deg)'};
    margin-left: auto;
  }
  .input-icon-check {
    color: ${({selected}) => selected ? "#36369B" : "#6C6BCC"};
    font-size: 11px;
    margin-left: auto;
  }
  .shape-container {
    position: absolute;
    right: -23px;
    top: 10px;
  }
`