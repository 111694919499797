import Session from "supertokens-auth-react/recipe/session";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Multitenancy from "supertokens-auth-react/recipe/multitenancy";

const apiDomain = `${process.env.REACT_APP_API_DOMAIN}`;
const websiteDomain = `${process.env.REACT_APP_WEBSITE_DOMAIN}`;
const apiBasePath = `${process.env.REACT_APP_API_BASE_PATH}`;

export const SuperTokensConfig = {
  appInfo: {
    appName: "zaia-web",
    apiDomain,
    websiteDomain,
    apiBasePath,
  },
  recipeList: [
    EmailPassword.init(),
    Multitenancy.init({
      override: {
        functions: (oI) => {
          return {
            ...oI,
            getTenantId: async () => {
              return "doctor";
            },
          };
        },
      },
    }),
    Session.init(),
  ],
};
