import styled from "styled-components";

export const ProcessNameContainer = styled.div` 
  background-color: ${({theme}) => theme.background};
  padding: 5px;
  border-radius: 7px;
  cursor: default;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
`

export const ProcessName = styled.div` 
  font-size: 17px;
  font-family: "Nunito Sans Light";
  border-bottom: ${({last, theme}) => !last ? '1px solid ' + theme.lineSeparation : null};
  /* margin: 5px 0px; */
  padding: 2px 5px; 
  .card-selected-patient-process {
    color: #526479 !important;
  }
`