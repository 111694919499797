import React, { useEffect } from "react";
import * as S from "./DropdownCheck.style";

function DropdownCheck({ 
  optionsSelectedIds, 
  setOptionsSelectedIds, 
  multipleSelection=true, 
  quantityActive,
  options 
}) {  

  return (
    <S.DropdownCheckDiv >
      {
        options?.map(option => {
          return (
            <DropdownCheckOptions
              title={option.name}
              id={option.id}
              key={option.id}
              quantity={option.quantity}
              quantityActive={quantityActive}
              setOptionsSelectedIds={setOptionsSelectedIds}
              optionsSelectedIds={optionsSelectedIds}
              multipleSelection={multipleSelection}
            />  
          )
        })
      } 
    </S.DropdownCheckDiv>
  );
}

const DropdownCheckOptions = ({ 
  title, 
  id, 
  quantity,
  quantityActive,
  setOptionsSelectedIds, 
  optionsSelectedIds, 
  multipleSelection
}) => {

  const checkId = (id) => {
    if(multipleSelection) {
      const indexProcess = optionsSelectedIds.indexOf(id);
      if(indexProcess === -1) setOptionsSelectedIds([...optionsSelectedIds, id])
      else setOptionsSelectedIds(optionsSelectedIds.filter(item => item !== id))
    } else {
      setOptionsSelectedIds(id)
    }
  }

  useEffect( () => {
  }, [optionsSelectedIds])

  return (
    <S.DropdownCheckOptionsDiv>
      <input 
        type="checkbox" 
        id={`check${id}${title}`} 
        onClick={() => checkId(id)} 
        onChange={() => {}}
        checked={
          multipleSelection
          ? optionsSelectedIds?.includes(id)
          : optionsSelectedIds === id
        }
      />
      <label htmlFor={`check${id}${title}`}>{title}</label>
      { quantityActive ? <div className="process-quantity">({quantity})</div>: null}
    </S.DropdownCheckOptionsDiv>
  );
};

export default DropdownCheck;