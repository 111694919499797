import React, { useState, useEffect, useContext } from "react";
import * as S from "./Box.style";
import { useTranslation } from "react-i18next";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import { ThemeContext } from 'styled-components';
import { getHexOpacity } from "../../../../../utils/pipes";

// Assets
const userDefaultImage = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaIcons/sintoma-bien-on.svg`;

function Box({ 
  patient, 
  time, 
  timeModified,
  dateModified,
  date="02 Ago. 2020", 
  report, 
  description, 
  type=1, 
  buttonText,
  patientPhoto,
  levelReport=1,
  completeBox,
}) {
  const [t] = useTranslation("global");
  const [cardStyles, setColors] = useState({});
  const theme = useContext(ThemeContext);

  const levels = [
    {
      id: 1,
      name: t("globally.mild"),
      primaryColor: theme.primaryCardMildReport,
      secondaryColor: theme.secondaryCardMildReport+getHexOpacity(0.55),
      onCard: theme.onCardMildReport,
      onCardGrey: theme.onCardGreyMildReport,
      // boxShadow: theme.boxShadowMildReport
    },
    {
      id: 2,
      name: t("globally.medium"),
      primaryColor: theme.primaryCardAvarageReport,
      secondaryColor: theme.secondaryCardAvarageReport+getHexOpacity(0.55),
      onCard: theme.onCardAvarageReport,
      onCardGrey: theme.onCardGreyAvarageReport,
      // boxShadow: theme.boxShadowAvarageReport
    },
    {
      id: 3,
      name: t("globally.severe"),
      primaryColor: theme.primaryCardSeriousReport,
      secondaryColor: theme.secondaryCardSeriousReport+getHexOpacity(0.55),
      onCard: theme.onCardSeriousReport,
      onCardGrey: theme.onCardGreySeriousReport,
      // boxShadow: theme.boxShadowSeriousReport
    },
    {
      id: 4,
      name: t("globally.comments"),
      primaryColor: theme.primaryCardComment,
      secondaryColor: theme.secondaryCardComment+getHexOpacity(0.55),
      onCard: theme.onCardComment,
      onCardGrey: theme.onCardGreyComment,
      // boxShadow: theme.boxShadowComment
    },
  ]

  useEffect(() => {
    let cardStyle = {
      primaryColor: 'grey', 
      secundaryColor: 'black', 
      icon:'',
      title: ''
    };
    switch (type) {
      // symptoms
      case 1: 
        cardStyle.primaryColor = theme.secondarySectionSypmtoms;
        cardStyle.secundaryColor = theme.secondarySectionSypmtoms;
        cardStyle.icon = 'zaia-icono-sintomas';
        cardStyle.title = t("dashboard.boxes.symptom.title");
        break;
      // medicines
      case 2: 
        cardStyle.primaryColor = theme.secondarySectionMedicines;
        cardStyle.secundaryColor = theme.secondarySectionMedicines;
        cardStyle.icon = 'zaia-icono-medicina';
        cardStyle.title = t("dashboard.boxes.medicine.title");
        break;
      // vital signs 
      case 3: 
        cardStyle.primaryColor = theme.secondarySectionVitalSigns;
        cardStyle.secundaryColor = theme.secondarySectionVitalSigns;
        cardStyle.icon = 'zaia-icono-signos-vitales';
        cardStyle.title = t("dashboard.boxes.vitalSigns.title");
        break;
      // profile
      case 4: 
        cardStyle.primaryColor = theme.secondarySectionProfile;
        cardStyle.secundaryColor = theme.secondarySectionProfile;
        cardStyle.icon = 'zaia-icono-perfil';
        cardStyle.title = t("dashboard.boxes.profile.title");
        break;   
      // calendar   
      case 5: 
        cardStyle.primaryColor = theme.secondarySectionCalendar;
        cardStyle.secundaryColor = theme.secondarySectionCalendar;
        cardStyle.icon = 'zaia-icono-calendario';
        cardStyle.title = t("dashboard.boxes.calendar.title");
        break;
      default: break;
    }
    setColors(cardStyle);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  function getBoxSubtitle(type, description, t) {
    switch (type) {
      case 1:
        return (
          <p className="box__userInfoSubtitle">
            {t("dashboard.boxes.symptom.text1")} 
              {" "}
                <strong>{description}</strong>
              {" "}
            {t("dashboard.boxes.symptom.text2")}
          </p>
        );
      case 2:
        return (
          <p className="box__userInfoSubtitle">
            {t("dashboard.boxes.medicine.text")} 
            {" "}
            <strong>{description}.</strong>
          </p>
        );
      case 3:
        return (
          <p className="box__userInfoSubtitle">
            {t("dashboard.boxes.vitalSigns.text")} 
            {" "}
            <strong>{description}.</strong>
          </p>
        );
      case 4:
        return (
          <p className="box__userInfoSubtitle">
            {t("dashboard.boxes.profile.text")}
          </p>
        );
      case 5:
        return (
          <p className="box__userInfoSubtitle">
            {t("dashboard.boxes.calendar.text")} 
            {" "}
            <strong>{description}.</strong>
          </p>
        );
      default:
        return null;
    }
  }

  return (
    <S.Box 
      type={type}
      typeBoxColor={cardStyles.secundaryColor}
      primaryColor={levels[`${levelReport-1}`].primaryColor}
      secundaryColor={levels[`${levelReport-1}`].secondaryColor}
      // onCard={levels[`${levelReport-1}`].onCard}
      // onCardGrey={levels[`${levelReport-1}`].onCardGrey}
      boxShadow={levels[`${levelReport-1}`].boxShadow}
      completeBox={completeBox}
    >
      <div className="box__header">
        <div className="box__headerProfile">
          <div className="box__headerIcon">
            <div className={'icon ' + cardStyles.icon}></div>
          </div>
          <div className="box__headerTitle">
            {cardStyles.title}
            <p className="box__headerDate">{time}</p>
          </div>
        </div>
        <div className="box__headerTime">
          <p>{date}</p>
        </div>
      </div>
      <div className="box__userInfo">
        {
          type === 4 || type === 5 ? null :
          <div className="box__userInfoHeader">
            <div>
              <ZaiaImage
                size={25}
                img={patientPhoto}
                defaultImg={userDefaultImage}
                rounded={true}
              />
              <p>{patient}</p>
            </div>
            <button>{levels[`${levelReport-1}`].name}</button>
          </div>
        }
        { 
          levelReport === 4 ?
          <p className="box__userInfoSubtitle">{
            t("dashboard.boxes.comment.text") 
            + ": "}
            <strong>{description}{type === 1 && dateModified && timeModified ? '' : '.'}</strong>
            {
              type === 1 && dateModified && timeModified
              ? <>
                {" " + t("dashboard.boxes.comment.textDate") + " "}
                <strong>{dateModified}</strong>
                {" " + t("dashboard.boxes.comment.textTime") + " "}
                <strong>{timeModified}</strong>
              </>
              : ''
            }
          </p> :
          getBoxSubtitle(type, description, t)
        }
        {type === 4 ? <button className="skyBlueBox__btn">{buttonText}</button> : null}
      </div>
    </S.Box>
  );
}

export default Box;
