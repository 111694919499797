import { gql } from "@apollo/client";

export const SUBSCRIPTION_FEELINGS_COMMENT_REPORTS = gql`
  subscription SUBSCRIPTION_FEELINGS_COMMENT_REPORTS(
    $initialDate: timestamptz, 
    $finalDate: timestamptz,
    $processId: [Int!]
  ) {
    userToFeeling(
      where: {
        date: {
          _gte: $initialDate, 
          _lte: $finalDate
        }, 
        description: {
          _is_null: false
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      date
      description
      id
      levelFeeling
      userId
      modifiedDate
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const SUBSCRIPTION_FEELINGS_COMMENT_REPORTS_BY_USER = gql`
  subscription SUBSCRIPTION_FEELINGS_COMMENT_REPORTS_BY_USER(
    $initialDate: timestamptz, 
    $finalDate: timestamptz, 
    $userId: uuid,
    $processId: [Int!]
  ) {
    userToFeeling(
      where: {
        date: {
          _gte: $initialDate,
          _lte: $finalDate
        }, 
        userId: {
          _eq: $userId
        }, 
        description: {
          _is_null: false
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      date
      description
      id
      levelFeeling
      userId
      modifiedDate
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`