import styled from "styled-components";

export const MedicationSelector = styled.div`
	height: 100%;
	.title {
		color: #3E3DA3;
		font-family: "Nunito Sans";
		font-size: 20px;
		padding: 20px 10px;
	}
	.medication-list {
    height: calc(100% - 68px);
		overflow: auto;
    &__container {
      padding: 20px 10px 0 10px;
      .medication-name {
        color: #818298;
        font-family: "Nunito Sans";
        font-size: 17px;
        padding: 10px 0;
        cursor: pointer;
        transition: 0.3s ease;
        &:not(:last-child) {
          margin-bottom: 20px;
        }
        &--selected {
          color: #3E3DA3;
          font-family: "Nunito Sans Bold";
          font-size: 24px;
        }
      }
    }
  }
`