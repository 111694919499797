import React from "react";
import { useField } from "formik";
import { CustomInputDiv } from "./style";

function CustomTextInput({ placeholder, icon, setErrors, ...props }) {
  const [field, meta] = useField(props);

  return (
    <>
      {
        props.name === "phone" && (
          <CustomInputDiv>
            <div className="customTextInput__number">
              <div className={`icon ${icon}`}></div>
              <input placeholder={placeholder} {...field} {...props} />
            </div>
            {meta.touched && meta.error ? (
              <div className="error">{meta.error}</div>
            ) : null}
          </CustomInputDiv>
        ) 
      }
      { setErrors ? (
          <CustomInputDiv>
            <div className="customTextInput__input">
              <div className={`icon ${icon}`}></div>
              <input
                onFocus={() => setErrors(false)}
                placeholder={placeholder}
                {...field}
                {...props}
              />
            </div>

            {meta.touched && meta.error ? (
              <div className="error">{meta.error}</div>
            ) : null}
          </CustomInputDiv>
        ) : (
          <CustomInputDiv>
            <div className="customTextInput__input">
              <div className={`icon ${icon}`}></div>
              <input placeholder={placeholder} {...field} {...props} />
            </div>

            {meta.touched && meta.error ? (
              <div className="error">{meta.error}</div>
            ) : null}
          </CustomInputDiv>
        )
      }
    </>
  );
}

export default CustomTextInput;
