/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalSendSurvey.style";
import * as P from "../../../../../utils/pipes";

// graphQL
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

// Hooks
import ZaiaReaction from "../../../../../pages/zaiaComponents/zaiaReaction";
import ZaiaButton from "../../../../../pages/zaiaComponents/zaiaButton";
import ZaiaModalResponseContent from "../../../../../pages/zaiaComponents/zaiaModalResponseContent";

import SurveySearcher from "./components/surveySearcher";
import SurveyFrecuency from "./components/surveyFrecuency";
import SurveyRange from "./components/surveyRange";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../graphql/queries/Process";
import SurveyList from "./components/surveyList";
import SurveyPatients from "./components/surveyPatients";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import { QUERY_GET_SURVEYS_BY_ID_PROCESS, QUERY_GET_SURVEY_AND_FREQUENCY_BY_USER } from "../../../../../graphql/queries/Surveys";
import { MUTATION_INSERT_USER_TO_CUSTOM_FORM_FRECUENCY, MUTATION_UPDATE_USER_TO_CUSTOM_FORM_FRECUENCY } from "../../../../../graphql/mutations/Surveys";
import SurveyPreview from "./components/surveyPreview";
import useDoctorData from "../../../../../hooks/useDoctorData";
import usePatientList from "../../../../../hooks/usePatientList";
import { QUERY_GET_CUSTOM_FORMS_BY_ID } from "../../../../../graphql/queries/CustomForm";
import GenericTemplate from "../../../outcomes/components/outcomesComponents/shared/genericTemplate";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function ModalSendSurvey({ 
  surveyForPreview,
  processId,
  patientId,
  setFormSuccess,
  setLoadingData
}) {

  const { t, i18n: { language } } = useTranslation("global");

  const { data: processData } = useQuery( QUERY_GET_PROCESS_NAMES );

  const { doctorData } = useDoctorData();
  const [summaryView, setSummaryView] = useState(false);
  const [previewSurveyView, setPreviewSurveyView] = useState(false);

  const [getSurveyAndFrequencies, { data: surveyData } ]= useLazyQuery( QUERY_GET_SURVEY_AND_FREQUENCY_BY_USER );
  const [insertUserToCustomFrecuency, { loading: insertUserToCustomFrecuencyLoading }] = useMutation(MUTATION_INSERT_USER_TO_CUSTOM_FORM_FRECUENCY);
  const [updateUserToCustomFrecuency, { loading: updateUserToCustomFrecuencyLoading }] = useMutation(MUTATION_UPDATE_USER_TO_CUSTOM_FORM_FRECUENCY);

  useEffect(() => {
    if(surveyForPreview) {
      setPreviewSurveyView(surveyForPreview);
    }
  }, [surveyForPreview])

  const surveyFormInitial = {
    program: {
      value: null,
      text: ""
    },
    survey: {
      value: null,
      text: "",
    },
    patientsList: {
      value: [],
      text: ""
    },
    withFrequency: {
      value: true,
      text: ""
    },
    interval: {
      value: null,
      text: ""
    },
    reminderHour: {
      value: null,
      text: ""
    },
    rangeStart: {
      value: "",
      text: ""
    },
    rangeFinish: {
      value: "",
      text: ""
    }
  }

  const surveyInputs = [
    {
      id: 1,
      placeholder: t("globally.selectProgram"),
      icon: "zaia-add-book",
      form: ["program"],
      dependent: []
    },
    {
      id: 2,
      placeholder: t("surveys.sentNewSurvey"),
      icon: "zaia-i-check",
      form: ["survey"],
      dependent: ["program"]
    },
    {
      id: 3,
      placeholder: t("globally.selectPatients"),
      icon: "zaia-i-person",
      form: ["patientsList"],
      dependent: ["program"]
    },
    {
      id: 4,
      placeholder: t("globally.frequency"),
      icon: "zaia-i-frequency",
      form: ["withFrequency", "interval", "reminderHour"],
      dependent: []
    },
    {
      id: 5,
      placeholder: t("globally.range"),
      icon: "zaia-i-time",
      form: ["rangeStart", "rangeFinish"],
      dependent: ["withFrequency"]
    }
  ]

  const frequenciesList = [
    {
      value: 1,
      data: {
        number: 1,
        type: "day"
      },
      text: `${t("globally.every")} ${t("globally.day")}` 
    },
    {
      value: 2,
      data: {
        number: 2,
        type: "day"
      },
      text: `${t("globally.every")} 2 ${t("globally.days")}` 
    },
    {
      value: 3,
      data: {
        number: 4,
        type: "day"
      },
      text: `${t("globally.every")} 4 ${t("globally.days")}` 
    },
    {
      value: 4,
      data: {
        number: 1,
        type: "week"
      },
      text: `${t("globally.every")} ${t("globally.week")}` 
    },
    {
      value: 5,
      data: {
        number: 2,
        type: "day"
      },
      text: `${t("globally.every")} 2 ${t("globally.weeks")}` 
    },
    {
      value: 6,
      data: {
        number: 1,
        type: "month"
      },
      text: `${t("globally.every")} ${t("globally.month")}` 
    },
    {
      value: 7,
      data: {
        number: 2,
        type: "month"
      },
      text: `${t("globally.every")} 2 ${t("globally.month")}` 
    },
    {
      value: 8,
      data: {
        number: 3,
        type: "month"
      },
      text: `${t("globally.every")} 3 ${t("globally.month")}` 
    },
  ]

  const [surveyForm, setSurveyForm] = useState(surveyFormInitial);
  const [inputSelected, setInputSelected] = useState(surveyInputs[0])
  const [processIdSelected, setProcessIdSelected] = useState(processId);
  const [patientIdSelected, setPatientIdSelected] = useState(patientId);

  const { data: surveysData, loading: surveysDataLoading } = useQuery(QUERY_GET_SURVEYS_BY_ID_PROCESS, {
    variables: {
      processId: processIdSelected
    }
  })

  useEffect(() => {
    setLoadingData(surveysDataLoading || insertUserToCustomFrecuencyLoading || updateUserToCustomFrecuencyLoading);
  }, [surveysDataLoading, insertUserToCustomFrecuencyLoading, updateUserToCustomFrecuencyLoading])
  
  const goNextInput = () => {
    if(inputSelected.id < surveyInputs.length - 1) {
      setInputSelected(surveyInputs[inputSelected.id])
    }
  }  

  const changeFormField = (changesList) => {
    const surveyFormTemp = JSON.parse(JSON.stringify(surveyForm));
    for (const change of changesList) {
      if(change.fieldName === "program") {
        setProcessIdSelected(change.value)
      }
      if(surveyFormTemp[change.fieldName]) {
        surveyFormTemp[change.fieldName].value = change.value;
        surveyFormTemp[change.fieldName].text = change.text;
      }
    }
    setSurveyForm(surveyFormTemp);
  }
  
  const handleSubmit = async () => { 
    if(previewSurveyView) {
      setPreviewSurveyView(null);
    }  else if(summaryView === false) {
      setSummaryView(true);
    } else {
      inserUserToCustomFormFrecuency();
    }
  };

  const getUTCTime = (time) => {
    let currentDate = new Date();

    // Extract hours, minutes, and seconds from the input time
    const [hours, minutes, seconds] = time.split(':');
    console.log("currentDate", time);
    console.log("currentDate", hours, minutes, seconds);
    
    // Set the time on the current date
    currentDate.setHours(parseInt(hours, 10));
    currentDate.setMinutes(parseInt(minutes, 10));
    currentDate.setSeconds(seconds ? parseInt(seconds, 10) : 0);
    console.log("currentDate", currentDate);
    // Convert to UTC and format the result
    const utcTime = currentDate.toISOString().slice(11, 19);

    return utcTime;
  }

  const inserUserToCustomFormFrecuency = async () => {
    const form = await surveyForm;

    const surveysDataToInsert = [];
    const surveysDataToUpdate = [];

    const {data: surveysAndFrequencies} = await getSurveyAndFrequencies({
      variables: {
        customFormId: form.survey.value,
      }
    });

    form.patientsList.value.forEach(async (patient) => {

      const data = {
        customFormId: form.survey.value, 
        doctorId: doctorData.id, 
        userId: patient.value,
        IsComplete: false
      }

      if(form.withFrequency.value === false) {
        const surveyDate = new Date(form.interval.value + "T" + form.reminderHour.value);
        data.expectedDateAnswer = surveyDate;
      } else {
        const surveyDate = new Date(form.rangeStart.value + "T" + form.reminderHour.value);
        data.startDateTime = new Date(form.rangeStart.value);
        data.endDateTime = new Date(form.rangeFinish.value);
        data.frequency = frequenciesList.find((frequency) => frequency.value === form.interval.value).data.number;
        data.frequencyType = frequenciesList.find((frequency) => frequency.value === form.interval.value).data.type;
        data.expectedDateAnswer = surveyDate;
      }

      if(surveysAndFrequencies?.customForm[0].customFormFrequency.find((frequency) => frequency.userId === patient.value)) {
        const dataToUpdate = {
          where: {
            userId: {_eq: data.userId}, 
            customFormId: {_eq: data.customFormId}, 
            doctorId: {_eq: data.doctorId}
          }, 
          _set: data
        }
        surveysDataToUpdate.push(dataToUpdate);
      } else {
        surveysDataToInsert.push(data);
      }
    })
    
    try {
      await insertUserToCustomFrecuency({
        variables: {
          objects: surveysDataToInsert
        }
      })
      await updateUserToCustomFrecuency({
        variables: {
          updatesData: surveysDataToUpdate
        }
      })
      setFormSuccess(true);
    } catch (err) {
      setFormSuccess(false);
      console.log(err);
    }
  }

  const inputsVerification = useMemo(() => {

    let verification = true;
  
    surveyInputs.forEach((input) => {      
      if(input.form.filter((form) =>  surveyForm[form].value !== null).length !== input.form.length) { 
        verification = false;
      };
    })

    return verification;
    
  }, [surveyInputs, surveyForm])

  const props = useMemo(() => {

    let bgColor = "#E0E6ED";
    let icon = "zaia-i-lock-chat";
    let color = "#8A939D";
    let title = t("globally.createAlert");
    let disabled = true;

    if(previewSurveyView) {
      bgColor = "#3E3DA3";
      icon = "";
      color = "#FFFFFF";
      title = "Close Preview";
      disabled = false;
        // title = t("surveys.preview");
    } else {
      if(inputsVerification) {
        bgColor = "#17AF54";
        icon = "";
        color = "#FFFFFF";
        disabled = false;
      }
  
      if(summaryView === false) {
        title = t("globally.done");
      }
    }

    return (
      {
        backgroundColor: bgColor,
        icon: icon,
        color: color,
        title: title,
        disabled: disabled
      }
    )
  }, [inputsVerification, summaryView, previewSurveyView])

  const footerMsg = () => {
    return previewSurveyView ? "" : t("surveys.sendSurveyModal.fillSections");
  }

  const getDinamicInputsViews = () => {

      return(
        <div className="custom-form-inputs">
          {
            <div className={`medication-inputs ${inputSelected.id === 1 && 'medication-inputs--selected'}`}>
              <SurveySearcher
                selectedProcess={surveyForm.program}
                changeFormField={changeFormField}
                processId={processId}
                processList={
                  processData?.process.filter((process) => process.processToCustomForms.length > 1).map( process => {
                    return (
                      { value: process.id, text: P.dataTranslation(process.processName, language)}
                    )
                  }).sort(function(a,b){
                    return a.text.localeCompare(b.text);
                  })
                }
                goNextInput={goNextInput}
              />
            </div>
          }
          {
            <div className={`medication-inputs ${inputSelected.id === 2 && 'medication-inputs--selected'}`}>
              <SurveyList
                selectedSurvey={surveyForm.survey}
                selectedProcess={surveyForm.program}
                changeFormField={changeFormField}
                surveyList={
                  surveysData?.customForm.map((survey) => {
                    return (
                      {
                        id: survey.id,
                        text: P.dataTranslation(survey.customFormName, language),
                        questionsNumber: survey.formGroups.length
                      }
                    ) 
                  })
                }
                goNextInput={goNextInput}
                setPreviewSurveyView={setPreviewSurveyView}
              />
            </div>
          }
          {
            <div className={`medication-inputs ${inputSelected.id === 3 && 'medication-inputs--selected'}`}>
              <SurveyPatients
                selectedPatientsList={surveyForm.patientsList}
                selectedProcess={surveyForm.program}
                changeFormField={changeFormField}
                processId={processIdSelected}
                patientId={patientIdSelected}
              />
            </div>
          }
          {
            <div className={`medication-inputs ${inputSelected.id === 4 && 'medication-inputs--selected'}`}>
              <SurveyFrecuency
                selectedWithFrequency={surveyForm.withFrequency}
                selectedInterval={surveyForm.interval}
                selectedReminderHour={surveyForm.reminderHour}
                frequenciesList={frequenciesList}
                changeFormField={changeFormField}
                goNextInput={goNextInput}
              />
            </div>
          }
          {
            <div className={`medication-inputs ${inputSelected.id === 5 && 'medication-inputs--selected'}`}>
              <SurveyRange
                selectedRangeStart={surveyForm.rangeStart}
                selectedRangeFinish={surveyForm.rangeFinish}
                changeFormField={changeFormField}
              />
            </div>
          }
        </div>
      )
    
    
  }

  return (
    <S.ModalSendSurvey>
      <div className="rows-container">
        <div className="rows">
          {
            summaryView &&
            <SurveySummary
              surveyInputs={surveyInputs}
              surveyForm={surveyForm}
            />
          }
          {
            previewSurveyView &&
            <SurveyPreview
              previewSurveyView={previewSurveyView}
            />
          }
          {
            (!summaryView && !previewSurveyView )&&
            <>
              <div className="principal-input">
                <div className="principal-input__text">{t("surveys.sendSurveyModal.subtitle")}</div>
                <SurveyInputs
                  inputsList={surveyInputs}
                  inputSelected={inputSelected}
                  setInputSelected={setInputSelected}
                  surveyForm={surveyForm}
                  changeFormField={changeFormField}
                  setSummaryView={setSummaryView}
                />
              </div>
              {getDinamicInputsViews()}
            </>
          }
        </div>  
      </div>
      {
        !surveyForPreview && 
        <div className="footer">
          <div className="footer__text">
            {footerMsg()}
          </div>
          <div className="button-container">
            <div onClick={() => handleSubmit()}>
              <ZaiaButton
                {...props}
                type="button"
                option="primary"
                marginTop="5"
                iconSize={"20"}
                iconMargin={"5"}
                borderRadius="75"
                fontSize="14"
                height="36"
                fontFamily={"Nunito Sans"}
                shadow={false}
                border={false}
              />
            </div>
          </div>
        </div>
      }
    </S.ModalSendSurvey>
  );
}

const SurveySummary = ({
  surveyInputs,
  surveyForm,
}) => {

  const { t, i18n: { language } }= useTranslation("global");
  const {patientList} = usePatientList();

  const { 
    data: customForm
  } = useQuery(
    QUERY_GET_CUSTOM_FORMS_BY_ID,
    {
      variables: {
        customFormId: surveyForm["survey"]?.value
      },
    }
  );

  return(
    <div className="summary">
      <div className="summary__left">
        <div className="summary-title">
          {t("globally.summary")}
        </div>
        <div className="summary-items">
          {
            surveyInputs.map((input) => { 
              if(input.form?.some((form) => {return surveyForm[form]?.value})){
                return (
                  <div className="summary-item" key={input.id}>
                    <div className="summary-item__title">
                      <i className={'summary-item__icon icon ' + input.icon} />
                      <div className="summary-item__name input-text__input-name">{input.placeholder}</div>
                    </div>
                    {
                      input.id !== 2 &&
                      <div className="summary-item__info">
                        {input.form?.map((form) => {return surveyForm[form]?.text})}
                      </div>
                    }
                    {
                      input.id === 2 && 
                      <div className="summary-item__info">
                        {
                          surveyForm["patientsList"]?.value?.map(patient => {
                            return (
                              <div 
                                key={patient.id}
                                className={`symptom-box`}
                              >
                                <i class="symptom-box__icon icon zaia-i-checkbox-on"></i>
                                <ZaiaImage
                                  img={patientList?.find(item => item.id === patient.value)?.picture}
                                  alt={`${patient.text}`}
                                  size={40}
                                  rounded={true}
                                />
                                <div 
                                  className={`symptom-box__name`}
                                >
                                  {`${patient.text}`}
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    }
                  </div>
                )
              } else {
                return null
              }
            })
          }
        </div>
      </div>
      <div className="summary__right">
        <div className="survey-name">{P.dataTranslation(customForm?.customForm_by_pk.customFormName, language)}</div>
        <div className="survey-modules">
          <div className="survey-module">
            {
              customForm?.customForm_by_pk.formGroups.map((form) => {
                return(
                  <GenericTemplate
                    title={P.dataTranslation(form.customFormName, language)}
                    dataForm={form}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const SurveyInputs = ({
  inputsList, 
  inputSelected,
  setInputSelected,
  surveyForm,
  setSummaryView
}) => {

  const {t} = useTranslation("global");

  return (
    <div className="principal-input__container">      
      {
        inputsList.map((input) => {
          const disabled = input.dependent.filter((dependent) => surveyForm[dependent].value === null || surveyForm[dependent].value === false).length > 0;
          const filledVerification = input.form.filter((form) => surveyForm[form].value !== null && surveyForm[form].value.length !== 0 ).length === input.form.length && input.form.length > 0;          
          return (
            <div className="input-box__input" key={input.id} onClick={!disabled 
              ? () => {
                setInputSelected(input)
                setSummaryView(false);
              }
              : null 
            }>
              <S.InputOption
                disabled={disabled}
                selected={inputSelected?.id === input.id}
                filled={filledVerification}
              >
                <i className={'input-icon icon ' + input.icon} />
                <div className="input-text">
                  {input.placeholder}
                  <b>{input.form.map((form) => {return surveyForm[form].text})}</b>
                </div>
                {
                  !filledVerification 
                  ? <i className={'input-icon-arrow icon zaia-icono-dropdown-light'} />
                  : <i className={'input-icon-check icon zaia-i-check-1'} />
                }
                { 
                  inputSelected?.id === input.id &&
                  <div className="shape-container">
                    <div className="shape">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="32" viewBox="0 0 25 32" fill="none">
                        <path d="M0 31.5C-2.16635e-07 26.544 4.32006 22.5263 9.64912 22.5263L15.3509 22.5263C20.6799 22.5263 25 26.544 25 31.5L25 0.499999C25 5.45603 20.6799 9.47368 15.3509 9.47368L9.64912 9.47368C4.32006 9.47368 -1.13842e-06 5.45603 -1.35505e-06 0.5L0 31.5Z" fill="#6C6BCC"/>
                      </svg>
                    </div>
                  </div>
                }
              </S.InputOption>
              {
                disabled && input.id === 5 &&
                <div className="warning-dependent-msg">
                  {t("surveys.sendSurveyModal.rangeDisabled")}
                </div>
              }
            </div>
          )
        })
      } 
    </div>
  )
}


export default ModalSendSurvey;
