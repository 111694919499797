import { gql } from "@apollo/client";

export const QUERY_GET_VITAL_SIGNS = gql`
  query QUERY_GET_VITAL_SIGNS(
    $vitalSigns: [userVitalSignHistory_bool_exp]
    $initialDate: timestamptz
    $finalDate: timestamptz
  ) {
    userVitalSignHistory(where: {_or: $vitalSigns, created_at: {_gte: $initialDate, _lte: $finalDate}}) {
      id
      measure1
      measure2
      vitalSign
      created_at
      unit
      vitalSignVal {
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const QUERY_GET_VITAL_SIGNS_BY_USER = gql`
  query QUERY_GET_VITAL_SIGNS_BY_USER(
    $vitalSigns: [userVitalSignHistory_bool_exp]
    $initialDate: timestamptz
    $finalDate: timestamptz
    $userId: uuid
  ) {
    userVitalSignHistory(
      where: {
        _or: $vitalSigns, 
        created_at: {
          _gte: $initialDate, 
          _lte: $finalDate
        }, 
        userId: {
          _eq: $userId
        }
      }
    ) {
      id
      measure1
      measure2
      vitalSign
      created_at
      unit
      vitalSignVal {
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`
export const QUERY_GET_VITAL_SIGNS_BY_USER_COUNTER = gql`
  query QUERY_GET_VITAL_SIGNS_BY_USER_COUNTER(
    $vitalSigns1: [userVitalSignHistory_bool_exp]
    $vitalSigns2: [userVitalSignHistory_bool_exp]
    $vitalSigns3: [userVitalSignHistory_bool_exp]
    $initialDate: timestamptz
    $finalDate: timestamptz
  ) {
    user(where: {userToProcesses: {isAcepted: {_eq: true}}}) {
      id
      name
      lastname
      documentNumber
      picture
      phone
      weight
      height
      birthdate
      phoneCarer
      nameCarer
      email
      phoneCountry {
        phonecode
      }
      carerPhoneCountry {
        phonecode
      }
      userToProcesses {
        id
        nextUpdate
        stageId
        processStage {
          id
          descript
          expirationTime
          isAutomatic
          nextStageId
          processId
          stageName
          frequency
          frequencyType
        } 
        userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
          created_at
          customFormFieldChanges
          date
          descript
          doctorId
          id
          isChecked
          latestCustomFormId
          processId
          stageId
          userToProcessId
        }
        process {
          id
          processName
          processToCustomForms(order_by: {customFormId: asc}) {
            customFormId
            id
            processId
          }
        }
      }
      vitalSigns1Count: userVitalSignHistories_aggregate(where: {_or: $vitalSigns1, created_at: {_gte: $initialDate, _lte: $finalDate}}) {
        aggregate {
          count
        }
      },
      vitalSigns2Count: userVitalSignHistories_aggregate(where: {_or: $vitalSigns2, created_at: {_gte: $initialDate, _lte: $finalDate}}) {
        aggregate {
          count
        }
      },
      vitalSigns3Count: userVitalSignHistories_aggregate(where: {_or: $vitalSigns3, created_at: {_gte: $initialDate, _lte: $finalDate}}) {
        aggregate {
          count
        }
      }
    }
  }
`

export const QUERY_GET_VITAL_SIGNS_BY_USER_COUNTER_ID = gql`
  query QUERY_GET_VITAL_SIGNS_BY_USER_COUNTER(
    $vitalSigns1: [userVitalSignHistory_bool_exp]
    $vitalSigns2: [userVitalSignHistory_bool_exp]
    $vitalSigns3: [userVitalSignHistory_bool_exp]
    $initialDate: timestamptz
    $finalDate: timestamptz
    $userId: uuid
  ) {
    user(where: {id: {_eq: $userId}, userToProcesses: {isAcepted: {_eq: true}}}) {
      id
      name
      lastname
      documentNumber
      picture
      phone
      weight
      height
      birthdate
      phoneCarer
      nameCarer
      email
      phoneCountry {
        phonecode
      }
      carerPhoneCountry {
        phonecode
      }
      userToProcesses {
        id
        nextUpdate
        stageId
        processStage {
          id
          descript
          expirationTime
          isAutomatic
          nextStageId
          processId
          stageName
          frequency
          frequencyType
        } 
        userToProcessHistories(order_by: {created_at: desc}, where: {isChecked: {_eq: true}}) {
          created_at
          customFormFieldChanges
          date
          descript
          doctorId
          id
          isChecked
          latestCustomFormId
          processId
          stageId
          userToProcessId
        }
        process {
          id
          processName
          processToCustomForms(order_by: {customFormId: asc}) {
            customFormId
            id
            processId
          }
        }
      }
      vitalSigns1Count: userVitalSignHistories_aggregate(where: {_or: $vitalSigns1, created_at: {_gte: $initialDate, _lte: $finalDate}}) {
        aggregate {
          count
        }
      },
      vitalSigns2Count: userVitalSignHistories_aggregate(where: {_or: $vitalSigns2, created_at: {_gte: $initialDate, _lte: $finalDate}}) {
        aggregate {
          count
        }
      },
      vitalSigns3Count: userVitalSignHistories_aggregate(where: {_or: $vitalSigns3, created_at: {_gte: $initialDate, _lte: $finalDate}}) {
        aggregate {
          count
        }
      }
    }
  }
`


export const QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS = gql`
  query QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS(
    $initialDate: timestamptz
    $finalDate: timestamptz
    $processId: [Int!]
  ) {
    userVitalSignHistory(
      where: {
        created_at: {
          _gte: $initialDate, 
          _lte: $finalDate
        }, 
        isTaken: {
          _eq: false
        }, 
        comment: {
          _is_null: false
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      id
      measure1
      measure2
      vitalSign
      created_at
      unit
      comment
      vitalSignVal {
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER = gql`
  query QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER(
    $initialDate: timestamptz
    $finalDate: timestamptz
    $userId: uuid
    $processId: [Int!]
  ) {
    userVitalSignHistory(
      where: {
        created_at: {
          _gte: $initialDate, 
          _lte: $finalDate
        }, 
        userId: {
          _eq: $userId
        }, 
        isTaken: {
          _eq: false
        }, 
        comment: {
          _is_null: false
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      id
      measure1
      measure2
      vitalSign
      created_at
      unit
      comment
      vitalSignVal {
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER_NO_DATES = gql`
  query QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER_NO_DATES(
    $userId: uuid
    $processId: [Int!]
  ) {
    userVitalSignHistory(
      where: { 
        userId: {
          _eq: $userId
        }, 
        isTaken: {
          _eq: false
        }, 
        comment: {
          _is_null: false
        },
        user: {
          userToProcesses: {
            processId: {
              _in: $processId
            }
          }
        }
      }
    ) {
      id
      measure1
      measure2
      vitalSign
      created_at
      unit
      comment
      isTaken
      vitalSignVal {
        comment
      }
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`

export const QUERY_GET_VITAL_SIGNS_WEIGHT = gql`
  query QUERY_GET_VITAL_SIGNS_WEIGHT($userId: uuid) {
    userVitalSignHistory(where: {userId: {_eq: $userId}, vitalSign: {_eq: "weight"}}, order_by: {created_at: desc}, limit: 2) {
      id
      measure1
      measure2
      vitalSign
      created_at
      unit
      vitalSignVal {
        comment
        value
      }
    }
  }
`

export const QUERY_VITAL_SIGNS_AVERAGE = gql`
  query QUERY_VITAL_SIGNS_AVERAGE(
    $patientId: uuid 
    $processId: Int 
    $startDate: timestamptz
  ) {
    processToVitalSign(where: {processId: {_eq: $processId}}) {
      id
      processId
      min
      max
      enVitalSign {
        comment
        value
        userVitalSignHistories_aggregate(where: {userId: {_eq: $patientId}, created_at: {_gte: $startDate}}) {
          aggregate {
            avg {
              measure1
            }
          }
        }
      }
    }
  }
`

export const QUERY_GET_USER_VITAL_SIGNS = gql`
  query QUERY_GET_USER_VITAL_SIGNS(
    $patientId: uuid
    $startDate: timestamptz
    $finishDate: timestamptz
  ) {
    userToProcess(where: { user: { id: { _eq: $patientId } } }) {
      user {
        userToVitalSigns {
          id
          frequency
          frequencyType
          startHour
          unit
          vitalSignVal {
            comment
            value
            userVitalSignHistories(
              where: {
                _and: { created_at: { _gte: $startDate, _lte: $finishDate } }
              }
              order_by: { created_at: asc }
            ) {
              id
              measure1
              measure2
              unit
              created_at
            }
          }
        }
      }
    }
  }
`;

export const QUERY_GET_USER_VITAL_SIGNS_SIMPLE = gql`
  query QUERY_GET_USER_VITAL_SIGNS_SIMPLE(
    $patientId: uuid
    $startDate: timestamptz
    $finishDate: timestamptz
  ) {
    enVitalSign {
      comment
      value
      userVitalSignHistories(
        where: {
          _and: { created_at: { _gte: $startDate, _lte: $finishDate } }
          user: { id: { _eq: $patientId } }
        }
        order_by: { created_at: desc }
      ) {
        id
        measure1
        measure2
        unit
        created_at
        userId
      }
    }
  }
`;

export const QUERY_GET_USER_VITAL_SIGNS_SIMPLE_NO_DATES = gql`
  query QUERY_GET_USER_VITAL_SIGNS_SIMPLE_NO_DATES {
    enVitalSign {
      comment
      value
      userVitalSignHistories(
        order_by: { created_at: desc }
      ) {
        id
        measure1
        measure2
        unit
        created_at
        userId
        isTaken
        comment
      }
    }
  }
`;



export const QUERY_GET_VITAL_SIGNS_VALUES = gql`
  query QUERY_GET_VITAL_SIGNS_VALUES {
    enVitalSign {
      comment
      value
    }
    enFrequencyType {
      comment
      value
    }
  }
`;


export const QUERY_GET_USER_TO_VITAL_SIGNS = gql`
  query QUERY_GET_USER_TO_VITAL_SIGNS(
    $patientId: uuid
  ) {
    userToVitalSign(where: { userId: { _eq: $patientId } }) {
      id
      frequency
      frequencyType
      startHour
      unit
      vitalSignVal {
        comment
        value
      }
    }
  }
`;