import styled from "styled-components";

export const ProfileDiv = styled.div`
  /* display: flex;
  height: 100%;
  width: 100%;
  background-color: var(--white);
  .profile__left-side {
    height: 100%;
    width: 100%;
  } */
  /* .profile__right-side {
    height: 100%;
    max-width: 400px;
    width: 100%;
    border-left: #E3E3E3 1px solid;
  } */

  border-radius: 15px;
  margin: 0 31px 53px 31px;
  background-color: #F4F4FF;
  height: calc(100% - 53px); 
  padding: 20px 90px 0px 90px;
  .profile-tabs {
    background-color: #fff;
    display: flex;
    width: 100%;
    padding: 10px 20px 20px 20px;
    border-radius: 14px;
    background: #FEFEFF;
    box-sizing: border-box;
    .profile-tab {
      display: flex;
      min-width: 206px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      color: #36369B;
      font-family: "Nunito Sans";
      font-size: 17px;
      display: flex;
      cursor: pointer;
      border-bottom: 3px solid transparent;
      &__icon {
        font-size: 20px;
        margin-right: 10px;
        color: #8F8FBB;
      }
      &--active {
        font-family: "Nunito Sans Bold";
        border-bottom: 3px solid #B3B3F2;
      }
    }
  }
  .profile-content {
    height: calc(100% - 76px) ;
  }
`;
