import styled from "styled-components";

export const AiNavigatorSurveys = styled.div`
  padding: 10px 20px 0px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .header-options {
    &__section {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 17px;
    }
    .principal-selects-container {
      background-color: #F0F0FF;
      border-radius: 12px;
      padding: 10px;
      display: flex;
      .select-container {
        &__label {
          font-family: Nunito Sans;
          font-size: 15px;
          margin-left: 12px;
        }
        &:not(:last-child) {
          margin-right: 10px;
        }
        &--survey {
          width: 296px;
        }
      }
    }
    .update-outcome-button {
      margin-left: 10px;
      &__button {
        display: flex;
        padding: 6.5px 10px;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: #36369B;
        color: #FFF;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-style: normal;
        outline: none;
        border: none;
        cursor: pointer;
      }
      &__icon {
        font-size: 10px;
        margin-left: 5px;
      }
    }
    .filter-buttons-container {
      display: flex;
      align-items: center;
      .highlights-button {
        margin-right: 10px;
        cursor: pointer;
        display: flex;
        border: 1px solid #AAAADB;
        align-items: center;
        padding: 8.25px 8.5px;
        border-radius: 60px;
        color: #36369B;
        &--active {
          padding: 8.25px 8.5px;
          color: white;
          border: none;
          background-color: #FFA800;
        }
      }
      .event-type-select {
        .input-container {
          width: 270px;
        }
      }
    }
  }
  .reaction {
    margin: auto;
  }
  .process-tabs {
    &__title {
      font-family: Nunito Sans Bold;
      margin-top: 10px;
      font-size: 18px;
      color: #68688D;
    }
    &__process-name {
      font-family: Nunito Sans Bold;
      font-size: 28.15px;
      color: #68688D;
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      .process-tab {
        border-radius: 10px;
        background: #F0F0FF;
        display: flex;
        padding: 10px;
        justify-content: center;
        color: #6C6BCC;
        text-shadow: 0px 0px 6px rgba(247, 246, 255, 0.69);
        font-family: "Nunito Sans";
        font-size: 17px;
        margin-right: 10px;
        cursor: pointer;
        &--selected {
          color: #36369B;
          font-family: "Nunito Sans Bold";
          font-size: 17px;
          border-radius: 10px;
          background: #D2D2FF;
          box-shadow: 0px 4px 18.5px -3px rgba(0, 0, 255, 0.06) inset;
        }
      }
    }
  }
  .no-surveys {
    margin-top: 100px;
  }
  .outcomes-content{
    margin-top: 20px;
  }

`
export const PopUpSurveysList = styled.div`
  background-color: #FFFFFF;
  padding: 5px;
  border-radius: 7px;
  cursor: default;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  .survey-name {
    font-size: 17px;
    font-family: "Nunito Sans Light";
    color: #36369B;
    cursor: pointer;
    padding: 5px 10px; 
    &:not(:last-child) {
      border-bottom: ${({theme}) => '1px solid ' + theme.lineSeparation };
    }
    .card-selected-patient-process {
      color: #526479 !important;
    }
  }
`
