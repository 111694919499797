import styled from "styled-components";

export const ChatbotGuidanceCase = styled.div` 
  height: 100%;
  .chatbot-container {
    height: ${({footerHeight, selectedPatient}) => `calc(100% - ${footerHeight}px - ${selectedPatient ? "68px" : "88px"})` };
  }
  .chatbot-selectors {
    height: ${({selectedPatient}) => selectedPatient ? "68px" : "88px"};
    .process-selects {
      height: ${(
        {
          selectedProcess, 
          selectedPartition
        }
        ) => 
          selectedProcess && selectedPartition 
          ? "0px" 
          : !selectedProcess && !selectedPartition ? "132px" : "66px"
      };
      background-color: #fff;
      position: absolute;
      width: 100%;
      margin-top: ${({selectedPatient}) => selectedPatient ? "68px" : "88px"};
      /* margin-top: 118px; */
    }
  }
`;