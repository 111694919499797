import React, { useContext } from "react";
import * as S from "./ZaiaReaction.style.js";
import { ThemeContext } from 'styled-components';

function ZaiaReaction({ 
  textColor, 
  text, 
  subText,
  zaiaImg, 
  separation, 
  widthImg, 
  widthText, 
  widthSubText, 
  sizeText,
  sizeSubText,
  sizeBetweenText,
  weightText=true,
  loadingPoints=false,
  padding
}) {
  const theme = useContext(ThemeContext);

  return (
    <S.ZaiaReaction
      textColor={textColor}
      separation={separation}
      widthImg={widthImg}
      widthText={widthText}
      widthSubText={widthSubText}
      sizeText={sizeText}
      sizeSubText={sizeSubText}
      sizeBetweenText={sizeBetweenText}
      weightText={weightText}
      loadingPoints={loadingPoints}
      padding={padding}
    >
      <img src={zaiaImg} alt={text}/>
      <div className="reaction-text">
        {
          text ? <div className="zaia-reaction-text">{text}</div> : null
        }
        {
          subText ? <div className="zaia-reaction-subtext">{subText}</div> : null
        }
        {
          loadingPoints &&
          <S.ZaiaLoadingPoints color={textColor}>
            <div className="dot-flashing"></div>
          </S.ZaiaLoadingPoints>
        }
      </div>
    </S.ZaiaReaction>
  );
}

export default ZaiaReaction;
