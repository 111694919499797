import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./Police.style";
import { useParams } from "react-router-dom";
import PatientProfile from "./components/patientProfile";
import PatientSelector from "./components/patientSelector";

function Police() {
  const [t] = useTranslation("global");
  const { patientID } = useParams();

  return (
    <S.Police>
      {
        patientID 
        ? <div className="patient-dashboard-view">
          <PatientProfile/>
        </div>
        : <div className="patient-selector-view">
          <PatientSelector />
        </div>
      }
    </S.Police>
  );
}

export default Police;
