import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./NoteText.style";
import ZaiaInput from "../../../../zaiaComponents/zaiaInput";
import Popup from "reactjs-popup";
import { useQuery } from "@apollo/client";
import { QUERY_GET_TEMPLATES_BY_PROCESS } from "../../../../../graphql/queries/Process";

import * as P from "../../../../../utils/pipes";

function NoteText({
  maxViewActived,
  editNoteActived,
  formNoteText,
  setFormNoteText,
  userToProcessSelected
}) {

  const { t,
    i18n: { language }
   } = useTranslation("global"); 

  // query to get the process template
  const { data: templatesData, loading: templatesLoading } = useQuery(QUERY_GET_TEMPLATES_BY_PROCESS,
    {
      variables: {
        processId: userToProcessSelected?.process.id
      }
    }
  );

  const setTemplate = (template) => {
    setFormNoteText(P.dataTranslation(template.content, language));
  }

  return (
    <S.NoteText
      maxViewActived={maxViewActived}
    >
      <div className="note-label">
        {
          editNoteActived &&
          <i className="note-label__icon icon zaia-icono-editar"></i>
        }
        {
          editNoteActived
          ? t("notes.editingNote")
          : t("globally.note")
        }
      </div>
      <div className="note-space-text">
        <ZaiaInput
          placeholder={t("followUp.addComment")}
          textarea
          height={"100%"}
          value={formNoteText}
          borderRadius={maxViewActived ? "14px" : "14px 14px 0 0"}
          activeBorder={false}
          padding={"20px 27px 0px"}
          fontSize={"18px"}
          getText={(e) => {
            setFormNoteText(e)
          }}
        />
        {
          templatesData && templatesData?.template.length > 0 &&
          <Popup
            trigger={
              <div className="note-space-text__templates">
                {t("notes.templates")}
                <i className="note-space-text__templates-icon icon zaia-icono-dots-horizontal"></i>
              </div>
            }
            keepTooltipInside
            nested
            position={"top center"}
          >
            {
              close => (
                <S.TemplatesOptions>
                  <div className="title">
                    {t("notes.templates")}
                  </div>
                  <div className="card-options">
                    {
                      templatesData?.template.map((template) => {
                        return (
                          <div className="card-option"
                            key={template.id}
                            onClick={() => {
                              setTemplate(template);
                              close();
                            }}
                          >
                            {P.dataTranslation(template.title, language)}
                          </div>
                        )
                      })
                    }
                  </div>
                </S.TemplatesOptions>
              )
            }
          </Popup>
        }
      </div>
    </S.NoteText>
  );
}

export default NoteText;
