import React, { useState } from "react";
import * as S from  "./PatientProfile.style";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import PatientData from "../patientData";
import PatientEdition from "../patientEdition";
import PatientReport from "../patientReport";
import { QUERY_GET_USERS_BY_USER_ID } from "../../../../../graphql/queries/User";
import { useQuery, useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_CUSTOM_FORM_BY_PROCESS } from "../../../../../graphql/subscriptions/Surveys";

function PatientProfile() {  

  const { patientID } = useParams();

  const [reportSelected, setReportSelected] = useState();
  const [patientEdition, setPatientEdition] = useState(false);
  
  const { data: patientData, error } = useQuery(QUERY_GET_USERS_BY_USER_ID, {
    variables: {
      patientId: patientID
    }
  })

  const { data: processCustomForms, error: processCustomFormsError, loading: processCustomFormsLoading } = useSubscription(SUBSCRIPTION_GET_CUSTOM_FORM_BY_PROCESS, {
    variables: { 
      userId: patientID,
      userToProcessId: patientData?.user[0]?.userToProcesses?.find((userToProcess) => userToProcess.process?.nameKey === "Police-Homeless")?.id,
      processId: patientData?.user[0]?.userToProcesses?.find((userToProcess) => userToProcess.process?.nameKey === "Police-Homeless")?.process.id
    },
    onData: ({data: {data}}) => {
      setReportSelected(data?.process[0].processToCustomForms.find((processCustomForm) => processCustomForm.id === reportSelected?.id));
    }
  });


    return (
      <S.PatientProfile>
        {
          reportSelected && 
          <PatientReport
            reportSelected={reportSelected}
            setReportSelected={setReportSelected}
            patientData={patientData?.user[0]}
          />
        }
        {
          !reportSelected &&
          <PatientData
            reportSelected={reportSelected}
            setReportSelected={setReportSelected}
            setPatientEdition={setPatientEdition}
            patientData={patientData?.user[0]}
            processCustomForms={processCustomForms}
            processCustomFormsError={processCustomFormsError}
            processCustomFormsLoading={processCustomFormsLoading}
          />
        }
        {/* {
          patientEdition && 
          <PatientEdition
            patientEdition={patientEdition}
            setPatientEdition={setPatientEdition}
            patientData={patientData?.user[0]}
          />
        } */}
      </S.PatientProfile>
    )
  }
  
  export default PatientProfile;