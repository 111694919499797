import styled from "styled-components";
import { getHexOpacity } from "../../../../../../../utils/pipes";

export const PriorityPatients = styled.div`
  width: 100%;
  ::-webkit-scrollbar {
    display: none; 
  }
  .header {
    display: flex;
    width: 100%;
    height: 80px;
  }
  .table-container {
    height: calc(100% - 100px);
    width: 100%;
    margin-top: 20px;
    padding: 0 38px;
    box-sizing: border-box;
    overflow: auto;
    .suggestion-table {
      table-layout: auto;
      width: 100%;
      .th-blue {
        color: #3E3DA3;
        display: flex;
        .priority-icon {
          margin-right: 9px;
        }
        &--center{
          justify-content: center;
        }
      }
      th {
        align-items: center;
        color: #8686A9;
        font-size: 19px;
        font-family: "Nunito Sans";
        font-weight: 400;
        padding-bottom: 20px;
        &:nth-of-type(2) {
          text-align: left;
          padding-left: 57px;
        }
      }
      tbody {
        margin-top: 15px;
        tr {
          cursor: pointer;
        }
      }
      td {
        padding: 15px 0;
      }
      .priority {
        display: flex;
        align-items: center;
        justify-content: center;
        &__button {
          margin-right: 10px;
          width: 78px;
          height: 32px;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          border: none;
          outline: none;
          background-color: #FF4A4A;
          align-items: center;
          cursor: pointer;
          &__icon {
            color: #FFFFFF;
            font-size: 12px;
          }
        }
        &__separator {
          height: 12px;
          border-left : 1px solid #D2D7DC;
        }
      }
      .td-patient-info {
        width: 100%;
      } 
      .patient-content {
        display: flex;
        margin-left: 10px;
        /* width: 100%; */
        /* justify-content: center; */
        /* margin: 0 15%; */
        cursor: pointer;
        &__info {
          margin-left: 10px;
          &-name-psa {
            margin-left: 5px;
            color: #36369B;
            font-size: 17px;
            font-family: "Nunito Sans";
            text-overflow: ellipsis;
            overflow: hidden;
            height: 1.2em;
            white-space: nowrap;
            strong{
              margin-left: 10px;
            }
          }
          &-program {
            font-family: "Nunito Sans";
            padding: 0px 5px;
            color: #526479;
            font-size: 16px;
            background-color: #EEF1F4;
            width: fit-content;
            border-radius: 4px;
            display: flex;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 1.2em;
            white-space: nowrap;
            strong {
              margin-right: 10px;
            }
          }
        }
      }
      .last-symptom-card {
        padding: 10px;
        width: 265px;
        height: 55px;
        border-radius: 10.75px;
        background-color: #F3F3FC;
        display: flex;
        margin: auto;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        &__icon {
          color: #36369B;
          font-size: 20px;
        }
        &__info {
          width: calc(100% - 30px);
          margin-left: 10px;
          &-name {
            color: #36369B;
            font-size: 17px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            width: 100%;
          }
          &-date {
            font-size: 14px;
            color: ${"#36369B" + getHexOpacity(0.4)};
            inline-size: max-content;
          }
        }
        /* justify-content: center; */
      }
      .alert-counters {
        display: flex;
        .alert-counter {
          padding-left: 10px;
          padding-right: 20px;
          border-left: 1px solid #D2D7DC;
          display: flex;
          align-items: center;
          &__text {
            margin: 0px 10px 0px 5px;
          }
          &__color {
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }
          &--first {
            padding-left: 20px;
          }
        }
      }
      .quick-actions-container {
        /* visibility: hidden; */
        display: flex;    
        justify-content: center;
        .quick-action {
          margin: 0 5px;
          width: 61px;
          height: 53px;
          border-radius: 10.75px;
          background-color: #F3F3FC;
          display: flex;
          justify-content: center;
          cursor: pointer;
          &__icon {
            font-size: 17.75px;
            color: #36369B;
            align-items: center;
          }
        }
      }
    }
    .zaia-reaction {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }
`;