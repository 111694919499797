import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

function SEO() {
  const [t] = useTranslation("global");

  return (
    <Helmet>
      <title>{t("globally.appTitle")}</title>
      <meta name="description" content={t("globally.appDescription")} />
    </Helmet>
  );
}

export default SEO;