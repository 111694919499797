import styled from "styled-components";

export const GenericTemplateHistoryEdition = styled.div`
  width: 100%;
  box-sizing: border-box;
  .template-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-item-icon {
      color: ${({theme}) => theme.primaryColor}
    }
    &-title{
      font-family: Nunito Sans Bold;
      font-size: 15px;
      color: #3E3E5B;
    }
  }
  .template-content {
    display: flex;
    flex-wrap: wrap;
    .title {
      display: flex;
      font-family: Nunito Sans Bold;
      font-size: 15px;
      color: #3E3E5B;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  .template-content-item {
    margin: 50px 40px 0px 0px;
    display: flex;
    .extra-data {
      font-size: 11px;
      color: var(--blue-color);
      font-weight: 900;
      text-align: right;
      margin-top: 5px;
    }
  }
`;

export const PopUpPayload = styled.div`
  background: ${(theme) => theme.primaryCardComment};
  background: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Open Sans';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`