import React, { useEffect, useMemo, useState } from "react";
import * as S from "./PriorityPatients.style";
import { useTranslation } from "react-i18next";
import ZaiaImage from "../../../../../../zaiaComponents/zaiaImage";
import Popup from "reactjs-popup";
import ProcessName from "../../../../../shared/processName";

// pipes
import * as P from "../../../../../../../utils/pipes";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import { PopUpPayload } from "../../../../../outcomes/components/outcomesComponents/prostateCancer/cmpVerticalOptions/CmpVerticalOptions.style";
import useRecordNavigation from "../../../../../../../hooks/useRecordNavigation";
import { Actions } from "../../../../../../../utils/actionsIds";
import { QUERY_GET_PROCESS_TYPES } from "../../../../../../../graphql/queries/Process";
import { useQuery } from "@apollo/client";
import { QUERY_GET_SIDE_EFFECTS_BY_USER_COUNTER } from "../../../../../../../graphql/queries/SideEffects";
import { QUERY_GET_VITAL_SIGNS_BY_USER_COUNTER } from "../../../../../../../graphql/queries/VitalSigns";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function PriorityPatients({
  setSelectedPatient,
  setSelectedPatientMildAlerts,
  setSelectedPatientMediumAlerts,
  setSelectedPatientSevereAlerts,
  inputText
}) {

  const {t, i18n: { language }} = useTranslation("global");
  const { recordAction } = useRecordNavigation();

  const [usersAlertCounter, setUsersAlertCounter] = useState([]);
  const [usersAllAlertCounter, setUsersAllAlertCounter] = useState([])
  const [dateNow] = useState(new Date(Date.now()));
  const [dateAMonthBefore] = useState(new Date(
    new Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() - 30)).setHours(0, 0, 0, 0)
  ))

  const { data: processTypes } = useQuery(QUERY_GET_PROCESS_TYPES);

  const sideEffects = useMemo(() => {
    let sideEffects1 = [];
    let sideEffects2 = [];
    let sideEffects3 = [];
    processTypes?.process.forEach((process) => {
      process.processToSideEffects.forEach((sideEffect) => {
        if (sideEffect.severity === 1 || sideEffect.severity === 0) {
          
          if(
            !sideEffects1.find(
              (item) => 
                item._and.typeSideEffectVal.value._eq === sideEffect.typeSideEffect && 
                item._and.user.userToProcesses.processId._eq === process.id
            )
          ) {
            sideEffects1.push({
              _and: {
                user: {
                  userToProcesses: {
                    processId: {
                      _eq: process.id
                    }
                  }
                }, 
                typeSideEffectVal: {
                  value: {
                    _eq: sideEffect.typeSideEffect
                  }
                }
              }
            });
          }

          if(
            !sideEffects2.find(
              (item) => 
                item._and.typeSideEffectVal.value._eq === sideEffect.typeSideEffect && 
                item._and.user.userToProcesses.processId._eq === process.id
            )
          ) {
            sideEffects2.push({
              _and: {
                user: {
                  userToProcesses: {
                    processId: {
                      _eq: process.id
                    }
                  }
                }, 
                typeSideEffectVal: {
                  value: {
                    _eq: sideEffect.typeSideEffect
                  }
                }
              }
            });
          }

          if(
            !sideEffects3.find(
              (item) => 
                item._and.typeSideEffectVal.value._eq === sideEffect.typeSideEffect && 
                item._and.user.userToProcesses.processId._eq === process.id
            )
          ) {
            sideEffects3.push({
              _and: {
                user: {
                  userToProcesses: {
                    processId: {
                      _eq: process.id
                    }
                  }
                }, 
                typeSideEffectVal: {
                  value: {
                    _eq: sideEffect.typeSideEffect
                  }
                }
              }
            });
          }
        }
        if (sideEffect.severity === 2 || sideEffect.severity === 3) {

          if(
            !sideEffects2.find(
              (item) => 
                item._and.typeSideEffectVal.value._eq === sideEffect.typeSideEffect && 
                item._and.user.userToProcesses.processId._eq === process.id
            )
          ) {
            sideEffects2.push({
              _and: {
                user: {
                  userToProcesses: {
                    processId: {
                      _eq: process.id
                    }
                  }
                }, 
                typeSideEffectVal: {
                  value: {
                    _eq: sideEffect.typeSideEffect
                  }
                }
              }
            });
          }

          if(
            !sideEffects3.find(
              (item) => 
                item._and.typeSideEffectVal.value._eq === sideEffect.typeSideEffect && 
                item._and.user.userToProcesses.processId._eq === process.id
            )
          ) {
            sideEffects3.push({
              _and: {
                user: {
                  userToProcesses: {
                    processId: {
                      _eq: process.id
                    }
                  }
                }, 
                typeSideEffectVal: {
                  value: {
                    _eq: sideEffect.typeSideEffect
                  }
                }
              }
            });
          }
        }
        if (sideEffect.severity === 4 || sideEffect.severity === 5) {
          if(
            !sideEffects3.find(
              (item) => 
                item._and.typeSideEffectVal.value._eq === sideEffect.typeSideEffect && 
                item._and.user.userToProcesses.processId._eq === process.id
            )
          ) {
            sideEffects3.push({
              _and: {
                user: {
                  userToProcesses: {
                    processId: {
                      _eq: process.id
                    }
                  }
                }, 
                typeSideEffectVal: {
                  value: {
                    _eq: sideEffect.typeSideEffect
                  }
                }
              }
            });
          }
        }
      });
    }); 

    return [sideEffects1, sideEffects2, sideEffects3];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processTypes]);

  const vitalSigns = useMemo(() => {
    const vitalSignsBySeverity = [[], [], []];
  
    processTypes?.process.forEach((process) => {
      process.processToVitalSigns.forEach((vitalSign) => {
  
        const vitalSignFilter = {
          _and: {
            measure1: {},
            vitalSignVal: { value: { _eq: vitalSign.vitalSign } },
            user: {
              userToProcesses: {
                processId: { _eq: process.id }
              }
            }
          }
        };
  
        if (vitalSign.min) {
          vitalSignFilter._and.measure1._gte = vitalSign.min;
        }
  
        if (vitalSign.max) {
          vitalSignFilter._and.measure1._lte = vitalSign.max;
        }
  
        const severity = vitalSign.severity;
        if (severity >= 1 && severity <= 3) {
          vitalSignsBySeverity[severity - 1].push(vitalSignFilter);
        }
      });
    });
  
    return vitalSignsBySeverity;
  }, [processTypes]);

  const {
    data: sideEffectsData,
    loading: sideEffectsDataLoading,
    error: sideEffectsDataError,
  } = useQuery(
    QUERY_GET_SIDE_EFFECTS_BY_USER_COUNTER, {
    variables: {
      sideEffects1: sideEffects[0],
      severities1:  [0, 1],
      sideEffects2: sideEffects[1],
      severities2:  [2, 3],
      sideEffects3: sideEffects[2],
      severities3:  [4, 5],
      initialDate: dateAMonthBefore,
      finalDate: dateNow
    },
  });    

  const { 
      data: vitalSignsData, 
      loading: vitalSignsDataLoading, 
      error: vitalSignsDataError
  } = useQuery(
    QUERY_GET_VITAL_SIGNS_BY_USER_COUNTER, {
    variables: {
      vitalSigns1:  vitalSigns[0],
      vitalSigns2:  vitalSigns[1],
      vitalSigns3:  vitalSigns[2],
      initialDate: dateAMonthBefore,
      finalDate: dateNow
    },
  });

  useEffect(() => {
    const vitalSignsCounts = vitalSignsData?.user.map((user) => {
      return {
        user: user, 
        mild: user.vitalSigns1Count.aggregate.count,
        medium: user.vitalSigns2Count.aggregate.count,
        severe: user.vitalSigns3Count.aggregate.count,
      }
    });
    
    const allCounts = sideEffectsData?.user.map((user) => {
      return {
        user: user,
        mild: user.sideEffects1Count.aggregate.count + vitalSignsCounts?.find((count) => count.user.id === user.id)?.mild,
        medium: user.sideEffects2Count.aggregate.count + vitalSignsCounts?.find((count) => count.user.id === user.id)?.medium,
        severe: user.sideEffects3Count.aggregate.count + vitalSignsCounts?.find((count) => count.user.id === user.id)?.severe,
      }
    });
    
    const order = allCounts?.sort(
      (a, b) => (b.severe - a.severe) || (b.medium - a.medium) || (b.mild - a.mild) 
    );

    setUsersAlertCounter(order);
    setUsersAllAlertCounter(order);

  }, [sideEffectsData, vitalSignsData, dateNow, dateAMonthBefore]);  

  const getFilterText = (text) => {    
    return inputText 
      ? String(text).toLowerCase().includes(inputText.toLowerCase())
      : true
  }


  if (sideEffectsDataError || vitalSignsDataError)
    return (
      <S.PriorityPatients>
        <div className="table-container">
          <div className="zaia-reaction">
            <ZaiaReaction
              zaiaImg={logoZaiaAlert}
              widthImg="100px"
              text={"Error loading the patients"}
              sizeText="15px"
              widthText="240px"
            />
          </div>
        </div>
      </ S.PriorityPatients>
    )

  if(sideEffectsDataLoading || vitalSignsDataLoading) 
    return(
      <S.PriorityPatients>
        <div className="table-container">
          <div className="zaia-reaction">
            <ZaiaReaction
              widthImg="150px"
              zaiaImg={logoZaiaLoading}
            />  
          </div>
        </div>
      </S.PriorityPatients>
    )    

  return (
    <S.PriorityPatients>
      <div className="header">
        
      </div>
      <div className="table-container">
        <table className="suggestion-table">
          <thead className="suggestion-table__thead">
            <tr>
              <th className="th-blue"> 
                <i className="priority-icon icon zaia-i-outline-flag"/>
                {t("aiNavigator.table.priority")}
              </th>
              <th>
                {t("globally.patientName")}
              </th>
              <th>
                {t("aiNavigator.table.alertCounter")}
              </th>
            </tr>
          </thead>
            {
              usersAlertCounter && usersAlertCounter?.length > 0 &&
              <tbody>
                { usersAlertCounter.filter((patient) => getFilterText(`${patient.user.name} ${patient.user.lastname}`) || getFilterText(patient.user.email))?.slice(0, 5)?.map((patient, index) => {                  
                  return (
                    <tr 
                      key={patient.user.id} 
                      onClick={
                        () => {
                          recordAction(Actions.AINAVIGATOR.OPEN_MODAL_SUGGEST_PATIENT);
                          setSelectedPatient(patient.user);
                          setSelectedPatientMildAlerts(patient?.mild);
                          setSelectedPatientMediumAlerts(patient?.medium);
                          setSelectedPatientSevereAlerts(patient?.severe);
                        }
                      }
                    >
                      <td>
                        <div className="priority">
                          <button 
                            className="priority__button"
                            style={{
                              "backgroundColor": index <= 1 ? "#FF4A4A" : index === 2 ? "#FF7A00" : "#4AA8FF"
                            }}
                          >
                            <i className="priority__button__icon icon zaia-i-flag"/>
                          </button>
                          <div className="priority__separator"></div>
                        </div>
                      </td>
                      <td className="td-patient-info">
                        <div 
                          className="patient-content">
                          <ZaiaImage
                            img={patient?.user.picture}
                            alt=""
                            size={37}
                            rounded={true}
                          />
                          <div className="patient-content__info">
                            <div className="patient-content__info-name-psa">
                            {`${patient.user.name} ${patient.user.lastname}`}
                            </div>
                            <div className="patient-content__info-program">
                              <strong>{t("globally.program")}</strong>
                              {
                                <Popup
                                  trigger={
                                    <div className="patient-option__info-program-detail">
                                      {
                                        patient?.user.userToProcesses.length > 1 
                                        ? <div style={{"textDecoration": "underline"}}>{t("globally.variousProcesses")}</div> : 
                                        <div>{
                                          patient?.user.userToProcesses[0].process?.processName
                                          && P.dataTranslation(patient?.user.userToProcesses[0].process?.processName, language)
                                        }</div>
                                      }
                                    </div>
                                  }
                                  keepTooltipInside
                                  nested
                                  // position={"right bottom"}
                                  on="hover"
                                  disabled={patient?.user.userToProcesses.length > 1 ? false : true}
                                >
                                  {
                                    <ProcessName userToProcesses={patient?.user.userToProcesses}/>
                                  }
                                </Popup>
                              }
                              
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="alert-counters">
                          <div className="alert-counter alert-counter--first">
                            <div className="alert-counter__color" style={{backgroundColor: "#53C25D"}}></div>
                            <div className="alert-counter__text">{t("globally.mild")}</div>
                            <div className="alert-counter__number">{patient?.mild || 0}</div>
                          </div>
                          <div className="alert-counter">
                            <div className="alert-counter__color" style={{backgroundColor: "#FE6330"}}></div>
                            <div className="alert-counter__text">{t("globally.medium")}</div>
                            <div className="alert-counter__number">{patient?.medium || 0}</div>
                          </div>
                          <div className="alert-counter">
                            <div className="alert-counter__color" style={{backgroundColor: "#C92241"}}></div>
                            <div className="alert-counter__text">{t("globally.severe")}</div>
                            <div className="alert-counter__number">{patient?.severe || 0}</div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    )
                  })
                } 
              </tbody>
            }
        </table>  
      </div>
    </S.PriorityPatients>
  );
}

export default PriorityPatients;
