import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalAddSurveyData = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  .message {
    color: #333365;
    font-family: "Nunito Sans";
    font-size: 16px;
    margin: 20px 40px 0;
  }
    .rows-container {
      height: 100%;
      /* min-height: 478px; */
      overflow: auto;
      
    }
    .rows {
      display: flex;
      padding: 20px 40px 0px;
    }
    .principal-input {
      padding-right: 20px;
      width: 40%;
      &__form {
        height: 100%;
      }
      &__container {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
    .custom-form-inputs {
      overflow: auto;
      padding: 20px 20px 20px 15px;
      /* margin-left: 5px; */
      background-color: #F1F3F5;
      width: 60%;
      border-radius: 10px;
      border-left: 5px solid #6C6BCC;
      overflow-y: auto;
      min-height: 330px;
      max-height: 450px;
    }
    .input-box-time {
      padding: 12px 0px 30px 0px;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      &__icon {
        color: #3E3E5B; 
        margin-right: 10px;
      }
      .input-content {
        align-items: center;
        display: flex;      
        margin-right: 20px;
        &__label-bold {
          color: #3E3E5B; 
          white-space: nowrap;
          margin-right: 10px;
          font-size: 15px;
        }
      }
      &__separator {
        border-left: 1px solid #E6E6E6;
        height: 16px;
        margin-left: 9px;
        margin-right: 29px;
      }
      .button-add-favourite {
        display: flex;
        padding: 10px;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
        border: 1px solid #AAAADB;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        &__icon {
          font-size: 13px;
          color: #36369B;
        }
      }
    }
    .input-box {
      padding-bottom: 20px;
      color: #3E3E5B;
      display: flex;
      flex-direction: column;
      position: relative;
      &--border {
        .input-container {
          border-top: 2px solid #6C6BCC;
          border-right: 5px solid #6C6BCC;
          border-bottom: 2px solid #6C6BCC;
          border-left: 2px solid #6C6BCC;
        }
      }
      &--last {
        padding-bottom: 0px;
        height: 100%;
      }
      &__label {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 6px;
        &-icon {
          margin-right: 10px;
        }
      }
      .label-and-button {
        display: flex;
        justify-content: space-between;
        .highlight-button {
          display: flex;
          opacity: 0.5;
          color: #36369B;
          align-items: center;
          margin-bottom: 6px;
          cursor: pointer;
          &__icon {
            margin-right: 5px;
          }
          &__text {
            font-size: 14px;
          }
          &--active {
            opacity: 1;
            font-family: "Nunito Sans Bold";
            color: #FFA800;
          }
        }
      }
      .shape-container {
        position: absolute;
        right: -23px;
        top: 33px;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 10px 40px;
      border-top: 1px solid #0000002B;
      box-sizing: border-box;
      align-items: center;
      margin-top: 20px;
      .button-container {
        width: 230px;
        &__text {
          font-family: Nunito Sans;
          font-size: 15px;
        }
      }
    }
  
  

  @media only screen and (max-width: 435px) {
    .buttons-container {
      .button-submit {
        button {
          /* width: 70%; */
          margin: auto;
        }
      }
      .button-close {

      }
    } 
  }
`;